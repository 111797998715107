import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import "./porgramDetail.css";
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { MyUserContext } from 'contexts/MyUserContext';
import Tab from 'components/basic/tab';

import {
  apiPowerEqpStatusList, apiPowerSubProgramList, apiPowerSubErrorList,
  apiSingleLoadProgram, apiPowerExecutionDetail, apiDatalistOriginal
} from 'utils/Api';
import {
  formatTime, decimalWithOneDecimal, convertDateFormat, calculateDuration,
  generateDateTimeRangeJSON, formatDateTime, formatDateTimeRange, fillTimestamps
} from 'utils/commonFun';

import { decrypt } from 'utils/crypt';
import WorkpieceBreadCrumb from 'components/workpieceBreadCrumb/WorkpieceBreadCrumb';

import PageErr404 from "page/PageErr404";   //頁面不存在

const ProgramDetail = () => {
  const { t, i18n } = useTranslation("powerCarbon");
  let { tabName, workpieceID, programID, execuutionID } = useParams();
  const [tabList, setTabList] = useState([]);  //上方Tab
  const { permission, showFormatDate, showFormatTime } = useContext(MyUserContext);

  const navigate = useNavigate();

  const [calMethod, setCalMethod] = useState(1);  //0:只計算完整執行 1:計算全部(目前用不到)

  const [workpieceBasicInfo, setWorkpieceBasicInfo] = useState(null);
  const [powerInfo, setPowerInfo] = useState(null);

  const [transTimelineData, setTransTimelineData] = useState([]);  //將API回傳資料做轉換給ECharts設定檔資料
  const [timelineOptions, setTimelineOptions] = useState(null); //時間軸ECharts設定
  const [optionsXAxis, setOptionsXAxis] = useState([]); //圖表X軸(時間軸)
  const [loadChart, setLoadChart] = useState(false); //是否完整載入圖表

  const [epqStatus, setEpqStatus] = useState(null); //設備運轉狀態
  const [errorList, setErrorList] = useState(null); //錯誤發生時間軸
  const [subProgramList, setSubProgramList] = useState(null); //副程式時間軸
  const [filterMainProgramName, setFilterMainProgramName] = useState(0);

  const [showPageErr404, setShowPageErr404] = useState(false); //是否要顯示404頁面

  useEffect(() => {
    loadWorkpieceBasicInfo();
    loadPowerInfo();
  }, []);

  useEffect(() => {
    if (transTimelineData.length > 0 && optionsXAxis.length > 0) {
      setTimelineOptions({
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          },
          formatter: function (params) {
            var result = params[0].axisValueLabel + '<br/>';
            params.forEach(function (item) {
              result += item.marker + item.seriesName + ' ' + item.value + ' kW<br/>';
            });
            return result;
          }
        },
        toolbox: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: optionsXAxis
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '25%']
        },
        grid: {
          top: '20px',
          left: '0px',
          right: '0px',
          bottom: '20px',
          containLabel: true
        },
        series: [
          {
            name: t("instantaneousPower"),
            type: 'line',
            // smooth: true,
            // symbol: 'none',
            // areaStyle: {},
            data: transTimelineData
          }
        ]
      });
    }
  }, [transTimelineData, optionsXAxis, i18n.language]);


  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "PartsPrograms",
        active: tabName === "PartsPrograms"
      },
      {
        tabName: "TimePeriodCapture",
        active: tabName === "TimePeriodCapture"
      }
    ];

    if (permission !== null) {
      let filterMainTab = permission.find(d => d.tabName === "Power");
      if (filterMainTab) {
        for (let item of filterMainTab.tabList) {
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if (tab) newTabList.push(tab);
        }
      }
      else {
        newTabList = defaultTabs;
      }
    }
    else {
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if (filter) {
      if (filter.tabName === "PartsPrograms") {
        navigate(`/Power/${filter.tabName}/${workpieceID}/${programID}/${execuutionID}`);
      }
      else {
        navigate(`/Power/${filter.tabName}`);
      }
    }
  }, [tabList]);

  //#region 載入工件上方基本資訊
  const loadWorkpieceBasicInfo = async () => {
    const [httpStatus, reponseData] = await handleApiSingleLoadProgram(decrypt(programID));

    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        let data = reponseData.data;

        if (decrypt(workpieceID) === "0") {  //路徑:0/加工程式代號
          setWorkpieceBasicInfo({
            wpID: "0",
            wpNO: "",
            wpName: "",
            wpImage: "",
            programID: decrypt(programID),
            programName: data.programName
          });
        }
        else {  //路徑:工件代號/加工程式代號
          setWorkpieceBasicInfo({
            wpID: decrypt(workpieceID),
            wpNO: data.wpNO,
            wpName: data.wpName,
            wpImage: data.wpImage,
            programID: decrypt(programID),
            programName: data.programName
          });
        }
      }
      else if (reponseData.statusCode === "40300") {
        setShowPageErr404(true);
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 載入單一一筆加工程式
  const handleApiSingleLoadProgram = async (selectId) => {
    let response = await apiSingleLoadProgram(selectId, {});

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 載入上方標題電量資訊
  const loadPowerInfo = async () => {
    const [httpStatus, reponseData] = await handleApiPowerExecutionDetail();

    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        let data = reponseData.data;
        setPowerInfo(data);
      }
      else if (reponseData.statusCode === "40300") {
        setShowPageErr404(true);
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 用電量API(上方標題)
  const handleApiPowerExecutionDetail = async () => {
    let response = await apiPowerExecutionDetail(decrypt(execuutionID));
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    if (powerInfo != null) {
      loadPowerKwList();
      loadPowerEqpStatusList();
      loadPowerSubErrorList();
      loadPowerSubProgramList();
    }
  }, [powerInfo]);

  //#region 用電加工程式圖表(功率)
  const loadPowerKwList = async () => {
    let dateTimeRangeJSON = generateDateTimeRangeJSON(powerInfo?.programTimeStart, powerInfo?.programTimeEnd);

    for (let [itemIndex, item] of dateTimeRangeJSON.entries()) {
      let queryStartTime =item.startTime
      let queryEndTime = formatDateTime(new Date(new Date(item.endTime).getTime() + 1000));
      const [httpStatus, responseData] = await handleApiDatalistOriginal("power_now", "metrics", queryStartTime, queryEndTime);

      if (httpStatus == "200") {
        if (responseData.statusCode === "20000") {
          const timeStamp = fillTimestamps(responseData.data[0].itemList, item.startTime, item.endTime);
          setOptionsXAxis(prevData => {
            return [
              ...prevData,
              ...timeStamp
            ];
          });

          let itemList = responseData.data[0].itemList;          
          let itemListIndex = 0;
          let data = [];
          for (let mark of timeStamp) {
            if (itemList.length > 0) {
              if (itemListIndex <= itemList.length - 1 && mark === itemList[itemListIndex].timestamp) {
                data.push(itemList[itemListIndex].value);
                itemListIndex++;
              }
              else {
                data.push(null);
              }
            }
            else {
              data.push(null);
            }
          }
          updateData(data);

          if (itemIndex === dateTimeRangeJSON.length - 1) {
            setLoadChart(true);
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  const updateData = (data) => {
    setTransTimelineData(prevData => {
      return [
        ...prevData,  // Spread the previous array (this keeps the existing data)
        ...data       // Spread the new data into the array
      ];
    });
  }

  // useEffect(() => {
  //   console.log("transTimelineData", transTimelineData)
  // }, [transTimelineData]);

  // useEffect(() => {
  //   console.log("optionsXAxis", optionsXAxis)
  // }, [optionsXAxis]);

  //#region 查詢Metrics資料API
  const handleApiDatalistOriginal = async (metricCode, metricCollection, startTime, endTime) => {
    let postJson = {
      mid: parseInt(powerInfo?.mid),
      metricCodeList: [{
        metricCode: metricCode,
        metricCollection: metricCollection
      }],
      startTime: startTime,
      endTime: endTime
    };

    let response = await apiDatalistOriginal(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion
  
  //#region 用電加工程式圖表(設備運轉狀態)
  const loadPowerEqpStatusList = async () => {
    const [httpStatus, reponseData] = await handleApiPowerEqpStatusList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setEpqStatus(reponseData.data);
      }
      else {
        setEpqStatus(null);
      }
    }
    else {
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 用電加工程式圖表(設備運轉狀態)API
  const handleApiPowerEqpStatusList = async () => {
    let postData = {
      startTime: convertDateFormat(powerInfo?.programTimeStart, "yyyy-MM-DD HH:mm:ss"),
      endTime: convertDateFormat(powerInfo?.programTimeEnd, "yyyy-MM-DD HH:mm:ss"),
      mid: parseInt(powerInfo?.mid)
    };

    let response = await apiPowerEqpStatusList(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 用電加工程式圖表(錯誤發生時間軸)
  const loadPowerSubErrorList = async () => {
    const [httpStatus, reponseData] = await handleApiPowerSubErrorList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        let data = reponseData.data;
        let currentStartTime = powerInfo?.programTimeStart;
        if (data.length > 0) {
          for (let item of data) {
            let currentTime = item.timestamp;
            let duration = calculateDuration(currentStartTime, currentTime);
            item.seconds = duration;
          }
        }
        setErrorList(data);
      }
      else {
        setErrorList(null);
      }
    }
    else {
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 用電加工程式圖表(錯誤發生時間軸)API
  const handleApiPowerSubErrorList = async () => {
    let postData = {
      startTime: convertDateFormat(powerInfo?.programTimeStart, "yyyy-MM-DD HH:mm:ss"),
      endTime: convertDateFormat(powerInfo?.programTimeEnd, "yyyy-MM-DD HH:mm:ss"),
      midList: [parseInt(powerInfo?.mid)]
    };

    let response = await apiPowerSubErrorList(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 用電加工程式圖表(副程式時間軸)
  const loadPowerSubProgramList = async () => {
    const [httpStatus, reponseData] = await handleApiPowerSubProgramList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        let data = reponseData.data;
        setSubProgramList(data);

        let times = 0;
        for (let item of data.detail) {
          times++;
        }
        setFilterMainProgramName(times);
      }
      else {
        setSubProgramList(null);
      }
    }
    else {
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 用電加工程式圖表(副程式時間軸)API
  const handleApiPowerSubProgramList = async () => {
    let postData = {
      startTime: convertDateFormat(powerInfo?.programTimeStart, "yyyy-MM-DD HH:mm:ss"),
      endTime: convertDateFormat(powerInfo?.programTimeEnd, "yyyy-MM-DD HH:mm:ss"),
      mid: parseInt(powerInfo?.mid)
    };

    let response = await apiPowerSubProgramList(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion


  return (
    <>
      <Tab tabList={tabList} setTabList={setTabList} />
      {
        !showPageErr404 ?
          <div className='pageContent'>
            <div className="filterRow">
              <div>
                {/*麵包屑*/}
                <WorkpieceBreadCrumb basinInfo={workpieceBasicInfo} 
                    workpieceID={workpieceID} programID={programID}
                    execuutionID={execuutionID} />
                <div className="filling" id="pageWorkpiecesDetail"></div>
              </div>
              <div>
              </div>
            </div>
            <div className='mt-2'>
              <div className="execution-overview box-style-white-shadow">
                {/* <!--標題--> */}
                <div className="title">
                  <span className="titile-main">{workpieceBasicInfo?.programName}</span><br />
                  <span className="title-sub-blue">{workpieceBasicInfo?.wpNO}</span>
                  <span className="title-sub-black title-sub2">{workpieceBasicInfo?.wpName}</span>
                </div>

                {/* <!--數值卡--> */}
                <div className="card-value-purpleblue">
                  <div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("startTime")}</div>
                        <div className="content">
                          {(() => {
                            const trans = formatDateTimeRange(showFormatDate, showFormatTime, powerInfo?.programTimeStart);
                            return trans.startDate + " " + trans.startTime;
                          })()}
                        </div>
                      </div>
                      <div className="card">
                        <div className="title">{t("endTime")}</div>
                        <div className="content">
                          {(() => {
                            const trans = formatDateTimeRange(showFormatDate, showFormatTime, powerInfo?.programTimeEnd);
                            return trans.startDate + " " + trans.startTime;
                          })()}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("durarion")}</div>
                        <div className="content">{formatTime(powerInfo?.duration)}</div>
                      </div>
                      {/* <div className="card">
                            <div className="title">{t("executionCondition")}</div>
                            <div className="content">{programDetail?.executionCondition === "B" ? t("incomplete") : t("finished")}</div>
                        </div> */}
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("numberErrors")}</div>
                        <div className="content">{powerInfo?.errorCount}</div>
                      </div>
                      <div className="card">
                        <div className="title">{t("powerConsumption")}</div>
                        <div className="content">{powerInfo?.powerUsage?.toFixed(2)}</div>
                      </div>
                    </div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("carbonEmissions")}</div>
                        <div className="content">{powerInfo?.carbonEmissions?.toFixed(2)}</div>
                      </div>
                      <div className="card">
                        <div className="title">{t("equipmentUsed")}</div>
                        <div className="content">{powerInfo?.equipmentSerialNumber}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--時間軸--> */}
              <main className="box-style-white-shadow mt-4">
                <div className="margin20">
                  {/* <!--翻譯「時間軸」--> */}
                  <div className="text-black-20-bold">{t("timeline")}</div>
                  <div className="table-scroll-justify ">
                    <div>
                      {/* <!--Apache圖示例--> */}
                      <div className="apache_power_now power_now_partsProgram">
                        {
                          timelineOptions != null ?
                            <ReactEcharts option={timelineOptions} echarts={echarts} /> : null
                        }
                        {
                          !loadChart ?
                            <div className="loading chartbox">
                              <div className="spinner"></div>
                            </div> : null
                        }
                      </div>

                      <div className="chart_timeline_group">
                        {/* <!--設備狀態--> */}
                        <div className="chart_timeline_machinestatus">
                          {
                            epqStatus?.detail?.map((obj, index) => {
                              let classStatus = "";
                              let width = decimalWithOneDecimal(obj.seconds, epqStatus?.totalSeconds);
                              switch (obj.status) {
                                case "Machining":
                                  classStatus = "running";
                                  break;
                                case "Idle":
                                  classStatus = "idle";
                                  break;
                                case "Alarm":
                                  classStatus = "error";
                                  break;
                                default:
                                  classStatus = ""
                              }
                              return (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <div style={{ textAlign: "left" }}>
                                        {obj.status}<br /><br />
                                        <p className='mb-0'>{t("start")}&nbsp;{convertDateFormat(obj.startTime)}</p>
                                        <p className='mb-0'>{t("end")}&nbsp;{convertDateFormat(obj.endTime)}</p>
                                        <p className='mb-0'>{t("duration")}&nbsp;{formatTime(obj.seconds)}</p>
                                      </div>
                                    </Tooltip>
                                  }
                                  key={`epqStatusDetail${index}`}
                                >
                                  <div className={classStatus} style={{ width: `${width}%` }}></div>
                                </OverlayTrigger>
                              )
                            })
                          }
                        </div>
                        {/* <!--副程式--> */}
                        {
                          subProgramList?.totalItems > 0 ?
                            <div className="chart_timeline_subprogram">
                              {
                                subProgramList?.detail.map((obj, index) => {
                                  let width = decimalWithOneDecimal(obj.seconds, subProgramList?.totalSeconds);
                                  return (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip className="custom-tooltip">
                                          <div style={{ textAlign: "left" }}>
                                            {obj.subProgram}<br />
                                            <p className='mb-0'>{t("start")}&nbsp;{convertDateFormat(obj.startTime)}</p>
                                            <p className='mb-0'>{t("end")}&nbsp;{convertDateFormat(obj.endTime)}</p>
                                            <p className='mb-0'>{t("duration")}&nbsp;{formatTime(obj.seconds)}</p>
                                            <p className='mb-0'>{t("powerConsumptionTooltip")}&nbsp;{obj.powerConsumption}&nbsp;kWh</p>
                                          </div>
                                        </Tooltip>
                                      }
                                      key={`subProgramList${index}`}
                                    >
                                      <div style={{ width: `${width}%` }}></div>
                                    </OverlayTrigger>

                                  )
                                })
                              }
                            </div> :
                            null
                        }
                        {/* <div className="chart_timeline_subprogram">
                            <div style={{width: "5%"}}></div>
                            <div style={{width: "12%"}}></div>
                            <div style={{width: "8%"}}></div>
                            <div style={{width: "26%"}}></div>
                            <div style={{width: "5%"}}></div>
                            <div style={{width: "14%"}}></div>
                            <div style={{width: "6%"}}></div>
                            <div style={{width: "8%"}}></div>
                            <div style={{width: "1%"}}></div>
                            <div style={{width: "4%"}}></div>
                            <div style={{width: "9%"}}></div>
                            <div style={{width: "1%"}}></div>
                            <div style={{width: "1%"}}></div>
                        </div> */}
                        {/* <!--錯誤--> */}
                        <div className="chart_timeline_error">
                          {
                            errorList?.map((obj, index) => {
                              let width = decimalWithOneDecimal(obj.seconds, epqStatus?.totalSeconds);
                              return (
                                <div className="error floating-animation" key={index} style={{ width: `${width}%` }}>
                                  <OverlayTrigger
                                    placement="bottom-end"
                                    overlay={
                                      <Tooltip className="custom-tooltip">
                                        <div style={{ textAlign: "left" }}>
                                          {obj.subProgramName}<br />
                                          <p className='mb-0'>{t("time")}:&nbsp;{convertDateFormat(obj.timestamp)}</p>
                                          <p className='mb-0'>{t("code")}:&nbsp;{obj.code}</p>
                                          <p className='mb-0'>{t("message")}:&nbsp;{obj.message}</p>
                                        </div>
                                      </Tooltip>
                                    }
                                  >
                                    <div className></div>
                                    {/* <div key={index} className="error"></div> */}
                                  </OverlayTrigger>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>

              {/* <!--第3排 副程式--> */}
              <div className="flex-table box-list-whiterowgrayline mt-4">
                <div className="title">{t("subprograms")}</div>
                <div className="table-scroll-justify">
                  <div className="dmtable">
                    <div className="thead sticky-top">
                      <div className="tr">
                        <div className="tflex1 min-width9 sticky-left">{t("subprogram")}</div>
                        <div className="tflex1 min-width7 tLeft">{t("startTime")}</div>
                        <div className="tflex1 min-width7 tLeft">{t("endTime")}</div>
                        <div className="tflex1 min-width5 tCenter">{t("durarion")}</div>
                        <div className="tflex1 min-width5 tCenter">{t("numberErrors")}</div>
                        <div className="tflex1 min-width5 tRight">{t('powerConsumption')}</div>
                        <div className="tflex1 min-width5 tRight">{t("carbonEmissions")}</div>
                      </div>
                    </div>
                    <div className="tbody">                      
                      {
                        filterMainProgramName > 0 ?
                          subProgramList?.detail.map((obj, index) => {
                            return (
                              <div className="tr" key={index}>
                                <div className="tflex1 min-width9 sticky-left work-break-all">{obj.subProgram}</div>
                                <div className="tflex1 min-width7 tLeft">
                                  {(() => {
                                    const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.startTime);
                                    return trans.startDate + " " + trans.startTime;
                                  })()}
                                </div>
                                <div className="tflex1 min-width7 tLeft">
                                  {(() => {
                                    const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.endTime);
                                    return trans.startDate + " " + trans.startTime;
                                  })()}
                                </div>
                                <div className="tflex1 min-width5 tCenter">{formatTime(obj.seconds)}</div>
                                <div className="tflex1 min-width5 tCenter">{obj.errorCount}</div>
                                <div className="tflex1 min-width5 tRight">{obj.powerConsumption.toFixed(2)}</div>
                                <div className="tflex1 min-width5 tRight">{obj.carbonEmissions.toFixed(2)}</div>
                              </div>
                            )
                          }) :
                          <div className='noDataDiv'><span className='noData'>{t("noSubprograms")}</span></div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--第4排 錯誤--> */}
              <div className="flex-table box-list-whiterowgrayline mt-4">
                <div className="title">{t("errors")}</div>
                <div className="table-scroll-justify">
                  <div className="dmtable">
                    <div className="thead sticky-top">
                      <div className="tr">
                        <div className="tflex1 min-width7 sticky-left">{t("time")}</div>
                        <div className="tflex1 min-width7 tLeft">{t("type")}</div>
                        <div className="tflex1 min-width7 tLeft">{t("code")}</div>
                        <div className="tflex2 min-width11 tLeft">{t("message")}</div>
                        <div className="tflex1 min-width9 tLeft ">{t("subprogram")}</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {
                        errorList?.length > 0 ?
                          errorList?.map((obj, index) => {
                            return (
                              <div className="tr" key={index}>
                                <div className="tflex1 min-width7 sticky-left"><span>{obj.timestamp}</span></div>
                                <div className="tflex1 min-width7 tLeft">{obj.type}</div>
                                <div className="tflex1 min-width7 tLeft work-break-all">#{obj.code}</div>
                                <div className="tflex2 min-width11 tLeft">{obj.message}</div>
                                <div className="tflex1 min-width9 tLeft work-break-all">{obj.subProgramName}</div>
                              </div>
                            )
                          }) :
                          <div className='noDataDiv'><span className='noData'>{t("noErrorEvents")}</span></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> : <PageErr404 judgeBackHome={false} backURL={`/Power/${tabName}`} />
      }

      <ToastContainer />
    </>
  );
};

export default ProgramDetail;
