import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { apiOrderList, apiCheckProgramTimeZero, apiScheduleClearCache } from 'utils/Api';
import { convertDateFormat, formatTimeDays } from 'utils/commonFun';
import { ToastContainer, toast } from 'react-toastify';
import '../APS-modal-viewProcess.css';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import ManualScheduling from "page/apps/data/aps/modal/ManualScheduling"; //手動排程
import AutoScheduling from "page/apps/data/aps/modal/AutoScheduling"; //自動排程
import UnableSchedule from "page/apps/data/aps/modal/UnableSchedule"; //無法進行排程

const SelectWorkOrder = ({ showModal, handleClose, handleCancel, loadOrderList, loadOrderCountAndStatus, orderCountAndStatus, setCurrentTab, ratingCriteria }) => {
  const { t, i18n } = useTranslation("aps");
  const { overtime, authority } = useContext(MyUserContext);

  const [orderData, setOrderData] = useState([]);  //工單資料

  const [chooseOrderID, setChooseOrderID] = useState([]);  //選擇工單流水號
  const [chooseOrderNo, setChooseOrderNo] = useState([]);  //選擇工單編號+工序
  const [unableScheduleProgram, setUnableScheduleProgram] = useState([]);  //無法進行排程-加工程式列表
  const [manualOrderId, setManualOrderId] = useState(null); //指定排程所選擇工單資訊

  const [manualSchedulingModal, setManualSchedulingModal] = useState(false); //指定排程Modal
  const handleManualSchedulingClose = () => {
    setManualSchedulingModal(false);
  }

  const [autoSchedulingModal, setAutoSchedulingModal] = useState(false); //自動排程Modal
  const handleAutoSchedulingClose = () => {
    setAutoSchedulingModal(false);
  }

  const [isChangeSchedule, setIsChangeSchedule] = useState(false);  //需不需要切換排程方法按鈕(自動或指定)

  const [unableScheduleModal, setUnableScheduleModal] = useState(false); //無法進行排程Modal
  const handleUnableScheduleClose = () => {
    setUnableScheduleModal(false);
  }

  useEffect(() => {
    if (showModal) {
      setChooseOrderID([]);
      setChooseOrderNo([]);
      setUnableScheduleProgram([]);

      loadUnScheduleOrderList();
      loadOrderCountAndStatus();
    }
  }, [showModal]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log("chooseOrderID", chooseOrderID);
      console.log("chooseOrderNo", chooseOrderNo);
    }
  }, [chooseOrderID, chooseOrderNo]);

  //#region 載入工單列表
  const loadUnScheduleOrderList = async () => {
    const [httpStatus, reponseData] = await handleApiUnScheduleOrderList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setOrderData(reponseData.data);
      }
    }
  }
  //#endregion

  //#region 載入工單列表API
  const handleApiUnScheduleOrderList = async () => {
    let postJson = {
      orderStatus: [0],
      orderField: "orderNo",
      descending: false
    };

    let response = await apiOrderList(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 點選工單
  const handleCheckboxChange = (event, orderID, orderNo, processNo) => {
    const isChecked = event.target.checked;
    const hasShiftKey = event.shiftKey;
    let selectedOrderIDs = [];
    let selectedOrderNOs = [];

    // Check if shift key was held down when clicking than multiple selection for orderIDs
    if (hasShiftKey) {
      const lastCheckedOrderID = chooseOrderID[chooseOrderID.length - 1];
      const lastIndex = orderData.detail.findIndex(d => d.orderID === lastCheckedOrderID);
      const currentIndex = orderData.detail.findIndex(d => d.orderID === orderID);
      const start = Math.min(lastIndex, currentIndex);
      const end = Math.max(lastIndex, currentIndex);
      selectedOrderIDs = orderData.detail.slice(start, end + 1).map(d => d.orderID).filter(d => !chooseOrderID.includes(d));
      selectedOrderNOs = orderData.detail.slice(start, end + 1).map(d => `${d.orderNo}-${d.processNo}`).filter(d => !chooseOrderNo.includes(d));
    } else {
      selectedOrderIDs = [orderID];
      selectedOrderNOs = [`${orderNo}-${processNo}`];
    }

    setChooseOrderID(prevChooseOrderID => {
      if (isChecked || hasShiftKey) {
        // 如果被選取，加入新值到陣列
        return [...prevChooseOrderID, ...selectedOrderIDs];
      } else {
        // 如果取消選取，過濾掉該值
        return prevChooseOrderID.filter(d => !selectedOrderIDs.includes(d));
      }
    });

    setChooseOrderNo(prevChooseOrderNo => {
      if (isChecked || hasShiftKey) {
        // 如果被選取，加入新值到陣列
        return [...prevChooseOrderNo, ...selectedOrderNOs];
      } else {
        // 如果取消選取，過濾掉該值
        return prevChooseOrderNo.filter(d => !selectedOrderNOs.includes(d));
      }
    });
  }
  //#endregion

  //#region 刪除選取工單
  const handleDelOrderNo = (no, index) => {
    setChooseOrderNo(prevChooseOrderNo => {
      return prevChooseOrderNo.filter(d => d !== no);
    });

    setChooseOrderID(prevChooseOrderID =>
      prevChooseOrderID.filter((_, i) => i !== index)
    );
  }
  //#endregion

  //#region 跳轉至工單頁面
  const handlePaused = () => {
    localStorage.setItem("filterOrderStatus", "paused");
    setCurrentTab("workOrders");
  }
  //#endregion

  //#region 清除按鈕
  const handleClear = () => {
    setChooseOrderID([]);
    setChooseOrderNo([]);
  }
  //#endregion

  //#region 加入排程
  const handleAddSchedule = async () => {
    handleClose();

    //檢查工單排程時長是否大於零
    const [httpStatus, reponseData] = await handleApiCheckProgramTimeZero();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        if (reponseData.data?.programName?.length > 0) {
          setUnableScheduleProgram(reponseData.data?.programName);
          setUnableScheduleModal(true);
        }
        else {
          let orderStatus = await loadOrderCountAndStatus(true); //已排程工單數量
          if (chooseOrderID.length > 1) {
            setIsChangeSchedule(false);
            setAutoSchedulingModal(true);
          }
          else if (chooseOrderID.length === 1) {
            await handleApiScheduleClearCache();
            setManualSchedulingModal(true);
            let filterOrderData = orderData?.detail?.find(d => d.orderID === chooseOrderID[0]);
            setManualOrderId(filterOrderData);

            if (orderStatus.scheduled > 0) {
              setIsChangeSchedule(true);
            }
            else if (orderStatus.scheduled === 0) {
              setIsChangeSchedule(false);
            }
          }
        }
      }
    }
    else {
      toast.error(reponseData.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  const handleApiResponse = (response) => {
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }

  //#region 載入工單列表API
  const handleApiCheckProgramTimeZero = async () => {
    let postJson = {
      orderID: chooseOrderID
    };

    let response = await apiCheckProgramTimeZero(postJson);
    return handleApiResponse(response);
  }
  //#endregion

  //#region 清除排程暫存資料API
  const handleApiScheduleClearCache = async () => {
    let response = await apiScheduleClearCache();
    return handleApiResponse(response);
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" size="xl" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{t("selectWorkOrder")}</div>
          </div>
          <button type="button" className="close"
            data-dismiss="modal" aria-label="Close" onClick={handleClose}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          <div className="status status-padding bg unscheduled">
            {t("unscheduled")}
          </div>
          <form className='tableCheckboxList mt-2'>
            <table className="category">
              <thead className="item-title">
                <tr>
                  <th className="inlineflex">
                    <div className="check"></div>
                    <div>{t("workOrderID")}</div></th>
                  <th className="">{t("part")}/{t("process")}</th>
                  <th className="">{t("scheduledDeliveryDate")}</th>
                  <th className="">{t("estDuration")}</th>
                </tr>
              </thead>
              <tbody className="item-group">
                {
                  orderData?.detail?.map((obj, index) => {
                    return (
                      <tr className="item" key={index}>
                        <td data-label={t("workOrderID")}>
                          <input type="checkbox" id={`orderID_${obj.orderID}`} name="dataSelected"
                            checked={!!chooseOrderID.find(d => d === obj.orderID)}
                            onClick={(e) => handleCheckboxChange(e, obj.orderID, obj.orderNo, obj.processNo)}
                          />                          
                          <label htmlFor={`orderID_${obj.orderID}`}>
                            {obj.orderNo}-{obj.processNo}
                            <div className="fill"></div>
                          </label>
                        </td>
                        <td data-label={`${t("part")} / ${t("process")}`}><b>{obj.wpNO}</b> {obj.wpName} / <b>{obj.processNo}</b> {obj.processName}</td>
                        <td data-label={t("scheduledDeliveryDate")}>
                          <div>
                            <b className="strong2">{convertDateFormat(obj.scheduledDeliveryDate, "MMM DD")}
                              {convertDateFormat(obj.scheduledDeliveryDate, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.scheduledDeliveryDate, "YYYY")}</> : null}</b>
                          </div>
                        </td>
                        <td data-label={t("estDuration")}>
                          {(() => {
                            const minEstimatedDuration = obj.minEstimatedDuration;
                            const maxEstimatedDuration = obj.maxEstimatedDuration;

                            if (minEstimatedDuration != null) {
                              return `${formatTimeDays(minEstimatedDuration, t)}${maxEstimatedDuration != null ? " ~ " + formatTimeDays(maxEstimatedDuration, t) : ""}`
                            }
                          })()}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            {
              orderCountAndStatus?.orderStatus?.paused > 0 ?
                <div className='pausedWorkOrderDiv'>
                  <div className='titleRow'>
                    <div className='title'>
                      {t("pausedWorkOrdersTitle", { num: orderCountAndStatus?.orderStatus?.paused })}
                    </div>
                    <div className='info'>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip className="custom-tooltip">
                            {t("pausedWorkOrdersInfo")}
                          </Tooltip>
                        }
                        trigger={['hover', 'focus']}
                      >
                        <div>
                          <span className="btn-item btn-onlyicon btn-inputdrop">
                            <div className="icon icon-info yellow"></div>
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div className='content'>
                    {t("pausedWorkOrdersContent")}
                  </div>
                  <div className='contentRow'>
                    <div className='link' onClick={handlePaused}>{t("pausedWorkOrderLink")}</div>
                  </div>
                </div> : null
            }
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="item-selected scroll">
            {chooseOrderNo.map((obj, objIndex) => (
              <div key={objIndex}>
                <div>
                  {obj}
                </div>
                <div className="cancel" onClick={() => handleDelOrderNo(obj, objIndex)}>
                  <div className="material-symbols-rounded">close</div>
                </div>
              </div>
            ))}
          </div>
          <div className="button-group">
            <div>
              <button className="button btn-txt-black" onClick={handleCancel}>
                {t("cancel")}
              </button>
              <button className="button btn-lv3" onClick={handleClear}>
                {t("clear")}
              </button>
            </div>
            <button className="button btn-lv1" onClick={handleAddSchedule} disabled={chooseOrderID.length === 0}>
              {t("addSchedule")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

      {/* 指定排程 */}
      <ManualScheduling
        showModal={manualSchedulingModal}
        handleClose={handleManualSchedulingClose}
        manualOrderId={manualOrderId}
        isChangeSchedule={isChangeSchedule}
        setAutoSchedulingModal={setAutoSchedulingModal}
        loadOrderList={loadOrderList}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
      />

      {/* 自動排程 */}
      <AutoScheduling
        showModal={autoSchedulingModal}
        handleClose={handleAutoSchedulingClose}
        chooseOrderID={chooseOrderID}
        loadOrderList={loadOrderList}
        isChangeSchedule={isChangeSchedule}
        setManualSchedulingModal={setManualSchedulingModal}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
        ratingCriteria={ratingCriteria}
      />

      {/* 無法進行排程 */}
      <UnableSchedule
        showModal={unableScheduleModal}
        handleClose={handleUnableScheduleClose}
        unableScheduleProgram={unableScheduleProgram}
      />

    </>
  );
};

export default SelectWorkOrder;