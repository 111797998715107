import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './modal.css';
import { MyUserContext } from 'contexts/MyUserContext';
import moment from 'moment';
import { convertDateFormat, weekCalc, encrypto } from 'utils/commonFun';

import SingleExecutionLogModal from "page/reports/data/modal/SingleExecutionLogModal";
import SelectProgramModal from "page/reports/data/modal/SelectProgramModal";

import { apiPowerConsumptionExecuteList, apiPowerConsumptionProgramList } from 'utils/Api';

const GenerateReportModal = ({ showModal, handleClose, content, setContent }) => {
  const { t } = useTranslation("reports");
  const navigate = useNavigate();
  const { overtime } = useContext(MyUserContext);

  const [reportType, setReportType] = useState("1");  //報告類型 1=>用電及碳排比較
  // const [content, setContent] = useState("1"); //內容 1=>單次執行比較 2=>程式比較

  // const [executionIdList, setExecutionIdList] = useState([]); //單次執行比較API-執行紀錄流水號
  // const [consumptionProgramList, setConsumptionProgramList] = useState([]); //程式比較API-(加工程式-使用設備)

  const [singleExecutionBehavior, setSingleExecutionBehavior] = useState(1); //執行紀錄列表行為 1:對照 2:比較
  const [showExecutionRef, setShowExecutionRef] = useState(null); //單次執行比較(對照)-執行紀錄列表(Modal詳細)
  const [showExecutionList, setShowExecutionList] = useState([]); //單次執行比較(比較)-執行紀錄列表(Modal詳細)

  const [programBehavior, setProgramBehavior] = useState(1); //程式比較行為 1:對照 2:比較
  const [showConsumptionProgramRef, setShowConsumptionProgramRef] = useState(null); //程式比較(對照)-加工程式-使用設備(Modal詳細)
  const [showConsumptionProgramList, setShowConsumptionProgramList] = useState([]); //程式比較(比較)-加工程式-使用設備(Modal詳細)

  //產生報告Modal
  const [showSingleExecutionLogModal, setShowSingleExecutionLogModal] = useState(false);  
  const handleSingleExecutionLogClose = () => setShowSingleExecutionLogModal(false);

  //選擇程式Modal
  const [showSelectProgramModal, setShowSelectProgramModal] = useState(false);  
  const handleSelectProgramClose = () => setShowSelectProgramModal(false);

  const [selectedDates, setSelectedDates] = useState(""); //切換日期
  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  const [judgeProgramComparison, setJudgeProgramComparison] = useState(false); //檢查程式比較裡面欄位是否有重複選擇

  //#region 初始載入
  useEffect(() => {
    if(showModal){
      //預載本週
      const calDate = moment().subtract(1, 'days'); //預先載入是昨天
      const [sundayThisWeek, saterdayThisWeek, isoWeekThisWeek] = weekCalc(calDate, 0);

      setSelectedDates({ startDate: sundayThisWeek.format('yyyy-MM-DD'), endDate: saterdayThisWeek.format('yyyy-MM-DD') });
    
      setJudgeCheckErr(false);
    }
  }, [showModal]);
  //#endregion

  //#region 新增比較程式
  const handleAdd = () => {
    if(content === "1"){
      setSingleExecutionBehavior(2);
      setShowSingleExecutionLogModal(true);
    }
    else{
      setProgramBehavior(2);
      setShowSelectProgramModal(true);
    }
  }
  //#endregion

  //#region 編輯對照程式
  const handleEdit = () => {
    if(content === "1"){
      setSingleExecutionBehavior(1);
      setShowSingleExecutionLogModal(true);
    }
    else{
      setProgramBehavior(1);
      setShowSelectProgramModal(true);
    }
  }
  //#endregion

  //#region 刪除程式
  const handleClear = (behavior, index) => {
    if(content === "1"){
      if(behavior === "reference"){
        setShowExecutionRef(null);
      }
      else{
        setShowExecutionList((prevList) => prevList.filter((item, idx) => idx !== index));
      }
    }
    else{
      if(behavior === "reference"){
        setShowConsumptionProgramRef(null);
      }
      else{
        setShowConsumptionProgramList((prevList) => prevList.filter((item, idx) => idx !== index));
      }
    }
  }
  //#endregion

  //#region 產生報告按鈕
  const handleGenerate = () => {
    setJudgeCheckErr(true);

    if(content === "1"){
      let err = false;
      if(showExecutionRef === null){
        err = true;
      }

      if(showExecutionList.length === 0){
        err = true;
      }

      if(showExecutionRef && showExecutionList.length > 0){
        let filter = showExecutionList.find(d => d.executionId === showExecutionRef.executionId);
        if(filter){
          err = true;
        }
      }

      if(!err){
        let executionIdList = [];
        executionIdList.push(showExecutionRef.executionId);
        for(let item of showExecutionList){
          executionIdList.push(item.executionId);
        }

        localStorage.setItem("reportContent", "1");
        localStorage.setItem("executeListRequest", JSON.stringify(executionIdList));
        localStorage.removeItem("programListRequest");
        // loadPowerConsumptionExecuteList();
        handleClose();

        navigate(`/Reports/${encrypto(`art_0`, 123, 25)}`);
      }
    }
    else{
      let err = false;
      if(showConsumptionProgramRef === null){
        err = true;
      }

      if(showConsumptionProgramList.length === 0){
        err = true;
      }

      if(!err){
        err = checkProgramList();
      }

      if(!err){
        let programList = [];
        programList.push({programID: showConsumptionProgramRef.programID, mid: showConsumptionProgramRef.mid});
        for(let item of showConsumptionProgramList){
          programList.push({programID: item.programID, mid: item.mid});
        }

        localStorage.setItem("reportContent", "2");
        localStorage.setItem("programListRequest", JSON.stringify(programList));
        localStorage.removeItem("executeListRequest");
        // loadPowerConsumptionProgramList();
        handleClose();

        navigate(`/Reports/${encrypto(`art_0`, 123, 25)}`);
      }
    }
  }
  //#endregion

  //#region 更換下拉選單內容
  const handleContent = (e) => {
    const { value } = e.target;
    setContent(value);
    setJudgeCheckErr(false);
  }
  //#endregion

  useEffect(() => {
    let result = checkProgramList();
    setJudgeProgramComparison(result);
  }, [showConsumptionProgramList, showConsumptionProgramRef]);

  //#region 檢查程式比較裡面是否有重複
  const checkProgramList = () => {
    let judgeRepet = false;

    if(showConsumptionProgramList.length > 0){
      //先與對照程式比對
      if(showConsumptionProgramRef !== null){
        let filter = showConsumptionProgramList.find(d => d.programID === showConsumptionProgramRef.programID && d.mid === showConsumptionProgramRef.mid);
        if(filter){
          judgeRepet = true;
        }
      }

      //比較程式內部比對
      for(let [index, item] of showConsumptionProgramList.entries()){
        let filter = showConsumptionProgramList.find((d, i) => d.programID === item.programID && d.mid === item.mid && i !== index);
        if(filter){
          judgeRepet = true;
          break;
        }
      }
    }

    return judgeRepet;
  }
  //#endregion

  // useEffect(() => {
  //   if(!showSingleExecutionLogModal && content === "1"){
  //     console.log("showExecutionRef", showExecutionRef)
  //     console.log("showExecutionList", showExecutionList)
  //   }
  // }, [showSingleExecutionLogModal, showExecutionRef, showExecutionList]);

  // useEffect(() => {
  //   if(!showSingleExecutionLogModal && content === "2"){
  //     console.log("showConsumptionProgramRef", showConsumptionProgramRef)
  //     console.log("showConsumptionProgramList", showConsumptionProgramList)
  //   }
  // }, [showSingleExecutionLogModal, showConsumptionProgramRef, showConsumptionProgramList]);

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("generateReport")}<br />
              <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="reportType">
                    <Form.Label className='form-label-custom'>
                      {t("reportType")}
                      <span className='text-danger ms-1'>*</span>
                    </Form.Label><br />
                    <Form.Select as="select">
                      <option value="1">{t("powerCarbonComparison")}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="reportType">
                    <Form.Label className='form-label-custom'>
                      {t("content")}
                      <span className='text-danger ms-1'>*</span>
                    </Form.Label><br />
                    <Form.Select as="select" value={content} onChange={handleContent}>
                      <option value="1">{t("singleExecutionComparison")}</option>
                      <option value="2">{t("programComparison")}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <p className='text-greyA'>{t("generateCaption")}</p>
              <Row>
                <Col xs={12}>
                  <Form.Label className='form-label-custom'>
                    {t("referenceProgram")}
                    <span className='text-danger ms-1'>*</span>
                  </Form.Label><br />
                  {
                    (!judgeCheckErr && (content === "1" && showExecutionRef === null)) || (!judgeCheckErr && (content === "2" && showConsumptionProgramRef === null)) ?
                    <div className={`border-gray p-2 cursor-pointer`} onClick={handleEdit}></div> : 
                    (judgeCheckErr && (content === "1" && showExecutionRef === null)) || (judgeCheckErr && (content === "2" && showConsumptionProgramRef === null)) ?
                      <div className={`border-red p-2 cursor-pointer`} onClick={handleEdit}></div> :
                      <div className={`border-gray p-2`}>
                      {
                        content === "1" && showExecutionRef ?
                          <Row>
                            <Col xs={11} className='cursor-pointer' onClick={handleEdit}>
                              <Row>
                                <Col xs={2} className='px-0'>
                                  
                                </Col>
                                <Col xs={10} lg={3} className='tCenter px-0'>
                                  {showExecutionRef.programName}
                                </Col>
                                <Col xs={12} lg={4} className='tCenter text-blue px-0'>
                                  {convertDateFormat(showExecutionRef.programTimeStart)}
                                </Col>
                                <Col xs={12} lg={3} className='tCenter text-blue px-0'>
                                  {showExecutionRef.equipmentSerialNumber}
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={1} className='d-flex justify-content-end'>
                              <span className="material-symbols-rounded-clear cursor-pointer" title="刪除" 
                                onClick={()=>handleClear("reference")}>cancel</span>
                            </Col>
                          </Row> : null
                      }
                      {
                        content === "2" && showConsumptionProgramRef ?
                          <Row>
                            <Col xs={11} className='cursor-pointer' onClick={handleEdit}>
                              <Row>
                                <Col xs={2} className='px-0'>
                                  
                                </Col>
                                <Col xs={10} lg={3} className='tCenter px-0'>
                                  {showConsumptionProgramRef.programName}
                                </Col>
                                <Col xs={12} lg={4} className='tCenter text-blue px-0'>
                                  {t("averageAllExecutionRecords")}
                                </Col>
                                <Col xs={12} lg={3} className='tCenter text-blue px-0'>
                                  {showConsumptionProgramRef.mName}
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={1} className='d-flex justify-content-end'>
                              <span className="material-symbols-rounded-clear cursor-pointer" title="刪除" 
                                onClick={()=>handleClear("reference")}>cancel</span>
                            </Col>
                          </Row> : null
                      }
                    </div>
                  }
                  {
                    (judgeCheckErr && (content === "1" && showExecutionRef === null)) || (judgeCheckErr && (content === "2" && showConsumptionProgramRef === null)) ?
                      <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                      <></>
                  }
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Button variant="outline-primary" className='d-flex justify-content-center' onClick={handleEdit}>
                    <span className="material-symbols-rounded mb-0">edit</span>{t("edit")}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12}>
                  <Form.Label className='form-label-custom'>
                    {t("comparisonProgram")}
                    <span className='text-danger ms-1'>*</span>
                  </Form.Label><br />
                  {
                    content === "1" && showExecutionList.length === 0 ?
                      <>
                        <div className={`${judgeCheckErr && showExecutionList.length === 0 ? "border-red" : "border-gray"} p-2 cursor-pointer`} onClick={handleAdd}></div>
                        {
                          judgeCheckErr && showExecutionList.length === 0 ?
                            <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                            <></>
                        }
                      </> :
                      null
                  }
                  {content === "1" && showExecutionList.length > 0 && showExecutionList.map((item, index) => {
                      return <React.Fragment key={index}>
                        <div className={`${judgeCheckErr && showExecutionRef && showExecutionRef.executionId === item.executionId ? "border-red" : "border-gray"} p-2 mb-1`}>
                          <Row>
                            <Col xs={11} className='cursor-pointer' onClick={handleAdd}>
                              <Row>
                                <Col xs={2} className='tCenter fw-bolder px-0'>
                                  {index + 1}
                                </Col>
                                <Col xs={10} lg={3} className='tCenter px-0'>
                                  {item.programName}
                                </Col>
                                <Col xs={12} lg={4} className='tCenter text-blue px-0'>
                                  {convertDateFormat(item.programTimeStart)}
                                </Col>
                                <Col xs={12} lg={3} className='tCenter text-blue px-0'>
                                  {item.equipmentSerialNumber}
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={1} className='d-flex justify-content-end'>
                              <span className="material-symbols-rounded-clear cursor-pointer" 
                                onClick={()=>handleClear("comparison", index)}>cancel</span>
                            </Col>
                          </Row>
                        </div>
                        {/* {
                          showExecutionList.slice(index + 1).find(d => d.executionId === item.executionId) ?
                            <Form.Label className='text-danger mb-2'>{t("selectedContentOverlaps")}</Form.Label> :
                            <></>
                        } */}
                        {
                          judgeCheckErr && showExecutionRef && showExecutionRef.executionId === item.executionId ?
                            <Form.Label className='text-danger mb-2'>{t("selectedContentOverlaps")}</Form.Label> :
                            <></>
                        }
                      </React.Fragment>;
                  })}
                  
                  {
                    content === "2" && showConsumptionProgramList.length === 0 ?
                      <>
                        <div className={`${judgeCheckErr && showConsumptionProgramList.length === 0 ? "border-red" : "border-gray"} p-2 cursor-pointer`} onClick={handleAdd}></div>
                        {
                          judgeCheckErr && showConsumptionProgramList.length === 0 ?
                            <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                            <></>
                        }
                      </> :
                      null
                  }
                  
                  {content === "2" && showConsumptionProgramList.length > 0 && showConsumptionProgramList.map((item, index) => {
                      return <React.Fragment key={index}>
                        <div className={`${judgeCheckErr && judgeProgramComparison ? "border-red" : "border-gray"} p-2 mb-1`}>
                          <Row>
                            <Col xs={11} className='cursor-pointer' onClick={handleAdd}>
                              <Row>
                                <Col xs={2} className='tCenter fw-bolder px-0'>
                                  {index + 1}
                                </Col>
                                <Col xs={10} lg={3} className='tCenter px-0'>
                                  {item.programName}
                                </Col>
                                <Col xs={12} lg={4} className='tCenter text-blue px-0'>
                                  {t("averageAllExecutionRecords")}
                                </Col>
                                <Col xs={12} lg={3} className='tCenter text-blue px-0'>
                                  {item.mName}
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={1} className='d-flex justify-content-end'>
                              <span className="material-symbols-rounded-clear cursor-pointer" 
                                onClick={()=>handleClear("comparison", index)}>cancel</span>
                            </Col>
                          </Row>
                        </div>
                        {/* {
                          judgeCheckErr && showConsumptionProgramRef && showConsumptionProgramRef.programID === item.programID && showConsumptionProgramRef.mid === item.mid ?
                            <Form.Label className='text-danger mb-2'>{t("selectedContentOverlaps")}</Form.Label> :
                            <></>
                        } */}
                        {
                          judgeCheckErr && judgeProgramComparison ?
                            <Form.Label className='text-danger mb-2'>{t("selectedContentOverlaps")}</Form.Label> :
                            <></>
                        }
                      </React.Fragment>;
                  })}
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Button variant="outline-primary" className={`d-flex justify-content-center ${(content === "1" && showExecutionList?.length >= 10) || (content === "2" && showConsumptionProgramList?.length >= 10) ? "disabled": ""}`} 
                    onClick={handleAdd}
                    disabled={(content === "1" && showExecutionList?.length >= 10) || (content === "2" && showConsumptionProgramList?.length >= 10)}>
                    <span className="material-symbols-rounded mb-0">add</span>{t("add")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <div className="modal-footer-custom">
          <div className="btn-gp">
              <div>
                <button className="btn btn-txt-black" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button className="btn btn-lv1 fat" onClick={handleGenerate}>
                  {t("generate")}
                </button>
              </div>
            </div>
          </div>
          {/* <Modal.Footer className="modal-footer-custom">
            <Row>
              <Col xs={6} >
                <button className="button-not-border" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </Col>
              <Col xs={6} className='d-flex justify-content-end'>
                <button className="button-primary" onClick={handleGenerate}>
                  {t("generate")}
                </button>
              </Col>
            </Row>
          </Modal.Footer> */}
        </Modal>

        <ToastContainer />

        {/* 選擇單次執行紀錄 */}
        <SingleExecutionLogModal 
          showModal={showSingleExecutionLogModal}
          handleClose={handleSingleExecutionLogClose} 
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          // executionIdList={executionIdList}
          // setExecutionIdList={setExecutionIdList}
          singleExecutionBehavior={singleExecutionBehavior}
          showExecutionRef={showExecutionRef}
          setShowExecutionRef={setShowExecutionRef}
          showExecutionList={showExecutionList}
          setShowExecutionList={setShowExecutionList}
        />

        {/* 選擇程式比較 */}
        <SelectProgramModal
          showModal={showSelectProgramModal}
          handleClose={handleSelectProgramClose} 
          programBehavior={programBehavior}
          showConsumptionProgramRef={showConsumptionProgramRef}
          setShowConsumptionProgramRef={setShowConsumptionProgramRef}
          showConsumptionProgramList={showConsumptionProgramList}
          setShowConsumptionProgramList={setShowConsumptionProgramList}
          // consumptionProgramList={consumptionProgramList}
          // setConsumptionProgramList={setConsumptionProgramList}
        />
    </>
  );
};

export default GenerateReportModal;