import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Row, Col } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { getNextSunday, getThisSunday } from 'utils/commonFun';
import { apiMachineListData, apiIdeaLogin, apiGetWorkHours } from 'utils/Api';
import './Analytics.css';
import { MyUserContext } from 'contexts/MyUserContext';
import { WeekPicker } from 'components/weekPicker/WeekPicker';
import { startOfWeek, endOfWeek } from "date-fns";

import Tab from 'components/basic/tab';
import UtilizationRateShift from "page/analytics/data/utilizationRateShift/UtilizationRateShift"; //機台稼動率(班別)
import UtilizationRate from "page/analytics/data/utilizationRate/UtilizationRate"; //設備稼動
import OverallEquipmentEfficiency from "page/analytics/data/overallEquipmentEfficiency/overallEquipmentEfficiency"; //設備整體效能 (OEE)
import RunningTimeShift from "page/analytics/data/runningTimeShift/runningTimeShift"; //班別運轉時長

const AnalyticsMain = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  let { tabName } = useParams();

  const { overtime, checkLogin, authority, permission, loadRefreshJob, judgeLoadToday } = useContext(MyUserContext);

  const [currentSelect, setCurrentSelect] = useState(0); //0=>本周  1=>上周

  const [tabList, setTabList] = useState([]);
  const [choiceMachine, setChoiceMachine] = useState(null); //目前選擇的機台

  const [showSelDateRange, setShowSelDateRange] = useState(""); //顯示下拉選單選擇

  const [selDateArray, setSelDateArray] = useState([]);  //下拉選單內容(日期區間)
  // const [thisWeek, setThisWeek] = useState({isoWeek: "", rangeDate: "", startDate: "", endDate: ""}); //本周
  // const [lastWeek, setLastWeek] = useState({isoWeek: "", rangeDate: "", startDate: "", endDate: ""}); //上周

  const [startDate, setStartDate] = useState(""); //開始日期
  const [endDate, setEndDate] = useState(""); //結束日期
  const [machineList, setMachineList] = useState({}); //機台列表

  const [week, setWeek] = useState(() => {
    const storedStartDate = localStorage.getItem("analytics_startDate");
    const storedEndDate = localStorage.getItem("analytics_endDate");

    if (storedStartDate && storedEndDate) {
      // 使用 localStorage 中的日期來初始化
      return {
        firstDay: startOfWeek(new Date(storedStartDate), { weekStartsOn: 0 }),
        lastDay: endOfWeek(new Date(storedEndDate), { weekStartsOn: 0 })
      };
    } else {
      // 沒有 localStorage 資料時，使用當前日期來初始化
      return {
        firstDay: startOfWeek(new Date(), { weekStartsOn: 0 }),
        lastDay: endOfWeek(new Date(), { weekStartsOn: 0 })
      };
    }
  });

  const [workHours, setWorkHours] = useState(null); //顯示上班時間

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      //#region 抓取上班時間
      const [httpStatusWorkHours, reponseDataWorkHours] = await handleApiGetWorkHours();
      if (httpStatusWorkHours == "200") {
        if (reponseDataWorkHours.statusCode === "20000") {
          setWorkHours(reponseDataWorkHours.data);
        }
      }
      //#endregion
    }
    fetchData();
  }, []);
  //#endregion

  //#region 抓取上班時間
  const handleApiGetWorkHours = async () => {
    let workHoursResponse = await apiGetWorkHours();
    if (workHoursResponse) {
      const httpStatus = workHoursResponse.request.status;
      const reponseData = workHoursResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    localStorage.setItem("analytics_startDate", moment(week.firstDay).format("YYYY-MM-DD"));
    localStorage.setItem("analytics_endDate", moment(week.lastDay).format("YYYY-MM-DD"));
    setStartDate(moment(week.firstDay).format("YYYY-MM-DD"));
    setEndDate(moment(week.lastDay).format("YYYY-MM-DD"));
  }, [week]);

  //#region 初始載入
  useEffect(() => {
    checkLogin();
  }, [judgeLoadToday]);
  //#endregion

  useEffect(() => {
    if (workHours != null) {
      let newTabList = [];
      let defaultTabs = [
        {
          tabName: "OverallEquipmentEfficiency",
          active: tabName === "OverallEquipmentEfficiency"
        },
        {
          tabName: "EquipmentUtilization",
          active: tabName === "EquipmentUtilization"
        }
      ];

      if (workHours.shiftsAnalysis) {
        defaultTabs.push({
          tabName: "EquipmentUtilizationShifts",
          active: tabName === "EquipmentUtilizationShifts"
        });
      }
      else {
        if (location.pathname === "/Analytics/EquipmentUtilizationShifts") {
          navigate(`/Analytics/OverallEquipmentEfficiency`);
        }
      }

      if (permission !== null) {
        let filterMainTab = permission.find(d => d.tabName === "Analytics");
        if (filterMainTab) {
          for (let item of filterMainTab.tabList) {
            const tab = defaultTabs.find(tab => tab.tabName === item);
            if (tab) newTabList.push(tab);
          }
        }
        else {
          newTabList = defaultTabs;
        }
      }
      else {
        newTabList = defaultTabs;
      }
      setTabList(newTabList);
    }
  }, [permission, workHours]);

  useEffect(() => {
    if (authority?.userAccount != null) {
      const fetchData = async () => {
        const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
        if (httpStatusMachine == "200") {
          if (reponseDataMachine.statusCode === "20000") {
            let artMachine = reponseDataMachine.data;
            let newMachineList = [];
            const [httpStatus, reponseData] = await handleApiIdeaLogin();
            if (httpStatus === 200) {
              let ideaMachine = reponseData?.artUserInfo?.owingMachines;
              for (let item of ideaMachine) {
                let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                if (filter) {
                  newMachineList.push(filter);
                }
              }
            }
            else {
              newMachineList = artMachine;
            }

            setMachineList(newMachineList);

            if (localStorage.getItem("choiceMachine") !== null) {
              setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
            }
            else {
              if (newMachineList.length > 0) {
                localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                setChoiceMachine(newMachineList[0]);
              }
              else {
                localStorage.removeItem("choiceMachine");
                setChoiceMachine(null);
              }
            }
          }
          else if (reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104") {
            overtime();
          }
        }
      };
      fetchData();
    }
  }, [authority]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if (filter) {
      navigate(`/Analytics/${filter.tabName}`);
    }
  }, [tabList]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if (machineListResponse) {
      const httpStatus = machineListResponse.request.status;
      const reponseData = machineListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 機台下拉選單
  const handleDropdownSelect = (obj) => {
    setChoiceMachine(obj);
    localStorage.setItem("choiceMachine", JSON.stringify(obj));
  };
  //#endregion

  //#region 計算日期區間(舊版寫法)
  const handleDateRange = (index) => {
    setCurrentSelect(index);
    localStorage.setItem("analytics_currentSelect", index);

    setStartDate(selDateArray[index].startDate);
    setEndDate(selDateArray[index].endDate);

    localStorage.setItem("analytics_startDate", selDateArray[index].startDate);
    localStorage.setItem("analytics_endDate", selDateArray[index].endDate);

    if (index === 0) {
      setShowSelDateRange(t("dateRangeChioce1", { isoWeek: selDateArray[index].isoWeek, rangeDate: `${moment(selDateArray[index].startDate).format('MMM D')} - ${moment(selDateArray[index].endDate).format('MMM D, yyyy')}` }));
    }
    else if (index == 1) {
      setShowSelDateRange(t("dateRangeChioce2", { isoWeek: selDateArray[index].isoWeek, rangeDate: `${moment(selDateArray[index].startDate).format('MMM D')} - ${moment(selDateArray[index].endDate).format('MMM D, yyyy')}` }));
    }
    else {
      setShowSelDateRange(t("dateRangeChioce3", { isoWeek: selDateArray[index].isoWeek, rangeDate: `${moment(selDateArray[index].startDate).format('MMM D')} - ${moment(selDateArray[index].endDate).format('MMM D, yyyy')}` }));
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');

    let ideaLoginResponse = await apiIdeaLogin(formData);

    if (ideaLoginResponse) {
      const httpStatus = ideaLoginResponse.request.status;
      const reponseData = ideaLoginResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  return (
    <>
      <div className='analyticsHeader box-style-white-shadow'>
        <div className="filterRow">
          <div>
            {
              machineList.length > 0 ?
                <div className="input-rightpadding h38">
                  <button className="input outline fit-content h-100" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <b>{choiceMachine.mName}</b>
                  </button>
                  <div className="icon icon-Dropdown bg-greyA small"></div>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                      machineList.map((obj, index) => {
                        return <a className="dropdown-item cursor-pointer" onClick={() => handleDropdownSelect(obj)} key={index}>{obj.mName}</a>;
                      })
                    }
                  </div>
                </div>
                :
                <div className="input-rightpadding h38">
                  <button className="input outline fit-content h-100" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <b>{t("noEquipment")}</b>
                  </button>
                  <div className="icon icon-Dropdown bg-greyA small"></div>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item cursor-pointer">{t("noEquipment")}</a>
                  </div>
                </div>
            }
          </div>
          <div>
            <WeekPicker week={week} setWeek={setWeek} disableAfterDate={new Date(getNextSunday())} />
            {
              authority?.role !== "sysAdmin" ?
                <button className={`btn btn-txtBlue-bgLightBlue ms-2 btn-icontext ${localStorage.getItem("runJob") == null ? "" : "disabled"}`} onClick={() => loadRefreshJob(true)}
                  disabled={localStorage.getItem("runJob") != null}>
                  <div className={`icon icon-UpdateToNow blue`}></div>
                  <div className="text">{t("updateNOW")}</div>
                </button> : null
            }
          </div>
        </div>
      </div>
      <Tab tabList={tabList} setTabList={setTabList} />
      {
        tabList?.find(d => d.active && d.tabName === "EquipmentUtilization") ?
          <UtilizationRate choiceMachine={choiceMachine} startDate={startDate} endDate={endDate} /> :
          null
      }
      {
        tabList?.find(d => d.active && d.tabName === "OverallEquipmentEfficiency") ?
          <OverallEquipmentEfficiency choiceMachine={choiceMachine} showSelDateRange={showSelDateRange}
            startDate={startDate} endDate={endDate} /> :
          null
      }
      {
        tabList?.find(d => d.active && d.tabName === "EquipmentUtilizationShifts") ?
          <RunningTimeShift choiceMachine={choiceMachine} showSelDateRange={showSelDateRange}
            startDate={startDate} endDate={endDate} /> :
          null
      }
    </>
  );
};

export default AnalyticsMain;