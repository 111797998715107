import axios from 'axios';

const apiUrl = window.apiUrl;
const ideaApiUrl = window.ideaApiUrl;

let getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': 'Bearer ' + getAuthToken(),
        // 'Language': getLanguage(),
    }
}

let getUploadHeaders = () => {
    return {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        // 'Authorization': 'Bearer ' + getAuthToken(),
        // 'Language': getLanguage(),
    }
}

export const fetchDataCall = async (api, method, payload = null, showLoadCss = true, download = false) => {
    if(showLoadCss){
        document.querySelector(".loading-circle-div").classList.remove('d-none');
    }
    
    var config = {
        method: method,
        url: `${apiUrl}/api/${api}`,
        headers: getHeaders(),
        params: null,
        data: null,
        withCredentials: true
    };

    if (method == "get") {
        if (payload != null) {
            Object.keys(payload).forEach((key) => {
                if (payload[key] === "") {
                    delete payload[key];
                }
            });
        }
        config.params = payload;
    } else {
        config.data = payload;
    }

    let apiReturn = await axios.request(config)
        .then(async function (response) {
            document.querySelector(".loading-circle-div").classList.add('d-none');
            return response;
        })
        .catch(function (error) {
            console.log(error)
        });
    
    document.querySelector(".loading-circle-div").classList.add('d-none');    
    return apiReturn;
};

export const fetchUploadDataCall = async (api, method, payload = null) => {
    document.querySelector(".loading-circle-div").classList.remove('d-none');
    var config = {
        method: method,
        url: `${apiUrl}/api/${api}`,
        headers: getUploadHeaders(),
        params: null,
        data: null,
        withCredentials: true
    };

    if (method == "get") {
        if (payload != null) {
            Object.keys(payload).forEach((key) => {
                if (payload[key] === "") {
                    delete payload[key];
                }
            });
        }
        config.params = payload;
    } else {
        config.data = payload;
    }

    let apiReturn = await axios.request(config)
        .then(async function (response) {
            document.querySelector(".loading-circle-div").classList.add('d-none');
            return response;
        })
        .catch(function (error) {
            document.querySelector(".loading-circle-div").classList.add('d-none');
            //console.log(error);
        });
    return apiReturn;
};

export const fetchFormDataCall = async (api, method, payload = null, download = false) => {
    document.querySelector(".loading-circle-div").classList.remove('d-none');
    var config = {
        method: method,
        url: `${ideaApiUrl}${api}`,
        headers: getUploadHeaders(),
        params: null,
        data: null,
        withCredentials: false
    };

    if (method == "get") {
        if (payload != null) {
            Object.keys(payload).forEach((key) => {
                if (payload[key] === "") {
                    delete payload[key];
                }
            });
        }
        config.params = payload;
    } else {
        config.data = payload;
    }

    let apiReturn = await axios.request(config)
        .then(async function (response) {
            document.querySelector(".loading-circle-div").classList.add('d-none');
            return response;
        })
        .catch(function (error) {
            document.querySelector(".loading-circle-div").classList.add('d-none');
        });
    return apiReturn;
};

//#region 工件計算機
export const apiQueryWorkPieceData = data => fetchDataCall("workpieceCost/query/Workpiece", "post", data);  //查詢工件列表
export const apiWorkPieceOptions = data => fetchDataCall("workpieceCost/options", "get", data);  //取得類別、材質以及尺寸(下拉選單)
export const apiWorkPieceCostBatch = data => fetchDataCall("workpieceCost/query/WorkpieceCostBatch", "post", data);  //取得工件詳細成本計算資料
export const apiSpreadsheet = data => fetchUploadDataCall("workpieceCostUpload/spreadsheet", "post", data);  //上傳Excel文件
export const apiGetWorkpiece = data => fetchDataCall("workpieceCost/Workpiece", "get", data);  //工件列表(上傳圖片用)
export const apiUploadImage = data => fetchUploadDataCall("images/workpiece", "post", data);  //上傳圖片
//#endregion

//#region 設定檔
export const apiGetWorkDays = data => fetchDataCall("utilizationRate/setting/workDays", "get", data); //顯示工作日
export const apiPutWorkDays = data => fetchDataCall("utilizationRate/setting/workDays", "put", data); //儲存工作日
export const apiGetMachineOperatingTime = data => fetchDataCall("utilizationRate/setting/machineOperatingTime", "get", data); //顯示機台運轉時間
export const apiPutMachineOperatingTime = data => fetchDataCall("utilizationRate/setting/machineOperatingTime", "put", data); //儲存機台運轉時間
export const apiGetWorkHours = data => fetchDataCall("utilizationRate/setting/workHours", "get", data); //顯示上班時間
export const apiPutWorkHours = data => fetchDataCall("utilizationRate/setting/workHours", "put", data); //儲存上班時間
export const apiGetRatingCriteria = data => fetchDataCall("utilizationRate/setting/ratingCriteria", "get", data); //顯示評級標準
export const apiPutRatingCriteria = data => fetchDataCall("utilizationRate/setting/ratingCriteria", "put", data); //儲存評級標準
export const apiGetMisc = data => fetchDataCall("utilizationRate/setting/misc", "get", data); //顯示其他設定
export const apiPutMisc = data => fetchDataCall("utilizationRate/setting/misc", "put", data); //儲存其他設定
//#endregion

//#region 機台稼動率
export const apiMachineListData = data => fetchDataCall("utilizationRate/machineList", "get", data);  //列出所有機台資訊
export const apiDailySummaryData = data => fetchDataCall("utilizationRate/dailySummary", "post", data);  //顯示稼動率(Summary)
export const apiDailyShiftData = data => fetchDataCall("utilizationRate/dailyShift", "post", data);  //顯示稼動率(Day)
export const apiShutDownDailyShiftData = data => fetchDataCall("utilizationRate/shutDownDailyShift", "post", data);  //顯示停機(Day)
export const apiDailyShiftStatusDurationData = data => fetchDataCall("utilizationRate/dailyShiftStatusDuration", "post", data);  //顯示各班別長條圖稼動率
export const apiShutDownEventsData = data => fetchDataCall("utilizationRate/shutDownEvents", "post", data);  //錯誤停機事件列表
export const apiWorkpiecePgmDurationData = data => fetchDataCall("utilizationRate/workpiecePgmDuration", "post", data);  //加工程序運轉時長
export const apiDailyProgramDuration = data => fetchDataCall("utilizationRate/dailyProgramDuration", "post", data);  //顯示加工程式長條圖
//#endregion

//#region 登入登出
export const apiCSRF = data => fetchDataCall("admin/csrf_token", "get", data); //取得CSRF
export const apiLogin = data => fetchDataCall("admin/user/login", "post", data); //登入
export const apiQueryAuthority = data => fetchDataCall("admin/user/queryAuthority", "post", data, false); //權限
export const apiLogout = data => fetchDataCall("admin/user/logout", "post", data); //登出
export const apiValidateToken = data => fetchDataCall("admin/user/validateToken", "post", data); //驗證啟用使用者Token
export const apiSetPwd = data => fetchDataCall("admin/user/setPwd", "post", data); //設定密碼
export const apiForgotPwd = data => fetchDataCall("admin/user/forgotPWD", "post", data); //忘記密碼
export const apiIdeaLogin = data => fetchFormDataCall("/artUserLogin", "post", data); //艾創點登入
//#endregion

//#region 原始數據
export const apiRawDataSet = data => fetchDataCall(`utilizationRate/setting/rawData/${data}`, "get", null); //原始數據表頭設定檔
export const apiRawDataDailyShiftStatusDuration = data => fetchDataCall("utilizationRate/dailyShiftStatusDuration/rawData", "post", data); //原始數據內容(各班別的設備運轉時間)
export const apiRawDataEquipmentRuntime = data => fetchDataCall("utilizationRate/equipmentRuntime/rawData", "post", data); //原始數據內容(設備運轉)
export const apiRawDataEquipmentRuntimePlannedHours = data => fetchDataCall("utilizationRate/equipmentRuntime/plannedHours", "put", data); //原始數據內容(修改設備運轉)
export const apiRawDataWorkpieceProduction = data => fetchDataCall("utilizationRate/workpieceProduction/rawData", "post", data); //原始數據內容(工件生產)
export const apiRawDataDefectiveCount = data => fetchDataCall("utilizationRate/workpieceProduction/defectiveCount", "put", data); //修改原始數據內容(工件生產)
export const apiRawDataPowerCarbon = (data, showLoadCss) => fetchDataCall("carbonFactor/rawData", "post", data, showLoadCss); //原始數據內容(用電量)
//#endregion

//#region 加工程式
export const apiProgramList = data => fetchDataCall(`program/list`, "post", data); //加工程式列表
export const apiPostProgram = data => fetchDataCall(`program`, "post", data); //新增以及編輯加工程式
export const apiSingleLoadProgram = (programID, wpID) => fetchDataCall(`program/${programID}`, "post", wpID); //載入單一一筆加工程式
export const apiDelProgram = data => fetchDataCall(`program`, "delete", data); //刪除一筆加工程式
//#endregion

//#region 工件
export const apiWorkpieceList = data => fetchDataCall(`workpiece/list`, "post", data); //工件列表
export const apiPostWorkpiece = data => fetchUploadDataCall(`workpiece`, "post", data); //新增以及編輯工件
export const apiSingleLoadWorkpiece = data => fetchDataCall(`workpiece/${data}`, "get", null); //載入單一一筆工件
export const apiDelWorkpiece = data => fetchDataCall(`workpiece`, "delete", data); //刪除一筆工件
export const apiKeywordWorkpiece = data => fetchDataCall(`keyword/workpiece`, "get", null); //關鍵字列表
export const apiPostKeywordWorkpiece = data => fetchDataCall(`keyword/workpiece`, "post", data); //新增以及編輯關鍵字
export const apiOptionCategory = data => fetchDataCall(`option/category`, "get", null); //工件類別列表
export const apiPostOptionCategory = data => fetchDataCall(`option/category`, "post", data); //新增以及編輯工件類別
export const apiDelOptionCategory = data => fetchDataCall(`option/category`, "delete", data); //刪除一筆工件類別
export const apiWorkpieceUnique = data => fetchDataCall(`workpiece/isUnique`, "post", data, false); //檢查工件以及工序是否唯一值
//#endregion

//#region OEE
export const apiOEE = data => fetchDataCall(`utilizationRate/OEEProgramsData`, "post", data); //OEE
export const apiQuantityOEE = data => fetchDataCall("utilizationRate/OEEProgramsData/QuantityInformation", "post", data); //計算每天生產工件數量
//#endregion

//#region 工件用電
export const apiPowerWorkpieceList = data => fetchDataCall(`powerConsumption/workpieceList`, "post", data); //工件用電列表
export const apiPowerProgramList = data => fetchDataCall(`powerConsumption/programList`, "post", data); //加工程式用電列表
export const apiPowerProgramExecList = data => fetchDataCall(`powerConsumption/program/executeList`, "post", data); //工件的加工程式明細
export const apiPowerKwList = data => fetchDataCall(`powerConsumption/powerKwList`, "post", data); //用電加工程式圖表(功率)
export const apiPowerEqpStatusList = data => fetchDataCall(`powerConsumption/program/eqpStatus`, "post", data); //用電加工程式圖表(設備運轉狀態)
export const apiPowerSubProgramList = data => fetchDataCall(`powerConsumption/program/subProgramList`, "post", data); //用電加工程式圖表(副程式時間軸)
export const apiPowerSubErrorList = data => fetchDataCall(`powerConsumption/program/errorList`, "post", data); //用電加工程式圖表(錯誤發生時間軸)
export const apiPowerExecutionDetail = data => fetchDataCall(`powerConsumption/executionDetail/${data}`, "get", null);  //用電量紀錄流水號明細
//#endregion

//#region 報告
export const apiPowerConsumptionExecuteList = data => fetchDataCall(`powerConsumption/comparison/executeList`, "post", data); //單次執行比較報告
export const apiPowerConsumptionProgramList = data => fetchDataCall(`powerConsumption/comparison/programList`, "post", data); //程式比較報告
export const apiPowerConsumptionReportList = data => fetchDataCall(`powerConsumption/comparison/reportList`, "post", data); //報告列表
export const apiPowerConsumptionReportDetail = data => fetchDataCall(`powerConsumption/comparison/reportDetail`, "post", data); //載入單一一筆報告
export const apiPowerConsumptionReport = data => fetchDataCall(`powerConsumption/comparison/report`, "post", data); //儲存比較報告
export const apiPowerConsumptionDelReport = data => fetchDataCall(`powerConsumption/comparison/report`, "delete", data); //刪除報告
//#endregion

//#region 碳排放係數
export const apiCarbonFactorListData = data => fetchDataCall("carbonFactor/list", "post", data);  //電力碳排系數設定列表
//#endregion

//#region 擷取時間區間
export const apiPeriodReportListData = data => fetchDataCall("powerConsumption/periodReportList", "post", data);  //載入手動擷取程式用電量分析報告清單
export const apiPostPeriodReport = data => fetchDataCall("powerConsumption/periodReport", "post", data, data?.action === "checkReportName");  //新增以及修改手動擷取程式用電量分析報告
export const apiSinglePeriodReport = data => fetchDataCall("powerConsumption/periodReportDetail", "post", data);  //載入一筆手動截取程式用電量分析報告
export const apiCreateWorkpiece = data => fetchDataCall("powerConsumption/createWorkpieceOrProgram", "post", data, false);  //新增工件及加工程式
export const apiQuerySettingData = data => fetchDataCall("admin/querySetting", "post", data); //設定檔(全站)
//#endregion

//#region 分析按鈕
export const apiJobStatus = () => fetchDataCall("utilizationRate/getJobStatus", "get", null);  //取得 Job 狀態
//#endregion

//#region 機台訊息
export const apiEquimentMessage = data => fetchDataCall("equipmentMessage/list", "post", data);  //查詢機台訊息
//#endregion

//#region 歷史數據
export const apiMetricsList = data => fetchDataCall("metrics/metricslist", "post", data);  //載入Metrics列表
export const apiDatalist = data => fetchDataCall("metrics/datalist", "post", data, false);  //查詢Metrics資料(X軸時間戳用)
export const apiDatalistOriginal = data => fetchDataCall("metrics/datalistOriginal", "post", data, false);  //查詢Metrics資料
//#endregion

//#region APS
//#region 資料夾
export const apiProcessList = data => fetchDataCall("process/list", "post", data);  //載入工序管理列表
export const apiSingleProcess = data => fetchDataCall(`process/processDetail`, "post", data);  //載入一筆工序
export const apiAddProcess = data => fetchDataCall(`process`, "post", data);  //新增工序
export const apiEditProcess = data => fetchDataCall(`process`, "put", data);  //編輯工序
export const apiDelProcess = data => fetchDataCall(`process`, "delete", data);  //刪除工序
export const apiCheckProcess = data => fetchDataCall(`process/checkProcessIsUsed`, "post", data, false);  //檢查工序號是否已經被使用
export const apiCreatedProcessList = data => fetchDataCall(`process/listProcessByWpID`, "post", data);  //取得該工件已建立工序
//#endregion

//#region 工單管理
export const apiOrderList = (data, showLoad = true) => fetchDataCall("order/list", "post", data, showLoad);  //載入工單排程列表
export const apiOrderCountAndStatus = () => fetchDataCall("order/orderCountAndStatus", "get", null);  //工單數量&狀態數量表
export const apiSingleOrder = data => fetchDataCall("order/orderDetail", "post", data);  //取得一筆工單資料
export const apiCheckOrderNo = data => fetchDataCall("order/checkOrderNoIsUsed", "post", data, false);  //檢查工單號是否已經被使用
export const apiListOrderByOrderNo = data => fetchDataCall(`order/listOrderByOrderNo`, "post", data, false);  //取得該工單關聯工單
export const apiAddEditOrder = data => fetchDataCall(`order`, "post", data);  //新增工單/編輯工單
export const apiChangeOrderOperate = data => fetchDataCall(`order/operate`, "put", data);  //工單狀態更新
//#endregion

//#region 工單排程
export const apiOrderStatus = data => fetchDataCall(`order/status`, "put", data);  //工單狀態更新
export const apiSchedulePreview = data => fetchDataCall(`order/schedule/preview`, "post", data);  //預覽排程
export const apiScheduleConfirm = data => fetchDataCall(`order/schedule/confirm`, "post", data);  //確定排程
export const apiScheduleContinue = data => fetchDataCall(`order/schedule/continue`, "post", data);  //繼續生產
export const apiScheduleCancel = data => fetchDataCall(`order/schedule/cancel`, "post", data);  //取消排程
export const apiCheckProgramTimeZero = data => fetchDataCall(`order/checkProgramTimeZero`, "post", data);  //檢查工單排程時長
export const apiScheduleClearCache = data => fetchDataCall(`order/schedule/clearCache`, "post", data);  //檢查工單排程時長
//#endregion

//#region 儲位
export const apiStorageList = data => fetchDataCall(`order/storageList`, "post", data);  //儲位列表
//#endregion

//#region 甘特圖
export const apiGanttChartGroupByMachine = data => fetchDataCall(`order/list/groupByMachine`, "post", data);  //甘特圖(針對設備)
//#endregion
//#endregion