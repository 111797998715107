import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useNavigate  } from 'react-router-dom';
import './PageErr404.css';
import { MyUserContext } from 'contexts/MyUserContext';

const PageErr404 = ({judgeBackHome = true, backURL = "/"}) => {
  const { t, i18n } = useTranslation("common");  
  const navigate = useNavigate();
  // const { lang } = useContext(MyUserContext);

  //獲取所有cookie
  const cookies = document.cookie;

  //解析cookie字串
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  //使用函示抓取cookie
  const myCookie = getCookie('lang');
  
  useEffect(() => {
   i18n.changeLanguage(myCookie);
  }, []);

  return (
    <div className='pageErrDiv'>
      <div>
        <img src="/images/img_404page.svg" className='pageErrImg' />
        <p className='pageErrTitle'>{judgeBackHome ? t("pageNotFound") : t("itemNotFound")}</p>
        <p className='pageErrContent'>{judgeBackHome ? t("pageNotFound1") : t("itemNotFound1")}</p>
        <div className='pageErrButton'>
        <button type="button" className="btn btn-lv1 h38" onClick={()=>navigate(backURL)}>
            {judgeBackHome ? t("backToHome") : t("backToList")}
          </button>
          {/* <button type="button" className="button-export-Workpiece" onClick={()=>navigate(backURL)}>
            {judgeBackHome ? t("backToHome") : t("backToList")}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default PageErr404;
