import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { formatDateTimeRange, isoWeekCalc, secondsToHours, decimalWithOneDecimal, judgeUtilizationRate } from 'utils/commonFun';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';

//#region OEE概要
const Summary = (props) => {
    const { startDate, endDate, quantityProducedWorkpieces, nonDefectiveQuantityWorkpieces, idealProductionHours,
        plannedEquipmentLoadingTime, equipmentRuntimeRawDataMachining, ratingCriteria, productionPerformanceNumerator,
        productionPerformanceDenominator, machineOperatingTime, machineOperatingHours } = props;

    const { t, i18n } = useTranslation("overallEquipmentEfficiency");
    const { lang, showFormatDate, showFormatTime } = useContext(MyUserContext);
    const navigate = useNavigate();
    const [transPeriodDate, setTransPeriodDate] = useState("");

    useEffect(() => {
        let trans = formatDateTimeRange(showFormatDate, null, startDate, endDate);
        setTransPeriodDate(`${trans.startDate} ${trans.startTime} ~ ${trans.endDate} ${trans.endTime}`);
    }, [showFormatDate, showFormatTime, lang]);

    const handlePlannedIdealProductionHours = () => {
        navigate("/Folder/Programs");
    }

    const handlePlannedEquipmentLoadingTime = () => {
        localStorage.setItem("rawData_startDate", startDate);
        localStorage.setItem("rawData_endDate", endDate);
        navigate("/RawData/EquipmentRuntime");
    }

    const handleWorkpieceProduction = () => {
        localStorage.setItem("rawData_judgeEdit", true);
        localStorage.setItem("rawData_startDate", startDate);
        localStorage.setItem("rawData_endDate", endDate);
        navigate("/RawData/WorkpieceProduction");
    }

    return (
        <>
            {/* <!--第1排 Summary 全小藍框--> */}
            <div className="flex-box-111111 mtop10">
                {/* <!--第1排｜1號框--> */}
                <div className="boxstyle-blue littlebox">
                    <div>
                        <div className="title">{t("selectedPeriod")}</div>
                        <div>
                            <div>
                                <span className="score">{t("weekNum", { e: isoWeekCalc(startDate) })}</span>
                            </div>
                            <div>({transPeriodDate})</div>
                        </div>
                    </div>
                </div>
                {/* <!--第1排｜2號框--> */}
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip className="custom-tooltip">
                            <span dangerouslySetInnerHTML={{ __html: t("quantityProducedWorkpiecesToolTip") }} />
                        </Tooltip>
                    }
                >
                    <div className="boxstyle-blue littlebox">
                        <div>
                            <div className="title">{t("quantityProducedWorkpieces")}</div>
                            <div>
                                <div>
                                    <span className="score">{quantityProducedWorkpieces}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OverlayTrigger>


                {/* <!--第1排｜3號框--> */}
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip className="custom-tooltip">
                            <span dangerouslySetInnerHTML={{ __html: t("nonDefectiveQuantityProducedWorkpiecesToolTip") }} />
                        </Tooltip>
                    }
                >
                    <div className="boxstyle-blue littlebox">
                        <div>
                            <div className="title">{t("nonDefectiveQuantityProducedWorkpieces")}</div>
                            <div>
                                <div>
                                    <span className="score">{nonDefectiveQuantityWorkpieces}</span>
                                </div>
                                <button className="btn-littlebox" onClick={handleWorkpieceProduction}>
                                    {/* <div className="material-symbols-rounded">edit</div> */}
                                    <div className="icon icon-Edit"></div>
                                    <div className="text">{t("edit")}</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </OverlayTrigger>


                {/* <!--第1排｜4號框--> */}
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip className="custom-tooltip">
                            <span dangerouslySetInnerHTML={{ __html: t("idealProductionTimeToolTip") }} />
                        </Tooltip>
                    }
                >
                    <div className="boxstyle-blue littlebox">
                        <div>
                            <div className="title">{t("idealProductionTime")}</div>
                            <div>
                                <div>
                                    <span className="score">{idealProductionHours}</span>
                                </div>
                                <button className="btn-littlebox" onClick={handlePlannedIdealProductionHours}>
                                    {/* <div className="material-symbols-rounded">edit</div> */}
                                    <div className="icon icon-Edit"></div>
                                    <div className="text">{t("edit")}</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </OverlayTrigger>


                {/* <!--第1排｜5號框--> */}
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip className="custom-tooltip">
                            {(() => {
                                if (machineOperatingTime) {
                                    let result = [];
                                    for (let item of machineOperatingTime) {
                                        result.push(moment(`2024-01-01 ${item.startTime}`).format("HH:mm A") + " ~ " + (moment(`2024-01-01 ${item.endTime}`).format("HH:mm A")));
                                    }

                                    let resultString = i18n.language === 'en' ? result.join(', ') : result.join('、');
                                    return <span dangerouslySetInnerHTML={{ __html: t("plannedEquipmentLoadingTimeToolTip", { e: plannedEquipmentLoadingTime, period: resultString, oneDayHour: machineOperatingHours.toFixed(1) }) }} />;
                                }
                                return <span dangerouslySetInnerHTML={{ __html: t("plannedEquipmentLoadingTimeToolTip24Hours", { e: plannedEquipmentLoadingTime }) }} />;
                            })()}
                            {/* <span dangerouslySetInnerHTML={{ __html: t("plannedEquipmentLoadingTimeToolTip") }} /> */}
                        </Tooltip>
                    }
                >
                    <div className="boxstyle-blue littlebox">
                        <div>
                            <div className="title">{t("plannedEquipmentLoadingTime")}</div>
                            <div>
                                <div>
                                    <span className="score">{plannedEquipmentLoadingTime}</span>
                                </div>
                                <button className="btn-littlebox" onClick={handlePlannedEquipmentLoadingTime}>
                                    {/* <div className="material-symbols-rounded">edit</div> */}
                                    <div className="icon icon-Edit"></div>
                                    <div className="text">{t("edit")}</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </OverlayTrigger>

                {/* <!--第1排｜6號框--> */}
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip className="custom-tooltip">
                            <span dangerouslySetInnerHTML={{ __html: t("equipmentActualRunningHoursToolTip") }} />
                        </Tooltip>
                    }
                >
                    <div className="boxstyle-blue littlebox">
                        <div>
                            <div className="title">{t("equipmentActualRunningHours")}</div>
                            <div>
                                <div>
                                    <span className="score">{secondsToHours(equipmentRuntimeRawDataMachining)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OverlayTrigger>

            </div>
            {/* <!--第1排 END--> */}

            {/* <!--第2排 100% width白外框--> */}
            <div className="boxgroup-outer outer-white">
                <div className="box-style-white-shadow">
                    <div className="grid grid-col-4to2">
                        {/* <!--第2排｜1號框--> */}
                        {(() => {
                            const yieldRate = decimalWithOneDecimal(nonDefectiveQuantityWorkpieces, quantityProducedWorkpieces);
                            const productionPerformance = decimalWithOneDecimal(parseFloat(productionPerformanceNumerator), parseFloat(productionPerformanceDenominator));
                            const equipmentAvailability = decimalWithOneDecimal(parseFloat(secondsToHours(equipmentRuntimeRawDataMachining)), parseFloat(plannedEquipmentLoadingTime));

                            const oee = (((yieldRate / 100) * (productionPerformance / 100) * (equipmentAvailability / 100)) * 100).toFixed(1);
                            let result = "";
                            if (ratingCriteria) {
                                result = judgeUtilizationRate(oee, ratingCriteria.overallEquipmentEfficiency);
                            }

                            return <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip className="custom-tooltip">
                                        <span dangerouslySetInnerHTML={{ __html: t("overallEquipmentEffectivenessToolTip") }} />
                                    </Tooltip>
                                }
                            >
                                <div className={`boxstyle-white littlebox color-div-lv1-${result}`}>
                                    <div>
                                        <div>{t("overallEquipmentEffectiveness")}</div>
                                        <div>
                                            <span className="score-XL">{oee}%</span>
                                            <span className="criteria-L">{result.toUpperCase()}</span>
                                        </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        })()}
                        {/* <!--第2排｜2號框--> */}
                        {(() => {
                            let resultVal = decimalWithOneDecimal(nonDefectiveQuantityWorkpieces, quantityProducedWorkpieces);
                            let result = "";
                            if (ratingCriteria) {
                                result = judgeUtilizationRate(resultVal, ratingCriteria.yieldRate);
                            }

                            return <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip className="custom-tooltip">
                                        <span dangerouslySetInnerHTML={{ __html: t("yieldRateToolTip") }} />
                                    </Tooltip>
                                }
                            >
                                <div className={`boxstyle-lightblue littlebox color-div-lv2-${result}`}>
                                    <div>
                                        <div>{t("yieldRate")}</div>
                                        <div>
                                            <span className="score-XL">{resultVal}%</span>
                                            <span className="criteria-L">{result.toUpperCase()}</span>
                                        </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        })()}
                        {/* <!--第2排｜3號框--> */}
                        {(() => {
                            let resultVal = decimalWithOneDecimal(parseFloat(productionPerformanceNumerator), parseFloat(productionPerformanceDenominator));
                            let result = "";
                            if (ratingCriteria) {
                                result = judgeUtilizationRate(resultVal, ratingCriteria.productionEfficiency);
                            }

                            return <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip className="custom-tooltip">
                                        <span dangerouslySetInnerHTML={{ __html: t("productionPerformanceToolTip") }} />
                                    </Tooltip>
                                }
                            >
                                <div className={`boxstyle-lightblue littlebox color-div-lv2-${result}`}>
                                    <div>
                                        <div>{t("productionPerformance")}</div>
                                        <div>
                                            <span className="score-XL">{resultVal}%</span>
                                            <span className="criteria-L">{result.toUpperCase()}</span>
                                        </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        })()}
                        {/* <!--第2排｜4號框--> */}
                        {(() => {
                            let resultVal = decimalWithOneDecimal(parseFloat(secondsToHours(equipmentRuntimeRawDataMachining)), parseFloat(plannedEquipmentLoadingTime));
                            let result = "";
                            if (ratingCriteria) {
                                result = judgeUtilizationRate(resultVal, ratingCriteria.equipmentOperatingRate);
                            }

                            return <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip className="custom-tooltip">
                                        <span dangerouslySetInnerHTML={{ __html: t("equipmentAvailabilityToolTip") }} />
                                    </Tooltip>
                                }
                            >
                                <div className={`boxstyle-lightblue littlebox color-div-lv2-${result}`}>
                                    <div>
                                        <div>{t("equipmentAvailability")}</div>
                                        <div>
                                            <span className="score-XL">{resultVal}%</span>
                                            <span className="criteria-L">{result.toUpperCase()}</span>
                                        </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        })()}
                    </div>
                </div>
            </div>
            {/* <!--第2排 END--> */}

        </>
    );
}
//#endregion

export default Summary;