import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { Dropdown } from 'react-bootstrap';
import './TimePeriodCaptureMain.css';
import { ToastContainer, toast } from 'react-toastify';
import MessageModal from 'components/basic/MessageModal';
import MyPagination from 'components/basic/Pagination';

import { formatDateTimeRange, formatTimeDays, convertDateFormat } from 'utils/commonFun';

import CapturePeriodEquipment from './modal/capturePeriodEquipment';
import CapturePeriodPart from './modal/capturePeriodPart';
import CapturePeriodConfirm from './modal/capturePeriodConfirm';

import DateRangePicker from 'components/dateRangePicker/DateRangePicker';

import { apiPeriodReportListData, apiPowerConsumptionDelReport } from 'utils/Api';

import { encrypt } from 'utils/crypt';

const TimePeriodCaptureMain = () => {
  const { t } = useTranslation("powerCarbon");
  const navigate = useNavigate();
  let { tabName } = useParams();

  const { lang, periodReportTypeList, showFormatDate, showFormatTime } = useContext(MyUserContext);

  const [currentPage, setCurrentPage] = useState(1);

  let [dateValue, setDateValue] = useState([]); //查詢區間
  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字

  let [timePeriodCaptureList, setTimePeriodCaptureList] = useState(null); //擷取區間列表
  const [currentReportID, setCurrentReportID] = useState(0); //目前選擇的流水號

  const [currentStep, setCurrentStep] = useState(0); //目前第幾步驟
  const [choiceMachine, setChoiceMachine] = useState(""); //選擇機器
  const [choiceDate, setChoiceDate] = useState([]); //選擇區間
  const [periodName, setPeriodName] = useState(""); //區間名稱
  const [periodReportType, setPeriodReportType] = useState(1); //區間類別

  const [displayCreatPart, setDisplayCreatPart] = useState(false); //是否顯示新增工件
  const [wpID, setWpID] = useState(0);  //工件流水號(insert的時候不要拋送，update的時候要拋送null)
  const [wpNO, setWpNO] = useState(""); //工件代號(畫面顯示)
  const [wpName, setWpName] = useState(""); //工件名稱(畫面顯示)
  const [programID, setProgramID] = useState(0); //加工程式流水號(insert的時候不要拋送，update的時候要拋送null)
  const [programName, setProgramName] = useState(""); //加工程式名稱(畫面顯示)

  const [productionQty, setProductionQty] = useState(""); //生產數量
  const [remark, setRemark] = useState(""); //備註

  const [showCapturePeriodEquipmentModal, setShowCapturePeriodEquipmentModal] = useState(false); //是否顯示編輯第一步Modal
  const handleCapturePeriodEquipmentClose = (step = 0) => {
    setShowCapturePeriodEquipmentModal(false);
    setCurrentStep(step);

    if (step === 2) {
      setShowCapturePeriodPartModal(true);
    }
    else if (step === 0) {
      changeDefault();
    }
  }  //關閉

  const [showCapturePeriodPartModal, setShowCapturePeriodPartModal] = useState(false); //是否顯示編輯第二步Modal
  const handleCapturePeriodPartClose = (step = 0) => {
    setShowCapturePeriodPartModal(false);
    setCurrentStep(step);

    if (step === 1) {
      setShowCapturePeriodEquipmentModal(true);
    }
    else if (step === 3) {
      setShowCapturePeriodConfirmModal(true);
    }
    else if (step === 0) {
      changeDefault();
    }
  } //關閉

  const [showCapturePeriodConfirmModal, setShowCapturePeriodConfirmModal] = useState(false); //是否顯示確認視窗Modal
  const handleCapturePeriodConfirmClose = (step = 0) => {
    setShowCapturePeriodConfirmModal(false);
    setCurrentStep(step);

    if (step === 3) {
      setShowCapturePeriodPartModal(true);
    }
    else if (step === 4) {
      setShowCaptureSuccessfulModal(true);
    }
    // else if(step === 0){
    //   changeDefault();
    // } 
  } //關閉

  const [showCaptureSuccessfulModal, setShowCaptureSuccessfulModal] = useState(false); //是否顯示擷取成功
  const handleCaptureSuccessfulClose = (step = 0) => {
    setShowCaptureSuccessfulModal(false); //關閉擷取成功
    setCurrentStep(step);

    formClearData(); //清空預設值
  }

  const [showDelReportModal, setShowDelReportModal] = useState(false); //是否顯示確定刪除嗎？Modal
  const handleDelReportClose = () => setShowDelReportModal(false);

  useEffect(() => {
    if (localStorage.getItem("power_keyword") !== null) {
      setKeyword(localStorage.getItem("power_keyword"));
    }

    if (localStorage.getItem("power_startDate") !== null && localStorage.getItem("power_endDate") !== null) {
      setDateValue([localStorage.getItem("power_startDate"), localStorage.getItem("power_endDate")]);
    }
  }, []);

  useEffect(() => {
    handlePeriodReportListData();
  }, [currentPage]);

  useEffect(() => {
    if (timePeriodCaptureList?.totalItems > 0 && timePeriodCaptureList?.detail?.length === 0) {
      setCurrentPage(1);
    }
  }, [timePeriodCaptureList]);

  //#region 改成預設值
  const changeDefault = () => {
    setCurrentReportID(0);
    setChoiceDate([]);
    setPeriodName("");
    setPeriodReportType(1);
    setWpID(0);
    setWpNO("");
    setWpName("");
    setProgramID(0);
    setProgramName("");
    setProductionQty("");
    setRemark("");
  }
  //#endregion

  //#region 手動擷取程式用電量分析報告清單
  const handlePeriodReportListData = async () => {
    const [httpStatus, reponseData] = await handleApiPeriodReportListData();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setTimePeriodCaptureList(reponseData.data);
      }
    }
  }
  //#endregion

  //#region 手動擷取程式用電量分析報告清單API
  const handleApiPeriodReportListData = async () => {
    let postJson = {
      keyword: keyword,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30
    };

    if (localStorage.getItem("power_startDate") !== null && localStorage.getItem("power_endDate") !== null) {
      postJson.queryStartDate = localStorage.getItem("power_startDate");
      postJson.queryEndDate = localStorage.getItem("power_endDate");
    }

    let response = await apiPeriodReportListData(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    let keyword = value.replace(/\s/g, "");
    setKeyword(keyword);
    localStorage.setItem("reports_keyword", keyword);
  };
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //#endregion


  //#region 搜詢按鈕
  const handleSearch = () => {
    handlePeriodReportListData();
  }
  //#endregion

  //#region 跳轉擷取區間Modal
  const handleCapturePeriod = () => {
    setShowCapturePeriodEquipmentModal(true);
  }
  //#endregion

  useEffect(() => {
    if (dateValue.length > 0) {
      localStorage.setItem("power_startDate", dateValue[0]);
      localStorage.setItem("power_endDate", dateValue[1]);
    }
    else {
      localStorage.removeItem("power_startDate");
      localStorage.removeItem("power_endDate");
    }
  }, [dateValue]);

  //#region 擷取區間第一步的下一步按鈕
  const handleStep1 = () => {
    handleCapturePeriodEquipmentClose(2);
  }
  //#endregion

  //#region 擷取區間第二步的開始擷取按鈕
  const handleStep2 = () => {
    handleCapturePeriodPartClose(3);
  }
  //#endregion

  //#region 第三步完成按鈕
  const handleStep3 = async () => {
    handleCapturePeriodConfirmClose(4);
  }
  //#endregion

  //#region 最後一步等待和結果
  const handleStep4 = async () => {
    navigate(`/Power/${tabName}/${encrypt(currentReportID)}`);
  }
  //#endregion

  //#region 類別樣式
  const renderPeriodReportType = (periodReportType) => {
    let showName = periodReportTypeList.find(d => d.key === periodReportType)?.name;
    switch (periodReportType) {
      //生產
      case 1:
        return (
          <span className="tag-icontext box-color-green">
            <div className="material-symbols-rounded icon16">play_circle</div>
            <div>{showName}</div>
          </span>
        );
      //待機
      case 2:
        return (
          <span className="tag-icontext box-color-yellow">
            <div className="material-symbols-rounded icon16">hourglass_bottom</div>
            <div>{showName}</div>
          </span>
        );
      //錯誤
      case 3:
        return (
          <span className="tag-icontext box-color-red">
            <div className="material-symbols-rounded icon16">warning</div>
            <div>{showName}</div>
          </span>
        );
      case 4:
        return (
          <span className="tag-icontext box-color-orange">
            <div className="material-symbols-rounded icon16">heat</div>
            <div>{showName}</div>
          </span>
        );
      case 5:
        return (
          <span className="tag-icontext box-color-lakeblue">
            <div className="material-symbols-rounded icon16">experiment</div>
            <div>{showName}</div>
          </span>
        );
      case 6:
        return (
          <span className="tag-icontext box-color-grey">
            <div className="material-symbols-rounded icon16">bedtime</div>
            <div>{showName}</div>
          </span>
        );
      case 7:
        return (
          <span className="tag-icontext box-color-blue">
            <div className="material-symbols-rounded icon16"></div>
            <div>{showName}</div>
          </span>
        );
      // 其他 case
      default:
        return null;
    }
  };
  //#endregion

  //#region 刪除報告
  const handleDel = (id) => {
    setCurrentReportID(id);
    setShowDelReportModal(true);
  }
  //#endregion

  //#region 刪除報告
  const handleDelConsumptionReportData = async () => {
    const [httpStatus, reponseData] = await handleApiDelConsumptionReportData();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<>
          <span>{t("deletionCompleted")}</span>
        </>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        handleDelReportClose();
        handlePeriodReportListData();
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    }
  }
  //#endregion

  //#region 刪除報告API
  const handleApiDelConsumptionReportData = async () => {
    let postJson = {
      reportID: currentReportID
    };
    let response = await apiPowerConsumptionDelReport(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 最後一步：等待和結果取消按鈕
  const handleCaptureSuccessCancel = () => {
    handleCaptureSuccessfulClose();
    handlePeriodReportListData();
  }
  //#endregion

  //#region清空預設值
  const formClearData = () => {
    console.log("我來清掉囉")
    setCurrentReportID(0);
    // setChoiceMachine("");

    setChoiceDate([]);
    setPeriodName("");
    setPeriodReportType(1);
    setDisplayCreatPart(false);
    setWpID(0);
    setWpNO("");
    setWpName("");
    setProgramID(0);
    setProgramName("");
    setProductionQty("");
    setRemark("");
  }
  //#endregion

  useEffect(() => {
    console.log("choiceMachine", choiceMachine)
  }, [choiceMachine]);

  //#region 介面
  return (
    <>
      <div className="pageContent">
        <div className="filterRow">
          <div>
            <DateRangePicker dateValue={dateValue} setDateValue={setDateValue} lang={lang} />
            <div className="btn-search">
              <input type="search" placeholder={t("search")} aria-label="Search"
                value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                  if (event.key === "Enter") { handleSearch(); }
                }} />
              <button type="button" className="btn btn-onlyicon bounce btn-lv5"
                onClick={handleSearch}>
                <div className="icon icon-Search"></div>
              </button>
            </div>
          </div>
          <div>
            <button className="btn btn-lv1 btn-icontext" onClick={handleCapturePeriod}>
              <div className="icon icon-Cut"></div>
              <div>{t("capturePeriod")}</div>
            </button>
          </div>
        </div>
        <div className="overflow-hidden">
          <main className="table-wrapper border rwd col4to2 text-keep-normal">
            {
              timePeriodCaptureList?.totalItems > 0 ?
                <div className="table-minheight2">
                  {/* <div className="table-report table-scroll-justify"> */}
                    {/* <div> */}
                      <table>
                        <thead>
                          <tr className="sticky-top">
                            {/* <th></th> */}
                            <th className="sticky-left">{t("periodName")}</th>
                            <th className="">{t("type")}</th>
                            <th className="">{t("equipmentUsed")}</th>
                            <th className="">{t("startEndTime")}</th>
                            <th className="tRight">{t("duration2")}</th>
                            <th className="tRight">{t("powerConsumption")}</th>
                            <th className="tRight">{t("carbonEmissions")}</th>
                            <th className="tRight">{t("averagePower")}</th>
                            <th className="tRight">{t("electricityCost")}</th>
                            <th className="">{t("partProduced")}</th>
                            <th className="">{t("producedCount")}</th>
                            <th className="">{t("averagePerPart")}</th>
                            {/* <th className="tRight">{t("averagePowerConsumptionPerPart")}</th>
                            <th className="tRight">{t("averageCarbonEmissionsPerPart")}</th>
                            <th className="tRight">{t("averageElectricityCost")}</th> */}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            timePeriodCaptureList?.detail.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  {/*因為目前建置的區間報告都不是有圖片的工件，不確定有沒有這個需求，2025-1-10啾啾先把這欄隱藏*/}
                                  {/* <td data-label={t("periodName")}>
                                    {
                                      obj.wpImage != null ?
                                        <img className="img-wp" src={obj.wpImage} alt={obj.wpName} /> :
                                        obj.periodReportType === 1 ? <div className="imgreplace"></div> : null
                                    }
                                  </td> */}
                                  <td className='sticky-left span2 text break-all' data-label={t("periodName")}>
                                    <b className='clickTxt' onClick={() => { navigate(`/Power/${tabName}/${encrypt(obj.reportID)}`); }}>{obj.reportName}</b>
                                  </td>
                                  <td className='' data-label={t("type")}>
                                    {renderPeriodReportType(obj.periodReportType)}
                                  </td>
                                  <td className='' data-label={t("equipmentUsed")}>
                                    {obj.equipmentSerialNumber}
                                  </td>
                                  <td className='span2 text noBr' data-label={t("startEndTime")}>
                                    {(() => {
                                      const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.startTime, obj.endTime);
                                      return trans.startDate + " " + trans.startTime + " ~ " + trans.endDate + " " + trans.endTime;
                                    })()}
                                  </td>
                                  <td className='tRight' data-label={t("duration2")}>
                                    {formatTimeDays(obj.duration, t)}
                                  </td>
                                  <td className='tRight' data-label={t("powerConsumption")}>
                                    {obj.powerUsage?.toFixed(2)}
                                  </td>
                                  <td className='tRight' data-label={t("carbonEmissions")}>
                                    {obj.carbonUsage?.toFixed(2)}
                                  </td>
                                  <td className='tRight' data-label={t("averagePower")}>
                                    {obj.averagePowerConsumption?.toFixed(2)}
                                  </td>
                                  <td className='tRight' data-label={t("electricityCost")}>
                                    {obj.electricityCost?.toFixed(2)}
                                  </td>
                                  <td className={`text break-all ${obj.periodReportType !== 1 ? 'rwd-display-none' : ''}`} data-label={t("partProduced")}>
                                    {
                                      obj.programID != null && obj.wpNO != null ?
                                        <div className='borderLeft'>
                                          <span className='programName mb-0'>{obj.programName}</span><br />
                                          <span className='workpiece mb-0'>{obj.wpNO}&nbsp;&nbsp;{obj.wpName}</span>
                                        </div> : null
                                    }
                                    {
                                      obj.programID == null && obj.wpNO != null ?
                                        <div className='borderLeft'>
                                          <span className='workpiece mb-0'>{obj.wpNO}&nbsp;&nbsp;{obj.wpName}</span>
                                        </div> : null
                                    }
                                    {
                                      obj.programID != null && obj.wpNO == null ?
                                        <div className='borderLeft'>
                                          <span className='programName mb-0'>{obj.programName}</span>
                                        </div> : null
                                    }
                                  </td>
                                  <td  className={obj.periodReportType !== 1 ? 'rwd-display-none' : ''} data-label={t("producedCount")}>
                                    {obj.productionQty}&nbsp;
                                  </td>
                                  <td data-label={t("averagePerPart")} className={obj.periodReportType !== 1 ? 'rwd-display-none' : ''}>
                                    {obj.periodReportType === 1 && (
                                      <div id="timePeriodCapture-Main-PartAverage" className='flex-wrap column'>
                                        <div className="flex-nowrap pBottom4 align-center">
                                          <div className="icon icon-Power-Filled size-S yellow mRight8"></div>
                                          <div className="text grey4 text14 ">{obj.averageQtyPowerConsumption?.toFixed(2)}&nbsp;</div>
                                        </div>
                                        <div className="flex-nowrap pBottom4 align-center">
                                          <div className="icon icon-Cloud-Filled size-S lakeblue mRight8"></div>
                                          <div className="text grey4 text14">{obj.averageQtyCarbonEmissions?.toFixed(2)}&nbsp;</div>
                                        </div>
                                        <div className="flex-nowrap align-center">
                                          <div className="icon icon-Price size-S green mRight8 "></div>
                                          <div className="text grey4 text14">{obj.averageQtyElectricityCost?.toFixed(2)}&nbsp;</div>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                  {/* <td className='tRight' data-label={t("averagePowerConsumptionPerPart")}>
                                    {obj.averageQtyPowerConsumption?.toFixed(2)}&nbsp;
                                  </td>
                                  <td className="tRight" data-label={t("averageCarbonEmissionsPerPart")}>
                                    {obj.averageQtyCarbonEmissions?.toFixed(2)}&nbsp;
                                  </td>
                                  <td className="tRight" data-label={t("averageElectricityCost")}>
                                    {obj.averageQtyElectricityCost?.toFixed(2)}&nbsp;
                                  </td> */}
                                  <td className="tableFuncBtn">
                                    <Dropdown>
                                      <Dropdown.Toggle className="dropdownIcon">
                                        <i className="icon icon-More"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item className='d-flex align-items-center' onClick={() => { navigate(`/Power/${tabName}/${encrypt(obj.reportID)}`); localStorage.setItem("timeperiodCaptureEdit", true) }}>
                                          <div className="icon icon-Edit bg-grey8 size-M"></div>&nbsp;&nbsp;
                                          <div className="text-grey4 text14">{t("edit")}</div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='d-flex align-items-center text-alert' onClick={() => handleDel(obj.reportID)}>
                                          <div className="icon icon-TrashCan bg-red size-M"></div>&nbsp;&nbsp;
                                          <div className="text-red text14">{t("delete")}</div>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    {/* </div> */}
                  {/* </div> */}
                </div>


                :
                <div className="nodata">
                  {/* <!--選取的條件沒有執行紀錄--> */}
                  <div className="notice">{t("noPeriodsFound")}</div>
                </div>
            }
          </main>
        </div>


        {
          timePeriodCaptureList?.totalItems > 0 ?
            <div className="d-flex justify-content-center mt-3">
              <MyPagination totalPages={Math.ceil(timePeriodCaptureList?.totalItems / 30)} currentPage={currentPage}
                onPageChange={handlePageChange} />
            </div> : null
        }
      </div>

      {/* 第1步：預設的欄位內容 */}
      <CapturePeriodEquipment
        showModal={showCapturePeriodEquipmentModal}
        handleClose={handleCapturePeriodEquipmentClose}
        choiceMachine={choiceMachine}
        setChoiceMachine={setChoiceMachine}
        choiceDate={choiceDate}
        setChoiceDate={setChoiceDate}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleNextStep={handleStep1}
      />

      {/* 第2步：時段命名、選擇配對的工件 */}
      <CapturePeriodPart
        showModal={showCapturePeriodPartModal}
        handleClose={handleCapturePeriodPartClose}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleNextStep={handleStep2}
        choiceMachine={choiceMachine}
        periodName={periodName}
        setPeriodName={setPeriodName}
        currentReportID={currentReportID}
        periodReportType={periodReportType}
        setPeriodReportType={setPeriodReportType}
        wpID={wpID}
        setWpID={setWpID}
        setProgramID={setProgramID}
        wpNO={wpNO}
        setWpNO={setWpNO}
        wpName={wpName}
        setWpName={setWpName}
        programID={programID}
        programName={programName}
        setProgramName={setProgramName}
        productionQty={productionQty}
        setProductionQty={setProductionQty}
        remark={remark}
        setRemark={setRemark}
        displayCreatPart={displayCreatPart}
        setDisplayCreatPart={setDisplayCreatPart}
      />

      {/* 開始擷取區間？ */}
      <CapturePeriodConfirm
        showModal={showCapturePeriodConfirmModal}
        handleClose={handleCapturePeriodConfirmClose}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleNextStep={handleStep3}
        periodName={periodName}
        choiceMachine={choiceMachine}
        choiceDate={choiceDate}
        periodReportType={periodReportType}
        wpID={wpID}
        setWpID={setWpID}
        wpNO={wpNO}
        wpName={wpName}
        programID={programID}
        setProgramID={setProgramID}
        programName={programName}
        productionQty={productionQty}
        remark={remark}
        displayCreatPart={displayCreatPart}
        setCurrentReportID={setCurrentReportID}
      />

      {/* 最後一步：等待和結果 */}
      <MessageModal
        title={t("captureSuccessful")}
        content={`${t("captureSuccessfulContent")}`}
        showModal={showCaptureSuccessfulModal}
        handleClose={handleCaptureSuccessCancel}
        size="xs"
        confirmModalTxt={t("viewNow")}
        cancelTxt={t("cancel")}
        handleConfirmData={handleStep4}
        cancelModal={handleCaptureSuccessCancel}
      />

      {/* 確定刪除嗎？ */}
      <MessageModal
        title={t("confirmDeletion")}
        content={`${t("confirmDeletionContent")}`}
        showModal={showDelReportModal}
        handleConfirmData={handleDelConsumptionReportData}
        size="xs"
        confirmModalTxt={t("delete")}
        cancelTxt={t("cancel")}
        cancelModal={handleDelReportClose}
        handleClose={handleDelReportClose}
      />

      <ToastContainer />
    </>
  );
};

export default TimePeriodCaptureMain;