import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import moment from 'moment';
import { weekCalc, convertDateFormat } from 'utils/commonFun';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import "./workpieces.css";
import { MyUserContext } from 'contexts/MyUserContext';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import PowerCarbon from './data/powerCarbon/PowerCarbon';
import TimePeriodCaptureMain from './data/timePeriodCapture/TimePeriodCaptureMain';

import Tab from 'components/basic/tab';
import { apiMachineListData, apiIdeaLogin } from 'utils/Api';

const WorkpiecesMain = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  let { tabName } = useParams();
  const { overtime, authority, permission } = useContext(MyUserContext);

  const [tabList, setTabList] = useState([]);  //上方Tab

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "PartsPrograms",
        active: tabName === "PartsPrograms"
      },
      {
        tabName: "TimePeriodCapture",
        active: tabName === "TimePeriodCapture"
      }
    ];

    if(permission !== null){
      let filterMainTab = permission.find(d => d.tabName === "Power");
      if(filterMainTab){
        for(let item of filterMainTab.tabList){
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if(tab) newTabList.push(tab);
        }
      }
      else{
        newTabList = defaultTabs;
      }
    }
    else{
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if(filter){
      navigate(`/Power/${filter.tabName}`);
    }
  }, [tabList]);

  return (
    <>
      <Tab tabList={tabList} setTabList={setTabList} />
      {
        tabList?.find(d => d.active && d.tabName === "PartsPrograms") ?
          <PowerCarbon /> : null
      }
      {
        tabList?.find(d => d.active && d.tabName === "TimePeriodCapture") ?
          <TimePeriodCaptureMain /> : null
      }
      <ToastContainer />
    </>
  );
};

export default WorkpiecesMain;
