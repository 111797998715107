import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import MessageModal from 'components/basic/MessageModal';

import Select from 'react-select';

import { isNumber, isPositiveInteger, isNumberAndAlphabet, formatTimeDays, isTodayAfter} from 'utils/commonFun';
import { apiListOrderByOrderNo, apiWorkpieceList, apiCreatedProcessList,
  apiCheckOrderNo, apiAddEditOrder, apiSingleOrder
 } from 'utils/Api';

const WorkOrderModal = ({showModal, handleClose, behavior, loadOrderList, orderID, loadOrderCountAndStatus}) => {
  const { t } = useTranslation("aps");  

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  const [orderNo, setOrderNo] = useState(""); //工單編號
  const [associatedWorkOrder, setAssociatedWorkOrder] = useState([]);  //關聯的工單
  const [selPart, setSelPart] = useState(null);  //使用者選擇的工件
  const [workpieceSetting, setWorkpieceSetting] = useState([]); //工件設定檔

  const [selProcess, setSelProcess] = useState(null);  //使用者選擇的工序
  const [processSetting, setProcessSetting] = useState([]); //工序設定檔
  const [checkProcessIsUsed, setCheckProcessIsUsed] = useState(false);  //檢查相同的工單編號是否使用重複的工序號

  const [scheduledDeliveryDate, setScheduledDeliveryDate] = useState(""); //預計交付日期

  const [scheduledProductionQuantity, setScheduledProductionQuantity] = useState(""); //排程生產數量

  const [actualQuantity, setActualQuantity] = useState(0); //實際完工數量

  const [equipmentAndPrograms, setEquipmentAndPrograms] = useState(null); //設備&程式

  const [estDuration, setEstDuration] = useState({min: 0, max: 0});  //預計時長
  const [equipment, setEquipment] = useState(""); //適用設備
  const [processRemark, setProcessRemark] = useState(""); //工序備註
  const [remark, setRemark] = useState(""); //備註

  const [initWorkOrder, setInitWorkOrder] = useState(null);

  const [showLeavePageModal, setShowLeavePageModal] = useState(false); //是否顯示確定離開嗎？Modal
  const handleLeavePageClose = () => setShowLeavePageModal(false);

  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false); //是否顯示確定儲存嗎？Modal
  const handleSaveChangesClose = () => setShowSaveChangesModal(false);

  const [showConvertedUnscheduledModal, setShowConvertedUnscheduledModal] = useState(false); //是否顯示工單將轉成「未排程」狀態Modal
  const handleConvertedUnscheduledClose = () => setShowConvertedUnscheduledModal(false);

  //#region 下拉多選樣式
  const customStylesPart = {
    control: (provided) => ({
      ...provided,
      backgroundColor: (behavior === "update" && actualQuantity > 0) ? '#f2f2f2' : 'white',
      color: (behavior === "update" && actualQuantity > 0) ? '#a9a9a9' : 'black',
      borderColor: (behavior === "update" && actualQuantity > 0) ? '#ccc' : judgeCheckErr && selPart === null ? '#F66F6F' : '#CCC',
      '&:hover': {
        borderColor: (behavior === "update" && actualQuantity > 0) ? '#ccc' : judgeCheckErr && selPart === null ? '#F66F6F' : '#CCC', // 滑鼠懸停時邊框顏色
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: (behavior === "update" && actualQuantity > 0) ? '#f2f2f2' : state.isSelected ? 'white' : provided.backgroundColor,
      color: (behavior === "update" && actualQuantity > 0) ? '#a9a9a9' : state.isSelected ? 'black' : provided.color,
      cursor: (behavior === "update" && actualQuantity > 0) ? 'not-allowed' : 'default', // 禁用時的滑鼠游標
    })
  };

  const customStylesProcess = {
    control: (provided) => ({
      ...provided,
      backgroundColor: (behavior === "update" && actualQuantity > 0) ? '#f2f2f2' : 'white',
      color: (behavior === "update" && actualQuantity > 0) ? '#a9a9a9' : 'black',
      borderColor: (behavior === "update" && actualQuantity > 0) ? '#ccc' : (
        (selPart !== null && processSetting?.length === 0) || // noProcess
        (judgeCheckErr && selProcess === null) ||             // requiredFields
        (judgeCheckErr && selProcess !== null && checkProcessIsUsed) // notProcessSameOrderID
      ) ? '#F66F6F' : '#CCC',
      '&:hover': {
        borderColor: (behavior === "update" && actualQuantity > 0) ? '#ccc' : (
          (selPart !== null && processSetting?.length === 0) || // noProcess
          (judgeCheckErr && selProcess === null) ||             // requiredFields
          (judgeCheckErr && selProcess !== null && checkProcessIsUsed) // notProcessSameOrderID
        ) ? '#F66F6F' : '#CCC', // 滑鼠懸停時邊框顏色
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: (behavior === "update" && actualQuantity > 0) ? '#f2f2f2' : state.isSelected ? 'white' : provided.backgroundColor,
      color: (behavior === "update" && actualQuantity > 0) ? '#a9a9a9' : state.isSelected ? 'black' : provided.color,
      cursor: (behavior === "update" && actualQuantity > 0) ? 'not-allowed' : 'default', // 禁用時的滑鼠游標
    })
  };
  //#endregion

  useEffect(() => {
    if(showModal){
      setJudgeCheckErr(false);
      loadWorkpieceList(); //載入下拉選單工件
      setCheckProcessIsUsed(false);

      if(behavior === "insert"){
        setOrderNo("");
        setAssociatedWorkOrder([]);
        setSelPart(null);
        setWorkpieceSetting([]);

        setSelProcess(null);
        setProcessSetting([]);

        setScheduledDeliveryDate("");
        setScheduledProductionQuantity("");

        setEquipmentAndPrograms(null);
        setEstDuration({min: 0, max: 0});
        setEquipment("");

        setProcessRemark("");
        setRemark("");
      }
      else{
        handleSingleOrder();
      }

    }
  }, [showModal]);

  useEffect(() => {
    if(behavior === "update"){
      if(equipmentAndPrograms != null && selProcess != null, scheduledProductionQuantity != ""){
        if(equipmentAndPrograms?.length > 0){
          calcEstDuration(selProcess?.value, scheduledProductionQuantity);
        }
      }
    }
  }, [equipmentAndPrograms, selProcess, scheduledProductionQuantity]);

  //#region 載入工件列表
  const loadWorkpieceList = async () => {
    const [httpStatus, reponseData] = await handleApiWorkpieceList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data.detail;
        
        const newWorkpiece = data?.map(item => ({
          value: item.wpID,
          label: item.wpNO + " " + item.wpName
        }));
        setWorkpieceSetting(newWorkpiece);
      }
    }
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = {};
    let programListResponse = await apiWorkpieceList(postJson);
    if(programListResponse){
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 載入單一一筆工單
  const handleSingleOrder = async () => {
    const [httpStatus, reponseData] = await handleApiSingleOrder();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        let data = reponseData.data;

        setInitWorkOrder(data);

        let requirements = data.requirements;

        let orderNO = requirements.orderNo.replaceAll("WO", "");
        setOrderNo(orderNO);
        GetAssociatedWorkOrder(orderNO);

        setSelPart({value: requirements.wpID, label: requirements.wpNO + " " + requirements.wpName});
        GetCreatedProcessList(requirements.wpID);
        setSelProcess({value: requirements.processID, label: requirements.processNo + " " + requirements.processName});

        setScheduledDeliveryDate(requirements.scheduledDeliveryDate);
        setScheduledProductionQuantity(requirements.scheduledQuantity.toString());

        if(data.orderRemark){
          setRemark(data.orderRemark);
        }
        else{
          setRemark("");
        }

        setActualQuantity(data.actualQuantity);
      }
    }
  }
  //#endregion

  //#region 載入單一一筆工單API
  const handleApiSingleOrder = async () => {
    let postJson = {
      orderID: orderID
    };
    let response = await apiSingleOrder(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 儲存按鈕
  const handleSave = async () => {
    setJudgeCheckErr(true);

    //檢查欄位是否有問題
    let check = checkCol();
    if(!check){
      if(behavior === "update"){
        if(initWorkOrder?.requirements?.orderStatus === 0){
          setShowSaveChangesModal(true);
        }
        else{
          let isCheckUpdate = checkUpdateStatus();
          if(isCheckUpdate){
            setShowConvertedUnscheduledModal(true);
          }
          else{
            setShowSaveChangesModal(true);
          }
        }
      }
      else{
        handleAddEditOrder();
      }
    }
  }
  //#endregion

  //#region 確認欄位是否有異動會影響排程
  const checkUpdateStatus = () => {
    if(behavior === "update"){
      let requirements = initWorkOrder.requirements;

      if(orderNo != requirements.orderNo.replaceAll("WO", "") ||
          selPart?.value !== requirements.wpID ||
          selProcess?.value !== requirements.processID  ||
          scheduledProductionQuantity != requirements.scheduledQuantity.toString()){
        return true;
      }

      return false;
    }

    return false;
  }
  //#endregion
  
  const handleAddEditOrder = async () => {
    const [httpStatus, reponseData] = await handleApiAddEditOrder();
    
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20100"){
        toast(<>
          <span>{behavior === "insert" ? t("createdSuccessfully") : t("editingCompletedSaved")}</span>
          </>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        loadOrderList();
        handleSaveChangesClose();

        loadOrderCountAndStatus();
        handleClose();

        handleConvertedUnscheduledClose();
      }
      else{
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }

  //#region API新增工單/編輯工單
  const handleApiAddEditOrder = async () => {
    let postJson = {
      action: behavior,
      orderNo: "WO" + orderNo,
      processID: selProcess.value,
      scheduledDeliveryDate: scheduledDeliveryDate,
      plannedQuantity: parseFloat(scheduledProductionQuantity),       // for insert plannedQuantity = scheduledProductionQuantity
      continueQuantity: parseFloat(scheduledProductionQuantity)
    };

    if(behavior === "update"){
      //要帶工單流水號
      postJson.orderID = orderID;
      delete postJson.plannedQuantity;    // plannedQuantity no need to update
    }

    if(remark != ""){
      postJson.orderRemark = remark;
    }

    let response = await apiAddEditOrder(postJson);
    if(response){
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 檢查欄位是否有問題
  const checkCol = () => {
    if(orderNo === "" || !isNumberAndAlphabet(orderNo) ||
      orderNo.length > 10){
      return true;
    }

    if(selPart === null){
      return true;
    }

    if((selPart !== null && processSetting?.length === 0) ||
      (selProcess === null) ||
      (selProcess !== null && checkProcessIsUsed)){
      return true;
    }

    if(scheduledDeliveryDate === "" || !isTodayAfter(scheduledDeliveryDate)){
      return true;
    }

    if(scheduledProductionQuantity === "" || (!isNumber(parseFloat(scheduledProductionQuantity)) || !isPositiveInteger(parseFloat(scheduledProductionQuantity)))){
      return true;
    }

    if(remark !== "" && remark?.length > 300){
      return true;
    }

    return false;
  }
  //#endregion

  //#region 確定要離開嗎?
  const handleLeave = async () => {
    let updateCol = await checkUpdateCol();
    if(updateCol){
      setShowLeavePageModal(true);
    }
    else{
      handleClose();
      loadOrderList();
    }

  }
  //#endregion

  //#region 確認欄位是否有改變
  const checkUpdateCol = () => {
    if(behavior === "update"){
      let requirements = initWorkOrder.requirements;

      if(orderNo != requirements.orderNo.replaceAll("WO", "") ||
          selPart?.value !== requirements.wpID ||
          selProcess?.value !== requirements.processID ||
          scheduledDeliveryDate != requirements.scheduledDeliveryDate ||
          scheduledProductionQuantity != requirements.scheduledQuantity.toString()
        ){
        return true;
      }
  
      let orderRemark = initWorkOrder.orderRemark;
      if(orderRemark === null){
        orderRemark = "";
      }
  
      if(remark != orderRemark){
        return true;
      }
    }
    else{
      if(orderNo != "" ||
          selPart !== null ||
          selProcess !== null ||
          scheduledDeliveryDate != "" ||
          scheduledProductionQuantity != "" ||
          remark != ""
        ){
        return true;
      }
    }

    return false;
  }
  //#endregion

  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "orderNo") {
      setOrderNo(value);
      if(selProcess != null && value != ""){
        checkOrderNo(value, selProcess.value);
      }
    } 
    else if(name === "ScheduledDeliveryDate"){
      setScheduledDeliveryDate(value);
    }
    else if(name === "ScheduledProductionQuantity"){
      setScheduledProductionQuantity(value);

      if(value != "" && selProcess != null){
        calcEstDuration(selProcess.value, value);
      }
      else{
        setProcessRemark("");
        setEquipment("");
        setEstDuration({min: 0, max: 0});
      }
    }
    else if(name === "Remark"){
      setRemark(value);
    }
  };
  //#endregion

  //#region 取得該工單關聯工單
  const GetAssociatedWorkOrder = async (orderNo) => {
    const [httpStatus, reponseData] = await handleApiAssociatedWorkOrder(orderNo);
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        setAssociatedWorkOrder(reponseData.data);
      }
    }
  }
  //#endregion

  //#region API取得該工單關聯工單
  const handleApiAssociatedWorkOrder = async (orderNo) => {
    let postJson = {
      orderNo: "WO" + orderNo
    };
    let response = await apiListOrderByOrderNo(postJson);
    if(response){
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 取得該工件已建立工序
  const GetCreatedProcessList = async (wpID) => {
    const [httpStatus, reponseData] = await handleApiCreatedProcessList(wpID);
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        
        const newProcess = data.map(item => ({
          value: item.processID,
          label: item.processNo + " " + item.processName
        }));
        setProcessSetting(newProcess);

        setEquipmentAndPrograms(data);
      }
    }
  }
  //#endregion

  //#region API取得該工件已建立工序
  const handleApiCreatedProcessList = async (wpID) => {
    let postJson = {
      wpID: wpID
    };
    let programListResponse = await apiCreatedProcessList(postJson);
    if(programListResponse){
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 輸入完成工單編號
  const handleBlurOrderNo = (e) => {
    const { value } = e.target;
    GetAssociatedWorkOrder(value);
  }
  //#endregion

  //#region 下拉多選 Change事件
  const handleSelChange = async (e, name) => {
    if(name === "part"){
      setSelPart(e);

      setSelProcess(null);

      if(e){
        GetCreatedProcessList(e.value);
      }
      else{
        setProcessSetting([]);
      }
    }
    else if(name === "process"){
      setSelProcess(e);

      if(e){
        calcEstDuration(e.value, scheduledProductionQuantity);

        if(e.value !== initWorkOrder?.requirements?.processID){
          if(orderNo != ""){
            checkOrderNo(orderNo, e.value);
          }
        }
        else{
          setCheckProcessIsUsed(false);
        }
      }
      else{
        setProcessRemark("");
        setEquipment("");
        setEstDuration({min: 0, max: 0});
        setCheckProcessIsUsed(false);
      }
      
    }
  };
  //#endregion

  //#region 預計時長計算
  const calcEstDuration = (processID, Quantity) => {
    let filter = equipmentAndPrograms.find(d => d.processID === processID);
    if(filter){
      setProcessRemark(filter.remark);

      let newEquipment = "";
      let equipment = filter.equipmentAndPrograms;
      
      let minuteList = [];
      if(equipment.length > 0){
        for(let item of equipment){
          for(let obj of item.equipment){
            newEquipment += obj + ", ";
          }
          
          let program = item.program;
          if(isNumber(parseFloat(Quantity)) && isPositiveInteger(parseFloat(Quantity))){
            let batchProductionQuantity = program.batchProductionQuantity;
            let programDuration = program.programDuration;

            if(programDuration != 0){
              let singleQuantity = Math.ceil(parseInt(Quantity) / batchProductionQuantity);
              let calc = singleQuantity * programDuration;
              minuteList.push(calc);
            }
          }
        }
      }

      newEquipment = newEquipment.slice(0, newEquipment.length - 2);
      setEquipment(newEquipment);

      if(isNumber(parseFloat(Quantity)) && isPositiveInteger(parseFloat(Quantity))){
        if(minuteList.length === 0){
          setEstDuration({min: 0, max: 0});
        }
        else if(minuteList.length === 1){
          setEstDuration({min: minuteList[0], max: 0});
        }
        else{
          const max = Math.max(...minuteList);
          const min = Math.min(...minuteList);
          setEstDuration({min: min, max: max});
        }
      }
      else{
        setEstDuration({min: 0, max: 0});
      }
    }
  }
  //#endregion

  //#region 檢查工單號是否已經被使用
  const checkOrderNo = async (orderNo, processID) => {
    const [httpStatus, reponseData] = await handleApiCheckOrderNo(orderNo, processID);
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        setCheckProcessIsUsed(reponseData.data?.used);
      }
    }
  }
  //#endregion

  //#region API檢查工單號是否已經被使用
  const handleApiCheckOrderNo = async (orderNo, processID) => {
    let postJson = {
      orderNo: "WO" + orderNo,
      processID: processID
    };
    let response = await apiCheckOrderNo(postJson);
    if(response){
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{behavior === "insert" ? t("create") : t("edit")}</div>
            <div className="modal-title-note">{t("requiredFieldsAsterisk")} 
              <span className="text-red">&nbsp;*</span>
            </div>
          </div>
          <button type="button" className="close" 
            data-dismiss="modal" aria-label="Close" 
            onClick={handleLeave}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          <div className="modal-form">
            <div className="modal-inputText w100">
              <div className="input-wrapper">
                  <span className="prefix">WO</span>
                  <input 
                    type="text" 
                    id="orderNo" 
                    required
                    name="orderNo"
                    value={orderNo}
                    onChange={handleInputChange}
                    onBlur={handleBlurOrderNo}
                    className={`orderNoInput w-100
                        ${judgeCheckErr && (orderNo === "" || !isNumberAndAlphabet(orderNo) ||
                        orderNo.length > 10) ? "error" : ""}`}
                    disabled={behavior === "update" && actualQuantity > 0}
                  />
              </div>
              <label htmlFor="orderNo">{t("workOrderID")}&nbsp;<span className='text-alert'>*</span>
                <span className="charactor">({orderNo.length ?? 0}/10)</span>
              </label>

              {
                judgeCheckErr && orderNo === "" ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }

              {
                judgeCheckErr && orderNo !== "" && !isNumberAndAlphabet(orderNo) ?
                  <span className="errortext">{t("onlyNumbersLettersAllowed")}</span> : null
              }
              {
                judgeCheckErr && orderNo !== "" && isNumberAndAlphabet(orderNo) &&
                  orderNo.length > 10 ?
                  <span className="errortext">{t("exceedsCharacterLimit")}</span> : null
              }
            </div>

            {
              associatedWorkOrder?.length > 0 ?
                <div className='modal-createdProcesses'>
                  <div className='title'>{t("associatedWorkOrder")}</div>
                  {
                    associatedWorkOrder?.map((obj, index) => {
                      return <div className='item' key={index}>
                        <span className='workOrderID'>{obj.orderNo}-{obj.processNo}</span>
                        <span className='partProcesses'>
                          <span className='no'>{obj.wpNO}</span>
                          <span>{obj.wpName}</span>&nbsp;/&nbsp;
                          <span className='no'>{obj.processNo}</span>
                          <span>{obj.processName}</span>
                        </span>
                      </div>;
                    })
                  }
                </div> : null
            }

            <div className="modal-inputText w50 mR20">
              <Select id="Part" className={`select required`}
                styles={customStylesPart}
                options={workpieceSetting}
                value={selPart} 
                onChange={(e) => handleSelChange(e, "part")}
                placeholder={t("select")}
                isSearchable={true}
                isClearable={true}
                isDisabled={behavior === "update" && actualQuantity > 0}
              />
              <label htmlFor="Part">{t("part")}</label>

              {
                judgeCheckErr && selPart === null ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }
            </div>

            <div className="modal-inputText w50">
              <Select id="Process"
                styles={customStylesProcess}
                options={processSetting}
                value={selProcess} 
                onChange={(e) => handleSelChange(e, "process")}
                placeholder={t("select")}
                isSearchable={true}
                isClearable={true}
                className={`select required`}
                isDisabled={behavior === "update" && actualQuantity > 0}
              />
              <label htmlFor="Process">{t("process")}</label>
              {
                selPart !== null && processSetting?.length === 0 ?
                  <span className="errortext">{t("noProcess")}</span> : null
              }

              {
                judgeCheckErr && selProcess === null ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }

              {
                judgeCheckErr && selProcess !== null && checkProcessIsUsed ?
                  <span className="errortext">{t("notProcessSameOrderID")}</span> : null
              }

            </div>
            
            <div className="modal-inputText w50 mR20">
              <input type="date" id="ScheduledDeliveryDate" 
                name="ScheduledDeliveryDate"
                value={scheduledDeliveryDate}
                onChange={handleInputChange}
                required
                className={`${judgeCheckErr && (scheduledDeliveryDate === "" || !isTodayAfter(scheduledDeliveryDate)) ? "error" : ""}`}
              />
              <label htmlFor="ScheduledDeliveryDate">{t("scheduledDeliveryDate")}</label>
              {
                judgeCheckErr && scheduledDeliveryDate === "" ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }
              {
                judgeCheckErr && scheduledDeliveryDate !== "" && !isTodayAfter(scheduledDeliveryDate) ?
                  <span className='errortext'>{t("pastDateAllowed")}</span> : null
              }
            </div>                        
            <div className="modal-inputText w50">
              <input type="text" id="ScheduledProductionQuantity" 
                name="ScheduledProductionQuantity"
                value={scheduledProductionQuantity}
                onChange={handleInputChange}
                required
                className={`${judgeCheckErr && (scheduledProductionQuantity === "" || (!isNumber(parseFloat(scheduledProductionQuantity)) || !isPositiveInteger(parseFloat(scheduledProductionQuantity)))) ? "error" : ""}`}
              />
              <label htmlFor="ScheduledProductionQuantity">{t("scheduledProductionQuantity")}</label>
              {
                judgeCheckErr && scheduledProductionQuantity === "" ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }
              {
                judgeCheckErr && scheduledProductionQuantity !== "" && (!isNumber(parseFloat(scheduledProductionQuantity)) || !isPositiveInteger(parseFloat(scheduledProductionQuantity))) ?
                  <span className="errortext">{t("errorGreaterOne")}</span> : null
              }
            </div>
            
            {
              !(estDuration.min === 0 && estDuration.max === 0) ?
                <div className='modal-trialCalculation'>
                  <div className='subTitle'>{t("estDuration")}</div>
                  <div className='value'>{formatTimeDays(estDuration.min, t)}{estDuration.max != 0 ? " ~ " + formatTimeDays(estDuration.max, t) : ""}</div>
                </div> : null
            }
            
            {
              !(equipment === "" && processRemark === "") ?
                <div className='modal-applicableEquipment'>
                  <div className='title'>{t("applicableEquipment")}</div>
                  <div className='content'>{equipment}</div>
                  <div className='title mt-2'>{t("remark")}</div>
                  <div className='content'>{processRemark}</div>
                </div> : null
            }

            <hr />

            <div className="modal-inputText w100">
                <textarea id="Remark" 
                  name="Remark"
                  value={remark}
                  onChange={handleInputChange}  
                  className={judgeCheckErr && (remark !== "" && remark?.length > 300) ? "error" : ""}
                ></textarea>
                <label htmlFor="Remark">{t("remark")}<span className="charactor">({remark?.length ?? 0}/300)</span></label>
                {
                  judgeCheckErr && remark !== "" && remark?.length > 300 ?
                    <span className="errortext">{t("exceedsCharacterLimit")}</span> : null
                }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-gp">
            <div>
              <button className="btn btn-txt-black" onClick={handleLeave}>
                {t("cancel")}
              </button>
            </div>
            <button type="submit" 
              className="btn btn-lv1"
              onClick={handleSave}
            >{t("save")}</button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

      {/* 確定離開嗎？ */}
      <MessageModal 
        title={t("leavePage")}
        content={`${t("leavePageContent1")}<br /><br />${t("leavePageContent2")}`}
        showModal={showLeavePageModal} 
        handleConfirmData={() => {handleLeavePageClose();handleClose();loadOrderList(true);}}
        size="xs"
        confirmModalTxt={t("leaveSaving")}
        cancelTxt={t("stayThisPage")}
        cancelModal={() => {handleLeavePageClose();loadOrderList(false);}}
        handleClose={() => {handleLeavePageClose();loadOrderList(false);}}
      />

      {/* 確定儲存嗎？ */}
      <MessageModal 
        title={t("saveChanges")}
        content={`${t("saveChangesContent1")}<br /><br />${t("saveChangesContent2")}`}
        showModal={showSaveChangesModal} 
        handleConfirmData={handleAddEditOrder}
        size="xs"
        confirmModalTxt={t("confirm")}
        cancelTxt={t("cancel")}
        cancelModal={handleSaveChangesClose}
        handleClose={handleSaveChangesClose}
      />

      {/* 工單將轉成「未排程」狀態 */}
      <MessageModal 
        title={t("orderConvertedUnscheduled")}
        content={`${t("orderConvertedUnscheduledContent1")}<br /><br />${t("orderConvertedUnscheduledContent2")}`}
        showModal={showConvertedUnscheduledModal} 
        handleConfirmData={handleAddEditOrder}
        size="xs"
        confirmModalTxt={t("confirm")}
        cancelTxt={t("cancel")}
        cancelModal={handleConvertedUnscheduledClose}
        handleClose={handleConvertedUnscheduledClose}
      />
    </>
  );
};

export default WorkOrderModal;
