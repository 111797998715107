import React, {useRef, useContext, useEffect, useState} from 'react';
import { MyUserContext } from 'contexts/MyUserContext';

import { apiIdeaLogin } from 'utils/Api';

const IdeaArt = ({pageURL}) => {
  const { lang, authority, checkLogin } = useContext(MyUserContext);
  const iframeRef = useRef(null);
  
  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    setCurrentPage(`${pageURL}?t=${new Date().getTime()}`);
  }, [pageURL]);

  useEffect(() => {
    if(authority && authority.userAccount != null){
      sendMessageToIframe();
    }
  }, [lang, iframeRef, authority, checkLogin]);

  //向iframe发送消息
  const sendMessageToIframe = async () => {
    if(iframeRef != null){
      const iframe = iframeRef.current;
      // console.log("你說", iframe)
      if (iframe) {
        // console.log("進來惹")
        const [httpStatus, responseData] = await handleApiIdeaLogin();
        let json = {};
        let artUserId = "";
        if(httpStatus === 200){
          artUserId = responseData.artUserInfo?.artUserId;
          json = {
            lang: lang,
            belongsTo: responseData.artUserInfo?.belongsTo,
            artUserId: responseData.artUserInfo?.artUserId
          };
        }
        else{
          artUserId = window.artUserId;
          json = {
            lang: lang,
            belongsTo: window.belongsTo,
            artUserId: window.artUserId
          };
        }
        // console.log("父層傳遞", json)
        // console.log("父層傳遞", window.ideaUrl)
        iframe.contentWindow.postMessage(json, window.ideaUrl);
      }
    }
  };

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');
    
    let ideaLoginResponse = await apiIdeaLogin(formData);
    
    if(ideaLoginResponse){
        const httpStatus = ideaLoginResponse.request.status;
        const reponseData = ideaLoginResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    const iframe = iframeRef.current;

    // Function to check the iframe content
    const checkIframeContent = () => {
      console.log("iframe", iframe);
      console.log("iframe.contentDocument", iframe.contentDocument);
      if (iframe && iframe.contentDocument) {
        // Access the iframe's document content
        const iframeDocument = iframe.contentDocument;
        const iframeUrl = iframeDocument.location.href;
        
        if (iframeUrl !== currentPage) {
          console.error(`Iframe内容URL不匹配：${iframeUrl}（预期为 ${currentPage}）`);
          iframe.src = '';
          setTimeout(() => {
            iframe.src = currentPage;
          }, 1000);

        } else {
          console.log('Iframe加载了正确的页面。');
        }
      }
      else {
        console.error('無法訪問iframe的contentDocument。');
      }
    };

    // load事件監聽器
    iframe.addEventListener('load', checkIframeContent);

    // 清除事件監聽器
    return () => {
      iframe.removeEventListener('load', checkIframeContent);
    };
  }, [iframeRef, currentPage]);

  return (
    <iframe
        width="100%"
        src={currentPage}
        allowFullScreen
        style={{height: "calc(100vh - 97px)"}}
        ref={iframeRef}
    ></iframe>
  );
};

export default IdeaArt;