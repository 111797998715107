import Cookies from 'universal-cookie';
const cookies = new Cookies();
const domain = window.cookieDomain;

//#region ¥Î¤átoken
const LANG_NAME = 'lang';
export const setCookieLang = (lang) => {
    cookies.set(LANG_NAME, lang, { HttpOnly: true, path: "/", domain: domain });
}
export const getCookieLang = () => {
    var lang = cookies.get(LANG_NAME);
    if (lang != null) {
        return lang;
    } else {
        return null;
    }
}

export const removeCookieLang = () => {
    cookies.remove(LANG_NAME, { path: "/", domain: domain });
}

export const checkCookieLang = () => {
    var lang = cookies.get(LANG_NAME);
    if (lang != undefined && lang != null) {
        return true;
    } else {
        return false;
    }
}
//#endregion