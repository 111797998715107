import React, { useState, useEffect, useContext } from 'react';
import { apiMachineListData, apiIdeaLogin } from 'utils/Api';
import { MyUserContext } from 'contexts/MyUserContext';

export const MachineSelect = ({ choiceMachine, setChoiceMachine, setPreviousMachine }) => {
  const { authority, overtime } = useContext(MyUserContext);
  const [machineList, setMachineList] = useState(null); //機台列表


  //#region 設備內容
  useEffect(() => {
    if (authority?.userAccount != null) {
      const fetchData = async () => {
        const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
        if (httpStatusMachine == "200") {
          if (reponseDataMachine.statusCode === "20000") {
            let artMachine = reponseDataMachine.data;
            let newMachineList = [];
            const [httpStatus, reponseData] = await handleApiIdeaLogin();
            if (httpStatus === 200) {
              let ideaMachine = reponseData?.artUserInfo?.owingMachines;
              for (let item of ideaMachine) {
                let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                if (filter) {
                  newMachineList.push(filter);
                }
              }
            }
            else {
              newMachineList = artMachine;
            }

            setMachineList(newMachineList);

            if (localStorage.getItem("choiceMachine") !== null) {
              setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
              setPreviousMachine(JSON.parse(localStorage.getItem("choiceMachine")));
            }
            else {
              if (newMachineList.length > 0) {
                localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                setChoiceMachine(newMachineList[0]);
                setPreviousMachine(newMachineList[0]);
              }
              else {
                localStorage.removeItem("choiceMachine");
                setChoiceMachine(null);
                setPreviousMachine(null);
              }
            }
          }
          else if (reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104") {
            overtime();
          }
        }
      };
      fetchData();
    }
  }, [authority]);
  //#endregion

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if (machineListResponse) {
      const httpStatus = machineListResponse.request.status;
      const reponseData = machineListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');

    let ideaLoginResponse = await apiIdeaLogin(formData);

    if (ideaLoginResponse) {
      const httpStatus = ideaLoginResponse.request.status;
      const reponseData = ideaLoginResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 機台下拉選單
  const handleDropdownSelect = (obj) => {
    setPreviousMachine(choiceMachine);
    setChoiceMachine(obj);
    localStorage.setItem("choiceMachine", JSON.stringify(obj));
  };
  //#endregion

  return (
    <div className="input-rightpadding">
      <button className="input outline fit-content"
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <b>{choiceMachine?.mName}</b>
      </button>
      <div className="icon icon-Dropdown bg-greyA small"></div>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {
          machineList?.map((obj, index) => {
            return <a className="dropdown-item cursor-pointer"
              onClick={() => handleDropdownSelect(obj)}
              key={index}>{obj.mName}</a>;
          })
        }
      </div>
    </div>
    // <div className="dropdown input input-dropdown custom-machine-dropdown">
    //   <div className="btn dropdown-toggle" 
    //     data-bs-toggle="dropdown" 
    //     aria-haspopup="true" 
    //     aria-expanded="false">
    //       {choiceMachine?.mName}
    //     <div className="icon">
    //       <span className="separator"></span>
    //       <div>
    //           <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
    //               className="css-8mmkcg">
    //               <path
    //                   d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
    //               </path>
    //           </svg>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    //     {
    //       machineList?.map((obj, index) => {
    //         return <a className="dropdown-item cursor-pointer"
    //         onClick={()=> handleDropdownSelect(obj)}
    //           key={index}>{obj.mName}</a>;
    //       })
    //     }
    //   </div>
    // </div>
  );
}