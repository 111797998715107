import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { apiStorageList } from 'utils/Api';
import { formatTimeDays } from 'utils/commonFun';

import MyPagination from 'components/basic/Pagination';
import MessageModal from 'components/basic/MessageModal';
import { ToastContainer, toast } from 'react-toastify';

const StorageStatus = () => {
  const { t } = useTranslation("aps");
  const { overtime, show404Page, machineList } = useContext(MyUserContext);
  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字  
  const [storageData, setStorageData] = useState([]);  //儲位資料
  const [storageStatus, setStorageStatus] = useState([]); //上方使用者選擇狀態
  const [mid, setMid] = useState(0);
  const [mShortName, setMShortName] = useState(t("all"));
  const [descending, setDescending] = useState(false);
  const [orderField, setOrderField] = useState("equipment");
  const [currentPage, setCurrentPage] = useState(1);

  //複選下拉選單
  const dropdownRef = useRef(null);
  const scrollRef = useRef(null);
  const interval = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  let autoRefreshInterval = window.autoRefreshInterval ? window.autoRefreshInterval * 1000 : 10000;

  useEffect(() => {
    loadStorageList(); //載入儲位列表

    clearInterval(interval.current);
    interval.current = setInterval(() => {
      loadStorageList();
    }, autoRefreshInterval);
    return () => { clearInterval(interval.current); }
  }, [mid, currentPage, storageStatus, descending, orderField]);

  useEffect(() => {
    if (storageData?.totalItems > 0 && storageData?.detail?.length === 0) {
      setCurrentPage(1);
    }
  }, [storageData]);

  //#region 載入儲位列表
  const loadStorageList = async () => {
    const [httpStatus, reponseData] = await handleApiStorageList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setStorageData(reponseData.data);
      }
      else if (reponseData.statusCode === "40300") {
        show404Page();
      }
      else if (reponseData.statusCode === "40103") {
        overtime();
      }
    }
  }
  //#endregion

  //#region 載入儲位列表API
  const handleApiStorageList = async () => {
    let postJson = {
      keyword: keyword,
      mid: mid,
      statusList: storageStatus,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30,
      descending: descending,
      orderField: orderField
    };

    if (process.env.NODE_ENV === "development") { console.log("postJson", postJson); }

    if (storageStatus.length > 0) {
      postJson.statusList = storageStatus;
    }

    let response = await apiStorageList(postJson);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
  };
  //#endregion

  //#region 查詢按鈕
  const handleSearch = () => {
    loadStorageList();
  }
  //#endregion

  //#region 下拉選單狀態
  const handleOptionClick = (e) => {
    const { value, checked } = e.target;

    setStorageStatus(prevStatus => {
      if (checked) {
        return [...prevStatus, parseInt(value)];
      } else {
        return prevStatus.filter(status => status !== parseInt(value));
      }
    });
  }
  //#endregion

  //#region 下方狀態列表的打叉按鈕
  const removeStatus = (value) => {
    setStorageStatus(prevStatus => {
      return prevStatus.filter(status => status !== value);
    });
  }
  //#endregion

  //#region 移到空白地方要關掉下拉選單的工單狀態
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //#endregion

  //#region 機台下拉選單
  const handleMachineSelect = (obj) => {
    setMid(obj.mid);
    setMShortName(obj.mShortName);
  };
  //#endregion

  //#region 排序欄位
  //2025-03-11 新版JoJo問ChatGPT改成將排序按鈕直接做在表頭文字，箭頭變成只是輔助視覺效果
  const orderByCol = (field) => {
    if (orderField === field) {
      setDescending(!descending); // 如果點擊同一欄位，則切換升冪/降冪
    } else {
      setOrderField(field);
      setDescending(false); // 點擊新欄位時，預設升冪排序
    }
  };
  //#endregion

  return (
    <div className="page">
      <div className="filterRow">
        <div>
          <div className="input-rightpadding h38">
            <button className="input outline fit-content h-100" type="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {t(mShortName)}
            </button>
            <div className="icon icon-Dropdown bg-greyA small"></div>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {
                machineList?.map((obj, index) => {
                  return <a className="dropdown-item cursor-pointer"
                    onClick={() => handleMachineSelect(obj)}
                    key={index}>{obj.mShortName}</a>;
                })
              }
            </div>
          </div>
          <div className="multi-select-dropdown" ref={dropdownRef}>
            <div className="btn-dropdown" onClick={toggleDropdown}>
              <div
                className="search-input input-dropdown"
                id="searchInput"
              >
                {t("status")}&nbsp;({storageStatus.length})
              </div>
              <i className="btn-item btn-onlyicon btn-inputdrop">
                <div className="icon icon-Dropdown"></div>
              </i>
              <i className="clear-btn">✕</i>
            </div>
            {isDropdownOpen && (
              <div className="dropdown-options d-block" id="dropdown-options">
                <label className="dropdown-option">
                  <input type="checkbox" value="0" onClick={handleOptionClick}
                    checked={storageStatus.includes(0)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("unassigned")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="1" onClick={handleOptionClick}
                    checked={storageStatus.includes(1)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("completed")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="2" onClick={handleOptionClick}
                    checked={storageStatus.includes(2)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("inProgress")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="3" onClick={handleOptionClick}
                    checked={storageStatus.includes(3)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("waiting")}</span>
                </label>
              </div>
            )}
          </div>
          <div className="btn-search">
            <input type="search" placeholder={t("search")} aria-label="Search"
              value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                if (event.key === "Enter") { handleSearch(); }
              }} />
            <button type="submit" className="btn btn-onlyicon bounce btn-lv5"
              onClick={handleSearch}>
              <div className="icon icon-Search"></div>
            </button>
          </div>
        </div>
        <div>
          <button className="btn-item btn-lv1 btn-icontext-rwd" onClick={handleSearch}>
            <div className="icon icon-UpdateToNow"></div>
            <span>{t("updateNOW")}</span>
          </button>
        </div>
      </div>
      <div>
        <div id="selectedOptionsContainer">
          {(() => {
            if (storageStatus.length > 0) {
              let result = [];
              for (let item of storageStatus) {
                let status = "";
                if (item === 0) {
                  status = t("unassigned");
                }
                else if (item === 1) {
                  status = t("completed");
                }
                else if (item === 2) {
                  status = t("inProgress");
                }
                else if (item === 3) {
                  status = t("waiting");
                }

                result.push(<div className="selected-option">{status}<span className="delete-option" onClick={() => removeStatus(item)}>✕</span></div>);
              }
              return result;
            }
          })()}
        </div>
      </div>
      <div className="page-content round table-wrapper rwd col4to2 text-keep-normal">
        {
          storageData?.detail?.length > 0 ?
            <>
              <table className="APSTable scroll" ref={scrollRef}>
                <thead className="showTHead">
                  <tr className="tLeft">
                    <th
                      className={`hoverShowIcon cursor-pointer ${orderField === "equipment" ? "clicked" : "unclicked"}`}
                      onClick={() => orderByCol("equipment")}
                    >
                      {t("equipment")}
                      <span className="sort-icons">
                        <span className={`grey8 icon-ArrowUp icon${orderField === "equipment" && !descending ? "-clicked" : ""}`}></span>
                        <span className={`grey8 icon-ArrowDown icon${orderField === "equipment" && descending ? "-clicked" : ""}`}></span>
                      </span>
                    </th>
                    <th className="c1 noShowWhenRWD">{t("storageNo")}</th>
                    <th className="c1 noShowWhenRWD">{t("storageStatus")}</th>

                    <th
                      className={`hoverShowIcon cursor-pointer ${orderField === "orderNo" ? "clicked" : "unclicked"}`}
                      onClick={() => orderByCol("orderNo")}
                    >
                      {t("workOrderID")}
                      <span className="sort-icons">
                        <span className={`grey8 icon-ArrowUp icon${orderField === "orderNo" && !descending ? "-clicked" : ""}`}></span>
                        <span className={`grey8 icon-ArrowDown icon${orderField === "orderNo" && descending ? "-clicked" : ""}`}></span>
                      </span>
                    </th>
                    <th
                      className={`hoverShowIcon cursor-pointer ${orderField === "wpNO" ? "clicked" : "unclicked"}`}
                      onClick={() => orderByCol("wpNO")}
                    >
                      {t("part")}/{t("process")}
                      <span className="sort-icons">
                        <span className={`grey8 icon-ArrowUp icon${orderField === "wpNO" && !descending ? "-clicked" : ""}`}></span>
                        <span className={`grey8 icon-ArrowDown icon${orderField === "wpNO" && descending ? "-clicked" : ""}`}></span>
                      </span>
                    </th>
                    <th
                      className={`hoverShowIcon cursor-pointer ${orderField === "wpNO" ? "clicked" : "unclicked"}`}
                      onClick={() => orderByCol("wpNO")}
                    >
                      {t("material")}
                      <span className="sort-icons">
                        <span className={`grey8 icon-ArrowUp icon${orderField === "wpNO" && !descending ? "-clicked" : ""}`}></span>
                        <span className={`grey8 icon-ArrowDown icon${orderField === "wpNO" && descending ? "-clicked" : ""}`}></span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    storageData?.detail?.map((obj, index) => {
                      return (
                        <tr className="tr" key={obj.storageID}>
                          <td data-label={t("equipment")} className="f2">{obj.equipment}</td>
                          <td data-label={t("storageNo")} className="f1">{obj.storageNo}</td>
                          <td data-label={t("storageStatus")} className="f2">
                            {(() => {
                              if (obj.storageState === 0) {
                                return <div className="status status-padding bg unscheduled">
                                  {t("unassigned")}
                                </div>
                              }
                              else if (obj.storageState === 3) {
                                return <div className="status status-padding bg scheduled">
                                  {t("waiting")}
                                </div>
                              }
                              else if (obj.storageState === 2) {
                                return <div className="status status-padding bg inproduction">
                                  {t("inProgress")}
                                </div>
                              }
                              else if (obj.storageState === 1) {
                                return <div className="status status-padding bg completed">
                                  {t("completed")}
                                </div>
                              }
                            })()
                            }
                          </td>
                          <td data-label={t("workOrderID")} className="f2">{obj.orderNo}-{obj.processNo}</td>{/*工單編號 orderNo-工序號 processNo*/}
                          <td data-label={`${t("part")} / ${t("process")}`} className="f4">
                            <div>
                              <span className="strong2">{obj.wpNO}</span> {/*工件編號 wpNO*/}
                              <span>{obj.wpName}</span>{/*工件名稱 wpName*/}
                              <span> / </span>
                              <span className="strong2">{obj.processNo}</span>{/*工序號 processNo*/}
                              <span>{obj.processName}</span>{/*工序名稱 processName*/}
                            </div>
                          </td>
                          <td data-label={t("material")} className="f2">{obj.wpNO}</td>{/*物料編號*/}
                        </tr>
                      )
                    })
                  }
                </tbody>

              </table>
              <div className="d-flex justify-content-center mt-3">
                <MyPagination totalPages={Math.ceil(storageData?.totalItems / 30)}
                  currentPage={currentPage}
                  onPageChange={handlePageChange} />
              </div>
            </> :
            <div className="table-nodata">
              <div className="nodata-text">{t("noDataFound")}</div>
            </div>
        }
      </div>
    </div>
  );
};

export default StorageStatus;
