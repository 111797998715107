import React, {useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { useFloating, offset, flip, shift } from '@floating-ui/react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { DonutChart } from 'components/utilizationRateShift/DonutChart';
import moment from 'moment';
import { isoWeekCalc, convertDateFormat, calculateTotalShiftDuration, calculateDaysBetweenDates,
    secondsToHours, decimalWithOneDecimal, judgeUtilizationRate, calculateShiftDuration,
    utilizationRateTransCss, judgeErrorTime, processShifts, generateTimeSlots,
    formatTime, formatDateTimeRange
 } from 'utils/commonFun';

import { apiGetWorkHours, apiDailySummaryData, apiShutDownDailyShiftData, apiGetRatingCriteria,
  apiDailyShiftData, apiDailyShiftStatusDurationData, apiDailyProgramDuration
 } from 'utils/Api';

import './runningTimeShift.css';


const RunningTimeShift = (props) => {
  const {choiceMachine, showSelDateRange, startDate, endDate} = props;

  const { t } = useTranslation("runningTimeShift");
  const { overtime } = useContext(MyUserContext);
  
  const [dailySummary, setDailySummary] = useState(null); //顯示稼動率(Summary)
  const [shutDownDaily, setShutDownDaily] = useState(null); //顯示停機(Day)
  const [dailyShift, setDailyShift] = useState(null); //顯示稼動率(Day)
  const [ratingCriteria, setRatingCriteria] = useState(null); //顯示評級標準

  const [periodDays, setPeriodDays] = useState(0); //區間天數
  const [workHours, setWorkHours] = useState(null); //顯示上班時間
  const [shiftsHours, setShiftsHours] = useState(0); //班別時數
  const [calShiftsHours, setCalShiftsHours] = useState([]); //計算各班別時數
  const [errorsTimes, setErrorsTimes] = useState(0); //班別期間的錯誤次數
  const [calShiftsErrorTimes, setCalShiftsErrorTimes] = useState([]); //計算各班別時數
  const [statusDuration, setStatusDuration] = useState(null); //顯示長條圖
  const [dailyProgramDuration, setDailyProgramDuration] = useState(null); //顯示加工程式長條圖



  useEffect(() => {
    if(startDate != "" && endDate != "" && choiceMachine != null){
      const fetchData = async () => {
        //#region 抓取稼動率(Summary)API
        const [httpStatusDailySummary, reponseDataDailySummary] = await handleApiDailySummary();
        if(httpStatusDailySummary == "200"){
          if(reponseDataDailySummary.statusCode === "20000"){
              setDailySummary(reponseDataDailySummary.data);
          }
          else if(reponseDataDailySummary.statusCode === "40103" || reponseDataDailySummary.statusCode === "40104"){
              overtime();
          }
        }
        //#endregion

        //#region 顯示停機(Day)API
        const [httpStatusShutDownDailyShift, reponseDataShutDownDailyShift] = await handleApiShutDownDailyShift();
        if(httpStatusShutDownDailyShift == "200"){
          if(reponseDataShutDownDailyShift.statusCode === "20000"){
            setShutDownDaily(reponseDataShutDownDailyShift.data);
          }
        }
        //#endregion

        //#region 抓取上班時間
        const [httpStatusWorkHours, reponseDataWorkHours] = await handleApiGetWorkHours();
        if(httpStatusWorkHours == "200"){
          if(reponseDataWorkHours.statusCode === "20000"){
              setWorkHours(reponseDataWorkHours.data);
          }
        }
        //#endregion

        //#region 抓取稼動率(Day)API
        const [httpStatusDailyShift, reponseDataDailyShift] = await handleApiDailyShift();
        if(httpStatusDailyShift == "200"){
          if(reponseDataDailyShift.statusCode === "20000"){
              setDailyShift(reponseDataDailyShift.data);
          }
        }
        //#endregion

        //#region 評級資料
        const [httpStatusRatingCriteria, reponseDataRatingCriteria] = await handleApiGetRatingCriteria();
        if(httpStatusRatingCriteria == "200"){
          if(reponseDataRatingCriteria.statusCode === "20000"){
            setRatingCriteria(reponseDataRatingCriteria.data);
          }
        }
        //#endregion
      
        //#region 各班別長條圖稼動率API
        const [httpStatusShiftStatusDuration, reponseDataShiftStatusDuration] = await handleApiShiftStatusDurationData();
        if(httpStatusShiftStatusDuration == "200"){
          if(reponseDataShiftStatusDuration.statusCode === "20000"){
            setStatusDuration(reponseDataShiftStatusDuration.data);
          }
        }
        //#endregion
      
        //#region 顯示加工程式長條圖API
        const [httpStatusDailyProgramDuration, reponseDataDailyProgramDurationData] = await handleApiDailyProgramDurationData();
        if(httpStatusDailyProgramDuration == "200"){
          if(reponseDataDailyProgramDurationData.statusCode === "20000"){
            setDailyProgramDuration(reponseDataDailyProgramDurationData.data);
          }
        }
        //#endregion
      }
      fetchData();

      let newPeriodDays = calculateDaysBetweenDates(startDate, endDate);
      setPeriodDays(newPeriodDays);
    }
  }, [startDate, endDate, choiceMachine, showSelDateRange]);

  //#region 抓取稼動率(Summary)API
  const handleApiDailySummary = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        id: choiceMachine.mid
    }
    
    let dailySummaryResponse = await apiDailySummaryData(postData);
    if(dailySummaryResponse){
        const httpStatus = dailySummaryResponse.request.status;
        const reponseData = dailySummaryResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 顯示停機(Day)API
  const handleApiShutDownDailyShift = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        id: choiceMachine?.mid,
        doGroupbyShift: true
    }

    let shutDownDailyShiftResponse = await apiShutDownDailyShiftData(postData);
    if(shutDownDailyShiftResponse){
        const httpStatus = shutDownDailyShiftResponse.request.status;
        const reponseData = shutDownDailyShiftResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 抓取上班時間
  const handleApiGetWorkHours = async () => {
    let workHoursResponse = await apiGetWorkHours();
    if(workHoursResponse){
        const httpStatus = workHoursResponse.request.status;
        const reponseData = workHoursResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    if(workHours != null){
      let shiftTimeList = workHours.shiftTimeList;
      let newCalShiftsHours = [];
      for(let item of shiftTimeList){
        newCalShiftsHours.push(calculateShiftDuration(item.startTime, item.endTime));
      }
      setCalShiftsHours(newCalShiftsHours);

      let newShiftsHours = calculateTotalShiftDuration(workHours.shiftTimeList);
      setShiftsHours(newShiftsHours);
    }
  }, [workHours]);
  
  //#region 計算班別期間的錯誤次數
  useEffect(() => {
    if(shutDownDaily != null){
      let newErrorsTimes = 0;
      // 根據第一天的 shiftList 初始化結果陣列長度
      let result = new Array(shutDownDaily[0].shiftList.length).fill(0);

      shutDownDaily.forEach((data, dataIndex) => {
        data.shiftList.forEach((shiftList, shiftListIndex) => {
          newErrorsTimes += shiftList.times;
          result[shiftListIndex] += shiftList.times;
        });
      });
      setErrorsTimes(newErrorsTimes);
      setCalShiftsErrorTimes(result);
    }
  }, [shutDownDaily]);
  //#endregion

  //#region 抓取稼動率(Day)API
  const handleApiDailyShift = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        id: choiceMachine.mid
    }
    
    let dailyShiftResponse = await apiDailyShiftData(postData);
    if(dailyShiftResponse){
        const httpStatus = dailyShiftResponse.request.status;
        const reponseData = dailyShiftResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 抓取評級標準
  const handleApiGetRatingCriteria = async () => {
    let ratingCriteriaResponse = await apiGetRatingCriteria();
    if(ratingCriteriaResponse){
        const httpStatus = ratingCriteriaResponse.request.status;
        const reponseData = ratingCriteriaResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion
  
  //#region 各班別長條圖稼動率API
  const handleApiShiftStatusDurationData = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        id: choiceMachine.mid,
        useCustomStartTime: true
    }

    let dailyShiftStatusDurationResponse = await apiDailyShiftStatusDurationData(postData);
    if(dailyShiftStatusDurationResponse){
        const httpStatus = dailyShiftStatusDurationResponse.request.status;
        const reponseData = dailyShiftStatusDurationResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 顯示加工程式長條圖API
  const handleApiDailyProgramDurationData = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        mid: choiceMachine.mid,
        useCustomStartTime: true
    }

    let response = await apiDailyProgramDuration(postData);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion
  
  return (
    <div className='pageContent'>
      <Summary t={t} 
        startDate={startDate}
        endDate={endDate}
        workHours={workHours}
        dailySummary={dailySummary}
        shutDownDaily={shutDownDaily}
        periodDays={periodDays}
        errorsTimes={errorsTimes}
        shiftsHours={shiftsHours}
        setShiftsHours={setShiftsHours}
        // setShiftDurationSelectedPeriod={setShiftDurationSelectedPeriod}
      />

      <ShiftsStatistics t={t}
        dailySummary={dailySummary}
        periodDays={periodDays}
        calShiftsHours={calShiftsHours}
        calShiftsErrorTimes={calShiftsErrorTimes}
        ratingCriteria={ratingCriteria}
        // shiftDurationSelectedPeriod={shiftDurationSelectedPeriod}
      />

      <DailyRunningRate t={t}
        dailyShift={dailyShift}
        workHours={workHours}
        ratingCriteria={ratingCriteria}
        shutDownDaily={shutDownDaily}
        shiftsHours={shiftsHours}
      />

      <Timeline t={t}
        workHours={workHours}
        statusDuration={statusDuration}
        dailyProgramDuration={dailyProgramDuration}
      />
    </div>
  )
};

//#region 概要
const Summary = (props) => {
  const {t, startDate, endDate, workHours, dailySummary, periodDays, errorsTimes, shiftsHours} = props;
  const { jobLastUpdateDate, lang, showFormatDate, showFormatTime } = useContext(MyUserContext);
  const [transJobLastUpdateDate, setTransJobLastUpdateDate] = useState(jobLastUpdateDate);

  const [transPeriodDate, setTransPeriodDate] = useState("");
  
  const [equipmentActualRunningHours, setEquipmentActualRunningHours] = useState(0); //班別期間的設備實際運轉時長 (小時)

  useEffect(() => {
    let trans = formatDateTimeRange(showFormatDate, showFormatTime, jobLastUpdateDate);
    setTransJobLastUpdateDate(`${trans.startDate} ${trans.startTime}`);


  }, [jobLastUpdateDate, showFormatDate, showFormatTime, lang]);

  useEffect(() => {
    let trans = formatDateTimeRange(showFormatDate, null, startDate, endDate);
    setTransPeriodDate(`${trans.startDate} ${trans.startTime} ~ ${trans.endDate} ${trans.endTime}`);
  }, [showFormatDate, showFormatTime, lang]);
  
  //#region 計算班別設備實際運轉時長
  useEffect(() => {
    if(dailySummary != null){
      let newEquipmentActualRunningHours = 0;
      for(let daily of dailySummary){
        
        let machining = daily.statusList?.find(d => d.status === "Machining");
        if(machining){
          newEquipmentActualRunningHours += machining.seconds;
        }
      }
      newEquipmentActualRunningHours = secondsToHours(newEquipmentActualRunningHours);
      setEquipmentActualRunningHours(newEquipmentActualRunningHours);
    }
  }, [dailySummary]);
  //#endregion

  const handleJumpMessages = () => {
    const baseUrl = window.location.origin;
    const path = '/History/Messages';
    localStorage.setItem("history_startDate", startDate + " 00:00:00");
    localStorage.setItem("history_endDate", endDate + " 23:59:59");
    const fullUrl = `${baseUrl}${path}`;
    window.open(fullUrl, '_blank');
  }

  return(
    <>
      <div className="block-title-remark">
        <div>
          <h2 className="block-title">{t("summary")}</h2>
        </div>
        <div className="remark">
          <span>{t("lastUpdate")}</span>{localStorage.getItem("runJob") != null ? t("updating") : transJobLastUpdateDate}
        </div>
      </div>
      {/* <!--第1排 全小藍框--> */}
      <div className="flex-box-111111 mtop10">
          {/* <!--第1排｜1號框--> */}
          <div className="boxstyle-blue littlebox">
              <div>
                  <div className="title">{t("selectedPeriod")}</div>
                  <div>
                      <div className="score">{t("weekNum", {e: isoWeekCalc(startDate)})}</div>
                      <div className="note">({transPeriodDate})</div>
                  </div>
              </div>
          </div>
          {/* <!--第1排｜2號框--> */}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="custom-tooltip">
                <span dangerouslySetInnerHTML={{ __html: t("numberShiftsTooltip") }} />
              </Tooltip>
            }
          >
            <div className="boxstyle-blue littlebox">
              <div>
                <div className="title">{t("numberShifts")}</div>
                <div>
                  <div>
                    <span className="score">{workHours?.shiftTimeList?.length ?? 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </OverlayTrigger>
          {/* <!--第1排｜3號框--> */}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="custom-tooltip">
                <span dangerouslySetInnerHTML={{ __html: t("totalShiftDurationPerDayTooltip") }} />
              </Tooltip>
            }
          >
            <div className="boxstyle-blue littlebox">
              <div>
                <div className="title">{t("totalShiftDurationPerDay")}<span className="unit">({t("hrs")})</span></div>
                <div>
                  <div>
                    <span className="score">{shiftsHours.toFixed(1)}</span>
                  </div>
                </div>
              </div>
            </div>
          </OverlayTrigger>
          
          {/* <!--第1排｜4號框--> */}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="custom-tooltip">
                <span dangerouslySetInnerHTML={{ __html: t("TotalShiftDurationSelectedPeriodTooltip") }} />
              </Tooltip>
            }
          >
          <div className="boxstyle-blue littlebox">
              <div>
                  <div className="title">{t("TotalShiftDurationSelectedPeriod")}<span className="unit">({t("hrs")})</span></div>
                  <div>
                      <div>
                          <div className="score">{(shiftsHours * periodDays).toFixed(1)}</div>
                      </div>
                  </div>
              </div>
          </div>
          </OverlayTrigger>
          {/* <!--第1排｜5號框--> */}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="custom-tooltip">
                <span dangerouslySetInnerHTML={{ __html: t("equipmentActualRunningHoursDuringShiftsTooltip") }} />
              </Tooltip>
            }
          >
            <div className="boxstyle-blue littlebox">
              <div>
                <div className="title">{t("equipmentActualRunningHoursDuringShifts")}<span className="unit">({t("hrs")})</span></div>
                <div>
                  <div>
                    <span className="score">{equipmentActualRunningHours}</span>
                  </div>
                </div>
              </div>
            </div>
          </OverlayTrigger>
          
          {/* <!--第1排｜6號框--> */}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="custom-tooltip">
                <span dangerouslySetInnerHTML={{ __html: t("numberErrorsDuringShiftsTooltip") }} />
              </Tooltip>
            }
          >
            <div className="boxstyle-blue littlebox cursor-pointer" onClick={handleJumpMessages}>
              <div>
                <div className="title">{t("numberErrorsDuringShifts")}</div>
                <div>
                  <div>
                      <span className="score">{errorsTimes}</span>
                  </div>
                </div>
              </div>
            </div>
          </OverlayTrigger>
          
      </div>
      {/* <!--第1排 END--> */}
    </>
  );
};
//#endregion

//#region 班別統計
const ShiftsStatistics = (props) => {
  const {t, dailySummary, periodDays, calShiftsHours, calShiftsErrorTimes, ratingCriteria} = props;
  const [shiftsDailyCalData, setShiftsDailyCalData] = useState(null); //使用dailySummaryAPI來計算小時以及比例

  //#region 計算班別設備實際運轉時長
  useEffect(() => {
    if(dailySummary != null && calShiftsHours.length > 0 && calShiftsErrorTimes.length > 0){
      let newShiftsDailyCalData = [];
      dailySummary.forEach((daily, dailyIndex) => {
        let newStatusList = [];
        let newUtilizationRateSummaryStatus = [
          {
              status: "Machining",
              rate: 0,
              hours: 0,
              seconds: 0,
              ratingCriteria: ""
          },
          {
              status: "Idle",
              rate: 0,
              hours: 0,
              seconds: 0,
              ratingCriteria: ""
          },
          {
              status: "Alarm",
              rate: 0,
              hours: 0,
              seconds: 0,
              ratingCriteria: ""
          },
          {
              status: "Offline",
              rate: 0,
              hours: 0,
              seconds: 0,
              ratingCriteria: ""
          }
        ];

        daily.statusList.forEach((statusList, index) => {
          const utilizationRate = decimalWithOneDecimal(statusList.seconds, periodDays * calShiftsHours[dailyIndex] * 3600);
          if(statusList.status === "Machining"){
            newUtilizationRateSummaryStatus[0].seconds = statusList.seconds;
            newUtilizationRateSummaryStatus[0].hours = secondsToHours(statusList.seconds);
            newUtilizationRateSummaryStatus[0].rate = utilizationRate;
            newUtilizationRateSummaryStatus[0].ratingCriteria = utilizationRateTransCss(judgeUtilizationRate(utilizationRate, ratingCriteria?.shiftDailyUtilizationRate));
          }
          else if(statusList.status === "Idle"){
            newUtilizationRateSummaryStatus[1].seconds = statusList.seconds;
            newUtilizationRateSummaryStatus[1].hours = secondsToHours(statusList.seconds);
            newUtilizationRateSummaryStatus[1].rate = utilizationRate;
          }
          else if(statusList.status === "Alarm"){
            newUtilizationRateSummaryStatus[2].seconds = statusList.seconds;
            newUtilizationRateSummaryStatus[2].hours = secondsToHours(statusList.seconds);
            newUtilizationRateSummaryStatus[2].rate = utilizationRate;
          }
          else{
            newUtilizationRateSummaryStatus[3].seconds = statusList.seconds;
            newUtilizationRateSummaryStatus[3].hours = secondsToHours(statusList.seconds);
            newUtilizationRateSummaryStatus[3].rate = utilizationRate;
          }
        });

        newShiftsDailyCalData.push({
          shiftName: daily.shiftName,
          statusList: newUtilizationRateSummaryStatus,
          workpieceExecuteCount: daily.workpieceExecuteCount,
          error: calShiftsErrorTimes[dailyIndex]
        });
      });
      setShiftsDailyCalData(newShiftsDailyCalData);
    }
  }, [dailySummary, calShiftsHours, calShiftsErrorTimes]);
  //#endregion

  return(
    <>
      {/* <!--第2排：班別統計--> */}
      <div className={`flex-box-${dailySummary?.length === 3 ? "111" : "1111"} mtop20`}>
        {/* <!--說明：2&4個班的時候，用class="flex-box-1111"。3個班的時候，用class="flex-box-111"--> */}
        {
          shiftsDailyCalData?.map((obj, index) => {
            return (
              <section className="box-style-white-shadow area-shift-statistic" key={index}>
                <div className={`margin20 shift${index + 1}`}>
                    {/* <!--2-1 班別名稱--> */}
                    <div className="deco-shiftname">
                        <div className="shiftname">{obj.shiftName}</div>
                        <div className="bar"></div>
                        <div className="bg"></div>
                    </div>
                    {/* <!--2-2 圓餅圖--> */}
                    <div className="chart-and-table">
                        <div>
                            <div className="chart">
                              <div>
                                <DonutChart 
                                  Operating={obj.statusList[0].rate} Idle={obj.statusList[1].rate}
                                  Error={obj.statusList[2].rate} NotUse={obj.statusList[3].rate}
                                  t={t}
                                />
                              </div>
                            </div>
                            <div>
                                <div className="chart-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <td>{t("rate")}</td>
                                                <td>{t("hours")}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                              obj.statusList?.map((detail, detailIndex) => {
                                                return (
                                                  <tr key={detailIndex}>
                                                    <th>
                                                    {(() => {
                                                      if(detailIndex === 0){
                                                        return <div className="dot running"></div>
                                                      }
                                                      else if(detailIndex === 1){
                                                        return <div className="dot idle"></div>
                                                      }  
                                                      else if(detailIndex === 2){
                                                        return <div className="dot error"></div>
                                                      } 
                                                      else{
                                                        return <div className="dot offline"></div>
                                                      }       
                                                    })()}
                                                        
                                                    </th>
                                                    <td>{detail.rate}<span className="unit">%</span></td>
                                                    <td>{detail.hours}</td>
                                                  </tr>
                                                )
                                              })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--2-3 評等--> */}
                    <div className="statistic">
                        <div className={`criteria_lv${obj.statusList[0].ratingCriteria}`}>
                            <div className="title">{t("equipmentRunningRate")}</div>
                            <div className="score">{obj.statusList[0].rate}<span className="unit">%</span></div>
                        </div>
                        <div>
                            <div className="title">{t("programRun")}</div>
                            <div className="score">{obj.workpieceExecuteCount}</div>
                        </div>
                        <div>
                            <div className="title">{t("numberErrors")}</div>
                            <div className="score">{obj.error}</div>
                        </div>
                    </div>
                </div>
            </section>
            )
          })
        }
      </div>
    </>
  );
};
//#endregion

//#region 第三排班別統計詳細
const DailyRunningRate = (props) => {
  const {t, dailyShift, workHours, ratingCriteria, shutDownDaily, shiftsHours} = props;
  const { showFormatDate } = useContext(MyUserContext);

  const [shiftsWidth, setShiftsWidth] = useState([]);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [hoveredDailyIndex, setHoveredDailyIndex] = useState(null);
  const [hoveredErrorIndex, setHoveredErrorIndex] = useState(null);

  // Detect if it's a touch device
  useEffect(() => {
    const handleTouchStart = () => setIsTouchDevice(true);
    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  const handleDailyMouseOver = (index) => {
    setHoveredDailyIndex(index); // Set the hovered column index
  };

  const handleDailyMouseOut = () => {
    setHoveredDailyIndex(null); // Clear the hovered column index when the mouse leaves
  };

  const handleTouchMove = (shiftIndex) => {
    // const touch = event.touches[0]; // Get first touch point
    // const shiftIndex = calculateShiftIndex(touch.clientX); // Implement shift index calculation
    setHoveredDailyIndex(shiftIndex);
  };

  const handleErrorMouseOver = (index) => {
    setHoveredErrorIndex(index); // Set the hovered column index
  };

  const handleErrorMouseOut = () => {
    setHoveredErrorIndex(null); // Clear the hovered column index when the mouse leaves
  };

  useEffect(() => {
    if(shiftsHours != 0 && workHours != null){
      let newShiftsWidth = [];
      for(let obj of workHours?.shiftTimeList){
        let width = decimalWithOneDecimal(calculateShiftDuration(obj.startTime, obj.endTime, "s"), shiftsHours*3600, 2);
        newShiftsWidth.push(width);
      }
      setShiftsWidth(newShiftsWidth);
    }
  }, [shiftsHours, workHours]);

  const handleJumpMessages = (shift, showDate) => {
    const baseUrl = window.location.origin;
    const path = '/History/Messages';
    localStorage.setItem("history_startDate", showDate + " " + shift.startTime);
    localStorage.setItem("history_endDate", showDate + " " + shift.endTime);
    const fullUrl = `${baseUrl}${path}`;
    window.open(fullUrl, '_blank');
  }

  return(
    <>
      {/* <!--第3排：日期列表 * 2塊--> */}
      <div className="flex-box-11 mtop20">
          {/* <!--圖表：班別｜日期列表｜設備運轉率--> */}
          <section className="box-style-white-shadow">
            <div className="chartbox-notable">
              <div className="margin20">
                <div>
                  <h3 className="title">{t("dailyRunningRateEachShift")}</h3>
                  <div className="chart_dailyList">
                    <div className="deco_shiftname">
                      <div className="space"></div>
                      <div className="chart">
                        {
                          shiftsWidth.length > 0 && workHours?.shiftTimeList?.map((obj, index) => {
                            return(
                              <div className={`shift shift${index + 1}`} style={{width: `${shiftsWidth[index]}%`}} key={index}>
                                <div className="shiftname">
                                  <div>{obj.shiftName}</div>
                                </div>
                                <div>
                                  <div className="bar"></div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className="timeline scroll-body scroll">
                        <table className="highlightable-table">
                            <tbody>
                            {
                              shiftsWidth.length > 0 && dailyShift?.shiftList?.map((obj, index) => {
                                return (
                                  <tr key={index}>
                                    <th className="date">
                                      {(() => {
                                        const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");
                                        const removeYear = cleanString(showFormatDate.replace(/YYYY/g, ""));
                                        const trans = formatDateTimeRange(removeYear, null, obj.showDate);
                                        return trans.startDate;
                                      })()}
                                      <span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span>
                                    </th>
                                    {obj.shiftList.map((shiftObj, shiftIndex) => (
                                      <td className={`shift${shiftIndex + 1} ${
                                          hoveredDailyIndex === shiftIndex ? "highlight" : ""
                                        }`} 
                                        style={{width: `${shiftsWidth[shiftIndex]}%`}} 
                                        key={shiftIndex}
                                        onMouseOver={isTouchDevice ? null : () => handleDailyMouseOver(shiftIndex)}
                                        onMouseOut={isTouchDevice ? null : handleDailyMouseOut}
                                        onTouchMove={isTouchDevice ? () => handleTouchMove(shiftIndex) : null}
                                        onTouchEnd={handleDailyMouseOut}
                                      >
                                        <div className={`score criteria_lv${utilizationRateTransCss(judgeUtilizationRate(shiftObj.rate, ratingCriteria?.shiftDailyUtilizationRate))}`}
                                          tabIndex="0">
                                          {shiftObj.rate} <span className="unit">%</span>
                                        </div>
                                      </td>
                                    ))}
                                  </tr>
                                );
                              })
                            }  
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--圖表：班別｜日期列表｜錯誤次數--> */}
          <section className="box-style-white-shadow">
              <div className="chartbox-notable">
                  <div className="margin20">
                      <div>
                          <h3 className="title">{t("dailyNumberErrorsEachShift")}</h3>
                          <div className="chart_dailyList">
                              <div className="deco_shiftname">
                                  <div className="space"></div>
                                  <div className="chart">
                                    {
                                      workHours?.shiftTimeList?.map((obj, index) => {
                                        return(
                                          <div className={`shift shift${index + 1}`} style={{width: `${shiftsWidth[index]}%`}} key={index}>
                                            <div className="shiftname">
                                              <div>{obj.shiftName}</div>
                                            </div>
                                            <div>
                                              <div className="bar"></div>
                                              <div className="bg"></div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                              </div>
                              <div className="timeline scroll-body scroll">
                                  <table className="highlightable-table">
                                      <tbody>     
                                      {
                                        shutDownDaily?.map((obj, index) => {
                                          return (
                                            <tr key={index}>
                                              <th className="date">
                                                {(() => {
                                                  const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");
                                                  const removeYear = cleanString(showFormatDate.replace(/YYYY/g, ""));
                                                  const trans = formatDateTimeRange(removeYear, null, obj.showDate);
                                                  return trans.startDate;
                                                })()}
                                                <span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span>
                                              </th>
                                              {obj.shiftList.map((shiftObj, shiftIndex) => (
                                                <td className={`shift${shiftIndex + 1} ${
                                                    hoveredErrorIndex === shiftIndex ? "highlight" : ""
                                                  }`} 
                                                  style={{width: `${shiftsWidth[shiftIndex]}%`}} 
                                                  key={shiftIndex}
                                                  onMouseOver={isTouchDevice ? null : () => handleErrorMouseOver(shiftIndex)}
                                                  onMouseOut={isTouchDevice ? null : handleErrorMouseOut}
                                                  onTouchMove={isTouchDevice ? () => handleTouchMove(shiftIndex) : null}
                                                  onTouchEnd={handleErrorMouseOut}
                                                  // onClick={()=>handleJumpMessages(workHours?.shiftTimeList[shiftIndex], obj.showDate)}
                                                >
                                                  <div className={`score error_lv${utilizationRateTransCss(judgeErrorTime(shiftObj.times, ratingCriteria?.shiftEquipmentErrorDowntime, 1, workHours?.shiftTimeList?.length))}`}
                                                    tabIndex="0">
                                                    {shiftObj.times}
                                                  </div>
                                                </td>
                                              ))}
                                            </tr>
                                          );
                                        })
                                      }      
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
    </>
  );
};
//#endregion

//#region 時間軸：有班別
const Timeline = (props) => {
  const {t, workHours, statusDuration, dailyProgramDuration} = props;
  const { lang, showFormatDate, showFormatTime } = useContext(MyUserContext);
  const [shiftsProcess, setShiftsProcess] = useState([]);  //補齊班別空白時段
  const [timeList, setTimeList] = useState([]); //時間軸上方列表
  let currentShift = 0;

  //#region 初始載入
  useEffect(() => {
    if(workHours != null){
      if(workHours.shiftTimeList.length > 0){
        let fillProcess24hr = processShifts(workHours.shiftTimeList, workHours.dailyStartTime);
        let newShiftsProcess = [];
        let totalWidth = 0;
        for(let obj of fillProcess24hr){
          let calWidth = decimalWithOneDecimal(calculateShiftDuration(obj.startTime, obj.endTime, "s"), 86400, 2);

          newShiftsProcess.push({
            shiftName: obj.shiftName,
            startTime: obj.startTime,
            endTime: obj.endTime,
            width: calWidth,
            leftWidth: totalWidth.toFixed(2)
          });
          totalWidth += parseFloat(calWidth);
        }
        setShiftsProcess(newShiftsProcess);
      }
      const updatedTime = moment(workHours.dailyStartTime, "HH:mm").add(1, 'hours').format("HH:mm");
      setTimeList(generateTimeSlots(updatedTime));
    }
  }, [workHours]);
  //#endregion

  return (
    <>
      <h2 className="block-title">{t("equipmentStatusTimeline")}</h2>
      <section>
        <div className="box-style-white-shadow chart_machinestatus mtop10">
          <div className="margin20 relative">
            {/* <!--班別的標題名稱--> */}
            <div className="deco_timeline_shiftname">
              <div className="space">
                <div className="shift-whitebg" >
                  <div style={{width: "100%"}}>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="chart">
                <div>
                {
                  shiftsProcess?.map((obj, objIndex) => {
                    if(objIndex === 0){
                      currentShift = 0;
                    }
                    return (
                      <div key={objIndex}>
                        <div style={{width: `${obj.leftWidth}%`}}></div>
                        {(() => {
                          if(obj.shiftName === "No Shift"){
                            return <div className="noshift" style={{width: `${obj.width}%`}}></div>
                          }
                          else{
                            currentShift = currentShift + 1;
                            return <div className="shift" style={{width: `${obj.width}%`}}>
                                      <div className={`shift${String(currentShift)} shiftname`}>
                                        <div>{obj.shiftName}</div>
                                      </div>
                                      <div>
                                        <div className={`shift${String(currentShift)} bar`}></div>
                                      </div>
                                    </div>
                          }       
                        })()}
                      </div>
                    )
                  })
                }
                  <div className="shift-whitebg" >
                    <div style={{width: "100%"}}>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--設備運轉時間軸--> */}
            <div className="timeline">
              <table>
                <thead  className="sticky top16">
                  <tr className="smallScreen">
                    <th></th>
                    {(() => {
                      if(workHours?.dailyStartTime){
                        let dailyStartTime = workHours.dailyStartTime.substring(0, 2);

                        if (dailyStartTime === "23" || dailyStartTime === "00" || dailyStartTime === "01" ||
                            dailyStartTime === "11" || dailyStartTime === "12" || dailyStartTime === "13") {
                          return (
                            <>
                              {
                                dailyStartTime === "23" || dailyStartTime === "00" || dailyStartTime === "01" ?
                                  <>
                                    <td colSpan="6">{t("AM")}</td>
                                    <td colSpan="6">{t("PM")}</td>
                                  </> :
                                  <>
                                    <td colSpan="6">{t("PM")}</td>
                                    <td colSpan="6">{t("AM")}</td>
                                  </>
                              }
                            </>
                          );
                        } 
                        else if (dailyStartTime === "02" || dailyStartTime === "03" || dailyStartTime === "14" || dailyStartTime === "15") {
                          return (
                            <>
                              {
                                dailyStartTime === "02" || dailyStartTime === "03" ?
                                  <>
                                    <td colSpan="5">{t("AM")}</td>
                                    <td colSpan="6">{t("PM")}</td>
                                    <td colSpan="1">{t("AM")}</td>
                                  </> :
                                  <>
                                    <td colSpan="5">{t("PM")}</td>
                                    <td colSpan="6">{t("AM")}</td>
                                    <td colSpan="1">{t("PM")}</td>
                                  </>
                              }
                            </>
                          );
                        } 
                        else if (dailyStartTime === "04" || dailyStartTime === "05" || dailyStartTime === "16" || dailyStartTime === "17") {
                          return (
                            <>
                              {
                                dailyStartTime === "04" || dailyStartTime === "05" ?
                                  <>
                                    <td colSpan="4">{t("AM")}</td>
                                    <td colSpan="6">{t("PM")}</td>
                                    <td colSpan="2">{t("AM")}</td>
                                  </> :
                                  <>
                                    <td colSpan="4">{t("PM")}</td>
                                    <td colSpan="6">{t("AM")}</td>
                                    <td colSpan="2">{t("PM")}</td>
                                  </>
                              }
                            </>
                          );
                        } 
                        else if (dailyStartTime === "06" || dailyStartTime === "07" || dailyStartTime === "18" || dailyStartTime === "19") {
                          return (
                            <>
                              {
                                dailyStartTime === "06" || dailyStartTime === "07" ?
                                  <>
                                    <td colSpan="3">{t("AM")}</td>
                                    <td colSpan="6">{t("PM")}</td>
                                    <td colSpan="3">{t("AM")}</td>
                                  </> :
                                  <>
                                    <td colSpan="3">{t("PM")}</td>
                                    <td colSpan="6">{t("AM")}</td>
                                    <td colSpan="3">{t("PM")}</td>
                                  </>
                              }
                            </>
                          );
                        } 
                        else if (dailyStartTime === "08" || dailyStartTime === "09" || dailyStartTime === "20" || dailyStartTime === "21") {
                          return (
                            <>
                              {
                                dailyStartTime === "08" || dailyStartTime === "09" ?
                                  <>
                                    <td colSpan="2">{t("AM")}</td>
                                    <td colSpan="6">{t("PM")}</td>
                                    <td colSpan="2">{t("AM")}</td>
                                  </> :
                                  <>
                                    <td colSpan="2">{t("PM")}</td>
                                    <td colSpan="6">{t("AM")}</td>
                                    <td colSpan="2">{t("PM")}</td>
                                  </>
                              }
                            </>
                          );
                        } 
                        else if (dailyStartTime === "10" || dailyStartTime === "22") {
                          return (
                            <>
                              {
                                dailyStartTime === "10" ?
                                  <>
                                    <td colSpan="1">{t("AM")}</td>
                                    <td colSpan="6">{t("PM")}</td>
                                    <td colSpan="5">{t("AM")}</td>
                                  </> :
                                  <>
                                    <td colSpan="1">{t("PM")}</td>
                                    <td colSpan="6">{t("AM")}</td>
                                    <td colSpan="5">{t("PM")}</td>
                                  </>
                              }
                            </>
                          );
                        }
                      }
                    })()}
                  </tr>
                  <tr className="time">
                    <th></th>
                    {
                      timeList?.map((obj, objIndex) => {
                        return (
                          <td key={`time${objIndex}`} className={objIndex < timeList.length - 1 && convertDateFormat(timeList[objIndex + 1], "A") != convertDateFormat(timeList[objIndex], "A") ? "crossline" : ""}>
                            {
                              lang === "en" ?
                              <>
                                {convertDateFormat(obj, `${showFormatTime.startsWith("H") ? "H" : "h"}`)}
                                <span>:{convertDateFormat(obj, "mm")}</span><br />
                                {showFormatTime.includes("A") ? <span>{convertDateFormat(obj, "A")}</span> : null}
                              </>:
                              <>
                                {showFormatTime.includes("A") ? <span>{t(`${convertDateFormat(obj, "A")}`)}<br /></span> : null}
                                {convertDateFormat(obj, `${showFormatTime.startsWith("H") ? "H" : "h"}`)}<span>:{convertDateFormat(obj, "mm")}</span>
                              </>
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody className="scroll-body scroll">
                  {
                    statusDuration?.map((obj, objIndex) => {
                      let leftWidth = 0;
                      let programLeftWidth = 0;
                      return (
                        <tr key={objIndex}>
                          <th className="date">
                            {/* <span>{moment(obj.showDate).format('MMM')} </span> {moment(obj.showDate).format('D')} */}
                            {(() => {
                              const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");
                              const removeYear = cleanString(showFormatDate.replace(/YYYY/g, ""));
                              const trans = formatDateTimeRange(removeYear, null, obj.showDate);
                              return trans.startDate;
                            })()}
                            <span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span>
                          </th>
                          <td colSpan="12">
                            <div className="chart_timeline_machinestatus">
                              <div>
                                {
                                  obj.shiftList[0].statusList?.map((statusObj, statusObjIndex) => {
                                    let statusCss = "";
                                    if(statusObj.status === "Machining"){
                                      statusCss = "running";
                                    }
                                    else if(statusObj.status === "Idle"){
                                      statusCss = "idle";
                                    }
                                    else if(statusObj.status === "Alarm"){
                                      statusCss = "error";
                                    }
                                    else if(statusObj.status === "Offline"){
                                      statusCss = "offline";
                                    }
                                    else{
                                      statusCss = "nodata";
                                    }
                                    
                                    let width = decimalWithOneDecimal(statusObj.seconds, 86400, 2);
                                    // const tooltipContent = <div className={statusCss} key={statusObjIndex}>
                                    //     <div style={{width: `${String(leftWidth)}%`}}></div>
                                    //     <div className="status" style={{width: `${String(width)}%`}}></div>
                                    //   </div>;

                                    // const block = (
                                    //   <StatusBlock
                                    //     key={statusObjIndex}
                                    //     status={statusCss}
                                    //     width={width}
                                    //     leftWidth={leftWidth}
                                    //     tooltipContent={tooltipContent}
                                    //   />
                                    // );
                                    const statusElement = (
                                        <div className={statusCss} key={statusObjIndex}>
                                          <div style={{width: `${String(leftWidth)}%`}}></div>
                                          <OverlayTrigger
                                            key={statusObjIndex}
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                <div className={`tp_machinestatus ${statusCss}`}>
                                                  {
                                                    statusObj.status === "Upcoming" ?
                                                    <div>
                                                      <div className="status">{t("upcoming")}</div>
                                                      <div className="datetime">
                                                        {t("periodOperationalDataYet")}
                                                      </div>
                                                    </div> :
                                                    <>
                                                      <div>
                                                        <div className="dot"></div>
                                                      </div>
                                                      <div>
                                                          <div className="status">
                                                          {(() => {
                                                            if(statusObj.status === "Machining"){
                                                              return t("running");
                                                            }
                                                            else if(statusObj.status === "Idle"){
                                                              return t("idlePreparing");
                                                            }
                                                            else if(statusObj.status === "Alarm"){
                                                              return t("error");
                                                            }
                                                            else if(statusObj.status === "Offline"){
                                                              return t("powerOffOffline");
                                                            }
                                                            else{
                                                              return t("noData");
                                                            }  
                                                          })()}
                                                          </div>
                                                          <div className="datetime">
                                                          {convertDateFormat(statusObj.datetimeStart, "MMM DD, YYYY")}<br />
                                                          {convertDateFormat(statusObj.datetimeStart, "hh:mm:ss A")} ~ {convertDateFormat(statusObj.datetimeEnd, "hh:mm:ss A")}
                                                          </div>
                                                          <div className="duration">
                                                              <div className="title">{t("duration")}</div>
                                                              <div className="content">{formatTime(statusObj.seconds)}</div>
                                                          </div>
                                                          <hr />
                                                          <div className="program">
                                                            {(() => {
                                                              let programExecution = 0; //執行中的程式 1:執行中的程式 2:沒有程式資料 3:暫停中的程式 4:沒有選擇程式
                                                              if(statusObj.status === "Machining"){
                                                                if(statusObj.cnc_pgm_name !== "-"){
                                                                  programExecution = 1;
                                                                }
                                                                else{
                                                                  programExecution = 2;
                                                                }
                                                              }
                                                              else if(statusObj.status === "Idle" || statusObj.status === "Alarm"){
                                                                if(statusObj.cnc_pgm_name !== "-"){
                                                                  programExecution = 3;
                                                                }
                                                                else{
                                                                  programExecution = 4;
                                                                }
                                                              } 
                                                              else if(statusObj.status === "Offline"){
                                                                programExecution = 4;
                                                              }

                                                              if(programExecution === 1){
                                                                return <div className="title">{t("programExecution")}</div>;
                                                              }
                                                              else if(programExecution === 2){
                                                                return <div className="title">{t("noProgramData")}</div>;
                                                              }
                                                              else if(programExecution === 3){
                                                                return <div className="title">{t("programHold")}</div>;
                                                              }
                                                              else if(programExecution === 4){
                                                                return <div className="title">{t("noProgramSelected")}</div>;
                                                              }
                                                            })()}
                                                            {
                                                                statusObj.status !== "Offline" && statusObj.cnc_pgm_name !== "-" ?
                                                                <div className="content">
                                                                  <div>
                                                                    {
                                                                      statusObj.wpName ?
                                                                      <span className="part">{statusObj.wpName}</span> :
                                                                      null
                                                                    }
                                                                      <span className="programname">{statusObj.cnc_pgm_name}</span>
                                                                  </div>
                                                                </div> : null
                                                              }
                                                          </div>
                                                      </div>
                                                    </>
                                                  }
                                                  
                                              </div>
                                              </Tooltip>
                                            }
                                          >
                                            <div className="status" style={{width: `${String(width)}%`}}></div>
                                          </OverlayTrigger> 
                                        </div>
                                      
                                    );

                                    leftWidth += parseFloat(width);
                                    return statusElement;
                                  })
                                }
                              </div>
                            </div>
                            <div className="chart_timeline_programrun">
                              <div>
                              {(() => {
                                if(dailyProgramDuration){
                                  const isFirstNeedStart = dailyProgramDuration[objIndex].isFirstNeedStart;
                                  const isLastNeedEnd = dailyProgramDuration[objIndex].isLastNeedEnd;
                                  const programList = dailyProgramDuration[objIndex].programList;

                                  if(Array.isArray(programList)) {
                                    const statusElements = []; // 建立一個陣列存放元素
                                    for(let [programObjIndex, programObj] of programList?.entries()){
                                      let cssStart = "run-start";
                                      let cssEnd = "run-finished";
                                      let width = decimalWithOneDecimal(programObj.seconds, 86400, 2);
                                      if(programObjIndex === 0 && !isFirstNeedStart){
                                        cssStart = "";
                                      }

                                      if(programObjIndex === programList.length - 1 && !isLastNeedEnd){
                                        cssEnd = "";
                                      }

                                      const statusElement = (
                                        <div key={`${objIndex}${programObjIndex}`}>
                                          <div style={{width: `${String(programLeftWidth)}%`}}></div>
                                          {
                                            programObj.programName != "-" ?
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>
                                                  <div className="tp_machinestatus offline">
                                                    <div>
                                                      <div className="program">
                                                        <div className="title">{t("program")}</div>
                                                        <div className="content">
                                                          <div>
                                                            {
                                                              programObj.wpName ?
                                                              <span className="part">{programObj.wpName}</span> :
                                                              null
                                                            }
                                                            <span className="programname">{programObj.programName}</span>
                                                          </div>
                                                        </div>
                                                        <div className="datetime">
                                                          {convertDateFormat(programObj.datetimeStart, "MMM DD, YYYY")}<br />
                                                          {convertDateFormat(programObj.datetimeStart, "hh:mm:ss A")} ~ {convertDateFormat(programObj.datetimeEnd, "hh:mm:ss A")}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Tooltip>
                                              }
                                            >
                                              <div className={`run ${cssStart} ${cssEnd}`} style={{width: `${String(width)}%`}}>
                                                  <div className="runshadow"></div>
                                              </div>
                                            </OverlayTrigger>:
                                            <div style={{width: `${String(width)}%`}}>
                                              <div></div>
                                            </div>
                                          }
                                        </div>
                                      );

                                      programLeftWidth += parseFloat(width);
                                      statusElements.push(statusElement); // 將元素推入陣列
                                    }
                                    return statusElements; // 返回所有元素
                                  }
                                }
                              })()}  
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            {/* <!--班別的底色--> */}
            <div className="deco_timeline_shift">
              <div className="space"></div>
              <div className="chart">
                <div>
                {
                  shiftsProcess?.map((obj, objIndex) => {
                    if(objIndex === 0){
                      currentShift = 0;
                    }
                    return (
                      <div key={objIndex}>
                        <div style={{width: `${obj.leftWidth}%`}}></div>
                        {(() => {
                          if(obj.shiftName === "No Shift"){
                            return <div className="noshift" style={{width: `${obj.width}%`}}></div>
                          }
                          else{
                            currentShift += 1;
                            return <div className={`shift${String(currentShift)} shift bg`} style={{width: `${obj.width}%`}}></div>
                          }       
                        })()}
                      </div>
                    )
                  })
                }
                </div>
              </div>
            </div>
            <ChartWithMouseLine />
          </div>
        </div>
      </section>
    </>
    
  );
};
//#endregion

const ChartWithMouseLine = () => {
  const chartRef = useRef(null);
  const verticalLineRef = useRef(null);
  const [mouseX, setMouseX] = useState(null);

  const handleMouseMove = (event) => {
    const chart = chartRef.current;
    if (chart) {
      const chartRect = chart.getBoundingClientRect();
      const mouseXPosition = event.clientX - chartRect.left;
      const mouseYPosition = event.clientY;

      if (event.clientX >= chartRect.left && event.clientX <= chartRect.right &&
        mouseYPosition >= chartRect.top && mouseYPosition <= chartRect.bottom) {
        setMouseX(mouseXPosition);
        verticalLineRef.current.style.display = 'block';
      } else {
        verticalLineRef.current.style.display = 'none';
      }
    }
  };

  const handleTouchMove = (event) => {
    const chart = chartRef.current;
    if (chart) {
      const chartRect = chart.getBoundingClientRect();
      const touch = event.touches[0]; // First touch point
      const touchXPosition = touch.clientX - chartRect.left;
      const touchYPosition = touch.clientY;

      if (touch.clientX >= chartRect.left && touch.clientX <= chartRect.right &&
        touchYPosition >= chartRect.top && touchYPosition <= chartRect.bottom) {
        setMouseX(touchXPosition);
        verticalLineRef.current.style.display = 'block';
      } else {
        verticalLineRef.current.style.display = 'none';
      }
    }
  };

  const handleTouchEnd = () => {
    verticalLineRef.current.style.display = 'none'; // Hide line when touch ends
  };

  useEffect(() => {
    const chart = chartRef.current;

    // Add mouse and touch event listeners
    document.addEventListener('mousemove', handleMouseMove);
    chart.addEventListener('touchmove', handleTouchMove);
    chart.addEventListener('touchend', handleTouchEnd);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      if (chart) {
        chart.removeEventListener('touchmove', handleTouchMove);
        chart.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, []);

  return (
    <div className="deco_timeline_mouseLine">
      <div className="space"></div>
      <div className="chart">
        <div>
          <div id="chartMouseMove" ref={chartRef} style={{ position: 'relative' }}>
            <div
              id="vertical-line"
              ref={verticalLineRef}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                width: '1px',
                backgroundColor: 'black',
                left: mouseX !== null ? `${mouseX}px` : '0',
                display: 'none',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StatusBlock = ({ status, width, leftWidth, tooltipContent }) => {
  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'top',
    middleware: [offset(8), flip(), shift({ padding: 5 })],
  });
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className={status}
      style={{ width: `${width}%`, position: 'relative', display: 'inline-block' }}
      ref={reference}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {/* 空的 div 用於 leftWidth 偏移 */}
      <div style={{ width: `${leftWidth}%`, display: 'inline-block' }}></div>
      {/* 狀態區塊 */}
      <div className="status" style={{ width: `${width}%`, display: 'inline-block' }}>
        {/* 觸發浮動元素的區域 */}
        <span style={{ cursor: 'pointer' }}>🛈</span>
      </div>

      {/* 浮動元素 */}
      {context.open && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            backgroundColor: 'white',
            border: '1px solid black',
            padding: '5px',
            zIndex: 1000,
            pointerEvents: 'none',
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default RunningTimeShift;