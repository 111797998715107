// import logo from './logo.svg';
// import './App.css';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import WorkPiece from "page/WorkPiece";

function App() {
  return (
    <>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>
      <h1>HI</h1>

      <h1>1</h1>
      <h1>222222</h1>
      <h1>3333</h1>
      <h1>4444</h1>
      <h1>5555</h1>
    </>
    
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<WorkPiece />} />
    //   </Routes>
    // </Router>
  );
}

export default App;
