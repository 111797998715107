import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Row, Col, Dropdown, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MyUserContext } from 'contexts/MyUserContext';
import {
  formatTime, timeStringToSeconds, formatTime24Hour, isValidTimeString, isNumber,
  isPositiveInteger, processUserData, formatNumberWithCommas
} from 'utils/commonFun';
import MyPagination from 'components/basic/Pagination';
import { ToastContainer, toast } from 'react-toastify';

import '../FolderMain.css';

import {
  apiProgramList, apiWorkpieceList, apiWorkpieceUnique, apiPostProgram,
  apiSingleLoadProgram, apiDelProgram, apiSingleLoadWorkpiece
} from 'utils/Api';

const Programs = ({ tabList, setTabList }) => {
  const { t } = useTranslation("folder");
  const { overtime } = useContext(MyUserContext);

  const [tableResult, setTableResult] = useState(""); //查詢結果(設備運轉) 1=>尚未新增加工程序 2=>沒有搜尋到相關內容
  const [tableList, setTableList] = useState(null); //顯示加工程序列表
  const [currentPage, setCurrentPage] = useState(1);

  const [showModal, setShowModal] = useState(false);  //加工程序Modal
  const handleClose = () => setShowModal(false);

  const [behavior, setBehavior] = useState(""); //insert:代表新增  update:代表編輯
  const [selectId, setSelectId] = useState(0);
  const [initProgramName, setInitProgramName] = useState("");  //工序名稱
  const [modalData, setModalData] = useState({
    programID: 0,
    programName: "",
    wpID: null,
    batchProductionQuantity: "",
    preProcessingDuration: 0,
    programExecutionDuration: 0,
    waitingDuration: 0,
    programDescription: ""
  });  //Modal資料

  const [duration, setDuration] = useState([
    {
      name: "preProcessingDuration",
      hours: "",
      minutes: "",
      seconds: ""
    },
    {
      name: "programExecutionDuration",
      hours: "",
      minutes: "",
      seconds: ""
    },
    {
      name: "waitingDuration",
      hours: "",
      minutes: "",
      seconds: ""
    }
  ]); //時間換算
  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息
  const [uniqueFocus, setUniqueFocus] = useState(false); //檢查工序是否focus
  const [judgeUnique, setJudgeUnique] = useState(false); //檢查是否唯一值
  const [notUniqueWorkpiece, setNotUniqueWorkpiece] = useState(null); //重複值需要列出哪一個工件

  const [workpieceList, setWorkpieceList] = useState(null); //下拉所有選單工件列表

  const [selectedMachineProgram, setSelectedMachineProgram] = useState(""); //目前選擇加工程序名稱
  const [singleWorkpiece, setSingleWorkpiece] = useState(null); //詳細工件列表

  const [showDelModal, setShowDelModal] = useState(false);  //刪除加工程序Modal
  const handleDelClose = () => setShowDelModal(false);

  const [showCannotDelModal, setShowCannotDelModal] = useState(false);  //不可刪除加工程式Modal
  const handleCannotDelClose = () => setShowCannotDelModal(false);

  const [showWorkpieceModal, setShowWorkpieceModal] = useState(false);  //工件詳細資訊Modal
  const handleWorkpieceClose = () => setShowWorkpieceModal(false);

  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字
  const [isSaving, setIsSaving] = useState(false);
  const searchRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem('programKeyword')) {
      setKeyword(localStorage.getItem('programKeyword'));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('programKeyword') && keyword != "") {
      if (searchRef.current) {
        searchRef.current.click();
      }
      localStorage.removeItem('programKeyword');
    }
  }, [keyword]);

  useEffect(() => {
    loadProgramList();
    if (process.env.NODE_ENV === 'development') { console.log("loadProgramList currentPage", currentPage); }
  }, [currentPage]);

  //#region 載入加工程序列表
  const loadProgramList = async () => {
    const [httpStatus, reponseData] = await handleApiProgramList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        const data = reponseData.data;

        if (Math.ceil(data?.totalItems / 30) >= currentPage) {
          setTableList(data);
        }
        else {
          setTableList(null);
          setCurrentPage(1);
          if (process.env.NODE_ENV === 'development') { console.log("setCurrentPage(1)"); }
        }

        if (reponseData?.data?.totalItems === 0 && keyword === "") {
          setTableResult("1");
        }
        else if (reponseData?.data?.totalItems === 0 && keyword !== "") {
          setTableResult("2");
        }
        else {
          setTableResult("");
        }
      }
      else if (reponseData.statusCode === "40103" || reponseData.statusCode === "40104") {
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取加工程序列表
  const handleApiProgramList = async () => {
    let postJson = {
      keyword: keyword,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30
    }
    let programListResponse = await apiProgramList(postJson);
    if (programListResponse) {
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 載入工件列表
  const loadWorkpieceList = async () => {
    const [httpStatus, reponseData] = await handleApiWorkpieceList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setWorkpieceList(reponseData.data.detail);
      }
      else if (reponseData.statusCode === "40103" || reponseData.statusCode === "40104") {
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = {};
    let programListResponse = await apiWorkpieceList(postJson);
    if (programListResponse) {
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 新增編輯Modal
  const handleModal = async (behavior, programID) => {
    loadWorkpieceList();
    setJudgeCheckErr(false);
    setJudgeUnique(false);
    setBehavior(behavior);
    if (behavior === "update") {
      //需要載入單一筆工序
      const [httpStatus, reponseData] = await handleApiSingleLoadProgram(programID);

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;
          setInitProgramName(data.programName);
          setJudgeUnique(true);
          data.wpID = data.wpID?.toString();
          data.batchProductionQuantity = data.batchProductionQuantity?.toString();
          setModalData(data);

          //時間換算變數要一起換算進去
          let newDuration = [
            {
              name: "preProcessingDuration",
              hours: "",
              minutes: "",
              seconds: ""
            },
            {
              name: "programExecutionDuration",
              hours: "",
              minutes: "",
              seconds: ""
            },
            {
              name: "waitingDuration",
              hours: "",
              minutes: "",
              seconds: ""
            }
          ];
          if (data.preProcessingDuration !== null) {
            let formatDuration = formatTime(data.preProcessingDuration);
            newDuration[0].hours = formatDuration.substring(0, 2) === "00" ? "" : formatDuration.substring(0, 2);
            newDuration[0].minutes = formatDuration.substring(3, 5) === "00" ? "" : formatDuration.substring(3, 5);
            newDuration[0].seconds = formatDuration.substring(6, 8) === "00" ? "" : formatDuration.substring(6, 8);
          }
          if (data.programExecutionDuration !== null) {
            let formatDuration = formatTime(data.programExecutionDuration);
            newDuration[1].hours = formatDuration.substring(0, 2) === "00" ? "" : formatDuration.substring(0, 2);
            newDuration[1].minutes = formatDuration.substring(3, 5) === "00" ? "" : formatDuration.substring(3, 5);
            newDuration[1].seconds = formatDuration.substring(6, 8) === "00" ? "" : formatDuration.substring(6, 8);
          }
          if (data.waitingDuration !== null) {
            let formatDuration = formatTime(data.waitingDuration);
            newDuration[2].hours = formatDuration.substring(0, 2) === "00" ? "" : formatDuration.substring(0, 2);
            newDuration[2].minutes = formatDuration.substring(3, 5) === "00" ? "" : formatDuration.substring(3, 5);
            newDuration[2].seconds = formatDuration.substring(6, 8) === "00" ? "" : formatDuration.substring(6, 8);
          }
          setDuration(newDuration);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else {
      setInitProgramName("");
      setModalData({
        programID: 0,
        programName: "",
        wpID: null,
        batchProductionQuantity: "",
        preProcessingDuration: 0,
        programExecutionDuration: 0,
        waitingDuration: 0,
        programDescription: ""
      });

      setDuration([
        {
          name: "preProcessingDuration",
          hours: "",
          minutes: "",
          seconds: ""
        },
        {
          name: "programExecutionDuration",
          hours: "",
          minutes: "",
          seconds: ""
        },
        {
          name: "waitingDuration",
          hours: "",
          minutes: "",
          seconds: ""
        }
      ]);
    }
    setSelectId(parseInt(programID));

    if (behavior != "delete") {
      setShowModal(true);
    }
    else {
      handleDelete(false, programID);
    }
  }
  //#endregion

  //#region 載入單一一筆加工程式
  const handleApiSingleLoadProgram = async (selectId) => {
    let response = await apiSingleLoadProgram(selectId, {});

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "programName") {
      setUniqueFocus(true);
    }

    setModalData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  //#endregion

  //#region Input時長欄位改變值
  const handleInputDurationChange = (e) => {
    const { name, value } = e.target;

    let newDuration = [...duration];
    // let newDuration = [...duration];

    if (name === "preProcessingDurationHours" || name === "preProcessingDurationMinutes" || name === "preProcessingDurationSeconds") {
      // let index = duration.findIndex(d => d.name === "preProcessingDuration");

      if (name === "preProcessingDurationHours") {
        newDuration[0].hours = value;
      }
      else if (name === "preProcessingDurationMinutes") {
        newDuration[0].minutes = value;
      }
      else if (name === "preProcessingDurationSeconds") {
        newDuration[0].seconds = value;
      }
    }
    else if (name === "programExecutionDurationHours" || name === "programExecutionDurationMinutes" || name === "programExecutionDurationSeconds") {
      // let index = duration.findIndex(d => d.name === "programExecutionDuration");

      if (name === "programExecutionDurationHours") {
        newDuration[1].hours = value;
      }
      else if (name === "programExecutionDurationMinutes") {
        newDuration[1].minutes = value;
      }
      else if (name === "programExecutionDurationSeconds") {
        newDuration[1].seconds = value;
      }
    }
    else if (name === "waitingDurationHours" || name === "waitingDurationMinutes" || name === "waitingDurationSeconds") {
      // let index = duration.findIndex(d => d.name === "waitingDuration");

      if (name === "waitingDurationHours") {
        newDuration[2].hours = value;
      }
      else if (name === "waitingDurationMinutes") {
        newDuration[2].minutes = value;
      }
      else if (name === "waitingDurationSeconds") {
        newDuration[2].seconds = value;
      }
    }
    setDuration(newDuration);


  }
  //#endregion

  //#region 儲存
  const handleSave = async () => {
    setIsSaving(true);
    setJudgeCheckErr(true);

    //檢查欄位是否有錯
    let judgeErr = validateModalData();
    if (!judgeErr) {
      const [httpStatusUser, reponseDataUser] = await handleApiPostProgram();
      if (httpStatusUser == "200") {
        if (reponseDataUser.statusCode === "20100") {
          toast(<>
            {
              behavior == "insert" ?
                <span>{t("createdSuccessfully")}</span> :
                <span>{t("editingCompletedSaved")}</span>
            }
          </>, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          loadProgramList();
          if (process.env.NODE_ENV === 'development') { console.log("loadProgramList handleSave"); }
          handleClose();
        }
        else {
          toast.error(reponseDataUser.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    setIsSaving(false);
  }
  //#endregion

  //#region 檢查欄位值
  const validateModalData = () => {
    let judgeErr = false;

    Object.keys(modalData).forEach((key) => {
      if (!judgeErr) {
        if (key === "programName") {
          if (modalData["programName"] === "" || !judgeUnique || modalData["programName"].length > 100) {
            judgeErr = true;
          }
        }
        else if (key === "batchProductionQuantity") {
          if (modalData["batchProductionQuantity"] === "" || !isNumber(modalData?.batchProductionQuantity) ||
            !isPositiveInteger(parseFloat(modalData?.batchProductionQuantity))) {
            judgeErr = true;
          }
        }
        else if (key === "programDescription") {
          if (modalData["programDescription"] !== "" && modalData["programDescription"]?.length > 100) {
            judgeErr = true;
          }
        }
      }
    });

    let newDuration = { ...duration };
    if (!isValidTimeString(formatTime24Hour(newDuration[0].hours, newDuration[0].minutes, newDuration[0].seconds)) ||
      !isValidTimeString(formatTime24Hour(newDuration[1].hours, newDuration[1].minutes, newDuration[1].seconds)) ||
      !isValidTimeString(formatTime24Hour(newDuration[2].hours, newDuration[2].minutes, newDuration[2].seconds))) {
      judgeErr = true;
    }

    return judgeErr;
  }
  //#endregion

  //#region 新增以及編輯加工程序API
  const handleApiPostProgram = async () => {
    //送出需要換算秒數
    let preProcessingDuration = timeStringToSeconds(formatTime24Hour(duration[0].hours, duration[0].minutes, duration[0].seconds));
    let programExecutionDuration = timeStringToSeconds(formatTime24Hour(duration[1].hours, duration[1].minutes, duration[1].seconds));
    let waitingDuration = timeStringToSeconds(formatTime24Hour(duration[2].hours, duration[2].minutes, duration[2].seconds));

    let newModalData = { ...modalData };
    newModalData.preProcessingDuration = preProcessingDuration;
    newModalData.programExecutionDuration = programExecutionDuration;
    newModalData.waitingDuration = waitingDuration;
    newModalData.batchProductionQuantity = parseInt(newModalData.batchProductionQuantity);
    newModalData.wpID = parseInt(newModalData.wpID);

    const postData = {
      action: behavior,
      data: [processUserData(newModalData)]
    };

    let response = await apiPostProgram(postData);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 刪除工件
  const handleDelete = async (doDelete, programID) => {
    //串接刪除API
    const [httpStatus, reponseData] = await handleApiDelProgram(doDelete, programID);

    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        if (doDelete) {
          toast(<>
            <span>{t("deletionCompleted")}</span>
          </>, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          loadProgramList();
          if (process.env.NODE_ENV === 'development') { console.log("loadProgramList handleDelete"); }
          handleDelClose();
        }
        else {
          setShowDelModal(true);
        }
      }
      else if (!doDelete && reponseData.statusCode === "DL003") {
        setShowCannotDelModal(true);
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 刪除一筆加工程序
  const handleApiDelProgram = async (doDelete, programID) => {
    if (programID === undefined) {
      programID = selectId;
    }

    let postData = {
      programID: programID,
      doDelete: doDelete
    };
    let response = await apiDelProgram(postData);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 工件明細
  const handleWorkpiece = async (machineProgram, wpID) => {
    setSelectedMachineProgram(machineProgram);
    if (wpID != null) {
      const [httpStatus, reponseData] = await handleApiSingleLoadWorkpiece(wpID);

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          setSingleWorkpiece(reponseData.data);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }

      setShowWorkpieceModal(true);
    }
    else {
      setSingleWorkpiece(null);
    }
  }
  //#endregion

  //#region 載入單一一筆工件
  const handleApiSingleLoadWorkpiece = async (wpID) => {
    let response = await apiSingleLoadWorkpiece(wpID);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
  };
  //#endregion

  //#region 查詢
  const searchWorkpiece = () => {
    loadProgramList();
    if (process.env.NODE_ENV === 'development') { console.log("loadProgramList searchWorkpiece"); }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region 檢查加工程序
  const checkUnique = async (e) => {
    setUniqueFocus(false);

    if (modalData.programName != initProgramName) {
      let postData = {
        type: "programName",
        value: modalData.programName
      }
      const [httpStatus, reponseData] = await handleApiChkWorkpieceUnique(postData);

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.programName !== null) {
            setNotUniqueWorkpiece(reponseData.data);
            setJudgeUnique(false);
          }
          else {
            setJudgeUnique(true);
          }
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else {
      setJudgeUnique(true);
    }
  }
  //#endregion

  //#region 檢查加工程序是否唯一
  const handleApiChkWorkpieceUnique = async (postData) => {
    let response = await apiWorkpieceUnique(postData);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  const handleEditWorkpiece = () => {
    localStorage.setItem('workpieceKeyword', singleWorkpiece.wpNO);

    let newTabList = [...tabList];
    let currentShowTab = newTabList.findIndex(d => d.active);
    newTabList[currentShowTab].active = false;

    let changeShowTab = newTabList.findIndex(d => d.tabName === "Workpieces");
    newTabList[changeShowTab].active = true;

    setTabList(newTabList);
  }

  return (
    <>
      <div className="pageContent">
        <div className="filterRow">
          <div>
            <div className="btn-search">
              <input type="search" placeholder={t("search")} aria-label="Search"
                value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                  if (event.key === "Enter") { searchWorkpiece(); }
                }} />
              <button type="button" className="btn btn-onlyicon bounce btn-lv5"
                ref={searchRef} onClick={searchWorkpiece}>
                <div className="icon icon-Search"></div>
              </button>
            </div>
          </div>
          <div>
            <button type="button" className="btn btn-lv1 btn-icontext"
              onClick={() => handleModal("insert", 0)}>
              <div className="icon icon-Create"></div>
              <div>{t("create")}</div>
            </button>
          </div>
        </div>

        <div className="table-wrapper border rwd firstColImg text-keep-all">
          <div className="table-minheight1" ref={scrollRef}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>{t("machiningProgram")}</th>
                  <th>{t("workpieceID")} / {t("workpieceName")}</th>
                  <th className='tCenter cursor-pointer'>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("batchProductionQuantityToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {t("batchProductionQuantity")}<div className="size-XS bg-blue-light1 icon-info hover"></div>
                      </div>
                    </OverlayTrigger>
                  </th>
                  <th className='tCenter cursor-pointer'>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("preProcessingDurationToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {t("preProcessingDuration")}<div className="size-XS bg-blue-light1 icon-info hover"></div>
                      </div>
                    </OverlayTrigger>
                  </th>
                  <th className='tCenter cursor-pointer'>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("programExecutionDurationToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {t("programExecutionDuration")}<div className="size-XS bg-blue-light1 icon-info hover"></div>
                      </div>
                    </OverlayTrigger>
                  </th>
                  <th className='tCenter cursor-pointer'>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("loadingUnloadingWaitingDurationToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {t("loadingUnloadingWaitingDuration")}<div className="size-XS bg-blue-light1 icon-info hover"></div>
                      </div>
                    </OverlayTrigger>
                  </th>
                  <th>{t("programDescription")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  tableResult === "1" ?
                    <tr>
                      <td colSpan="8">
                        <div className='noDataDiv'><span className='noData'>{t("noProgramDataCreatedYet")}</span></div>
                      </td>
                    </tr>
                    : ""
                }
                {
                  tableResult === "2" ?
                    <tr>
                      <td colSpan="8">
                        <div className='noDataDiv'><span className='noData'>{t("noSearchResultsFound")}</span></div>
                      </td>
                    </tr>
                    : ""
                }
                {
                  tableList?.detail?.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='imgBlock'>
                            {
                              obj.wpImageList?.length > 0 ?
                                <ScaleImage key={index} src={obj.wpImageList[0]} className='w-100' /> :
                                ""
                            }
                          </div>
                        </td>
                        <td className="text break-all" data-label={t("machiningProgram")}>{obj.programName}</td>
                        <td className="text break-all cursor-pointer" data-label={`${t("workpieceID")} / ${t("workpieceName")}`} onClick={() => handleWorkpiece(obj.programName, obj.wpID)}><span className='text-grey8 text12'>{obj.wpNO}</span><br /><b>{obj.wpName}</b></td>
                        <td data-label={t("batchProductionQuantity")} className='tCenter'>{obj.batchProductionQuantity}</td>
                        <td data-label={t("preProcessingDuration")} className='tCenter'>{formatTime(obj.preProcessingDuration)}</td>
                        <td data-label={t("programExecutionDuration")} className='tCenter'>{formatTime(obj.programExecutionDuration)}</td>
                        <td data-label={t("loadingUnloadingWaitingDuration")} className='tCenter'>{formatTime(obj.waitingDuration)}</td>
                        <td data-label={t("programDescription")}>{obj.programDescription}&nbsp;</td>
                        <td className="tableFuncBtn">
                          <Dropdown>
                            <Dropdown.Toggle className="dropdownIcon">
                              <i className="icon icon-More"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item className='d-flex align-items-center' onClick={() => handleModal("update", obj.programID)}>
                                <div className="icon icon-Edit bg-grey8 size-M"></div>&nbsp;&nbsp;
                                <div className="text-grey4 text14">{t("edit")}</div>
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex align-items-center' onClick={() => handleModal("delete", obj.programID)}>
                                <div className="icon icon-TrashCan bg-red size-M"></div>&nbsp;&nbsp;
                                <div className="text-red text14">{t("delete")}</div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <MyPagination totalPages={Math.ceil(tableList?.totalItems / 30)} currentPage={currentPage}
            onPageChange={handlePageChange} />
        </div>
      </div>


      {/* 工序Modal */}
      <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {behavior === "insert" ? t("create") : t("edit")}<br />
            <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="programName">
                  <Form.Label className='form-label-custom'>
                    {t("machiningProgram")}
                    <span className='text-danger ms-1'>*</span>
                    <span className='form-textnum ms-1'>({modalData?.programName.length ?? 0}/100)</span>
                  </Form.Label><br />
                  <div className="input-rightpadding w-100">
                    <Form.Control
                      type="text" name="programName" value={modalData?.programName}
                      onChange={handleInputChange} onBlur={checkUnique} autoComplete="off"
                      className={`input outline w-100 ${judgeCheckErr && (modalData?.programName === "" || modalData?.programName.length > 100 || !judgeUnique) ? "input-error" : ""}`}
                    />
                    {
                      modalData?.programName != "" && modalData?.programName != null && judgeUnique && !uniqueFocus ?
                        <div className="bg-green icon icon-CheckCircle"></div> :
                        modalData?.programName != "" && modalData?.programName != null && !uniqueFocus ?
                          <div className="bg-red icon icon-CrossCircle"></div> : ""
                    }
                  </div>
                  {/* <div className="input-with-iconAccount">
                    <Form.Control
                      type="text" name="programName" value={modalData?.programName}
                      onChange={handleInputChange} onBlur={checkUnique} autoComplete="off"
                      className={`border-0 ${judgeCheckErr && (modalData?.programName === "" || modalData?.programName.length > 100 || !judgeUnique) ? "input-error" : ""}`}
                    />
                    {
                      modalData?.programName != "" && modalData?.programName != null && judgeUnique && !uniqueFocus ?
                        <span className="material-symbols-rounded-unique inputIcon">check_circle</span> :
                        modalData?.programName != "" && modalData?.programName != null && !uniqueFocus ?
                          <span className="material-symbols-rounded-uniqueCancel inputIcon">cancel</span> : ""
                    }
                  </div> */}

                  {
                    judgeCheckErr && modalData?.programName === "" ?
                      <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && modalData?.programName !== "" && modalData?.programName.length > 100 ?
                      <Form.Label className='text-danger mb-0'>
                        {t("exceedsCharacterLimit")}
                      </Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && modalData?.programName !== "" && modalData?.programName.length <= 100 && !judgeUnique ?
                      <Form.Label className='text-danger mb-0'>
                        {
                          notUniqueWorkpiece?.wpNO === null && notUniqueWorkpiece?.wpName === null ?
                            t("machingProgramAlreadyTakenNotWorkpiece") :
                            t("machingProgramAlreadyTaken", { workpiece: `${notUniqueWorkpiece?.wpNO} ${notUniqueWorkpiece?.wpName}` })
                        }
                      </Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="wpID">
                  <Form.Label className='form-label-custom'>
                    {t("workpiece")}
                  </Form.Label><br />
                  <Form.Select name="wpID" value={modalData?.wpID === null ? "" : modalData?.wpID}
                    onChange={handleInputChange}
                  >
                    <option value="">{t("selectWorkpiece")}</option>
                    {
                      workpieceList?.map((obj, index) => {
                        return (
                          <option value={obj.wpID} key={index}>{obj.wpNO + " " + obj.wpName}</option>
                        )
                      })
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="batchProductionQuantity">
                  <Form.Label className='form-label-custom'>
                    {t("batchProductionQuantity")}
                    <span className='text-danger ms-1'>*</span>
                  </Form.Label><br />
                  <Form.Control
                    type="text" autoComplete="off" name="batchProductionQuantity"
                    onChange={handleInputChange} value={modalData?.batchProductionQuantity}
                    className={judgeCheckErr && (modalData?.batchProductionQuantity === "" || !isNumber(modalData?.batchProductionQuantity) || !isPositiveInteger(parseFloat(modalData?.batchProductionQuantity))) ? "input-error" : ""}
                  />

                  {
                    judgeCheckErr && modalData?.batchProductionQuantity === "" ?
                      <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                      <></>
                  }

                  {
                    judgeCheckErr && modalData?.batchProductionQuantity !== "" && !isNumber(modalData?.batchProductionQuantity) ?
                      <Form.Label className='text-danger mb-0'>{t("formatErrorNumber")}</Form.Label> :
                      <></>
                  }

                  {
                    judgeCheckErr && modalData?.batchProductionQuantity !== "" &&
                      isNumber(modalData?.batchProductionQuantity) &&
                      !isPositiveInteger(parseFloat(modalData?.batchProductionQuantity)) ?
                      <Form.Label className='text-danger mb-0'>{t("formatErrorGreaterZero")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-3" controlId="preProcessingDuration">
                  <Form.Label className='form-label-custom timeDurationTitle'>
                    {t("preProcessingDuration")}
                  </Form.Label><br />
                  <div className={`timeDurationDiv ${judgeCheckErr && !isValidTimeString(formatTime24Hour(duration[0].hours, duration[0].minutes, duration[0].seconds)) ? "error" : ""}`}>
                    <input type="text" id="preProcessingDurationHours" name="preProcessingDurationHours" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[0].hours} />:
                    <input type="text" name="preProcessingDurationMinutes" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[0].minutes} />:
                    <input type="text" name="preProcessingDurationSeconds" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[0].seconds} />
                  </div>
                  {
                    judgeCheckErr && !isValidTimeString(formatTime24Hour(duration[0].hours, duration[0].minutes, duration[0].seconds)) ?
                      <Form.Label className='text-danger mb-0'>{t("timeFormat")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-3" controlId="programExecutionDuration">
                  <Form.Label className='form-label-custom timeDurationTitle'>
                    {t("programExecutionDuration")}
                  </Form.Label><br />
                  <div className={`timeDurationDiv ${judgeCheckErr && !isValidTimeString(formatTime24Hour(duration[1].hours, duration[1].minutes, duration[1].seconds)) ? "error" : ""}`}>
                    <input type="text" name="programExecutionDurationHours" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[1].hours} />:
                    <input type="text" name="programExecutionDurationMinutes" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[1].minutes} />:
                    <input type="text" name="programExecutionDurationSeconds" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[1].seconds} />
                  </div>
                  {
                    judgeCheckErr && !isValidTimeString(formatTime24Hour(duration[1].hours, duration[1].minutes, duration[1].seconds)) ?
                      <Form.Label className='text-danger mb-0'>{t("timeFormat")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-3" controlId="waitingDuration">
                  <Form.Label className='form-label-custom timeDurationTitle'>
                    {t("loadingUnloadingWaitingDuration")}
                  </Form.Label><br />
                  <div className={`timeDurationDiv ${judgeCheckErr && !isValidTimeString(formatTime24Hour(duration[2].hours, duration[2].minutes, duration[2].seconds)) ? "error" : ""}`}>
                    <input type="text" name="waitingDurationHours" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[2].hours} />:
                    <input type="text" name="waitingDurationMinutes" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[2].minutes} />:
                    <input type="text" name="waitingDurationSeconds" className='timeDuration' placeholder='00'
                      onChange={handleInputDurationChange} value={duration[2].seconds} />
                  </div>
                  {
                    judgeCheckErr && !isValidTimeString(formatTime24Hour(duration[2].hours, duration[2].minutes, duration[2].seconds)) ?
                      <Form.Label className='text-danger mb-0'>{t("timeFormat")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="programDescription">
                  <Form.Label className='form-label-custom'>
                    {t("programDescription")}
                    <span className='form-textnum ms-1'>({modalData?.programDescription?.length ?? 0}/100)</span>
                  </Form.Label><br />
                  <Form.Control as="textarea" rows={4} autoComplete="off"
                    name="programDescription" value={modalData?.programDescription}
                    onChange={handleInputChange}
                    className={judgeCheckErr && (modalData?.programDescription !== "" && modalData?.programDescription?.length > 100) ? "input-error" : ""}
                  />
                  {
                    judgeCheckErr && modalData?.programDescription !== "" && modalData?.programDescription?.length > 100 ?
                      <Form.Label className='text-danger mb-0'>
                        {t("exceedsCharacterLimit")}
                      </Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
              <button type="button" className="btn btn-txt-black" onClick={handleClose}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-lv1 fat" onClick={handleSave} disabled={isSaving}>
                {t("save")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} >
              <button type="button" className="btn btn-txt-black" onClick={handleClose}>
                {t("cancel")}
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <button type="button" className="btn btn-lv1" onClick={handleSave}>
                {t("save")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      {/* 刪除加工程式(後續改) */}
      <Modal show={showDelModal} onHide={handleDelClose} size="xs" backdrop="static" keyboard={false} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("deleteThisInformation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("deleteThisInformationMsg1")}<br /><br />
          {t("deleteThisInformationMsg2")}
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
              <button type="button" className="btn btn-txt-black" onClick={handleDelClose}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-lv1 fat" onClick={() => handleDelete(true, selectId)}>
                {t("delete")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} >
              <button type="button" className="button-secondary button-formula" onClick={handleDelClose}>
                <b>{t("cancel")}</b>
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <button type="button" className="button-primary button-formula" onClick={handleDelete}>
                <b>{t("delete")}</b>
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      {/* 不可刪除加工程式 */}
      <Modal show={showCannotDelModal} onHide={handleCannotDelClose} size="xs" backdrop="static" keyboard={false} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("programCannot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("programCannotContnet1")}<br /><br />
          <a href="/APS/folder" target='_blank' className='text-blue'>{t("programCannotContnet2")}</a>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div></div>
            <div>
              <button type="button" className="btn btn-lv1 fat" onClick={handleCannotDelClose}>
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* 工件內容 */}
      <Modal show={showWorkpieceModal} onHide={handleWorkpieceClose} size="xl" backdrop="static" keyboard={false} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className='mb-0'>{t("workpiece")}</h4>
            <p className='workpieceColTitle mb-0'>{selectedMachineProgram}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrapper border">
            <div className="table-scroll">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{t("workpieceID")}</th>
                    <th>{t("workpieceName")} / {t("drawingNumber")}</th>
                    <th>{t("category")}</th>
                    <th>{t("dimensions")}</th>
                    <th>{t("keywords")}</th>
                    <th className='tRight'>{t("suggestedPrice")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    singleWorkpiece ?
                      <tr className='table-tbody-border'>
                        <td className='d-flex justify-content-center'>
                          <div className='imgBlock'>
                            {
                              singleWorkpiece.wpImageList && singleWorkpiece.wpImageList.length > 0 ?
                                <ScaleImage src={singleWorkpiece.wpImageList[0]} maxWidth="100" maxHeight="100" /> :
                                ""
                            }
                          </div>
                        </td>
                        <td className='w-10'>{singleWorkpiece.wpNO}</td>
                        <td className='w-25'><b>{singleWorkpiece.wpName}</b><br /><span className='text-grey8 text12'>{singleWorkpiece.drawingNumber}</span></td>
                        <td>{singleWorkpiece.categoryName}</td>
                        <td className='w-20'>{singleWorkpiece.dimensions}</td>
                        <td>
                          {(() => {
                            var array = singleWorkpiece.keywords;
                            var stringWithSemicolon = array?.join('; ');
                            return stringWithSemicolon;
                          })()}
                        </td>
                        <td className='tRight w-15'>{singleWorkpiece.suggestedPrice ? `$ ${formatNumberWithCommas(singleWorkpiece.suggestedPrice)}` : ""}</td>
                      </tr>
                      : <></>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
            </div>
            <div>
              <button type="button" className="btn btn-lv3 fat" onClick={handleEditWorkpiece}>
                {t("editWorkpiecesPage")}
              </button>
              <button type="button" className="btn btn-lv1 fat" onClick={handleWorkpieceClose}>
                {t("back")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer>
          <Row>
            <Col xs={12} className='d-flex justify-content-end'>
              <button type="button" className="button-secondary button-formula" onClick={handleEditWorkpiece}>
                {t("editWorkpiecesPage")}
              </button>
              <button className='button-primary ms-2' onClick={handleWorkpieceClose}>
                {t("back")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>


      <ToastContainer />
    </>
  );
};

/* 縮放圖片 */
const ScaleImage = ({ src, maxWidth, maxHeight }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    const scaleImage = (originalWidth, originalHeight, maxWidth, maxHeight) => {
      const ratioWidth = maxWidth / originalWidth;
      const ratioHeight = maxHeight / originalHeight;
      const scaleFactor = Math.min(ratioWidth, ratioHeight);
      const newWidth = Math.round(originalWidth * scaleFactor);
      const newHeight = Math.round(originalHeight * scaleFactor);
      return { width: newWidth, height: newHeight };
    };

    const updateDimensions = () => {
      const originalWidth = imageRef.current.naturalWidth;
      const originalHeight = imageRef.current.naturalHeight;
      const scaledDimensions = scaleImage(originalWidth, originalHeight, maxWidth, maxHeight);
      setDimensions(scaledDimensions);
    };

    // 监听图像加载完成事件，更新图像尺寸
    imageRef.current.addEventListener('load', updateDimensions);

    // 在组件卸载时移除事件监听器
    return () => {
      if (imageRef.current != null) {
        imageRef.current.removeEventListener('load', updateDimensions);
      }
    };
  }, [maxWidth, maxHeight, src]);

  return <img ref={imageRef} src={src} alt="Your Image" style={{ width: dimensions.width, height: dimensions.height }} />;
}

export default Programs;