import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import MyPagination from 'components/basic/Pagination';
import { isBeforeLastMonthFirstDay, convertDateFormat, isNonNegativeInteger, isNumber, formatDateTimeRange } from 'utils/commonFun';
import { apiRawDataSet } from 'utils/Api';
import { ToastContainer, toast } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';

const WorkpieceProduction = (props) => {
  const { tableResult, behavior, showData, setShowData, currentPage, setCurrentPage,
    workpieceProductionUpdateData, setWorkpieceProductionUpdateData, setFieldInstructionsJSON } = props;

  const { t } = useTranslation("workpieceProduction");
  const { lang, showFormatDate } = useContext(MyUserContext);
  const [tableHeader, setTableHeader] = useState(null); //Header名稱
  const scrollRef = useRef(null);

  useEffect(() => {
    loadRawDataSet();
  }, [lang]);

  //#region 載入原始數據表頭設定檔
  const loadRawDataSet = async () => {
    const [httpStatus, response] = await handleApiRawDataSet("workpieceProduction");
    if (httpStatus == "200") {
      if (response.statusCode === "20000") {
        setTableHeader(response.data);
        if (response.data.length > 0) {
          let json = [];
          response.data.forEach(item => {
            if (item.key === "showDate") {
              json.push({ colName: item.value, colDescribe: [t("showDate")] });
            }
            else if (item.key === "workpieceID") {
              json.push({ colName: item.value, colDescribe: [t("workpieceID")] });
            }
            else if (item.key === "workpieceName") {
              json.push({ colName: item.value, colDescribe: [t("workpieceName")] });
            }
            else if (item.key === "machiningProgram") {
              json.push({ colName: item.value, colDescribe: [t("machiningProgram")] });
            }
            else if (item.key === "productionQuantity") {
              json.push({ colName: item.value, colDescribe: [t("productionQuantity")] });
            }
            else if (item.key === "defectiveCount") {
              json.push({ colName: item.value, colDescribe: [t("defectiveCount1"), t("defectiveCount2")] });
            }
            else if (item.key === "equipmentSerialNumber") {
              json.push({ colName: item.value, colDescribe: [t("equipmentSerialNumber")] });
            }
          });
          setFieldInstructionsJSON(json);
        }
      }
    }
    else {
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 原始數據表頭設定檔API
  const handleApiRawDataSet = async (type) => {
    let response = await apiRawDataSet(type);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    //切換分頁也要儲存
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region 切換不良品數量
  const handleDefectiveCount = (e, summaryID) => {
    let { value } = e.target;
    let newShowData = { ...showData };
    let detail = newShowData.detail;
    let filterIndex = detail.findIndex(d => d.summaryID === summaryID);
    detail[filterIndex].defectiveCount = value;
    newShowData.detail = detail;
    setShowData(newShowData);
  }
  //#endregion

  //#region 確定要儲存的JSON
  const handleBlurDefectiveCount = (e, id) => {
    let { value } = e.target;
    let newShowData = { ...showData };
    let detail = newShowData.detail;
    let filterIndex = detail.findIndex(d => d.summaryID === id);
    let defectiveCount = detail[filterIndex].defectiveCount;
    let summaryID = detail[filterIndex].summaryID;
    let productionQuantity = detail[filterIndex].productionQuantity;
    if (defectiveCount === "") {
      detail[filterIndex].defectiveCount = "";

      let newWorkpieceProductionUpdateData = [...workpieceProductionUpdateData];
      let filter = newWorkpieceProductionUpdateData.findIndex(d => d.summaryID === summaryID);

      if (filter != -1) {
        newWorkpieceProductionUpdateData[filter].defectiveCount = null;
      }
      else {
        newWorkpieceProductionUpdateData.push({
          summaryID: summaryID,
          defectiveCount: null
        });
      }

      setWorkpieceProductionUpdateData(newWorkpieceProductionUpdateData);
    }
    else {
      //若格式正確
      if (!(behavior === "save" && defectiveCount !== "" && (!isNumber(defectiveCount) || !isNonNegativeInteger(defectiveCount) || parseInt(defectiveCount) > productionQuantity))) {
        //需要檢查裡面是有重複日期，重複不可以再push，要更新不良品數量
        let newWorkpieceProductionUpdateData = [...workpieceProductionUpdateData];
        let filter = newWorkpieceProductionUpdateData.findIndex(d => d.summaryID === summaryID);

        if (filter != -1) {
          newWorkpieceProductionUpdateData[filter].defectiveCount = defectiveCount;
        }
        else {
          newWorkpieceProductionUpdateData.push({
            summaryID: summaryID,
            defectiveCount: defectiveCount
          });
        }

        setWorkpieceProductionUpdateData(newWorkpieceProductionUpdateData);
      }
    }

    newShowData.detail = detail;
    setShowData(newShowData);
  }
  //#endregion

  //#region 前端介面
  return (
    <>
      <div>
        {/*上排備註*/}
        <div className='remarkDiv'>
          <div className='remarkTxt'><div>{t("remark")}</div></div>
          <div className='remarkContent'>{t("remarkContent")}</div>
        </div>
        {/*「沒有數據」提醒*/}
        {(() => {
          if (tableResult === "1") {
            return <div className='noDataDiv'><span className='noData'>{t("noDataSelected")}</span></div>;
          }
          else if (tableResult === "2") {
            return <div className='noDataDiv'><span className='noData'>{t("invalidQueryConditions")}</span></div>;
          }
          else {
            return (
              <>
                {/*表格*/}
                <div className="table-wrapper border">
                  <div className="table-minheight2" ref={scrollRef}>
                    <table>
                      <thead>
                        <tr>
                          {
                            tableHeader?.map((obj, index) => (
                              <th key={index}>
                                <span>{obj.value}</span>
                              </th>
                            ))
                          }
                          {/* <th>
                                        <span className="text-greyA">Date</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">Workpiece ID</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">Workpiece Name</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">Machining Program</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">Production Quantity</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">Defective Count</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">Equipment Serial Number</span>
                                    </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                                tableResult === "1" ? 
                                <tr>
                                  <td colSpan="7">
                                    <div className='noDataDiv'><span className='noData'>{t("noDataSelected")}</span></div>
                                  </td>
                                </tr>
                                 : ""
                              }
                              {
                                tableResult === "2" ? 
                                <tr>
                                  <td colSpan="7">
                                    <div className='noDataDiv'><span className='noData'>{t("invalidQueryConditions")}</span></div>
                                  </td>
                                </tr>
                                 : ""
                              } */}

                        {
                          showData?.detail?.map((obj, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {(() => {
                                    const trans = formatDateTimeRange(showFormatDate, null, obj.showDate);
                                    return trans.startDate;
                                  })()}
                                </td> {/* data-label={t("showDate")}*/}
                                <td>{obj.wpNO}</td> {/* data-label={t("workpieceID")}*/}
                                <td>{obj.wpName}</td> {/* data-label={t("workpieceName")}*/}
                                <td>{obj.programName}</td> {/* data-label={t("machiningProgram")}*/}
                                <td>{obj.productionQuantity}</td> {/* data-label={t("productionQuantity")*/}
                                <td> {/* data-label={t("defectiveCount")}>*/}
                                  {
                                    behavior === "save" && !isBeforeLastMonthFirstDay(convertDateFormat(obj.showDate, "YYYY/MM/DD")) ?
                                      <input value={obj.defectiveCount}
                                        onChange={(e) => handleDefectiveCount(e, obj.summaryID)}
                                        onBlur={(e) => handleBlurDefectiveCount(e, obj.summaryID)}
                                        className={`inputText`}
                                      /> : obj.defectiveCount
                                  }
                                  {
                                    behavior === "save" && obj?.defectiveCount !== "" && !isNumber(obj?.defectiveCount) ?
                                      <p className='text-danger mb-0'>{t("formatErrorOnlyNumbers")}</p> :
                                      <></>
                                  }
                                  {
                                    behavior === "save" && obj?.defectiveCount !== "" && isNumber(obj?.defectiveCount) && !isNonNegativeInteger(obj?.defectiveCount) ?
                                      <p className='text-danger mb-0'>{t("formatErrorGreaterZero")}</p> :
                                      <></>
                                  }
                                  {
                                    behavior === "save" && obj?.defectiveCount !== "" && isNumber(obj?.defectiveCount) && isNonNegativeInteger(obj?.defectiveCount) && parseInt(obj?.defectiveCount) > obj.productionQuantity ?
                                      <p className='text-danger mb-0'>{t("formatErrorOverProductionQuantity")}</p> :
                                      <></>
                                  }
                                </td>
                                <td>{obj.machineSn}</td> {/*data-label={t("equipmentSerialNumber")} */}
                              </tr>
                            )
                          })
                        }
                        {/* {
                                          behavior === "save" && !isBeforeLastMonthFirstDay(convertDateFormat(obj.showDate, "YYYY/MM/DD")) ?
                                            <input value={obj.plannedHours}
                                              onChange={(e)=>handlePlannedHours(e, convertDateFormat(obj.showDate, "YYYY-MM-DD"))}
                                              onBlur={(e)=>handleBlurPlannedHours(e, convertDateFormat(obj.showDate, "YYYY-MM-DD"))}
                                              className={`inputText ${behavior === "save" && obj?.plannedHours !== "" && (!isNumber(obj?.plannedHours) || (parseFloat(obj?.plannedHours) < 0 || parseFloat(obj?.plannedHours) > 24) || !isFormatHours(obj?.plannedHours)) ? "input-error" : ""}`}
                                            /> : obj.plannedHours
                                        } */}

                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <MyPagination totalPages={Math.ceil((showData?.totalItems) / 30)} currentPage={currentPage}
                    onPageChange={handlePageChange} />
                </div>
              </>
            )
          }
        })()}
      </div>

      <ToastContainer />
    </>
  );
};

export default WorkpieceProduction;