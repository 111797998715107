const AES = require('crypto-js');
const key = AES.enc.Utf8.parse(window.key); //十六位十六進制數密鑰
const iv = AES.enc.Utf8.parse(window.iv);  //十六位十六進制密鑰偏移量
//#region 加密
export const encrypt = (word) => {
  const src = AES.enc.Utf8.parse(word);
  const encrypted = AES.AES.encrypt(src, key, { iv, mode: AES.mode.CBC, padding: AES.pad.Pkcs7 });
  return encrypted.ciphertext.toString().toUpperCase();
}
//#endregion

//#region 解密
export const decrypt = (word) => {
  const encryptedHexStr = AES.enc.Hex.parse(word);
  const src = AES.enc.Base64.stringify(encryptedHexStr);
  const decrypt = AES.AES.decrypt(src, key, { iv, mode: AES.mode.CBC, padding: AES.pad.Pkcs7 });
  const decryptedStr = decrypt.toString(AES.enc.Utf8);
  return decryptedStr.toString();
}
//#endregion