import React, {useEffect, useState} from 'react';
import { useSSR, useTranslation } from 'react-i18next'; //語系
import { Modal, Row, Col } from 'react-bootstrap';
import './fieldInstructions.css';

import { capitalizeFirstLetter } from 'utils/commonFun';

const FieldInstructions = ({ showModal, handleClose, jsonContent, tabList }) => {
  const { t } = useTranslation("common");
  const [subTitle, setSubTitle] = useState("");

  useEffect(() => {
    if(showModal){
      let filter = tabList.filter(d => d.active);
      if(filter){
        setSubTitle(t(capitalizeFirstLetter(filter[0].tabName)));
      }
    }
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={handleClose} size="xs" backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("fieldInstructions")}
          <div className="subtitle">
            {subTitle}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
            jsonContent?.map((obj, index) => {
                return(
                    <Row key={index} className='my-2'>
                        <Col xs={4}>
                          {obj.colName}
                        </Col>
                        <Col xs={8}>
                          {
                            obj.colDescribe.length > 1 ?
                            obj.colDescribe?.map((detailObj, detailIndex) => {
                              return(<p className='mb-0' key={detailIndex}><span dangerouslySetInnerHTML={{ __html: detailObj }} /></p>)
                            }) : <span dangerouslySetInnerHTML={{ __html: obj.colDescribe[0] }} />
                          }
                        </Col>
                    </Row>
                )
            }) 
        }
        
        <Row className='mt-4'>
            <Col xs={12} className='d-flex justify-content-end' onClick={handleClose}>
                <button className='btn btn-lv1 w-100 h38'>
                    {t("iunderstand")}
                </button>
            </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default FieldInstructions;