import React, {useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { secondsToHours, formatDateTimeRange } from 'utils/commonFun';
import moment from 'moment';

import { apiGetRatingCriteria, apiGetWorkDays, apiGetMachineOperatingTime, 
  apiRawDataEquipmentRuntime, apiShutDownDailyShiftData, apiOEE } from 'utils/Api';

import Summary from 'components/overallEquipmentEfficiency/Summary';  //OEE概要
import DataCharts from 'components/overallEquipmentEfficiency/DataCharts';  //OEE圖表
import TimeLine from 'components/utilizationRate/TimeLine';

import './overallEquipmentEfficiency.css';

const OverallEquipmentEfficiency = (props) => {
  const {choiceMachine, startDate, endDate} = props;

  const { t } = useTranslation("common");
  const { overtime, jobLastUpdateDate, lang, showFormatDate, showFormatTime } = useContext(MyUserContext);

  const [transJobLastUpdateDate, setTransJobLastUpdateDate] = useState(jobLastUpdateDate);
  const [OEEApi, setOEEApi] = useState(null);

  const [equipmentRuntimeRawData, setEquipmentRuntimeRawData] = useState(null);  //設備運轉時間
  const [shutDownDaily, setShutDownDaily] = useState(null); //顯示停機(Day)
  const [shutDownDailyTimes, setShutDownDailyTimes] = useState(0); //計算停機總次數
  const [ratingCriteria, setRatingCriteria] = useState(null); //顯示評級標準
  const [workDays, setWorkDays] = useState(null); //顯示工作日
  const [machineOperatingTime, setMachineOperatingTime] = useState(null); //顯示設備運轉時間
  const [machineOperatingHours, setMachineOperatingHours] = useState(null); //計算一天工作時數

  const [quantityProducedWorkpieces, setQuantityProducedWorkpieces] = useState(0);  //生產工件總數
  const [nonDefectiveQuantityWorkpieces, setNonDefectiveQuantityWorkpieces] = useState(0);  //生產工件良品數
  const [idealProductionHours, setIdealProductionHours] = useState(0);  //理想生產時數
  const [plannedEquipmentLoadingTime, setPlannedEquipmentLoadingTime] = useState(0.0);  //設備計畫工作時間
  const [equipmentRuntimeRawDataMachining, setEquipmentRuntimeRawDataMachining] = useState(0); //運轉中時數
  const [programActualDuration, setProgramActualDuration] = useState(0); //工序實際執行加總(時數)
  const [productionPerformanceNumerator, setProductionPerformanceNumerator] = useState(0); //生產效率分子
  const [productionPerformanceDenominator, setProductionPerformanceDenominator] = useState(0); //生產效率分母

  const [rawDataIdle, setRawDataIdle] = useState(0); //閒置或準備中時數
  const [rawDataAlarm, setRawDataAlarm] = useState(0); //錯誤停機時數
  const [rawDataOffline, setRawDataOffline] = useState(0); //關機或未連線時數

  useEffect(() => {
    let trans = formatDateTimeRange(showFormatDate, showFormatTime, jobLastUpdateDate);
    setTransJobLastUpdateDate(`${trans.startDate} ${trans.startTime}`);
  }, [jobLastUpdateDate, showFormatDate, showFormatTime, lang]);
  
  //#region 
  useEffect(() => {
    if(choiceMachine != null && startDate != null && endDate != null){
      const fetchData = async () => {
        const [httpStatusEquipmentRuntime, reponseDataEquipmentRuntime] = await handleApiGetEquipmentRuntime();
        if(httpStatusEquipmentRuntime == "200"){
          if(reponseDataEquipmentRuntime.statusCode === "20000"){
            setEquipmentRuntimeRawData(reponseDataEquipmentRuntime.data.detail);
          }
          else if(reponseDataEquipmentRuntime.statusCode === "40103" || reponseDataEquipmentRuntime.statusCode === "40104"){
            overtime();
          }
        }

        //#region 顯示停機(Day)API
        const [httpStatusShutDownDailyShift, reponseDataShutDownDailyShift] = await handleApiShutDownDailyShift();
        if(httpStatusShutDownDailyShift == "200"){
            if(reponseDataShutDownDailyShift.statusCode === "20000"){
              setShutDownDaily(reponseDataShutDownDailyShift.data);
              
              if(reponseDataShutDownDailyShift.data){
                let times = 0;
                for(let data of reponseDataShutDownDailyShift.data){
                  times += data.shiftList[0].times;
                }
                setShutDownDailyTimes(times);
              }
            }
            else if(reponseDataShutDownDailyShift.statusCode === "40103" || reponseDataShutDownDailyShift.statusCode === "40104"){
              overtime();
            }
        }
        //#endregion

        //#region 評級資料
        const [httpStatusRatingCriteria, reponseDataRatingCriteria] = await handleApiGetRatingCriteria();
        if(httpStatusRatingCriteria == "200"){
          if(reponseDataRatingCriteria.statusCode === "20000"){
            setRatingCriteria(reponseDataRatingCriteria.data);
          }
          else if(reponseDataRatingCriteria.statusCode === "40103" || reponseDataRatingCriteria.statusCode === "40104"){
            overtime();
          }
        }
        //#endregion

        //#region 工作日
        const [httpStatusWorkDays, reponseDataWorkDays] = await handleApiGetWorkDays();
        if(httpStatusWorkDays == "200"){
          if(reponseDataWorkDays.statusCode === "20000"){
            setWorkDays(reponseDataWorkDays.data);
          }
          else if(reponseDataWorkDays.statusCode === "40103" || reponseDataWorkDays.statusCode === "40104"){
            overtime();
          }
        }
        //#endregion

        //#region 顯示機台運轉時間API
        const [httpStatusMachineOperatingTime, reponseDataMachineOperatingTime] = await handleApiMachineOperatingTime();
        if(httpStatusMachineOperatingTime == "200"){
          if(reponseDataMachineOperatingTime.statusCode === "20000"){
            if(reponseDataMachineOperatingTime.data.choice === 1){
              setMachineOperatingTime(reponseDataMachineOperatingTime.data.customizedTimeList);
              // let customizedTime = reponseDataMachineOperatingTime.data.customizedTimeList;

              //let calHours = 0;  //計算總時數
              //for(let time of customizedTime){
              //  calHours += parseFloat(secondsToHours(timeCalc(time.startTime, time.endTime)));
              //}
              setMachineOperatingHours(reponseDataMachineOperatingTime.data.customizedTime);
            }
            else{
              setMachineOperatingTime(null);
              setMachineOperatingHours(24);
            }
          }
          else if(reponseDataMachineOperatingTime.statusCode === "40103" || reponseDataMachineOperatingTime.statusCode === "40104"){
              overtime();
          }
        }
        //#endregion
      }

      fetchData();

      //需要串接4-1 OEE API
      loadOEE();
    }
  }, [choiceMachine, startDate, endDate]);

  useEffect(() => {
    if(machineOperatingHours != null && equipmentRuntimeRawData != null){
      let finishPlanHours = 0;
      //#region 跑起訖日期間
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        //判斷是否真實資料裡面有沒有這一天
        let filter = equipmentRuntimeRawData.find(d => d.showDate === currentDate.format("yyyy-MM-DD"));
        if(filter){
          finishPlanHours += filter.plannedHours;
        }
        else{
          //判斷星期幾
          const dayOfWeek = currentDate.day();
          //判斷是否為休息日
          let judgeRest = false;
          if((dayOfWeek === 0 && !workDays?.Sunday) || (dayOfWeek === 1 && !workDays?.Monday) ||
              (dayOfWeek === 2 && !workDays?.Tuesday) || (dayOfWeek === 3 && !workDays?.Wednesday) ||
              (dayOfWeek === 4 && !workDays?.Thursday) || (dayOfWeek === 5 && !workDays?.Friday) ||
              (dayOfWeek === 6 && !workDays?.Saturday)){
            judgeRest = true;
          }
          
          if(!judgeRest){
            finishPlanHours += machineOperatingHours;
          }
        }

        currentDate = currentDate.add(1, 'days');
      }

      setPlannedEquipmentLoadingTime(finishPlanHours.toFixed(1));
      //#endregion
    }
  }, [machineOperatingHours, equipmentRuntimeRawData]);

  useEffect(() => {
    if(equipmentRuntimeRawData){
      let newMachining = 0; //運轉中
      let newIdle = 0;  //閒置或準備中
      let newAlarm = 0;  //錯誤停機
      let newOffline = 0;  //關機或未連線
      for(let item of equipmentRuntimeRawData){
        let statusList = item.statusList;
        let filterMachining = statusList.find(d => d.status === "Machining");
        if(filterMachining){
          newMachining += filterMachining.seconds;
        }
        
        let filterIdle = statusList.find(d => d.status === "Idle");
        if(filterIdle){
          newIdle += filterIdle.seconds;
        }

        let filterAlarm = statusList.find(d => d.status === "Alarm");
        if(filterAlarm){
          newAlarm += filterAlarm.seconds;
        }

        let filterOffline = statusList.find(d => d.status === "Offline");
        if(filterOffline){
          newOffline += filterOffline.seconds;
        }
      }

      // setRawDataMachining(newMachining);
      setRawDataIdle(newIdle);
      setRawDataAlarm(newAlarm);
      setRawDataOffline(newOffline);
    }
  }, [equipmentRuntimeRawData]);

  useEffect(() => {
    if(equipmentRuntimeRawData){
      let newMachining = 0; //運轉中
      let newIdle = 0;  //閒置或準備中
      let newAlarm = 0;  //錯誤停機
      let newOffline = 0;  //關機或未連線

      for(let item of equipmentRuntimeRawData){
        let statusList = item.statusList;
        let filterMachining = statusList.find(d => d.status === "Machining");
        if(filterMachining){
          newMachining += filterMachining.seconds;
        }

        let filterIdle = statusList.find(d => d.status === "Idle");
        if(filterIdle){
          newIdle += filterIdle.seconds;
        }

        let filterAlarm = statusList.find(d => d.status === "Alarm");
        if(filterAlarm){
          newAlarm += filterAlarm.seconds;
        }

        let filterOffline = statusList.find(d => d.status === "Offline");
        if(filterOffline){
          newOffline += filterOffline.seconds;
        }
      }

      setEquipmentRuntimeRawDataMachining(newMachining);
      setRawDataIdle(newIdle);
      setRawDataAlarm(newAlarm);
      setRawDataOffline(newOffline);
    }
  }, [equipmentRuntimeRawData]);
  //#endregion

  //#region 抓取原始數據內容(設備運轉)
  const handleApiGetEquipmentRuntime = async () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      midList: [choiceMachine?.mid]
    }

    let response = await apiRawDataEquipmentRuntime(postData);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 顯示停機(Day)API
  const handleApiShutDownDailyShift = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        id: choiceMachine?.mid,
        doGroupbyShift: false
    }

    let shutDownDailyShiftResponse = await apiShutDownDailyShiftData(postData);
    if(shutDownDailyShiftResponse){
        const httpStatus = shutDownDailyShiftResponse.request.status;
        const reponseData = shutDownDailyShiftResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 抓取評級標準
  const handleApiGetRatingCriteria = async () => {
    let ratingCriteriaResponse = await apiGetRatingCriteria();
    if(ratingCriteriaResponse){
        const httpStatus = ratingCriteriaResponse.request.status;
        const reponseData = ratingCriteriaResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 抓取休息日
  const handleApiGetWorkDays = async () => {
    let workDaysResponse = await apiGetWorkDays();
    if(workDaysResponse){
        const httpStatus = workDaysResponse.request.status;
        const reponseData = workDaysResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 顯示機台運轉時間API
  const handleApiMachineOperatingTime = async () => {
    let machineOperatingTimeResponse = await apiGetMachineOperatingTime();
    if(machineOperatingTimeResponse){
        const httpStatus = machineOperatingTimeResponse.request.status;
        const reponseData = machineOperatingTimeResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region
  useEffect(() => {
    if(OEEApi != null){
      let newQuantityProducedWorkpieces = 0;  //計算生產工件總數
      let newNonDefectiveQuantityWorkpieces = 0;  //生產工件良品數
      let newIdealProduction = 0; //理想生產(秒數)
      // let newProgramActualDuration = 0;  //工序實際執行(秒數)

      let newProductionPerformanceNumerator = 0;
      let newProductionPerformanceDenominator = 0;

      for(let item of OEEApi){
        newQuantityProducedWorkpieces += item.productionQuantity;
        newNonDefectiveQuantityWorkpieces += item.nonDefectiveCount;

        let curentIdealProduction = 0; //每一個工件計算的理想生產秒數
        let currentProductionPerformanceNumerator = 0;
        if(item.programExecutionDuration === 0){  //如果沒有理想就面對現實
          curentIdealProduction = item.programActualDuration;
          currentProductionPerformanceNumerator = item.programActualDuration;
        }
        else{  //有理想就需要乘上生產數量
          curentIdealProduction = item.programExecutionDuration * item.productionQuantity;
          currentProductionPerformanceNumerator = item.programExecutionDuration * item.productionQuantity;
        }
        newIdealProduction += curentIdealProduction;

        newProductionPerformanceNumerator += currentProductionPerformanceNumerator;
        newProductionPerformanceDenominator += item.programActualDuration;
        // newProgramActualDuration += item.programActualDuration;
      }
      setQuantityProducedWorkpieces(newQuantityProducedWorkpieces);
      setNonDefectiveQuantityWorkpieces(newNonDefectiveQuantityWorkpieces);
      setIdealProductionHours(secondsToHours(newIdealProduction));
      // setProgramActualDuration(secondsToHours(newProgramActualDuration));

      setProductionPerformanceNumerator(newProductionPerformanceNumerator);
      setProductionPerformanceDenominator(newProductionPerformanceDenominator);
    }
  }, [OEEApi]);
  //#endregion

  const loadOEE = async () => {
    const [httpStatusOEE, reponseDataOEE] = await handleApiOEE();
    if(httpStatusOEE == "200"){
      if(reponseDataOEE.statusCode === "20000"){
        setOEEApi(reponseDataOEE.data);
      }
      else if(reponseDataOEE.statusCode === "40103" || reponseDataOEE.statusCode === "40104"){
        overtime();
      }
    }
  }

  //#region OEE API
  const handleApiOEE = async () => {
    if(choiceMachine?.mid){
      const postData = {
          startDate: startDate,
          endDate: endDate,
          midList: [choiceMachine?.mid]
      }

      let oeeResponse = await apiOEE(postData);
      if(oeeResponse){
          const httpStatus = oeeResponse.request.status;
          const reponseData = oeeResponse.data;

          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
  }
  //#endregion


    return(
      <div className='pageContent'>
        <div className="block-title-remark">
          <div>
            <h2 className="block-title">{t("summary")}</h2>
          </div>
          <div className="remark">
            <span>{t("lastUpdate")}</span>{localStorage.getItem("runJob") != null ? t("updating") : transJobLastUpdateDate}
          </div>
        </div>
        <Summary 
            startDate={startDate} //開始日期
            endDate={endDate}  //結束日期
            quantityProducedWorkpieces={quantityProducedWorkpieces}  //生產工件總數
            nonDefectiveQuantityWorkpieces={nonDefectiveQuantityWorkpieces}  //生產工件良品數
            idealProductionHours={idealProductionHours}  //理想生產時數
            plannedEquipmentLoadingTime={plannedEquipmentLoadingTime} //設備計畫工作時間
            equipmentRuntimeRawDataMachining={equipmentRuntimeRawDataMachining} //運轉中時數
            ratingCriteria={ratingCriteria} //評級標準
            // programActualDuration={programActualDuration} //工序實際執行加總(時數)
            productionPerformanceNumerator={productionPerformanceNumerator}
            productionPerformanceDenominator={productionPerformanceDenominator}
            machineOperatingTime={machineOperatingTime}
            machineOperatingHours={machineOperatingHours}
        />

        <h2 className="block-title">{t("dataCharts")}</h2>
        <DataCharts 
          startDate={startDate} //開始日期
          endDate={endDate}  //結束日期
          choiceMachine={choiceMachine}
          OEEApi={OEEApi}
          plannedEquipmentLoadingTime={plannedEquipmentLoadingTime}
          rawData={equipmentRuntimeRawData}
          rawDataMachining={equipmentRuntimeRawDataMachining} //區間內的實際運轉中時數
          rawDataIdle={rawDataIdle} //區間內的實際閒置或準備中
          rawDataAlarm={rawDataAlarm} //區間內的錯誤停機
          rawDataOffline={rawDataOffline}  //區間內的關機或未連線
          shutDownDaily={shutDownDaily}  //每日錯誤停機
          ratingCriteria={ratingCriteria} //API評分標準
        />

        <h2 className="block-title">{t("equipmentStatusTimeline")}</h2>
        <TimeLine 
          startDate={startDate} //開始日期
          endDate={endDate}  //結束日期
          choiceMachine={choiceMachine}
        />
      </div>
    )
}

export default OverallEquipmentEfficiency;