import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Row, Col } from 'react-bootstrap';
import { apiSingleOrder, apiScheduleContinue, apiCreatedProcessList } from 'utils/Api';
import { ToastContainer, toast } from 'react-toastify';
import { convertDateFormat, formatTimeDays, isNumber, isPositiveInteger} from 'utils/commonFun';

//#region 繼續工單
const ContinueWorkOrder = ({showModal, handleClose, orderID, loadOrderList, loadOrderCountAndStatus}) => {
  const { t } = useTranslation("aps");  

  const [workOrderDetail, setWorkOrderDetail] = useState(null);
  const [quantityContinueProduction, setQuantityContinueProduction] = useState(""); //繼續生產數量
  const [estDuration, setEstDuration] = useState({min: 0, max: 0});  //預計時長
  const [equipmentAndPrograms, setEquipmentAndPrograms] = useState(null); //設備&程式

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  useEffect(() => {
    if(showModal){
      handleSingleOrder();
      setJudgeCheckErr(false);
    }
  }, [showModal]);

  useEffect(() => {
    if(workOrderDetail != null){
      if(workOrderDetail?.requirements?.orderStatus !== 5){
        let actualQuantity = parseInt(workOrderDetail?.actualQuantity);
        let scheduledQuantity = parseInt(workOrderDetail?.requirements?.scheduledQuantity);
        if(actualQuantity < scheduledQuantity){
          let newQuantityContinueProduction = scheduledQuantity - actualQuantity;
          setQuantityContinueProduction(newQuantityContinueProduction.toString());
        }
        else{
          setQuantityContinueProduction("0");
        }
      }
      else{
        setQuantityContinueProduction("0");
      }
    }
  }, [workOrderDetail]);

  useEffect(() => {
    if(quantityContinueProduction != ""){
      if(equipmentAndPrograms != null){
        if(equipmentAndPrograms?.length > 0){
          calcEstDuration(workOrderDetail?.requirements?.processID, quantityContinueProduction);
        }
      }
    }
  }, [quantityContinueProduction, equipmentAndPrograms]);

  //#region 取得該工件已建立工序
  const GetCreatedProcessList = async (wpID) => {
    const [httpStatus, reponseData] = await handleApiCreatedProcessList(wpID);
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        setEquipmentAndPrograms(reponseData.data);
      }
    }
  }
  //#endregion

  //#region API取得該工件已建立工序
  const handleApiCreatedProcessList = async (wpID) => {
    let postJson = {
      wpID: wpID
    };
    let programListResponse = await apiCreatedProcessList(postJson);
    if(programListResponse){
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  // #region 預計時長計算
  const calcEstDuration = (processID, Quantity) => {
    let filter = equipmentAndPrograms.find(d => d.processID === processID);
    if(filter){
      let equipment = filter.equipmentAndPrograms;
      let minuteList = [];
      if(equipment.length > 0){
        for(let item of equipment){
          let program = item.program;
          if(isNumber(parseFloat(Quantity)) && isPositiveInteger(parseFloat(Quantity))){
            let batchProductionQuantity = program.batchProductionQuantity;
            let programDuration = program.programDuration;

            if(programDuration != 0){
              let singleQuantity = Math.ceil(parseInt(Quantity) / batchProductionQuantity);
              let calc = singleQuantity * programDuration;
              minuteList.push(calc);
            }
          }
        }
      }

      if(isNumber(parseFloat(Quantity)) && isPositiveInteger(parseFloat(Quantity))){
        if(minuteList.length === 0){
          setEstDuration({min: 0, max: 0});
        }
        else if(minuteList.length === 1){
          setEstDuration({min: minuteList[0], max: 0});
        }
        else{
          const max = Math.max(...minuteList);
          const min = Math.min(...minuteList);
          setEstDuration({min: min, max: max});
        }
      }
      else{
        setEstDuration({min: 0, max: 0});
      }
    }
  }
  //#endregion

   //#region 載入單一一筆工單
   const handleSingleOrder = async () => {
    const [httpStatus, reponseData] = await handleApiSingleOrder();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        let data = reponseData.data;

        setWorkOrderDetail(data);

        GetCreatedProcessList(data?.requirements?.wpID);
      }
    }
  }
  //#endregion

  //#region 載入單一一筆工單API
  const handleApiSingleOrder = async () => {
    let postJson = {
      orderID: orderID
    };
    let response = await apiSingleOrder(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion


  //#region 套用按鈕
  const handleApply = async() => {
    setJudgeCheckErr(true);

    //檢查欄位是否有問題
    let check = checkCol();
    if(!check){
      const [httpStatus, reponseData] = await handleApiScheduleContinue();
      if(httpStatus == "200"){
        if(reponseData.statusCode === "20100"){
          loadOrderList();
          handleClose();
          loadOrderCountAndStatus();
          toast(t("appliedSuccessfully"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        else{
          toast.error(t("appliedFailed"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
  }
  //#endregion

  //#region 檢查欄位是否有問題
  const checkCol = () => {
    if(quantityContinueProduction === "" || 
      (!isNumber(parseFloat(quantityContinueProduction)) || 
      !isPositiveInteger(parseFloat(quantityContinueProduction)))){
      return true;
    }

    return false;
  }
  //#endregion

  //#region 繼續生產API
  const handleApiScheduleContinue = async () => {
    let postJson = {
      orderID: orderID,
      continueQty: parseInt(quantityContinueProduction)
    };
    let response = await apiScheduleContinue(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { value } = e.target;
    setQuantityContinueProduction(value);
  };
  //#endregion
  
  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" size="md" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{t("continue")}</div>
            <div className="modal-title-note">{t("requiredFieldsAsterisk")} 
                <span className="text-red">&nbsp;*</span>
            </div>
          </div>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close"
            onClick={handleClose}>
              <div className="icon-Cross"></div>
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-applicableEquipment">
            <div className="workOrderID">{workOrderDetail?.requirements?.orderNo}-{workOrderDetail?.requirements?.processNo}</div>
            <Row>
              <Col xs={6}>
                <div className="continueTitle">{t("part")} / {t("process")}</div>
              </Col>
              <Col xs={6}>
                <div className="continueTitle">{t("scheduledDeliveryDate")}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}>
                <div className="content">
                  {workOrderDetail?.requirements?.wpNO}&nbsp;{workOrderDetail?.requirements?.wpName}&nbsp;/&nbsp;
                  {workOrderDetail?.requirements?.processNo}&nbsp;{workOrderDetail?.requirements?.processName}
                </div>
              </Col>
              <Col xs={6}>
                <div className="content">
                  {convertDateFormat(workOrderDetail?.requirements?.scheduledDeliveryDate, "MMM DD")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <div className="continueTitle">{t("plannedDeliveryQuantity")}</div>
              </Col>
              <Col xs={4}>
                <div className="continueTitle">{t("scheduledProductionQuantity")}</div>
              </Col>
              <Col xs={4}>
                <div className="continueTitle">{t("actualProducedQuantity")}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4}>
                <div className="content">{workOrderDetail?.requirements?.plannedQuantity}</div>
              </Col>
              <Col xs={4}>
                <div className="content">{quantityContinueProduction !== "" ? parseInt(quantityContinueProduction) +  workOrderDetail?.actualQuantity : null}</div>
              </Col>
              <Col xs={4}>
                <div className="content">{workOrderDetail?.actualQuantity}</div>
              </Col>
            </Row>
          </div>
          <div className="modal-inputText w100 mR20">
            <input id="QuantityContinueProduction" 
              name="QuantityContinueProduction"
              value={quantityContinueProduction}
              onChange={handleInputChange}
              required
              className={judgeCheckErr && (quantityContinueProduction === "" || (!isNumber(parseFloat(quantityContinueProduction)) || !isPositiveInteger(parseFloat(quantityContinueProduction)))) ? "error" : ""}
            />
            <label htmlFor="QuantityContinueProduction">{t("quantityContinueProduction")}</label>
            {
              judgeCheckErr && quantityContinueProduction === "" ?
                <span className="errortext">{t("requiredFields")}</span> : null
            }
            {
              judgeCheckErr && quantityContinueProduction !== "" && (!isNumber(parseFloat(quantityContinueProduction)) || !isPositiveInteger(parseFloat(quantityContinueProduction))) ?
                <span className="errortext">{t("errorGreaterOne")}</span> : null
            }
          </div>
          {
            !(estDuration.min === 0 && estDuration.max === 0) ?
              <div className='modal-trialCalculation mt-4'>
                <div className='subTitle'>{t("estDuration")}</div>
                <div className='value'>{formatTimeDays(estDuration.min, t)}{estDuration.max != 0 ? " ~ " + formatTimeDays(estDuration.max, t) : ""}</div>
              </div> : null
          }
        </div>
        <div className="modal-footer">
          <div className="btn-gp">
            <button className="btn btn-txt-black" onClick={handleClose}>{t("cancel")}</button>
            <button className="btn btn-lv1 fat" onClick={handleApply}>{t("apply")}</button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ContinueWorkOrder;
//#endregion