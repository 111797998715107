import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next'; //語系
import "./WeekPicker.css";
import { v4 } from "uuid";
import { ArrowLeft } from "./ArrowLeft";
import { ArrowRight } from "./ArrowRight";
import { addMonths, endOfWeek, startOfWeek, subMonths } from "date-fns";
import { getDaysInMonth } from "date-fns";
import { isoWeekCalc, formatDateTimeRange } from 'utils/commonFun';
import { MyUserContext } from 'contexts/MyUserContext';

export const WeekPicker = (props) => {
  const { week, setWeek, onChange, disableAfterDate } = props;

  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const { lang, showFormatDate } = useContext(MyUserContext);

  useEffect(() => {
    onChange && onChange(week);
  }, [week]);

  const isLeapYear = () => {
    let leapYear = new Date(new Date().getFullYear(), 1, 29);
    return leapYear.getDate() == 29;
  };

  const convertDate = (date) => {
    let dt = new Date(date);

    return `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}.`;
  };

  const handleClick = (e) => {
    let localDate;
    if (e.target.id.includes("prev")) {
      localDate = new Date(date.setDate(1));
      setDate(new Date(date.setDate(1)));
    } else if (e.target.id.includes("next")) {
      localDate = new Date(date.setDate(getDaysInMonth(date)));
      setDate(new Date(date.setDate(getDaysInMonth(date))));
    } else {
      localDate = new Date(date.setDate(e.target.id));
      setDate(new Date(date.setDate(e.target.id)));
    }
    const firstDay = startOfWeek(localDate, { weekStartsOn: 0 });
    const lastDay = endOfWeek(localDate, { weekStartsOn: 0 });

    if (disableAfterDate && new Date(localDate) <= new Date(disableAfterDate)) {
      setWeek({ firstDay, lastDay });
      setOpen(false);
    }
  };

  const [months, setMonths] = useState([]);

  const days = {
    "1": 31,
    "2": isLeapYear() ? 29 : 28,
    "3": 31,
    "4": 30,
    "5": 31,
    "6": 30,
    "7": 31,
    "8": 31,
    "9": 30,
    "10": 31,
    "11": 30,
    "12": 31
  };

  useEffect(() => {
    if (lang === "zh-TW") {
      setMonths([
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12"
      ]);
    }
    else {
      setMonths([
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]);
    }
  }, [lang]);

  const renderDays = () => {
    let month = date.getMonth() + 1;
    let ar = [];
    for (let i = 1; i <= days[month]; i++) {
      let currentDate = new Date(date).setDate(i);

      let cName = "single-number ";
      if (
        new Date(week.firstDay).getTime() <= new Date(currentDate).getTime() &&
        new Date(currentDate).getTime() <= new Date(week.lastDay).getTime()
      ) {
        cName = cName + "selected-week";
      }

      // Check if date should be disabled
      if (disableAfterDate && new Date(currentDate) > new Date(disableAfterDate)) {
        cName += " disabled";
      }

      ar.push(
        <div key={v4()} id={i} className={cName} onClick={handleClick}>
          {i}
        </div>
      );
    }

    const displayDate = new Date(date).setDate(1);
    let dayInTheWeek = new Date(displayDate).getDay();
    let prevMonth = [];
    let prevMonthDays = new Date(date).getMonth();
    if (prevMonthDays === 0) {
      prevMonthDays = 12;
    }
    for (let i = dayInTheWeek; i > 0; i--) {
      let previousMonth = new Date(date).setMonth(
        new Date(date).getMonth() - 1
      );
      let currentDate = new Date(previousMonth).setDate(
        days[prevMonthDays] - i + 1
      );
      let cName = "single-number other-month";
      let currentTime = new Date(currentDate).getTime();
      let firstTime = new Date(week.firstDay).getTime();
      let endTime = new Date(week.lastDay).getTime();
      if (currentTime >= firstTime && currentTime <= endTime) {
        cName = "single-number selected-week";
      }

      prevMonth.push(
        <div
          onClick={handleClick}
          key={v4()}
          id={"prev-" + i}
          className={cName}
        >
          {days[prevMonthDays] - i + 1}
        </div>
      );
    }

    let nextMonth = [];
    let fullDays = 35;
    if ([...prevMonth, ...ar].length > 35) {
      fullDays = 42;
    }

    for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
      let cName = "single-number other-month";
      const lastDay = week.lastDay.getTime();
      const lastDayOfMonth = new Date(
        new Date(date).setDate(getDaysInMonth(date))
      );

      if (lastDayOfMonth.getTime() <= lastDay && week.firstDay.getMonth() == lastDayOfMonth.getMonth()) {
        cName = "single-number selected-week";
      }

      nextMonth.push(
        <div
          onClick={handleClick}
          key={v4()}
          id={"next-" + i}
          className={cName}
        >
          {i}
        </div>
      );
    }
    return [...prevMonth, ...ar, ...nextMonth];
  };

  const handleDate = (next) => {
    let localDate = new Date(date);
    if (next) {
      localDate = addMonths(localDate, 1);
    } else {
      localDate = subMonths(localDate, 1);
    }
    setDate(new Date(localDate));
  };

  return (
    <div className="input-rightpadding h38">
      <div
        className="input outline fit-content h-100"
        onBlur={() => setOpen(false)}
        onClick={() => setOpen(true)}
        tabIndex={0}
      >
        <p>
          <span className="remark">
            {t("dateRangeChioce3-1", { isoWeek: isoWeekCalc(week.firstDay) })}
          </span>
          <span>
            {/* {` ${moment(week.firstDay).format("MMM D")} - ${moment(week.lastDay).format("MMM D, yyyy")}`} */}
            {(() => {
              const trans = formatDateTimeRange(showFormatDate, null, week.firstDay, week.lastDay);
              return `${trans.startDate} ~ ${trans.endDate}`;
            })()}
          </span>
        </p>
        {/* <p>
          {t("dateRangeChioce3", { isoWeek: isoWeekCalc(week.firstDay), rangeDate: `${moment(week.firstDay).format('MMM D')} - ${moment(week.lastDay).format('MMM D, yyyy')}` })}
        </p> */}
        {open && (
          <div className="week-picker-options">
            <div className="title-week">
              <div onClick={() => handleDate()} className="arrow-container">
                {ArrowLeft}
              </div>
              {
                lang === "zh-TW" ?
                  `${date.getFullYear()}年 ${months[date.getMonth()]}月` :
                  `${months[date.getMonth()]} ${date.getFullYear()}`
              }
              <div onClick={() => handleDate(true)} className="arrow-container">
                {ArrowRight}
              </div>
            </div>
            <div className="numbers-container">
              <div className="single-number day">{t("su")}</div>
              <div className="single-number day">{t("mo")}</div>
              <div className="single-number day">{t("tu")}</div>
              <div className="single-number day">{t("we")}</div>
              <div className="single-number day">{t("th")}</div>
              <div className="single-number day">{t("fr")}</div>
              <div className="single-number day">{t("sa")}</div>
            </div>
            <div className="numbers-container">{renderDays()}</div>
          </div>
        )}
      </div>
      <div className="icon icon-Dropdown bg-greyA small"></div>
    </div>
  );
};
