import ReconnectingWebSocket from 'reconnecting-websocket';

class WebSocketService {
  constructor(url) {
    this.socket = null;
    this.url = url;
  }

  connect() {
    this.socket = new ReconnectingWebSocket(this.url);

    this.socket.onopen = () => {
      console.log('WebSocket连接已建立');
    };

    this.socket.onmessage = (message) => {
      console.log('收到消息:', message.data);
    };

    this.socket.onclose = () => {
      console.log('WebSocket连接已关闭');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket错误:', error);
    };
  }

  sendMessage = (message) => {
    this.socket.send(message);
  };

//   sendMessage(message) {
//     if (this.socket && this.socket.readyState === WebSocket.OPEN) {
//       this.socket.send(message);
//     }
//   }

  close() {
    // if (this.socket) {
    //   console.log('关闭WebSocket连接' + this.url);
    //   this.socket.close();
    // }

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log('关闭WebSocket连接');
      this.socket.close();
    } else {
      console.warn('WebSocket连接未打开或已关闭');
    }
  }
}

export default WebSocketService;