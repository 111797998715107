import React, { useState, useRef } from "react";
import { default as ReactSelect, components, InputAction } from "react-select";
import { useTranslation } from 'react-i18next'; //語系
// import './MultiSelect.css';

const MultiSelect = (props) => {
  const { t } = useTranslation("common");
  //12/13 啾啾增加下面這行，去引入className樣式變數
  const { className, options, value, onChange, isError, isSelectAll, placeholder, menuPlacement, ...restProps } = props;
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options.filter(({ label }) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

  const comparator = (v1, v2) => (v1.value - v2.value);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props) => (
    <components.Option {...props} style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', alignItems: 'center', }}>
      {props.value === "*" &&
      !isAllSelected.current &&
      filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{props.label}</label>
    </components.Option>
  );

  const Input = (props) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput) e.preventDefault();
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt) => opt.label === label).length ===
                0
          ),
        ].sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#EBEEFB', // 多选值的背景颜色
      color: '#3D71F8', // 多选值的文字颜色
      borderRadius: '8px', // 圆角边框
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#3D71F8', // 多选值的文字颜色
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#9FBAFF', // 移除按钮的文字颜色
      ':hover': {
        backgroundColor: '#3D71F8', // 移除按钮的背景颜色（悬停时）
        color: 'white', // 移除按钮的文字颜色（悬停时）
      }
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "35px",
      overflow: "auto",
      overflowY: "hidden",
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isSelected ? 'blue' : 'white', // 选中项背景颜色
    //   color: state.isSelected ? 'white' : 'black', // 选中项文字颜色
    // }),
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      backgroundColor:
        isSelected && !isFocused
          ? null
          : isFocused && !isSelected
          ? styles.backgroundColor
          : isFocused && isSelected
          ? "#DEEBFF"
          : null,
      color: null,
    }),
    menu: (def) => ({ ...def, zIndex: 9999 }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
      borderColor: props.isError ? '#F66F6F' : '#CCC',
      '&:hover': {
        borderColor: props.isError ? '#F66F6F' : '#CCC', // 滑鼠懸停時邊框顏色
      },
    }),
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
    } else selectAllLabel.current = "Select all";

    allOption.label = selectAllLabel.current;

    return (
      <ReactSelect
        {...props}
        //12/13 啾啾增加下面這行，讓套件可以在外部吃className
        className={className} // 把 className 傳遞給 ReactSelect
        inputValue={selectInput}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={[allOption, ...props.options]}
        onChange={handleChange}
        components={{
          Option: Option,
          Input: Input,
          ...props.components,
        }}
        filterOption={customFilterOption}
        menuPlacement={props.menuPlacement ?? "auto"}
        styles={customStyles}
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        placeholder={t(props.placeholder)}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      //12/13 啾啾增加下面這行，讓套件可以在外部吃className
      className={className} // 把 className 傳遞給 ReactSelect
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ...props.components,
      }}
      menuPlacement={props.menuPlacement ?? "auto"}
      onKeyDown={onKeyDown}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      blurInputOnSelect={true}
      placeholder={t(props.placeholder)}
    />
  );
};

export default MultiSelect;