import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import DateTimeRangePicker from 'components/dateTimeRangePicker/DateTimeRangePicker';
import './ChartData.css';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import SelectData from './Modal/SelectData';

import { apiMetricsList, apiDatalist, apiDatalistOriginal } from 'utils/Api';
import { MachineSelect } from 'components/machineSelect/machineSelect';
import { checkIfOver7Days, generateDateTimeRangeJSON, formatDateTime, formatDateTime2, getNowDateTime } from 'utils/commonFun';
import { changeConfirmLocale } from 'antd/es/modal/locale';

const ChartData = () => {
  const { t, i18n } = useTranslation("chartData");
  const { lang, overtime } = useContext(MyUserContext);

  const [judgeFirst, setJudgeFirst] = useState(true);
  let [queryRange, setQueryRange] = useState([]); //查詢區間
  const [dateError, setDateError] = useState(false);

  const [choiceMachine, setChoiceMachine] = useState(null); //目前選擇的設備

  const [showUndo, setShowUndo] = useState(false); //是否要顯示復原遮罩
  const [previousMachine, setPreviousMachine] = useState(null); //上一次選擇的設備
  const [previousDatetime, setPreviousDatetime] = useState([]); //上一次選擇的時間

  const [optionsChart, setOptionsChart] = useState(null);  //圖表設定檔
  const [optionsGrid, setOptionsGrid] = useState([]);  //圖表畫布邊距以及大小
  const [optionsXAxis, setOptionsXAxis] = useState([]); //圖表X軸(時間軸)
  const [chartX, setChartX] = useState([]); //圖表X軸(時間軸)
  const [chartXValue, setChartXValue] = useState([]); //圖表X軸(時間軸)(若新加入metrics需要加上X軸)
  const [optionsYAxis, setOptionsYAxis] = useState([]); //圖表Y軸(單位)
  const [optionsSeries, setOptionsSeries] = useState([]); //圖表Y軸(數值)

  const [showSelectDataModal, setShowSelectDataModal] = useState(false); //是否顯示選擇數據Modal
  const handleSelectDataClose = () => {
    setShowSelectDataModal(false);
  }

  const [metricsApiData, setMetricsApiData] = useState([]);  //Metrics API資料
  const [selectData, setSelectData] = useState([]);  //目前選擇資料細項
  const [delSelectData, setDelSelectData] = useState([]); //刪除資料
  const [metricsNum, setMetricsNum] = useState(0);  //目前Metrics數量

  const chartLineColor = ["#5470C6", "#FAC858", "#91CC75", "#EE6666", "#73C0DE", "#3BA272", "#FC8452", "#9A60B4", "#EA7CCC", "#D4A59C"];

  const [showModalExcelfileName, setShowModalExcelfileName] = useState(false); //是否顯示Modal(檔案名稱)
  const [tempExcelFileName, setTempExcelfileName] = useState(""); //檔案名稱(Modal)
  const regex = /^[^\/\\\*\?\$\@]+$/;
  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息
  const [downloadBtnDisabled, setDownloadBtnDisabled] = useState(false); //Download 按鈕樣式

  useEffect(() => {
    const initialize = async () => {
      await loadMetricsList();  // 等待 loadMetricsList 完成
      setDateError(false);
      setMetricsNum(1);

      if (queryRange.length === 0) {
        const { startDate, endDate } = getTimeRange();
        setQueryRange([startDate, endDate]);
        setPreviousDatetime([startDate, endDate]);
      }
    };

    initialize();  // 调用异步初始化函数
  }, []);

  useEffect(() => {
    if (judgeFirst && choiceMachine && selectData.length > 0 && queryRange.length > 0) {
      handleSearch();
      setJudgeFirst(false);
    }
  }, [judgeFirst, choiceMachine, selectData, queryRange]);

  //#region 取近30分鐘
  const getTimeRange = () => {
    // 獲取當前時間
    const endDate = new Date();
    endDate.setSeconds(0, 0); // 設置秒數和毫秒為0

    // 計算起始時間
    const startDate = new Date(endDate);
    startDate.setMinutes(endDate.getMinutes() - 30);

    return {
      startDate: formatDateTime(startDate),
      endDate: formatDateTime(endDate)
    };
  }
  //#endregion

  useEffect(() => {
    setOptionsChart({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false
        },
        formatter: function (params) {
          let tooltipText = '';
          console.log("params", params)
          for (let item of params) {
            let valueText = "-";

            if (item.data !== null) {
              if (item.seriesName === "spindle_bool" ||
                item.seriesName === "feed_axis_bool" ||
                item.seriesName === "lamp_bool" ||
                item.seriesName === "lube1_bool" ||
                item.seriesName === "lube2_bool" ||
                item.seriesName === "cts_pump_bool" ||
                item.seriesName === "cooling_pump_bool" ||
                item.seriesName === "guard_bool" ||
                item.seriesName === "chip_conveyor_bool"
              ) {
                if (item.data === 0) {
                  valueText = t("on");
                }
              }
              else {
                valueText = `${item.data} ${t(`${item.seriesName}_unit`)}`;
              }
            }

            tooltipText += `${item.axisValueLabel}<br/>${item.marker} ${t(`${item.seriesName}_chart`)}: ${valueText}<br/>`;
          }
          return tooltipText;
        }
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }]
      },
      grid: optionsGrid,
      xAxis: optionsXAxis,
      yAxis: optionsYAxis.map((axis, index) => ({
        ...axis,
        name: t(`${metricsApiData[index] !== undefined ? metricsApiData[index].metricItems[0].metricCode : "spindle_bool"}_unit`) // update with translation
      })),
      series: optionsSeries
    });
  }, [optionsGrid, optionsXAxis, optionsYAxis, optionsSeries, i18n.language]);

  //#region 載入Chart圖表內容設定檔
  const loadChartSet = (reponseData) => {
    return new Promise((resolve) => {
      let newOptionsGrid = []; //Chart grid
      let newOptionsXAxis = []; //Chart X軸
      let newOptionsYAxis = []; //Chart Y軸
      let newOptionsSeries = []; //Chart 數值
      let top = 30;

      reponseData.forEach((main, mainIndex) => {
        let chartType = 1;  //判斷圖形類型 1:折線圖 2:點點圖
        let metricCode = main.metricItems[0].metricCode;
        if (metricCode === "spindle_bool" ||
          metricCode === "feed_axis_bool" ||
          metricCode === "lamp_bool" ||
          metricCode === "lube1_bool" ||
          metricCode === "lube2_bool" ||
          metricCode === "cts_pump_bool" ||
          metricCode === "cooling_pump_bool" ||
          metricCode === "guard_bool" ||
          metricCode === "chip_conveyor_bool"
        ) {
          chartType = 2;
        }

        newOptionsGrid.push({
          index: mainIndex,
          left: 50,
          right: 0,
          top: top,
          height: chartType === 1 ? 200 : 10,
          show: false,
          chartType: chartType
        });

        newOptionsXAxis.push({
          gridIndex: mainIndex,
          type: 'category',
          boundaryGap: false,
          axisLine: { onZero: true },
          data: [],
          show: false
        });

        newOptionsYAxis.push({
          gridIndex: mainIndex,
          name: t(`${main.metricItems[0].metricCode}_unit`),
          type: 'value',
          nameGap: 8,
          show: false,
          chartType: chartType
        });

        if (main.metricGroupSelectName === "Action") {
          top += 60;
        }
        else {
          top += 290;
        }

        for (let [subIndex, sub] of main.metricItems.entries()) {
          let type = "line";
          if (chartType === 2) {
            type = "scatter";
          }

          newOptionsSeries.push({
            name: sub.metricCode,
            xAxisIndex: mainIndex,
            yAxisIndex: mainIndex,
            metricCode: sub.metricCode,
            type: type,
            data: [],
            lineStyle: {
              color: chartLineColor[subIndex % 10]
            },
            itemStyle: {
              color: chartLineColor[subIndex % 10]
            }
          });
        }
      });


      setOptionsGrid(newOptionsGrid);
      setOptionsXAxis(newOptionsXAxis);
      setOptionsYAxis(newOptionsYAxis);
      setOptionsSeries(newOptionsSeries);
      // Resolve the promise after the state updates are done
      resolve();
    });
  };
  //#endregion

  //#region 載入Metrics列表
  const loadMetricsList = async () => {
    const [httpStatus, reponseData] = await handleApiMetricsList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setMetricsApiData(reponseData.data);

        //特規=>預設瞬時功率
        let data = reponseData.data;
        for (let [mainIndex, main] of data.entries()) {
          let index = main.metricItems.findIndex(d => d.metricID === 1);
          if (index != -1) {
            setSelectData([
              {
                metricGroupID: main.metricGroupID,
                metricGroupSelectName: main.metricGroupSelectName,
                metricGroupDisplayName: main.metricGroupDisplayName,
                chartStatus: "d-none", //d-none=>不出現 loading=>搜尋當中 finish=>全部完成(有資料) nodata=>全部完成(無資料)
                chartIndex: mainIndex,
                metricItems: [
                  {
                    metricID: main.metricItems[index].metricID,
                    metricCode: main.metricItems[index].metricCode,
                    metricCollection: main.metricItems[index].metricCollection,
                    metricStatus: "d-none",  //show=>顯示 hide=>隱藏 nodata=>完全沒有數據 loading=>搜尋當中 d-none=>不出現
                    behavior: "",  //操作行為 delete=>要刪除
                    apiData: [],
                    apiStatus: "", //finish資料完成 空代表完全沒做 Stop暫停
                    searchResult: 0  //0:尚未執行新搜尋  1:已執行新搜尋
                  },
                ],
              }
            ]);
          }
        }

        await loadChartSet(reponseData.data);
      }
      else if (reponseData.statusCode === "40103") {
        overtime();
      }
    }
  }
  //#endregion

  //#region 載入Metrics列表API
  const handleApiMetricsList = async () => {
    let postJson = {};

    let response = await apiMetricsList(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 新增資料按鈕
  const handleAdd = () => {
    setShowSelectDataModal(true);
  }
  //#endregion

  //#region 查詢按鈕
  const handleSearch = async () => {
    if (queryRange.length > 0) {
      setDownloadBtnDisabled(true);
      let checkErr = checkIfOver7Days(queryRange);
      setDateError(checkErr);

      let newSelectData = await deleteSelectData();

      if (!checkErr && metricsNum > 0) {
        //先處理第一個Metric的時間戳(以他為準)
        //補時間戳
        updateTimestampList(newSelectData);
      }
      else {
        hideChartAndMetrics();
      }
    }
    else {
      setDateError(true);
    }
  }
  //#endregion

  //#region 更新時間軸
  const updateTimestampList = async (newSelectData) => {
    let newDataTimestampList = [...chartX];
    if (newSelectData[0].metricItems[0].apiStatus !== "finish") {
      let datumPoint = newSelectData[0].metricItems[0].metricCode;
      let metricCollection = newSelectData[0].metricItems[0].metricCollection;
      let dateTimeRangeJSON = generateDateTimeRangeJSON(queryRange[0], queryRange[1]);
      var timestampList = [];  //X軸時間戳
      var dataTimestampList = []; //X軸變數

      for (let time of dateTimeRangeJSON) {
        const [httpStatus, responseData] = await handleApiDataList(datumPoint, metricCollection, time.startTime, time.endTime);
        if (httpStatus === 200) {
          if (responseData.statusCode === "20000") {
            const timeStamp = fillTimestamps(responseData.data[0].timestampList, time.startTime, time.endTime);
            timestampList.push(...timeStamp);
            dataTimestampList.push(timeStamp);
          }
        }
      }
      for (let main of newSelectData) {
        updateXAxisData(main.chartIndex, timestampList);
      }
      setChartXValue(timestampList);
      newDataTimestampList = dataTimestampList;
      setChartX(newDataTimestampList);

      if (newDataTimestampList.length > 0) {
        processData(newDataTimestampList);
      }
    }
    else {
      processData(newDataTimestampList);

      for (let main of newSelectData) {
        let exist = optionsXAxis.find(d => d.gridIndex === main.chartIndex);
        if (exist?.data?.length === 0) {
          updateXAxisData(main.chartIndex, chartXValue);
        }
      }
    }
  }
  //#endregion

  //#region 刪掉behavior是delete狀態
  const deleteSelectData = () => {
    return new Promise((resolve) => {
      setSelectData(data => {
        const updatedData = [...data];

        for (let i = updatedData.length - 1; i >= 0; i--) {
          let judgeLoading = false;
          updatedData[i].metricItems = updatedData[i].metricItems.filter(item => item.behavior !== "delete");

          if (showUndo) {
            updatedData[i].metricItems.forEach(item => {
              item.apiStatus = "";  // 清空 apiStatus
              item.apiData = [];    // 清空 apiData
            });
          }

          updatedData[i].metricItems.forEach((item) => {
            if (item.apiStatus !== "finish") {
              judgeLoading = true;
              item.metricStatus = "loading";
              item.searchResult = 1;
            }
          });

          if (updatedData[i].metricItems.length === 0) {
            updatedData.splice(i, 1);
          }

          if (judgeLoading) {
            updatedData[i].chartStatus = "loading";
          }
        }


        updatedData.sort((a, b) => a.metricGroupID - b.metricGroupID);
        setShowUndo(false);

        resolve(updatedData);
        return updatedData;
      });
    });
  };
  //#endregion

  //#region 處理圖表內資料
  const processData = async (newDataTimestampList) => {
    for (let [mainIndex, main] of selectData.entries()) {
      let apiResultGroupIsNull = true;
      let isApiStatusNotFinish = 0;

      updateSeriesVisibility(main.chartIndex, true);
      for (let [subIndex, sub] of main.metricItems.entries()) {
        if (sub.apiStatus != "finish") {
          updateChartStatus(main.chartIndex, "loading");
          isApiStatusNotFinish++;
          updateMetricStatus(main.chartIndex, sub.metricCode, "loading");
          let dateTimeRangeJSON = generateDateTimeRangeJSON(queryRange[0], queryRange[1]);

          let apiResultIsNull = true;
          let apiResultData = [];
          for (let [timeIndex, time] of dateTimeRangeJSON.entries()) {
            try {
              const [httpStatus, responseData] = await handleApiDatalistOriginal(sub.metricCode, sub.metricCollection, time.startTime, time.endTime);
              if (httpStatus === 200 && responseData.statusCode === "20000") {
                let itemList = responseData.data[0].itemList;
                let itemListIndex = 0;
                let benchmark = newDataTimestampList[timeIndex];
                let data = [];

                for (let mark of benchmark) {
                  if (itemList.length > 0) {
                    if (itemListIndex <= itemList.length - 1 && mark === itemList[itemListIndex].timestamp) {
                      if (itemList[itemListIndex].value === "true") {
                        data.push(0);
                      }
                      else if (itemList[itemListIndex].value === "false") {
                        data.push(null);
                      }
                      else {
                        data.push(itemList[itemListIndex].value);
                      }

                      itemListIndex++;
                    } else {
                      data.push(null);
                    }
                    apiResultIsNull = false;
                    apiResultGroupIsNull = false;
                  } else {
                    data.push(null);
                  }
                }

                apiResultData.push(...data);
                addDataToGroup(main.chartIndex, sub.metricCode, data, timeIndex === 0);
              }
            } catch (error) {
              console.error(`Error processing timeIndex ${timeIndex}:`, error);
            }
          }

          if (apiResultIsNull) {
            updateMetricStatus(main.chartIndex, sub.metricCode, "nodata");
          }
          else {
            updateMetricStatus(main.chartIndex, sub.metricCode, "show");
          }

          updateApiData(main.chartIndex, sub.metricCode, apiResultData);
          updateApiStatus(main.chartIndex, sub.metricCode, "finish");
        }
      }

      // console.log("isApiStatusNotFinish", isApiStatusNotFinish)
      if (isApiStatusNotFinish > 0) {
        if (apiResultGroupIsNull) {
          updateChartStatus(main.chartIndex, "nodata");
        }
        else {
          updateChartStatus(main.chartIndex, "finish");
        }
      }

      if (mainIndex === selectData.length - 1) {
        setDownloadBtnDisabled(false);
      }
    }

    hideChartAndMetrics();
  };
  //#endregion

  //#region 補時間戳
  const fillTimestamps = (timestamps, startTime, endTime) => {    
    const result = [];
    let currentTime = new Date(startTime).getTime();
    const endTimeMs = new Date(endTime).getTime();
    let i = 0;

    // dataInterval 每次增加的比較秒數：代表資料間隔的時間在此interval內的圖表還是能將資料點連接起來，但也會造成最尾端的資料lost超過這個時間設定
    // 查詢時超過10小時，則區隔設定為30秒，超過1小時則設定為10秒，其他則設定為5秒
    let dataInterval = 7000;  

    if  (((endTimeMs - currentTime) / 1000) > 3600) {
      dataInterval = 30000; 
    } else if (((endTimeMs - currentTime) / 1000) > 1800) {
      dataInterval = 15000; 
    }
    
    // If the array is empty or the first timestamp doesn't match the startTime, add the startTime
    if (timestamps.length === 0 || currentTime !== new Date(timestamps[0]).getTime()) {
      result.push(formatDateTime2(new Date(currentTime)));
    }

    while (currentTime <= endTimeMs) {
      if (i < timestamps.length) {
        const nextTimestamp = new Date(timestamps[i]).getTime();
        if (currentTime < nextTimestamp) {
          currentTime += dataInterval;
          if (currentTime < nextTimestamp && currentTime <= endTimeMs) {
            result.push(formatDateTime2(new Date(currentTime)));
          } else if (currentTime <= endTimeMs) {
            result.push(timestamps[i]);
            currentTime = nextTimestamp;
            i++;
          }
        } else if (currentTime === nextTimestamp) {
          if (currentTime <= endTimeMs) {
            result.push(formatDateTime2(new Date(currentTime)));
          }
          currentTime += dataInterval;
          i++;
        } else {
          if (currentTime <= endTimeMs) {
            result.push(timestamps[i]);
          }
          currentTime = nextTimestamp;
          i++;
        }
      } else {
        currentTime += dataInterval;
        if (currentTime <= endTimeMs) {
          result.push(formatDateTime2(new Date(currentTime)));
        }
      }
    }

    //如果最後一個時間戳與起始日不在同一天，則將其移除
    const lastTimestamp = new Date(result[result.length - 1]);
    const startDay = new Date(startTime).getDate();
    const lastDay = lastTimestamp.getDate();

    if (lastDay !== startDay) {
      result.pop();
    }

    if (lastTimestamp != endTimeMs) {
      result.push(formatDateTime2(new Date(endTimeMs)));
    }

    return result;
  }
  //#endregion

  //#region 更新Chart 是否要顯示
  const updateSeriesVisibility = (groupIndex, visibility) => {
    setOptionsXAxis(prevXAxis => {
      const updatedXAxis = prevXAxis.map(axis => {
        if (axis.gridIndex === groupIndex) {
          return {
            ...axis,
            show: visibility // Ensure visibility is applied
          };
        }
        return axis;
      });

      return updatedXAxis;
    });

    setOptionsYAxis(prevYAxis => {
      const updatedYAxis = prevYAxis.map(axis => {
        if (axis.gridIndex === groupIndex) {
          return {
            ...axis,
            show: axis.chartType === 2 ? false : visibility // Ensure visibility is applied
          };
        }
        return axis;
      });

      return updatedYAxis;
    });

    setOptionsGrid(prevGrid => {
      let top = 30;

      const updatedGrid = prevGrid.map((options, optionsIndex) => {
        let currentShow = options.show;

        if (options.index === groupIndex) {
          currentShow = visibility;
        }

        if (currentShow) {
          const grid = {
            left: 50,
            right: 0,
            top: top,
            height: options.chartType === 1 ? 200 : 10,
            index: options.index,
            chartType: options.chartType,
            show: currentShow
          };

          // 更新top位置
          if (options.chartType === 2) {
            top += 60;
          }
          else {
            top += 290;
          }
          return grid;
        } else {
          return {
            left: 0,
            right: 0,
            top: 0,
            height: 0,
            index: options.index,
            chartType: options.chartType,
            show: currentShow
          };
        }
      });

      return updatedGrid;
    });
  }
  //#endregion

  //#region 指定要更新資料群組
  const addDataToGroup = (groupID, metricCode, newData, clearData = false) => {
    setOptionsSeries(prevSeries => {
      const dataToAdd = Array.isArray(newData) ? newData : [newData];

      // 更新 series
      const updatedSeries = prevSeries.map(series => {
        if (series.yAxisIndex === groupID && series.metricCode === metricCode) {
          return {
            ...series,
            data: clearData ? dataToAdd : [...series.data, ...dataToAdd]
          };
        }
        return series;
      });
      return updatedSeries;
    });
  };
  //#endregion

  //#region 更新時間軸
  const updateXAxisData = (index, newData) => {
    setOptionsXAxis(prevXAxis => {
      const updatedXAxis = prevXAxis.map(xAxis => {
        if (xAxis.gridIndex === index) {
          return {
            ...xAxis,
            data: newData // 更新 data
          };
        }

        return xAxis;
      });
      return updatedXAxis;
    });
  };
  //#endregion

  //#region 更新圖表狀態
  const updateChartStatus = (index, status) => {
    setSelectData(prevData => {
      const updatedData = prevData.map((main, i) => {
        if (main.chartIndex === index) {
          return {
            ...main,
            chartStatus: status
          };
        }
        return main;
      });
      return updatedData;
    });
  };
  //#endregion

  //#region 更新 metricStatus
  const updateMetricStatus = (groupIndex, metricCode, status) => {
    setSelectData(prevData => {
      const updatedData = prevData.map((main, i) => {
        if (main.chartIndex === groupIndex) {
          return {
            ...main,
            metricItems: main.metricItems.map((item, j) => {
              if (item.metricCode === metricCode) {
                return {
                  ...item,
                  metricStatus: status
                };
              }
              return item;
            })
          };
        }
        return main;
      });
      return updatedData;
    });
  };
  //#endregion

  //#region 更新 apiStatus
  const updateApiStatus = (groupIndex, metricCode, status) => {
    setSelectData(prevData => {
      const updatedData = prevData.map((main, i) => {
        if (main.chartIndex === groupIndex) {
          return {
            ...main,
            metricItems: main.metricItems.map((item, j) => {
              if (item.metricCode === metricCode) {
                return {
                  ...item,
                  apiStatus: status
                };
              }
              return item;
            })
          };
        }
        return main;
      });
      return updatedData;
    });
  };
  //#endregion

  //#region 更新 apiData
  const updateApiData = (groupIndex, metricCode, data) => {
    setSelectData(prevData => {
      const updatedData = prevData.map((main, i) => {
        if (main.chartIndex === groupIndex) {
          return {
            ...main,
            metricItems: main.metricItems.map((item, j) => {
              if (item.metricCode === metricCode) {
                return {
                  ...item,
                  apiData: data
                };
              }
              return item;
            })
          };
        }
        return main;
      });
      return updatedData;
    });
  };
  //#endregion

  //#region 隱藏圖表或只隱藏那一條metrics(Apply動作)
  const hideChartAndMetrics = () => {
    //把optionsSeries逐步檢查哪一筆不是空陣列 再跟selectData比對 如果2者存在代表要變成空陣列
    for (let item of delSelectData) {
      let filter = optionsSeries.findIndex(d => d.metricCode === item.metricCode);

      if (filter !== -1) {
        addDataToGroup(item.groupIndex, item.metricCode, [], true);

        let mainIndex = selectData.findIndex(d => d.chartIndex === item.groupIndex && d.metricItems.length === 0);
        if (mainIndex !== -1) {
          updateSeriesVisibility(item.groupIndex, false);
        }
      }
    }


  }
  //#endregion

  //#region 查詢Metrics資料API(X軸時間戳用)
  const handleApiDataList = async (metricCode, metricCollection, startTime, endTime) => {
    let postJson = {
      mid: choiceMachine.mid,
      metricCodeList: [{
        metricCode: metricCode,
        metricCollection: metricCollection
      }],
      startTime: startTime,
      endTime: endTime
    };

    let response = await apiDatalist(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 查詢Metrics資料API
  const handleApiDatalistOriginal = async (metricCode, metricCollection, startTime, endTime) => {
    let postJson = {
      mid: choiceMachine.mid,
      metricCodeList: [{
        metricCode: metricCode,
        metricCollection: metricCollection
      }],
      startTime: startTime,
      endTime: endTime
    };

    let response = await apiDatalistOriginal(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 是否隱藏線條
  const handleDisplay = (main, sub) => {
    if (sub.metricStatus === "show") {
      updateMetricStatus(main.chartIndex, sub.metricCode, "hide");
      addDataToGroup(main.chartIndex, sub.metricCode, [], true);
    }
    else {
      updateMetricStatus(main.chartIndex, sub.metricCode, "show");
      addDataToGroup(main.chartIndex, sub.metricCode, sub.apiData);
    }
  }
  //#endregion

  // useEffect(() => {
  //   console.log("optionsGrid", optionsGrid)
  // }, [optionsGrid]);

  // useEffect(() => {
  //   console.log("optionsXAxis", optionsXAxis)
  // }, [optionsXAxis]);

  // useEffect(() => {
  //   console.log("optionsYAxis", optionsYAxis)
  // }, [optionsYAxis]);

  // useEffect(() => {
  //   console.log("optionsSeries", optionsSeries)
  // }, [optionsSeries]);

  useEffect(() => {
    console.log("selectData", selectData)
  }, [selectData]);

  useEffect(() => {
    console.log("delSelectData", delSelectData)
  }, [delSelectData]);

  useEffect(() => {
    if (previousMachine != null) {
      if (previousMachine.mid != choiceMachine.mid) {
        setShowUndo(true);
      }
    }
  }, [previousMachine, choiceMachine]);

  useEffect(() => {
    if (previousDatetime != null) {
      if (previousDatetime[0] !== queryRange[0] || previousDatetime[1] !== queryRange[1]) {
        setShowUndo(true);
      }
    }
  }, [previousDatetime, queryRange]);

  //#region 復原搜尋條件
  const handleUndo = () => {
    setChoiceMachine(previousMachine);
    setQueryRange(previousDatetime);
    setShowUndo(false);
  }
  //#endregion

  //#region 暫停按鈕
  // const handleStopTask = () => {
  //   setStopTask(true);
  // }
  //#endregion

  //#region 下載按鈕
  const handleDownload = () => {
    setJudgeCheckErr(false);
    setShowModalExcelfileName(true);
    setTempExcelfileName(`Untitled_${getNowDateTime()}`);
  }
  //#endregion

  //#region 處理檔案名稱關閉Modal(取消按鈕)
  const handleExcelfileNameCancel_fun = () => setShowModalExcelfileName(false);
  //#endregion

  //#region 處理檔案名稱關閉Modal(確認按鈕)
  const handleConfirmDownloadExcel_fun = () => {
    setJudgeCheckErr(true);

    if (regex.test(tempExcelFileName?.trim())) {
      //創建工作簿
      const workbook = XLSX.utils.book_new();
      const timestamp = chartXValue;

      for (let obj of selectData) {
        if (obj.metricItems.length > 0) {
          for (let detail of obj.metricItems) {
            const formattedData = timestamp.map((time, index) => [time, detail.apiData[index]]);
            const worksheet = XLSX.utils.aoa_to_sheet(formattedData);
            XLSX.utils.sheet_add_aoa(worksheet, [[t("timestamp"), t(`${detail.metricCode}_modal`)]], { origin: 'A1' });
            XLSX.utils.book_append_sheet(workbook, worksheet, t(`${detail.metricCode}_modal`));
          }
        }
      }
      XLSX.writeFile(workbook, `${tempExcelFileName}.xlsx`);
      setShowModalExcelfileName(false);
    }
  }
  //#endregion

  //#region 修改數值(input改變數量)-change
  const handleExcelFileName = (e) => {
    const { value } = e.target;
    setTempExcelfileName(value);
  }
  //#endregion

  //#region 修改數值-blur
  const blurExcelFileCost = (e) => {
    const { value } = e.target;
    setTempExcelfileName(value);
  }
  //#endregion

  return (
    <>
      <div className='pageContent'>
        {/* <!--第1區塊：篩選--> */}
        <section className="filterRow h-normal">
          <div>
            {/*選擇設備*/}
            <MachineSelect choiceMachine={choiceMachine} setChoiceMachine={setChoiceMachine}
              setPreviousMachine={setPreviousMachine}
            />
            {/*選擇時間區間*/}
            <div className={`width21 inputWithError ${dateError ? "error" : ""}`}>
              <DateTimeRangePicker dateValue={queryRange} setDateValue={setQueryRange} lang={lang}
                setPreviousDatetime={setPreviousDatetime}
                className={`input h-100  ${dateError ? "error" : ""}`}
              />
              <div className="errortext">
                {
                  dateError && queryRange.length === 0 ?
                    <div>{t("requiredFields")}</div> :
                    <></>
                }
                {
                  dateError && queryRange.length > 0 && checkIfOver7Days(queryRange) ?
                    <div>{t("exceedOver7Days")}</div> :
                    <></>
                }
              </div>
            </div>
            {/*叫出「選擇數據」視窗+顯示勾選的數量*/}
            <div className="input-rightpadding" onClick={handleAdd}>
              <button className="input outline fit-content" type="button">{t("selectData")} ({metricsNum})</button>
              <div className="icon icon-Dropdown bg-greyA small"></div>
            </div>
            {/*搜尋按鈕*/}
            <button className="btn btn-lv1 btn-icontext" onClick={handleSearch}>
              <div className="icon icon-Search"></div>
              <div>{t("search")}</div>
            </button>
            {/*尚未做的功能：暫停下載資料
             <div className="filterRowDiv">
                <button className="button btn-lv5 btn-onlyicon animate-rotate fixsize38" onClick={handleStopTask}>
                  <span className="icon material-symbols-rounded">stop_circle</span>
                </button>
            </div> */}
          </div>
          <div>
            {/*下載按鈕*/}
            <button class="btn btn-lv3 btn-icontext" onClick={handleDownload}
              disabled={downloadBtnDisabled}>
              <div className="icon icon-Download"></div>
              <div>{t("download")}</div>
            </button>
          </div>
        </section >
        {/* <!--第2區塊：「顯示」選取的資料列--> */}
        <section className="area-show-items mtop20">
          <div className="title">{t("display")}</div>
          <div className="item">
            {selectData?.map((main, mainIndex) => (
              <React.Fragment key={mainIndex}>
                {main.metricItems.map((sub, subIndex) => {
                  if (sub.metricStatus === "show" || sub.metricStatus === "hide") {
                    return (
                      <div className={sub.metricStatus} key={subIndex}
                        onClick={() => handleDisplay(main, sub)}>
                        {t(`${sub.metricCode}_modal`)}
                      </div>
                    );
                  }
                  else {
                    return (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip className="custom-tooltip">
                            {t("noDataSelectedPeriod")}
                          </Tooltip>
                        }
                        key={subIndex}
                      >
                        <div className={sub.metricStatus}>
                          {t(`${sub.metricCode}_modal`)}
                        </div>
                      </OverlayTrigger>
                    );
                  }
                })}
              </React.Fragment>
            ))}
          </div>
        </section>
        {/* <!--第3區塊：圖表--> */}
        <section className="box-style-white-shadow mtop20">
          <div className="margin30 relative min-height160">
            {
              selectData.length === 0 || selectData.every((data) =>
                data.metricItems.every((item) => item.searchResult === 0)
              ) ?
                <div className="status-nodata">
                  <div>{t("noDataSelected")}</div>
                </div> :
                <>
                  <div className="decoline"></div>
                  <div className="title-and-chart">
                    <div className="area-linechart">
                      <div>
                        {selectData?.map((main, mainIndex) => (
                          <div className={`${main.metricGroupSelectName !== "Action" ? "chart-line" : "chart-scatter"} title align-top ${main.chartStatus === "d-none" ? main.chartStatus : ""}`}
                            key={`main${mainIndex}`}>
                            <div className="maintitle">{main.metricGroupSelectName !== "Action" ? t(`${main.metricGroupSelectName}`) : t(`${main.metricItems[0].metricCode}_chart`)}</div>
                            {
                              main.metricGroupSelectName !== "Action" ?
                                <div className="subtitle scroll scroll-shallow">
                                  {main.metricItems.map((sub, subIndex) => {
                                    let filter = optionsSeries.find(d => d.metricCode === sub.metricCode);
                                    if (filter !== undefined) {
                                      return (
                                        <React.Fragment key={`sub${subIndex}`}>
                                          {sub.metricStatus === "nodata" ? (
                                            <OverlayTrigger
                                              placement="bottom"
                                              overlay={
                                                <Tooltip className="custom-tooltip">
                                                  {t("noDataSelectedPeriod")}
                                                </Tooltip>
                                              }
                                            >
                                              <div className="nodata">
                                                <div className="dot" style={{ backgroundColor: filter.itemStyle.color }}></div>
                                                <div className="text">{t(`${sub.metricCode}_chart`)}</div>
                                              </div>
                                            </OverlayTrigger>
                                          ) : sub.metricStatus === "show" || sub.metricStatus === "hide" ? (
                                            <div>
                                              <div className="dot" style={{ backgroundColor: filter.itemStyle.color }}></div>
                                              <div className="text">{t(`${sub.metricCode}_chart`)}</div>
                                            </div>
                                          ) : null}
                                        </React.Fragment>
                                      );
                                    }
                                  })}
                                </div> : null
                            }

                            {
                              main.chartStatus === "loading" ?
                                <div className="loading chartbox">
                                  <div className="spinner"></div>
                                </div> : null
                            }
                            {
                              main.chartStatus === "nodata" ?
                                <div className="chart nodata-mask chartbox">
                                  <div>
                                    <div className="icon"></div>
                                    <div className="text">{t("noDataSelectedPeriod")}</div>
                                  </div>
                                </div> : null
                            }
                          </div>
                        ))}
                      </div>
                      <div className="chart">
                        <div className="chart_linechart" style={{ height: selectData.filter(d => d.chartStatus !== "d-none").length === 1 ? 250 : (selectData.filter(d => d.chartStatus !== "d-none" && d.metricGroupSelectName !== "Action").length * 290) + (selectData.filter(d => d.chartStatus !== "d-none" && d.metricGroupSelectName === "Action").length * 70) }}>
                          {
                            optionsChart ?
                              <ReactEcharts option={optionsChart}
                                echarts={echarts} className='w-100 h-100' /> : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }

          </div>
          <div className={`func-revert ${showUndo && !(selectData.length === 0 || selectData.every((data) =>
            data.metricItems.every((item) => item.searchResult === 0)
          )) ? "" : "hidden"}`}>
            <div>
              <div>
                <div className="btn" onClick={handleUndo}>
                  <div>
                    <div>
                      <div className="icon material-symbols-rounded">undo</div>
                    </div>
                    <div className="text">{t("revert")}</div>
                  </div>
                </div>
                <div className="title">{t("revertSearchCriteria")}</div>
                <div className="note">{t("revertSearchCriteriaDetail")}</div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--第4區塊：備註--> */}
        <div className="mtop20">
          <div className="box-note">
            <div className="title">{t("note")}</div>
            <div className="content"><img srcset="/images/img_action.svg" />{t("noteContent")}</div>
          </div>
        </div>
      </div >

      <SelectData
        showModal={showSelectDataModal}
        handleClose={handleSelectDataClose}
        metricsApiData={metricsApiData}
        selectData={selectData}
        setSelectData={setSelectData}
        setMetricsNum={setMetricsNum}
        delSelectData={delSelectData}
        setDelSelectData={setDelSelectData}
      />

      {/*檔案名稱 modal - start*/}
      <Modal show={showModalExcelfileName} onHide={handleExcelfileNameCancel_fun} backdrop="static" keyboard={false} centered={true}>
        <Modal.Body className='p-4'>
          <Row>
            <Col>
              <h4 className='mb-0'>{t("fileName")}</h4>
            </Col>
            <Col className='d-flex justify-content-end'>
              <FontAwesomeIcon icon={faXmark} className='text-dark fa-2x cursor-pointer' onClick={handleExcelfileNameCancel_fun} />
            </Col>
          </Row>
          <input className={`input-text-number w-100 my-3 ${judgeCheckErr && !regex.test(tempExcelFileName?.trim()) ? "input-error" : ""}`}
            value={tempExcelFileName}
            name="tempExcelFileName"
            onChange={(e) => handleExcelFileName(e)}
            onBlur={(e) => blurExcelFileCost(e)}
            autoComplete='off'
          />
          {
            judgeCheckErr && !regex.test(tempExcelFileName?.trim()) ?
              <Form.Label className='text-danger mb-0'>
                {t("specialCharactersError")}
              </Form.Label> :
              <></>
          }

          {/* <Row className='mt-5 pt-3'>
                <Col>
                    <button type="button" className="button-secondary button-formula" onClick={handleExcelfileNameCancel_fun}>
                        <b>{t("cancel")}</b>
                    </button>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <button type="button" className="button-primary button-formula" onClick={handleConfirmDownloadExcel_fun}>
                        <b>{t("download")}</b>
                    </button>
                </Col>
            </Row> */}
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
              <button type="button" className="btn btn-txt-black" onClick={handleExcelfileNameCancel_fun}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-lv1 fat" onClick={handleConfirmDownloadExcel_fun}>
                {t("download")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/*檔案名稱 modal - end*/}
    </>
  );
};

export default ChartData;