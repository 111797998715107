import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import moment from 'moment';
import { MyUserContext } from 'contexts/MyUserContext';
import { secondsToHours, decimalWithOneDecimal, judgeErrorTime, startToEndDate, formatDateTimeRange } from 'utils/commonFun';
import { apiQuantityOEE } from 'utils/Api';
import { encrypt } from 'utils/crypt';

import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';

//#region OEE圖表
const DataCharts = (props) => {
    const { startDate, endDate, choiceMachine, OEEApi, plannedEquipmentLoadingTime, rawData,
        rawDataMachining, rawDataIdle, rawDataAlarm, rawDataOffline, shutDownDaily, ratingCriteria } = props;

    const { t, i18n } = useTranslation("overallEquipmentEfficiency");
    const { overtime, misc, lang, showFormatDate } = useContext(MyUserContext);

    const [optionsNonDefectiveQuantity, setOptionsNonDefectiveQuantity] = useState(null); //工件生產良品數圖表
    const [optionsTotalQuantity, setOptionsTotalQuantity] = useState(null); //每日生產工件總數圖表
    const [optionsAverageProduction, setOptionsAverageProduction] = useState(null); //各工件平均生產時間偏差圖表

    const [optionsAvailability, setOptionsAvailability] = useState(null); //稼動率資料圖表
    const [optionsEquipmentOperationTimeline, setOptionsEquipmentOperationTimeline] = useState(null); //設備運轉時序圖表

    const [errorShutdownTimes, setErrorShutdownTimes] = useState(0);

    //#region 初始載入
    useEffect(() => {
        setOptionsAvailability({
            xAxis: {
                type: 'value'
            },
            yAxis: {
                type: 'category',
                data: [t("plannedTime"), t("actualRuntime")]
            },
            grid: {
                left: '16%',
                bottom: '10%',
                right: '5%',
                top: '0%'
            },
            series: [
                {
                    name: 'PlannedEquipmentLoadingTime',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [{ value: plannedEquipmentLoadingTime, itemStyle: { color: '#3259CA' } }, { value: 0, itemStyle: { opacity: 0 } }]
                },
                {
                    name: 'RawDataMachining',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [{ value: 0, itemStyle: { opacity: 0 } }, { value: secondsToHours(rawDataMachining), itemStyle: { color: '#7CC629' } }]
                },
                {
                    name: 'RawDataIdle',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [{ value: 0, itemStyle: { opacity: 0 } }, { value: secondsToHours(rawDataIdle), itemStyle: { color: '#FFAA00' } }]
                },
                {
                    name: 'RawDataAlarm',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [{ value: 0, itemStyle: { opacity: 0 } }, { value: secondsToHours(rawDataAlarm), itemStyle: { color: '#F66F6F' } }]
                }
            ]
        });

        let running = [];
        let idlePreparing = [];
        let errorShutdown = [];
        let plannedTime = [];
        let weekDay = [];
        if (rawData) {
            for (let item of rawData) {
                let statusList = item.statusList;
                running.push(secondsToHours(statusList[0].seconds));
                idlePreparing.push(secondsToHours(statusList[1].seconds));
                errorShutdown.push(secondsToHours(statusList[2].seconds));
                plannedTime.push(item.plannedHours);

                weekDay.push(t(moment(item.showDate).format('ddd')));
            }
        }

        setOptionsEquipmentOperationTimeline({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            legend: {
                data: [t("running"), t("idlePreparing"), t("errorShutdown"), t("plannedTime")]
            },
            xAxis: [
                {
                    type: 'category',
                    data: weekDay,
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: t("actualRuntimeHR"),
                    min: 0,
                    max: 24,
                    interval: 4,
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                {
                    type: 'value',
                    name: t("plannedTimeHR"),
                    min: 0,
                    max: 24,
                    interval: 4,
                    axisLabel: {
                        formatter: '{value}'
                    }
                }
            ],
            /*↓ 網友說加上這段，就能把Apache echart上下左右的空白都消掉*/
            grid: {
                top: '40px',
                left: '0px',
                right: '0px',
                bottom: '10px',
                containLabel: true
            },
            /*↑ 網友說加上這段，就能把Apache echart上下左右的空白都消掉*/
            color: ['#7CC629', '#FFAA00', '#F66F6F', '#3259CA'],
            series: [
                {
                    name: t("running"),
                    type: 'bar',
                    stack: 'Real',
                    emphasis: {
                        focus: 'series'
                    },
                    data: running
                },
                {
                    name: t("idlePreparing"),
                    type: 'bar',
                    stack: 'Real',
                    emphasis: {
                        focus: 'series'
                    },
                    data: idlePreparing
                },
                {
                    name: t("errorShutdown"),
                    type: 'bar',
                    stack: 'Real',
                    emphasis: {
                        focus: 'series'
                    },
                    data: errorShutdown
                },
                {
                    name: t("plannedTime"),
                    type: 'line',
                    yAxisIndex: 1,
                    tooltip: {
                        valueFormatter: function (value) {
                            return value;
                        }
                    },
                    data: plannedTime
                }
            ]
        });
    }, [plannedEquipmentLoadingTime, rawDataMachining, rawDataIdle, rawDataAlarm, rawDataOffline, i18n.language]);
    //#endregion

    useEffect(() => {
        if (startDate != "" && endDate != "" && choiceMachine != null && OEEApi != null) {
            if (OEEApi.length > 0) {
                loadQuantityOEE();

                let nonDefectiveQuantity = [];
                for (let item of OEEApi) {
                    nonDefectiveQuantity.push({
                        name: item.wpNO, wpID: item.wpID,
                        programID: item.programID, programName: item.programName,
                        value: item.nonDefectiveCount
                    });
                }

                setOptionsNonDefectiveQuantity({
                    series: {
                        type: 'pie',
                        radius: [20, 60],
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        label: {
                            formatter: function (params) {
                                return `${params.name} ${params.data.programName}\n${params.value} ${t("pcs")}`;
                            },
                            rich: {
                                time: {
                                    fontSize: 10,
                                    color: '#999'
                                },
                                test: {
                                    fontSize: 10,
                                    color: '#999'
                                }
                            }
                        },
                        data: nonDefectiveQuantity
                    }
                });

                let wpList = [];  //工件工序列表
                let timeDeviation = [];  //時間差
                let timeDeviationColorList = [];  //時間差顏色


                for (let item of OEEApi) {
                    if (item.wpName != null) {
                        wpList.push(`${item.wpName}\n(${item.programName})`);
                    }
                    else {
                        wpList.push(`(${item.programName})`);
                    }

                    if (item.programExecutionDuration === 0) {
                        timeDeviation.push(0);
                        timeDeviationColorList.push("#000");
                    }
                    else {
                        let singleTimeDiff = item.avgProcessDuration - item.programExecutionDuration;
                        let singleTimeDeviation = parseFloat(decimalWithOneDecimal(singleTimeDiff, item.programExecutionDuration));

                        timeDeviation.push({
                            value: singleTimeDeviation,
                            wpID: item.wpID,
                            programID: item.programID
                        });

                        if (singleTimeDiff > 0) {
                            timeDeviationColorList.push("#F66F6F");
                        }
                        else {
                            timeDeviationColorList.push("#37B9CC");
                        }
                    }
                }

                setOptionsAverageProduction({
                    tooltip: {
                        valueFormatter: function (value) {
                            return `${value}%`;
                        }
                        // trigger: 'item',
                        // renderMode: 'html',
                        // formatter: function (params) {
                        //     console.log(params)
                        //     return `<b>${params.name}</b>&nbsp;&nbsp;${params.value}%<br/><a href="http://192.168.99.22:8301/Power/PartsPrograms/2CE6685E8A687EE38FB9D1D857D78F70/2C788EB19D41F022B5E62B5CF3BA49CA">詳細</a>`;
                        // }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: wpList,
                        axisLabel: {
                            color: 'white'
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: timeDeviation,
                            type: 'bar',
                            itemStyle: {
                                color: function (params) {
                                    var colorList = timeDeviationColorList;
                                    return colorList[params.dataIndex];
                                }
                            }
                        }
                    ]
                });

            }
            else {
                setOptionsNonDefectiveQuantity(null);
                setOptionsTotalQuantity(null);
                setOptionsAverageProduction(null);
            }
        }
    }, [startDate, endDate, choiceMachine, OEEApi, lang]);

    useEffect(() => {
        if (shutDownDaily != null) {
            let times = 0;
            for (let item of shutDownDaily) {
                times += item.shiftList[0].times;
            }
            setErrorShutdownTimes(times);
        }
    }, [shutDownDaily]);

    const loadQuantityOEE = async () => {
        //#region 計算每天生產工件數量API
        const [httpStatus, responseData] = await handleApiQuantityOEE();
        if (httpStatus == "200") {
            if (responseData.statusCode === "20000") {
                let data = responseData.data;

                let nonDefective = [];
                let defective = [];
                let yieldRate = [];
                let weekDay = [];

                let arrayDate = startToEndDate(startDate, endDate);
                for (let date of arrayDate) {
                    let filter = data.find(d => d.showDate === date);
                    if (filter) {
                        nonDefective.push(filter.nonDefectiveCount);
                        defective.push(filter.defectiveCount);
                        yieldRate.push(decimalWithOneDecimal(filter.nonDefectiveCount, filter.productionQuantity));
                    }
                    else {
                        nonDefective.push(0);
                        defective.push(0);
                        yieldRate.push(0);
                    }
                    weekDay.push(t(moment(date).format('ddd')));
                }

                setOptionsTotalQuantity({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: [t("nonDefective"), t("defective"), t("yieldRate")]
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: weekDay,
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: t("countPCS"),
                            axisLabel: {
                                formatter: '{value}'
                            }
                        },
                        {
                            type: 'value',
                            name: t("yieldRatePercentage"),
                            axisLabel: {
                                formatter: '{value}'
                            }
                        }
                    ],
                    /*↓ 網友說加上這段，就能把Apache echart上下左右的空白都消掉*/
                    grid: {
                        top: '40px',
                        left: '0px',
                        right: '0px',
                        bottom: '10px',
                        containLabel: true
                    },
                    /*↑ 網友說加上這段，就能把Apache echart上下左右的空白都消掉*/
                    series: [
                        {
                            name: t("nonDefective"),
                            type: 'bar',
                            stack: 'Real',
                            emphasis: {
                                focus: 'series'
                            },
                            data: nonDefective,
                            itemStyle: {
                                color: '#37B9CC' // 柱状图颜色
                            }
                        },
                        {
                            name: t("defective"),
                            type: 'bar',
                            stack: 'Real',
                            emphasis: {
                                focus: 'series'
                            },
                            data: defective,
                            itemStyle: {
                                color: '#CCCCCC' // 柱状图颜色
                            }
                        },
                        {
                            name: t("yieldRate"),
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + "%";
                                }
                            },
                            data: yieldRate,
                            itemStyle: {
                                color: '#7CC629' // 柱状图颜色
                            }
                        }
                    ]
                });
            }
            else if (responseData.statusCode === "40103" || responseData.statusCode === "40104") {
                overtime();
            }
        }
        //#endregion
    }

    //#region 計算每天生產工件數量API
    const handleApiQuantityOEE = async () => {
        const postData = {
            startDate: startDate,
            endDate: endDate,
            midList: [choiceMachine.mid]
        }

        let response = await apiQuantityOEE(postData);
        if (response) {
            const httpStatus = response.request.status;
            const reponseData = response.data;

            return [httpStatus, reponseData];
        }
        else {
            return ["500", ""]
        }
    }
    //#endregion

    //#region 生產工件良品數click跳轉至用電工件以及加工程式
    const onChartReadyNonDefectiveQuantity = (chart) => {
        chart.on('click', function (params) {
            const data = params.data;
            const baseUrl = window.location.origin;
            const path = '/Power/PartsPrograms';
            localStorage.setItem("power_startDate", localStorage.getItem("analytics_startDate"));
            localStorage.setItem("power_endDate", localStorage.getItem("analytics_endDate"));
            const fullUrl = `${baseUrl}${path}/${data.wpID != null ? encrypt(data.wpID) : encrypt("0")}/${data.programID != null ? encrypt(data.programID) : encrypt("0")}`;
            window.open(fullUrl, '_blank');
        });
    };
    //#endregion

    //#region 每日生產工件總數click跳轉數據頁面工件生產
    const onChartReadyTotalQuantity = (chart) => {
        chart.on('click', function (params) {
            const baseUrl = window.location.origin;
            const path = '/RawData/WorkpieceProduction';
            localStorage.setItem("rawData_startDate", localStorage.getItem("analytics_startDate"));
            localStorage.setItem("rawData_endDate", localStorage.getItem("analytics_endDate"));
            const fullUrl = `${baseUrl}${path}`;
            window.open(fullUrl, '_blank');
        });
    }
    //#endregion

    //#region 各工件平均生產時間偏差跳轉至用電工件以及加工程式
    const onChartReadyAverageProduction = (chart) => {
        const width = window.innerWidth;
        if (width >= 1024) {
            chart.on('click', function (params) {
                const data = params.data;
                const baseUrl = window.location.origin;
                const path = '/Power/PartsPrograms';
                localStorage.setItem("power_startDate", localStorage.getItem("analytics_startDate"));
                localStorage.setItem("power_endDate", localStorage.getItem("analytics_endDate"));
                const fullUrl = `${baseUrl}${path}/${data.wpID != null ? encrypt(data.wpID.toString()) : encrypt("0")}/${data.programID != null ? encrypt(data.programID.toString()) : encrypt("0")}`;
                window.open(fullUrl, '_blank');
            });
        }
    }
    //#endregion

    //#region 實際運轉時數click跳轉數據頁面設備運轉時間
    const onChartReadyAvailability = (chart) => {
        chart.on('click', function (params) {
            const baseUrl = window.location.origin;
            const path = '/RawData/EquipmentRuntime';
            localStorage.setItem("rawData_startDate", localStorage.getItem("analytics_startDate"));
            localStorage.setItem("rawData_endDate", localStorage.getItem("analytics_endDate"));
            const fullUrl = `${baseUrl}${path}`;
            window.open(fullUrl, '_blank');
        });
    }
    //#endregion

    return (
        <>
            {/* <!--第3排 圖表1--> */}
            <div className="flex spacebetween chartbox outer-white-chartbox">

                {/* <!--第3排｜區塊1 工件生產良品數--> */}
                <div className="chartbox-notable width-33">
                    <div className={!misc?.detailedExplanation ? "d-none" : ""}>
                        {t("chartDescription1")}
                    </div>
                    <div className="box-style-white-shadow">
                        <div className="stretch">
                            {/* <!--上排小標題--> */}
                            <div>
                                <h3>{t("nonDefectiveQuantityProducedWorkpieces")}</h3>
                            </div>
                            <section className={`chartbox-inner-nodata w-100 ${optionsNonDefectiveQuantity ? "d-none" : ""}`}>
                                <div>
                                    <div>{t("noData")}</div>
                                </div>
                            </section>
                            {/* <!--下排圖--> */}
                            <div className={`${optionsNonDefectiveQuantity ? "" : "d-none"}`}>
                                <div className={`chartblock16 chart-height-talltoshort`}>
                                    {
                                        optionsNonDefectiveQuantity ?
                                            <ReactEcharts id="nonDefectiveQuantity"
                                                option={optionsNonDefectiveQuantity}
                                                echarts={echarts}
                                                onChartReady={onChartReadyNonDefectiveQuantity}
                                                className='w-100 h-100'
                                            /> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--第3排｜區塊2 每日生產工件總數--> */}
                <div className="chartbox-notable width-33">
                    <div className={!misc?.detailedExplanation ? "d-none" : ""}>
                        {t("chartDescription2")}
                    </div>
                    <div className="box-style-white-shadow">
                        <div className="stretch">
                            {/* <!--上排小標題--> */}
                            <div>
                                <h3>{t("totalQuantityProducedWorkpiecesEachDay")}</h3>
                            </div>
                            <section className={`chartbox-inner-nodata w-100 ${optionsTotalQuantity ? "d-none" : ""}`}>
                                <div>
                                    <div>{t("noData")}</div>
                                </div>
                            </section>
                            {/* <!--下排圖--> */}
                            <div className={`${optionsTotalQuantity ? "" : "d-none"}`}>
                                <div className="chartblock16 chart-height-talltoshort">
                                    {
                                        optionsTotalQuantity ?
                                            <ReactEcharts
                                                option={optionsTotalQuantity}
                                                onChartReady={onChartReadyTotalQuantity}
                                                echarts={echarts} className='w-100 h-100' /> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--第3排｜區塊3 各工件平均生產時間偏差 (%)--> */}
                <div className="chartbox-notable width-33of2">
                    <div className={!misc?.detailedExplanation ? "d-none" : ""}>
                        {t("chartDescription3")}
                    </div>
                    <div className="box-style-white-shadow">
                        <div className="stretch">
                            {/* <!--上排小標題--> */}
                            <div>
                                <h3>{t("averageProductionTimeDeviationEachWorkpiece")}</h3>
                            </div>
                            <section className={`chartbox-inner-nodata w-100 ${optionsAverageProduction ? "d-none" : ""}`}>
                                <div>
                                    <div>{t("noData")}</div>
                                </div>
                            </section>
                            {/* <!--下排圖--> */}
                            <div className={`${optionsAverageProduction ? "" : "d-none"}`}>
                                <div className="chartblock16">
                                    {
                                        optionsAverageProduction ?
                                            <ReactEcharts option={optionsAverageProduction}
                                                onChartReady={onChartReadyAverageProduction}
                                                echarts={echarts} className='w-100 h-100' /> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--第4排 圖表2--> */}
            <div className="flex spacebetween chartbox outer-white-chartbox">
                {/* <!--第4排 區塊1 計畫工作時間、實際運轉時間--> */}
                <div className="chartbox-scoreandtable width-50">
                    <div className={!misc?.detailedExplanation ? "d-none" : ""}>
                        {t("chartDescription4")}
                    </div>
                    <div className="box-style-white-shadow">
                        <div>
                            <div className="box-upper-score-gp grid-col-2">
                                {/* <!--上排分數--> */}
                                <div>
                                    <div className="subtitle">{t("availability")}</div>
                                    <div className="score-XL">{decimalWithOneDecimal(secondsToHours(rawDataMachining), plannedEquipmentLoadingTime)}%</div>
                                </div>
                                <div>
                                    <div className="subtitle">{t("operationalReadinessRate")}</div>
                                    <div className="score-XL">{decimalWithOneDecimal(secondsToHours(rawDataMachining + rawDataIdle), plannedEquipmentLoadingTime)}%</div>
                                </div>
                            </div>
                            <section className={`chartbox-inner-nodata ${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "" : "d-none"}`}>
                                <div>
                                    <div>{t("noData")}</div>
                                </div>
                            </section>
                            {/* <!--下排圖&表--> */}
                            <div className={`${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "d-none" : ""}`}>
                                <div className="chartblock16 chart-width-wide">
                                    {
                                        optionsAvailability ?
                                            <ReactEcharts
                                                option={optionsAvailability}
                                                echarts={echarts}
                                                onChartReady={onChartReadyAvailability}
                                                className='w-100 h-100' /> : null
                                    }
                                </div>
                                <div className="chart-table chart-width-narrow">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t("timeEntries")}</th>
                                                <td>{t("rate")}</td>
                                                <td>{t("hours")}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th><img src="/images/pieChartBlue.svg" alt={t("plannedLoadingTime")} /></th>
                                                <th>{t("plannedLoadingTime")}</th>
                                                <td></td>
                                                <td>{plannedEquipmentLoadingTime}</td>
                                            </tr>
                                            <tr>
                                                <th><img src="/images/pieChartGreen.svg" alt={t("running")} /></th>
                                                <th>{t("running")}</th>
                                                <td>{decimalWithOneDecimal(secondsToHours(rawDataMachining), plannedEquipmentLoadingTime)}<span className="unit">%</span></td>
                                                <td>{secondsToHours(rawDataMachining)}</td>
                                            </tr>
                                            <tr>
                                                <th><img src="/images/pieChartGreenYellow.svg" alt={t("operationalReadiness")} /></th>
                                                <th>{t("operationalReadiness")}</th>
                                                <td>{decimalWithOneDecimal(secondsToHours(rawDataMachining + rawDataIdle), plannedEquipmentLoadingTime)}<span className="unit">%</span></td>
                                                <td>{secondsToHours(rawDataMachining + rawDataIdle)}</td>
                                            </tr>
                                            <tr>
                                                <th><img src="/images/pieChartSum.svg" alt={t("powerOn")} /></th>
                                                <th>{t("powerOn")}</th>
                                                <td>{decimalWithOneDecimal(secondsToHours(rawDataMachining + rawDataIdle + rawDataAlarm), plannedEquipmentLoadingTime)}<span className="unit">%</span></td>
                                                <td>{secondsToHours(rawDataMachining + rawDataIdle + rawDataAlarm)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--第4排｜區塊2 設備運轉時序--> */}
                <div className="chartbox-notable width-33">
                    <div className={!misc?.detailedExplanation ? "d-none" : ""}>
                        {t("chartDescription5")}
                    </div>
                    <div className="box-style-white-shadow">
                        <div className="stretch">
                            {/* <!--上排小標題--> */}
                            <div>
                                <h3>{t("equipmentOperationTimeline")}</h3>
                            </div>
                            {/* <!--下排圖--> */}
                            <div className="chartblock18">
                                {
                                    optionsEquipmentOperationTimeline ?
                                        <ReactEcharts option={optionsEquipmentOperationTimeline}
                                            onChartReady={onChartReadyAvailability}
                                            echarts={echarts} className='w-100 h-100' /> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>


                {/* <!--第4排｜區塊3 設備錯誤停機次數 (每日)--> */}
                <div className="chartbox-notable width-16of2">
                    <div className={!misc?.detailedExplanation ? "d-none" : ""}>
                        {t("chartDescription6")}
                    </div>
                    <div className="box-style-white-shadow">
                        <div className="stretch">
                            {/* <!--上排小標題--> */}
                            <div className="title-and-score">
                                <h3 className="title">{t("numberErrorShutdownsDaily")}</h3>
                                <span className="score">{errorShutdownTimes}</span>
                            </div>
                            <section className={`chartbox-inner-nodata w-100 ${shutDownDaily?.length > 0 ? "d-none" : ""}`}>
                                <div>
                                    <div>{t("noData")}</div>
                                </div>
                            </section>
                            {/* <!--下排圖--> */}
                            <div className={`chart-week-daily ${!shutDownDaily || shutDownDaily?.length === 0 ? "d-none" : ""}`}>
                                <table>
                                    <tbody>
                                        {
                                            shutDownDaily?.map((obj, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th>
                                                            {(() => {
                                                                const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");
                                                                const removeYear = cleanString(showFormatDate.replace(/YYYY/g, ""));
                                                                const trans = formatDateTimeRange(removeYear, null, obj.showDate);
                                                                return trans.startDate;
                                                            })()}
                                                            <span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span>
                                                        </th>
                                                        <td>
                                                            {(() => {
                                                                let resultVal = obj.shiftList[0].times;
                                                                let result = judgeErrorTime(resultVal, ratingCriteria?.equipmentErrorDowntime, 1);
                                                                return <div className={`color-danger-div-${result}`}>{resultVal}</div>;
                                                            })()}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
//#endregion

export default DataCharts;