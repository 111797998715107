import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { convertDateFormat  } from 'utils/commonFun';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import { apiGanttChartGroupByMachine, apiGetWorkDays, apiIdeaLogin } from 'utils/Api';

import './GanttChart.css';

const GanttChart = () => {
    const { t } = useTranslation("aps");
    const { overtime, show404Page, authority, handleApiAuthority } = useContext(MyUserContext);
    // const tableRef = useRef(null);

    // const [workDays, setWorkDays] = useState(null); //顯示工作日
    const [restday, setRestday] = useState([]); //休息日

    const [mode, setMode] = useState("week");  //區間模式 day, week, month
    const [machineList, setMachineList] = useState([]);  //所有設備的列表

    const [serverApiDateRange, setServerApiDateRange] = useState([]);  //API目前需要跟Server抓取最新區間
    const [localApiDateRange, setLocalApiDateRange] = useState({minDate: "", maxDate: ""});  //API目前抓取本機的區間日期
    const [behavior, setBehavior] = useState("");  //行為 pre, next

    //#region 日模式
    const [currentDayDate, setCurrentDayDate] = useState(null); // 當前滑到的日期(日)
    const [showDayRange, setShowDayRange] = useState({minDate: "", maxDate: ""});  //顯示的區間日期
    const [dayHead, setDayHead] = useState([]); //上下排日期
    const [dayBody, setDayBody] = useState([]); //工單內容
    const [localDayApiOrder, setLocalDayApiOrder] = useState([]); //目前抓進來全部的工單(日)
    const [dayStartOrEndOrderId, setDayStartOrEndOrderId] = useState([]); //曾經有noStart以及noEnd(日)
    //#endregion
    
    //#region 週模式
    const [currentWeekDate, setCurrentWeekDate] = useState(null); // 當前滑到的日期(週)
    const [showWeekRange, setShowWeekRange] = useState({minDate: "", maxDate: ""});  //顯示的區間日期
    const [weekHeadMain, setWeekHeadMain] = useState({weekNum: 0, weekDetail: []});  //上排大範圍
    const [weekHeadSecondary, setWeekHeadSecondary] = useState([]);  //下排小範圍
    const [weekBody, setWeekBody] = useState([]);  //工單內容
    const [localWeekApiOrder, setLocalWeekApiOrder] = useState([]); //目前抓進來全部的工單(週)
    const [weekStartOrEndOrderId, setWeekStartOrEndOrderId] = useState([]); //曾經有noStart以及noEnd(週)
    //#endregion
    
    //#region 月模式
    const [currentMonthDate, setCurrentMonthDate] = useState(null); // 當前滑到的日期(月)
    const [showMonthRange, setShowMonthRange] = useState({minDate: "", maxDate: ""});  //顯示的區間日期
    const [monthHeadMain, setMonthHeadMain] = useState([]); //上排大範圍
    const [monthHeadSecondary, setMonthHeadSecondary] = useState([]); //下排小範圍
    const [monthBody, setMonthBody] = useState([]); //工單內容
    const [localMonthApiOrder, setLocalMonthApiOrder] = useState([]); //目前抓進來全部的工單(月)
    const [monthStartOrEndOrderId, setMonthStartOrEndOrderId] = useState([]); //曾經有noStart以及noEnd(月)
    //#endregion

    const containerRef = useRef(null);

    useEffect(() => {
      //先抓取2週
      const getThisSunday = moment().day(0).format('YYYY-MM-DD');
      const getNextSaturday = moment().add(1, 'weeks').day(6).format('YYYY-MM-DD');
      setServerApiDateRange([{minDate: getThisSunday, maxDate: getNextSaturday}]);
      setShowWeekRange({minDate: getThisSunday, maxDate: getNextSaturday});
      setCurrentWeekDate(getThisSunday);
      handleGetWorkDays(); //抓取休息日
    }, []);

    useEffect(() => {
      setBehavior("");
    }, [mode]);

    useEffect(() => {
      if(serverApiDateRange.length > 0){
        loadGanttChartGroupByMachine();
      }
    }, [serverApiDateRange]);

    useEffect(() => {
      if(mode === "week" && weekBody.length > 0){
        insertOrderToWeekBody();
      }
    }, [showWeekRange, weekHeadSecondary, localWeekApiOrder]);

    const insertOrderToWeekBody = async () => {
      let newWeekBody = JSON.parse(JSON.stringify(weekBody));
      let weekBodyMap = new Map(newWeekBody.map((item, index) => [item.mid, index]));
      let judgeChange = false;
      let newStartOrEndOrderId = [...weekStartOrEndOrderId];
      const newLocalWeekApiOrder = JSON.parse(JSON.stringify(localWeekApiOrder));

      for (let machine of newLocalWeekApiOrder) {
        const filterMidIndex = weekBodyMap.get(machine.mid);
        if (machine.orderList.length > 0 && filterMidIndex !== undefined) {
          judgeChange = true;
          for (let detail of machine.orderList) {
            const newDetail = { ...detail };
            //排除API回傳的工單開始時間大於結束時間的情況
            if (moment(newDetail.datetimeEnd).isBefore(moment(newDetail.datetimeStart))){
              continue;
            }
            
            const judgeStart = isMinDateBeforeOrder(newDetail.datetimeStart);
            const judgeEnd = isMaxDateAfterOrder(newDetail.datetimeEnd);

            newDetail.judgeStart = judgeStart;
            newDetail.judgeEnd = judgeEnd;

            let calStartTime = moment(newDetail.datetimeStart);
            let calEndTime = moment(newDetail.datetimeEnd);

            if(judgeStart){
              calStartTime = moment(showWeekRange.minDate).startOf('day');
            }

            if(judgeEnd){
              calEndTime = moment(showWeekRange.maxDate).endOf('day');
            }

            //排除顯示日期在區間外的工單
            if (moment(calEndTime).isBefore(moment(calStartTime))){
              continue;
            }

            const targetSunday = moment(calStartTime).day(0).format('YYYY-MM-DD');
            const datetimeStartWeekNum = moment(calStartTime).day();

            const weekDetail = newWeekBody[filterMidIndex]?.weekDetail || [];
            const targetFilter = weekDetail.findIndex(d => d.weekSunday === targetSunday);

            if(targetFilter !== -1){
              const { left, width } = calculatePositionAndWidth(calStartTime, calEndTime);

              if(width > 0){
                newDetail.left = left;
                newDetail.width = width;
                newDetail.disabled = false;

                if(newDetail.deliveryDelay){
                  newDetail.delayLeft = calculateDelayLeft(calStartTime, newDetail.scheduledDeliveryDate);
                }

                weekDetail[targetFilter].split[datetimeStartWeekNum].orderList.push(newDetail);

                if(!newDetail.judgeStart && !newDetail.judgeEnd){
                  // removeOrderFromWeekApiOrder(newDetail.orderID);

                  //如果是曾經只有出現頭部或尾巴
                  let filterNoStart = newStartOrEndOrderId.findIndex(d => d === newDetail.orderID);
                  
                  if(filterNoStart !== -1){
                    newStartOrEndOrderId = newStartOrEndOrderId.filter(d => d !== newDetail.orderID);

                    if(behavior === "pre"){
                      //往後找如果有相同的工單，也要移除
                      for(let i = targetFilter + 1; i < weekDetail.length; i++){
                        let initJ = 0;
                        if(i === targetFilter + 1){
                          initJ = datetimeStartWeekNum + 1;
                        }
                        for(let j = initJ; j < 7; j++){
                          let filterOrder = weekDetail[i].split[j].orderList.filter(d => d.orderID === newDetail.orderID);
                          if(filterOrder.length > 0){
                            //刪掉weekDetail[targetFilter].split[datetimeStartWeekNum].orderList裡面的工單
                            weekDetail[i].split[j].orderList = weekDetail[i].split[j].orderList.filter(d => d.orderID !== newDetail.orderID);
                            break;
                          }
                        }
                      }
                    }
                    else if(behavior === "next"){
                      //往前找如果有相同的工單，也要移除
                      for(let i = targetFilter - 1; i >= 0; i--){
                        let initJ = 6;
                        if(i === targetFilter - 1){
                          initJ = datetimeStartWeekNum - 1;
                        }
                        for(let j = initJ; j >= 0; j--){
                          let filterOrder = weekDetail[i].split[j].orderList.filter(d => d.orderID === newDetail.orderID);
                          if(filterOrder.length > 0){
                            //重新計算寬度
                            const { left, width } = calculatePositionAndWidth(moment(filterOrder[0].datetimeStart), moment(filterOrder[0].datetimeEnd));
                            filterOrder[0].left = left;
                            filterOrder[0].width = width;
                            break;
                          }
                        }
                      }
                    }
                  }
                }
                else{
                  newStartOrEndOrderId.push(detail.orderID);
                }
              }
            }
          }
        }
      }

      if(judgeChange){
        setWeekBody(newWeekBody);
        setWeekStartOrEndOrderId(newStartOrEndOrderId);
      }
    }

    const removeOrderFromWeekApiOrder = (orderIDToRemove) => {
      setLocalWeekApiOrder((prevOrders) =>
        prevOrders.map((machine) => ({
          ...machine,
          orderList: machine.orderList.filter((order) => order.orderID !== orderIDToRemove),
        }))
      );
    };

    useEffect(() => {
      if(mode === "day" && dayBody.length > 0){
        insertOrderToDayBody();
      }
    }, [showDayRange, dayHead, localDayApiOrder]);

    const insertOrderToDayBody = () => {
      let newDayBody = JSON.parse(JSON.stringify(dayBody));
      let dayBodyMap = new Map(newDayBody.map((item, index) => [item.mid, index]));
      let judgeChange = false;
      let newStartOrEndOrderId = [...dayStartOrEndOrderId];
      const newLocalDayApiOrder = JSON.parse(JSON.stringify(localDayApiOrder));

      for (let machine of newLocalDayApiOrder) {
        const filterMidIndex = dayBodyMap.get(machine.mid);

        if (machine.orderList.length > 0 && filterMidIndex !== undefined) {
          judgeChange = true;

          for (let detail of machine.orderList) {
            const newDetail = { ...detail };
            //排除API回傳的工單開始時間大於結束時間的情況
            if (moment(newDetail.datetimeEnd).isBefore(moment(newDetail.datetimeStart))){
              continue;
            }

            const judgeStart = isMinDateBeforeOrder(newDetail.datetimeStart);
            const judgeEnd = isMaxDateAfterOrder(newDetail.datetimeEnd);

            newDetail.judgeStart = judgeStart;
            newDetail.judgeEnd = judgeEnd;

            let calStartTime = moment(newDetail.datetimeStart);
            let calEndTime = moment(newDetail.datetimeEnd);

            if(judgeStart){
              calStartTime = moment(showDayRange.minDate).startOf('day');
            }

            if(judgeEnd){
              calEndTime = moment(showDayRange.maxDate).endOf('day');
            }

            //排除顯示日期在區間外的工單
            if (moment(calEndTime).isBefore(moment(calStartTime))){
              continue;
            }

            const dayDetail = newDayBody[filterMidIndex]?.dayDetail || [];
            const targetFilter = dayDetail.findIndex(d => d.showTxt === calStartTime.format('YYYY-MM-DD'));

            if(targetFilter !== -1){
              const { left, width } = calculatePositionAndWidth(calStartTime, calEndTime);

              if (width > 0) {
                newDetail.left = left;
                newDetail.width = width;
                newDetail.disabled = false;

                if (newDetail.deliveryDelay) {
                  newDetail.delayLeft = calculateDelayLeft(calStartTime, newDetail.scheduledDeliveryDate);
                }

                dayDetail[targetFilter].orderList.push(newDetail);

                if(!newDetail.judgeStart && !newDetail.judgeEnd){
                  // removeOrderFromDayApiOrder(newDetail.orderID);

                  if(behavior === "pre"){
                    //如果是曾經只有出現頭部或尾巴
                    let filterNoStart = newStartOrEndOrderId.findIndex(d => d === newDetail.orderID);
                    if(filterNoStart !== -1){
                      newStartOrEndOrderId = newStartOrEndOrderId.filter(d => d !== newDetail.orderID);

                      //往後找如果有相同的工單，也要移除
                      for(let i = targetFilter + 1; i < dayDetail.length; i++){
                        let filterOrder = dayDetail[i].orderList.filter(d => d.orderID === newDetail.orderID);
                        if(filterOrder.length > 0){
                          //刪掉dayDetail[targetFilter].orderList裡面的工單
                          dayDetail[i].orderList = dayDetail[i].orderList.filter(d => d.orderID !== newDetail.orderID);
                          break;
                        }
                      }
                    }
                  }
                  else if(behavior === "next"){
                    //如果是曾經只有出現頭部或尾巴
                    let filterNoStart = newStartOrEndOrderId.findIndex(d => d === newDetail.orderID);
                    if(filterNoStart !== -1){
                      newStartOrEndOrderId = newStartOrEndOrderId.filter(d => d !== newDetail.orderID);

                      //往前找如果有相同的工單，也要移除
                      for(let i = targetFilter - 1; i >= 0; i--){
                        let filterOrder = dayDetail[i].orderList.filter(d => d.orderID === newDetail.orderID);
                        if(filterOrder.length > 0){
                          //重新計算寬度
                          const { left, width } = calculatePositionAndWidth(moment(filterOrder[0].datetimeStart), moment(filterOrder[0].datetimeEnd));
                          filterOrder[0].left = left;
                          filterOrder[0].width = width;
                          break;
                        }
                      }
                    }
                  }
                }
                else{
                  newStartOrEndOrderId.push(newDetail.orderID);
                }
              }
            }
          }
        }
      }

      if(judgeChange){
        setDayBody(newDayBody);
        setDayStartOrEndOrderId(newStartOrEndOrderId);
      }
    }

    const removeOrderFromDayApiOrder = (orderIDToRemove) => {
      setLocalDayApiOrder((prevOrders) =>
        prevOrders.map((machine) => ({
          ...machine,
          orderList: machine.orderList.filter((order) => order.orderID !== orderIDToRemove),
        }))
      );
    };
    
    useEffect(() => {
      if(mode === "month" && monthBody.length > 0){
        insertOrderToMonthBody();
      }
    }, [showMonthRange, monthHeadSecondary, localMonthApiOrder]);

    const insertOrderToMonthBody = () => {
      let newMonthBody = JSON.parse(JSON.stringify(monthBody));
      let dayMonthMap = new Map(newMonthBody.map((item, index) => [item.mid, index]));
      let judgeChange = false;
      let newStartOrEndOrderId = [...monthStartOrEndOrderId];
      const newLocalMonthApiOrder = JSON.parse(JSON.stringify(localMonthApiOrder));

      for (let machine of newLocalMonthApiOrder) {
        const filterMidIndex = dayMonthMap.get(machine.mid);

        if (machine.orderList.length > 0 && filterMidIndex !== undefined) {
          judgeChange = true;

          for (let detail of machine.orderList) {
            const newDetail = { ...detail };
            //排除API回傳的工單開始時間大於結束時間的情況
            if (moment(newDetail.datetimeEnd).isBefore(moment(newDetail.datetimeStart))){
              continue;
            }

            const judgeStart = isMinDateBeforeOrder(newDetail.datetimeStart);
            const judgeEnd = isMaxDateAfterOrder(newDetail.datetimeEnd);

            newDetail.judgeStart = judgeStart;
            newDetail.judgeEnd = judgeEnd;

            let calStartTime = moment(newDetail.datetimeStart);
            let calEndTime = moment(newDetail.datetimeEnd);

            if(judgeStart){
              calStartTime = moment(showMonthRange.minDate).startOf('day');
            }

            if(judgeEnd){
              calEndTime = moment(showMonthRange.maxDate).endOf('day');
            }

            //排除顯示日期在區間外的工單
            if (moment(calEndTime).isBefore(moment(calStartTime))){
              continue;
            }

            const monthDetail = newMonthBody[filterMidIndex]?.monthDetail || [];
            const compareCalStartTime = moment(calStartTime).startOf("month").format('YYYY-MM-DD');
            const targetFilter = monthDetail.findIndex(d => d.showTxt === compareCalStartTime);

            if(targetFilter !== -1){
              const { left, width } = calculatePositionAndWidth(calStartTime, calEndTime);

              if (width > 0) {
                newDetail.left = left;
                newDetail.width = width;
                newDetail.disabled = false;

                if (newDetail.deliveryDelay) {
                  newDetail.delayLeft = calculateDelayLeft(calStartTime, newDetail.scheduledDeliveryDate);
                }

                const datetimeStartFilter = monthDetail[targetFilter].split.findIndex(d => d.showTxt === calStartTime.format('YYYY-MM-DD'));

                if(datetimeStartFilter !== -1){
                  monthDetail[targetFilter].split[datetimeStartFilter].orderList.push(newDetail);

                  //月分先不做去除重疊
                }
              }
            }
          }
        }
      }

      if(judgeChange){
        setMonthBody(newMonthBody);
        setMonthStartOrEndOrderId(newStartOrEndOrderId);
      }
    }

    const calculatePositionAndWidth = (calStartTime, calEndTime) => {
      let left = 0, width = 0;

      if (calStartTime.format("HH:mm:ss") !== "00:00:00") {
        const targetStartTime = moment(calStartTime).startOf("day");
        const durationInMinutes = calStartTime.diff(targetStartTime, "minutes");
        left = (durationInMinutes / 1440) * 100;
      }

      const diffMinutes = calEndTime.diff(calStartTime, "minutes") + 1;
      width = (diffMinutes / 1440) * 100;

      return { left, width };
    };

    const calculateDelayLeft = (calStartTime, scheduledDeliveryDate) => {
      const startTime = calStartTime.endOf("day");
      const scheduledTime = moment(scheduledDeliveryDate).endOf("day");
      return scheduledTime.diff(startTime, "days");
    };

    //#region 檢查工單是否在顯示最小值之前呢?
    const isMinDateBeforeOrder = (datetimeStart) => {
      let minDate = moment(showWeekRange.minDate, "YYYY-MM-DD");
      if(mode === "day"){
        minDate = moment(showDayRange.minDate, "YYYY-MM-DD");
      }
      else if(mode === "month"){
        minDate = moment(showMonthRange.minDate, "YYYY-MM-DD");
      }
      const transDatetimeStart = moment(datetimeStart);
    
      // 判断 minDate 是否在 datetimeStart 之前
      const isBefore = transDatetimeStart.isBefore(minDate);
    
      return isBefore;
    }
    //#endregion

    //#region 檢查工單是否在顯示最大值之後呢?
    const isMaxDateAfterOrder = (datetimeEnd) => {
      let maxDate = moment(showWeekRange.maxDate, "YYYY-MM-DD").endOf('day');
      if(mode === "day"){
        maxDate = moment(showDayRange.maxDate, "YYYY-MM-DD").endOf('day');
      }
      else if(mode === "month"){
        maxDate = moment(showMonthRange.maxDate, "YYYY-MM-DD").endOf('day');
      }
      const transDatetimeEnd = moment(datetimeEnd);

      // 判断工单的结束时间是否在 maxDate 之后
      const isAfter = transDatetimeEnd.isAfter(maxDate);

      return isAfter;
    }
    //#endregion

    //#region 載入甘特圖(針對設備)
    const loadGanttChartGroupByMachine = async () => {
      for(let apiDate of serverApiDateRange){
        const [httpStatus, reponseData] = await handleApiGanttChartGroupByMachine(apiDate);
        if(httpStatus == "200"){
          if(reponseData.statusCode === "20000"){
            let data = reponseData.data;                      
            // 檢查 newData 中是否所有機台的 orderList 都為空
            const hasNonEmptyOrderList = data.some(
              (machine) => machine.orderList && machine.orderList.length > 0
            );
  
            if (hasNonEmptyOrderList) {
              handleNewData(data, "week");
              handleNewData(data, "day");
              handleNewData(data, "month");
            }
  
            //要記得這個設備只載入一次
            if(machineList.length === 0){   // || machineList.length !== data.length){
              let newMachineList = [];
              let artMachine = [];
              
              for(let item of data){
                artMachine.push({
                  mShortName: item.mShortName,                  
                  mid: item.mid,
                  machineSn: item.machineSn
                });
              }

              // 加上只顯示艾創點的機器列表（讓APS顯示的機器與Monitor一致）
              const [httpStatus, reponseData] = await handleApiIdeaLogin();

              if(httpStatus === 200){
                let ideaMachine = reponseData?.artUserInfo?.owingMachines;

                for(let item of ideaMachine){
                  let filter = artMachine.find(d => d.machineSn === item.serialNumber);

                  if(filter){
                    newMachineList.push({
                      mShortName: filter.mShortName,
                      mid: filter.mid                      
                    });
                  }
                }
              }
              else {
                for(let item of artMachine){
                  newMachineList.push({
                    mShortName: item.mShortName,
                    mid: item.mid      
                  });
                }
              }
              
              setMachineList(newMachineList);
            }
            
            if(localApiDateRange.minDate === "" && localApiDateRange.maxDate === ""){
              setLocalApiDateRange(serverApiDateRange[0]);
            }
          }
          else if(reponseData.statusCode === "40300"){
            show404Page();
          }
          else if(reponseData.statusCode === "40103"){
            overtime();
          }
        }
      }
    }
    //#endregion

    const handleNewData = (newData, mode) => {
      let updateFunction;
      let currentData;
  
      switch (mode) {
        case "day":
          updateFunction = setLocalDayApiOrder;
          currentData = localDayApiOrder;
          break;
        case "week":
          updateFunction = setLocalWeekApiOrder;
          currentData = localWeekApiOrder;
          break;
        case "month":
          updateFunction = setLocalMonthApiOrder;
          currentData = localMonthApiOrder;
          break;
        default:
          console.error("Invalid mode:", mode);
          return;
      }

      // 合併新資料
      const mergedData = [...currentData];      
      newData.forEach((newMachine) => {
        const existingMachine = mergedData.find(
          (machine) => machine.mid === newMachine.mid
        );
  
        if (existingMachine) {
          // 合併 orderList
          const orderIdSet = new Set(
            existingMachine.orderList.map((order) => order.orderID)
          );
          newMachine.orderList.forEach((newOrder) => {
            if (!orderIdSet.has(newOrder.orderID)) {
              existingMachine.orderList.push(newOrder);
            }
          });
        } else {
          // 新增新機台資料
          mergedData.push(newMachine);
        }
      });
  
      // 更新對應狀態
      updateFunction(mergedData);
    };
  
    //#region 艾創點登入API
    const handleApiIdeaLogin = async () => {
      const formData = new FormData();
      let userAccount = authority?.userAccount;

      if (userAccount === undefined) {
        const [httpStatusUser, reponseDataUser] = await handleApiAuthority();
        if (httpStatusUser == "200") {
          if (reponseDataUser.statusCode === "20000") {
            userAccount = reponseDataUser.data.userAccount;            
          }
        }
      }

      formData.append('accountId', userAccount);
      formData.append('password', 'Demo2023');
      
      let ideaLoginResponse = await apiIdeaLogin(formData);
      
      if(ideaLoginResponse){
          const httpStatus = ideaLoginResponse.request.status;
          const reponseData = ideaLoginResponse.data;
  
          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 載入甘特圖(針對設備)API
    const handleApiGanttChartGroupByMachine = async (apiDate) => {
      let postJson = {
        datetimeStart: `${apiDate.minDate} 00:00:00`,
        datetimeEnd: `${apiDate.maxDate} 23:59:59`,
      };
      
      let response = await apiGanttChartGroupByMachine(postJson);
      if(response){
          const httpStatus = response.request.status;
          const reponseData = response.data;
  
          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 切換區間模式
    const handleModeChange = (mode) => {
        setMode(mode);
    }
    //#endregion

    //#region 工作日
    const handleGetWorkDays = async () => {
      const [httpStatusWorkDays, reponseDataWorkDays] = await handleApiGetWorkDays();
      if(httpStatusWorkDays == "200"){
        if(reponseDataWorkDays.statusCode === "20000"){
          const data = reponseDataWorkDays.data;
          const trueCount = Object.values(data).filter(value => value === true).length;
          if(trueCount.length === 7){
            setRestday([0, 6]);
          }
          else{
            let restdayArr = [];
            if(!data.Sunday){
              restdayArr.push(0);
            }
            if(!data.Monday){
              restdayArr.push(1);
            }
            if(!data.Tuesday){
              restdayArr.push(2);
            }
            if(!data.Wednesday){
              restdayArr.push(3);
            }
            if(!data.Thursday){
              restdayArr.push(4);
            }
            if(!data.Friday){
              restdayArr.push(5);
            }
            if(!data.Saturday){
              restdayArr.push(6);
            }
            setRestday(restdayArr);
          }
        }
      }
    }
    //#endregion

    //#region 抓取休息日
    const handleApiGetWorkDays = async () => {
      let workDaysResponse = await apiGetWorkDays();
      if(workDaysResponse){
          const httpStatus = workDaysResponse.request.status;
          const reponseData = workDaysResponse.data;
  
          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 上一區間
    const handlePreDate = () => {
      setBehavior("pre");
      if(mode === "week"){
        //檢查前一周日期是否已經長出來惹
        //先將滾動的值減一週
        const scrollPrevSunday = moment(currentWeekDate).subtract(1, 'weeks').day(0).format('YYYY-MM-DD');
        const isAtStart = containerRef.current.scrollLeft === 0;
        
        if(currentWeekDate != null && !isAtStart && (scrollPrevSunday === showWeekRange.minDate ||
          moment(showWeekRange.minDate).isBefore(scrollPrevSunday)))
        {
          const sundayElement = document.getElementById(`week-${scrollPrevSunday}`);
          if(sundayElement){
            sundayElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
          }
          setCurrentWeekDate(scrollPrevSunday);
        }
        else{
          const getPrevSunday = moment(showWeekRange.minDate).subtract(1, 'weeks').day(0).format('YYYY-MM-DD');
          const getPrevSaturday = moment(showWeekRange.minDate).subtract(1, 'weeks').day(6).format('YYYY-MM-DD');

          setShowWeekRange({minDate: getPrevSunday, maxDate: showWeekRange.maxDate});         
          setServerApiDateRange([{minDate: getPrevSunday, maxDate: moment(localApiDateRange.minDate).subtract(1, 'days').format('YYYY-MM-DD')}]);
          setLocalApiDateRange({minDate: getPrevSunday, maxDate: localApiDateRange.maxDate});

          const weeks = moment(showWeekRange.maxDate).diff(getPrevSunday, 'weeks') + 1;
          
          const oldWeekHeadMain = {...weekHeadMain};
          oldWeekHeadMain.weekNum = weeks;
          const oldWeekHeadSecondary = [...weekHeadSecondary];
          let [newWeekHeadMain, newWeekHeadSecondary] = updateWeekTheadDetails(oldWeekHeadMain, oldWeekHeadSecondary, getPrevSunday, getPrevSaturday, restday, "pre");

          setWeekHeadMain(newWeekHeadMain);
          setWeekHeadSecondary(newWeekHeadSecondary);
          setCurrentWeekDate(moment(getPrevSunday).day(0).format('YYYY-MM-DD'));

          document.documentElement.style.setProperty(
            "--dynamic-week-main-width",
            `${weeks * 50}%`
          );  //動態改變--dynamic-week-main-width屬性
          
          const oldWeekBody = [...weekBody];
          let newWeekBody = updateWeekTbodyDetails(machineList, oldWeekHeadSecondary, oldWeekBody, "pre");
          setWeekBody(newWeekBody);
        }
      }
      else if(mode === "day"){
        const scrollPrevDay = moment(currentDayDate).subtract(1, 'days').format('YYYY-MM-DD');
        const isAtStart = containerRef.current.scrollLeft === 0;

        if(currentDayDate != null && !isAtStart && (scrollPrevDay === showDayRange.minDate ||
          moment(showDayRange.minDate).isBefore(scrollPrevDay))){
            const prevDayElement = document.getElementById(`day-${scrollPrevDay}`);
            if(prevDayElement){
              prevDayElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
            }
            setCurrentDayDate(scrollPrevDay);
        }
        else{
          const getPrevDay = moment(showDayRange.minDate).subtract(1, 'days').format('YYYY-MM-DD');
          setShowDayRange({minDate: getPrevDay, maxDate: showDayRange.maxDate});
          setServerApiDateRange([{minDate: getPrevDay, maxDate: getPrevDay}]);
          setLocalApiDateRange({minDate: getPrevDay, maxDate: localApiDateRange.maxDate});

          const oldDayHead = [...dayHead];
          let newDayHeady = updateDayTheadDetails(oldDayHead, getPrevDay, getPrevDay, restday, "pre");
          setDayHead(newDayHeady);

          setCurrentDayDate(getPrevDay);

          const oldDayBody = [...dayBody];
          let newDayBody = updateDayTbodyDetails(machineList, oldDayHead, oldDayBody, "pre");
          setDayBody(newDayBody);
        }
      }
      else{
        const scrollPrevMonth = moment(currentMonthDate).subtract(1, 'days').startOf('month').format('YYYY-MM-DD');
        const isAtStart = containerRef.current.scrollLeft === 0;
        
        if(currentMonthDate != null && !isAtStart && (scrollPrevMonth === showMonthRange.minDate ||
          moment(showMonthRange.minDate).isBefore(scrollPrevMonth))){
          const prevMonthElement = document.getElementById(`month-${scrollPrevMonth}`);
          if(prevMonthElement){
            prevMonthElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
          }
          setCurrentMonthDate(scrollPrevMonth);
        }
        else{
          const getPrevMonth = moment(showMonthRange.minDate).subtract(1, 'months').format('YYYY-MM-DD');
          const getPrevMonthEnd = moment(showMonthRange.minDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
          setShowMonthRange({minDate: getPrevMonth, maxDate: showMonthRange.maxDate});
          setServerApiDateRange([{minDate: getPrevMonth, maxDate: moment(localApiDateRange.minDate).subtract(1, 'days').format('YYYY-MM-DD')}]);
          setLocalApiDateRange({minDate: getPrevMonth, maxDate: localApiDateRange.maxDate});

          const oldMonthHeadMain = [...monthHeadMain];
          const oldMonthHeadSecondary = [...monthHeadSecondary];
          let [newMonthHeadMain, newMonthHeadSecondary] = updateMonthTheadDetails(oldMonthHeadMain, oldMonthHeadSecondary, getPrevMonth, getPrevMonthEnd, restday, "pre");

          setMonthHeadMain(newMonthHeadMain);
          setMonthHeadSecondary(newMonthHeadSecondary);

          setCurrentMonthDate(getPrevMonth);

          const oldMonthBody = [...monthBody];
          let newMonthBody = updateMonthTbodyDetails(oldMonthBody, machineList, newMonthHeadSecondary, "pre");
          setMonthBody(newMonthBody);
        }
      }
    }
    //#endregion

    //#region 下一區間
    const handleNextDate = () => {
      setBehavior("next");
      if(mode === "week"){
        const scrollNextSunday = moment(currentWeekDate).add(1, 'weeks').day(0).format('YYYY-MM-DD');
        const scrollNextSaturday = moment(currentWeekDate).add(1, 'weeks').day(6).format('YYYY-MM-DD');

        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        const isAtEnd = scrollLeft + clientWidth >= scrollWidth;

        if(currentWeekDate != null && !isAtEnd && (showWeekRange.maxDate != scrollNextSaturday)){
            const sundayElement = document.getElementById(`week-${scrollNextSunday}`);
            if(sundayElement){
              sundayElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
            }
            setCurrentWeekDate(scrollNextSunday);
        }
        else{
          const getNextSunday = moment(showWeekRange.maxDate).add(1, 'weeks').day(0).format('YYYY-MM-DD');
          const getNextSaturday = moment(showWeekRange.maxDate).add(1, 'weeks').day(6).format('YYYY-MM-DD');

          setShowWeekRange({minDate: showWeekRange.minDate, maxDate: getNextSaturday});
          setServerApiDateRange([{minDate: moment(localApiDateRange.maxDate).add(1, 'days').format('YYYY-MM-DD'), maxDate: getNextSaturday}]);
          setLocalApiDateRange({minDate: localApiDateRange.minDate, maxDate: getNextSaturday});

          const weeks = moment(getNextSaturday).diff(showWeekRange.minDate, 'weeks') + 1;

          const oldWeekHeadMain = {...weekHeadMain};
          oldWeekHeadMain.weekNum = weeks;
          const oldWeekHeadSecondary = weekHeadSecondary;
          let [newWeekHeadMain, newWeekHeadSecondary] = updateWeekTheadDetails(oldWeekHeadMain, oldWeekHeadSecondary, getNextSunday, getNextSaturday, restday, "next");

          
          setWeekHeadMain(newWeekHeadMain);
          setWeekHeadSecondary(newWeekHeadSecondary);

          setCurrentWeekDate(moment(showWeekRange.maxDate).day(0).format('YYYY-MM-DD'));
          
          document.documentElement.style.setProperty(
            "--dynamic-week-main-width",
            `${weeks * 50}%`
          );  //動態改變--dynamic-week-main-width屬性
          
          const oldWeekBody = weekBody;
          let newWeekBody = updateWeekTbodyDetails(machineList, oldWeekHeadSecondary, oldWeekBody, "next");
          setWeekBody(newWeekBody);

          const sundayElement = document.getElementById(`week-${moment(showWeekRange.maxDate).day(0).format('YYYY-MM-DD')}`);
          if(sundayElement){
            setTimeout(() => {
              sundayElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
            }, 100); 
          }
        }
      }
      else if(mode === "day"){
        const scrollNextDay = moment(currentDayDate).add(1, 'days').format('YYYY-MM-DD');

        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        const isAtEnd = scrollLeft + clientWidth >= scrollWidth;

        if(currentDayDate != null && !isAtEnd && (showDayRange.maxDate != scrollNextDay)){
          const nextDayElement = document.getElementById(`day-${scrollNextDay}`);
          if(nextDayElement){
            nextDayElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
          }
          setCurrentDayDate(scrollNextDay);
        }
        else{
          const getNextDay = moment(showDayRange.maxDate).add(1, 'days').format('YYYY-MM-DD');
          setShowDayRange({minDate: showDayRange.minDate, maxDate: getNextDay});
          setServerApiDateRange([{minDate: moment(localApiDateRange.maxDate).add(1, 'days').format('YYYY-MM-DD'), maxDate: getNextDay}]);
          setLocalApiDateRange({minDate: localApiDateRange.minDate, maxDate: getNextDay});

          const oldDayHead = [...dayHead];
          let newDayHeady = updateDayTheadDetails(oldDayHead, getNextDay, getNextDay, restday, "next");
          setDayHead(newDayHeady);

          setCurrentDayDate(moment(getNextDay).format('YYYY-MM-DD'));

          const oldDayBody = [...dayBody];
          let newDayBody = updateDayTbodyDetails(machineList, oldDayHead, oldDayBody, "next");
          setDayBody(newDayBody);

          const nextDayElement = document.getElementById(`day-${moment(scrollNextDay).format('YYYY-MM-DD')}`);
          if(nextDayElement){
            setTimeout(() => {
              nextDayElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
            }, 100); 
          }
        }
      }
      else{
        const scrollNextMonth = moment(currentMonthDate).add(1, 'months').startOf("month").format('YYYY-MM-DD');

        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        const isAtEnd = scrollLeft + clientWidth >= scrollWidth;
        
        if(currentMonthDate != null && !isAtEnd && (showMonthRange.maxDate != scrollNextMonth)){
          const nextMonthElement = document.getElementById(`month-${moment(scrollNextMonth).format('YYYY-MM-DD')}`);
          if(nextMonthElement){
            nextMonthElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
          }
          setCurrentMonthDate(scrollNextMonth);
        }
        else{
          const getNextMonth = moment(showMonthRange.maxDate).add(1, 'months').startOf('month').format('YYYY-MM-DD');
          const getNextMonthEnd = moment(showMonthRange.maxDate).add(1, 'months').endOf('month').format('YYYY-MM-DD');
          setShowMonthRange({minDate: showMonthRange.minDate, maxDate: getNextMonthEnd});
          setServerApiDateRange([{minDate: moment(localApiDateRange.maxDate).add(1, 'days').format('YYYY-MM-DD'), maxDate: getNextMonthEnd}]);
          setLocalApiDateRange({minDate: localApiDateRange.minDate, maxDate: getNextMonthEnd});

          const oldMonthHeadMain = [...monthHeadMain];
          const oldMonthHeadSecondary = [...monthHeadSecondary];
          let [newMonthHeadMain, newMonthHeadSecondary] = updateMonthTheadDetails(oldMonthHeadMain, oldMonthHeadSecondary, getNextMonth, getNextMonthEnd, restday, "next");

          setMonthHeadMain(newMonthHeadMain);
          setMonthHeadSecondary(newMonthHeadSecondary);

          setCurrentMonthDate(getNextMonth);

          const oldMonthBody = [...monthBody];
          let newMonthBody = updateMonthTbodyDetails(oldMonthBody, machineList, newMonthHeadSecondary, "next");
          setMonthBody(newMonthBody);

          setTimeout(() => {
            const nextMonthElement = document.getElementById(`month-${scrollNextMonth}`);
            if(nextMonthElement){
              nextMonthElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
            }
          }, 100); 
        }
      }
    }
    //#endregion

    //#region 偵測滾動事件，更新當前日期
    const handleScroll = () => {
      if (containerRef.current) {
        const children = Array.from(containerRef.current.children[0].children[1].children);

        const visibleChild = children.find((child) => {
          const rect = child.getBoundingClientRect();
          if(mode === "week"){
            return rect.left >= 0 && rect.right <= window.clientWidth;
          }
          return rect.left >= 0 && rect.right <= window.innerWidth;
        });

        if (visibleChild) {
          if(mode === "week"){
            setCurrentWeekDate(visibleChild.dataset.value);
          }
          else if(mode === "day"){
            setCurrentDayDate(visibleChild.dataset.value);
          }
          else{
            setCurrentMonthDate(visibleChild.dataset.value);
          }
        }
      }
    };
    //#endregion

    //#region 返回初始載入日期區間
    const handleBackInit = () => {
      if(mode === "week"){
        const scrollWeekDay = moment().day(0).format('YYYY-MM-DD');
        const element = document.getElementById(`week-${scrollWeekDay}`);
        if(element){
          element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        }
      }
      else if(mode === "day"){
        const scrollTodayDay = moment().format('YYYY-MM-DD');
        const element = document.getElementById(`day-${scrollTodayDay}`);
        if(element){
          element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        }
      }
      else{
        const scrollMonthDay = moment().startOf('month').format('YYYY-MM-DD');
        const element = document.getElementById(`month-${scrollMonthDay}`);
        if(element){
          element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        }
      }
    }
    //#endregion

    return (
        <div className="GanttChart-wrapper">
          <div className="filterRow">
            <div>
              {/*區間切換*/}
              <div className='btnSwitch-text blue'>
                <button type="button" className={mode === "day" ? "selected" : ""} 
                  onClick={()=>handleModeChange("day")}>
                  {t("btnday")}
                </button>
                <button type="button" className={mode === "week" ? "selected" : ""} 
                  onClick={()=>handleModeChange("week")}>
                  {t("btnweek")}
                </button>
                <button type="button" className={mode === "month" ? "selected" : ""} 
                  onClick={()=>handleModeChange("month")}>
                  {t("btnmonth")}
                </button>
              </div>

              {/*返回本週 返回本月 返回本日*/}
              <button className="btn btn-lv3 btn-icontext-rwd" onClick={handleBackInit}>
                <div className="icon icon-BackToThisWeek"></div>
                <span>
                {(() => {
                  if(mode === "week"){
                    return t("backToThisWeek");
                  }
                  else if(mode === "month"){
                    return t("backToThisMonth");
                  }
                  else{
                    return t("backToToday");
                  }
                })()}  
                </span>
              </button>
            </div>
            <div>
              {/*「修改排程」按鈕*/}
              {/* <button className="btn btn-lv1 btn-icontext-rwd" onClick={handleChangeSchedule}>
                <div className="icon icon-ChangeSchedule"></div>
                <span>{t("changeSchedule")}</span>
              </button> */}
              {/*「上一頁」「下一頁」按鈕*/}
              <div className='btnSwitch-icon whiteBlack'>
                <button type="button" onClick={handlePreDate}>
                  <div className="icon icon-PrePage bounce"></div>
                </button>
                <button type="button" onClick={handleNextDate}>
                  <div className="icon icon-NextPage bounce"></div>
                </button>
              </div>
            </div>
          </div>
          <div className="ganttChart mtop20">
            <div className="table-left">
              <div className="thead">
                <div className="tr main"></div>
                <div className="tr secondary"></div>
              </div>
              {/*所有設備的列表*/}
              <div className="tbody">
              {
                machineList.map((obj, index) => {
                  return(
                    <div className="tr" key={`machine${obj.mid}`}><div className="th"><div>{obj.mShortName}</div></div></div>
                  )
                })
              }
              </div>
            </div>
            <div className={`table-right scroll ${mode === "day" ? "daily" : ""}${mode === "week" ? "weekly" : ""}${mode === "month" ? "monthly" : ""}`} 
              ref={containerRef} onScroll={handleScroll}>
              <div className="thead">
                <Thead mode={mode} 
                  restday={restday}
                  machineList={machineList}
                  weekHeadMain={weekHeadMain}
                  setWeekHeadMain={setWeekHeadMain} 
                  weekHeadSecondary={weekHeadSecondary}
                  setWeekHeadSecondary={setWeekHeadSecondary}
                  setWeekBody={setWeekBody}
                  dayHead={dayHead}
                  setDayHead={setDayHead}
                  setDayBody={setDayBody}
                  setShowDayRange={setShowDayRange}
                  monthHeadMain={monthHeadMain}
                  setMonthHeadMain={setMonthHeadMain}
                  monthHeadSecondary={monthHeadSecondary}
                  setMonthHeadSecondary={setMonthHeadSecondary}
                  setMonthBody={setMonthBody}
                  setShowMonthRange={setShowMonthRange}
                  setCurrentDayDate={setCurrentDayDate}
                  setCurrentMonthDate={setCurrentMonthDate}
                  localApiDateRange={localApiDateRange}
                  setLocalApiDateRange={setLocalApiDateRange}
                  setServerApiDateRange={setServerApiDateRange}
                />
              </div>
              <div className="tbody">
                <Tbody mode={mode}
                  weekBody={weekBody}
                  setWeekBody={setWeekBody}
                  currentWeekDate={currentWeekDate}
                  dayBody={dayBody}
                  setDayBody={setDayBody}
                  currentDayDate={currentDayDate}
                  monthBody={monthBody}
                  setMonthBody={setMonthBody}
                  currentMonthDate={currentMonthDate}
                />
              </div>
            </div>
          </div>
        </div>
    );
};

//#region thead main Dom
const Thead = ({mode, restday, machineList,
  weekHeadMain, setWeekHeadMain, 
  weekHeadSecondary, setWeekHeadSecondary,
  setWeekBody,
  dayHead, setDayHead, 
  setDayBody, setShowDayRange,
  monthHeadMain, setMonthHeadMain, 
  monthHeadSecondary, setMonthHeadSecondary,
  setMonthBody, setShowMonthRange,
  setCurrentDayDate, setCurrentMonthDate,
  localApiDateRange, setLocalApiDateRange,
  setServerApiDateRange
}) => {
  const { t } = useTranslation("aps");

  useEffect(() => {
    if(restday.length > 0 && machineList.length > 0){
      //第一次進來
      if(weekHeadMain.weekNum === 0 && mode === "week"){
        const getThisSunday = moment().day(0);
        const getNextSaturday = moment().add(1, 'weeks').day(6);
  
        const oldWeekHeadMain = { weekNum: 2, detail: [] };
        const oldWeekHeadSecondary = [];
        let [newWeekHeadMain, newWeekHeadSecondary] = updateWeekTheadDetails(oldWeekHeadMain, oldWeekHeadSecondary, getThisSunday, getNextSaturday, restday);
        setWeekHeadMain(newWeekHeadMain);
        setWeekHeadSecondary(newWeekHeadSecondary);

        let newWeekBody = updateWeekTbodyDetails(machineList, newWeekHeadSecondary, []);
        setWeekBody(newWeekBody);
      }
      else if(dayHead.length === 0 && mode === "day"){
        const getToday = moment();
        const getTomorrow = moment().add(1, 'days');

        setShowDayRange({minDate: getToday.format("YYYY-MM-DD"), maxDate: getTomorrow.format("YYYY-MM-DD")});
        setCurrentDayDate(getToday.format("YYYY-MM-DD"));

        let newDayHeady = updateDayTheadDetails([], getToday, getTomorrow, restday);
        setDayHead(newDayHeady);

        let newDayBody = updateDayTbodyDetails(machineList, newDayHeady, []);
        setDayBody(newDayBody);
      }
      else if(monthHeadMain.length === 0 && mode === "month"){
        const getThisMonth = moment().startOf('month');
        const getNextMonth = moment().add(1, 'months').endOf('month');

        setShowMonthRange({minDate: getThisMonth.format("YYYY-MM-DD"), maxDate: getNextMonth.format("YYYY-MM-DD")});
        setCurrentMonthDate(getThisMonth.format("YYYY-MM-DD"));
        
        const isBefore = moment(getThisMonth).isBefore(moment(localApiDateRange.minDate));
        const isAfter = moment(getNextMonth).isAfter(moment(localApiDateRange.maxDate));

        let localApiDateMinDate = moment(localApiDateRange.minDate);
        let localApiDateMaxDate = moment(localApiDateRange.maxDate);
        let apiDate = [];
        if(isBefore){
          localApiDateMinDate = getThisMonth;
          apiDate.push({
            minDate: getThisMonth.format("YYYY-MM-DD"), 
            maxDate: moment(localApiDateRange.minDate).subtract(1, 'days').format('YYYY-MM-DD')
          });
        }

        if(isAfter){
          localApiDateMaxDate = getNextMonth;
          apiDate.push({
            minDate: moment(localApiDateRange.maxDate).add(1, 'days').format('YYYY-MM-DD'), 
            maxDate: getNextMonth.format("YYYY-MM-DD")
          });
        }

        if(isBefore || isAfter){
          setServerApiDateRange(apiDate);
          setLocalApiDateRange({
            minDate: localApiDateMinDate.format('YYYY-MM-DD'), 
            maxDate: localApiDateMaxDate.format('YYYY-MM-DD')
          });
        }

        let [newMonthHeadMain, newMonthHeadSecondary] = updateMonthTheadDetails([], [], getThisMonth, getNextMonth, restday);
        setMonthHeadMain(newMonthHeadMain);
        setMonthHeadSecondary(newMonthHeadSecondary);

        let newMonthBody = updateMonthTbodyDetails([], machineList, newMonthHeadSecondary);
        setMonthBody(newMonthBody);
      }
    }
  }, [restday, mode, machineList]);

  return(
    <>
      <div className="tr main">
        {
          mode === "week" ?
            <div className="dynamic-trMainWidth">
              {/*這塊正下方是多少天 ，flex:N的N就填那個天數*/}
              {
                weekHeadMain?.detail?.map((obj, index) => {
                  return(
                    <div className="td" key={`weekHeadMain${index}`} style={{ flex: obj.flex }}>
                      <div>
                        <b>
                          {t(convertDateFormat(obj.showTxt, "MMM"))}
                          {convertDateFormat(obj.showTxt, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.showTxt, "YYYY")}</> : null}
                        </b>
                      </div>
                    </div>
                  )
                })
              }
            </div> : null
        }
        {
          mode === "day" && dayHead?.map((obj, index) => {
            return(
              <div className={`td split ${obj.isRestday ? "restday" : ""}`} key={`dayHead${index}`}>
                <div>
                  <b>
                    {convertDateFormat(obj.showTxt, "MMM DD")}
                    {convertDateFormat(obj.showTxt, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.showTxt, "YYYY")}</> : null}
                  </b>
                  <span className="weekday">{t(obj.weekTxt)}</span>
                </div>
              </div>
            )
          })
        }
        {
          mode === "month" && monthHeadMain?.map((obj, index) => {
            return(
              <div className={`td split wDay${(obj.dayNum)?.toString()}`} key={`monthHeadMain${index}`}>
                <div>
                  <b>
                    {t(convertDateFormat(obj.showTxt, "MMM"))}
                    {convertDateFormat(obj.showTxt, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.showTxt, "YYYY")}</> : null}
                  </b>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="tr secondary">
      {
        mode === "week" && weekHeadSecondary?.map((obj, index) => {
          return(
            <div className="split" id={`week-${obj.detail[0].showTxt}`} data-value={`${obj.detail[0].showTxt}`} 
              key={`weekHeadSecondary${index}`}>
              {
                obj.detail.map((objDetail, index) => {
                  return(
                    <div
                      className={`td ${objDetail.isRestday ? "restday" : ""} ${objDetail.isEnd ? "end" : ""}`} 
                      key={`weekHeadSecondaryDetail${index}`}>
                      <div>
                        <div>{convertDateFormat(objDetail.showTxt, "DD")}</div>
                        <div className="weekday">{t(objDetail.weekTxt)}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
      {
        mode === "day" && dayHead?.map((obj, index) => {
          return(
            <div className="split" id={`day-${obj.showTxt}`} data-value={`${obj.showTxt}`} key={`dayHeadSecondary${index}`}>
              {/*當是「日」的時候，這塊就沒有日期和星期幾，連同外層的<div>一起都拿掉：<div><div>日期</div><div className="weekday">星期幾</div></div>*/}
              <div className={`td ${obj.isRestday ? "restday" : ""}`}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
              </div>
            </div>
          )
        })
      }
      {
        mode === "month" && monthHeadSecondary?.map((obj, index) => {
          return(
            <div className={`split wDay${(obj.dayNum)?.toString()}`} id={`month-${obj.detail[0].showTxt}`} data-value={`${obj.detail[0].showTxt}`} 
              key={`monthHeadSecondary${index}`}>
              {
                obj.detail.map((objDetail, index) => {
                  return(
                    <div className={`td ${objDetail.isRestday ? "restday" : ""}`} key={`monthHeadSecondaryDetail${index}`}>
                      <div>
                        <div>{convertDateFormat(objDetail.showTxt, "D")}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
      </div>
    </>
)
}
//#endregion

//#region 更新Week thead
const updateWeekTheadDetails = (oldWeekHeadMain, oldWeekHeadSecondary, startDate, endDate, restday, behavior = "") => {
  let newWeekHeadMain = oldWeekHeadMain;
  let weekDetail = oldWeekHeadMain.detail;

  //上排大範圍
  let thisSundayLastDay = moment(startDate).endOf('month');
  let daysBetweenWeekFirst = moment(thisSundayLastDay).diff(moment(startDate), 'days') + 1;

  let filterFirst = weekDetail.findIndex((obj) => obj.showTxt === moment(startDate).format('YYYY-MM-01'));

  if(filterFirst === -1){
    if(behavior === "pre"){
      weekDetail.unshift(
        {
          showTxt: moment(startDate).format('YYYY-MM-01'),
          flex: daysBetweenWeekFirst
        }
      );
    }
    else{
      weekDetail.push(
        {
          showTxt: moment(startDate).format('YYYY-MM-01'),
          flex: daysBetweenWeekFirst
        }
      );
    }
  }
  else{
    if(behavior === "pre"){
      weekDetail[filterFirst].flex = daysBetweenWeekFirst;
    }
    else{
      weekDetail[filterFirst].flex = weekDetail[filterFirst].flex + 7;
    }
  }
  
  if (moment(startDate).format('YYYY-MM-01') !== moment(endDate).format('YYYY-MM-01')) {
    const nextSaturdayFirstDay = moment(endDate).startOf('month');
    let daysBetweenWeekSecond = moment(endDate).diff(moment(nextSaturdayFirstDay), 'days') + 1;

    let filterSecond = weekDetail.findIndex((obj) => obj.showTxt === moment(endDate).format('YYYY-MM-01'));
    
    if(filterSecond === -1){
      if(behavior === "pre"){
        weekDetail.unshift(
          {
            showTxt: moment(endDate).format('YYYY-MM-01'),
            flex: daysBetweenWeekSecond
          }
        );
      }
      else{
        weekDetail.push(
          {
            showTxt: moment(endDate).format('YYYY-MM-01'),
            flex: daysBetweenWeekSecond
          }
        );
      }
    }
    else{
      if(behavior === "next"){
        weekDetail[filterSecond].flex = daysBetweenWeekSecond;
      }
      else{
        weekDetail[filterSecond].flex = daysBetweenWeekSecond + weekDetail[filterSecond].flex
      }
    }
  }
  newWeekHeadMain.detail = weekDetail;

  //下排小範圍
  let currentDate = moment(startDate);
  let newWeekHeadSecondary = oldWeekHeadSecondary;
  let secondaryDetail = [];
  while (currentDate.isSameOrBefore(endDate)) {
    if (currentDate.weekday() === 0) {
      secondaryDetail = [];
    }

    const isRestday = restday.includes(currentDate.weekday());

    secondaryDetail.push({
      showTxt: currentDate.format('YYYY-MM-DD'),
      isRestday: isRestday,
      isEnd: currentDate.isSame(moment(currentDate.format('YYYY-MM-DD')).endOf('month'), 'day'),
      weekTxt: currentDate.format('ddd')
    });

    if (currentDate.weekday() === 6) {
      if(behavior === "pre"){
        newWeekHeadSecondary.unshift({ detail: secondaryDetail });
      }
      else{
        newWeekHeadSecondary.push({ detail: secondaryDetail });
      }
    }

    currentDate.add(1, 'days');
  }

  return [newWeekHeadMain, newWeekHeadSecondary];
};
//#endregion

//#region 更新Day thead
const updateDayTheadDetails = (oldDayHead, startDate, endDate, restday, behavior="") => {
  let newDayHead = oldDayHead;
  let currentDate = moment(startDate);

  const addDayToHead = (date, behavior, isRestday, newDayHead) => {
    const day = {
      showTxt: moment(date).format('YYYY-MM-DD'),
      isRestday: isRestday,
      weekTxt: moment(date).format('ddd')
    };
    
    if (behavior === "pre") {
      newDayHead.unshift(day);
    } else {
      newDayHead.push(day);
    }
  };
  
  //上排大範圍
  if (behavior === "") {
    while (currentDate.isSameOrBefore(endDate)) {
      const isRestday = restday.includes(currentDate.weekday());
      addDayToHead(currentDate, behavior, isRestday, newDayHead);
      currentDate.add(1, 'days');
    }
  } else {
    const isRestday = restday.includes(currentDate.weekday());
    addDayToHead(currentDate, behavior, isRestday, newDayHead);
  }

  return newDayHead;
}
//#endregion

//#region 更新Month thead
const updateMonthTheadDetails = (oldMonthHeadMain, oldMonthHeadSecondary, startDate, endDate, restday, behavior="") => {
  let newMonthHeadMain = oldMonthHeadMain;
  let newMonthHeadSecondary = oldMonthHeadSecondary;
  let secondaryDetail = oldMonthHeadSecondary.detail;
  let currentDate = moment(startDate);

  while(currentDate.isSameOrBefore(endDate)){
    if (currentDate.date() === 1) {
      let daysInMonth = moment(currentDate).daysInMonth();

      if(behavior === "pre"){
        newMonthHeadMain.unshift({
          showTxt: currentDate.format('YYYY-MM-DD'),
          dayNum: daysInMonth
        });
      }
      else{
        newMonthHeadMain.push({
          showTxt: currentDate.format('YYYY-MM-DD'),
          dayNum: daysInMonth
        });
      }
      
      secondaryDetail = [];
    }
  
    const isRestday = restday.includes(currentDate.weekday());
    secondaryDetail.push({
      showTxt: currentDate.format('YYYY-MM-DD'),
      isRestday: isRestday
    });
  
    if (currentDate.isSame(currentDate.clone().endOf('month'), 'day')) {
      let daysInMonth = moment(currentDate).daysInMonth();

      if(behavior === "pre"){
        newMonthHeadSecondary.unshift({dayNum: daysInMonth, detail: secondaryDetail });
      }
      else{
        newMonthHeadSecondary.push({dayNum: daysInMonth, detail: secondaryDetail });
      }
    }
  
    currentDate.add(1, 'days');
  }

  return [newMonthHeadMain, newMonthHeadSecondary];
}
//#endregion

//#region tbody Dom
const Tbody = ({mode, 
  weekBody, setWeekBody, currentWeekDate, 
  dayBody, setDayBody, currentDayDate,
  monthBody, setMonthBody, currentMonthDate}) => {
  const barChartRef = useRef(null);
  const barClickRef = useRef(null); // 使用 Ref 替代狀態

  // 更新 weekBody 的函數(disabled)
  const updateWeekBody = (newWeekBody, orderNo, reset = false) => {
    let filterIndex = newWeekBody[0].weekDetail.findIndex(
      (d) => d.weekSunday === currentWeekDate
    );

    if (filterIndex !== -1) {
      for (let machine of newWeekBody) {
        for (let i = filterIndex; i <= filterIndex + 1 && i < machine.weekDetail.length; i++) {
          const split = machine.weekDetail[i].split;

          for (let objSplit of split) {
            if (objSplit.orderList.length === 0) {
              continue;
            }

            for (let order of objSplit.orderList) {
              order.disabled = reset ? false : order.orderNo !== orderNo; // 設置 disabled
            }
          }
        }
      }

      setWeekBody(newWeekBody);
    }
  }

  // 更新 dayBody 的函數(disabled)
  const updateDayBody = (newDayBody, orderNo, reset = false) => {
    let filterIndex = newDayBody[0].dayDetail.findIndex(
      (d) => d.showTxt === currentDayDate
    );

    if (filterIndex !== -1) {
      for (let machine of newDayBody) {
        for (let i = filterIndex; i <= filterIndex + 1 && i < machine.dayDetail.length; i++) {
          for (let order of machine.dayDetail[filterIndex].orderList) {
            order.disabled = reset ? false : order.orderNo !== orderNo; // 設置 disabled
          }
        }
      }

      setDayBody(newDayBody);
    }
  }

  // 更新 monthBody 的函數(disabled) => 這要檢查哪裡錯誤
  const updateMonthBody = (newMonthBody, orderNo, reset = false) => {
    let filterIndex = newMonthBody[0].monthDetail.findIndex(
      (d) => d.showTxt === currentMonthDate
    );

    if (filterIndex !== -1) {
      for (let machine of newMonthBody) {
        for (let i = filterIndex; i <= filterIndex + 1 && i < machine.monthDetail.length; i++) {
          const split = machine.monthDetail[i].split;

          for (let objSplit of split) {
            if (objSplit.orderList.length === 0) {
              continue;
            }

            for (let order of objSplit.orderList) {
              order.disabled = reset ? false : order.orderNo !== orderNo; // 設置 disabled
            }
          }
        }
      }

      setMonthBody(newMonthBody);
    }
  }

  const handleBarClick = (obj) => {
    barClickRef.current = true; // 更新 Ref

    const orderNo = obj.orderNo;
    copy(`${orderNo}-${obj.processNo}`);
    if(mode === "week"){
      let newWeekBody = JSON.parse(JSON.stringify(weekBody));
      updateWeekBody(newWeekBody, orderNo);
    }
    else if(mode === "day"){
      let newDayBody = JSON.parse(JSON.stringify(dayBody));
      updateDayBody(newDayBody, orderNo);
    }
    else{
      let newMonthBody = JSON.parse(JSON.stringify(monthBody));
      updateMonthBody(newMonthBody, orderNo);
    }
  }

  useEffect(() => {
    //點擊在bar以外的事件
    const handleClickOutside = (event) => {
      if (barChartRef.current && !barChartRef.current.contains(event.target)) {
        if (barClickRef.current) {
            barClickRef.current = false;
        }
        else{
          if(mode === "week"){
            let newWeekBody = JSON.parse(JSON.stringify(weekBody));
            updateWeekBody(newWeekBody, null, true); // reset disabled
          }
          else if(mode === "day"){
            let newDayBody = JSON.parse(JSON.stringify(dayBody));
            updateDayBody(newDayBody, null, true);
          }
          else{
            let newMonthBody = JSON.parse(JSON.stringify(monthBody));
            updateMonthBody(newMonthBody, null, true);
          }
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
        document.removeEventListener("click", handleClickOutside);
    };
  }, [weekBody, dayBody, monthBody]);

  return(
    <>
      {
        mode === "week" && weekBody?.map((obj, index) => {
          return(
            <div className="tr" key={`weekBody${index}`}>
              {
                obj.weekDetail.map((objDetail, indexDetail) => {
                  return(
                    <div className="split" key={`weekBodySplit${indexDetail}`}>
                      {
                        objDetail.split.map((objSplit, indexSplit) => {
                          return(
                            <div className={`td standard ${objSplit.isRestday ? "restday" : ""}`} key={`weekBodySplitDiv${indexSplit}`}>
                              {
                                objSplit.orderList.map((objOrder, indexOrder) => {
                                  return(
                                    <React.Fragment key={`weekOrder${indexOrder}`}>
                                      <OverlayTrigger claaName="delay"
                                        key={`weekOrder${indexOrder}`}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip className="custom-tooltip">
                                            {obj.mShortName}<br />
                                            {objOrder.orderNo}-{objOrder.processNo}<br />
                                            {convertDateFormat(objOrder.datetimeStart, "MMM DD hh:mm A")}~{convertDateFormat(objOrder.datetimeEnd, "MMM DD hh:mm A")}
                                          </Tooltip>
                                        }
                                      >
                                        <div ref={barChartRef}
                                          className={`bar ${objOrder.orderBgColor} ${objOrder.judgeStart ? "noStart" : ""} 
                                            ${objOrder.judgeEnd ? "noEnd" : ""} ${objOrder.orderStatus === 2 ? "started" : ""} 
                                            ${objOrder.deliveryDelay ? "delay" : ""} ${objOrder.disabled ? "disabled" : ""}`}
                                          style={{ left: `calc(${objOrder.left || 0}% - 1px)`, width: `calc(${objOrder.width || 0}% - 1px)` }}
                                          onClick={()=>handleBarClick(objOrder)}
                                        >
                                          <div>
                                            <span>{objOrder.orderNo}-{objOrder.processNo}</span>
                                          </div>
                                        </div>
                                      </OverlayTrigger>
                                      {objOrder.deliveryDelay && (
                                        <div
                                          className="delayPin"
                                          style={{
                                            left: `calc(${(objOrder.delayLeft + 1) * 100}% - 1.625rem)`
                                          }}
                                        >
                                          <div className="text">{convertDateFormat(objOrder.scheduledDeliveryDate, "MMM DD")}</div>
                                          <div className="icon">
                                            <div className="icon-delayPin"></div>
                                          </div>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
      {
        mode === "day" && dayBody?.map((obj, index) => {
          return(
            <div className="tr" key={`dayBody${index}`}>
              {
                obj.dayDetail.map((objDetail, indexDetail) => {
                  return(
                    <div className="split" key={`dayBodyDetail${indexDetail}`}>
                      <div className={`td standard ${objDetail.isRestday ? "restday" : ""}`}>
                      {
                        objDetail.orderList.map((objOrder, indexOrder) => {
                          return(
                            <React.Fragment key={`dayOrder${indexOrder}`}>
                              <OverlayTrigger className="delay"
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    {obj.mShortName}<br />
                                    {objOrder.orderNo}-{objOrder.processNo}<br />
                                    {convertDateFormat(objOrder.datetimeStart, "MMM DD hh:mm A")}~{convertDateFormat(objOrder.datetimeEnd, "MMM DD hh:mm A")}
                                  </Tooltip>
                                }
                              >
                                <div ref={barChartRef}
                                  className={`bar ${objOrder.orderBgColor} ${objOrder.judgeStart ? "noStart" : ""} 
                                    ${objOrder.judgeEnd ? "noEnd" : ""} ${objOrder.orderStatus === 2 ? "started" : ""} 
                                    ${objOrder.deliveryDelay ? "delay" : ""} ${objOrder.disabled ? "disabled" : ""}`}
                                  style={{ left: `calc(${objOrder.left || 0}% - 1px)`, width: `calc(${objOrder.width || 0}% - 1px)` }}
                                  onClick={()=>handleBarClick(objOrder)}
                                >
                                  <div>
                                    <span>{objOrder.orderNo}-{objOrder.processNo}</span>
                                  </div>
                                </div>
                              </OverlayTrigger>
                              {objOrder.deliveryDelay && (
                                <div
                                  className="delayPin"
                                  style={{
                                    left: `calc(${(objOrder.delayLeft + 1) * 100}% - 1.625rem)`
                                  }}
                                >
                                  <div className="text">{convertDateFormat(objOrder.scheduledDeliveryDate, "MMM DD")}</div>
                                  <div className="icon">
                                    <div className="icon-delayPin"></div>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          )
                        })
                      }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
      {
        mode === "month" && monthBody?.map((obj, index) => {
          return(
            <div className="tr" key={`monthBody${index}`}>
              {
                obj.monthDetail.map((objDetail, indexDetail) => {
                  return(
                    <div className={`split wDay${(objDetail.dayNum)?.toString()}`} 
                      key={`monthBodyDetail${indexDetail}`}>
                      {
                        objDetail.split.map((objSplit, indexSplit) => {
                          return(
                            <div className={`td standard ${objSplit.isRestday ? "restday" : ""}`} 
                              key={`monthBodySplitDiv${indexSplit}`}>
                              {
                                objSplit.orderList.map((objOrder, indexOrder) => {
                                  return(
                                    <React.Fragment key={`monthOrder${indexOrder}`}>
                                      <OverlayTrigger className="delay"
                                        placement="bottom"
                                        overlay={
                                          <Tooltip className="custom-tooltip">
                                            {obj.mShortName}<br />
                                            {objOrder.orderNo}-{objOrder.processNo}<br />
                                            {convertDateFormat(objOrder.datetimeStart, "MMM DD hh:mm A")}~{convertDateFormat(objOrder.datetimeEnd, "MMM DD hh:mm A")}
                                          </Tooltip>
                                        }
                                      >
                                        <div ref={barChartRef}
                                          className={`bar ${objOrder.orderBgColor} ${objOrder.judgeStart ? "noStart" : ""} 
                                            ${objOrder.judgeEnd ? "noEnd" : ""} ${objOrder.orderStatus === 2 ? "started" : ""} 
                                            ${objOrder.deliveryDelay ? "delay" : ""} ${objOrder.disabled ? "disabled" : ""}`}
                                          style={{ left: `calc(${objOrder.left || 0}% - 1px)`, width: `calc(${objOrder.width || 0}% - 1px)` }}
                                          onClick={()=>handleBarClick(objOrder)}
                                        >
                                          <div>
                                            <span>{objOrder.orderNo}-{objOrder.processNo}</span>
                                          </div>
                                        </div>
                                      </OverlayTrigger>
                                      {objOrder.deliveryDelay && (
                                        <div
                                          className="delayPin"
                                          style={{
                                            left: `calc(${(objOrder.delayLeft + 1) * 100}% - 1.625rem)`
                                          }}
                                        >
                                          <div className="text">{convertDateFormat(objOrder.scheduledDeliveryDate, "MMM DD")}</div>
                                          <div className="icon">
                                            <div className="icon-delayPin"></div>
                                          </div>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </>
  )
}
//#endregion

//#region 更新Week tbody
const updateWeekTbodyDetails = (machineList, weekHeadSecondary, oldWeekBody, behavior = "") => {
  let newWeekBody = oldWeekBody;

  let weekDetail = [];

  const createSplit = (detail) => {
    return detail.map(({ showTxt, isRestday }) => ({
      showTxt,
      isRestday,
      orderList: [],
    }));
  };

  if (behavior === "" && newWeekBody.length === 0){
    machineList.forEach((machine, index) => {
      let mid = machine.mid;
      let mShortName = machine.mShortName;
  
      if(index === 0){
        for (const headSecondary of weekHeadSecondary) {
          const { detail } = headSecondary;
      
          weekDetail.push({ weekSunday: detail[0].showTxt, weekSaturday: detail[6].showTxt, split: createSplit(detail) });
        }
      }
      
      newWeekBody.push({
        mid: mid,
        mShortName: mShortName,
        weekDetail: weekDetail
      });
    });
  }
  else{
    newWeekBody.forEach((body, index) => {
      weekDetail = [...body.weekDetail];
      if (behavior === "pre") {
        const firstData = weekHeadSecondary[0].detail;
        const newSplit = { weekSunday: firstData[0].showTxt, weekSaturday: firstData[6].showTxt, split: createSplit(firstData) };
        weekDetail.unshift(newSplit);
      } else {
        const lastData = weekHeadSecondary[weekHeadSecondary.length - 1].detail;
        const newSplit = { weekSunday: lastData[0].showTxt, weekSaturday: lastData[6].showTxt, split: createSplit(lastData) };
        weekDetail.push(newSplit);
      }
      body.weekDetail = weekDetail;
    });
  }

  return newWeekBody;
}
//#endregion

//#region 更新Day tbody
const updateDayTbodyDetails = (machineList, dayHead, oldDayBody, behavior = "") => {
  let newDayBody = oldDayBody;
  let dayDetail = [];

  if (behavior === "" && newDayBody.length === 0){
    machineList.forEach((machine, index) => {
      let mid = machine.mid;
      let mShortName = machine.mShortName;

      if(index === 0){
        for(let day of dayHead){
          let showTxt = day.showTxt;
          let isRestday = day.isRestday;

          dayDetail.push({
            showTxt: showTxt,
            isRestday: isRestday,
            orderList: []
          });
        }
      }

      newDayBody.push({
        mid: mid,
        mShortName: mShortName,
        dayDetail: dayDetail
      });
    });
  }
  else{
    newDayBody.forEach((body, index) => {
      dayDetail = [...body.dayDetail];
      if (behavior === "pre") {
        const firstData = dayHead[0];
        dayDetail.unshift({
          showTxt: firstData.showTxt,
          isRestday: firstData.isRestday,
          orderList: []
        });
      } else {
        const lastData = dayHead[dayHead.length - 1];
        dayDetail.push({
          showTxt: lastData.showTxt,
          isRestday: lastData.isRestday,
          orderList: []
        });
      }

      body.dayDetail = dayDetail;
    });
  }

  return newDayBody;
}
//#endregion

//#region 更新Month tbody
const updateMonthTbodyDetails = (oldMonthBody, machineList, monthHeadSecondary, behavior = "") => {
  let newMonthBody = oldMonthBody;
  let monthDetail = [];
  
  const createSplit = (detail) => {
    return detail.map(({ showTxt, isRestday }) => ({
      showTxt,
      isRestday,
      orderList: [],
    }));
  };

  if (behavior === "" && newMonthBody.length === 0){
    machineList.forEach((machine, index) => {
      let mid = machine.mid;
      let mShortName = machine.mShortName;

      if(index === 0){
        for(let month of monthHeadSecondary){
          let detail = month.detail;
          let split = [];

          for(let day of detail){
            let showTxt = day.showTxt;
            let isRestday = day.isRestday;

            split.push({
              showTxt: showTxt,
              isRestday: isRestday,
              orderList: []
            });
          }

          monthDetail.push({
            dayNum: month.dayNum,
            showTxt: split[0].showTxt,
            split: split
          });
        }
      }

      newMonthBody.push({
        mid: mid,
        mShortName: mShortName,
        monthDetail: monthDetail
      });
    });
  }
  else{
    newMonthBody.forEach((body, index) => {
      monthDetail = [...body.monthDetail];
      if (behavior === "pre") {
        const firstData = monthHeadSecondary[0].detail;
        const newSplit = { dayNum: firstData.length, showTxt: firstData[0].showTxt, split: createSplit(firstData) };
        monthDetail.unshift(newSplit);
      } else {
        const lastData = monthHeadSecondary[monthHeadSecondary.length - 1].detail;
        const newSplit = { dayNum: lastData.length, showTxt: lastData[0].showTxt, split: createSplit(lastData) };
        monthDetail.push(newSplit);
      }
        
      body.monthDetail = monthDetail;
    });
  }

  return newMonthBody;
}
//#endregion

export default GanttChart;