import moment from 'moment';

//#region 轉換秒數格式
export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    let result = '';
  
    if (hours > 0) {
      result += `${hours < 10 ? '0' : ''}${hours}:`;
    }
    else{
        result += `00:`;
    }
  
    if (remainingMinutes > 0) {
      result += `${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}:`;
    }
    else{
        result += `00:`;
    }
  
    if (remainingSeconds > 0) {
      result += `${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }
    else{
        result += `00`;
    }
  
    return result.trim();
}
//#endregion

//#region 轉換秒數格式(天)
export const formatTimeDays = (seconds, t) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const remainingSeconds = seconds % 60;
  
  const daysString = days > 0 ? `${days}${t("day")} ` : '';
  const hoursString = hours.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');
  const secondsString = remainingSeconds.toString().padStart(2, '0');

  return `${daysString}${hoursString}:${minutesString}:${secondsString}`;
}
//#endregion

//#region 時間轉換成秒數
export const timeStringToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return (hours * 3600) + (minutes * 60) + seconds;
}
//#endregion

//#region 時間格式
export const formatTime24Hour = (hours, minutes, seconds) => {
  let newHours = hours === "" ? "00" : hours;
  let newMinutes = minutes === "" ? "00" : minutes;
  let newSeconds = seconds === "" ? "00" : seconds;
  return newHours + ":" + newMinutes + ":" + newSeconds;
}
//#endregion

export const isValidTimeString = (timeString) => {
  // 正則表達式匹配
  const regex = /^(\d+):(\d{1,2}):(\d{1,2})$/;
  const match = timeString.match(regex);
  
  // 如果未匹配到或者匹配的時間大於等於60的情況返回 false
  if (!match || Number(match[2]) >= 60 || Number(match[3]) >= 60) {
    return false;
  }

  // 檢查第一個匹配組（小時）是否為正整數
  if (Number.isInteger(Number(match[1])) && Number(match[1]) >= 0) {
    return true;
  }

  return false;
}

const leftChar = (text, char, number, left ) => {
  return text.length >= number ? char : ''
  // if text.length >= number
  // '1', '0', 3 -> 001
  // "A", ' ', 3 -> "  A"
  // "ABCD", ' ', 3 -> "ABCD"
}

//#region 千分位
export const addThousandsSeparator = (number) => {
    // 使用正則表達式將數字加上千分位分隔符
    if(number != null){
      if (Number.isInteger(number)){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      else{
        const parts = number.toString().split('.');
        if(parts[1] != undefined){
          return parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + parts[1];
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return "-";
}
//#endregion

//#region 去除千分位
export const removeThousandsSeparator = (numberString) => {
    return numberString.replace(/,/g, '');
}
//#endregion

//#region 轉換成美國格式
export const getCurrentTimeInUSFormat = () => {
  const date = new Date();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()]; // 获取月份的缩写

  const day = date.getDate(); // 获取日期

  const year = date.getFullYear(); // 获取年份

  let hours = date.getHours(); // 获取小时
  const amOrPm = hours >= 12 ? 'PM' : 'AM'; // 根据小时判断上午或下午
  hours = hours % 12 || 12; // 将小时转换为12小时制

  const minutes = ('0' + date.getMinutes()).slice(-2); // 获取分钟，并确保以两位数显示

  const formattedDate = `${month}. ${day}, ${year} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
  return formattedDate;
};
//#endregion

export const isNullCal = (val) => {
  if(val == null){
    return 0;
  }

  return val;  
}

export const isNullDisplay = (type, val) => {
  if(type == "number"){
    if(val == null){
      return "-";
    }
  }
  return val;  
}

//#region 獲取目前日期時間
export const getNowDateTime = () => {
  const currentDate = new Date(); // 获取当前日期和时间

  const year = currentDate.getFullYear(); // 年份
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 月份（注意需要加1，且补零）
  const day = ('0' + currentDate.getDate()).slice(-2); // 日期（补零）

  const hours = ('0' + currentDate.getHours()).slice(-2); // 小时（补零）
  const minutes = ('0' + currentDate.getMinutes()).slice(-2); // 分钟（补零）
  const seconds = ('0' + currentDate.getSeconds()).slice(-2); // 秒钟（补零）

  const formattedDate = `${year}-${month}-${day}_${hours}${minutes}${seconds}`; // 格式化日期
  return formattedDate;
}
//#endregion

export const countTrueValues = (obj) => {
  const trueValues = Object.values(obj).filter(value => value === true);
  return trueValues.length;
}

//#region 算百分比
export const decimalWithOneDecimal  = (Numerator, Denominator, decimalPoint = 1) => {
  if(Numerator == 0 || Denominator == 0){
    return '0';
  }
  else{
    let percentage = (Numerator / Denominator) * 100;
    let value = percentage.toFixed(decimalPoint);
    return value;
  }
}
//#endregion

//#region 判斷稼動率評級
export const judgeUtilizationRate = (calc, ratingCriteria) => {
  let value = "";
  if(calc >= ratingCriteria?.great){
    value = "great";
  }
  else if(calc >= ratingCriteria?.good){
    value = "good";
  }
  else if(calc >= ratingCriteria?.fair){
    value = "fair";
  }
  else{
    value = "poor";
  }

  return value;
}
//#endregion

//#region 稼動率評級結果轉換成CSS
export const utilizationRateTransCss = (ratingCriteria) => {
  let value = "";
  if(ratingCriteria === "great"){
    value = "1";
  }
  else if(ratingCriteria === "good"){
    value = "2";
  }
  else if(ratingCriteria === "fair"){
    value = "3";
  }
  else{
    value = "4";
  }
  return value;
}
//#endregion

//#region 判斷機台錯誤評級
export const judgeErrorTime = (calc, ratingCriteria, multiple, shiftLength = 1) => {
  let value = "";

  if(calc === 0){
    value = "great";
  }
  else if(calc >= (Math.ceil((ratingCriteria?.great / shiftLength) * multiple))){
    value = "poor";
  }
  else if(calc >= (Math.ceil((ratingCriteria?.good / shiftLength) * multiple))){
    value = "fair";
  }
  else if(calc >= (Math.ceil((ratingCriteria?.fair / shiftLength) * multiple))){
    value = "good";
  }
  else{
    value = "great";
  }
  // if(calc <= (Math.ceil((ratingCriteria?.great / shiftLength) * multiple))){
  //   value = "great";
  // }
  // else if(calc <= (Math.ceil((ratingCriteria?.good / shiftLength) * multiple))){
  //   value = "good";
  // }
  // else if(calc <= (Math.ceil((ratingCriteria?.fair / shiftLength) * multiple))){
  //   value = "fair";
  // }
  // else{
  //   value = "poor";
  // }

  return value;
}

export const judgeErrorShutdownTime = (calc, ratingCriteria, multiple, shiftLength = 1) => {
  let value = "";

  if(calc === 0){
    value = "great";
  }
  else if(calc >= (Math.ceil((ratingCriteria?.great / shiftLength) * multiple))){
    value = "poor";
  }
  else if(calc >= (Math.ceil((ratingCriteria?.good / shiftLength) * multiple))){
    value = "fair";
  }
  else if(calc >= (Math.ceil((ratingCriteria?.fair / shiftLength) * multiple))){
    value = "good";
  }
  else{
    value = "great";
  }
  
  // if(calc <= (Math.ceil((ratingCriteria?.fair / shiftLength) * multiple))){
  //   value = "great";
  // }
  // else if(calc <= (Math.ceil((ratingCriteria?.good / shiftLength) * multiple))){
  //   value = "good";
  // }
  // else if(calc <= (Math.ceil((ratingCriteria?.great / shiftLength) * multiple))){
  //   value = "fair";
  // }
  // else{
  //   value = "poor";
  // }

  return value;
}
//#endregion

//#region 計算時間差幾個小時
export const timeCalc = (startTime, endTime) => {
    const formatStartTime = moment(startTime, 'HH:mm');
    const formatEndTime = moment(endTime, 'HH:mm');

    const difference = formatStartTime.diff(formatEndTime);

    if(difference < 0){
      //計算秒數差
      const durationInSeconds = formatEndTime.diff(formatStartTime, 'seconds');
      return durationInSeconds;
    }
    else{
      //後面再考慮 hours = (24:00 - starttime) + (endtime - 00:00)
      let hour24 = moment("24:00", 'HH:mm');
      let hour0 = moment("00:00", 'HH:mm');
      const beforeDurationInSeconds = hour24.diff(formatStartTime, 'seconds');
      const afterDurationInSeconds = formatEndTime.diff(hour0, 'seconds');
      const durationInSeconds = beforeDurationInSeconds + afterDurationInSeconds;
      return durationInSeconds;
    }
    
}
//#endregion

//#regin 秒數轉換成時數
export const secondsToHours = (seconds) => {
  const hours = seconds / 3600;
  return hours.toFixed(1); // 四捨五入到小數點後一位
}
//#endregion

//#region 計算周的起始日期到結束日期的區間
export const weekCalc = (currentDate, num) => {
  let logDate = moment(currentDate);
  const sunday = logDate.clone().add(num, 'weeks').day(0);  //週日

  let saterday = logDate.clone().add(num, 'weeks').day(6);  //週六
  if(num == 0){
    saterday = moment(currentDate);
  }

  const isoWeek = moment(sunday).add(3, 'days').isoWeek();

  return [sunday, saterday, isoWeek]
}
//#endregion

//#region 計算週期
export const isoWeekCalc = (startDate) => {
  let logDate = moment(startDate);
  const isoWeek = moment(logDate).add(3, 'days').isoWeek();

  return isoWeek;
}
//#endregion

export const maskEmail = (email) => {
  const maskChar = '*';

  const parts = email.split('@');
  if (parts.length !== 2) {
      // Invalid email format, return the original string
      return email;
  }

  const username = parts[0];
  const domain = parts[1];

  // Mask the middle part of the username
  const maskedUsername = username.length > 2
      ? username[0] + username.slice(1, -1).replace(/./g, maskChar) + username.slice(-1)
      : username;

  return maskedUsername + '@' + domain;
}

/* 目前時間往後推算1天 */
export const addOneDay = (input_format = "MMM DD, YYYY hh:mm A") => {
  return moment().add(1, 'days').format(input_format);
}

/*
    轉換日期格式
    input_date=>輸入日期
    input_format=>轉換的格式(先預設為Nov 23, 2023 02:39 PM這種顯示)
*/
export const convertDateFormat = (input_date, input_format = "MMM DD, YYYY hh:mm:ss A") => {
  const parsedDate = moment(input_date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss", "HH:mm", "hh:mm A"]);
  
  if (!parsedDate.isValid()) {
      return null; // 或者返回一個默認值，或者抛出錯誤
  }

  return parsedDate.format(input_format);
}

/*
  天數差(要加上今天)
*/
export const dayDifference = (start_date, end_date) => {
  const startDate = moment(start_date);
  const endDate = moment(end_date);
  const daysDifference = endDate.diff(startDate, 'days');
  return daysDifference + 1;
}

// 设置cookie
export const setCookie = (cookieName, cookieValue, expirationDays) => {
  var expires = "";
  
  if (expirationDays) {
      var date = new Date();
      date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }

  document.cookie = cookieName + "=" + cookieValue + expires + "; path=/";
}

export const getCookie = (cookieName) => {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(';');

  for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
      }
  }

  // 如果找不到指定的cookie，返回空字符串
  return "";
}

/* 判斷是否在上一個月的第一天之前 */
export const isBeforeLastMonthFirstDay = (input_date) => {
  // 檢查 input_date 是否是有效的日期
  const isValidDate = (date) => !isNaN(date.getTime());
  
  // 如果 input_date 不是有效的日期，返回 false
  if (!isValidDate(new Date(input_date))) {
    return false;
  }

  // 獲取當前日期
  const currentDate = new Date();

  // 設定日期為上個月的第一天
  const lastMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

  // 比較是否在上一個月的第一天之前
  return new Date(input_date) < lastMonthFirstDay;
}

export const isFutureDay = (input_startDate, input_endDate) => {
  const startDate = moment(input_startDate);
  const endDate = moment(input_endDate);

  const today = moment();
  const sixMonthsFromNow = today.clone().add(6, 'months');

  const isEndDateAfterTomorrow = endDate.isSameOrAfter(today, 'day');

  if (isEndDateAfterTomorrow) {
    //有在未來區間的日子
    const datesAfterTomorrow = [];
    let currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate, 'day')) {
      const isAfterSixMonths = currentDate.isAfter(sixMonthsFromNow);
      if (currentDate.isSameOrAfter(today, 'day') && !isAfterSixMonths) {
        datesAfterTomorrow.push(currentDate.format('YYYY-MM-DD'));
      }
      currentDate.add(1, 'day');
    }
    return datesAfterTomorrow;
  } 
  return null;
}

/* 判斷是否是數值 */
export const isNumber = (value) => {
  return /^-?\d*\.?\d+$/.test(value);
}

/* 判斷正整數 */
export const isPositiveInteger = (value) => {
  return /^[1-9]\d*$/.test(value);
}

/* 判斷大於等於0整數 */
export const isNonNegativeInteger = (value) => {
  return /^\d+$/.test(value);
}

/* 判斷是否是數值與英文字母 */
export const isNumberAndAlphabet = (value) => {
  return /^[0-9a-zA-Z]+$/.test(value);
}

/* 判斷尺寸 */
export const isDimensions = (value) => {
  return /^[0-9Ø]+$/.test(value);
}

/* 判斷小數點第一位是否為5或0 */
export const isFormatHours = (value) => {
  var judge = false;
  // 将数字转换为字符串
  const valueString = value.toString();
  if(valueString.indexOf('.') !== -1){
    // 获取小数点后第一位的字符
    const firstDecimal = valueString.charAt(valueString.indexOf('.') + 1);

    // 判断是否为0或5
    if (firstDecimal === '0' || firstDecimal === '5') {
      judge = true;
    }
  }
  else{
    judge = true;
  }
  
  return judge;
}

/**
 * 判斷是不是今天之後的日子
 * @param {string} input_date - 要檢查的日期
 * @param {boolean} includeToday - 是否包括今天
 * @return {boolean} - 是否是今天之後的日子
 */
export const isTodayAfter = (input_date, includeToday = true, includeTime = false) => {
  let judge = false;

  // 解析 input_date，補齊秒數為 00
  const [datePart, timePart] = input_date.split('T');
  const formattedInputDate = timePart
    ? `${datePart}T${timePart}:59` // 若有時間，補上秒數
    : input_date; // 若無時間，直接使用
  
  // 獲取當前時間或日期
  const now = new Date();
  if (!includeTime) {
    now.setHours(0, 0, 0, 0); // 清除時間部分
  }

  // 將 input_date 轉換為 Date
  const targetDate = new Date(formattedInputDate);
  if (!includeTime) {
    targetDate.setHours(0, 0, 0, 0); // 清除時間部分
  }

  // 判斷是否是今天之后的日子
  if (includeToday) {
    judge = targetDate >= now;
  } else {
    judge = targetDate > now;
  }

  return judge;
}

// /*判斷是不是今天之後的日子*/
// export const isTodayAfter = (input_date) => {
//   var judge = false;
//   //獲取今天的日期
//   const today = new Date();

//   // 要检查的日期
//   const targetDate = new Date(input_date); 

//   // 判断是否是今天之后的日子
//   if (targetDate >= today || (targetDate.toDateString() === today.toDateString())) {
//     judge = true;
//   }

//   return judge;
// }

//#region 計算天數
export const calculateDaysBetweenDates = (startDate, endDate) => {
  // 將日期字串轉換為 Date 物件
  const start = new Date(startDate);
  const end = new Date(endDate);

  // 將時間部分忽略，僅比較日期
  const startUTC = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const endUTC = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  // 一天的毫秒數
  const oneDay = 1000 * 60 * 60 * 24;

  // 計算兩個日期之間的天數
  const daysBetween = Math.floor((endUTC - startUTC) / oneDay) + 1;
  return daysBetween;
}
//#endregion

export const processUserData = (data) => {
  const processedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
          // 檢查是否為字串類型再進行 trim
          const processedValue = typeof value === 'string' ? value.trim() : value;
          
          // 將空字串替換為 null
          const finalValue = processedValue === '' ? null : processedValue;

          return [key, finalValue];
      })
  );

  // 遞迴檢查並替換空字串為 null
  const replaceEmptyStrings = (obj) => {
      for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === 'object' && obj[key] !== null) {
                  // 遞迴處理巢狀物件
                  replaceEmptyStrings(obj[key]);
              } else if (obj[key] === '') {
                  // 將空字串替換為 null
                  obj[key] = null;
              }
          }
      }
  };

  replaceEmptyStrings(processedData);

  return processedData;
};

export const roundToTwoDecimalPlaces = (number) => {
  return (Math.round(number * 100) / 100);
}

// export const roundToTwoDecimalPlaces = (number) => {
//   // 使用 toFixed 将数字四舍五入到两位小数
//   return (Math.round(number * 100) / 100);
//   // const roundedNumber = parseFloat(number).toFixed(2);
//   // return roundedNumber;
// }

//#region 千分位
export const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
} 
//#endregion

//#region 從開始日期到結束日期
export const startToEndDate = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const dateArray = [];
  let currentDate = start.clone();
  while (currentDate.isSameOrBefore(end)) {
      dateArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
  }

  return dateArray;
}
//#endregion

//#region 首位字母轉小寫
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}
//#endregion

//#region 持續了幾秒鐘
export const calculateDuration = (startTimeStr, endTimeStr) => {
  const startTime = moment(startTimeStr);
  const endTime = moment(endTimeStr);
  const durationInSeconds = endTime.diff(startTime, 'seconds');
  return durationInSeconds;
}
//#endregion

/**
 * encrypto 加密程序
 * @param {Strng} str 待加密字符串
 * @param {Number} xor 异或值
 * @param {Number} hex 加密后的进制数
 * @return {Strng} 加密后的字符串
 */
export const encrypto = ( str, xor, hex ) => {
  if ( typeof str !== 'string' || typeof xor !== 'number' || typeof hex !== 'number') {
    return;
  }

  let resultList = []; 
  hex = hex <= 25 ? hex : hex % 25;

  for ( let i=0; i<str.length; i++ ) {
    // 提取字符串每个字符的ascll码
    let charCode = str.charCodeAt(i);
    // 进行异或加密
    charCode = (charCode * 1) ^ xor;
    // 异或加密后的字符转成 hex 位数的字符串
    charCode = charCode.toString(hex);
    resultList.push(charCode);
  }

  let splitStr = String.fromCharCode(hex + 97);
  let resultStr = resultList.join( splitStr );
  return resultStr;
}

//解密
export const decrypto = ( str, xor, hex ) => { 
  if ( typeof str !== 'string' || typeof xor !== 'number' || typeof hex !== 'number') {
    return;
  }
  let strCharList = [];
  let resultList = []; 
  hex = hex <= 25 ? hex : hex % 25;
  // 解析出分割字符
  let splitStr = String.fromCharCode(hex + 97);
  // 分割出加密字符串的加密后的每个字符
  strCharList = str.split(splitStr);

  for ( let i=0; i<strCharList.length; i++ ) {
    // 将加密后的每个字符转成加密后的ascll码
    let charCode = parseInt(strCharList[i], hex);
    // 异或解密出原字符的ascll码
    charCode = (charCode * 1) ^ xor;
    let strChar = String.fromCharCode(charCode);
    resultList.push(strChar);
  }
  let resultStr = resultList.join('');
  return resultStr;
}

//#region 取得現在年月日時分秒
export const getCurrentDateTime = () => {
  const now = new Date();
  
  const year = String(now.getFullYear()).slice(-2);
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  
  const dateTimeString = `${year}${month}${day}${hours}${minutes}${seconds}`;
  
  return dateTimeString;
}
//#endregion

//#region 檢查日期區間是否超過 7 天
export const checkIfOver7Days = (dates) => {
  if (dates.length !== 2) return false;
  const startDate = moment(dates[0], 'YYYY-MM-DD HH:mm:ss');
  const endDate = moment(dates[1], 'YYYY-MM-DD HH:mm:ss');
  const diff = endDate.diff(startDate, 'days', true); // true 表示包含時間部分的精度
  return diff > 7;
};
//#endregion

//#region 檢查日期區間是否超過5分鐘
export const isDifferenceMoreThanFiveMinutes = (date1, date2) => {
  // 將日期轉換為毫秒
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();

  // 計算兩個日期之間的毫秒差
  const differenceInMilliseconds = Math.abs(time1 - time2);

  // 計算5分鐘的毫秒數
  const fiveMinutesInMilliseconds = 5 * 60 * 1000;

  // 比較差值是否超過5分鐘
  return differenceInMilliseconds > fiveMinutesInMilliseconds;
}
//#endregion

//#region 抓取下周日
export const getNextSunday = (date = new Date()) => {
  const currentDate = new Date(date);

  // 计算当前日期是星期几 (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = currentDate.getDay();

  // 计算到下一个周日的天数
  const daysUntilNextSunday = (7 - dayOfWeek) % 7;
  
  // 如果今天已经是周日，那么 daysUntilNextSunday 会是 0，所以我们需要加 7 天来获取下周的周日
  const nextSunday = new Date(currentDate);
  nextSunday.setDate(currentDate.getDate() + (daysUntilNextSunday === 0 ? 7 : daysUntilNextSunday));

  // 格式化日期 (例如 'YYYY-MM-DD')
  const year = nextSunday.getFullYear();
  const month = String(nextSunday.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
  const day = String(nextSunday.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}
//#endregion

//#region 抓取本周日
export const getThisSunday = (date = new Date()) => {
  // 创建一个新的日期对象，以避免修改原始日期
  const currentDate = new Date(date);
  
  // 计算当前日期是星期几 (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = currentDate.getDay();
  
  // 计算本周日的日期
  const daysUntilSunday = 0 - dayOfWeek;
  const thisSunday = new Date(currentDate);
  thisSunday.setDate(currentDate.getDate() + daysUntilSunday);
  
  // 格式化日期 (例如 'YYYY-MM-DD')
  const year = thisSunday.getFullYear();
  const month = String(thisSunday.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
  const day = String(thisSunday.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}


export const formatDateTime = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const formatDateTime2 = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}



//#region 產生結構日期區間(拆成每一天)
export const generateDateTimeRangeJSON = (startDateTime, endDateTime) => {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);
  let currentStart = start;
  const result = [];

  while (currentStart < end) {
    let currentEnd = new Date(currentStart);
    // 如果是第一天，结束时间设为当天的23:59:59
    currentEnd.setHours(23, 59, 59, 999);

    // 如果当前结束时间超过了给定的结束日期时间，则使用给定的结束时间
    if (currentEnd > end) {
        currentEnd = end;
    }

    result.push({
        startTime: formatDateTime(currentStart),
        endTime: formatDateTime(currentEnd)
    });

    // 将开始时间移到下一天的00:00:00
    currentStart = new Date(currentEnd);
    currentStart.setHours(0, 0, 0, 0);
    currentStart.setDate(currentStart.getDate() + 1);
  }

  return result;
}
//#endregion

//#region 班別計算時長
export const calculateTotalShiftDuration = (shifts) => {
  let totalDuration = 0;

  shifts.forEach(shift => {
    const { startTime, endTime } = shift;
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);

    let duration;
    // Check if the end time is smaller, indicating a shift that spans across two days
    if (end < start) {
      duration = (end.getTime() - start.getTime() + 24 * 60 * 60 * 1000) / (1000 * 60 * 60); // Add one day (24 hours)
    } else {
      duration = (end.getTime() - start.getTime()) / (1000 * 60 * 60); // Calculate difference in hours
    }

    totalDuration += duration;
  });

  return totalDuration;
}
//#endregion

//#region 計算時間長度(開始時間以及結束時間)
export const calculateShiftDuration = (startTime, endTime, format = "hr") => {
  // 解析時間成小時與分鐘
  const [startHour, startMinute, startSecond = 0] = startTime.split(':').map(Number);
  const [endHour, endMinute, endSecond = 0] = endTime.split(':').map(Number);

  // 將開始和結束時間轉換為秒數
  const startInSeconds = startHour * 3600 + startMinute * 60 + startSecond;
  const endInSeconds = endHour * 3600 + endMinute * 60 + endSecond;

  let durationInSeconds;

  // 如果結束時間小於開始時間，代表是跨日
  if (endInSeconds < startInSeconds) {
    durationInSeconds = (24 * 3600 - startInSeconds) + endInSeconds; // 跨日的情況
  } else {
    durationInSeconds = endInSeconds - startInSeconds; // 正常同日的情況
  }

  switch (format) {
    case "hr":
      // 將秒數轉換為小時（以小數表示）
      return durationInSeconds / 3600;
    case "s":
      // 返回秒數
      return durationInSeconds;
    default:
      throw new Error(`Unsupported format: ${format}. Supported formats are "hr" and "s".`);
  }
}
//#endregion

//#region 將時間轉換為以秒計算的形式，方便後續處理
function timeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
//#endregion

//#region 將秒轉回時間
function secondsToTime(seconds) {
  const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
  return `${hours}:${minutes}:00`;
}
//#endregion

//#region 新增一個新的空白時間段物件
function createEmptyShift(startTime, endTime) {
  return {
    shiftName: 'No Shift',
    startTime,
    endTime,
  };
}
//#endregion

/**
 * 補齊班別陣列中的空隙，確保24小時內無空白時間
 * @param {Array} shifts - 原始班別陣列
 * @returns {Array} 補齊後的班別陣列
 */
function fillShiftsWithEmptySlots(shifts) {
  const fullDayInSeconds = 24 * 3600; // 一天的秒數
  const filledShifts = [];

  for (let i = 0; i < shifts.length; i++) {
    const currentShift = shifts[i];
    const nextShift = shifts[(i + 1) % shifts.length]; // 確保循環

    const currentEndSeconds = timeToSeconds(currentShift.endTime);
    const nextStartSeconds = timeToSeconds(nextShift.startTime);

    // 將當前班別添加到已填充的班別中
    filledShifts.push(currentShift);

    // 計算當前班別結束到下一班開始的時間差
    let gapStart = currentEndSeconds;
    let gapEnd = nextStartSeconds;

    // 處理跨日情況，若當前班別結束時間大於下一班開始時間
    if (gapStart >= gapEnd) {
      gapEnd += fullDayInSeconds; // 將結束時間推至下一天的時間線上
    }

    // 如果有空隙，插入一個「無班別」的時間段
    if (gapEnd - gapStart > 0) {
      filledShifts.push(
        createEmptyShift(secondsToTime(gapStart % fullDayInSeconds), secondsToTime(gapEnd % fullDayInSeconds))
      );
    }
  }

  return filledShifts;
}

/**
 * 判斷指定時間是否在班別的時間範圍內
 * @param {object} shift - 班別物件
 * @param {number} timeInSeconds - 指定的時間（秒數）
 * @returns {boolean} 是否在該班別範圍內
 */
function isTimeInShift(shift, timeInSeconds) {
  const start = timeToSeconds(shift.startTime);
  const end = timeToSeconds(shift.endTime);
  const fullDayInSeconds = 24 * 3600;

  if (start <= end) {
    return timeInSeconds >= start && timeInSeconds < end;
  } else {
    // 跨日班別
    return timeInSeconds >= start || timeInSeconds < end;
  }
}

/**
 * 根據指定的開始時間排序班別陣列
 * @param {Array} shifts - 已補齊的班別陣列
 * @param {string} specifiedTime - 指定的開始時間（格式 "HH:MM:SS"）
 * @returns {Array} 排序後的班別陣列
 */
function reorderShiftsBySpecifiedTime(shifts, specifiedTime) {
  const specifiedTimeSeconds = timeToSeconds(specifiedTime + ":00");
  const index = shifts.findIndex(shift => isTimeInShift(shift, specifiedTimeSeconds));

  if (index === -1) {
    // 如果找不到包含指定時間的班別，返回原陣列
    return shifts;
  }

  // 將陣列分為兩部分，從指定班別開始
  const firstPart = shifts.slice(index);
  const secondPart = shifts.slice(0, index);

  return [...firstPart, ...secondPart];
}

/**
 * 補齊班別並根據指定時間排序
 * @param {Array} shifts - 原始班別陣列
 * @param {string} specifiedTime - 指定的開始時間（格式 "HH:MM:SS"）
 * @returns {Array} 處理後的班別陣列
 */
export const processShifts = (shifts, specifiedTime) => {
  const filledShifts = fillShiftsWithEmptySlots(shifts);
  const sortedShifts = reorderShiftsBySpecifiedTime(filledShifts, specifiedTime);
  return sortedShifts;
}

/**
 * 生成從指定時間開始，每次增加指定小時數，直到滿24小時的時間陣列
 * @param {string} startTime - 開始時間，格式為 "HH:MM"
 * @param {number} stepHours - 每次增加的小時數，預設為2
 * @returns {string[]} 時間陣列
 */
export const generateTimeSlots = (startTime, stepHours = 2) => {
  // 解析開始時間的時與分
  const [startHour, startMinute] = startTime.split(':').map(Number);
  
  // 初始化時間陣列
  const slots = [];
  
  // 當前時間的時與分
  let currentHour = startHour;
  let currentMinute = startMinute;

  // 計算需要生成的時間段數量
  const totalSlots = Math.ceil(24 / stepHours);

  for (let i = 0; i < totalSlots; i++) {
    // 格式化當前時間為 "HH:MM"
    const formattedHour = String(currentHour).padStart(2, '0');
    const formattedMinute = String(currentMinute).padStart(2, '0');
    slots.push(`${formattedHour}:${formattedMinute}`);
    
    // 增加步長小時，並處理循環回到0
    currentHour = (currentHour + stepHours) % 24;
    // 分鐘保持不變
  }

  return slots;
}

export const getMisc = () => {
  let misc = localStorage.getItem("misc");
  
  if (misc === undefined || misc === null) {
    misc = {
      detailedExplanation: false,
      darkTheme: false
    };

    localStorage.setItem("misc", JSON.stringify(misc));
  } else {    
    misc = JSON.parse(misc);
  }
  return misc;
}

/**
 * 時間轉換格式
 * @param {string} dateFormat - 日期格式
 * @param {string} timeFormat - 時間格式
 * @param {string} start - 起始點
 * @param {string} end - 結束點
 * @return {json} - 起訖顯示方式
 */
export const formatDateTimeRange = (dateFormat, timeFormat, start, end = null, removeSeconds = false) => {
  // 動態處理時間格式：移除秒數 (如果需要)
  if (!timeFormat) {
      timeFormat = "";
  }
  const adjustedTimeFormat = removeSeconds
      ? timeFormat.replace(/:ss( AA)?/, "$1") // 移除 ":ss" 和多餘的空格
      : timeFormat;

  // 格式化單一日期
  const formatDate = (date, format) => {
      return moment(date).format(format);
  };

  // 移除孤立符號與斜線
  const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");

  // 如果只有起始點，直接格式化並回傳
  if (!end) {
    const formattedDate = cleanString(formatDate(start, dateFormat));
    const formattedTime = adjustedTimeFormat ? formatDate(start, adjustedTimeFormat) : "";
    return {
      startDate: formattedDate,
      startTime: formattedTime,
      endDate: "",
      endTime: "",
    };
  }

  // 格式化起始點與結束點
  const startMoment = moment(start);
  const endMoment = moment(end);

  let startDateFormat = dateFormat;
  let endDateFormat = dateFormat;

  // 判斷是否有時間格式
  const hasTimeFormat = !!adjustedTimeFormat;
  let noShowEndDate = true;

  if (!hasTimeFormat) {
    // 日期處理：無時間格式
    if (dateFormat.startsWith("D")) {
      // 起始點濾掉年份和月份
      if (startMoment.year() === endMoment.year()) {
        startDateFormat = startDateFormat.replace(/YYYY/g, "");
        if (startMoment.month() === endMoment.month()) {
            startDateFormat = startDateFormat.replace(/M{1,2}/g, "");
        }
      }
    }
    else if(dateFormat.startsWith("M")) {
      // 結束點濾掉年份和月份
      if (startMoment.year() === endMoment.year()) {
        startDateFormat = startDateFormat.replace(/YYYY/g, "");
        if (startMoment.month() === endMoment.month()) {
            endDateFormat = endDateFormat.replace(/M{1,2}/g, "");
        }
      }
    }
    else {
      // 結束點濾掉年份和月份
      if (startMoment.year() === endMoment.year()) {
        endDateFormat = endDateFormat.replace(/YYYY/g, "");
        if (startMoment.month() === endMoment.month()) {
            endDateFormat = endDateFormat.replace(/M{1,2}/g, "");
        }
      }
    }
  } else {
      // 日期處理：有時間格式
      if (
          startMoment.year() === endMoment.year() &&
          startMoment.month() === endMoment.month() &&
          startMoment.date() === endMoment.date()
      ) {
        // 同一天，只顯示結束時間
        noShowEndDate = false;
      } 
  }

  // 格式化結果
  const formattedStartDate = cleanString(formatDate(startMoment, startDateFormat));
  const formattedStartTime = hasTimeFormat ? formatDate(startMoment, adjustedTimeFormat) : "";
  let formattedEndDate = cleanString(formatDate(endMoment, endDateFormat));
  const formattedEndTime =
      hasTimeFormat && !formattedEndDate.includes(adjustedTimeFormat)
          ? formatDate(endMoment, adjustedTimeFormat)
          : "";

  return {
      startDate: formattedStartDate,
      startTime: formattedStartTime,
      endDate: noShowEndDate ? formattedEndDate : "",
      endTime: formattedEndTime,
  };
}

//#region 補時間戳
export const fillTimestamps = (timestamps, startTime, endTime) => {
  const result = [];
  let currentTime = new Date(startTime).getTime();
  const endTimeMs = new Date(endTime).getTime();
  
  // dataInterval 每次增加的比較秒數：代表資料間隔的時間在此interval內的圖表還是能將資料點連接起來，但也會造成最尾端的資料lost超過這個時間設定
  // 查詢時超過10小時，則區隔設定為30秒，超過1小時則設定為10秒，其他則設定為5秒
  let dataInterval = 7000;

  if  (((endTimeMs - currentTime) / 1000) > 3600) {
    dataInterval = 30000; 
  } else if (((endTimeMs - currentTime) / 1000) > 1800) {
    dataInterval = 15000; 
  }

  let i = 0;

  // If the array is empty or the first timestamp doesn't match the startTime, add the startTime
  if (timestamps.length === 0 || currentTime !== new Date(timestamps[0].timestamp).getTime()) {
    result.push(formatDateTime2(new Date(currentTime)));
  }

  while (currentTime <= endTimeMs) {
    if (i < timestamps.length) {
      const nextTimestamp = new Date(timestamps[i].timestamp).getTime();
      if (currentTime < nextTimestamp) {
        currentTime += dataInterval;
        if (currentTime < nextTimestamp && currentTime <= endTimeMs) {
          result.push(formatDateTime2(new Date(currentTime)));
        } else if (currentTime <= endTimeMs) {
          result.push(timestamps[i].timestamp);
          currentTime = nextTimestamp;
          i++;
        }
      } else if (currentTime === nextTimestamp) {
        if (currentTime <= endTimeMs) {
          result.push(formatDateTime2(new Date(currentTime)));
        }
        currentTime += dataInterval;
        i++;
      } else {
        if (currentTime <= endTimeMs) {
          result.push(timestamps[i].timestamp);
        }
        currentTime = nextTimestamp;
        i++;
      }
    } else {
      currentTime += dataInterval;
      if (currentTime <= endTimeMs) {
        result.push(formatDateTime2(new Date(currentTime)));
      }
    }
  }

  //如果最後一個時間戳與起始日不在同一天，則將其移除
  const lastTimestamp = new Date(result[result.length - 1]);
  const startDay = new Date(startTime).getDate();
  const lastDay = lastTimestamp.getDate();

  if (lastDay !== startDay) {
    result.pop();
  }

  if (lastTimestamp.getTime() != endTimeMs) {
    result.push(formatDateTime2(new Date(endTimeMs)));
  }

  return result;
}
//#endregion