import React, { useState, useEffect } from 'react';
import { DatePicker, Space, ConfigProvider } from 'antd';
import { I18nProvider } from '@react-aria/i18n';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './DateTimeRangePicker.css';

import 'dayjs/locale/zh-tw';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

// export default function DateTimeRangePicker({ dateValue, setDateValue, lang, hasError, setPreviousDatetime = null }) 

export default function DateTimeRangePicker({
  dateValue, 
  setDateValue, 
  lang, 
  hasError, 
  setPreviousDatetime = null, 
  className = '' // 預設 className 為空字符串
}) {

  const handleChange = (dates) => {
    if (setPreviousDatetime != null) {
      setPreviousDatetime(dateValue);
    }

    setDateValue(formatDates(dates));
  };

  // 將日期轉換為所需格式的函數
  const formatDates = (dates) => {
    return dates ? dates.map(date => date.format('YYYY-MM-DD HH:mm:ss')) : [];
  };

  return (
    <ConfigProvider locale={lang === "zh-TW" ? require('antd/lib/locale/zh_TW').default : require('antd/lib/locale/en_US').default}>
      {
        dateValue?.length === 0 ?
          <RangePicker
            showTime
            className={`${className} ${hasError ? "ant-picker-status-error" : ""}`} // 組合 className
            // className={hasError ? "ant-picker-status-error" : ""}
            onChange={handleChange} /> :
          <RangePicker
            showTime
            value={[dayjs(dateValue[0], "YYYY-MM-DD HH:mm:ss"), dayjs(dateValue[1], "YYYY-MM-DD HH:mm:ss")]}
            onChange={handleChange}
            className={`${className} ${hasError ? "ant-picker-status-error" : ""}`} // 組合 className
            // className={hasError ? "ant-picker-status-error" : ""}
             />
      }
    </ConfigProvider>
  );
}