import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import moment from 'moment';
import { weekCalc } from 'utils/commonFun';
import { Row, Col } from 'react-bootstrap';
import DateRangePicker from 'components/dateRangePicker/DateRangePicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { apiMachineListData, apiIdeaLogin } from 'utils/Api';
import { ToastContainer } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';

const Search = ({ showEquipmentRuntimeEachShiftEdit, showEquipmentRuntimeEdit, showWorkpieceProductionEdit,
  showPowerCarbonEdit, equipmentRuntimeBehavior, setEquipmentRuntimeBehavior, workpieceProductionBehavior,
  setWorkpieceProductionBehavior, selectedDates, setSelectedDates,
  choiceMachine, setChoiceMachine, handleSearch, handleFieldInstructions, handleDownload, setShowLeavePageModal,
  setShowChangePageModal, setEquipmentRuntimeUpdateData, setWorkpieceProductionUpdateData,
  equipmentRuntimeEachShiftResult, equipmentRuntimeResult, workpieceProductionResult, powerCarbonResult }) => {
  const { t } = useTranslation("common");
  const { overtime, authority, lang } = useContext(MyUserContext);

  const [machineList, setMachineList] = useState(null); //機台列表

  //#region 初始載入
  useEffect(() => {
    if (localStorage.getItem("rawData_startDate") != null && localStorage.getItem("rawData_endDate") != null) {
      setSelectedDates([localStorage.getItem("rawData_startDate"), localStorage.getItem("rawData_endDate")]);
    }
    else {
      //預載本週
      const calDate = moment().subtract(1, 'days'); //預先載入是昨天
      const [sundayThisWeek, saterdayThisWeek, isoWeekThisWeek] = weekCalc(calDate, 0);

      setSelectedDates([sundayThisWeek.format('yyyy-MM-DD'), saterdayThisWeek.format('yyyy-MM-DD')]);
    }

    //機台列表
    // loadMachineList();
  }, []);
  //#endregion

  useEffect(() => {
    console.log("selectedDates", selectedDates)
  }, [selectedDates]);

  useEffect(() => {
    if (authority?.userAccount != null) {
      const fetchData = async () => {
        const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
        if (httpStatusMachine == "200") {
          if (reponseDataMachine.statusCode === "20000") {
            let artMachine = reponseDataMachine.data;
            let newMachineList = [];
            const [httpStatus, reponseData] = await handleApiIdeaLogin();
            if (httpStatus === 200) {
              let ideaMachine = reponseData?.artUserInfo?.owingMachines;
              for (let item of ideaMachine) {
                let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                if (filter) {
                  newMachineList.push(filter);
                }
              }
            }
            else {
              newMachineList = artMachine;
            }

            setMachineList(newMachineList);

            if (localStorage.getItem("choiceMachine") !== null) {
              setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
            }
            else {
              if (newMachineList.length > 0) {
                localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                setChoiceMachine(newMachineList[0]);
              }
              else {
                localStorage.removeItem("choiceMachine");
                setChoiceMachine(null);
              }
            }
          }
          else if (reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104") {
            overtime();
          }
        }
      };
      fetchData();
    }
  }, [authority]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if (machineListResponse) {
      const httpStatus = machineListResponse.request.status;
      const reponseData = machineListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');

    let ideaLoginResponse = await apiIdeaLogin(formData);

    if (ideaLoginResponse) {
      const httpStatus = ideaLoginResponse.request.status;
      const reponseData = ideaLoginResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 日期轉換
  const handleDateRangeChange = (event, picker) => {
    setSelectedDates({
      startDate: picker.startDate.format('yyyy-MM-DD'),
      endDate: picker.endDate.format('yyyy-MM-DD'),
    });

    localStorage.setItem("rawData_startDate", picker.startDate.format('yyyy-MM-DD'));
    localStorage.setItem("rawData_endDate", picker.endDate.format('yyyy-MM-DD'));
  };
  //#endregion

  //#region 編輯儲存操作行為
  const handlerTabBehavior = () => {
    if (equipmentRuntimeBehavior === "edit" && showEquipmentRuntimeEdit) {
      setEquipmentRuntimeUpdateData([]);
      setEquipmentRuntimeBehavior("save");
    }

    if (workpieceProductionBehavior === "edit" && showWorkpieceProductionEdit) {
      setWorkpieceProductionUpdateData([]);
      setWorkpieceProductionBehavior("save");
    }

    if ((equipmentRuntimeBehavior === "save" && showEquipmentRuntimeEdit) ||
      (workpieceProductionBehavior === "save" && showWorkpieceProductionEdit)) {
      setShowChangePageModal(true);
    }
  }
  //#endregion

  //#region 取消不儲存
  const handleCancel = () => {
    setShowLeavePageModal(true);
  }
  //#endregion

  //#region 機台下拉選單
  const handleDropdownSelect = (obj) => {
    setChoiceMachine(obj);
    localStorage.setItem("choiceMachine", JSON.stringify(obj));
  };
  //#endregion

  // useEffect(() => {
  //   console.log("selectedDates", selectedDates)
  // }, [selectedDates]);

  return (
    <>
      <div className='filterRow'>
        <div>
          <div className="input-rightpadding">
            <button className="input outline fit-content" type="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" disabled={equipmentRuntimeBehavior === "save" || workpieceProductionBehavior === "save"}>
              <b>{choiceMachine?.mName}</b>
            </button>
            <div className="icon icon-Dropdown bg-greyA small"></div>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {
                machineList?.map((obj, index) => {
                  return <a className="dropdown-item cursor-pointer"
                    onClick={() => handleDropdownSelect(obj)}
                    key={index}>{obj.mName}</a>;
                })
              }
            </div>
          </div>
          <DateRangePicker dateValue={selectedDates} setDateValue={setSelectedDates} lang={lang} />
          <button type="button" className="btn btn-lv1 btn-icontext"
            disabled={equipmentRuntimeBehavior === "save" || workpieceProductionBehavior === "save"} onClick={handleSearch}>
            <div className="icon icon-Search"></div>
            <div className="text">{t("search")}</div>
          </button>
        </div>
        <div>
          <button type="button" className="btn btn-txtBlue-bgWhite btn-icontext-rwd"
            onClick={handleFieldInstructions}>
            <div className="icon icon-info"></div>
            <div className="text">{t("fieldInstructions")}</div>
          </button>

          {
            (showEquipmentRuntimeEdit && equipmentRuntimeBehavior === "edit") || (showWorkpieceProductionEdit && workpieceProductionBehavior === "edit") ?
              <>
                <button type="button" className="btn btn-lv3 btn-icontext"
                  onClick={handleDownload} disabled={(showEquipmentRuntimeEachShiftEdit && equipmentRuntimeEachShiftResult != "") ||
                    (showEquipmentRuntimeEdit && equipmentRuntimeResult != "") || (showWorkpieceProductionEdit && workpieceProductionResult != "") ||
                    (showPowerCarbonEdit && powerCarbonResult != "")}>
                  <div className="icon icon-Download"></div>
                  <div>{t("download")}</div>
                </button>
                <button type="button" className="btn btn-lv2 btn-icontext" onClick={handlerTabBehavior}
                  disabled={(showEquipmentRuntimeEachShiftEdit && equipmentRuntimeEachShiftResult != "") ||
                    (showEquipmentRuntimeEdit && equipmentRuntimeResult != "") || (showWorkpieceProductionEdit && workpieceProductionResult != "")}>
                  <div className="icon icon-Edit"></div>
                  <div>{t("edit")}</div>
                </button>
              </>
              :
              !showEquipmentRuntimeEdit || !showWorkpieceProductionEdit ?
                <button type="button" className="btn btn-lv3 btn-icontext"
                  onClick={handleDownload} disabled={(showEquipmentRuntimeEachShiftEdit && equipmentRuntimeEachShiftResult != "") ||
                    (showEquipmentRuntimeEdit && equipmentRuntimeResult != "") || (showWorkpieceProductionEdit && workpieceProductionResult != "") ||
                    (showPowerCarbonEdit && powerCarbonResult != "")}>
                  <div className="icon icon-Download"></div>
                  <div>{t("download")}</div>
                </button> : null
          }
          {
            (showEquipmentRuntimeEdit && equipmentRuntimeBehavior === "save") || (showWorkpieceProductionEdit && workpieceProductionBehavior === "save") ?
              <>
                <button type="button" className="btn btn-lv3"
                  onClick={handleCancel}>
                  {t("cancel")}
                </button>
                <button type="button" className="btn btn-lv1 btn-icontext-rwd"
                  onClick={handlerTabBehavior}>
                  <div className="icon icon-Save"></div>
                  <div className="text">{t("save")}</div>
                </button>
              </>
              : null
          }
        </div>

      </div>

      {/* <div className='d-xs-block d-md-none mx-1'>
        <Row>
          <Col xs={12} className='justify-content-start align-items-center'>
            <div className="input-rightpadding h38">
              <button className="input outline fit-content" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" disabled={equipmentRuntimeBehavior === "save" || workpieceProductionBehavior === "save"}>
                <b>{choiceMachine?.mName}</b>
              </button>
              <div className="icon icon-Dropdown bg-greyA small"></div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  machineList?.map((obj, index) => {
                    return <a className="dropdown-item cursor-pointer"
                      onClick={() => handleDropdownSelect(obj)}
                      key={index}>{obj.mName}</a>;
                  })
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col xs={12} className='justify-content-start align-items-center'>
            <DateRangePicker onApply={handleDateRangeChange}>
              <input
                type="text"
                value={
                  selectedDates.startDate && selectedDates.endDate
                    ? `${selectedDates.startDate} to ${selectedDates.endDate}`
                    : ''
                }
                className='custom-input-dateRangePicker'
                readOnly
                disabled={equipmentRuntimeBehavior === "save"}
              />
            </DateRangePicker>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col xs={12} className='d-flex justify-content-end align-items-center'>
            <button type="button" className="btn btn-lv1 btn-icontext ms-2 h38"
              disabled={equipmentRuntimeBehavior === "save" || workpieceProductionBehavior === "save"} onClick={handleSearch}
            >
              <div className="icon icon-Search"></div>
              <div className="text">{t("search")}</div>
            </button>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col xs={(showEquipmentRuntimeEdit && equipmentRuntimeBehavior === "edit") || (showWorkpieceProductionEdit && workpieceProductionBehavior === "edit") ? 7 : 12} className='d-flex justify-content-center align-items-center px-0'>
            <button type="button" className="btn btn-txtBlue-bgWhite btn-icontext-rwd ms-2 h38"
              onClick={handleFieldInstructions}
            >
              <div className="icon icon-info"></div>
              <div className="text">{t("fieldInstructions")}</div>
            </button>
          </Col>
          {
            (showEquipmentRuntimeEdit && equipmentRuntimeBehavior === "edit") || (showWorkpieceProductionEdit && workpieceProductionBehavior === "edit") ?
              <Col xs={4} className='d-flex justify-content-center align-items-center px-0'>
                <button type="button"
                  className="btn btn-lv3 btn-icontext ms-2 h38"
                  onClick={handleDownload} disabled={(showEquipmentRuntimeEachShiftEdit && equipmentRuntimeEachShiftResult != "") ||
                    (showEquipmentRuntimeEdit && equipmentRuntimeResult != "") || (showWorkpieceProductionEdit && workpieceProductionResult != "")}
                >
                  <div className="icon icon-Download"></div>
                  <div>{t("download")}</div>
                </button>
              </Col> : null
          }
        </Row>
        {
          (showEquipmentRuntimeEdit && equipmentRuntimeBehavior === "edit") || (showWorkpieceProductionEdit && workpieceProductionBehavior === "edit") ?
            <Row className='mt-2'>
              <Col xs={12} className='d-flex justify-content-center align-items-center px-0'>
                <button type="button" className="btn btn-lv2 btn-icontext ms-2 h38" onClick={handlerTabBehavior}
                  disabled={(showEquipmentRuntimeEachShiftEdit && equipmentRuntimeEachShiftResult != "") ||
                    (showEquipmentRuntimeEdit && equipmentRuntimeResult != "") || (showWorkpieceProductionEdit && workpieceProductionResult != "")}>
                  <div className="icon icon-Edit"></div>
                  <div>{t("edit")}</div>
                </button>
              </Col>
            </Row> : null
        }
        {
          (showEquipmentRuntimeEdit && equipmentRuntimeBehavior === "edit") || (showWorkpieceProductionEdit && workpieceProductionBehavior === "edit") ?
            <Row className='mt-2'>
              <Col xs={6} className='d-flex justify-content-center align-items-center px-0'>
                <button type="button" className="btn btn-lv3 ms-2 h38"
                  onClick={handleCancel}>
                  {t("cancel")}
                </button>
              </Col>
              <Col xs={6} className='d-flex justify-content-center align-items-center px-0'>
                <button type="button" className="btn btn-lv1 btn-icontext-rwd ms-2 h38" onClick={handlerTabBehavior}>
                  <div className="icon icon-Save"></div>
                  <div className="text">{t("save")}</div>
                </button>
              </Col>
            </Row> : null
        }
      </div> */}

      <ToastContainer />
    </>
  );
};

export default Search;