import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { decimalWithOneDecimal, convertDateFormat, formatTimeDays } from 'utils/commonFun';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import MessageModal from 'components/basic/MessageModal';

import { apiOrderList, apiChangeOrderOperate, apiOrderStatus } from 'utils/Api';

import MyPagination from 'components/basic/Pagination';

import WorkOrderModal from "page/apps/data/aps/modal/WorkOrderModal"; //工單
import WorkOrderDetail from "page/apps/data/aps/modal/WorkOrderDetail"; //瀏覽工單內容
import PauseWorkOrder from "page/apps/data/aps/modal/PauseWorkOrder"; //暫停工單
import AbortWorkOrder from "page/apps/data/aps/modal/AbortWorkOrder"; //終止工單
import ContinueWorkOrder from "page/apps/data/aps/modal/ContinueWorkOrder"; //繼續工單

import '../APS-statistic.css';
import '../APS-pageWO.css';
import '../APS-dropdown.css';
import { use } from 'react';
import { set } from 'date-fns';

const WorkOrder = ({ currentTab, loadOrderCountAndStatus, isArchived }) => {
  const { t } = useTranslation("aps");
  const { overtime, show404Page } = useContext(MyUserContext);

  const isFirstRender = useRef(true);
  const [orderData, setOrderData] = useState([]);  //工單資料
  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字
  const [keywordSearch, setKeywordSearch] = useState(""); // for autoRefresh state variable

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const [orderStatus, setOrderStatus] = useState([]); //上方使用者選擇狀態
  const [descending, setDescending] = useState(false); //按工件正排 / 倒排 (預設為正排)
  const [orderField, setOrderField] = useState("equipment"); //排序欄位(orderNo, equipment, datetimeStart, scheduledDeliveryDate)
  const [currentPage, setCurrentPage] = useState(1);

  const scrollRef = useRef(null);
  const interval = useRef(null);

  const [behavior, setBehavior] = useState("insert"); //打開Modal是insert:新增 update:編輯
  const [orderID, setOrderID] = useState(0);  //選擇工單流水號

  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false); //是否工單Modal
  const handleWorkOrderClose = () => { setShowWorkOrderModal(false); }

  const [showWorkOrderDetailModal, setShowWorkOrderDetailModal] = useState(false); //是否工單明細Modal
  const handleWorkOrderDetailClose = () => { setShowWorkOrderDetailModal(false); }

  const [showDelWorkOrderModal, setShowDelWorkOrderModal] = useState(false); //是否顯示刪除工單？Modal
  const handleDelWorkOrderClose = () => setShowDelWorkOrderModal(false);

  const [showArchiveWorkOrderModal, setShowArchiveWorkOrderModal] = useState(false); //是否顯示封存工單？Modal
  const handleArchiveWorkOrderClose = () => setShowArchiveWorkOrderModal(false);

  const [pauseModal, setPauseModal] = useState(false); //是否顯示暫停工單Modal
  const handlePauseClose = () => setPauseModal(false);

  const [abortModal, setAbortModal] = useState(false); //是否顯示終止工單Modal
  const handleAbortClose = () => setAbortModal(false);

  const [continueModal, setContinueModal] = useState(false); //是否顯示繼續生產工單Modal
  const handleContinueClose = () => setContinueModal(false);

  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false); //是否顯示解除封存工單？Modal
  const handleUnarchiveClose = () => setShowUnarchiveModal(false);

  const [showCannotDelModal, setShowCannotDelModal] = useState(false); //是否顯示不可刪除工單Modal
  const handleCannotDelClose = () => setShowCannotDelModal(false);

  const [showNotRecommendedModal, setShowNotRecommendedModal] = useState(false); //是否顯示不建議的工單狀態Modal
  const handleNotRecommendedClose = () => setShowNotRecommendedModal(false);

  const [showSetCompletedModal, setShowSetCompletedModal] = useState(false); //是否顯示設定為「完成」狀態?Modal
  const handleSetCompletedClose = () => setShowSetCompletedModal(false);

  let autoRefreshInterval = window.autoRefreshInterval ? window.autoRefreshInterval * 1000 : 10000;
  let inetrvalActive = false;

  useEffect(() => {
    if ((currentTab === "workOrders" || currentTab === "archivedOrders")) {
      loadOrderList();
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 如果首次渲染，跳過這次執行
      isFirstRender.current = false;
      return;
    }

    if (currentTab === "workOrders" || currentTab === "archivedOrders") {
      loadOrderList(); // 載入工單列表      
    }

    if (currentTab === "workOrders") {
      setAutoRefresh(true);
    }
    return () => { clearInterval(interval.current); }
  }, [currentPage, orderStatus, descending, orderField, keywordSearch]);

  useEffect(() => {
    if (orderData?.totalItems > 0 && orderData?.detail?.length === 0) {
      setCurrentPage(1);
    }
  }, [orderData]);

  useEffect(() => {
    if (checkModalShow()) {
      setAutoRefresh(false);
    }
    else {
      setAutoRefresh(true);
    }
    return () => { clearInterval(interval.current); }
  }, [showWorkOrderModal, showDelWorkOrderModal, showArchiveWorkOrderModal, pauseModal, abortModal, continueModal, showUnarchiveModal, showCannotDelModal, showNotRecommendedModal, showSetCompletedModal]);

  useEffect(() => {
    if (checkModalShow()) {
      setAutoRefresh(false);
    }
  }, [showWorkOrderDetailModal]);

  const checkModalShow = () => {
    return (showWorkOrderModal || showWorkOrderDetailModal || showDelWorkOrderModal || showArchiveWorkOrderModal || pauseModal || abortModal || continueModal || showUnarchiveModal || showCannotDelModal || showNotRecommendedModal || showSetCompletedModal);
  }

  const setAutoRefresh = (setRefresh) => {
    if (currentTab === "workOrders") {
      inetrvalActive = setRefresh;
      clearInterval(interval.current);

      if (setRefresh) {
        interval.current = setInterval(() => {
          loadOrderList(true);
        }, autoRefreshInterval);
      }
      if (process.env.NODE_ENV === 'development') {
        console.log("WorkOrder inetrvalActive", inetrvalActive, "intervalID", interval.current);
      }
    }
  }

  //#region 載入工單列表
  const loadOrderList = async (setRefresh) => {
    const [httpStatus, reponseData] = await handleApiOrderList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setOrderData(reponseData.data);

        if (setRefresh === undefined) { setRefresh = true; }
        // setAutoRefresh(true) here for all modal requirements;
        if (!inetrvalActive && setRefresh) { setAutoRefresh(true); }
      }
      else if (reponseData.statusCode === "40300") {
        show404Page();
      }
      else if (reponseData.statusCode === "40103") {
        overtime();
      }
    }
  }
  //#endregion

  //#region 載入工單列表API
  const handleApiOrderList = async () => {
    let postJson = {
      keyword: keyword,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30,
      descending: descending,
      orderField: orderField
    };

    if (localStorage.getItem("filterOrderStatus") !== null) {
      localStorage.removeItem("filterOrderStatus");
      setOrderStatus([3]);
      postJson.orderStatus = [3];
    }
    else {
      if (orderStatus.length > 0) {
        postJson.orderStatus = orderStatus;
      }
    }

    if (isArchived) {
      postJson.isListArchive = true;
    }

    let response = await apiOrderList(postJson, true);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
  };
  //#endregion

  //#region 查詢按鈕
  const handleSearch = () => {
    setKeywordSearch(keyword);
  }
  //#endregion

  //#region 新增按鈕
  const handleCreate = () => {
    setBehavior("insert");
    setShowWorkOrderModal(true);
  }
  //#endregion

  //#region 編輯按鈕
  const handleEdit = (ID) => {
    setBehavior("update");
    setOrderID(ID);
    setShowWorkOrderModal(true);
  }
  //#endregion

  //#region 瀏覽內容按鈕
  const handleDetail = (ID) => {
    setOrderID(ID);
    setShowWorkOrderDetailModal(true);
  }
  //#endregion

  //#region 刪除按鈕 judgeDel=>代表是否可刪除
  const handleDelete = (ID, judgeDel) => {
    setOrderID(ID);

    if (judgeDel) {
      setShowDelWorkOrderModal(true);
    }
    else {
      setShowCannotDelModal(true);
    }
  }
  //#endregion

  //#region 刪除Modal確認按鈕
  const handleDelWorkOrder = async () => {
    const [httpStatus, reponseData] = await handleApiChangeOrderStatus("delete");
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<span>{t("workOrderDeleted")}</span>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        loadOrderList();
        loadOrderCountAndStatus();
        setShowDelWorkOrderModal(false);
      }
    }
  }
  //#endregion

  //#region 封存按鈕
  const handleArchive = (ID) => {
    setOrderID(ID);
    setShowArchiveWorkOrderModal(true);
  }
  //#endregion

  //#region 封存Modal確認按鈕
  const handleArchiveWorkOrder = async () => {
    const [httpStatus, reponseData] = await handleApiChangeOrderStatus("archive");
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<span>{t("workOrderArchived")}</span>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        loadOrderList();
        setShowArchiveWorkOrderModal(false);
        loadOrderCountAndStatus();
      }
    }
  }
  //#endregion

  //#region 工單狀態更新API
  const handleApiChangeOrderStatus = async (event, isArchived = true) => {
    let postJson = {
      orderID: orderID
    };

    if (event === "delete") {
      postJson.isDeleted = true;
    }

    if (event === "archive") {
      postJson.isArchived = isArchived;
    }

    let response = await apiChangeOrderOperate(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 移到空白地方要關掉下拉選單的工單狀態
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //#endregion

  //#region 下拉選單狀態
  const handleOptionClick = (e) => {
    const { value, checked } = e.target;

    setOrderStatus(prevStatus => {
      if (checked) {
        return [...prevStatus, parseInt(value)];
      } else {
        return prevStatus.filter(status => status !== parseInt(value));
      }
    });
  }
  //#endregion

  //#region 下方狀態列表的打叉按鈕
  const removeStatus = (value) => {
    setOrderStatus(prevStatus => {
      return prevStatus.filter(status => status !== value);
    });
  }
  //#endregion

  //#region 暫停工單
  const handlePause = (ID) => {
    setPauseModal(true);
    setOrderID(ID);
  }
  //#endregion

  //#region 終止工單
  const handleAbort = (ID) => {
    setAbortModal(true);
    setOrderID(ID);
  }
  //#endregion

  //#region 繼續工單
  const handleContinue = (ID) => {
    setContinueModal(true);
    setOrderID(ID);
  }
  //#endregion

  //#region 解除封存
  const handleUnArchive = (ID) => {
    setOrderID(ID);
    setShowUnarchiveModal(true);
  }
  //#endregion

  //#region 解除封存-確認按鈕
  const handleUnarchiveConfirm = async () => {
    const [httpStatus, reponseData] = await handleApiChangeOrderStatus("archive", false);
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<span>{t("unarchiveCompleted")}</span>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        loadOrderList();
        loadOrderCountAndStatus();
        setShowUnarchiveModal(false);
      }
    }
  }
  //#endregion

  //#region 完成按鈕
  const handleComplete = (ID, isComplete) => {
    setOrderID(ID);

    // 暫時不考慮 obj.actualQuantity >= obj.plannedQuantity 的判斷
    setShowSetCompletedModal(true);
  }
  //#endregion

  //#region 不建議工單-繼續按鈕 設定為完成狀態-確定按鈕
  const handleSetCompletedConfirm = async (behavior) => {
    if (behavior === "notRecommended") {
      setShowNotRecommendedModal(false);
      setShowSetCompletedModal(true);
    }
    else {
      const [httpStatus, reponseData] = await handleApiOrderStatus();
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20100") {
          loadOrderList();
          setShowSetCompletedModal(false);
          loadOrderCountAndStatus();

          toast(t("appliedSuccessfully"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
  }
  //#endregion

  //#region 工單狀態更新API
  const handleApiOrderStatus = async () => {
    let postJson = {
      orderID: orderID,
      orderStatus: 5
    };

    let response = await apiOrderStatus(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 排序欄位
  //2025-03-11 新版JoJo問ChatGPT改成將排序按鈕直接做在表頭文字，箭頭變成只是輔助視覺效果
  const orderByCol = (field) => {
    if (orderField === field) {
      setDescending(!descending); // 如果點擊同一欄位，則切換升冪/降冪
    } else {
      setOrderField(field);
      setDescending(false); // 點擊新欄位時，預設升冪排序
    }
  };
  //#endregion

  return (
    <div className="table-wrapper rwd firstColCheckbox text-keep-normal">
      <div className="filterRow">
        <div>
          <div className="btn-search">
            <input type="search" id="search" name="query" placeholder={t("search")} aria-label="Search"
              value={keyword} onChange={handleInputKeywordChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") { handleSearch(); }
              }}
            />
            <button type="submit" className="btn btn-onlyicon bounce btn-lv5"
              onClick={handleSearch}>
              <div className="icon icon-Search"></div>
            </button>
          </div>
          <div className="multi-select-dropdown" ref={dropdownRef}>
            <div className="btn-dropdown" onClick={toggleDropdown}>
              <div
                className="search-input input-dropdown"
                id="searchInput"
              >
                {t("status")}&nbsp;({orderStatus.length})
              </div>
              <i className="btn-item btn-onlyicon btn-inputdrop">
                <div className="icon icon-Dropdown"></div>
              </i>
              <i className="clear-btn">✕</i>
            </div>
            {isDropdownOpen && (
              <div className="dropdown-options d-block" id="dropdown-options">
                <label className="dropdown-option">
                  <input type="checkbox" value="0" onClick={handleOptionClick}
                    checked={orderStatus.includes(0)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("unscheduled")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="1" onClick={handleOptionClick}
                    checked={orderStatus.includes(1)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("scheduled")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="2" onClick={handleOptionClick}
                    checked={orderStatus.includes(2)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("started")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="3" onClick={handleOptionClick}
                    checked={orderStatus.includes(3)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("paused")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="4" onClick={handleOptionClick}
                    checked={orderStatus.includes(4)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("aborted")}</span>
                </label>
                <label className="dropdown-option">
                  <input type="checkbox" value="5" onClick={handleOptionClick}
                    checked={orderStatus.includes(5)} />
                  <span className="icon icon-Check"></span>
                  <span className="text">{t("completed")}</span>
                </label>
              </div>
            )}
          </div>
        </div>
        <div>
          <button className="btn btn-lv1 btn-icontext-rwd" onClick={handleCreate}>
            <div className="icon icon-Create"></div>
            <span>{t("create")}</span>
          </button>
        </div>
      </div>
      <div>
        <div id="selectedOptionsContainer">
          {(() => {
            if (orderStatus.length > 0) {
              let result = [];
              for (let item of orderStatus) {
                let status = "";
                if (item === 0) {
                  status = t("unscheduled");
                }
                else if (item === 1) {
                  status = t("scheduled");
                }
                else if (item === 2) {
                  status = t("started");
                }
                else if (item === 3) {
                  status = t("paused");
                }
                else if (item === 4) {
                  status = t("aborted");
                }
                else if (item === 5) {
                  status = t("completed");
                }
                result.push(<div className="selected-option">{status}<span className="delete-option" onClick={() => removeStatus(item)}>✕</span></div>);
              }
              return result;
            }
          })()}
        </div>
      </div>

      {
        orderData?.detail?.length > 0 ?
          <>
            <table className="APSTable scroll" id="WorkOrder" ref={scrollRef}>
              <thead className="showTHead">
                <tr className="tLeft">
                  <th className="c1 noShowWhenRWD"></th>
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "orderNo" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("orderNo")}
                  >
                    {t("workOrderID")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "orderNo" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "orderNo" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  <th className="noShowWhenRWD">{t("status")}</th>
                  <th className="noShowWhenRWD">{t("part")}/{t("process")}</th>
                  <th className="minWidth160 noShowWhenRWD">{t("progress")}</th>
                  <th className="tCenter noShowWhenRWD">{t("scheduledProductionQuantity")}</th>
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "datetimeStart" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("datetimeStart")}
                  >
                    {t("scheduledTime")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "datetimeStart" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "datetimeStart" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "equipment" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("equipment")}
                  >
                    {t("equipment")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "equipment" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "equipment" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  {/* <div className="td c9">{t("material")}</div> */}
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "scheduledDeliveryDate" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("scheduledDeliveryDate")}
                  >
                    {t("scheduledDeliveryDate")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "scheduledDeliveryDate" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "scheduledDeliveryDate" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  {/* <th className="th d-flex justify-content-center">{t("plannedDeliveryQuantity")}</th> */}
                  <th className="tRight noShowWhenRWD">{t("estDuration")}</th>
                  {/* <div className="td c13">{t("actualProductionTime")}</div> */}
                </tr>
              </thead>
              <tbody>
                {
                  orderData?.detail?.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td className="c1 d-flex justify-content-center">
                          {
                            obj.orderStatus === 3 ?
                              <div className="icon icon-Radius status bg paused">
                                <div className="icon icon-xs icon-Paused"></div>
                              </div> : null
                          }
                          {
                            obj.orderStatus === 5 ?
                              <div className="icon icon-Radius status bg completed">
                                <div className="icon icon-xs icon-Check"></div>
                              </div> : null
                          }
                          {
                            obj.deliveryDelay ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    {t("deliveryDelay")}
                                  </Tooltip>
                                }
                              >
                                <div className="icon icon-Radius status bg deliveryDelay">
                                  <div className="icon icon-xs icon-DeliveryDelay"></div>
                                </div>
                              </OverlayTrigger>
                              : null
                          }
                        </td>
                        <td data-label={t("workOrderID")} className="text break-all">{obj.orderNo}-{obj.processNo}</td>
                        <td data-label={t("status")}>
                          {(() => {
                            let orderStatus = obj.orderStatus;

                            if (orderStatus === 0) {
                              return <div className="status status-padding bg unscheduled">
                                {t("unscheduled")}
                              </div>
                            }
                            else if (orderStatus === 1) {
                              return <div className="status status-padding bg scheduled">
                                {t("scheduled")}
                              </div>
                            }
                            else if (orderStatus === 2) {
                              return <div className="status status-padding bg inproduction">
                                {t("started")}
                              </div>
                            }
                            else if (orderStatus === 3) {
                              return <div className="status status-padding bg paused">
                                {t("paused")}
                              </div>
                            }
                            else if (orderStatus === 4) {
                              return <div className="status status-padding bg aborted">
                                {t("aborted")}
                              </div>
                            }
                            else if (orderStatus === 5) {
                              return <div className="status status-padding bg completed">
                                {t("completed")}
                              </div>
                            }
                          })()}
                        </td>
                        <td data-label={`${t("part")} / ${t("process")}`}>
                          <div className="text break-all">
                            <span className="strong2">{obj.wpNO}</span>
                            <span>{obj.wpName}</span>
                            <span> / </span>
                            <span className="strong2">{obj.processNo}</span>
                            <span>{obj.processName}</span>
                          </div>
                        </td>
                        <td data-label={t("progress")}>
                          <div className="progress-aps">
                            <div className={`chart ${(obj.orderStatus === 2 || obj.orderStatus === 5) ? "chart-black" : "chart-gray"}`}>
                              <div style={{ width: `${decimalWithOneDecimal(obj.actualQuantity, obj.scheduledQuantity, 0)}%` }}></div>
                            </div>
                            <div className="text">
                              <span className={`value ${(obj.orderStatus === 2 || obj.orderStatus === 5) ? "value-black" : "value-gray"}`}>
                                {decimalWithOneDecimal(obj.actualQuantity, obj.scheduledQuantity, 0)}
                                <span className={`unit ${(obj.orderStatus === 2 || obj.orderStatus === 5) ? "unit-black" : "unit-gray"}`}>%</span>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td data-label={t("scheduledProductionQuantity")} className="tCenter">
                          <span className={`${obj.actualQuantity === 0 ? "text-greyC" : ""}`}> {obj.actualQuantity} / </span>
                          <span>{obj.scheduledQuantity}</span>
                        </td>
                        <td data-label={t("scheduledTime")}>
                          {
                            obj.datetimeStart != null ?
                              <div>
                                <span className="strong2">{convertDateFormat(obj.datetimeStart, "MMM DD")}</span>
                                <span>{convertDateFormat(obj.datetimeStart, "hh:mm:ss A")}</span>
                                <span> - </span>
                                {
                                  convertDateFormat(obj.datetimeStart, "MMM DD") != convertDateFormat(obj.datetimeEnd, "MMM DD") ?
                                    <span className="strong2">{convertDateFormat(obj.datetimeEnd, "MMM DD")}</span> :
                                    null
                                }
                                <span>{convertDateFormat(obj.datetimeEnd, "hh:mm:ss A")}</span>
                              </div> : "-"
                          }
                        </td>
                        <td data-label={t("equipment")}>{obj.equipment}</td>
                        {/* <td className="td c9">{obj.material}</td> */}
                        <td data-label={t("scheduledDeliveryDate")}>
                          <div>
                            <span className="strong2">{convertDateFormat(obj.scheduledDeliveryDate, "MMM DD")}
                              {convertDateFormat(obj.scheduledDeliveryDate, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.scheduledDeliveryDate, "YYYY")}</> : null}</span>
                          </div>
                        </td>
                        {/* <td data-label={t("plannedDeliveryQuantity")} d-flex justify-content-center">{obj.plannedQuantity}</td> */}
                        <td data-label={t("estDuration")} className="tRight">
                          {(() => {
                            let result = [];
                            let resultDuration = [];
                            const minEstimatedDuration = obj.minEstimatedDuration;
                            const maxEstimatedDuration = obj.maxEstimatedDuration;

                            if (minEstimatedDuration != null) {
                              const transMinEstimatedDuration = formatTimeDays(minEstimatedDuration, t);
                              const transMaxEstimatedDuration = formatTimeDays(maxEstimatedDuration, t);

                              const [dayMin, timeMin] = transMinEstimatedDuration.split(' ');
                              if (timeMin != undefined) {
                                resultDuration.push(<span className="strong2">{dayMin}</span>);
                                resultDuration.push(<span>{timeMin}</span>);
                              }
                              else {
                                resultDuration.push(<span>{dayMin}</span>);
                              }

                              if (maxEstimatedDuration != null) {
                                resultDuration.push(" ~ ");

                                const [dayMax, timeMax] = transMaxEstimatedDuration.split(' ');
                                if (timeMax != undefined) {
                                  resultDuration.push(<span className="strong2">{dayMax}</span>);
                                  resultDuration.push(<span>{timeMax}</span>);
                                }
                                else {
                                  resultDuration.push(<span>{dayMax}</span>);
                                }
                              }

                              result.push(<div>{resultDuration}</div>);
                            }

                            return result;
                          })()}
                        </td>
                        {/* <td className="td c13"> 
                        {(() => {
                          let actualProductionStartTime = obj.actualProductionStartTime;
                          let actualProductionEndTime = obj.actualProductionEndTime;

                          if(actualProductionStartTime != null){
                            
                            return <div>
                              <span className="strong2">{convertDateFormat(actualProductionStartTime, "MMM DD")}</span>
                              <span>{convertDateFormat(actualProductionStartTime, "hh:mm:ss A")}</span>
                              <span> ~ </span>
                              {
                                actualProductionEndTime != null ?
                                <>
                                  <span className="strong2">{convertDateFormat(actualProductionEndTime, "MMM DD")}</span>
                                  <span>{convertDateFormat(actualProductionEndTime, "hh:mm:ss A")}</span>
                                </> : null
                              }
                            </div>
                          }                    
                        })()}  
                        
                      </td> */}
                        <td className="td btns tableFuncBtn">
                          {
                            !isArchived && (obj.orderStatus === 0 || obj.orderStatus === 1) ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("edit")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="btn-item" onClick={() => handleEdit(obj.orderID)}>
                                  <div className="icon-Edit"></div>
                                </div>
                              </OverlayTrigger>
                              : null
                          }
                          {
                            !isArchived && obj.orderStatus === 5 ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("continue")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="btn-item" onClick={() => handleContinue(obj.orderID)}>
                                  <div className="icon-Continue"></div>
                                </div>
                              </OverlayTrigger> : null
                          }
                          {
                            !isArchived && (obj.orderStatus === 4 || obj.orderStatus === 5) ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("archive")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="btn-item" onClick={() => handleArchive(obj.orderID)}>
                                  <div className="icon-Archive"></div>
                                </div>
                              </OverlayTrigger>
                              : null
                          }
                          {
                            !isArchived ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("details")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="btn-item" onClick={() => handleDetail(obj.orderID)}>
                                  <div className="icon-Details"></div>
                                </div>
                              </OverlayTrigger> : null
                          }
                          {
                            !isArchived && (obj.orderStatus === 0 || obj.orderStatus === 1) ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("delete")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className={`btn-item red ${obj.actualQuantity > 0 ? "disabled" : ""}`}
                                  onClick={() => handleDelete(obj.orderID, obj.actualQuantity === 0)}>
                                  <div className="icon-Delete"></div>
                                </div>
                              </OverlayTrigger>
                              : null
                          }
                          {
                            !isArchived && (obj.orderStatus === 2) ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("pause")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="btn-item" onClick={() => handlePause(obj.orderID)}>
                                  <div className="icon-Pause"></div>
                                </div>
                              </OverlayTrigger>
                              : null
                          }
                          {
                            !isArchived && obj.orderStatus === 3 ?
                              <>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <span>{t("continue")}</span>
                                    </Tooltip>
                                  }
                                >
                                  <div className="btn-item" onClick={() => handleContinue(obj.orderID)}>
                                    <div className="icon-Continue"></div>
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <span>{t("complete")}</span>
                                    </Tooltip>
                                  }
                                >
                                  <div className="btn-item" onClick={() => handleComplete(obj.orderID, obj.actualQuantity >= obj.plannedQuantity)}>
                                    <div className="icon-Complete"></div>
                                  </div>
                                </OverlayTrigger>
                              </>
                              : null
                          }
                          {
                            !isArchived && (obj.orderStatus === 2 || obj.orderStatus === 3) ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("abort")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="btn-item red" onClick={() => handleAbort(obj.orderID)}>
                                  <div className="icon-Abort"></div>
                                </div>
                              </OverlayTrigger> : null
                          }
                          {
                            isArchived ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("unarchive")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="btn-item" onClick={() => handleUnArchive(obj.orderID)}>
                                  <div className="icon-Unarchive"></div>
                                </div>
                              </OverlayTrigger> : null
                          }
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>

            <div className="d-flex justify-content-center mt-3">
              <MyPagination totalPages={Math.ceil(orderData?.totalItems / 30)}
                currentPage={currentPage}
                onPageChange={handlePageChange} />
            </div>
          </> :
          <div className="table-nodata">
            <div className="nodata-text">{t("noDataFound")}</div>
          </div>
      }

      <ToastContainer />

      <WorkOrderModal
        showModal={showWorkOrderModal}
        handleClose={handleWorkOrderClose}
        behavior={behavior}
        loadOrderList={loadOrderList}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
        orderID={orderID}
      />

      {/* 工單內容 */}
      <WorkOrderDetail
        showModal={showWorkOrderDetailModal}
        handleClose={handleWorkOrderDetailClose}
        loadOrderList={loadOrderList}
        orderID={orderID}
      />

      {/* 刪除工單? */}
      <MessageModal
        title={t("deleteWorkOrder")}
        content={`${t("deleteWorkOrderContent1")}<br /><br />${t("deleteWorkOrderContent2")}`}
        showModal={showDelWorkOrderModal}
        handleConfirmData={handleDelWorkOrder}
        size="xs"
        confirmModalTxt={t("confirm")}
        cancelTxt={t("cancel")}
        cancelModal={handleDelWorkOrderClose}
        handleClose={handleDelWorkOrderClose}
      />

      {/* 封存工單? */}
      <MessageModal
        title={t("archiveWorkOrder")}
        content={`${t("archiveWorkOrderContent1")}<br /><br />${t("archiveWorkOrderContent2")}`}
        showModal={showArchiveWorkOrderModal}
        handleConfirmData={handleArchiveWorkOrder}
        size="xs"
        confirmModalTxt={t("confirm")}
        cancelTxt={t("cancel")}
        cancelModal={handleArchiveWorkOrderClose}
        handleClose={handleArchiveWorkOrderClose}
      />

      {/* 暫停工單 */}
      <PauseWorkOrder
        showModal={pauseModal}
        handleClose={handlePauseClose}
        orderID={orderID}
        loadOrderList={loadOrderList}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
      />

      {/* 終止工單 */}
      <AbortWorkOrder
        showModal={abortModal}
        handleClose={handleAbortClose}
        orderID={orderID}
        loadOrderList={loadOrderList}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
      />

      {/* 繼續生產 */}
      <ContinueWorkOrder
        showModal={continueModal}
        handleClose={handleContinueClose}
        orderID={orderID}
        loadOrderList={loadOrderList}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
      />

      {/* 解除封存 */}
      <MessageModal
        title={t("unarchiveWorkOrder")}
        content={`${t("unarchiveWorkOrderContent1")}`}
        showModal={showUnarchiveModal}
        handleConfirmData={handleUnarchiveConfirm}
        size="xs"
        confirmModalTxt={t("confirm")}
        cancelTxt={t("cancel")}
        cancelModal={handleUnarchiveClose}
        handleClose={handleUnarchiveClose}
      />

      {/* 不可刪除工單 */}
      <MessageModal
        title={t("deleteCannot")}
        content={`${t("deleteCannotContent")}`}
        showModal={showCannotDelModal}
        handleConfirmData={handleCannotDelClose}
        size="xs"
        confirmModalTxt={t("confirm")}
        handleClose={handleCannotDelClose}
      />

      {/* 不建議的工單狀態 */}
      <MessageModal
        title={t("notRecommended")}
        content={`${t("notRecommendedContent1")}<br /><br />${t("notRecommendedContent2")}`}
        showModal={showNotRecommendedModal}
        handleConfirmData={() => handleSetCompletedConfirm("notRecommended")}
        size="xs"
        confirmModalTxt={t("continue")}
        cancelTxt={t("cancel")}
        cancelModal={handleNotRecommendedClose}
        handleClose={handleNotRecommendedClose}
      />

      {/* 設定為「完成」狀態? */}
      <MessageModal
        title={t("setCompleted")}
        content={`${t("setCompletedContent1")}<br /><br />${t("setCompletedContent2")}`}
        showModal={showSetCompletedModal}
        handleConfirmData={() => handleSetCompletedConfirm("setCompleted")}
        size="xs"
        confirmModalTxt={t("continue")}
        cancelTxt={t("cancel")}
        cancelModal={handleSetCompletedClose}
        handleClose={handleSetCompletedClose}
      />
    </div>
  );
};

export default WorkOrder;