import React, {useEffect, useContext, useState} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { Row, Col, Button  } from 'react-bootstrap';
import './ReportsContent.css';
import { ToastContainer, toast } from 'react-toastify';
import { formatDateTimeRange, formatTime, isNumber, decrypto, getCurrentDateTime, encrypto } from 'utils/commonFun';

import { apiCarbonFactorListData, apiPowerConsumptionExecuteList, apiPowerConsumptionProgramList, 
  apiPowerConsumptionReport, apiPowerConsumptionReportDetail } from 'utils/Api';

import ReportNameModal from "page/reports/data/modal/ReportNameModal"; //編輯報告名稱Modal
import MessageModal from 'components/basic/MessageModal';

import PageErr404 from "page/PageErr404";   //頁面不存在

const ReportsMain = () => {
  const { t } = useTranslation("reports");
  const navigate = useNavigate();
  let { reportID } = useParams(); 
  
  const { overtime, showFormatDate, showFormatTime } = useContext(MyUserContext);

  const [currentReportID, setCurrentReportID] = useState(""); //目前報告流水號
  const [currentReportName, setCurrentReportName] = useState(""); //目前報告名稱

  const [content, setContent] = useState("1"); //內容 1=>單次執行比較 2=>程式比較
  const [singleExecutionLogTable, setSingleExecutionLogTable] = useState([]); //單次執行比較表格
  const [programTable, setProgramTable] = useState([]); //程式比較表格

  const [initCarbonElectricity, setInitCarbonElectricity] = useState("0.000"); //初始載入電力碳排係數
  const [carbonElectricity, setCarbonElectricity] = useState("0.000"); //電力碳排係數
  const [carbonElectricityErr, setCarbonElectricityErr] = useState(false); //電力碳排係數(是否有錯誤)

  const [initElectricityPrice, setInitElectricityPrice] = useState("0.0000"); //初始載入每度電價
  const [electricityPrice, setElectricityPrice] = useState("0.0000"); //每度電價
  const [electricityPriceErr, setElectricityPriceErr] = useState(false); //每度電價(是否有錯誤)

  const [initCarbonRelatedCosts, setInitCarbonRelatedCosts] = useState("0.0000"); //每克碳相關費用 
  const [carbonRelatedCosts, setCarbonRelatedCosts] = useState("0.0000"); //每克碳相關費用 
  const [carbonRelatedCostsErr, setCarbonRelatedCostsErr] = useState(false); //每克碳相關費用(是否有錯誤)

  const [showReportNameModal, setShowReportNameModal] = useState(false); //是否顯示編輯報告名稱Modal
  const handleReportNameClose = () => setShowReportNameModal(false);

  const [apiStartTime, setApiStartTime] = useState(""); //API開始時間
  const [apiEndTime, setApiEndTime] = useState(""); //API結束時間
  const [apiSaveCompareReport, setApiSaveCompareReport] = useState([]); //API儲存報告(ProgramList)

  const [showLeavePageModal, setShowLeavePageModal] = useState(false); //是否顯示確定離開嗎？Modal
  const handleLeavePageClose = () => setShowLeavePageModal(false);

  const [showPageErr404, setShowPageErr404] = useState(false); //是否要顯示404頁面

  //#region 初始載入
  useEffect(() => {
    var decrypReportID = decrypto(reportID, 123, 25);
    setCurrentReportID(decrypReportID.replaceAll("art_", ""));
    if(decrypReportID.replaceAll("art_", "") === "0"){
      var newContent = localStorage.getItem("reportContent");
      if(newContent != null){
        setContent(newContent);
        if(newContent === "1"){
          loadPowerConsumptionExecuteList();
        }
        else{
          loadPowerConsumptionProgramList();
        }
  
        handleCarbonFactorData();
        setCurrentReportName(`Report_${getCurrentDateTime()}`);
      }
      else{
        //一樣找不到頁面
      }
    }
    else{
      handleConsumptionReportDetailData(parseInt(decrypReportID.replaceAll("art_", "")));
    }
  }, []);
  //#endregion

  //#region 載入單一一筆報告
  const handleConsumptionReportDetailData = async (id) => {
    const [httpStatus, reponseData] = await handleApiConsumptionReportDetailData(id);
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        setCurrentReportName(data.reportName);
        setContent(String(data.reportItemID));
        setApiStartTime(data.startTime);
        setApiEndTime(data.endTime);
        setInitCarbonElectricity(String(data.carbonIntensityElectricity.toFixed(3)));
        setCarbonElectricity(String(data.carbonIntensityElectricity.toFixed(3)));

        setInitElectricityPrice(String(data.electricityPrice.toFixed(4)));
        setElectricityPrice(String(data.electricityPrice.toFixed(4)));

        setInitCarbonRelatedCosts(String(data.carbonRelatedCosts.toFixed(4)));
        setCarbonRelatedCosts(String(data.carbonRelatedCosts.toFixed(4)));

        let newProgramList = [];
        let newApiProgramList = [];
        for (let item of data.programList) {
          if (data.reportItemID === 1) {
            newProgramList.push(createProgramList(item, true));
          } else {
            let programItem = createProgramList(item, false);
            programItem.totalExecutionCount = item.totalExecutionCount;
            programItem.averageErrorCount = item.errorCount;
            programItem.averagePowerConsumption = item.powerUsage;
            programItem.averageProgramExecutionDuration = item.seconds;
            newProgramList.push(programItem);
          }
          newApiProgramList.push(createApiProgramList(item));
        }

        if (data.reportItemID === 1) {
          setSingleExecutionLogTable(newProgramList);
        } else {
          setProgramTable(newProgramList);
        }
      }
      else if(reponseData.statusCode === "40400"){
        setShowPageErr404(true);
      }
      else{
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    }
  }
  //#endregion

  const createProgramList = (item, includeStartTime) => {
    let baseItem = {
      wpID: item.wpID,
      wpNO: item.wpNO,
      wpName: item.wpName,
      wpImage: item.wpImage,
      programID: item.programID,
      programName: item.programName,
      endTime: item.endTime,
      equipmentSerialNumber: item.equipmentSerialNumber,
      errorCount: item.errorCount,
      powerUsage: item.powerUsage,
      seconds: item.seconds
    };
  
    if (includeStartTime) {
      baseItem.startTime = item.startTime;
    }
  
    return baseItem;
  }

  const createApiProgramList = (item) => {
    return {
      programID: item.programID,
      programName: item.programName,
      startTime: item.startTime,
      endTime: item.endTime,
      equipmentSerialNumber: item.equipmentSerialNumber,
      errorCount: item.errorCount,
      powerUsage: item.powerUsage,
      seconds: item.seconds
    };
  }

  //#region 載入單一一筆報告API
  const handleApiConsumptionReportDetailData = async (id) => {
    let postJson = {
      reportID: id
    };
    let response = await apiPowerConsumptionReportDetail(postJson);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

 
  //#region 單次執行比較報告
  const loadPowerConsumptionExecuteList = async () => {
    const [httpStatus, reponseData] = await handleApiPowerConsumptionExecuteList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        const detail = data.detail;
        setSingleExecutionLogTable(detail);

        let apiArray = [];
        for(let item of detail){
          apiArray.push({
            programID: item.programID,
            programName: item.programName,
            startTime: item.startTime,
            endTime: item.endTime,
            equipmentSerialNumber: item.equipmentSerialNumber,
            errorCount: item.errorCount,
            powerUsage: item.powerUsage,
            seconds: item.seconds
          });
        }

        setApiSaveCompareReport(apiArray);
      }
      else if(reponseData.statusCode === "40103" || reponseData.statusCode === "40104"){
        overtime();
      }
    }
  }
  //#endregion

  //#region API單次執行比較報告
  const handleApiPowerConsumptionExecuteList = async () => {
    var executionIdList = JSON.parse(localStorage.getItem("executeListRequest"));
    let postJson = {
      executionIdList: executionIdList
    };
    let workpieceListResponse = await apiPowerConsumptionExecuteList(postJson);
    if(workpieceListResponse){
      const httpStatus = workpieceListResponse.request.status;
      const reponseData = workpieceListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 程式比較報告
  const loadPowerConsumptionProgramList = async () => {
    const [httpStatus, reponseData] = await handleApiPowerConsumptionProgramList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        const detail = data.detail;
        setProgramTable(detail);

        let apiArray = [];
        for(let item of detail){
          apiArray.push({
            programID: item.programID,
            programName: item.programName,
            endTime: item.endTime,
            totalExecutionCount: item.totalExecutionCount,
            equipmentSerialNumber: item.equipmentSerialNumber,
            errorCount: item.averageErrorCount,
            powerUsage: item.averagePowerConsumption,
            seconds: item.averageProgramExecutionDuration
          });
        }
        setApiSaveCompareReport(apiArray);
      }
    }
  }
  //#endregion

  //#region API程式比較報告
  const handleApiPowerConsumptionProgramList = async () => {
    var programList = JSON.parse(localStorage.getItem("programListRequest"));
    let postJson = {
      programList: programList
    };
    let workpieceListResponse = await apiPowerConsumptionProgramList(postJson);
    if(workpieceListResponse){
      const httpStatus = workpieceListResponse.request.status;
      const reponseData = workpieceListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 載入碳排放係數資料
  const handleCarbonFactorData = async () => {
    const [httpStatus, reponseData] = await handleApiCarbonFactorList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
          const data = reponseData.data;
          if(data.length > 0){
            setCarbonElectricity(data[0].carbonIntensityElectricity.toFixed(3));
          }
      }
      // else if(reponseData.statusCode === "40103" || reponseData.statusCode === "40104"){
      //     overtime();
      // }
      else{
          toast.error(reponseData.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
          });
      }
    }
    else{
      toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
      });
    }
  };
  //#endregion

  //#region 碳排放係數列表API
  const handleApiCarbonFactorList = async () => {
    let postData = {};

    let response = await apiCarbonFactorListData(postData);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 輸入右上角數值Change事件
  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if(name === "carbonElectricity"){
      setCarbonElectricity(value);
    }
    else if(name === "electricityPrice"){
      setElectricityPrice(value);
    }
    else if(name === "carbonRelatedCosts"){
      setCarbonRelatedCosts(value);
    }
  };
  //#endregion

  //#region 輸入右上角數值Blur事件
  const handleInputBlur = async (e) => {
    let { name, value } = e.target;

    if(name === "carbonElectricity"){
      if(!isNumber(value)){
        setCarbonElectricityErr(true);
      }
      else{
        value = parseFloat(value).toFixed(3);
        setCarbonElectricityErr(false);
      }
      setCarbonElectricity(value);
    }
    else if(name === "electricityPrice"){
      if(!isNumber(value)){
        setElectricityPriceErr(true);
      }
      else{
        value = parseFloat(value).toFixed(4);
        setElectricityPriceErr(false);
      }
      setElectricityPrice(value);
    }
    else if(name === "carbonRelatedCosts"){
      if(!isNumber(value)){
        setCarbonRelatedCostsErr(true);
      }
      else{
        value = parseFloat(value).toFixed(4);
        setCarbonRelatedCostsErr(false);
      }

      setCarbonRelatedCosts(value);
    }
  };
  //#endregion

  //#region 確定要離開嗎?
  const handleLeave = () => {
      if(initCarbonElectricity != carbonElectricity || initElectricityPrice != electricityPrice 
      || initCarbonRelatedCosts != carbonRelatedCosts){
        setShowLeavePageModal(true);
      }
      else{
        navigate("/Reports");
      }
  }
  //#endregion

  //#region 儲存報告按鈕
  const handleSave = () => {
    if(!carbonElectricityErr && !electricityPriceErr && !carbonRelatedCostsErr){
      setShowReportNameModal(true);
    }
  }
  //#endregion

  //#region 儲存報告
  const loadPowerConsumptionReport = async (behavior, tempReportName = "") => {
    if(!carbonElectricityErr && !electricityPriceErr && !carbonRelatedCostsErr){
      const [httpStatus, reponseData] = await handleApiPowerConsumptionReport(behavior, tempReportName);
      if(httpStatus == "200"){
        if(reponseData.statusCode === "20100"){
          if(currentReportID === "0"){
            toast(<>
              <span>{t("createdSuccessfully")}</span>
              </>, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            
            setCurrentReportName(tempReportName);
            handleReportNameClose();
            setCurrentReportID(reponseData.data.reportID.toString());
            handleConsumptionReportDetailData(reponseData.data.reportID);
            navigate(`/Reports/$${encrypto(`art_${reponseData.data.reportID}`, 123, 25)}`);
          }
          else{
            if(behavior === "editReportName"){
              toast(<>
                <span>{t("editingCompletedSaved")}</span>
                </>, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setShowReportNameModal(false);
            }
            else{
              toast(<>
                <span>{t("savedSuccessfully")}</span>
                </>, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              
              handleReportNameClose();
            }
          }
        }
      }
  
      return [httpStatus, reponseData];
    }
  }
  //#endregion

  //#region API儲存報告
  const handleApiPowerConsumptionReport = async (behavior, tempReportName) => {
    //單次執行比較(抓出最早最晚時間)
    //程式比較直接抓取第一筆的結束時間
    let startTime = "";
    let endTime = "";

    if(currentReportID === "0"){
      if(content === "1"){
        const { earliest, latest } = getEarliestAndLatestTimes(apiSaveCompareReport);
        startTime = earliest[0].startTime;
        endTime = latest[1].startTime;
      }
      else{
        endTime = apiSaveCompareReport[0].endTime;
      }
    }
    else{
      startTime = apiStartTime;
      endTime = apiEndTime;
    }

    let postJson = {
      action: currentReportID === "0" ? "insert" : "update",
      reportID: parseInt(currentReportID),
      reportName: behavior === "editReportName" || currentReportID === "0" ? tempReportName : currentReportName,
      carbonIntensityElectricity: parseFloat(carbonElectricity),
      electricityPrice: parseFloat(electricityPrice),
      carbonRelatedCosts: parseFloat(carbonRelatedCosts),
      reportItemID: parseInt(content),
      endTime: endTime,
      programList: apiSaveCompareReport
    };

    if(content === "1"){
      postJson.startTime = startTime;
    }

    let response = await apiPowerConsumptionReport(postJson);
    if(response){
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 抓出時間最早以及最晚
  const getEarliestAndLatestTimes = (timeList) => {
    // 将时间字符串转换为 Date 对象，并保留原始对象
    let times = timeList.map(item => ({
        ...item,
        date: new Date(item.startTime)
    }));

    // 按日期升序排序
    times.sort((a, b) => a.date - b.date);

    // 获取最早和最晚的两个时间
    const earliest = times.slice(0, 2); // 前两个
    const latest = times.slice(-2); // 最后两个

    return { earliest, latest };
  }
  //#endregion

  return (
    <>
        <div className='pageContent'>
          {
            !showPageErr404 ?
            <div>
              <Row>
                <Col xs={6}>
                  {
                    currentReportID !== "0" ?
                      <button className="btn btn-lv5 btn-icontext" onClick={handleLeave}>
                        <div className="icon icon-Back"></div>
                        <div>{t("back")}</div>
                      </button> : null
                  }
                </Col>
                <Col xs={6} className='d-flex justify-content-end'>
                  {
                    currentReportID === "0" ?
                    <>
                      <Button variant="btn btn-lv3 btn-icontext" className='d-flex justify-content-center' onClick={handleLeave}>
                      <div className="icon icon-Delete"></div>
                      <div>{t("cancel")}</div>
                      </Button>
                      <button className="btn btn-lv1 btn-icontext ms-2" onClick={()=>handleSave()}>
                      <div className="icon icon-Save"></div>
                      <div>{t("save")}</div>
                      </button>
                    </> :
                    <>
                      <Button variant="btn btn-lv3 btn-icontext" className='d-flex justify-content-center' onClick={()=>handleSave()}>
                      <div className="icon icon-EditReportName"></div>
                      <div>{t("editReportName")}</div>
                      </Button>
                      <button className="btn btn-lv1 btn-icontext ms-2" onClick={()=>loadPowerConsumptionReport("saveReport")}>
                        <div className="icon icon-Save"></div>
                        <div>{t("save")}</div>
                      </button>
                    </>
                  }
                  {/* {
                    content === "1" && singleExecutionLogTable?.length > 0 || content === "2" && programTable?.length > 0 ?
                      <Button variant="outline-primary" className='d-flex justify-content-center' onClick={navigate("/Reports")}>
                        <span className="material-symbols-rounded mb-0">delete_forever</span>{t("cancel")}
                      </Button> : 
                      <button className="button-primary" onClick={() => setShowGenerateReportModal(true)}>
                        <p className="material-symbols-rounded mb-0">post_add</p>&nbsp;&nbsp;
                        {t("generateReport")}
                      </button>
                  } */}
                </Col>
              </Row>
              <main id="power_report_once" className="pg-report table-wrapper border">
                <div className="titleline">
                  <div className="titlegp">
                      <div className="title">{currentReportName}</div>
                      <div className='d-flex'>
                        <p className='subtitleBefore'>{t("powerCarbonComparisonReport")}</p>
                        <p className='subtitleAfter'>&nbsp;|&nbsp;{content === "1" ? t("singleExecutionComparison") : t("programComparison")}</p>
                      </div>
                  </div>
                <div className="rightgp">
                  <div className="inputgp">
                      <label>{t("carbonIntensityElectricity")}</label>
                      <div className="input">
                        <input className="narrow" name="carbonElectricity" 
                          value={carbonElectricity} onChange={handleInputChange}
                          onBlur={handleInputBlur} />
                        <input className="wide disabled" value="kgCO2/kWh" disabled />
                      </div>
                      {
                        carbonElectricityErr ?
                        <p className='text-danger mb-0'>{t("formatErrorCorrectNumerical")}</p> :
                        null
                      }
                  </div>
                  <div className="inputgp">
                      <label>{t("electricityPricePerKwh")}</label>
                      <div className="input">
                        <input className="narrow disabled" value="$" disabled />
                        <input className="wide" name="electricityPrice" 
                          value={electricityPrice} onChange={handleInputChange}
                          onBlur={handleInputBlur} />
                      </div>
                      {
                        electricityPriceErr ?
                        <p className='text-danger mb-0'>{t("formatErrorCorrectNumerical")}</p> :
                        null
                      }
                  </div>
                  <div className="inputgp">
                      <label>{t("carbonRelatedCostsPerG")}</label>
                      <div className="input">
                        <input className="narrow disabled" value="$" disabled />
                        <input className="wide" name="carbonRelatedCosts" 
                          value={carbonRelatedCosts} onChange={handleInputChange}
                          onBlur={handleInputBlur} />
                      </div>
                      {
                        carbonRelatedCostsErr ?
                        <p className='text-danger mb-0'>{t("formatErrorCorrectNumerical")}</p> :
                        null
                      }
                  </div>
                </div>
              </div>
              {
                content === "1" && singleExecutionLogTable?.length > 0 ?
                  <div className="table-compare table-scroll-justify">
                    <div>
                        <table className="dmtable">
                            <thead>
                                <tr className="sticky-top">
                                    <th></th>
                                    <th></th>
                                    <th className="min-width8 sticky-left">{t("machiningProgram")}</th>
                                    <th className="min-width6">{t("workpiece")}</th>
                                    <th className="min-width8 tCenter">{t("executionTime")}</th>
                                    <th className="tCenter">{t("equipmentUsed")}</th>
                                    <th className="tCenter">{t("numberErrors")}</th>
                                    <th className="min-width6 tCenter">{t("duration")}</th>
                                    <th className="min-width6 tRight">{t("powerUsage")}</th>
                                    <th className="min-width6 tRight">{t("carbonEmissions")}</th>
                                    <th className="min-width6 tRight">{t("electricityPrice")}</th>
                                    <th className="min-width6 tRight">{t("carbonRelatedCostsPerG")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {singleExecutionLogTable.map((item, index) => {
                                  return <tr key={index}>
                                      <td className="sticky-left tCenter">{index + 1}</td>
                                      <td>
                                        {
                                          item.wpImage != null ?
                                            <img className="img-wp" src={item.wpImage} alt={item.wpName} /> :
                                            null
                                        }
                                      </td>
                                      <td className="sticky-left">{item.programName}</td>
                                      <td className="tdgp">
                                          <span className="subtd">{item.wpNO}</span><br />
                                          <span>{item.wpName}</span>
                                      </td>
                                      <td className="tRight">
                                        {(() => {
                                          const trans = formatDateTimeRange(showFormatDate, showFormatTime, item.startTime, item.endTime);
                                          return trans.startDate + " " + trans.startTime + " ~ " + trans.endDate + " " + trans.endTime;
                                        })()}
                                      </td>
                                      <td className="tCenter">{item.equipmentSerialNumber}</td>
                                      <td className="tCenter">{item.errorCount.toFixed(1)}</td>
                                      <td className="bold tRight">
                                        {(() => {
                                          let resultVal = item.seconds;

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0){
                                            let firstVal = singleExecutionLogTable[0].seconds;
                                            let currentVal = resultVal;

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                              signNumber = "-";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }

                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {formatTime(resultVal)}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{formatTime(Math.abs(comparisonResult))}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                      <td className="bold tRight">
                                        {(() => {
                                          //用電量
                                          let resultVal = item.powerUsage;

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0){
                                            let firstVal = singleExecutionLogTable[0].powerUsage;
                                            let currentVal = parseFloat(resultVal);

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }


                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {resultVal}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                      <td className="bold tRight">
                                      {(() => {
                                        //碳排放量
                                        let resultVal = "";
                                        if(carbonElectricityErr){
                                          resultVal = "-"
                                        }
                                        else{
                                          resultVal = (parseFloat(item.powerUsage) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                                        }
                                        
                                        let comparisonClass = "";
                                        let signNumber = "";
                                        let comparisonResult = 0;
                                        if(index != 0 && resultVal != "-"){
                                          let firstVal = parseFloat(singleExecutionLogTable[0].powerUsage) * parseFloat(carbonElectricity) * 1000;
                                          let currentVal = parseFloat(resultVal);

                                          if(firstVal === currentVal){
                                            comparisonClass = "equal";
                                            signNumber = "±";
                                          }
                                          else if(firstVal > currentVal){
                                            comparisonClass = "lower";
                                          }
                                          else{
                                            comparisonClass = "higher";
                                            signNumber = "+";
                                          }
                                          comparisonResult = currentVal - firstVal;
                                        }
                                        
                                        
                                        return <>
                                          {resultVal}<br />
                                          {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                        </>
                                      })()}
                                      </td>
                                      <td className="bold tRight">$ 
                                        {(() => {
                                          //電費
                                          let resultVal = "";
                                          if(electricityPriceErr){
                                            resultVal = "-"
                                          }
                                          else{
                                            resultVal = (parseFloat(item.powerUsage) * parseFloat(electricityPrice)).toFixed(2);
                                          }

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0 && resultVal != "-"){
                                            let firstVal = parseFloat(singleExecutionLogTable[0].powerUsage) * parseFloat(electricityPrice);
                                            let currentVal = parseFloat(resultVal);

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }

                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {resultVal}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                      <td className="bold tRight">$ 
                                        {(() => {
                                          //每克碳相關費用
                                          let resultVal = "";
                                          if(carbonRelatedCostsErr){
                                            resultVal = "-";
                                          }
                                          else{
                                            if(!carbonElectricityErr){
                                              var initCarbonEmissions = (parseFloat(item.powerUsage) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                                              resultVal = (initCarbonEmissions * parseFloat(carbonRelatedCosts)).toFixed(2);
                                            }
                                            else{
                                              resultVal = "-";
                                            }
                                          }

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0 && resultVal != "-"){
                                            var initCarbonEmissions = (parseFloat(singleExecutionLogTable[0].powerUsage) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                                            let firstVal = initCarbonEmissions * parseFloat(carbonRelatedCosts);
                                            let currentVal = parseFloat(resultVal);

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }

                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {resultVal}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                  </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div> : null
              }
              {
                content === "2" && programTable?.length > 0 ?
                <div className="table-compare table-scroll-justify">
                  <div>
                      <table className="dmtable">
                          <thead>
                              <tr className="sticky-top">
                                  <th></th>
                                  <th></th>
                                  <th className="min-width8 sticky-left">{t("machiningProgram")}</th>
                                  <th className="min-width6">{t("workpiece")}</th>
                                  <th className="min-width8 tCenter">{t("investigationPeriod")}</th>
                                  <th className="tCenter">{t("executionCount")}</th>
                                  <th className="tCenter">{t("equipmentUsed")}</th>
                                  <th className="min-width6 tCenter">{t("averageErrorCount")}</th>
                                  <th className="min-width6 tRight">{t("averageExecutionDuration")}</th>
                                  <th className="min-width6 tRight">{t("averagePowerConsumption")}</th>
                                  <th className="min-width6 tRight">{t("averageCarbonEmissions")}</th>
                                  <th className="min-width6 tRight">{t("averageElectricityPrice")}</th>
                                  <th className="min-width6 tRight">{t("averageCarbonRelatedCosts")}</th>
                              </tr>
                          </thead>
                          <tbody>
                          {programTable.map((item, index) => {
                                  return <tr key={index}>
                                      <td className="sticky-left tCenter">{index + 1}</td>
                                      <td>
                                        {
                                          item.wpImage != null ?
                                            <img className="img-wp" src={item.wpImage} alt={item.wpName} /> :
                                            null
                                        }
                                      </td>
                                      <td className="sticky-left">{item.programName}</td>
                                      <td className="tdgp">
                                          <span className="subtd">{item.wpNO}</span><br />
                                          <span>{item.wpName}</span>
                                      </td>
                                      <td className="tRight">
                                        {/* - {convertDateFormat(item.endTime)} */}
                                        {(() => {
                                          const trans = formatDateTimeRange(showFormatDate, showFormatTime, item.endTime);
                                          return " ~ " + trans.startDate + " " + trans.startTime;
                                        })()}
                                      </td>
                                      <td className="tCenter">{item.totalExecutionCount}</td>
                                      <td className="tCenter">{item.equipmentSerialNumber}</td>
                                      <td className="tCenter">{item.averageErrorCount.toFixed(1)}</td>
                                      <td className="bold tRight">
                                        {(() => {
                                          //平均程式執行時長
                                          let resultVal = item.averageProgramExecutionDuration;

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0){
                                            let firstVal = programTable[0].averageProgramExecutionDuration;
                                            let currentVal = resultVal;

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                              signNumber = "-";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }

                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {formatTime(resultVal)}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{formatTime(Math.abs(comparisonResult))}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                      <td className="bold tRight">
                                      {(() => {
                                          //平均用電量
                                          let resultVal = item.averagePowerConsumption;

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0){
                                            let firstVal = programTable[0].averagePowerConsumption;
                                            let currentVal = parseFloat(resultVal);

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }


                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {resultVal}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                      <td className="bold tRight"> 
                                      {(() => {
                                        //平均碳排放量
                                        let resultVal = "";
                                        if(carbonElectricityErr){
                                          resultVal = "-"
                                        }
                                        else{
                                          resultVal = (parseFloat(item.averagePowerConsumption) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                                        }

                                        let comparisonClass = "";
                                        let signNumber = "";
                                        let comparisonResult = 0;
                                        if(index != 0 && resultVal != "-"){
                                          let firstVal = parseFloat(programTable[0].averagePowerConsumption) * parseFloat(carbonElectricity) * 1000;
                                          let currentVal = parseFloat(resultVal);

                                          if(firstVal === currentVal){
                                            comparisonClass = "equal";
                                            signNumber = "±";
                                          }
                                          else if(firstVal > currentVal){
                                            comparisonClass = "lower";
                                          }
                                          else{
                                            comparisonClass = "higher";
                                            signNumber = "+";
                                          }

                                          comparisonResult = currentVal - firstVal;
                                        }
                                        
                                        return <>
                                          {resultVal}<br />
                                          {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                        </>
                                      })()}
                                      </td>
                                      <td className="bold tRight">$ 
                                        {(() => {
                                          //平均電費
                                          let resultVal = "";
                                          if(electricityPriceErr){
                                            resultVal = "-"
                                          }
                                          else{
                                            resultVal = (parseFloat(item.averagePowerConsumption) * parseFloat(electricityPrice)).toFixed(2);
                                          }

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0 && resultVal != "-"){
                                            let firstVal = parseFloat(programTable[0].averagePowerConsumption) * parseFloat(electricityPrice);
                                            let currentVal = parseFloat(resultVal);

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }

                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {resultVal}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                      <td className="bold tRight">$ 
                                      {(() => {
                                          //碳相關費用
                                          let resultVal = "";
                                          if(carbonRelatedCostsErr){
                                            resultVal = "-"
                                          }
                                          else{
                                            var initCarbonEmissions = (parseFloat(item.averagePowerConsumption) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                                            resultVal = (initCarbonEmissions * parseFloat(carbonRelatedCosts)).toFixed(2);
                                          }

                                          let comparisonClass = "";
                                          let signNumber = "";
                                          let comparisonResult = 0;
                                          if(index != 0 && resultVal != "-"){
                                            var initCarbonEmissions = (parseFloat(programTable[0].averagePowerConsumption) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                                            let firstVal = parseFloat(initCarbonEmissions) * parseFloat(carbonRelatedCosts);
                                            let currentVal = parseFloat(resultVal);

                                            if(firstVal === currentVal){
                                              comparisonClass = "equal";
                                              signNumber = "±";
                                            }
                                            else if(firstVal > currentVal){
                                              comparisonClass = "lower";
                                            }
                                            else{
                                              comparisonClass = "higher";
                                              signNumber = "+";
                                            }

                                            comparisonResult = currentVal - firstVal;
                                          }
                                          
                                          return <>
                                            {resultVal}<br />
                                            {index != 0 ? <span className={`subtd ${comparisonClass}`}>{signNumber}{comparisonResult?.toFixed(2) ?? '0.00'}</span> : null}
                                          </>
                                        })()}
                                      </td>
                                  </tr>
                                })}
                          </tbody>
                      </table>
                  </div>
              </div> : null
              }
              </main>
            </div> : 
            <PageErr404 judgeBackHome={false} backURL="/Reports" />
          }
          
          
          <ReportNameModal 
            showModal={showReportNameModal}
            handleClose={handleReportNameClose}
            reportName={currentReportName}
            setReportName={setCurrentReportName}
            reportID={currentReportID}
            handleLoadData={(tempReportName)=>loadPowerConsumptionReport(currentReportID === "0" ? "" : "editReportName", tempReportName)}
            judgeSaveAll={true}
          />

          {/* 確定離開嗎？ */}
          <MessageModal 
            title={t("leavePage")}
            content={`${t("leavePageContent1")}<br /><br />${t("leavePageContent2")}`}
            showModal={showLeavePageModal} 
            handleConfirmData={() => navigate("/Reports")}
            size="xs"
            confirmModalTxt={t("leaveSaving")}
            cancelTxt={t("stayThisPage")}
            cancelModal={handleLeavePageClose}
            handleClose={handleLeavePageClose}
          />
        </div>

        <ToastContainer />
    </>
  );
};

export default ReportsMain;