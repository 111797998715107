import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import './pwd.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import { apiValidateToken, apiSetPwd } from 'utils/Api';
import { removeCookieLogin } from 'utils/loginUtil';

import { encrypt } from 'utils/crypt';

const SetPWD = () => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const { behavior, token } = useParams();

    const [showContainer, setShowContainer] = useState(""); //msg=>訊息框 setPwd=>設定密碼
    const [errorType, setErrorType] = useState(""); //錯誤訊息 1=>找不到該用戶流水號 2=>是否已經超過24小時了 3=>使用者已啟用 User already activated

    const [userAccount, setUserAccount] = useState(""); //帳號

    const [pwd, setPwd] = useState(""); //密碼
    const [judgeVisibilityPwd, setJudgeVisibilityPwd] = useState(false); //是否打開眼睛
    const [confirmPwd, setConfirmPwd] = useState(""); //確認密碼
    const [judgeVisibilityConfirmPwd, setJudgeVisibilityConfirmPwd] = useState(false); //是否打開眼睛

    const [errPwd, setErrPwd] = useState(false); //密碼錯誤訊息
    const [confirmErrPwd, setconfirmErrPwd] = useState(false); //確認密碼錯誤訊息
    const [clickSubmit, setClickSubmit] = useState(false);  //點選設定密碼


    useEffect(() => {
        if(behavior == "resetPWD" || behavior == "activate"){
            removeCookieLogin();
            handleValidateToken();
        }
    }, []);

    const handleValidateToken = async () => {
        const [httpStatus, reponseData] = await handleApiValidateToken();
        if(httpStatus == "200"){
            if(reponseData.statusCode === "20100"){
                setShowContainer("setPwd");
                setUserAccount(reponseData.data.userAccount);
            }
            else if(reponseData.statusCode === "40400"){
                setShowContainer("msg");
                setErrorType("1");
            }
            else if(reponseData.statusCode === "40103"){
                setShowContainer("msg");
                setErrorType("2");
            }
            else if(reponseData.statusCode === "40106"){
                setShowContainer("msg");
                setErrorType("3");
            }
            else{
                setShowContainer("");
                setErrorType("");
                toast.error(reponseData.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            }
        }
        else{
            setShowContainer("");
            setErrorType("");
            toast.error(reponseData.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    //#region 驗證啟用使用者Token API
    const handleApiValidateToken = async () => {
        let postData = {
            token: token,
            action: behavior
        }
        let validateTokenResponse = await apiValidateToken(postData);
        if(validateTokenResponse){
            const httpStatus = validateTokenResponse.request.status;
            const reponseData = validateTokenResponse.data;

            return [httpStatus, reponseData];
        }
        else{
            return ["500", ""]
        }
    }
    //#endregion

    //#region 設定密碼 API
    const handleApiSetPwd = async () => {
        let postData = {
            pwd: encrypt(pwd),
            action: behavior,
            token: token
        }
        let setPwdResponse = await apiSetPwd(postData);
        if(setPwdResponse){
            const httpStatus = setPwdResponse.request.status;
            const reponseData = setPwdResponse.data;

            return [httpStatus, reponseData];
        }
        else{
            return ["500", ""]
        }
    }
    //#endregion

    //#region 跳轉登入頁面
    const navigateLogin = () => {
        navigate("/");
    }
    //#endregion

    //#region 是否打開眼睛
    const handleEye = (item) => {
        if(item === "pwd"){
            setJudgeVisibilityPwd(!judgeVisibilityPwd);
        }
        else{
            setJudgeVisibilityConfirmPwd(!judgeVisibilityConfirmPwd);
        }
    }
    //#endregion

    //#region 設定密碼
    const setPwdBtn = async () => {
        setClickSubmit(true);

        if(validateInput()){
            const [httpStatus, reponseData] = await handleApiSetPwd();
            if(httpStatus == "200"){
                if(reponseData.statusCode === "20100"){
                    setShowContainer("msg");
                    setErrorType("4");
                }
                else if(reponseData.statusCode === "40103" || reponseData.statusCode === "40104"){
                    
                }
                else{
                    toast.error(reponseData.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark"
                    });
                }
            }
            else{
                toast.error(reponseData.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }
    }
    //#endregion

    //#region 驗證欄位
    const validateInput = () => {
        if(pwd != "" && confirmPwd != ""){
            const regexPwd = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+~`|}{[\]:;'<>,.?/]{6,30}$/;
            if(pwd.length >= 6 && pwd.length <= 30 && regexPwd.test(pwd) && confirmPwd == pwd){
                return true;
            }
        }
        return false;
    }
    //#endregion

    const handleInputChange = async (e) => {
        const { name, value } = e.target;

        if(name === "pwd"){
            setPwd(value);

            if(clickSubmit){
                // const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=-])[a-zA-Z\d!@#$%^&*()_+=-]{6,30}$/; //必須包含英文、數字、符號。
                const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+~`|}{[\]:;'<>,.?/]{6,30}$/;
                if(value == "" || value.length < 6 || value.length > 30 || !regex.test(value)){
                    setErrPwd(true);
                }
                else{
                    setErrPwd(false);
                }

                if(confirmPwd == "" || confirmPwd != value){
                    setconfirmErrPwd(true);
                }
                else{
                    setconfirmErrPwd(false);
                }
            }
        }
        else{
            setConfirmPwd(value);

            if(clickSubmit){
                if(value == "" || value != pwd){
                    setconfirmErrPwd(true);
                }
                else{
                    setconfirmErrPwd(false);
                }

                // const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=-])[a-zA-Z\d!@#$%^&*()_+=-]{6,30}$/; //必須包含英文、數字、符號。
                const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+~`|}{[\]:;'<>,.?/]{6,30}$/;
                if(pwd == "" || pwd.length < 6 || pwd.length > 30 || !regex.test(pwd)){
                    setErrPwd(true);
                }
                else{
                    setErrPwd(false);
                }
            }
        }
    };

    return (
        <>
            <div className={`pwd-container ${showContainer === "msg" ? "" : "d-none"}`}>
                <div className='message-board'>
                    <p className='message-board-title mb-0'>
                        <b>
                        {(() => {
                            if(errorType == "1"){
                                return t("userNotFound");
                            }
                            else if(errorType == "2"){
                                return t("linkExpired");
                            }
                            else if(errorType == "3"){
                                return t("accountActivated");
                            }
                            else if(errorType == "4"){
                                return t("accountSuccessfullyActivated");
                            }
                        })()}
                        </b>
                    </p>
                    <p className='message-board-content'>
                        {(() => {
                            if(errorType == "1"){
                                return <p>{t("userNotFoundContent1")}<br /><br />{t("userNotFoundContent2")}<br />{t("userNotFoundContent3")}</p>;
                            }
                            else if(errorType == "2"){
                                return <p>{t("linkExpiredContent1")}<br />{t("linkExpiredContent2")}</p>;
                            }
                            else if(errorType == "3"){
                                return <p>{t("accountActivatedContent1")}<br />{t("accountActivatedContent2")}</p>;
                            }
                            else if(errorType == "4"){
                                return <p>{t("accountSuccessfullyActivatedContent1")}<br /><br />{t("accountSuccessfullyActivatedContent2")}</p>;
                            }
                        })()}
                    </p>
                    <input type="button" className='btn btn-lv1 w-100 h38' value={t("goLogin")} onClick={navigateLogin} />
                </div>
            </div>
            <div className={`pwd-container ${showContainer === "setPwd" ? "" : "d-none"}`}>
                <div className='pwd-form'>
                    <h4 className='tCenter mb-4 mt-5'><b>Set Password</b></h4>
                    <div className='pwd-board'>
                        <p className='user-account-title'>User Account</p>
                        <p className='user-account'>{userAccount}</p>

                        <div className={`input-with-icon px-2 mb-1 ${errPwd ? "input-error" : ""}`}>
                            <span className="material-symbols-rounded">lock</span>
                            <input type={judgeVisibilityPwd ? "text" : "password"} placeholder={t("password")} className='w-100'
                                name="pwd"
                                value={pwd} onChange={handleInputChange}
                            />
                            <span  className="material-symbols-rounded text-blue cursor-pointer" onClick={()=>handleEye("pwd")}>
                                {judgeVisibilityPwd ? "visibility" : "visibility_off"}
                            </span>
                        </div>
                        <div className="mb-4">
                            {
                                clickSubmit && pwd == "" ? <p className='text-err'>{t("requiredFields")}</p> : ""
                            }
                            {
                                clickSubmit && pwd != "" && pwd.length < 6 ? <p className='text-err'>{t("notEnoughCharacters")}</p> : ""
                            }
                            {
                                clickSubmit && pwd != "" && pwd.length > 30 ? <p className='text-err'>{t("exceedsCharacterLimit")}</p> : ""
                            }
                            {(() => {
                                if(clickSubmit && pwd != "" && pwd.length >= 6 && pwd.length <= 30){
                                    // const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=-])[a-zA-Z\d!@#$%^&*()_+=-]{6,30}$/;
                                    // const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,30}$/;
                                    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+~`|}{[\]:;'<>,.?/]{6,30}$/;
                                    if(!regex.test(pwd)){
                                        return <p className='text-err'>{t("formatErrorPwd")}</p>;
                                    }
                                }
                            })()}
                        </div>
                       
                        <div className={`input-with-icon px-2 mb-1 ${confirmErrPwd ? "input-error" : ""}`}>
                            <span className="material-symbols-rounded">lock</span>
                            <input type={judgeVisibilityConfirmPwd ? "text" : "password"} placeholder={t("confirmPwd")} className='w-100'
                                name="confirmPwd"
                                value={confirmPwd} onChange={handleInputChange}
                            />
                            <span  className="material-symbols-rounded text-blue cursor-pointer" onClick={()=>handleEye("resetPwd")}>
                                {judgeVisibilityConfirmPwd ? "visibility" : "visibility_off"}
                            </span>
                        </div>
                        <div className="mb-4">
                        {
                            clickSubmit && confirmPwd == "" ? <p className='text-err'>{t("requiredFields")}</p> : ""
                        }
                        {
                            clickSubmit && confirmPwd != "" && confirmPwd != pwd ? <p className='text-err'>{t("pwdMatch")}</p> : ""
                        }
                        </div>

                        <button className='btn btn-lv1 w-100 h38' onClick={setPwdBtn}>{t("setPwd")}</button>

                        <hr />

                        <p className='pwd-remark mb-0'>{t("passwordRules")}</p>
                        <p className='pwd-remark mb-0'>{t("passwordRules1")}</p>
                        <p className='pwd-remark mb-0'>{t("passwordRules2")}</p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default SetPWD;