import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { Modal, Form } from 'react-bootstrap';
import MultiSelect, { Option } from "components/multiSelect/MultiSelect";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './Messages.css';

import DateTimeRangePicker from 'components/dateTimeRangePicker/DateTimeRangePicker';
import MyPagination from 'components/basic/Pagination';

import { apiMachineListData, apiIdeaLogin, apiEquimentMessage } from 'utils/Api';

import { formatDateTimeRange } from 'utils/commonFun';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const domain = window.cookieDomain;

const Messages = () => {
  const { t, i18n } = useTranslation("history");

  const { lang, authority, overtime, showFormatDate, showFormatTime } = useContext(MyUserContext);

  const [mutiEquipmentSetting, setMutiEquipmentSetting] = useState([]); //設備設定檔
  const [selEquipment, setSelEquipment] = useState([]);  //使用者選擇的設備

  const [mutiLevelSetting, setMutiLevelSetting] = useState([]); //等級設定檔
  const [selLevel, setSelLevel] = useState([]);  //使用者選擇的等級

  const [mutiTypeSetting, setMutiTypeSetting] = useState([]); //類別設定檔
  const [selType, setSelType] = useState([]);  //使用者選擇的類別

  const [choiceDate, setChoiceDate] = useState([]); //選擇區間

  const [showFilter2, setShowFilter2] = useState(true); //是否要顯示第2排的查詢條件

  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字

  const [equipmentMessageList, setEquipmentMessageList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [machineList, setMachineList] = useState(null); //機台列表
  const [choiceMachine, setChoiceMachine] = useState(null); //目前選擇的設備

  const [showExclusion, setShowExclusion] = useState(1); //是否要除外

  const [showModal, setShowModal] = useState(false); //幫助中心Modal
  const handleClose = () => setShowModal(false);

  const scrollRef = useRef(null);

  useEffect(() => {
    // if(localStorage.getItem("history_equipment") !== null){
    //   setSelEquipment(localStorage.getItem("history_equipment"));
    // }
    if (localStorage.getItem("history_keyword") !== null) {
      setKeyword(localStorage.getItem("history_keyword"));
    }
    if (localStorage.getItem("history_startDate") !== null && localStorage.getItem("history_endDate") !== null) {
      setChoiceDate([localStorage.getItem("history_startDate"), localStorage.getItem("history_endDate")]);
      setSelLevel([{ value: "alarm", label: t("alarm") }]);



      setShowFilter2(true);
    }
    else {
      setShowFilter2(false);
    }

    setMutiTypeSetting(window.messageTypeEn);
  }, []);

  useEffect(() => {
    setMutiLevelSetting([
      { value: "alarm", label: t("alarm") },
      { value: "warning", label: t("warning") },
      { value: "info", label: t("info") },
      { value: "system Notification", label: t("systemNotification") }
    ]);

    let newSelLevel = [];
    for (let item of selLevel) {
      if (item.value === "alarm") {
        newSelLevel.push({ value: item.value, label: t("alarm") });
      }
      else if (item.value === "warning") {
        newSelLevel.push({ value: item.value, label: t("warning") });
      }
      else if (item.value === "info") {
        newSelLevel.push({ value: item.value, label: t("info") });
      }
      else if (item.value === "system Notification") {
        newSelLevel.push({ value: item.value, label: t("systemNotification") });
      }
    }
    setSelLevel(newSelLevel);
    handleSearch();
  }, [i18n.language]);

  useEffect(() => {
    if (machineList?.length > 0 && choiceMachine != null || choiceDate.length > 0) {
      handleSearch();
      localStorage.removeItem("history_startDate");
      localStorage.removeItem("history_endDate");
    }
  }, [machineList, choiceMachine, choiceDate]);

  //#region 設備內容
  useEffect(() => {
    if (authority?.userAccount != null) {
      const fetchData = async () => {
        const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
        if (httpStatusMachine == "200") {
          if (reponseDataMachine.statusCode === "20000") {
            let artMachine = reponseDataMachine.data;
            let newMachineList = [];
            const [httpStatus, reponseData] = await handleApiIdeaLogin();
            if (httpStatus === 200) {
              let ideaMachine = reponseData?.artUserInfo?.owingMachines;
              for (let item of ideaMachine) {
                let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                if (filter) {
                  newMachineList.push(filter);
                }
              }
            }
            else {
              newMachineList = artMachine;
            }

            setMachineList(newMachineList);



            if (cookies.get("choiceMachine")) {
              const encryptedValue = cookies.get("choiceMachine");
              if (encryptedValue) {
                setChoiceMachine(encryptedValue);
              }
            }
            else if (localStorage.getItem("choiceMachine") !== null) {
              setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
            }
            else {
              if (newMachineList.length > 0) {
                localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                setChoiceMachine(newMachineList[0]);
              }
              else {
                localStorage.removeItem("choiceMachine");
                setChoiceMachine(null);
              }
            }
          }
          else if (reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104") {
            overtime();
          }
        }
      };
      fetchData();
    }
  }, [authority]);
  //#endregion

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if (machineListResponse) {
      const httpStatus = machineListResponse.request.status;
      const reponseData = machineListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');

    let ideaLoginResponse = await apiIdeaLogin(formData);

    if (ideaLoginResponse) {
      const httpStatus = ideaLoginResponse.request.status;
      const reponseData = ideaLoginResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  // //#region 下拉多選 Change事件-設備
  // const handleSelChangeEquipment = (e) => {
  //   setSelEquipment(e);
  // };
  // //#endregion

  //#region 下拉多選 Change事件-等級
  const handleSelChangeLevel = (e) => {
    setSelLevel(e);
  };
  //#endregion

  //#region 下拉多選 Change事件-類別
  const handleSelChangeType = (e) => {
    setSelType(e);
  };
  //#endregion

  //#region 篩選按鈕
  const handleFilter = () => {
    setShowFilter2(!showFilter2);
    setSelType([]);
    setChoiceDate([]);
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = async (page) => {
    const [httpStatus, reponseData] = await handleApiEquimentMessage(page);
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setEquipmentMessageList(reponseData.data);
      }
    }

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
    localStorage.setItem("history_keyword", value);
  };
  //#endregion

  //#region 查詢按鈕
  const handleSearch = async () => {
    if (choiceMachine != null) {
      const [httpStatus, reponseData] = await handleApiEquimentMessage(1);
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          setEquipmentMessageList(reponseData.data);
        }
      }
    }
  }
  //#endregion

  //#region 訊息列表API
  const handleApiEquimentMessage = async (page) => {
    let postJson = {
      intLimit: 30,
      intOffset: (page - 1) * 30,
      mid: [choiceMachine?.mid],
      showExclusion: showExclusion
    };
    setCurrentPage(page);

    if (keyword != "") {
      postJson.keyword = keyword;
    }

    if (choiceDate.length > 0) {
      postJson.queryStartDate = choiceDate[0];
      postJson.queryEndDate = choiceDate[1];
    }

    // if(selEquipment.length != 0 && selEquipment.length !== mutiEquipmentSetting.length){
    //   let mid = [];
    //   for(let item of selEquipment){
    //     mid.push(item.value);
    //   }
    //   postJson.mid = mid;
    // }
    // else{
    //   if(selEquipment.length === 0){
    //     let mid = [];
    //     for(let item of mutiEquipmentSetting){
    //       mid.push(item.value);
    //     }
    //     postJson.mid = mid;
    //   }
    // }

    if (selLevel.length != 0 && selLevel.length !== mutiLevelSetting.length) {
      let level = [];
      for (let item of selLevel) {
        level.push(item.value);
      }
      postJson.level = level;
    }

    if (showFilter2) {
      if (selType.length != 0 && selType.length !== mutiTypeSetting.length) {
        let type = [];
        for (let item of selType) {
          type.push(item.value);
        }
        postJson.type = type;
      }
    }

    let response = await apiEquimentMessage(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 機台下拉選單
  const handleDropdownSelect = (obj) => {
    cookies.remove("choiceMachine", { HttpOnly: true, path: "/", domain: domain });
    setChoiceMachine(obj);
    localStorage.setItem("choiceMachine", JSON.stringify(obj));
  };
  //#endregion

  //#region 是否開啟除外事件
  const handleExclusionEvents = () => {
    setShowExclusion(showExclusion === 1 ? 0 : 1);
  }
  //#endregion

  useEffect(() => {
    console.log("choiceDate", choiceDate)
  }, [choiceDate]);

  return (
    <>
      <div className="pageContent">
        <div className='filterRow h-normal'>
          <div>
            {/*選擇設備*/}
            <div className="input-rightpadding">
              <button className="input outline fit-content" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <b>{choiceMachine?.mName}</b>
              </button>
              <div className="icon icon-Dropdown bg-greyA small"></div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  machineList?.map((obj, index) => {
                    return <a className="dropdown-item cursor-pointer"
                      onClick={() => handleDropdownSelect(obj)}
                      key={index}>{obj.mName}</a>;
                  })
                }
              </div>
            </div>
            {/* <div className="dropdown equipmentDiv">
              <button className="btn dropdown-toggle tLeft" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <b>{choiceMachine?.mName}</b>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  machineList?.map((obj, index) => {
                    return <a className="dropdown-item cursor-pointer"
                      onClick={() => handleDropdownSelect(obj)}
                      key={index}>{obj.mName}</a>;
                  })
                }
              </div>
            </div> */}
            {/* <div className='equipmentDiv'>
            <MultiSelect
              key="equipment_id"
              options={mutiEquipmentSetting}
              onChange={handleSelChangeEquipment}
              value={selEquipment}
              isSelectAll={true}
              menuPlacement={"bottom"}
              placeholder="equipment"
            />
            
          </div> */}
            <div className='levelDiv minWidth10'>
              <MultiSelect
                key="level_id"
                options={mutiLevelSetting}
                onChange={handleSelChangeLevel}
                value={selLevel}
                isSelectAll={true}
                menuPlacement={"bottom"}
                placeholder="level"
              />
            </div>
            {/* 
            <div className='searchInputDiv'>
              <input type="search" className='searchInput' placeholder={t("search")}
                value={keyword} onChange={handleInputKeywordChange} />
            </div>
            <button type="button" className="btn btn-lv1 btn-icontext" onClick={handleSearch}>
              <div className="d-flex justify-content-start align-items-center">
                <span className="material-symbols-rounded btn-SearchIcon">search</span>&nbsp;{t("search")}
              </div>
            </button> */}
            <div className="btn-search">
              <input type="search" placeholder={t("search")} aria-label="Search"
                value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                  if (event.key === "Enter") { handleSearch(); }
                }} />
              <button type="button" className="btn btn-onlyicon bounce btn-lv5"
                onClick={handleSearch}>
                <div className="icon icon-Search"></div>
              </button>
            </div>

            <button type="button" className="btn btn-txtBlue-bgLightBlue" onClick={handleFilter}>
              <div className="d-flex justify-content-start align-items-center">
                <div className="icon icon-Filter"></div>
                <div>{t("filter")}</div>
              </div>
            </button>
          </div>
          <div>
            {/*「顯示除外訊息」切換鈕*/}
            <div className='text14 flex-nowrap align-center'>
              {
                showExclusion === 1 ?
                  <><div className='mb-0 text-blue'>{t("exclusionEventsShowing")}</div>&nbsp;&nbsp;</> :
                  <><div className='mb-0 text-grey4'>{t("exclusionEventsHidden")}</div>&nbsp;&nbsp;</>
              }
              <Form.Check
                type="switch"
                id="switchShowExclusion"
                name="switchShowExclusion"
                checked={showExclusion === 1}
                onChange={handleExclusionEvents}
              />
            </div>
            {/*「幫助 Help」按鈕*/}
            <button type="button" className="btn btn-lv3 btn-icontext" onClick={() => setShowModal(true)}>
              <div className="icon icon-Help"></div>
              <div>{t("help")}</div>
            </button>
          </div>
        </div >

        {
          showFilter2 ?
            <div className='filterRow2 filterRow h-normal'>
              <div>
                < div className='typeDiv minWidth10' >
                  <MultiSelect
                    key="type_id"
                    options={mutiTypeSetting}
                    onChange={handleSelChangeType}
                    value={selType}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                    placeholder="type"
                  />
                </div >
                <div className={`width21`}>
                  <DateTimeRangePicker
                    dateValue={choiceDate} setDateValue={setChoiceDate}
                    lang={lang}
                    className={`input h-100`} />
                </div>
              </div>
              <div>
                <button type="button" className="btn btn-lv3 btn-icontext" onClick={handleFilter}>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="icon icon-ClearAll"></div>
                    <div>{t("clearAll")}</div>
                  </div>
                </button>
              </div>
            </div > : null
          // <div className='filterRow2'>
          //   < div className='typeDiv' >
          //     <MultiSelect
          //       key="type_id"
          //       options={mutiTypeSetting}
          //       onChange={handleSelChangeType}
          //       value={selType}
          //       isSelectAll={true}
          //       menuPlacement={"bottom"}
          //       placeholder="type"
          //     />
          //   </div >
          //   <div className='timeDiv'>
          //     <DateTimeRangePicker
          //       dateValue={choiceDate} setDateValue={setChoiceDate}
          //       lang={lang} />
          //   </div>
          //   <div className='clearAllDiv'>
          //     <button type="button" className="btn btn-lv3 btn-icontext h-100" onClick={handleFilter}>
          //       <div className="d-flex justify-content-start align-items-center">
          //         <div className="icon icon-ClearAll"></div>
          //         <div>{t("clearAll")}</div>
          //       </div>
          //     </button>
          //   </div>
          // </div > : null
        }
        <main className="table-wrapper border rwd col4to2 text-keep-normal mt-2">
          <div>
            {/* <div className="table-scrollMessagesData overflow-hidden"> */}

            {
              equipmentMessageList?.totalItems > 0 ?
                <>
                  {/* <div className="table-scrollMessagesData" ref={scrollRef}> */}
                  <div ref={scrollRef}>
                    <div className="table-report table-scroll-justify">
                      <div className="table-minheight2">
                        <table>
                          <thead>
                            <tr className="sticky-top">
                              <th className="min-width8 sticky-left">{t("time")}</th>
                              <th className="min-width8">{t("equipment")}</th>
                              <th className="min-width6">{t("level")}</th>
                              <th className="min-width6">{t("type")}</th>
                              <th className="min-width8">{t("messageCode")}</th>
                              <th className="min-width8">{t("message")}</th>
                              <th className="min-width6">{t("notify")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              equipmentMessageList?.detail.map((obj, index) => {
                                return (
                                  <tr key={index}>
                                    <td className='min-width8'>
                                      {(() => {
                                        const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.timestamp);
                                        return trans.startDate + " " + trans.startTime;
                                      })()}
                                    </td>
                                    <td data-label={t("equipment")} className="min-width8">
                                      {obj.equipmentSerialNumber}
                                    </td>
                                    <td data-label={t("level")} className='min-width6 align-items-center'>
                                      <div className="d-flex">
                                        <div style={{ width: "1rem" }} className="">
                                          {(() => {
                                            let icon = "";
                                            if (obj.messageLevel === "Warning" || obj.messageLevel === "注意") {
                                              icon = <div className="icon icon-Warning warning size-S"></div>;
                                            }
                                            else if (obj.messageLevel === "Alarm" || obj.messageLevel === "警告") {
                                              icon = <div className="icon icon-Alarm alarm size-S"></div>;
                                            }
                                            else if (obj.messageLevel === "System Notification" || obj.messageLevel === "系統提醒") {
                                              icon = <div className="icon icon-SystemNotification SystemNotification size-S"></div>;
                                            }
                                            return icon;
                                          })()}
                                        </div>
                                        <div className='mleft025'>{obj.messageLevel}</div>
                                      </div>
                                    </td>
                                    <td data-label={t("type")} className='min-width6'>
                                      {obj.messageType}
                                    </td>
                                    <td data-label={t("messageCode")} className='min-width8'>
                                      {obj.messageCode}
                                    </td>
                                    <td data-label={t("message")} className="min-width8">
                                      {obj.messageContent}
                                    </td>
                                    <td data-label={t("notify")}>
                                      {
                                        obj.hasNotified ?
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip className="custom-tooltip">
                                                {t("notificationEmailSent")}
                                              </Tooltip>
                                            }
                                          >
                                            <div className='iconEmail'>
                                              <div className="icon icon-Email  cursor-pointer blue size-S">mail</div>
                                            </div>
                                          </OverlayTrigger> : null
                                      }
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>

                :
                <div className="nodata">
                  {/* <!--選取的條件沒有執行紀錄--> */}
                  <div className="notice">{t("noMessagesFound")}</div>
                </div>
            }
          </div>
        </main>
        <div className="d-flex justify-content-center mt-2">
          <MyPagination totalPages={Math.ceil(equipmentMessageList?.totalItems / 30)}
            currentPage={currentPage}
            onPageChange={handlePageChange} />
        </div>
      </div >

      <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("helpCenter")}
            <div className="subtitle">
              {t("messages")}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex mb-2'>
            <div className='helpItem'>
              1
            </div>
            <div className='helpContent fw-bolder'>
              {t("helpmsg1")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg2")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>
              2
            </div>
            <div className='helpContent fw-bolder'>
              {t("helpmsg3")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg4")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg5")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'></div>
            <div className='helpContent'>
              <div className='d-flex'>
                <div className='helpItem'></div>
                <div className='helpContent'>
                  {t("helpmsg6")}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg7")}
            </div>
          </div>
          <div className='d-flex'>
            <div className='helpItem'></div>
            <div className='helpContent'>
              <div className='d-flex'>
                <div className='helpItem'></div>
                <div className='helpContent'>
                  {t("helpmsg8")}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'></div>
            <div className='helpContent'>
              <div className='d-flex'>
                <div className='helpItem'></div>
                <div className='helpContent'>
                  <span dangerouslySetInnerHTML={{
                    __html: t("helpmsg19", {
                      link:
                        authority?.role === "admin" ? `<a href=\"${window.backendUrl}/notificationRecipient\" target=\"_blank\">${t("jumpBackendMessages")}</a>` : t("jumpBackendMessages")
                    })
                  }} />
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>
              3
            </div>
            <div className='helpContent fw-bolder'>
              {t("helpmsg9")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg4")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg10")}
            </div>
          </div>
          <div className='d-flex'>
            <div className='helpItem'></div>
            <div className='helpContent'>
              <div className='d-flex'>
                <div className='helpItem'></div>
                <div className='helpContent'>
                  {t("helpmsg11")}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'></div>
            <div className='helpContent'>
              <div className='d-flex'>
                <div className='helpItem'></div>
                <div className='helpContent'>
                  <span dangerouslySetInnerHTML={{
                    __html: t("helpmsg20", {
                      link:
                        authority?.role === "admin" ? `<a href=\"${window.backendUrl}/notificationRecipient\" target=\"_blank\">${t("jumpBackendMessages")}</a>` : t("jumpBackendMessages")
                    })
                  }} />
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg12")}
            </div>
          </div>
          <div className='d-flex'>
            <div className='helpItem'></div>
            <div className='helpContent'>
              <div className='d-flex'>
                <div className='helpItem'></div>
                <div className='helpContent'>
                  {t("helpmsg13")}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'></div>
            <div className='helpContent'>
              <div className='d-flex'>
                <div className='helpItem'></div>
                <div className='helpContent'>
                  <span dangerouslySetInnerHTML={{
                    __html: t("helpmsg21", {
                      link:
                        authority?.role === "admin" ? `<a href=\"${window.backendUrl}/notificationRecipient\" target=\"_blank\">${t("jumpBackendMessages")}</a>` : t("jumpBackendMessages")
                    })
                  }} />
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>
              4
            </div>
            <div className='helpContent fw-bolder'>
              {t("helpmsg14")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              <span dangerouslySetInnerHTML={{
                __html: t("helpmsg15", {
                  link:
                    authority?.role === "admin" ? `<a href=\"${window.backendUrl}/notificationRecipient\" target=\"_blank\">${t("jumpBackendMessages")}</a>` : t("jumpBackendMessages")
                })
              }} />
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>
              5
            </div>
            <div className='helpContent fw-bolder'>
              {t("helpmsg16")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              {t("helpmsg17")}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='helpItem'>

            </div>
            <div className='helpContent'>
              <span dangerouslySetInnerHTML={{
                __html: t("helpmsg18", {
                  link:
                    authority?.role === "admin" ? `<a href=\"${window.backendUrl}/accountUsers\" target=\"_blank\">${t("jumpBackendAccount")}</a>` : t("jumpBackendAccount")
                })
              }} />
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div>
            <button className='btn btn-lv1 w-100 h38' onClick={handleClose}>
              {t("iunderstand")}
            </button>
          </div>
        </div>
        {/* <Modal.Footer>
          <Row className='mt-4'>
            <Col xs={12} className='d-flex justify-content-end'>
              <button className='button-primary w-100 d-flex align-items-center justify-content-center' onClick={handleClose}>
                {t("iunderstand")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Messages;