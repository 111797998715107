import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './modal.css';
import { MyUserContext } from 'contexts/MyUserContext';

import { apiPowerConsumptionReportDetail, apiPowerConsumptionReport } from 'utils/Api';


const ReportNameModal = ({ showModal, handleClose, reportName, setReportName, reportID, handleLoadData, judgeSaveAll = false }) => {
  const { t } = useTranslation("reports");
  const { overtime } = useContext(MyUserContext);

  const regex = /^[^\/\\\*\?\$\@]+$/;
  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息
  const [checkRepeat, setCheckRepeat] = useState(false); //檢查報告名稱是否重複

  const [tempReportName, setTempReportName] = useState(""); //暫存報告名稱

  useEffect(() => {
    if (showModal) {
      setCheckRepeat(false);

      if (reportID != "0") {
        handleConsumptionReportDetailData();
      }
      else {
        setTempReportName(reportName);
      }

    }
  }, [showModal]);

  //#region 修改報告名稱
  const handleReportNameChange = (e) => {
    setTempReportName(e.target.value);
  };
  //#endregion

  //#region 儲存報告名稱
  const handleSaveReportName = async () => {
    setJudgeCheckErr(true);

    if (tempReportName != "" && tempReportName.length <= 100 && regex.test(tempReportName?.trim())) {

      if (!judgeSaveAll) {
        handleSaveConsumptionReportDetailData();
      }
      else {
        const [httpStatus, reponseData] = await handleLoadData(tempReportName);

        if (reponseData.statusCode === "20100") {
          setReportName(tempReportName);
        }
        else if (reponseData.statusCode === "DL001") {
          setCheckRepeat(true);
        }
      }
    }
  }
  //#endregion

  //#region 載入單一一筆報告
  const handleConsumptionReportDetailData = async () => {
    const [httpStatus, reponseData] = await handleApiConsumptionReportDetailData();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setTempReportName(reponseData.data.reportName);
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    }
  }
  //#endregion

  //#region 載入單一一筆報告API
  const handleApiConsumptionReportDetailData = async () => {
    let postJson = {
      reportID: reportID
    };
    let response = await apiPowerConsumptionReportDetail(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 儲存單一一筆報告
  const handleSaveConsumptionReportDetailData = async () => {
    const [httpStatus, reponseData] = await handleApiSaveConsumptionReportDetailData();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<>
          <span>{t("editingCompletedSaved")}</span>
        </>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setReportName(tempReportName);
        handleClose();
        handleLoadData(tempReportName);
      }
      else if (reponseData.statusCode === "DL001") {
        setCheckRepeat(true);
      }
      else {
        setCheckRepeat(false);
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    }
  }
  //#endregion

  //#region 儲存單一一筆報告API
  const handleApiSaveConsumptionReportDetailData = async () => {
    let postJson = {
      action: "update",
      reportID: reportID,
      reportName: tempReportName
    };
    let response = await apiPowerConsumptionReport(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  return (
    <>
      {/* 編輯報告名稱 */}
      <Modal show={showModal} onHide={handleClose} size="xs" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              reportID === "0" ? t("saveReport") : t("editReportName")
            }
            <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="reportName">
                  <Form.Label className='form-label-custom'>
                    {t("reportName")}
                    <span className='text-danger ms-1'>*</span>
                    <span className='form-textnum ms-1'>({tempReportName.length ?? 0}/100)</span>
                  </Form.Label><br />
                  <Form.Control type="text" autoComplete="off" name="reportName"
                    value={tempReportName} onChange={handleReportNameChange}
                    className={judgeCheckErr && (tempReportName === "" || tempReportName.length > 100 || !regex.test(tempReportName?.trim()) || checkRepeat) ? "input-error mb-2" : "mb-2"}
                  />
                  {
                    judgeCheckErr && tempReportName === "" ?
                      <Form.Label className='text-danger'>{t("requiredFields")}</Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && tempReportName !== "" && tempReportName.length > 100 ?
                      <Form.Label className='text-danger'>{t("exceedsCharacterLimit")}</Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && tempReportName !== "" && tempReportName.length <= 100 && !regex.test(tempReportName?.trim()) ?
                      <Form.Label className='text-danger'>{t("specialCharactersError")}</Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && tempReportName !== "" && tempReportName.length <= 100 && regex.test(tempReportName?.trim()) && checkRepeat ?
                      <Form.Label className='text-danger'>{t("reportNameAlreadyTaken")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
              <button className="btn btn-txt-black" onClick={handleClose}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button className="btn btn-lv1 fat" onClick={handleSaveReportName}>
                {judgeSaveAll && reportID !== "0" ? t("apply") : t("save")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} >
              <button className='button-not-border' onClick={handleClose}>
                {t("cancel")}
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <button className="button-primary" onClick={handleSaveReportName}>
                {judgeSaveAll && reportID !== "0" ? t("apply") : t("save")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
};

export default ReportNameModal;