import React, { useEffect, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import './ReportsMain.css';
import { ToastContainer, toast } from 'react-toastify';
import MyPagination from 'components/basic/Pagination';

import { convertDateFormat, encrypto, formatDateTimeRange } from 'utils/commonFun';

import MessageModal from 'components/basic/MessageModal';
import GenerateReportModal from "page/reports/data/modal/GenerateReportModal"; //選擇報告內容Modal
import ReportNameModal from "page/reports/data/modal/ReportNameModal"; //編輯報告名稱Modal

import { apiPowerConsumptionReportList, apiPowerConsumptionDelReport } from 'utils/Api';

import DateRangePicker from 'components/dateRangePicker/DateRangePicker';

const ReportsMain = () => {
  const { t } = useTranslation("reports");
  const navigate = useNavigate();

  const { lang, showFormatDate, showFormatTime } = useContext(MyUserContext);

  const [currentPage, setCurrentPage] = useState(1);

  // let [dateValue, setDateValue] = useState({
  //   start: parseDate('2024-06-01'),
  //   end: parseDate('2024-06-19')
  // }); //查詢區間
  // let [dateValue, setDateValue] = useState({
  //   start: null,
  //   end: null
  // }); //查詢區間
  let [dateValue, setDateValue] = useState([]); //查詢區間
  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字

  let [reportList, setReportList] = useState(null); //報告列表
  const [currentReportID, setCurrentReportID] = useState(0); //目前選擇的流水號

  const [showDelReportModal, setShowDelReportModal] = useState(false); //是否顯示確定刪除嗎？Modal
  const handleDelReportClose = () => setShowDelReportModal(false);

  const [showReportNameModal, setShowReportNameModal] = useState(false); //是否顯示編輯報告名稱Modal
  const handleReportNameClose = () => setShowReportNameModal(false);

  const [reportName, setReportName] = useState(""); //報告名稱

  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const handleGenerateReportClose = () => setShowGenerateReportModal(false);
  const [content, setContent] = useState("1"); //內容 1=>單次執行比較 2=>程式比較

  const scrollRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("reports_keyword") !== null) {
      setKeyword(localStorage.getItem("reports_keyword"));
    }
    if (localStorage.getItem("report_startDate") !== null && localStorage.getItem("report_endDate") !== null) {
      // setDateValue({
      //   start: parseDate(localStorage.getItem("report_startDate")),
      //   end: parseDate(localStorage.getItem("report_endDate"))
      // });
      setDateValue([localStorage.getItem("report_startDate"), localStorage.getItem("report_endDate")]);
    }
  }, []);

  useEffect(() => {
    handleConsumptionReportListData();
  }, [currentPage]);

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
    localStorage.setItem("reports_keyword", value);
  };
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region 報告列表
  const handleConsumptionReportListData = async () => {
    const [httpStatus, reponseData] = await handleApiConsumptionReportListData();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setReportList(reponseData.data);
      }
    }
  }
  //#endregion

  //#region 報告列表API
  const handleApiConsumptionReportListData = async () => {
    let postJson = {
      queryStartDate: dateValue[0],
      queryEndDate: dateValue[1],
      keyword: keyword,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30
    };

    let response = await apiPowerConsumptionReportList(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 選擇功能
  const handleFun = (behavior, id) => {
    setCurrentReportID(id);
    if (behavior == "editReportName") {
      setShowReportNameModal(true);
    }
    else if (behavior === "delete") {
      setShowDelReportModal(true);
    }
  }
  //#endregion

  //#region 刪除報告
  const handleDelConsumptionReportData = async () => {
    const [httpStatus, reponseData] = await handleApiDelConsumptionReportData();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<>
          <span>{t("deletionCompleted")}</span>
        </>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        handleDelReportClose();
        handleConsumptionReportListData();
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    }
  }
  //#endregion

  //#region 刪除報告API
  const handleApiDelConsumptionReportData = async () => {
    let postJson = {
      reportID: currentReportID
    };
    let response = await apiPowerConsumptionDelReport(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 查詢按鈕
  const handleSearch = () => {
    handleConsumptionReportListData();
  }
  //#endregion

  //#region 跳轉報告名稱
  const handleReportName = (reportID) => {
    navigate(`/Reports/${encrypto(`art_${reportID}`, 123, 25)}`);
  }
  //#endregion

  useEffect(() => {
    if (dateValue.length > 0) {
      localStorage.setItem("report_startDate", dateValue[0]);
      localStorage.setItem("report_endDate", dateValue[1]);
    }
    else {
      localStorage.removeItem("report_startDate");
      localStorage.removeItem("report_endDate");
    }
  }, [dateValue]);

  return (
    <>
      <div className='pageContent'>
        <div className="filterRow">
          <div>
            {/*日期選擇器*/}
            <DateRangePicker dateValue={dateValue} setDateValue={setDateValue} lang={lang} />
            {/*搜尋欄*/}
            <div className="btn-search">
              <input type="search" placeholder={t("search")} aria-label="Search"
                value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                  if (event.key === "Enter") { handleSearch(); }
                }} />
              <button type="button" className="btn btn-onlyicon bounce btn-lv5" onClick={handleSearch}>
                <div className="icon icon-Search"></div>
              </button>
            </div>
          </div>
          <div>
            {/*「產生報告」按鈕*/}
            <button className="btn btn-lv1 btn-icontext" onClick={() => setShowGenerateReportModal(true)}>
              <div className="icon icon-GenerateReport"></div>
              <div>{t("generateReport")}</div>
            </button>
          </div>
        </div>
        <main className="table-wrapper border mt-2 rwd firstColImg text-keep-all">
          {
            reportList?.totalItems > 0 ?
              <div className="table-report table-scroll-justify">
                <div className="table-scrollFolderData" ref={scrollRef}>
                  <table>
                    <thead>
                      <tr className="sticky-top">
                        <th></th>
                        <th className="min-width8 sticky-left">{t("reportName")}</th>
                        <th className="min-width6">{t("type")}</th>
                        <th className="min-width6">{t("date")}</th>
                        <th className="min-width8">{t("referenceProgram")}</th>
                        <th className="min-width8">{t("comparisonProgram")}</th>
                        <th className="min-width6 tCenter">{t("generateDate")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        reportList?.detail.map((obj, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className='imgBlock'>
                                  {
                                    obj.wpImage?.length > 0 ?
                                      <img src={obj.wpImage} alt={obj.wpName} className='w-100' /> : <></>
                                  }
                                </div>
                                {/* {
                                  obj.wpImage != null ?
                                    <img className="img-wp" src={obj.wpImage} alt={obj.wpName} /> :
                                    null
                                } */}
                              </td>
                              <td data-label={t("reportName")} className='min-width8' >
                                <span className='clickTxt' onClick={() => handleReportName(obj.reportID)}>{obj.reportName}</span>
                              </td>
                              <td data-label={t("type")} className="min-width6">
                                {t("powerCarbonComparison")}<br /><span className="text-blue text12">{obj.reportItemID === 1 ? t("singleExecutionComparison") : t("programComparison")}</span>
                              </td>
                              <td data-label={t("date")} className='min-width6'>
                                {(() => {
                                  const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.startTime, obj.endTime);
                                  const startDateTime = obj.reportItemID === 1 ? `${trans.startDate} ${trans.startTime}` : "";
                                  const endDateTime = `${trans.endDate} ${trans.endTime}`;

                                  return (
                                    <>
                                      {startDateTime} ~ {endDateTime}
                                    </>
                                  );
                                })()}
                              </td>
                              <td data-label={t("referenceProgram")} className="min-width8">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <span dangerouslySetInnerHTML={{ __html: `${obj.reportItemID === 1 ? `${convertDateFormat(obj.programList[0]?.startTime)}｜` : ""}${obj.programList[0]?.equipmentSerialNumber}｜${obj.programList[0]?.programName}` }} />
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    {obj.wpNO != null && (
                                      <>
                                        <b className='mb-0'>
                                          {obj.wpNO}&nbsp;{obj.wpName}
                                        </b><br />
                                      </>
                                    )}
                                    {obj.programList[0]?.programName}
                                  </div>
                                  {/* <div>
                                    <b className='mb-0'>
                                      {obj.wpNO}&nbsp;{obj.wpName}
                                    </b><br />
                                    {obj.programList[0]?.programName}
                                  </div> */}
                                </OverlayTrigger>
                              </td>
                              <td data-label={t("comparisonProgram")} className="min-width8">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      {
                                        obj.programList.slice(1).map((item, itemIndex) => {
                                          return (
                                            <div key={itemIndex}>
                                              <span dangerouslySetInnerHTML={{ __html: `${obj.reportItemID === 1 ? `${convertDateFormat(item.startTime)}｜` : ""}${item.equipmentSerialNumber}｜${item.programName}` }} />
                                            </div>
                                          )
                                        })
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    {obj.programList.slice(1).map(item => item.programName).join(', ')}
                                  </div>
                                </OverlayTrigger>
                              </td>
                              <td data-label={t("generateDate")} className="tCenter">
                                {/* {convertDateFormat(obj.generateTime, 'MMM DD, YYYY')} */}
                                {(() => {
                                  const trans = formatDateTimeRange(showFormatDate, null, obj.generateTime);
                                  return trans.startDate;
                                })()}  
                              </td>
                              <td className="tCenter tableFuncBtn">
                                <Dropdown>
                                  <Dropdown.Toggle className="dropdownIcon">
                                    <div className="icon icon-More"></div>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item className='d-flex align-items-center' onClick={() => handleFun("editReportName", obj.reportID)}>
                                      <div className="icon icon-Edit bg-grey8 size-M"></div>&nbsp;&nbsp;
                                      <div className="text-grey4 text14">{t("editReportName")}</div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className='d-flex align-items-center' onClick={() => handleFun("delete", obj.reportID)}>
                                      <div className="icon icon-TrashCan bg-red size-M"></div>&nbsp;&nbsp;
                                      <div className="text-red text14">{t("delete")}</div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div> :
              <div className="nodata">
                {/* <!--選取的條件沒有執行紀錄--> */}
                <div className="notice">{t("noReportsFound")}</div>
              </div>
          }
        </main>

        <div className="d-flex justify-content-center mt-3">
          <MyPagination totalPages={Math.ceil(reportList?.totalItems / 30)} currentPage={currentPage}
            onPageChange={handlePageChange} />
        </div>
      </div>

      {/* 確定刪除嗎？ */}
      <MessageModal
        title={t("confirmDeletion")}
        content={`${t("confirmDeletionContent")}`}
        showModal={showDelReportModal}
        handleConfirmData={handleDelConsumptionReportData}
        size="xs"
        confirmModalTxt={t("delete")}
        cancelTxt={t("cancel")}
        cancelModal={handleDelReportClose}
        handleClose={handleDelReportClose}
      />

      <ReportNameModal
        showModal={showReportNameModal}
        handleClose={handleReportNameClose}
        reportName={reportName}
        setReportName={setReportName}
        reportID={currentReportID}
        handleLoadData={handleConsumptionReportListData}
      />

      <GenerateReportModal
        showModal={showGenerateReportModal}
        handleClose={handleGenerateReportClose}
        content={content}
        setContent={setContent}
      />

      <ToastContainer />
    </>
  );
};

export default ReportsMain;