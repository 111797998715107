import React, {useState, useEffect, useContext, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';

import Tab from 'components/basic/tab';
import IdeaArt from 'page/monitor/ideaArt';

const ideaUrl = window.ideaUrl;

const MonitorMain = () => {
  const { lang, checkLogin, permission } = useContext(MyUserContext);
  const navigate = useNavigate();
  let { tabName } = useParams();

  const [tabList, setTabList] = useState([]);
  const [currentPage, setCurrentPage] = useState(<IdeaArt pageURL={`${ideaUrl}/machine_list.php`} />);

  const [filterPage, setFilterPage] = useState("");
  const [hasRedirected, setHasRedirected] = useState(false);

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "MachineStatus",
        component: <IdeaArt pageURL={`${ideaUrl}/machine_list.php`} />,
        icon: "icon-EquipmentStatus",
        // icon: "airplay",
        active: tabName === "MachineStatus"
      },
      {
        tabName: "ComponentDiagnosis",
        component:<IdeaArt pageURL={`${ideaUrl}/machine_components_diagnosis.php`} />,
        icon: "icon-ComponentDiagnosis",
        // icon: "list_alt",
        active: tabName === "ComponentDiagnosis"
      },
      {
        tabName: "LifetimeEstimation",
        component:<IdeaArt pageURL={`${ideaUrl}/lifetime_estimation.php`} />,
        icon: "icon-LifetimeEstimation",
        // icon: "hourglass_top",
        active: tabName === "LifetimeEstimation"
      },
      {
        tabName: "ConsumableSupplies",
        component:<IdeaArt pageURL={`${ideaUrl}/consumable_supplies_monitor.php`} />,
        icon: "icon-LubricantLevel",
        // icon: "colors",
        active: tabName === "ConsumableSupplies"
      },
      {
        tabName: "PowerConsumption",
        component:<IdeaArt pageURL={`${ideaUrl}/power_consumption_monitor.php`} />,
        icon: "icon-PowerConsumption",
        // icon: "bolt",
        active: tabName === "PowerConsumption"
      },
      {
        tabName: "UtilizationRate",
        component:<IdeaArt pageURL={`${ideaUrl}/utilization.php`} />,
        icon: "icon-RunningTimeline",
        // icon: "view_timeline",
        active: tabName === "UtilizationRate"
      }
    ];

    if(permission !== null){
      let filterMainTab = permission.find(d => d.tabName === "Monitor");
      if(filterMainTab){
        for(let item of filterMainTab.tabList){
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if(tab) newTabList.push(tab);
        }
      }
      else{
        newTabList = defaultTabs;
      }
    }
    else{
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    if(tabList != null){
      let filterAcitve = tabList?.find(d => d.active === true);

      if(filterAcitve){
        setCurrentPage(filterAcitve.component);
      }
  
      let filter = tabList?.find(d => d.active);
      if(filter){
        const newPath = `/Monitor/${filter.tabName}`;
        const fullUrl = `${window.location.origin}${newPath}`;
        
        if (filterPage !== newPath) {
          setFilterPage(newPath);
          navigate(newPath);
          // if (!hasRedirected) {
          //   window.location.href = fullUrl;
          //   setHasRedirected(true);
          // }
        }
      }
    }
  }, [tabList, lang, filterPage, setFilterPage, navigate]);

  // useEffect(() => {
  //   if (filterPage) {
  //     window.location.href = filterPage; // 导航并重新加载页面
  //   }
  // }, [filterPage]);

  return (
    <>
        <div className='sidebarContent'>
          <Tab tabList={tabList} setTabList={setTabList} />
          {currentPage}
        </div>
    </>
  );
};

export default MonitorMain;