import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import "./rawData.css";
import { MyUserContext } from 'contexts/MyUserContext';

import { dayDifference, secondsToHours, timeCalc, isNumber, isFormatHours, isNonNegativeInteger } from 'utils/commonFun';
import MessageModal from 'components/basic/MessageModal';
import {
  apiRawDataDailyShiftStatusDuration, apiRawDataEquipmentRuntime, apiGetMachineOperatingTime,
  apiRawDataEquipmentRuntimePlannedHours, apiRawDataWorkpieceProduction, apiRawDataDefectiveCount, apiRawDataPowerCarbon,
  apiGetWorkHours
} from 'utils/Api';

import FieldInstructions from 'components/basic/FieldInstructions'; //欄位說明

import EquipmentRuntimeEachShift from './data/equipmentRuntimeEachShift'; //各班別的設備運轉時間(table)
import EquipmentRuntime from './data/equipmentRuntime'; //設備運轉時間(table)
import WorkpieceProduction from './data/workpieceProduction'; //工件生產(table)
import PowerCarbon from './data/powerCarbon'; //用電和碳排放(table)

import Tab from 'components/basic/tab';
import Search from './search'; //查詢欄位

const RawDataMain = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  let { tabName } = useParams();
  const { overtime, permission } = useContext(MyUserContext);
  const [judgeFirst, setJudgeFirst] = useState(true);

  const [equipmentRuntimeEachShiftResult, setEquipmentRuntimeEachShiftResult] = useState(""); //查詢結果(各班別的設備運轉時間) 1=>選擇的區間無資料 2=>無效的查詢條件
  const [equipmentRuntimeEachShiftData, setEquipmentRuntimeEachShiftData] = useState(null);  //表格內容(各班別的設備運轉時間)
  const [equipmentRuntimeEachShiftCurrentPage, setEquipmentRuntimeEachShiftCurrentPage] = useState(1); //目前頁碼(各班別的設備運轉時間)

  const [equipmentRuntimeResult, setEquipmentRuntimeResult] = useState(""); //查詢結果(設備運轉) 1=>選擇的區間無資料 2=>無效的查詢條件
  const [equipmentRuntimeData, setEquipmentRuntimeData] = useState(null);  //表格內容(設備運轉)
  const [equipmentRuntimeCurrentPage, setEquipmentRuntimeCurrentPage] = useState(1); //目前頁碼(設備運轉)
  const [equipmentRuntimeUpdateData, setEquipmentRuntimeUpdateData] = useState([]);  //需要異動資料(設備運轉)
  const [equipmentRuntimeTempPage, setEquipmentRuntimeTempPage] = useState("");  //暫存頁碼(設備運轉)

  const [workpieceProductionResult, setWorkpieceProductionResult] = useState(""); //查詢結果(工件生產) 1=>選擇的區間無資料 2=>無效的查詢條件
  const [workpieceProductionData, setWorkpieceProductionData] = useState(null);  //表格內容(工件生產)
  const [workpieceProductionCurrentPage, setWorkpieceProductionCurrentPage] = useState(1); //目前頁碼(工件生產)
  const [workpieceProductionUpdateData, setWorkpieceProductionUpdateData] = useState([]);  //需要異動資料(工件生產)
  const [workpieceProductionTempPage, setWorkpieceProductionTempPage] = useState("");  //暫存頁碼(工件生產)

  const [powerCarbonResult, setPowerCarbonResult] = useState(""); //查詢結果(用電量) 1=>選擇的區間無資料 2=>無效的查詢條件
  const [powerCarbonData, setPowerCarbonData] = useState(null);  //表格內容(用電量)
  const [powerCarbonCurrentPage, setPowerCarbonCurrentPage] = useState(1); //目前頁碼(用電量)

  const [tabList, setTabList] = useState([]);
  const [equipmentRuntimeBehavior, setEquipmentRuntimeBehavior] = useState("edit");
  const [workpieceProductionBehavior, setWorkpieceProductionBehavior] = useState("edit");

  const [showChangeDatesModal, setShowChangeDatesModal] = useState(false); //是否顯示請調整查詢的日期 90天

  // const [selectedDates, setSelectedDates] = useState({ start: "", end: "" }); //選擇日期區間
  const [selectedDates, setSelectedDates] = useState([]); //選擇日期區間
  const [choiceMachine, setChoiceMachine] = useState(null); //目前選擇的設備

  const [showFieldInstructionsModal, setShowFieldInstructionsModal] = useState(false); //是否顯示欄位說明
  const handleFieldInstructionsClose = () => setShowFieldInstructionsModal(false); //關閉欄位說明
  const [fieldInstructionsJSON, setFieldInstructionsJSON] = useState(null); //欄位說明內容

  const [machineOperatingTime, setMachineOperatingTime] = useState(null); //機台運轉時間
  const [machineOperatingCalHours, setMachineOperatingCalHours] = useState(0); //機台運轉時數

  const [showLeavePageModal, setShowLeavePageModal] = useState(false); //是否顯示確定離開嗎？
  const [showChangePageModal, setShowChangePageModal] = useState(false); //是否顯示變更的內容須耗費時間更新

  const [currentClickTabName, setCurrentClickTabName] = useState("");

  const [workHours, setWorkHours] = useState(null); //顯示上班時間

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      //#region 顯示機台運轉時間API
      const [httpStatusMachineOperatingTime, reponseDataMachineOperatingTime] = await handleApiMachineOperatingTime();
      if (httpStatusMachineOperatingTime == "200") {
        if (reponseDataMachineOperatingTime.statusCode === "20000") {
          let data = reponseDataMachineOperatingTime.data;
          setMachineOperatingTime(data);

          let calHours = 0;  //計算總時數
          if (data.choice === 1) {
            if (data?.customizedTimeList) {
              let customizedTime = data?.customizedTimeList;

              for (let time of customizedTime) {
                calHours += parseFloat(secondsToHours(timeCalc(time.startTime, time.endTime)));
              }
            }
          }
          else {
            calHours = 24;
          }

          setMachineOperatingCalHours(calHours);
        }
        else if (reponseDataMachineOperatingTime.statusCode === "40103" || reponseDataMachineOperatingTime.statusCode === "40104") {
          overtime();
        }
      }
      //#endregion

      //#region 抓取上班時間
      const [httpStatusWorkHours, reponseDataWorkHours] = await handleApiGetWorkHours();
      if (httpStatusWorkHours == "200") {
        if (reponseDataWorkHours.statusCode === "20000") {
          setWorkHours(reponseDataWorkHours.data);
        }
      }
      //#endregion
    };
    fetchData();

    // alert(localStorage.getItem("rawData_judgeEdit"))
    if (localStorage.getItem("rawData_judgeEdit") != null) {
      setWorkpieceProductionBehavior("save");
    }
    else {
      setWorkpieceProductionBehavior("edit");
    }
  }, []);
  //#endregion

  //#region 抓取上班時間
  const handleApiGetWorkHours = async () => {
    let workHoursResponse = await apiGetWorkHours();
    if (workHoursResponse) {
      const httpStatus = workHoursResponse.request.status;
      const reponseData = workHoursResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    if (workHours != null) {
      let newTabList = [];
      const defaultTabs = [
        {
          tabName: "EquipmentRuntime",
          active: tabName === "EquipmentRuntime"
        },
        {
          tabName: "WorkpieceProduction",
          active: tabName === "WorkpieceProduction"
        },
        {
          tabName: "PowerCarbon",
          active: tabName === "PowerCarbon"
        }
      ];

      if (workHours.shiftsAnalysis) {
        defaultTabs.push({
          tabName: "EquipmentUtilizationShifts",
          active: tabName === "EquipmentUtilizationShifts"
        });
      }
      else {
        if (location.pathname === "/RawData/EquipmentUtilizationShifts") {
          navigate(`/RawData/EquipmentRuntime`);
        }
      }

      if (permission !== null) {
        let filterMainTab = permission.find(d => d.tabName === "RawData");
        if (filterMainTab) {
          for (let item of filterMainTab.tabList) {
            const tab = defaultTabs.find(tab => tab.tabName === item);
            if (tab) newTabList.push(tab);
          }
        }
        else {
          newTabList = defaultTabs;
        }
      }
      else {
        newTabList = defaultTabs;
      }
      setTabList(newTabList);
    }
  }, [permission, workHours]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if (filter) {
      navigate(`/RawData/${filter.tabName}`);
    }
  }, [tabList]);

  useEffect(() => {
    if (choiceMachine != null && selectedDates.length > 0 && judgeFirst) {
      setJudgeFirst(false);
      handleSearch();
    }
  }, [choiceMachine, selectedDates]);

  useEffect(() => {
    if (choiceMachine != null && selectedDates.startDate != "" && selectedDates.endDate != "") {
      sessionStorage.clear();
      if (tabList?.find(d => d.active)?.tabName === "EquipmentUtilizationShifts") {
        localStorage.removeItem("rawData_judgeEdit");
        setEquipmentRuntimeEachShiftCurrentPage(1);
      }
      else if (tabList?.find(d => d.active)?.tabName === "EquipmentRuntime") {
        localStorage.removeItem("rawData_judgeEdit");
        setEquipmentRuntimeBehavior("edit");
        setEquipmentRuntimeCurrentPage(1);
      }
      else if (tabList?.find(d => d.active)?.tabName === "WorkpieceProduction") {
        setWorkpieceProductionCurrentPage(1);
      }
      else if (tabList?.find(d => d.active)?.tabName === "PowerCarbon") {
        localStorage.removeItem("rawData_judgeEdit");
        setPowerCarbonCurrentPage(1);
      }

      handleSearch();
    }
  }, [tabList]);

  useEffect(() => {
    if (choiceMachine != null && selectedDates.length > 0) {
      sessionStorage.clear();
      handleSearch();
    }
  }, [equipmentRuntimeEachShiftCurrentPage, equipmentRuntimeCurrentPage, workpieceProductionCurrentPage, powerCarbonCurrentPage]);

  useEffect(() => {
    if (currentClickTabName === "WorkpieceProduction") {
      if (localStorage.getItem("rawData_judgeEdit") != null) {
        setWorkpieceProductionBehavior("save");
      }
      else {
        setWorkpieceProductionBehavior("edit");
      }
    }
  }, [currentClickTabName]);

  // useEffect(() => {
  //   if(choiceMachine != null && selectedDates.startDate != "" && selectedDates.endDate != "" && tabList?.find(d => d.active && (d.tabName === "PowerCarbon"))){
  //     sessionStorage.clear();
  //     handleSearch();
  //   }
  // }, [lang]);

  //#region 請調整查詢的日期-我理解了按鈕事件
  const handleChangeDatesClose = () => {
    setShowChangeDatesModal(false);

    if (tabList?.find(d => d.active)?.tabName === "EquipmentUtilizationShifts") {
      setEquipmentRuntimeEachShiftResult("2");
    }
    else if (tabList?.find(d => d.active)?.tabName === "EquipmentRuntime") {
      setEquipmentRuntimeResult("2");
    }
    else if (tabList?.find(d => d.active)?.tabName === "WorkpieceProduction") {
      setWorkpieceProductionResult("2");
    }
    else if (tabList?.find(d => d.active)?.tabName === "PowerCarbon") {
      setPowerCarbonResult("2");
    }
  }
  //#endregion

  //#region 查詢按鈕
  const handleSearch = () => {
    //判斷日期是否超過90天
    if (dayDifference(selectedDates[0], selectedDates[1]) >= 90) {
      setShowChangeDatesModal(true);
    }
    else {
      if (tabList?.find(d => d.active)?.tabName === "EquipmentUtilizationShifts") {
        loadRawDataDailyShiftStatusDuration();
      }
      else if (tabList?.find(d => d.active)?.tabName === "EquipmentRuntime") {
        loadEquipmentRuntime();
      }
      else if (tabList?.find(d => d.active)?.tabName === "WorkpieceProduction") {
        loadWorkpieceProduction();
      }
      else if (tabList?.find(d => d.active)?.tabName === "PowerCarbon") {
        loadRawDataPowerCarbon();
      }
    }
  }
  //#endregion

  //#region 各班別的設備運轉時間API
  const loadRawDataDailyShiftStatusDuration = async () => {
    if (choiceMachine != null && selectedDates.length > 0) {
      const [httpStatus, reponseData] = await handleApiRawDataDailyShiftStatusDuration("json");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.totalItems > 0) {
            setEquipmentRuntimeEachShiftData(reponseData.data);
            setEquipmentRuntimeEachShiftResult("");
          }
          else {
            setEquipmentRuntimeEachShiftData(null);
            setEquipmentRuntimeEachShiftResult("1");
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 各班別的設備運轉時間API
  const handleApiRawDataDailyShiftStatusDuration = async (format) => {
    let postData = {
      startDate: selectedDates[0],
      endDate: selectedDates[1],
      midList: [parseInt(choiceMachine?.mid)],
      intLimit: 30,
      intOffset: (equipmentRuntimeEachShiftCurrentPage - 1) * 30,
      format: format
    };

    let response = await apiRawDataDailyShiftStatusDuration(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 欄位說明
  const handleFieldInstructions = () => {
    setShowFieldInstructionsModal(true);
  }
  //#endregion

  //#region 下載按鈕
  const handleDownload = () => {
    loadDownload();
  }
  //#endregion

  //#region 下載資料
  const loadDownload = async () => {
    if (equipmentRuntimeEachShiftResult === "" && tabList?.find(d => d.active)?.tabName === "EquipmentUtilizationShifts") {
      const [httpStatus, reponseData] = await handleApiRawDataDailyShiftStatusDuration("csv");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.detail.url != null) {
            const url = reponseData.data.detail.url;
            const link = document.createElement('a');
            link.href = url;
            link.download = reponseData.data.detail.url;
            link.click();
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    else if (equipmentRuntimeResult === "" && tabList?.find(d => d.active)?.tabName === "EquipmentRuntime") {
      const [httpStatus, reponseData] = await handleApiGetEquipmentRuntime("csv");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.detail.url != null) {
            const url = reponseData.data.detail.url;
            const link = document.createElement('a');
            link.href = url;
            link.download = reponseData.data.detail.url;
            link.click();
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    else if (equipmentRuntimeResult === "" && tabList?.find(d => d.active)?.tabName === "WorkpieceProduction") {
      const [httpStatus, reponseData] = await handleApiGetWorkpieceProduction("csv");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.detail.url != null) {
            const url = reponseData.data.detail.url;
            const link = document.createElement('a');
            link.href = url;
            link.download = reponseData.data.detail.url;
            link.click();
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    else if (equipmentRuntimeResult === "" && tabList?.find(d => d.active)?.tabName === "PowerCarbon") {
      const [httpStatus, reponseData] = await handleApiRawDataPowerCarbon("csv");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.detail.url != null) {
            const url = reponseData.data.detail.url;
            const link = document.createElement('a');
            link.href = url;
            link.download = reponseData.data.detail.url;
            link.click();
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 設備運轉時間API
  const loadEquipmentRuntime = async () => {
    if (choiceMachine != null && selectedDates.length > 0) {
      const [httpStatus, reponseData] = await handleApiGetEquipmentRuntime("json");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          // console.log("reponseData.data", reponseData.data)
          if (reponseData.data.totalItems > 0) {
            let data = reponseData.data;
            data?.detail.forEach(function (item) {
              item.plannedHours = item.plannedHours.toString();
            });
            setEquipmentRuntimeData(data);
            setEquipmentRuntimeResult("");
          }
          else {
            setEquipmentRuntimeData(null);
            setEquipmentRuntimeResult("1");
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 抓取原始數據內容(設備運轉)
  const handleApiGetEquipmentRuntime = async (format) => {
    const postData = {
      startDate: selectedDates[0],
      endDate: selectedDates[1],
      midList: [choiceMachine.mid],
      intLimit: 30,
      intOffset: (equipmentRuntimeCurrentPage - 1) * 30,
      format: format
    }

    let response = await apiRawDataEquipmentRuntime(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 顯示機台運轉時間API
  const handleApiMachineOperatingTime = async () => {
    let machineOperatingTimeResponse = await apiGetMachineOperatingTime();
    if (machineOperatingTimeResponse) {
      const httpStatus = machineOperatingTimeResponse.request.status;
      const reponseData = machineOperatingTimeResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 工件生產API
  const loadWorkpieceProduction = async () => {
    if (choiceMachine != null && selectedDates.length > 0) {
      const [httpStatus, reponseData] = await handleApiGetWorkpieceProduction("json");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.totalItems > 0) {
            let data = reponseData.data;
            setWorkpieceProductionData(data);
            setWorkpieceProductionResult("");
          }
          else {
            setWorkpieceProductionData(null);
            setWorkpieceProductionResult("1");
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 抓取原始數據內容(工件生產)
  const handleApiGetWorkpieceProduction = async (format) => {
    const postData = {
      startDate: selectedDates[0],
      endDate: selectedDates[1],
      midList: [choiceMachine.mid],
      intLimit: 30,
      intOffset: (workpieceProductionCurrentPage - 1) * 30,
      format: format
    }

    let response = await apiRawDataWorkpieceProduction(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 確定離開嗎？-停留在此頁
  const handleLeavePageCancel = () => {
    setShowLeavePageModal(false);
  }

  //#endregion

  //#region 確定離開嗎？-不儲存就離開
  const handleLeavePageConfirm = () => {
    if (tabList?.find(d => d.active)?.tabName === "EquipmentRuntime") {
      if (equipmentRuntimeTempPage != "") {
        setEquipmentRuntimeCurrentPage(equipmentRuntimeTempPage);
        setEquipmentRuntimeTempPage("");
      }

      loadEquipmentRuntime();
      setEquipmentRuntimeBehavior("edit");
    }
    else if (tabList?.find(d => d.active)?.tabName === "WorkpieceProduction") {
      if (workpieceProductionTempPage != "") {
        setWorkpieceProductionCurrentPage(workpieceProductionTempPage);
        setWorkpieceProductionTempPage("");
      }

      //需要串接API工件生產資料
      setWorkpieceProductionBehavior("edit");
    }

    setShowLeavePageModal(false);
  }
  //#endregion

  //#region 變更的內容須耗費時間更新-停留在此頁
  const handleChangePageCancel = () => {
    setShowChangePageModal(false);
  }
  //#endregion

  //#region 變更的內容須耗費時間更新-確認儲存
  const handleChangePageConfirm = async () => {
    setShowChangePageModal(false);

    if (tabList?.find(d => d.active)?.tabName === "EquipmentRuntime") {
      //只要資料面裡面有一筆有錯，就全部不更新
      let judgeError = false;
      if (equipmentRuntimeData?.detail.length > 0) {
        for (let item of equipmentRuntimeData?.detail) {
          const plannedHours = item.plannedHours;
          if (plannedHours !== "" && (!isNumber(plannedHours) || (parseFloat(plannedHours) < 0 || parseFloat(plannedHours) > 24) || !isFormatHours(plannedHours))) {
            judgeError = true;
          }
        }
      }

      if (!judgeError) {
        const [httpStatus, reponseData] = await handleApiPlannedHoursTime();
        if (httpStatus == "200") {
          if (reponseData.statusCode === "20100") {
            toast(<span>{t("editingCompletedSaved")}</span>, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
        else {
          toast.error(`HTTP Status Code:${httpStatus}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }


        setEquipmentRuntimeBehavior("edit");
      }
    }
    else if (tabList?.find(d => d.active)?.tabName === "WorkpieceProduction") {
      //只要資料面裡面有一筆有錯，就全部不更新  
      let judgeError = false;
      if (workpieceProductionData?.detail.length > 0) {
        for (let item of workpieceProductionData?.detail) {
          const defectiveCount = item.defectiveCount;
          const productionQuantity = item.productionQuantity;
          if (defectiveCount !== "" && (!isNumber(defectiveCount) || !isNonNegativeInteger(defectiveCount) || parseInt(defectiveCount) > productionQuantity)) {
            judgeError = true;
          }
        }
      }

      if (!judgeError) {
        const [httpStatus, reponseData] = await handleApiDefectiveCount();
        if (httpStatus == "200") {
          if (reponseData.statusCode === "20100") {
            toast(<span>{t("editingCompletedSaved")}</span>, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
        else {
          toast.error(`HTTP Status Code:${httpStatus}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }


        setWorkpieceProductionBehavior("edit");
      }

    }
  }
  //#endregion

  //#region 修改原始數據內容(設備運轉)API
  const handleApiPlannedHoursTime = async () => {
    let response = await apiRawDataEquipmentRuntimePlannedHours(equipmentRuntimeUpdateData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 修改原始數據內容(工件生產)API
  const handleApiDefectiveCount = async () => {
    let response = await apiRawDataDefectiveCount(workpieceProductionUpdateData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 用電量
  const loadRawDataPowerCarbon = async () => {
    if (choiceMachine != null && selectedDates.length > 0) {
      const [httpStatus, reponseData] = await handleApiRawDataPowerCarbon("json");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.totalItems > 0) {
            setPowerCarbonData(reponseData.data);
            setPowerCarbonResult("");
          }
          else {
            setPowerCarbonData(null);
            setPowerCarbonResult("1");
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 用電量API
  const handleApiRawDataPowerCarbon = async (format) => {
    let postData = {
      startDate: selectedDates[0],
      endDate: selectedDates[1],
      midList: [parseInt(choiceMachine?.mid)],
      intLimit: 30,
      intOffset: (powerCarbonCurrentPage - 1) * 30,
      format: format
    };

    let response = await apiRawDataPowerCarbon(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  return (
    <>
      <div style={{width: '100%'}}>
        <Tab tabList={tabList} setTabList={setTabList}
          currentClickTabName={currentClickTabName}
          setCurrentClickTabName={setCurrentClickTabName} />
        <div className="pageContent">
          <Search
            showEquipmentRuntimeEachShiftEdit={tabList?.find(d => d.active && (d.tabName === "EquipmentUtilizationShifts"))}
            showEquipmentRuntimeEdit={tabList?.find(d => d.active && (d.tabName === "EquipmentRuntime"))}
            showWorkpieceProductionEdit={tabList?.find(d => d.active && (d.tabName === "WorkpieceProduction"))}
            showPowerCarbonEdit={tabList?.find(d => d.active && (d.tabName === "PowerCarbon"))}
            equipmentRuntimeBehavior={equipmentRuntimeBehavior} //編輯以及儲存行為(設備運轉)
            setEquipmentRuntimeBehavior={setEquipmentRuntimeBehavior} //設定編輯以及儲存行為(設備運轉)
            workpieceProductionBehavior={workpieceProductionBehavior} //編輯以及儲存行為(工件生產)
            setWorkpieceProductionBehavior={setWorkpieceProductionBehavior}  //設定編輯以及儲存行為(工件生產)
            selectedDates={selectedDates} //選擇日期區間
            setSelectedDates={setSelectedDates} //設定選擇日期區間
            choiceMachine={choiceMachine}  //選擇設備
            setChoiceMachine={setChoiceMachine} //設定選擇設備
            handleSearch={handleSearch} //查詢事件
            handleFieldInstructions={handleFieldInstructions} //欄位說明按鈕
            handleDownload={handleDownload}
            setShowLeavePageModal={setShowLeavePageModal}
            setShowChangePageModal={setShowChangePageModal}
            setEquipmentRuntimeUpdateData={setEquipmentRuntimeUpdateData}
            setWorkpieceProductionUpdateData={setWorkpieceProductionUpdateData}
            equipmentRuntimeEachShiftResult={equipmentRuntimeEachShiftResult}
            equipmentRuntimeResult={equipmentRuntimeResult}
            workpieceProductionResult={workpieceProductionResult}
            powerCarbonResult={powerCarbonResult}
          />

          {
            tabList?.find(d => d.active && d.tabName === "EquipmentUtilizationShifts") ?
              <EquipmentRuntimeEachShift tableResult={equipmentRuntimeEachShiftResult}
                showData={equipmentRuntimeEachShiftData} currentPage={equipmentRuntimeEachShiftCurrentPage}
                setCurrentPage={setEquipmentRuntimeEachShiftCurrentPage}
                setFieldInstructionsJSON={setFieldInstructionsJSON}
              /> : null
          }
          {
            tabList?.find(d => d.active && d.tabName === "EquipmentRuntime") ?
              <EquipmentRuntime tableResult={equipmentRuntimeResult} behavior={equipmentRuntimeBehavior}
                showData={equipmentRuntimeData} setShowData={setEquipmentRuntimeData}
                currentPage={equipmentRuntimeCurrentPage}
                setCurrentPage={setEquipmentRuntimeCurrentPage}
                machineOperatingCalHours={machineOperatingCalHours}
                equipmentRuntimeUpdateData={equipmentRuntimeUpdateData}
                setEquipmentRuntimeUpdateData={setEquipmentRuntimeUpdateData}
                choiceMachine={choiceMachine}
                setShowLeavePageModal={setShowLeavePageModal}
                setEquipmentRuntimeTempPage={setEquipmentRuntimeTempPage}
                setFieldInstructionsJSON={setFieldInstructionsJSON}
              /> : null
          }
          {
            tabList?.find(d => d.active && d.tabName === "WorkpieceProduction") ?
              <WorkpieceProduction tableResult={workpieceProductionResult} behavior={workpieceProductionBehavior}
                showData={workpieceProductionData} setShowData={setWorkpieceProductionData}
                currentPage={workpieceProductionCurrentPage}
                setCurrentPage={setWorkpieceProductionCurrentPage}
                workpieceProductionUpdateData={workpieceProductionUpdateData}
                setWorkpieceProductionUpdateData={setWorkpieceProductionUpdateData}
                choiceMachine={choiceMachine}
                setFieldInstructionsJSON={setFieldInstructionsJSON}
              /> : null
          }
          {
            tabList?.find(d => d.active && d.tabName === "PowerCarbon") ?
              <PowerCarbon tableResult={powerCarbonResult}
                showData={powerCarbonData} setShowData={setPowerCarbonData}
                currentPage={powerCarbonCurrentPage}
                setCurrentPage={setPowerCarbonCurrentPage}
                setFieldInstructionsJSON={setFieldInstructionsJSON}
              /> : null
          }
        </div>
      </div>


      {/* 請調整查詢的日期 */}
      <MessageModal
        title={t("changeQueryDates")}
        content={`${t("changeQueryDatesMsg1")}<br /><br />${t("changeQueryDatesMsg2")}`}
        showModal={showChangeDatesModal}
        handleClose={handleChangeDatesClose}
        size="xs"
      />

      {/* 欄位說明 */}
      <FieldInstructions
        showModal={showFieldInstructionsModal}
        handleClose={handleFieldInstructionsClose}
        jsonContent={fieldInstructionsJSON}
        tabList={tabList}
      />

      {/* 確定離開嗎？ */}
      <MessageModal
        title={t("leavePage")}
        content={`${t("leavePageMsg1")}<br /><br />${t("leavePageMsg2")}`}
        showModal={showLeavePageModal}
        handleConfirmData={handleLeavePageConfirm}
        size="xs"
        confirmModalTxt={t("leaveWithoutSaving")}
        cancelTxt={t("stayThisPage")}
        cancelModal={handleLeavePageCancel}
        handleClose={handleLeavePageCancel}
      />

      {/* 變更的內容須耗費時間更新 */}
      <MessageModal
        title={t("changesMayTakeTimeUpdate")}
        content={`${t("changesMayTakeTimeUpdateMsg1")}<br /><br />${t("changesMayTakeTimeUpdateMsg2")}`}
        showModal={showChangePageModal}
        handleConfirmData={handleChangePageConfirm}
        size="xs"
        confirmModalTxt={t("confirmSave")}
        cancelTxt={t("stayThisPage")}
        cancelModal={handleChangePageCancel}
        handleClose={handleChangePageCancel}
      />

      <ToastContainer />
    </>
  );
};

export default RawDataMain;