import React, {useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { getCookieLang, setCookieLang } from 'utils/langUtil';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MyUserContext } from 'contexts/MyUserContext';
import { getCookieLogin, removeCookieLogin } from 'utils/loginUtil';
import { encrypt, decrypt } from 'utils/crypt';

import Header from 'components/basic/Header'; //Header
import SidebarMenu from 'components/basic/SidebarMenu'; //側邊

import { isDifferenceMoreThanFiveMinutes, getCurrentDateTime } from 'utils/commonFun';

import { apiLogout, apiQueryAuthority, apiQuerySettingData, apiJobStatus } from 'utils/Api';

import WebSocketService from 'components/webSocketService/WebSocketService';
import MessageModal from 'components/basic/MessageModal';
import moment from 'moment';
import 'moment/locale/zh-tw';

const Menu = (props) => {
    const websocketUrl = window.websocketUrl;
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation("common");

    const [lang, setLang] = useState("en"); //語系
    const [authority, setAuthority] = useState(null); //權限列表
    const [misc, setMisc] = useState(null); //其他設定

    const [showOverTimeMsg, setShowOverTimeMsg] = useState(false);

    const [permission, setPermission] = useState(null); //頁面權限
    const [periodReportTypeList, setPeriodReportTypeList] = useState([]); //類別列表

    const [wsRefreshJob, setWsRefreshJob] = useState(null);     //Web Socket 用電能耗
    
    const [showRefreshJobModal, setShowRefreshJobModal] = useState(false); //是否顯示確定更新數據嗎？Modal
    const handleRefreshJobClose = () => setShowRefreshJobModal(false);

    const [showSystemBusyModal, setShowSystemBusyModal] = useState(false); //系統忙碌中Modal
    const handleSystemBusyClose = () => setShowSystemBusyModal(false);

    const [jobLastUpdateDate, setJobLastUpdateDate] = useState(""); //Job最後更新時間

    const [randomCode, setRandomCode] = useState("");
    const [judgeFinishJob, setJudgeFinishJob] = useState(false);

    const [judgeLoadToday, setJudgeLoadToday] = useState(false);  //日期區間是否要載入今天

    const [showFormatDate, setShowFormatDate] = useState(""); //顯示日期格式
    const [showFormatTime, setShowFormatTime] = useState(""); //顯示時間格式

    useEffect(() => {
      if(location.state != null){
          setAuthority(location.state);
      }
      else{
          handleAuthority();
      }

      setLang(getCookieLang() ?? "en");
      checkPeriodReportType();
      setRandomCode(getCurrentDateTime());

      moment.locale(lang); //設定日曆套件語系

      if(localStorage.getItem("showFormatDate") === null){
        localStorage.setItem("showFormatDate", "MMM DD, YYYY");
        setShowFormatDate("MMM DD, YYYY");
      }
      else{
        setShowFormatDate(localStorage.getItem("showFormatDate"));
      }

      if(localStorage.getItem("showFormatTime") === null){
        localStorage.setItem("showFormatTime", "hh:mm:ss A");
        setShowFormatTime("hh:mm:ss A");
      }
      else{
        setShowFormatTime(localStorage.getItem("showFormatTime"));
      }
    }, []);

    useEffect(() => {
        if(authority != null){
          if(authority.clientPages != null){
              if (!localStorage.getItem('permission')){
                  setPermission(authority.clientPages);
                  localStorage.setItem('permission', JSON.stringify(authority.clientPages));
                  // fetch(`/json/permission.json?date=${getNowDateTime()}`)
                  // .then(response => response.json())
                  // .then(data => {
                  //     setPermission(data);
                  //     localStorage.setItem('permission', JSON.stringify(data));
                  // })
                  // .catch(error => {
                  //     setPermission(null);
                  // });
              }
              else{
                  let localVal = JSON.parse(localStorage.getItem('permission'));
                  setPermission(localVal);
              }
          }
          else{
              alert("權限設定檔尚未設定，請通知管理員!");
              logInAgain();
          }

          if(localStorage.getItem("runJob") != null){
            loadRefreshJob();
          }
          else{
            checkJobStatus();
          }
        }
    }, [authority]);

    //#region 載入權限
    const handleAuthority = async () => {
        const [httpStatusUser, reponseDataUser] = await handleApiAuthority();
        if(httpStatusUser == "200"){
            if(reponseDataUser.statusCode === "20000"){
                setAuthority(reponseDataUser.data);
            }
            else if(reponseDataUser.statusCode === "40103"){
                overtime();
            }
            else{
                setAuthority(null);
                toast.error(reponseDataUser.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            }
        }
        else{
            setAuthority(null);
            toast.error(reponseDataUser.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    //#endregion

    //#region 權限API
    const handleApiAuthority = async () => {
        let postData = {};

        let settingListResponse = await apiQueryAuthority(postData);
        if(settingListResponse){
            const httpStatus = settingListResponse.request.status;
            const reponseData = settingListResponse.data;

            return [httpStatus, reponseData];
        }
        else{
            return ["500", ""]
        }
    }
    //#endregion

    //#region 登出
    const handleLogOut = async () => {
        const [httpStatus, reponseData] = await handleApiLogOut();
        if(httpStatus == "200"){
            if(reponseData.statusCode === "20000"){
                removeCookieLogin();
                navigate("/");

                localStorage.clear();
            }
            else{
                toast.error(reponseData.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            }
        }
        else{
            toast.error(reponseData.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    //#endregion

    //#region 登出API
    const handleApiLogOut = async () => {
      let settingListResponse = await apiLogout();
      if(settingListResponse){
          const httpStatus = settingListResponse.request.status;
          const reponseData = settingListResponse.data;

          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 切換語系
    const handleChangeLang = async (selectedLang) => {
      setCookieLang(selectedLang);
      setLang(selectedLang);
      checkPeriodReportType();

      moment.locale(selectedLang);  //設定日曆套件語系
    }
    //#endregion

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    const checkLogin = async () => {
      if(getCookieLogin() === null){
        removeCookieLogin();
        navigate("/");
      }
    }

    //#region token過期無效登出訊息
    const overtime = async () => {
      if(getCookieLogin()){
        setShowOverTimeMsg(true);
      }
      else{
        removeCookieLogin();
        navigate("/");
      }
    }
    //#endregion

    //#region 登出
    const logInAgain = async () => {
      removeCookieLogin();
      navigate("/");

      localStorage.clear();
    }
    //#endregion

    //#region 載入類別
    const checkPeriodReportType = async (e) => {
      const [httpStatus, reponseData] = await handleApiSettingList("periodReportType");
      if(httpStatus == "200"){
        if(reponseData.statusCode === "20000"){
          if(reponseData.data.length > 0){
            setPeriodReportTypeList(JSON.parse(reponseData.data[0].value));
          }
        }
      }
    }
    //#endregion

    //#region 設定API
    const handleApiSettingList = async (type) => {
      let postData = {
        type: type
      };

      let settingListResponse = await apiQuerySettingData(postData);
      if(settingListResponse){
        const httpStatus = settingListResponse.request.status;
        const reponseData = settingListResponse.data;

        return [httpStatus, reponseData];
      }
      else{
        return ["500", ""]
      }
    }
    //#endregion

    //#region 跳出確定更新數據
    const openRefreshJob = async(onlyJudgeBusy = false) => {
      const [jobStatus, executing] = await checkJobStatus();

      if(onlyJudgeBusy){
        if(jobStatus === "notRun"){
          //系統忙碌中
          setShowSystemBusyModal(true);
        }
        else{
          setShowRefreshJobModal(true);
        }
      }
    }
    //#endregion

    //#region 檢查Job狀態
    const checkJobStatus = async () => {
      let result = "error";
      let executing = 0;
      let lastUpdateDate = "";
      //先取得Job狀態如果是工作中就不用跑job  
      const [httpStatus, reponseData] = await handleApiJobStatus();
      if(httpStatus == "200"){
        if(reponseData.statusCode === "20000"){
          let data = reponseData.data;
          executing = data.executing;
          lastUpdateDate = data.lastUpdateDate;
          setJobLastUpdateDate(lastUpdateDate);

          if(isDifferenceMoreThanFiveMinutes(lastUpdateDate, new Date())){
            result = "run";
          }
          else{
            result = "notRun";
          }

          if(localStorage.getItem("runJob") != null){
            result = "unconditionally";
          }

          let isAutoTriggered = data.isAutoTriggered;
          if(!isAutoTriggered){
            if(moment(lastUpdateDate, "YYYY-MM-DD").isSame(moment(new Date(), "YYYY-MM-DD"), 'day')){
              setJudgeLoadToday(true);
            }
          }
        }
        else{
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
      else{
        toast.error(httpStatus, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      return [result, executing];
    }
    //#endregion

    //#region 呼叫用電能耗Job
    const loadRefreshJob = async () => {
      setShowRefreshJobModal(false);
      const [jobStatus, executing] = await checkJobStatus();

      if(jobStatus === "notRun"){
        //系統忙碌中
        setShowSystemBusyModal(true);
      }
      else if(jobStatus === "run"){
        localStorage.setItem("runJob", true);

        let initId = `${encrypt(authority?.businessAccountList[0]?.accountID)}:${encrypt(authority?.id)}:${randomCode}`;
        const socketURL = `${websocketUrl}/api/utilizationRate/ws/${initId}`;
        const service = new WebSocketService(socketURL);
        service.connect();
        service.sendMessage(executing === 0 ? "run_job" : "");
        service.socket.onmessage = handleMessage;
        setWsRefreshJob(service);
      }
      else if(jobStatus === "unconditionally"){
        let initId = `${encrypt(authority?.businessAccountList[0]?.accountID)}:${encrypt(authority?.id)}:${randomCode}`;
        const socketURL = `${websocketUrl}/api/utilizationRate/ws/${initId}`;
        const service = new WebSocketService(socketURL);
        service.connect();
        service.socket.onmessage = handleMessage;
        setWsRefreshJob(service);
      }
    }
    //#endregion

    //#region 取得 Job 狀態
    const handleApiJobStatus = async () => {
      let settingListResponse = await apiJobStatus();
      if(settingListResponse){
        const httpStatus = settingListResponse.request.status;
        const reponseData = settingListResponse.data;

        return [httpStatus, reponseData];
      }
      else{
        return ["500", ""]
      }
  }
  //#endregion

    //#region 回應消息
    const handleMessage = (event) => {
      const message = event.data;
      const parseMsg = JSON.parse(message);

      if(parseMsg.statusCode === "20100"){
         //成功之後就關閉連線
        toast(t("analyticsDataUpdatedSuccessfully"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        localStorage.removeItem("runJob");

        if(wsRefreshJob){
          wsRefreshJob.close();
        }

        setJudgeLoadToday(true);
        setJobLastUpdateDate(parseMsg.data);

        setTimeout(function() {
          navigate(0);
        }, 3000);
      }

      setJudgeFinishJob(true);
    };
    //#endregion

    return(
        <>
          <MyUserContext.Provider value={useMemo(() => ({
              lang: lang,
              authority: authority,
              overtime: overtime,
              checkLogin: checkLogin,
              misc: misc,
              permission: permission,
              periodReportTypeList: periodReportTypeList,
              loadRefreshJob: openRefreshJob,
              jobLastUpdateDate: jobLastUpdateDate,
              judgeLoadToday: judgeLoadToday,
              showFormatDate: showFormatDate,
              showFormatTime: showFormatTime
          }), [
              lang,
              authority,
              overtime,
              checkLogin,
              misc,
              permission,
              periodReportTypeList,
              openRefreshJob,
              jobLastUpdateDate,
              judgeLoadToday,
              showFormatDate,
              showFormatTime
          ])}>
            <Header handleLogOut={handleLogOut} 
              lang={lang} handleChangeLang={handleChangeLang} 
              misc={misc} setMisc={setMisc}
              showFormatDate={showFormatDate} setShowFormatDate={setShowFormatDate}
              showFormatTime={showFormatTime} setShowFormatTime={setShowFormatTime}
            />
            <SidebarMenu />
            <div className='sidebarContent'>
                {props.children}
            </div>

            <ToastContainer />
          </MyUserContext.Provider>

          <div className={`fade modal-backdrop ${showOverTimeMsg ? "show" : "d-none"}`}></div>
          <div className={`token-circle-div ${!showOverTimeMsg ? "d-none" : ""}`}>
            <div className="pwd-container">
              <div className='message-board'>
                <p className='message-board-title mb-0'>
                    <b>{t("connectionExpired")}</b>
                </p>
                <p className='message-board-content'>
                {t("connectionExpiredMsg1")}<br />
                {t("connectionExpiredMsg2")}<br />
                </p>
                <input type="button" className='btn btn-lv1 w-100' value={t("logInAgain")} onClick={logInAgain} />
              </div>
            </div>
          </div>

          {/* 確定更新數據嗎？ */}
          <MessageModal 
            title={t("confirmDataRefresh")}
            content={`${t("refreshInformation1")}<br />${t("refreshInformation2")}<br /><br />${t("refreshInformation3")}`}
            showModal={showRefreshJobModal} 
            handleConfirmData={loadRefreshJob}
            size="xs"
            confirmModalTxt={t("refresh")}
            cancelTxt={t("cancel")}
            cancelModal={handleRefreshJobClose}
            handleClose={handleRefreshJobClose}
          />

          {/* 系統忙碌中 */}
          <MessageModal 
            title={t("systemBusy")}
            content={`${t("systemBusy1")}`}
            showModal={showSystemBusyModal} 
            handleConfirmData={handleSystemBusyClose}
            size="xs"
            confirmModalTxt={t("back")}
            handleClose={handleSystemBusyClose}
          />
        </>
    );
}

export default Menu;