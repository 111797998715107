import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Modal } from 'react-bootstrap';
import { apiSingleProcess } from 'utils/Api';
import { formatTimeDays} from 'utils/commonFun';
import { ToastContainer, toast } from 'react-toastify';
import '../APS-modal-viewProcess.css';

import Process from "page/apps/data/aps/modal/Process"; //工序
import VewAllProcess from "page/apps/data/aps/modal/VewAllProcess"; //全部工序

const Detail = ({showModal, handleClose, processID, loadProcessList}) => {
  const { t, i18n } = useTranslation("aps");  
  const { overtime, authority } = useContext(MyUserContext);

  const [processDetail, setProcessDetail] = useState(null);
  const [wpID, setWpID] = useState(0); //工件流水號
  const [wpNO, setWpNO] = useState(0); //工件編號
  const [wpName, setWpName] = useState(0); //工件名稱

  const [showProcessDataModal, setShowProcessDataModal] = useState(false); //是否顯示工序Modal
  const handleProcessDataClose = () => {
    setShowProcessDataModal(false);
  }

  const [showVewAllProcessModal, setShowVewAllProcessModal] = useState(false); //是否顯示全部工序Modal
  const handleVewAllProcessClose = () => setShowVewAllProcessModal(false);

  useEffect(() => {
    if(showModal){
      handleSingleProcess();
    }
  }, [showModal]);


  //#region 載入單一一筆工序
  const handleSingleProcess = async () => {
    const [httpStatus, reponseData] = await handleApiSingleProcess();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        let data = reponseData.data;
        setProcessDetail(data);

        setWpID(data.wpID);
        setWpNO(data.wpNO);
        setWpName(data.wpName);
      }
    }
  }
  //#endregion

  //#region 載入單一一筆工序API
  const handleApiSingleProcess = async () => {
    let postJson = {
      processID: processID
    };
    let response = await apiSingleProcess(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 全部工序
  const handleViewAllProcesses = () => {
    handleClose();
    setShowVewAllProcessModal(true);
  }
  //#endregion

  //#regin 編輯
  const handleEdit = () => {
    handleClose();
    setShowProcessDataModal(true);
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{t("details")}</div>
          </div>
          <button type="button" className="close" 
            data-dismiss="modal" aria-label="Close" 
            onClick={handleClose}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          <div className="modal-view">
          <div className="gp">
            <ul className="gp-greyline">
              <li className="lv1 side2">
                <div className="name">{t("part")}</div>
                <div className="content">{processDetail?.wpNO} {processDetail?.wpName}</div>
              </li>
              <li className="lv1 side2">
                <div className="name">{t("process")}</div>
                <div className="content">{processDetail?.processNo} {processDetail?.processName}</div>
              </li>
            </ul>
          </div>
            <div className="gp">
              <h2>{t("equipmentPrograms")}</h2>
              {
                processDetail?.equipmentAndPrograms.length > 0 ?
                processDetail?.equipmentAndPrograms?.map((obj, index) => {
                  return <ul className="gp-greyline" key={index}>
                    <li className="lv1 side2">
                      <div className="name">{t("equipment")}</div>
                      <div className="content">
                        {
                          obj?.equipment?.map((e, eIndex) => {
                            return (
                              <React.Fragment key={eIndex}>
                                {e.mShortName}<br />
                              </React.Fragment>
                            );
                          })
                        }
                      </div>
                    </li>
                    <li className="lv1 side2">
                      <div className="name">{t("program")}</div>
                      <div className="content">{obj.program.programName}</div>
                    </li>
                    <hr />
                    <li className="lv2 side2">
                      <div className="name">{t("batchProductionQuantity")}</div>
                      <div className="content">{obj.program.batchProductionQuantity}</div>
                    </li>
                    <li className="lv2 side2">
                      <div className="name">{t("duration")}</div>
                      <div className="content">{formatTimeDays(obj.program.programDuration, t)}</div>
                    </li>
                    {
                      obj.program.programDescription !== null ?
                      <li className="lv2 side2">
                        <div className="name">{t("programDescription")}</div>
                        <div className="content">{obj.program.programDescription}</div>
                      </li> : null
                    }
                </ul>
                }): 
                <ul className="gp-greyline">
                  <li className="lv1 side2">
                    <div className="name">{t("equipment")}</div>
                    <div className="content noAssociatedData">{t("noAssociatedData")}</div>
                  </li>
                  <li className="lv1 side2">
                    <div className="name">{t("program")}</div>
                    <div className="content noAssociatedData">{t("noAssociatedData")}</div>
                  </li>
                </ul>
              }
            </div>
            {
              processDetail?.remark != null ?
              <div className="gp">
                <h2>{t("remark")}</h2>
                <ul className="gp-greyline">
                  <li className="lv2 side1">
                    <div className="content">{processDetail?.remark}</div>
                  </li>
                </ul>
              </div> : null
            }
        </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-gp">
            <div>
              <button className="btn btn-icontext-rwd btn-lv3" onClick={handleViewAllProcesses}>
                <div className="icon icon-ViewAllProcess"></div>
                <span>{t("viewAllProcesses")}</span>
              </button>
              <button className="btn btn-icontext-rwd btn-lv3" onClick={handleEdit}>
                <div className="icon icon-Edit"></div>
                <span>{t("edit")}</span>
              </button>
            </div>
            <button type="submit" className="btn btn-lv1 fat" onClick={handleClose}>{t("cancel")}</button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

      <Process 
        showModal={showProcessDataModal}
        handleClose={handleProcessDataClose}
        behavior="update"
        loadProcessList={loadProcessList}
        processID={processID}
      />

      <VewAllProcess 
        showModal={showVewAllProcessModal}
        handleClose={handleVewAllProcessClose}
        wpID={wpID}
        wpNO={wpNO}
        wpName={wpName}
      />

    </>
  );
};

export default Detail;