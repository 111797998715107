import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { apiWorkpieceList, apiCreatedProcessList, apiCheckProcess, apiMachineListData, 
  apiIdeaLogin, apiProgramList, apiSingleLoadProgram, apiAddProcess, apiSingleProcess,
  apiEditProcess
 } from 'utils/Api';
import { isNumber, isPositiveInteger, formatTimeDays} from 'utils/commonFun';
import MultiSelect, { Option } from "components/multiSelect/MultiSelect";
import { ToastContainer, toast } from 'react-toastify';
import MessageModal from 'components/basic/MessageModal';

const Process = ({showModal, handleClose, behavior, loadProcessList, processID}) => {
  const { t, i18n } = useTranslation("aps");  
  const { overtime, authority } = useContext(MyUserContext);

  const navigate = useNavigate();

  // const [workpieceList, setWorkpieceList] = useState(null); //下拉所有選單工件列表
  const [workpieceSetting, setWorkpieceSetting] = useState([]); //工件設定檔
  // const [programList, setProgramList] = useState(null); //下拉所有選單加工程式列表
  const [programSetting, setProgramSetting] = useState(null); //加工程式列表設定檔

  const [selProgram, setSelProgram] = useState(null);  //使用者選擇的工件(暫時)

  const [mutiEquipmentSetting, setMutiEquipmentSetting] = useState([]); //設備設定檔
  const [selEquipment, setSelEquipment] = useState([]);  //使用者選擇的設備

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  const [selPart, setSelPart] = useState(null);  //使用者選擇的工件
  const [createdProcesses, setCreatedProcesses] = useState([]);  //已建立的工序
  const [processNo, setProcessNo] = useState(""); //工序號
  const [checkProcessIsUsed, setCheckProcessIsUsed] = useState(false); //工序號是否被使用過
  const [processName, setProcessName] = useState(""); //工序名稱
  const [initProcessName, setInitProcessName] = useState(""); //工序名稱(初始)
  const [equipmentPrograms, setEquipmentPrograms] = useState([]); //設備以及程式
  const [initEquipmentPrograms, setInitEquipmentPrograms] = useState([]); //設備以及程式(初始)
  const [remark, setRemark] = useState(""); //備註
  const [initRemark, setInitRemark] = useState(""); //備註(初始)

  const [showLeavePageModal, setShowLeavePageModal] = useState(false); //是否顯示確定離開嗎？Modal
  const [isSaving, setIsSaving] = useState(false);
  const handleLeavePageClose = () => setShowLeavePageModal(false);  

  //#region 下拉多選樣式
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
      borderColor: judgeCheckErr && selPart === null ? '#F66F6F' : '#CCC',
      '&:hover': {
        borderColor: judgeCheckErr && selPart === null ? '#F66F6F' : '#CCC', // 滑鼠懸停時邊框顏色
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : provided.backgroundColor,
      color: state.isSelected ? 'black' : provided.color,
    }),
  };

  const customStylesProgram = (isError) => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
      borderColor: isError ? '#F66F6F' : '#CCC',
      '&:hover': {
        borderColor: isError ? '#F66F6F' : '#CCC', // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : provided.backgroundColor,
      color: state.isSelected ? 'black' : provided.color,
    }),
  });
  //#endregion

  useEffect(() => {
    if(showModal){
      setJudgeCheckErr(false);
      loadWorkpieceList(); //載入下拉選單工件
      loadProgramList();  //載入加工程式
      setIsSaving(false);

      if(behavior === "insert"){
        setSelPart(null);
        setCreatedProcesses([]);
        setProcessNo("");
        setProcessName("");
        setCheckProcessIsUsed(false);
        
        setEquipmentPrograms([
          {
            selMids: [],
            selProgram: null,
            batchProductionQuantity: 0,
            duration: 0,
            programDescription: "",
            initProgram: 0,
            action: "insert"
          }
        ]);

        setRemark("");        
      }
      else{
        handleSingleProcess();
      }      
    }
  }, [showModal]);

  //#region 載入工件列表
  const loadWorkpieceList = async () => {
    const [httpStatus, reponseData] = await handleApiWorkpieceList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data.detail;
        
        const newWorkpiece = data.map(item => ({
          value: item.wpID,
          label: item.wpNO + " " + item.wpName
        }));
        setWorkpieceSetting(newWorkpiece);
      }
      else if(reponseData.statusCode === "40103"){
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = {};
    let programListResponse = await apiWorkpieceList(postJson);
    if(programListResponse){
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 下拉多選 Change事件
  const handleSelChange = async (e, name, index) => {
    if(name === "part"){
      setSelPart(e);

      if(e){
        GetCreatedProcessList(e.value);
      }
      else{
        setCreatedProcesses([]);
      }
    }
    else if(name === "program"){
      let result = {
        batchProductionQuantity: 0,
        duration: 0,
        programDescription: ""
      };
      if(e){
        //要抓取加工程式詳細資料
        result = await loadSingleProgram(e.value);
      }

      setEquipmentPrograms((prevPrograms) =>
        prevPrograms.map((program, i) =>
          i === index ? { 
            ...program, 
            selProgram: e,
            batchProductionQuantity: result.batchProductionQuantity,
            duration: result.duration,
            programDescription: result.programDescription
          } : program
        )
      );
    }
  };
  //#endregion

  //#region 載入單一筆加工程式相關資訊
  const loadSingleProgram = async (programID) => {
    let result = {
      batchProductionQuantity: 0,
      duration: 0,
      programDescription: ""
    };
    const [httpStatus, reponseData] = await handleApiSingleLoadProgram(programID);
    
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        let data = reponseData.data;
        result.batchProductionQuantity = data.batchProductionQuantity;
        result.duration = (data.programExecutionDuration === null ? 0 : data.programExecutionDuration) +
        (data.waitingDuration === null ? 0 : data.waitingDuration);
        result.programDescription = data.programDescription;
      }
    }

    return result;
  }
  //#endregion

  //#region 載入單一一筆加工程式
  const handleApiSingleLoadProgram = async (selectId) => {
    let response = await apiSingleLoadProgram(selectId, {});

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 載入單一一筆工序
  const handleSingleProcess = async () => {
    const [httpStatus, reponseData] = await handleApiSingleProcess();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        let data = reponseData.data;
        setSelPart({value: data.wpID, label: `${data.wpNO} ${data.wpName}`});
        setCreatedProcesses([]);
        setProcessNo(data.processNo);
        setProcessName(data.processName);
        setInitProcessName(data.processName);
        setCheckProcessIsUsed(false);

        let newEquipmentPrograms = [];
        for(let item of data.equipmentAndPrograms){
          let newSelMids = [];
          for(let e of item.equipment){
            newSelMids.push({value: e.mid, label: e.mShortName});
          }

          newEquipmentPrograms.push({
            selMids: newSelMids,
            selProgram: {value: item.program.programID, label: item.program.programName},
            batchProductionQuantity: item.program.batchProductionQuantity,
            duration: item.program.programDuration,
            programDescription: item.program.programDescription,
            initProgram: item.program.programID,
            action: "update"
          });
        }
        
        setEquipmentPrograms(newEquipmentPrograms);
        setInitEquipmentPrograms(newEquipmentPrograms);
        setRemark(data.remark);
        setInitRemark(data.remark);
      }
    }
  }
  //#endregion

  //#region 載入單一一筆工序API
  const handleApiSingleProcess = async () => {
    let postJson = {
      processID: processID
    };
    let response = await apiSingleProcess(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion
  
  useEffect(() => {
    if(authority?.userAccount != null){
        const fetchData = async () => {
          const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
          if(httpStatusMachine == "200"){
            if(reponseDataMachine.statusCode === "20000"){
              let artMachine = reponseDataMachine.data;
              let newMachineList = [];
              const [httpStatus, reponseData] = await handleApiIdeaLogin();
              if(httpStatus === 200){
                let ideaMachine = reponseData?.artUserInfo?.owingMachines;
                for(let item of ideaMachine){
                  let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                  if(filter){
                    newMachineList.push({
                      value: filter.mid,
                      label: filter.mShortName
                    });
                  }
                }
              }
              else{
                for(let item of artMachine){
                  newMachineList.push({
                    value: item.mid,
                    label: item.mShortName
                  });
                }
              }
              
              setMutiEquipmentSetting(newMachineList);
            }
          }
      };
      fetchData();
    }
  }, [authority]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if(machineListResponse){
      const httpStatus = machineListResponse.request.status;
      const reponseData = machineListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');
    
    let ideaLoginResponse = await apiIdeaLogin(formData);
    
    if(ideaLoginResponse){
        const httpStatus = ideaLoginResponse.request.status;
        const reponseData = ideaLoginResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 載入加工程式列表
  const loadProgramList = async () => {
    const [httpStatus, reponseData] = await handleApiProgramList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        let data = reponseData.data.detail;

        const newProgram = data.map(item => ({
          value: item.programID,
          label: item.programName
        }));
        setProgramSetting(newProgram);
      }
    }
  }
  //#endregion

  //#region API抓取加工程式列表
  const handleApiProgramList = async () => {
    let postJson = {}
    let programListResponse = await apiProgramList(postJson);
    if(programListResponse){
        const httpStatus = programListResponse.request.status;
        const reponseData = programListResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 取得該工件已建立工序
  const GetCreatedProcessList = async (wpID) => {
    const [httpStatus, reponseData] = await handleApiCreatedProcessList(wpID);
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        setCreatedProcesses(reponseData.data);
      }
      else if(reponseData.statusCode === "40103"){
        overtime();
      }
    }
  }
  //#endregion

  //#region API取得該工件已建立工序
  const handleApiCreatedProcessList = async (wpID) => {
    let postJson = {
      wpID: wpID
    };
    let programListResponse = await apiCreatedProcessList(postJson);
    if(programListResponse){
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "processNo") {
      setProcessNo(value);
    } 
    else if (name === "processName") {
      setProcessName(value);
    }
    else if(name === "remark"){
      setRemark(value);
    }
  };
  //#endregion

  //#region 檢查工序號是否已經被使用
  const handleCheckRepeat = async() => {
    let result = false;
    if(selPart != null){
      const [httpStatus, reponseData] = await handleApiCheckProcess();
      if(httpStatus == "200"){
        if(reponseData.statusCode === "20000"){
          setCheckProcessIsUsed(reponseData.data.used);
          result = reponseData.data.used;
        }
      }
    }
    else{
      setCheckProcessIsUsed(false);
    }

    return result;
  }
  //#endregion

  //#region API檢查工序號是否已經被使用
  const handleApiCheckProcess = async () => {
    let postJson = {
      processNo: processNo,
      wpID: selPart.value
    };
    let response = await apiCheckProcess(postJson);
    if(response){
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 儲存按鈕
  const handleSave = async () => {
    setIsSaving(true);
    setJudgeCheckErr(true);

    //檢查欄位是否有問題
    let check = checkCol();
    if(!check.result){
      if(behavior === "insert"){
        if(handleCheckRepeat()){
          let postJson = {
            wpID: selPart.value,
            processNo: processNo,
            processName: processName,
            equipmentAndPrograms: check.equipmentAndPrograms
          };

          if(remark != ""){
            postJson.remark = remark;
          }
          const [httpStatus, reponseData] = await handleApiAddProcess(postJson);

          if(httpStatus == "200"){
            if(reponseData.statusCode === "20100"){              
              toast(<span>{t("createdSuccessfully")}</span>, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });              
              loadProcessList();              
              handleClose();
            }
            else{
              toast.error(reponseData.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        }
      }
      else{
        let postJson = {
          processID: processID,
          processName: processName,
          editedPrograms: check.editedPrograms,
          equipmentAndPrograms: check.equipmentAndPrograms
        };
        if(remark != ""){
          postJson.remark = remark;
        }

        const [httpStatus, reponseData] = await handleApiEditProcess(postJson);
        if(httpStatus == "200"){
          if(reponseData.statusCode === "20100"){
            toast(<span>{t("editingCompletedSaved")}</span>, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });          
            loadProcessList();            
            handleClose();
          }
          else{
            toast.error(reponseData.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
      }
    }
    setIsSaving(false);
  }
  //#endregion

  //#region 檢查欄位是否有問題
  const checkCol = () => {
    let newEquipmentAndPrograms = [];
    let newEditedPrograms = [];

    if(selPart === null){
      return {result: true, 
        equipmentAndPrograms: newEquipmentAndPrograms,
        editedPrograms: newEditedPrograms
      };
    }

    if(processNo === "" || (!isNumber(processNo) || !isPositiveInteger(processNo)) ||
    processNo.length > 4 || checkProcessIsUsed){
      return {result: true, 
        equipmentAndPrograms: newEquipmentAndPrograms,
        editedPrograms: newEditedPrograms
      };
    }

    if(processName === "" || processName.length > 50){
      return {result: true, 
        equipmentAndPrograms: newEquipmentAndPrograms,
        editedPrograms: newEditedPrograms
      };
    }

    if(equipmentPrograms.length > 0){
      for (let [index, item] of equipmentPrograms.entries()) {
        if(item.selMids.length === 0 || handleRepeatEquipment(index)){
          return {result: true, 
            equipmentAndPrograms: newEquipmentAndPrograms,
            editedPrograms: newEditedPrograms
          };
        }

        if(item.selProgram === null || handleRepeatProgram(item.selProgram)){
          return {result: true, 
            equipmentAndPrograms: newEquipmentAndPrograms,
            editedPrograms: newEditedPrograms
          };
        }
        
        if(item.action === "insert"){
          let newMids = [];
          for(let detail of item.selMids){
            newMids.push(detail.value);
          }

          newEquipmentAndPrograms.push({
            mids: newMids,
            programID: item.selProgram.value
          });
        }
        else{
          if(item.initProgram != item.selProgram.value){
            newEditedPrograms.push({
              previousProgramID: item.initProgram,
              programID: item.selProgram.value
            });
          }
        }
      }
    }
    else{
      return {result: true, 
        equipmentAndPrograms: newEquipmentAndPrograms,
        editedPrograms: newEditedPrograms
      };
    }

    if(remark !== null && remark?.length > 300){
      return {result: true, 
        equipmentAndPrograms: newEquipmentAndPrograms,
        editedPrograms: newEditedPrograms
      };
    }

    return {result: false, 
      equipmentAndPrograms: newEquipmentAndPrograms,
      editedPrograms: newEditedPrograms
    };
  }
  //#endregion

  //#region 新增工序API
  const handleApiAddProcess = async (postJson) => {
    let response = await apiAddProcess(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 編輯工序API
  const handleApiEditProcess = async (postJson) => {
    let response = await apiEditProcess(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 下拉多選 Change事件-設備
  const handleSelChangeEquipment = (e, index) => {
    setEquipmentPrograms((prevPrograms) =>
      prevPrograms.map((program, i) =>
        i === index ? { ...program, selMids: e } : program
      )
    );
  };
  //#endregion

  //#region 刪掉設備按鈕
  const handleDelEquipment = (mainIndex, subIndex) => {
    setEquipmentPrograms((prevPrograms) =>
      prevPrograms.map((program, i) =>
        i === mainIndex
          ? {
              ...program,
              selMids: program.selMids.filter((_, j) => j !== subIndex)
            }
          : program
      )
    );
  };
  //#endregion

  //#region 新增設備與加工程式
  const handleAdd = () =>{
    setEquipmentPrograms((prevPrograms) => [
      ...prevPrograms,
      {
        selMids: [],
        selProgram: null,
        batchProductionQuantity: 0,
        duration: 0,
        programDescription: "",
        action: "insert"
      }
    ]);
  }
  //#endregion

  //#region 刪掉其中一筆設備和加工程式
  const handleDelEquipmentPrograms = (indexToRemove) => {
    setEquipmentPrograms((prevPrograms) =>
      prevPrograms.filter((_, index) => index !== indexToRemove)
    );
  }
  //#endregion

  //#region 判斷加工程式是否有重複
  const handleRepeatProgram = (selProgram) => {
    let result = false;

    if(selProgram != null){
      let filter = equipmentPrograms.filter(d => d.selProgram === selProgram);
      if(filter.length >= 2){
        result = true;
      }
    }

    return result;
  }
  //#endregion

  //#region 判斷設備是否有重複
  const handleRepeatEquipment = (selMidsIndex) => {
    const currentSelMids = equipmentPrograms[selMidsIndex].selMids;

    return equipmentPrograms.some((program, i) => {
      if (i === selMidsIndex) return false; // Skip the specified index
  
      // Check if any machine in currentSelMids exists in other selMids arrays
      return program.selMids.some((machine) =>
        currentSelMids.some((currentMachine) => currentMachine.value === machine.value)
      );
    });
  }
  //#endregion

  //#region 確定要離開嗎?
  const handleLeave = async () => {
    let updateCol = await checkUpdateCol();
    //要記得多判斷是否有編輯過資料
    if(updateCol){
      setShowLeavePageModal(true);
    }
    else{
      handleClose();
      loadProcessList();
    }
  }
  //#endregion

  //#region 確認欄位是否有改變
  const checkUpdateCol = () => {
    let result = false;
    
    if(behavior === "update"){
      if(initProcessName != processName ||
        initRemark != remark 
      ){
        result = true;
      }

      for(let [itemIndex, item] of equipmentPrograms.entries()){
        const initItem = initEquipmentPrograms[itemIndex];
        if (initItem && item.selProgram.value !== initItem.selProgram.value) {
          result = true;
          break;
        }
      }
    }
    else{
      if(selPart != null || processNo != "" || processName != "" ||
        remark != ""
      ){
        result = true;
      }

      for(let item of equipmentPrograms){
        if(item.selMids.length !== 0 || item.selProgram !== null){
          result = true;
          break;
        }
      }
    }

    return result;
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{behavior === "insert" ? t("create") : t("edit")}</div>
            <div className="modal-title-note">{t("requiredFieldsAsterisk")} 
              <span className="text-red">&nbsp;*</span>
            </div>
          </div>
          <button type="button" className="close" 
            data-dismiss="modal" aria-label="Close" 
            onClick={handleLeave}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          <div className="modal-form">
            <div className="modal-inputText w100">
              <Select id="Part" className={`select required ${behavior === "update" ? "disabled" : ""}`}
                styles={customStyles}
                options={workpieceSetting}
                value={selPart} 
                onChange={(e) => handleSelChange(e, "part")}
                placeholder={t("select")}
                isSearchable={true}
                isClearable={true}
                isDisabled={behavior === "update"}
              />
              <label htmlFor="Part" className="mT0">{t("part")}</label>

              {
                judgeCheckErr && selPart === null ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }
            </div>

            {
              createdProcesses.length > 0 ?
                <div className='modal-createdProcesses'>
                  <div className='title'>{t("createdProcesses")}</div>
                  {
                    createdProcesses.map((obj, index) => {
                      return <div className='item' key={index}>
                        <span className='processNO'>{obj.processNo}</span>
                        <span className='processName'>{obj.processName}</span>
                      </div>;
                    })
                  }
                </div> : null
            }
            
            <div className="modal-inputText w30 mR20">
              <input type="text" id="ProcessNo" 
                name="processNo"
                value={processNo}
                onChange={handleInputChange}
                onBlur={handleCheckRepeat}
                className={
                  judgeCheckErr && (processNo === "" || (!isNumber(processNo) && !isPositiveInteger(processNo)) ||
                  processNo.length > 4 || checkProcessIsUsed) ? "error" : ""
                }
                required
                disabled={behavior === "update"}
              />
              <label htmlFor="ProcessNo">{t("processNo")}</label>

              {
                judgeCheckErr && processNo === "" ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }
              {
                judgeCheckErr && processNo !== "" && (!isNumber(processNo) || !isPositiveInteger(processNo)) ?
                  <span className="errortext">{t("errorGreaterOne")}</span> : null
              }
              {
                judgeCheckErr && processNo !== "" && (isNumber(parseFloat(processNo)) && isPositiveInteger(parseFloat(processNo))) &&
                  processNo.length > 4 ?
                  <span className="errortext">{t("exceedsCharacterLimit")}</span> : null
              }
              {
                judgeCheckErr && processNo !== "" && (isNumber(parseFloat(processNo)) && isPositiveInteger(parseFloat(processNo))) &&
                  processNo.length <= 4 && checkProcessIsUsed ?
                  <span className="errortext">{t("processNumberAlready")}</span> : null
              }
            </div>
            <div className="modal-inputText w70">
              <input type="text" id="ProcessName" required
                name="processName"
                value={processName}
                onChange={handleInputChange}
                className={
                  judgeCheckErr && (processName === "" || processName.length > 50) ? "error" : ""
                }
              />
              <label htmlFor="ProcessName">{t("processName")}
                <span className="charactor">({processName.length ?? 0}/50)</span>
              </label>

              {
                judgeCheckErr && processName === "" ?
                  <span className="errortext">{t("requiredFields")}</span> : null
              }
              {
                judgeCheckErr && processName !== "" && processName.length > 50 ?
                  <span className="errortext">{t("exceedsCharacterLimit")}</span> : null
              }
            </div>
            <div className="gp">
                <div className="title">{t("equipmentPrograms")}</div>
                {
                  equipmentPrograms.map((obj, index) => {
                    return (
                      <div className="gp-blackline" key={index}>
                        {equipmentPrograms.length != 1 && behavior === "insert" ? (
                          <div className="cancel" onClick={()=>handleDelEquipmentPrograms(index)}>
                            <div className="icon-Cross"></div>
                          </div>
                        ) : null}

                        <div className="modal-inputText w100">
                          <Select id="Program" className='select required'
                            name="program"
                            key="program"
                            styles={customStylesProgram(judgeCheckErr && (obj.selProgram === null || handleRepeatProgram(obj.selProgram)))}
                            options={programSetting}
                            value={obj.selProgram} 
                            onChange={(e) => handleSelChange(e, "program", index)}
                            placeholder={t("select")}
                            isSearchable={true}
                            isClearable={true}

                          />
                          <label htmlFor="Program" className="mT0">{t("program")}</label>
                          {
                            judgeCheckErr && obj.selProgram === null ?
                              <span className="errortext">{t("requiredFields")}</span> : null
                          }
                          {
                            judgeCheckErr && handleRepeatProgram(obj.selProgram) ?
                              <span className="errortext">{t("selectedContentOverlaps")}</span> : null
                          }
                        </div>
                        
                        <div className="modal-inputText w100 mt-2">
                          <MultiSelect
                            className={`select required ${behavior === "update" && obj.action === "update" ? "disabled" : ""}`}
                            id="Equipment"
                            options={mutiEquipmentSetting}
                            onChange={(e)=>handleSelChangeEquipment(e, index)}
                            value={obj.selMids}
                            isSelectAll={true}
                            menuPlacement="bottom"
                            placeholder={t("select")}
                            isError={judgeCheckErr && obj.selMids.length === 0}
                            isDisabled={behavior === "update" && obj.action === "update"}
                          />
                          <label htmlFor="Equipment" className="mT0 modal-label">{t("equipment")}</label>
                          {
                            judgeCheckErr && obj.selMids.length === 0 ?
                              <span className="errortext">{t("requiredFields")}</span> : null
                          }
                        </div>
                        
                        <div className={`gp-list`}>
                          <div className={obj.selMids.length > 0 && judgeCheckErr && handleRepeatEquipment(index) ? "border-error" : ""}>
                            {
                              obj.selMids.map((mid, midIndex) => {
                                return <div className="item" key={midIndex}>
                                  <div>{mid.label}</div>
                                  {
                                    behavior === "insert" ?
                                      <div className="icon" onClick={()=>handleDelEquipment(index, midIndex)}>
                                        <div className="icon-Cross lightblue"></div>
                                      </div> : null
                                  }
                                </div>;
                              })
                            }
                          </div>
                          {
                            obj.selMids.length > 0 && judgeCheckErr && handleRepeatEquipment(index) ?
                              <span className="errortext">{t("selectedContentOverlaps")}</span> : null
                          }
                        </div>

                        {
                          obj.selProgram !== null ?
                          <div className='programDetail'>
                            <div className='rowDetail'>
                              <span className='mainTitle'>{t("batchProductionQuantity")}</span>
                              <span className='content'>{obj.batchProductionQuantity}</span>
                            </div>
                            <div className='rowDetail'>
                            <span className='mainTitle'>{t("duration")}</span>
                            <span className='content'>{formatTimeDays(obj.duration, t)}</span>
                            </div>
                            {
                              obj.programDescription != null ?
                              <div className='rowDetail'>
                                <div className='mainTitle'>{t("programDescription")}</div>
                                <span className='contentDescription'>{obj.programDescription}</span>
                              </div> : null
                            }
                          </div> : null
                        }
                        
                      </div>
                    );
                  })
                }
            </div>
            <div className="btn-gp">
                <div></div>
                <button className="btn btn-icontext btn-lv3 btn-sub" onClick={handleAdd}>
                    <div className="icon icon-Add"></div>
                    <span>{t("add")}</span>
                </button>
            </div>
            <hr />
            <div className="modal-inputText w100">
                <textarea id="Remark" 
                  name="remark"
                  value={remark}
                  onChange={handleInputChange}
                  className={
                    judgeCheckErr && (remark !== "" && remark?.length > 300) ? "error" : ""
                  }></textarea>
                <label htmlFor="Remark">{t("remark")}<span className="charactor">({remark?.length ?? 0}/300)</span></label>
                {
                  judgeCheckErr && remark !== "" && remark?.length > 300 ?
                    <span className="errortext">{t("exceedsCharacterLimit")}</span> : null
                }
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-gp">
            <div>
              <button className="btn btn-txt-black" onClick={handleLeave}>
                {t("cancel")}
              </button>
            </div>
            <button type="submit" 
              className="btn btn-lv1"
              disabled={isSaving}
              onClick={handleSave}
            >{t("save")}</button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

       {/* 確定離開嗎？ */}
       <MessageModal 
        title={t("leavePage")}
        content={`${t("leavePageContent1")}<br /><br />${t("leavePageContent2")}`}
        showModal={showLeavePageModal} 
        handleConfirmData={() => {handleLeavePageClose();handleClose();loadProcessList();}}
        size="xs"
        confirmModalTxt={t("leaveSaving")}
        cancelTxt={t("stayThisPage")}
        cancelModal={handleLeavePageClose}
        handleClose={handleLeavePageClose}
      />
    </>
  );
};

export default Process;
