import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { useParams, useNavigate } from 'react-router-dom';

import './HistoryMain.css';

import Tab from 'components/basic/tab';
import Messages from "page/history/data/Messages/Messages"; //訊息
import ChartData from "page/history/data/ChartData/ChartData"; //圖表和數據

const HistoryMain = () => {
  const navigate = useNavigate();
  let { tabName } = useParams();
  const { permission } = useContext(MyUserContext);
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "Messages",
        active: tabName === "Messages"
      },
      {
        tabName: "ChartData",
        active: tabName === "ChartData"
      }
    ];

    if(permission !== null){
      let filterMainTab = permission.find(d => d.tabName === "History");
      if(filterMainTab){
        for(let item of filterMainTab.tabList){
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if(tab) newTabList.push(tab);
        }
      }
      else{
        newTabList = defaultTabs;
      }
    }
    else{
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if(filter){
      navigate(`/History/${filter.tabName}`);
    }
  }, [tabList]);

  return (
    <div>
      <Tab tabList={tabList} setTabList={setTabList} />
        {
          tabList?.find(d => d.active && d.tabName === "Messages") ?
            <Messages /> :
            null
        }
        {
          tabList?.find(d => d.active && d.tabName === "ChartData") ?
            <ChartData /> :
            null
        }
    </div>
  );
};
    
export default HistoryMain;