import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { ToastContainer, toast } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';

import { apiOrderCountAndStatus, apiGetRatingCriteria } from 'utils/Api';

import WorkOrder from "page/apps/data/aps/workOrderScheduling/WorkOrder";
import Scheduling from "page/apps/data/aps/workOrderScheduling/Scheduling";

import '../APS-statistic.css';
import '../APS-pageWO.css';
import '../APS-dropdown.css';

const WorkOrderSchedulingMain = () => {
  const { t } = useTranslation("aps");  
  const { overtime } = useContext(MyUserContext);
  const [orderCountAndStatus, setOrderCountAndStatus] = useState([]);  //工單數量&狀態數量表
  const [currentTab, setCurrentTab] = useState("workOrders"); //目前是哪一個分頁  workOrders=>工單 scheduling=>排程 archivedOrders=>封存
  const [ratingCriteria, setRatingCriteria] = useState(null); //顯示評級標準
  
  
  useEffect(() => {
    loadOrderCountAndStatus(); //載入工單數量&狀態數量表
    loadCriteriaData();        //載入評級標準
  }, []);
  

  //#region 載入工單數量&狀態數量表 Manual:指定排程 Auto:自動排程 Both:兩者皆是
  const loadOrderCountAndStatus = async (isReturn = false) => {
    const [httpStatus, reponseData] = await handleApiOrderCountAndStatus();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        setOrderCountAndStatus(reponseData.data);

        if(isReturn){
          let orderStatus = reponseData.data.orderStatus;
          return orderStatus;
        }
      }
    }
  }
  //#endregion

  //#region 載入工單數量&狀態數量表列表API
  const handleApiOrderCountAndStatus = async () => {
    let postJson = {};
    let response = await apiOrderCountAndStatus(postJson);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  const loadCriteriaData = async () => {
    const [httpStatusRatingCriteria, reponseDataRatingCriteria] = await handleApiGetRatingCriteria();
    if(httpStatusRatingCriteria == "200"){
      if(reponseDataRatingCriteria.statusCode === "20000"){
        setRatingCriteria(reponseDataRatingCriteria.data);
      }
      else if(reponseDataRatingCriteria.statusCode === "40103" || reponseDataRatingCriteria.statusCode === "40104"){
        overtime();
      }
    }
  }

  const handleApiGetRatingCriteria = async () => {
    let ratingCriteriaResponse = await apiGetRatingCriteria();
    if(ratingCriteriaResponse){
        const httpStatus = ratingCriteriaResponse.request.status;
        const reponseData = ratingCriteriaResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }

  const handleTab = (tab) => {
    setCurrentTab(tab);
  }

  return (
    <>
      <section className="statistic">
        <div className="w2 box-style-white-shadow">
          <h2>{t("orderCount")}</h2>
          <ul>
            <li>
              <div className="score">{orderCountAndStatus?.totalOrders}</div>
              <div className="sub-title"></div>
            </li>
          </ul>
        </div>
        <div className="w10 box-style-white-shadow">
          <h2>{t("statusCount")}</h2>
          <ul>
            <li>
              <div className="score">{orderCountAndStatus?.orderStatus?.unscheduled}</div>
              <div className="sub-title status unscheduled">{t("unscheduled")}</div>
            </li>
            <li>
              <div className="score">{orderCountAndStatus?.orderStatus?.scheduled}</div>
              <div className="sub-title status scheduled">{t("scheduled")}</div>
            </li>
            <li>
              <div className="score">{orderCountAndStatus?.orderStatus?.inProduction}</div>
              <div className="sub-title status inproduction">{t("started")}</div>
            </li>
            <li>
              <div className="score">{orderCountAndStatus?.orderStatus?.paused}</div>
              <div className="sub-title status paused">
                <span>{t("paused")}</span>
                <span className="icon status bg paused"><div className="icon icon-Paused"></div></span>
              </div>
            </li>
            <li>
              <div className="score">{orderCountAndStatus?.orderStatus?.completed}</div>
              <div className="sub-title status completed">
                <span>{t("completed")}</span>
                <span className="icon status bg completed"><div className="icon icon-Check"></div></span>
              </div>
            </li>
            <li>
              <div className="score">{orderCountAndStatus?.orderStatus?.aborted}</div>
              <div className="sub-title status aborted">{t("aborted")}</div>
            </li>
          </ul>
        </div>
      </section>
      <section className="page">
        <div className="APS-tab scroll">
          <div className={`cursor-pointer ${currentTab === "workOrders" ? "tab-clicked" : "tab-unclick"}`}
            onClick={()=>handleTab("workOrders")}>
            {t("workOrders")}
          </div>
          <div className={`cursor-pointer ${currentTab === "scheduling" ? "tab-clicked" : "tab-unclick"}`}
            onClick={()=>handleTab("scheduling")}>
            {t("scheduling")}
          </div>
          <div className={`cursor-pointer ${currentTab === "archivedOrders" ? "tab-clicked" : "tab-unclick"}`}
            onClick={()=>handleTab("archivedOrders")}>
            {t("archivedOrders")}
          </div>
        </div>
        <div className="page-content">
          {
            currentTab === "workOrders" ?
              <WorkOrder 
                currentTab={currentTab}
                loadOrderCountAndStatus={loadOrderCountAndStatus}
                isArchived={false}
              /> : null
          }
          {
            currentTab === "scheduling" ?
              <Scheduling
                currentTab={currentTab}
                loadOrderCountAndStatus={loadOrderCountAndStatus}
                orderCountAndStatus={orderCountAndStatus}
                setCurrentTab={setCurrentTab}
                ratingCriteria={ratingCriteria}
              /> : null
          }
          {
            currentTab === "archivedOrders" ?
              <WorkOrder 
                currentTab={currentTab}
                loadOrderCountAndStatus={loadOrderCountAndStatus}
                isArchived={true}
              /> : null
          }
        </div>
      </section>

      <ToastContainer />

    </>
  );
};

export default WorkOrderSchedulingMain;