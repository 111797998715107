import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { convertDateFormat } from 'utils/commonFun';
import { decimalWithOneDecimal, convertDateFormat, formatTimeDays } from 'utils/commonFun';
import MyPagination from 'components/basic/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import MessageModal from 'components/basic/MessageModal';

import { apiOrderList, apiScheduleCancel } from 'utils/Api';

import SelectWorkOrder from "page/apps/data/aps/modal/SelectWorkOrder"; //選擇工單
import WorkOrderDetail from "page/apps/data/aps/modal/WorkOrderDetail"; //瀏覽工單內容
import PauseWorkOrder from "page/apps/data/aps/modal/PauseWorkOrder"; //暫停工單
import AbortWorkOrder from "page/apps/data/aps/modal/AbortWorkOrder"; //終止工單
import ManualScheduling from "page/apps/data/aps/modal/ManualScheduling"; //手動排程
import GanttChart from './GanttChart'; //甘特圖

const Scheduling = ({ currentTab, loadOrderCountAndStatus, orderCountAndStatus, setCurrentTab, ratingCriteria }) => {
  const { t, i18n } = useTranslation("aps");
  const { overtime, show404Page } = useContext(MyUserContext);

  const [orderData, setOrderData] = useState([]);  //工單資料
  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字
  const [keywordSearch, setKeywordSearch] = useState(""); // for autoRefresh state variable

  const [descending, setDescending] = useState(false); //按工件正排 / 倒排 (預設為正排)
  const [orderField, setOrderField] = useState("equipment"); //排序欄位(orderNo, equipment, datetimeStart, scheduledDeliveryDate)
  const [currentPage, setCurrentPage] = useState(1);

  const scrollRef = useRef(null);
  const interval = useRef(null);

  const [selectWorkOrderModal, setSelectWorkOrderModal] = useState(false); //是否選擇工單Modal
  const handleSelectWorkOrderClose = () => { setSelectWorkOrderModal(false); }

  const [orderID, setorderID] = useState(0);  //選擇工單流水號
  const [manualOrderId, setManualOrderId] = useState(null); //指定排程所選擇工單資訊

  const [pauseModal, setPauseModal] = useState(false); //是否顯示暫停工單Modal
  const handlePauseClose = () => { setPauseModal(false); }

  const [abortModal, setAbortModal] = useState(false); //是否顯示終止工單Modal
  const handleAbortClose = () => { setAbortModal(false); }  // setAutoRefresh(true);}

  const [showWorkOrderDetailModal, setShowWorkOrderDetailModal] = useState(false); //是否工單明細Modal
  const handleWorkOrderDetailClose = () => setShowWorkOrderDetailModal(false);

  const [manualSchedulingModal, setManualSchedulingModal] = useState(false); //指定排程Modal
  const handleManualSchedulingClose = () => setManualSchedulingModal(false);

  const [showCancelScheduleModal, setShowCancelScheduleModal] = useState(false); //是否顯示取消這個排程？Modal
  const handleCancelScheduleClose = () => setShowCancelScheduleModal(false);

  const [unscheduled, setUnscheduled] = useState(0);

  const [showUnscheduledModal, setShowUnscheduledModal] = useState(false); //是否顯示沒有未排程的工單Modal
  const handleUnscheduledClose = () => setShowUnscheduledModal(false);

  const autoRefreshInterval = window.autoRefreshInterval ? window.autoRefreshInterval * 1000 : 10000;
  let inetrvalActive = false;
  let oldKeyword = "";

  useEffect(() => {
    if (currentTab === "scheduling") {
      loadOrderList(); //載入工單列表

      const fetchData = async () => {
        let orderStatus = await loadOrderCountAndStatus(true); //未排程工單數量
        setUnscheduled(orderStatus.unscheduled);
      };
      fetchData();

      return () => { clearInterval(interval.current); }
    }
  }, [currentPage, descending, orderField, keywordSearch]);

  useEffect(() => {
    if (orderData?.totalItems > 0 && orderData?.detail?.length === 0) {
      setCurrentPage(1);
    }
  }, [orderData]);

  useEffect(() => {
    if (checkModalShow()) {
      setAutoRefresh(false);
    }
    else {
      setAutoRefresh(true);
    }
    return () => { clearInterval(interval.current); }
  }, [pauseModal, abortModal, showCancelScheduleModal, showUnscheduledModal]);

  // selectWorkOrderModal will cause another modal to show, so clearInterval when selectWorkOrderModal is true, than setAutoRefresh(true) when orderData changed
  useEffect(() => {
    if (checkModalShow()) {
      setAutoRefresh(false);
    }
  }, [selectWorkOrderModal, manualSchedulingModal, showWorkOrderDetailModal]);

  const checkModalShow = () => {
    return (selectWorkOrderModal || pauseModal || abortModal || showWorkOrderDetailModal || manualSchedulingModal || showCancelScheduleModal || showUnscheduledModal);
  }

  const setAutoRefresh = (setRefresh) => {
    inetrvalActive = setRefresh;
    clearInterval(interval.current);

    if (setRefresh) {
      interval.current = setInterval(() => {
        loadOrderList();
      }, autoRefreshInterval);
    }
    if (process.env.NODE_ENV === 'development') {
      console.log("Scheduling inetrvalActive", inetrvalActive, "intervalID", interval.current);
    }
  }

  //#region 載入工單列表
  const loadOrderList = async (setRefresh) => {
    const [httpStatus, reponseData] = await handleApiOrderList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setOrderData(reponseData.data);

        // 這裡主要給子Modal使用，讓子Modal可以透過loadOrderList設定autoRefresh
        if (setRefresh === undefined) { setRefresh = true; }
        if (!inetrvalActive && setRefresh) { setAutoRefresh(true); }
      }
      else if (reponseData.statusCode === "40300") {
        show404Page();
      }
      else if (reponseData.statusCode === "40103") {
        overtime();
      }
    }
  }
  //#endregion

  //#region 載入工單列表API
  const handleApiOrderList = async () => {
    let postJson = {
      keyword: keyword,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30,
      descending: descending,
      orderStatus: [1, 2],
      orderField: orderField
    };

    let response = await apiOrderList(postJson, true);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
  };
  //#endregion

  //#region 查詢按鈕
  const handleSearch = () => {
    if (keyword == oldKeyword) {
      loadOrderList();
    }

    oldKeyword = keyword;
    setKeywordSearch(keyword);
  }
  //#endregion

  //#region 新增按鈕
  const handleCreate = () => {
    if (unscheduled === 0) {
      setShowUnscheduledModal(true);
    }
    else {
      setSelectWorkOrderModal(true); //加入排程
    }
  }
  //#endregion

  //#region 暫停工單
  const handlePause = (ID) => {
    setPauseModal(true);
    setorderID(ID);
  }
  //#endregion

  //#region 終止工單
  const handleAbort = (ID) => {
    setAbortModal(true);
    setorderID(ID);
  }
  //#endregion

  //#region 瀏覽內容按鈕
  const handleDetail = (ID) => {
    setorderID(ID);
    setShowWorkOrderDetailModal(true);
  }
  //#endregion

  //#region 修改排程
  const handleChangeSchedule = (ID) => {
    let filter = orderData?.detail?.find(d => d.orderID === ID);
    if (filter) {
      setManualOrderId(filter);
    }
    setManualSchedulingModal(true);
  }
  //#endregion

  //#region 取消排程
  const handleCancelSchedule = (ID) => {
    setorderID(ID);
    setShowCancelScheduleModal(true);
  }
  //#endregion

  //#region 取消這個排程-確定按鈕
  const handleCancelScheduleConfirm = async () => {
    const [httpStatus, reponseData] = await handleApiScheduleCancel();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        setShowCancelScheduleModal(false);
        loadOrderList();
        loadOrderCountAndStatus();
        toast(t("canceledSuccessfully"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 取消排程API
  const handleApiScheduleCancel = async () => {
    let postJson = {
      orderID: orderID
    };

    let response = await apiScheduleCancel(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion
  
  //#region 排序欄位
  //2025-03-11 新版JoJo問ChatGPT改成將排序按鈕直接做在表頭文字，箭頭變成只是輔助視覺效果
  const orderByCol = (field) => {
    if (orderField === field) {
      setDescending(!descending); // 如果點擊同一欄位，則切換升冪/降冪
    } else {
      setOrderField(field);
      setDescending(false); // 點擊新欄位時，預設升冪排序
    }
  };
  //#endregion

  return (
    <div className="table-wrapper rwd firstColCheckbox text-keep-normal">
      <div className="filterRow">
        <div>
          <div className="btn-search">
            <input type="search" id="search" name="query" placeholder={t("search")} aria-label="Search"
              value={keyword} onChange={handleInputKeywordChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") { handleSearch(); }
              }}
            />
            <button type="submit" className="btn btn-onlyicon bounce btn-lv5"
              onClick={handleSearch}>
              <div className="icon icon-Search"></div>
            </button>
          </div>
        </div>
        <div>
          <button className={`btn btn-lv1 btn-icontext-rwd ${unscheduled === 0 ? "disabled" : ""}`} onClick={handleCreate}>
            <div className="icon icon-Create"></div>
            <span>{t("addSchedule")}</span>
          </button>
        </div>
      </div>

      {
        orderData?.detail?.length > 0 ?
          <>
            <table className="APSTable scroll" id="Scheduling" ref={scrollRef}>
              <thead className="showTHead">
                <tr className="tLeft">
                  <th className="c1 noShowWhenRWD"></th>
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "orderNo" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("orderNo")}
                  >
                    {t("workOrderID")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "orderNo" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "orderNo" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  <th className="noShowWhenRWD">{t("status")}</th>
                  <th className="noShowWhenRWD">{t("part")}/{t("process")}</th>
                  <th className="minWidth160 noShowWhenRWD">{t("progress")}</th>
                  <th className="tCenter noShowWhenRWD">{t("scheduledProductionQuantity")}</th>
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "datetimeStart" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("datetimeStart")}
                  >
                    {t("scheduledTime")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "datetimeStart" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "datetimeStart" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "equipment" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("equipment")}
                  >
                    {t("equipment")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "equipment" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "equipment" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  <th
                    className={`hoverShowIcon cursor-pointer ${orderField === "scheduledDeliveryDate" ? "clicked" : "unclicked"}`}
                    onClick={() => orderByCol("scheduledDeliveryDate")}
                  >
                    {t("scheduledDeliveryDate")}
                    <span className="sort-icons">
                      <span className={`grey8 icon-ArrowUp icon${orderField === "scheduledDeliveryDate" && !descending ? "-clicked" : ""}`}></span>
                      <span className={`grey8 icon-ArrowDown icon${orderField === "scheduledDeliveryDate" && descending ? "-clicked" : ""}`}></span>
                    </span>
                  </th>
                  <th className="tRight noShowWhenRWD">{t("estDuration")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  orderData?.detail?.map((obj, index) => {
                    return (
                      <tr className="" key={index}>
                        <td className="c1 d-flex justify-content-center">
                          {
                            obj.orderStatus === 3 ?
                              <span className="icon icon-Radius status bg paused ms-0">
                                <div className="icon icon-xs icon-Paused"></div>
                              </span> : null
                          }
                          {
                            obj.orderStatus === 5 ?
                              <span className="icon icon-Radius status bg completed ms-0">
                                <div className="icon icon-xs icon-Check"></div>
                              </span> : null
                          }
                          {
                            obj.deliveryDelay ?
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    {t("deliveryDelay")}
                                  </Tooltip>
                                }
                              >
                                <span className="icon icon-Radius status bg deliveryDelay ms-0">
                                  <div className="icon icon-xs icon-DeliveryDelay"></div>
                                </span>
                              </OverlayTrigger>
                              : null
                          }
                        </td>
                        <td data-label={t("workOrderID")} className="f2">{obj.orderNo}-{obj.processNo}</td>
                        <td data-label={t("status")} className="f1">
                          {(() => {
                            let orderStatus = obj.orderStatus;

                            if (orderStatus === 0) {
                              return <div className="status status-padding bg unscheduled">
                                {t("unscheduled")}
                              </div>
                            }
                            else if (orderStatus === 1) {
                              return <div className="status status-padding bg scheduled">
                                {t("scheduled")}
                              </div>
                            }
                            else if (orderStatus === 2) {
                              return <div className="status status-padding bg inproduction">
                                {t("started")}
                              </div>
                            }
                            else if (orderStatus === 3) {
                              return <div className="status status-padding bg paused">
                                {t("paused")}
                              </div>
                            }
                            else if (orderStatus === 4) {
                              return <div className="status status-padding bg aborted">
                                {t("aborted")}
                              </div>
                            }
                            else if (orderStatus === 5) {
                              return <div className="status status-padding bg completed">
                                {t("completed")}
                              </div>
                            }
                          })()}
                        </td>
                        <td data-label={`${t("part")} / ${t("process")}`} className="f4">
                          <div>
                            <span className="strong2">{obj.wpNO}</span>
                            <span>{obj.wpName}</span>
                            <span> / </span>
                            <span className="strong2">{obj.processNo}</span>
                            <span>{obj.processName}</span>
                          </div>
                        </td>
                        <td data-label={t("progress")}>
                          <div className="progress-aps">
                            <div className={`chart ${(obj.orderStatus === 2 || obj.orderStatus === 5) ? "chart-black" : "chart-gray"}`}>
                              <div style={{ width: `${decimalWithOneDecimal(obj.actualQuantity, obj.scheduledQuantity, 0)}%` }}></div>
                            </div>
                            <div className="text">
                              <span className={`value ${(obj.orderStatus === 2 || obj.orderStatus === 5) ? "value-black" : "value-gray"}`}>
                                {decimalWithOneDecimal(obj.actualQuantity, obj.scheduledQuantity, 0)}
                                <span className={`unit ${(obj.orderStatus === 2 || obj.orderStatus === 5) ? "unit-black" : "unit-gray"}`}>%</span>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td data-label={t("scheduledProductionQuantity")} className="tCenter">
                          <span className={`${obj.actualQuantity === 0 ? "text-greyC" : ""}`}> {obj.actualQuantity} / </span>
                          <span>{obj.scheduledQuantity}</span>
                        </td>
                        <td data-label={t("scheduledTime")} className="f4">
                          {
                            obj.datetimeStart != null ?
                              <div>
                                <span className="strong2">{convertDateFormat(obj.datetimeStart, "MMM DD")}</span>
                                <span>{convertDateFormat(obj.datetimeStart, "hh:mm:ss A")}</span>
                                <span> - </span>
                                {
                                  convertDateFormat(obj.datetimeStart, "MMM DD") != convertDateFormat(obj.datetimeEnd, "MMM DD") ?
                                    <span className="strong2">{convertDateFormat(obj.datetimeEnd, "MMM DD")}</span> :
                                    null
                                }
                                <span>{convertDateFormat(obj.datetimeEnd, "hh:mm:ss A")}</span>
                              </div> : "-"
                          }
                        </td>
                        <td data-label={t("equipment")} className="f1">{obj.equipment}</td>
                        <td data-label={t("scheduledDeliveryDate")} className="f2">
                          <div>
                            <span className="strong2">{convertDateFormat(obj.scheduledDeliveryDate, "MMM DD")}
                              {convertDateFormat(obj.scheduledDeliveryDate, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.scheduledDeliveryDate, "YYYY")}</> : null}</span>
                          </div>
                        </td>
                        <td data-label={t("estDuration")} className="tRight">
                          {(() => {
                            let result = [];
                            let resultDuration = [];
                            const minEstimatedDuration = obj.minEstimatedDuration;
                            const maxEstimatedDuration = obj.maxEstimatedDuration;

                            if (minEstimatedDuration != null) {
                              const transMinEstimatedDuration = formatTimeDays(minEstimatedDuration, t);
                              const transMaxEstimatedDuration = formatTimeDays(maxEstimatedDuration, t);

                              const [dayMin, timeMin] = transMinEstimatedDuration.split(' ');
                              if (timeMin != undefined) {
                                resultDuration.push(<span className="strong2">{dayMin}</span>);
                                resultDuration.push(<span>{timeMin}</span>);
                              }
                              else {
                                resultDuration.push(<span>{dayMin}</span>);
                              }

                              if (maxEstimatedDuration != null) {
                                resultDuration.push(" ~ ");

                                const [dayMax, timeMax] = transMaxEstimatedDuration.split(' ');
                                if (timeMax != undefined) {
                                  resultDuration.push(<span className="strong2">{dayMax}</span>);
                                  resultDuration.push(<span>{timeMax}</span>);
                                }
                                else {
                                  resultDuration.push(<span>{dayMax}</span>);
                                }
                              }

                              result.push(<div>{resultDuration}</div>);
                            }

                            return result;
                          })()}
                        </td>
                        <td className="td btns tableFuncBtn">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className="custom-tooltip">
                                <span>{t("details")}</span>
                              </Tooltip>
                            }
                          >
                            <div className="btn-item" onClick={() => handleDetail(obj.orderID)}>
                              <div className="icon-Details"></div>
                            </div>
                          </OverlayTrigger>
                          {
                            obj.orderStatus === 1 ?
                              <>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <span>{t("changeSchedule")}</span>
                                    </Tooltip>
                                  }
                                >
                                  <div className="btn-item" onClick={() => handleChangeSchedule(obj.orderID)}>
                                    <div className="icon-ChangeSchedule"></div>
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <span>{t("cancelSchedule")}</span>
                                    </Tooltip>
                                  }
                                >
                                  <div className="btn-item red">
                                    <div className="icon-CancelSchedule" onClick={() => handleCancelSchedule(obj.orderID)}></div>
                                  </div>
                                </OverlayTrigger>
                              </>
                              : null
                          }
                          {
                            obj.orderStatus === 2 ?
                              <>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <span>{t("pause")}</span>
                                    </Tooltip>
                                  }
                                >
                                  <div className="btn-item" onClick={() => handlePause(obj.orderID)}>
                                    <div className="icon-Pause"></div>
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className="custom-tooltip">
                                      <span>{t("abort")}</span>
                                    </Tooltip>
                                  }
                                >
                                  <div className="btn-item red" onClick={() => handleAbort(obj.orderID)}>
                                    <div className="icon-Abort"></div>
                                  </div>
                                </OverlayTrigger>
                              </>
                              : null
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>

            <div className="d-flex justify-content-center mt-3">
              <MyPagination totalPages={Math.ceil(orderData?.totalItems / 30)}
                currentPage={currentPage}
                onPageChange={handlePageChange} />
            </div>
          </> :
          <div className="table-nodata">
            <div className="nodata-text">{t("noDataFound")}</div>
          </div>
      }

      <ToastContainer />

      <SelectWorkOrder
        showModal={selectWorkOrderModal}
        handleClose={handleSelectWorkOrderClose}
        handleCancel={() => { handleSelectWorkOrderClose(); loadOrderList(); }}
        loadOrderList={loadOrderList}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
        orderCountAndStatus={orderCountAndStatus}
        setCurrentTab={setCurrentTab}
        ratingCriteria={ratingCriteria}
      />

      {/* 工單內容 */}
      <WorkOrderDetail
        showModal={showWorkOrderDetailModal}
        handleClose={handleWorkOrderDetailClose}
        loadOrderList={loadOrderList}
        orderID={orderID}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
      />

      {/* 暫停工單 */}
      <PauseWorkOrder
        showModal={pauseModal}
        handleClose={handlePauseClose}
        orderID={orderID}
        loadOrderList={loadOrderList}
      />

      {/* 終止工單 */}
      <AbortWorkOrder
        showModal={abortModal}
        handleClose={handleAbortClose}
        orderID={orderID}
        loadOrderList={loadOrderList}
      />

      {/* 指定排程 */}
      <ManualScheduling
        showModal={manualSchedulingModal}
        handleClose={handleManualSchedulingClose}
        manualOrderId={manualOrderId}
        isChangeSchedule={false}
        loadOrderList={loadOrderList}
      />

      {/* 取消這個排程？  */}
      <MessageModal
        title={t("cancelScheduleQuestion")}
        content={`${t("cancelScheduleQuestionContent1")}<br /><br />${t("cancelScheduleQuestionContent2")}`}
        showModal={showCancelScheduleModal}
        handleConfirmData={handleCancelScheduleConfirm}
        size="xs"
        confirmModalTxt={t("confirm")}
        cancelTxt={t("keepSchedule")}
        cancelModal={handleCancelScheduleClose}
        handleClose={handleCancelScheduleClose}
      />

      {/* 沒有未排程的工單  */}
      <MessageModal
        title={t("noUnscheduledWorkOrders")}
        content={`${t("noUnscheduledWorkOrdersContent")}`}
        showModal={showUnscheduledModal}
        handleConfirmData={handleUnscheduledClose}
        size="xs"
        confirmModalTxt={t("confirm")}
        handleClose={handleUnscheduledClose}
      />
    </div>
  );
};

export default Scheduling;