import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { Row, Col, Form } from 'react-bootstrap';
import './TimePeriodCaptureContent.css';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { ToastContainer, toast } from 'react-toastify';
import Tab from 'components/basic/tab';
import {
  formatTimeDays, isNumber, isPositiveInteger, generateDateTimeRangeJSON, formatDateTimeRange, fillTimestamps
} from 'utils/commonFun';
import Select from 'react-select';
import { decrypt } from 'utils/crypt';
import AddWorkpiece from './modal/addWorkpiece';
import MessageModal from 'components/basic/MessageModal';

import {
  apiSinglePeriodReport, apiPostPeriodReport, apiWorkpieceList,
  apiProgramList, apiDatalistOriginal
} from 'utils/Api';

import PageErr404 from "page/PageErr404";   //頁面不存在

const TimePeriodCaptureContent = () => {
  const { t, i18n } = useTranslation("powerCarbon");
  const navigate = useNavigate();

  const regex = /^[^\/\\\*\?\$\@]+$/;

  const { permission, periodReportTypeList, showFormatDate, showFormatTime } = useContext(MyUserContext);

  let { tabName, reportID } = useParams();
  const [tabList, setTabList] = useState([]);  //上方Tab
  let [reportContent, setReportContent] = useState(null);

  const [judgeEdit, setJudgeEdit] = useState(false); //是否是編輯模式
  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  const [initCarbonElectricity, setInitCarbonElectricity] = useState("0.000"); //初始載入電力排碳係數(小數點3位)
  const [carbonElectricity, setCarbonElectricity] = useState("0.000"); //電力排碳係數(小數點3位)
  const [carbonElectricityErr, setCarbonElectricityErr] = useState(false); //電力碳排係數(是否有錯誤)

  const [initElectricityPrice, setInitElectricityPrice] = useState("0.0000"); //初始載入每度電價(小數點4位)
  const [electricityPrice, setElectricityPrice] = useState("0.0000"); //每度電價(小數點4位)
  const [electricityPriceErr, setElectricityPriceErr] = useState(false); //每度電價(是否有錯誤)

  const [transTimelineData, setTransTimelineData] = useState([]);  //將API回傳資料做轉換給ECharts設定檔資料
  const [timelineOptions, setTimelineOptions] = useState(null); //時間軸ECharts設定
  const [optionsXAxis, setOptionsXAxis] = useState([]); //圖表X軸(時間軸)
  const [loadChart, setLoadChart] = useState(false); //是否完整載入圖表

  const [reportName, setReportName] = useState("");  //報告名稱
  const [judgeUniquePeriodName, setJudgeUniquePeriodName] = useState(true); //檢查是否唯一值區間名稱
  const [periodReportType, setPeriodReportType] = useState(1);  //區間類別

  const [selectedOptionPart, setSelectedOptionPart] = useState(null);
  const [optionsPart, setOptionsPart] = useState([]);

  // const [selectWp, setSelectWp] = useState(""); //工件下拉選單值
  const [wpID, setWpID] = useState(0);  //工件流水號
  const [wpNO, setWpNO] = useState("");  //工件代號
  const [wpName, setWpName] = useState("");  //工件名稱
  const [programID, setProgramID] = useState(0);  //加工程式流水號
  const [programName, setProgramName] = useState(0);  //加工程式名稱
  const [wpImage, setWpImage] = useState("");  //工件圖片

  const [productionQty, setProductionQty] = useState(""); //生產數量
  const [remark, setRemark] = useState("");  //備註

  const [addWorkpieceModal, setAddWorkpieceModal] = useState(false); //新增工件Modal
  const handleAddWorkpieceClose = () => setAddWorkpieceModal(false); //關閉

  const [showLeavePageModal, setShowLeavePageModal] = useState(false); //是否顯示確定離開嗎？Modal
  const handleLeavePageClose = () => setShowLeavePageModal(false);

  const [showPageErr404, setShowPageErr404] = useState(false); //是否要顯示404頁面

  useEffect(() => {
    setJudgeCheckErr(false);
    handleSinglePeriodReport();

    if (localStorage.getItem("timeperiodCaptureEdit") != null) {
      setJudgeEdit(true);

      localStorage.removeItem("timeperiodCaptureEdit");
    }
  }, []);

  //#region 下拉選單工件
  const loadSelWorkpiece = async () => {
    var parts = [];

    //抓取工件/加工程式以及只有加工程式
    const [httpStatus, reponseData] = await handleApiProgramList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        const data = reponseData.data;
        if (data.totalItems > 0) {
          var parts = [];
          for (let item of data.detail) {
            if (item.wpID != null && item.programID != null) {
              parts.push({
                value: `${item.wpID}-${item.programID}`,
                label: `${item.wpNO} ${item.wpName}/${item.programName}`,
                wpNO: item.wpNO,
                wpName: item.wpName,
                programName: item.programName,
                wpImage: item.wpImageList?.length > 0 ? item.wpImageList[0] : ""
              });
            }
            else if (item.wpID == null && item.programID != null) {
              parts.push({
                value: `0-${item.programID}`,
                label: `${item.programName}`,
                wpNO: item.wpNO,
                wpName: item.wpName,
                programName: item.programName,
                wpImage: item.wpImageList?.length > 0 ? item.wpImageList[0] : ""
              });
            }
          }
        }
      }
    }

    const [httpStatusWorkpiece, reponseDataWorkpiece] = await handleApiWorkpieceList();
    if (httpStatusWorkpiece == "200") {
      if (reponseDataWorkpiece.statusCode === "20000") {
        const data = reponseDataWorkpiece.data;
        if (data.totalItems > 0) {
          for (let item of data.detail) {
            parts.push({
              value: `${item.wpID}-0`,
              label: `${item.wpNO} ${item.wpName}`,
              wpNO: item.wpNO,
              wpName: item.wpName,
              programName: item.programName,
              wpImage: item.wpImageList?.length > 0 ? item.wpImageList[0] : ""
            });
          }
        }
      }
    }

    setOptionsPart(parts);
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = { doIncludeNoProgram: 2 };
    let programListResponse = await apiWorkpieceList(postJson);
    if (programListResponse) {
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region API抓取加工程式列表
  const handleApiProgramList = async () => {
    let postJson = {
      filterWPCondition: 0
    }
    let programListResponse = await apiProgramList(postJson);
    if (programListResponse) {
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    if (transTimelineData.length > 0 && optionsXAxis.length > 0) {
      setTimelineOptions({
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          },
          formatter: function (params) {
            var result = params[0].axisValueLabel + '<br/>';
            params.forEach(function (item) {
              result += item.marker + item.seriesName + ' ' + item.value + ' kW<br/>';
            });
            return result;
          }
        },
        toolbox: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: optionsXAxis
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '25%']
        },
        grid: {
          top: '20px',
          left: '0px',
          right: '0px',
          bottom: '20px',
          containLabel: true
        },
        series: [
          {
            name: t("instantaneousPower"),
            type: 'line',
            // smooth: true,
            // symbol: 'none',
            // areaStyle: {},
            data: transTimelineData
          }
        ]
      });

    }
  }, [transTimelineData, optionsXAxis, i18n.language]);

  //#region 用電加工程式圖表(功率)
  const loadPowerKwList = async () => {
    let dateTimeRangeJSON = generateDateTimeRangeJSON(reportContent?.startTime, reportContent?.endTime);

    for (let [itemIndex, item] of dateTimeRangeJSON.entries()) {
      const [httpStatus, responseData] = await handleApiDatalistOriginal("power_now", "metrics", item.startTime, item.endTime);

      if (httpStatus == "200") {
        if (responseData.statusCode === "20000") {
          const timeStamp = fillTimestamps(responseData.data[0].itemList, item.startTime, item.endTime);

          setOptionsXAxis(prevData => {
            return [
              ...prevData,
              ...timeStamp
            ];
          });

          let itemList = responseData.data[0].itemList;
          let itemListIndex = 0;
          let data = [];
          for (let mark of timeStamp) {
            if (itemList.length > 0) {
              if (itemListIndex <= itemList.length - 1 && mark === itemList[itemListIndex].timestamp) {
                data.push(itemList[itemListIndex].value);
                itemListIndex++;
              }
              else {
                data.push(null);
              }
            }
            else {
              data.push(null);
            }
          }
          updateData(data);

          if (itemIndex === dateTimeRangeJSON.length - 1) {
            setLoadChart(true);
          }
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  const updateData = (data) => {
    setTransTimelineData(prevData => {
      return [
        ...prevData,  // Spread the previous array (this keeps the existing data)
        ...data       // Spread the new data into the array
      ];
    });
  }

  //#region 查詢Metrics資料API
  const handleApiDatalistOriginal = async (metricCode, metricCollection, startTime, endTime) => {
    let postJson = {
      mid: parseInt(reportContent?.mid),
      metricCodeList: [{
        metricCode: metricCode,
        metricCollection: metricCollection
      }],
      startTime: startTime,
      endTime: endTime
    };

    let response = await apiDatalistOriginal(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    if (reportContent != null) {
      setInitCarbonElectricity((reportContent?.carbonIntensityElectricity).toFixed(3));
      setCarbonElectricity((reportContent?.carbonIntensityElectricity).toFixed(4));

      setInitElectricityPrice((reportContent?.electricityPrice).toFixed(3));
      setElectricityPrice((reportContent?.electricityPrice).toFixed(4));

      setReportName(reportContent?.reportName);
      setPeriodReportType(reportContent?.periodReportType);

      setProductionQty(reportContent?.productionQty != null ? reportContent?.productionQty.toString() : "");
      setRemark(reportContent?.remark != null ? reportContent?.remark : "");

      if (selectedOptionPart && !optionsPart.find(option => option.value === selectedOptionPart.value)) {
        setSelectedOptionPart(null);
        setWpID(0);
        setWpNO("");
        setWpName("");
        setProgramID(0);
        setProgramName("");
        setWpImage("");
      }
      else {
        setWpID(reportContent?.wpID != null ? reportContent?.wpID : 0);
        setWpNO(reportContent?.wpNO);
        setWpName(reportContent?.wpName);
        setProgramID(reportContent?.programID != null ? reportContent?.programID : 0);
        setProgramName(reportContent?.programName);
        setWpImage(reportContent?.wpImage != null ? reportContent?.wpImage : "");
      }

      loadPowerKwList();
    }
  }, [reportContent]);

  useEffect(() => {
    if (judgeEdit) {
      loadSelWorkpiece();
    }
  }, [judgeEdit]);

  useEffect(() => {
    if (judgeEdit) {
      let value = "";
      let label = "";
      if (wpID != 0 && programID != 0) {
        value = `${wpID}-${programID}`;
        label = `${wpNO} ${wpName}/${programName}`;
      }
      else if (wpID != 0 && programID == 0) {
        value = `${wpID}-0`;
        label = `${wpNO} ${wpName}`;
      }
      else if (wpID == 0 && programID != 0) {
        value = `0-${programID}`;
        label = `${programName}`;
      }

      if (value === "") {
        setSelectedOptionPart(null);
        setWpID(0);
        setWpNO("");
        setWpName("");
        setProgramID(0);
        setProgramName("");
        setWpImage("");
      }
      else {
        setSelectedOptionPart({
          value: value,
          label: label,
          wpNO: wpNO,
          wpName: wpName,
          programName: programName
        });
      }
    }
  }, [optionsPart]);

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "PartsPrograms",
        active: tabName === "PartsPrograms"
      },
      {
        tabName: "TimePeriodCapture",
        active: tabName === "TimePeriodCapture"
      }
    ];

    if (permission !== null) {
      let filterMainTab = permission.find(d => d.tabName === "Power");
      if (filterMainTab) {
        for (let item of filterMainTab.tabList) {
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if (tab) newTabList.push(tab);
        }
      }
      else {
        newTabList = defaultTabs;
      }
    }
    else {
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if (filter) {
      if (filter.tabName === "PartsPrograms") {
        navigate(`/Power/${filter.tabName}`);
      }
      else {
        navigate(`/Power/${filter.tabName}/${reportID}`);
      }
    }
  }, [tabList]);

  //#region 載入一筆手動截取程式用電量分析報告
  const handleSinglePeriodReport = async () => {
    const [httpStatus, reponseData] = await handleApiSinglePeriodReport();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setReportContent(reponseData.data);
      }
      else if (reponseData.statusCode === "40400") {
        setShowPageErr404(true);
      }
    }
  }
  //#endregion

  //#region 載入一筆手動截取程式用電量分析報告API
  const handleApiSinglePeriodReport = async () => {
    let postJson = {
      reportID: parseInt(decrypt(reportID))
    };

    let response = await apiSinglePeriodReport(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 確定要離開嗎?
  const handleLeave = async () => {
    let updateCol = await checkUpdateCol();
    //要記得多判斷是否有編輯過資料
    if (updateCol) {
      setShowLeavePageModal(true);
    }
    else {
      navigate(`/Power/${tabName}`);
    }
  }
  //#endregion

  //#region 確認欄位是否有改變
  const checkUpdateCol = () => {
    let result = false;
    if ((parseFloat(initCarbonElectricity) !== parseFloat(carbonElectricity)) ||
      (parseFloat(initElectricityPrice) !== parseFloat(electricityPrice)) ||
      (reportContent?.reportName != reportName) ||
      (reportContent?.periodReportType != periodReportType) ||
      ((reportContent?.remark != null && reportContent?.remark != remark))) {
      result = true;
    }

    if (periodReportType === 1) {
      if ((reportContent?.wpID != null && reportContent?.wpID != wpID) || (reportContent?.programID != null && reportContent?.programID != programID)) {
        result = true;
      }
    }

    return result;
  }
  //#endregion

  //#region 類別樣式
  const renderPeriodReportType = (periodReportType) => {
    let showName = periodReportTypeList.find(d => d.key === periodReportType)?.name;
    switch (periodReportType) {
      //生產
      case 1:
        return (
          <span className="tag-icontext box-color-green">
            <div className="material-symbols-rounded icon16">play_circle</div>
            <div>{showName}</div>
          </span>
        );
      //待機
      case 2:
        return (
          <span className="tag-icontext box-color-yellow">
            <div className="material-symbols-rounded icon16">hourglass_bottom</div>
            <div>{showName}</div>
          </span>
        );
      //錯誤
      case 3:
        return (
          <span className="tag-icontext box-color-red">
            <div className="material-symbols-rounded icon16">warning</div>
            <div>{showName}</div>
          </span>
        );
      case 4:
        return (
          <span className="tag-icontext box-color-orange">
            <div className="material-symbols-rounded icon16">heat</div>
            <div>{showName}</div>
          </span>
        );
      case 5:
        return (
          <span className="tag-icontext box-color-lakeblue">
            <div className="material-symbols-rounded icon16">experiment</div>
            <div>{showName}</div>
          </span>
        );
      case 6:
        return (
          <span className="tag-icontext box-color-grey">
            <div className="material-symbols-rounded icon16">bedtime</div>
            <div>{showName}</div>
          </span>
        );
      case 7:
        return (
          <span className="tag-icontext box-color-blue">
            <div className="material-symbols-rounded icon16"></div>
            <div>{showName}</div>
          </span>
        );
      // 其他 case
      default:
        return null;
    }
  };
  //#endregion

  //#region 輸入右上角數值Change事件
  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    const setters = {
      carbonElectricity: setCarbonElectricity,
      electricityPrice: setElectricityPrice,
      reportName: setReportName,
      periodReportType: (v) => setPeriodReportType(parseInt(v)),
      remark: setRemark,
      productionQty: setProductionQty
    };

    if (setters[name]) {
      setters[name](value);
    }
  };
  //#endregion

  //#region 輸入右上角數值Blur事件
  const handleInputBlur = async (e) => {
    let { name, value } = e.target;

    if (name === "carbonElectricity") {
      if (!isNumber(value)) {
        setCarbonElectricityErr(true);
      }
      else {
        value = parseFloat(value).toFixed(3);
        setCarbonElectricityErr(false);
      }
      setCarbonElectricity(value);
    }
    else if (name === "electricityPrice") {
      if (!isNumber(value)) {
        setElectricityPriceErr(true);
      }
      else {
        value = parseFloat(value).toFixed(4);
        setElectricityPriceErr(false);
      }
      setElectricityPrice(value);
    }
  };
  //#endregion

  //#region 儲存按鈕
  const handleSave = async () => {
    setJudgeCheckErr(true);
    let checkError = await checkCol();
    if (!checkError) {
      const [httpStatus, reponseData] = await handleApiPostPeriodReport("update");
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20100") {
          toast(<>
            <span>{t("editingCompletedSaved")}</span>
          </>, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          // navigate(`/power/${tabName}`);
          setJudgeEdit(false);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    // else{
    //   alert("有錯")
    // }
  }
  //#endregion

  //#region 檢查欄位是否有問題
  const checkCol = async () => {
    let result = false;

    if (carbonElectricityErr || electricityPriceErr) {
      result = true;
    }

    if (reportName === "" || reportName.length > 100 || !regex.test(reportName?.trim())) {
      result = true;
    }

    if (remark != "" && remark?.length > 300) {
      result = true;
    }

    if (periodReportType === 1) {
      if (productionQty === "") {
        result = true;
      }
      else {
        if (!isNumber(productionQty) || !isPositiveInteger(productionQty)) {
          result = true;
        }
      }
    }

    //上面錯誤訊息沒有錯再進入API檢查名稱重複問題
    if (!result) {
      //檢查區間名稱是否重複
      let checkUniquePeriodNameErr = await checkUniquePeriodName("checkReportName", true);
      if (checkUniquePeriodNameErr) {
        return true;
      }
    }
    return result;
  }
  //#endregion

  //#region 檢查區間名稱是否重複
  const checkUniquePeriodName = async (behavior = "checkReportName", judgeForceCheck = false) => {
    let result = false;
    //格式正確再來檢查是否有重複
    if ((judgeCheckErr && reportName !== "" && reportName.length <= 100) || judgeForceCheck) {
      const [httpStatus, reponseData] = await handleApiPostPeriodReport(behavior);
      if (httpStatus == "200") {
        if (reponseData.statusCode === "DL001") {
          setJudgeUniquePeriodName(false);
          result = true;
        }
        else if (reponseData.statusCode === "20100") {
          setJudgeUniquePeriodName(true);
        }
      }
    }

    return result;
  }
  //#endregion

  //#region 新增以及修改手動擷取程式用電量分析報告
  const handleApiPostPeriodReport = async (behavior) => {
    let postJson = {
      action: behavior,
      reportID: parseInt(decrypt(reportID)),
      reportName: reportName
    };

    if (behavior === "update") {
      postJson.periodReportType = periodReportType;
      postJson.equipmentSerialNumber = reportContent?.equipmentSerialNumber;
      postJson.startDate = reportContent?.startTime;
      postJson.endDate = reportContent?.endTime;

      if (periodReportType === 1) {
        postJson.productionQty = parseInt(productionQty);
        postJson.wpID = wpID;
        postJson.programID = programID;
      }
      else {
        postJson.wpID = 0;
        postJson.programID = 0;
      }


      postJson.remark = remark;

      postJson.carbonIntensityElectricity = parseFloat(carbonElectricity);
      postJson.electricityPrice = parseFloat(electricityPrice);
    }

    // console.log("postJson", postJson)

    let programListResponse = await apiPostPeriodReport(postJson);
    if (programListResponse) {
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region change事件工件/加工程式
  const handleChangePart = (option) => {
    setSelectedOptionPart(option);

    if (option != null) {
      let parts = option.value.split('-');
      let firstValue = parts[0];
      let secondValue = parts[1];

      if (firstValue != "0") {
        setWpID(parseInt(firstValue));
        setWpNO(option.wpNO);
        setWpName(option.wpName);
        setWpImage(option.wpImage);
      }
      else {
        setWpID(0);
        setWpNO("");
        setWpName("");
        setWpImage("");
      }

      if (secondValue != "0") {
        setProgramID(parseInt(secondValue));
        setProgramName(option.programName);
      }
      else {
        setProgramID(0);
        setProgramName("");
      }
    }
    else {
      setWpID(0);
      setProgramID(0);
      setWpNO("");
      setWpName("");
      setProgramName("");
      setWpImage("");
    }
  };
  //#endregion

  //#region 新增工件
  const handleAddWorkpiece = () => {
    setAddWorkpieceModal(true);
  }
  //#endregion

  return (
    <>
      <Tab tabList={tabList} setTabList={setTabList} />
      <div className='pageContent'>
        {
          !showPageErr404 ?
            <>
              <div className="filterRow">
                <div>
                  <div className="inputWithLabel">
                    <div className="text-grey8 text12"></div>
                    <button className="btn btn-txtBlue-bgWhite btn-icontext h38" onClick={handleLeave}>
                      <div className="icon icon-Back"></div>
                      <div>{t("back")}</div>
                    </button>
                  </div>
                </div>
                <div>

                  <div className={`inputWithLabel ${carbonElectricityErr ? "errorspace" : ""} ${electricityPriceErr ? "errorspace" : ""}`}>
                    <label className="text-grey8 text12">{t("carbonIntensityElectricity")}</label>
                    <div className="order2 input-rightpadding wide">
                      <input className={`input ${!judgeEdit ? "disabled" : ""} ${carbonElectricityErr ? "error" : ""}`} name="carbonElectricity"
                        value={carbonElectricity} onChange={handleInputChange}
                        disabled={!judgeEdit}
                        onBlur={handleInputBlur} />
                      <div className="text disabled">kgCO2/kWh</div>
                    </div>
                    {
                      carbonElectricityErr ?
                        <div className='errortext'>{t("formatErrorCorrectNumerical")}</div> :
                        null
                    }
                  </div>
                  <div className={`inputWithLabel ${electricityPriceErr ? "errorspace" : ""} ${carbonElectricityErr ? "errorspace" : ""}`}>
                    <label className="text-grey8 text12">{t("electricityPricePerKwh")}</label>
                    <div className="order2 input-leftpadding">
                      <input className={`input ${!judgeEdit ? "disabled" : ""}  ${electricityPriceErr ? "error" : ""}`} name="electricityPrice"
                        value={electricityPrice} onChange={handleInputChange}
                        disabled={!judgeEdit}
                        onBlur={handleInputBlur} />
                      <div className="text disabled">$</div>
                    </div>
                    {
                      electricityPriceErr ?
                        <div className='errortext'>{t("formatErrorCorrectNumerical")}</div> :
                        null
                    }
                  </div>

                  {
                    judgeEdit ?
                      <>
                        <div className="inputWithLabel">
                          <div className="text-grey8 text12"></div>
                          <button className='btn btn-lv3 btn-onlytext h38'
                            onClick={handleLeave}>
                            <div className="text">{t("cancel")}</div>
                          </button>
                        </div>
                        <div className="inputWithLabel">
                          <div className="text-grey8 text12"></div>
                          <button className="btn btn-lv1 btn-icontext h38" onClick={handleSave}>
                            <div className="icon icon-Save"></div>
                            <div className="text">{t("save")}</div>
                          </button>
                        </div>
                      </>
                      :
                      <div className="inputWithLabel">
                        <div className="text-grey8 text12"></div>
                        <button className='btn btn-lv3 btn-icontext h38'
                          onClick={() => setJudgeEdit(true)}>
                          <div className="icon icon-Edit"></div>
                          <div className="text">{t("edit")}</div>
                        </button>
                      </div>
                  }
                </div>
              </div>
              <div className={`execution-overview ${reportContent?.periodReportType === 1 ? "flat-bottom" : ""} box-style-white-shadow mtop20`}>
                {/* <!--標題--> */}
                <div className="title">
                  {
                    judgeEdit ?
                      <>
                        <Row>
                          <Col xs={4}>
                            <input
                              name="reportName" value={reportName}
                              onChange={handleInputChange} onBlur={checkUniquePeriodName}
                              className={`form-control ${judgeCheckErr && (reportName === "" || reportName.length > 100 || !regex.test(reportName?.trim()) || !judgeUniquePeriodName) ? "input-error" : ""}`} />
                          </Col>
                          <Col xs={2}>
                            <Form.Select as="select" name='periodReportType'
                              value={periodReportType} onChange={handleInputChange}>
                              {
                                periodReportTypeList?.map((obj, index) => {
                                  return (
                                    <option key={index} value={obj.key}>{obj.name}</option>
                                  )
                                })
                              }
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row className='text-danger'>
                          <Col xs={4}>
                            {
                              judgeCheckErr && reportName === "" ?
                                <Form.Label className='text-danger mb-0 ms-2'>{t("requiredFields")}</Form.Label> :
                                <></>
                            }
                            {
                              judgeCheckErr && reportName !== "" && reportName.length > 100 ?
                                <Form.Label className='text-danger mb-0 ms-2'>
                                  {t("exceedsCharacterLimit")}
                                </Form.Label> :
                                <></>
                            }
                            {
                              judgeCheckErr && reportName !== "" && reportName.length <= 100 && !regex.test(reportName?.trim()) ?
                                <Form.Label className='text-danger mb-0'>
                                  {t("specialCharactersError")}
                                </Form.Label> :
                                <></>
                            }
                            {
                              judgeCheckErr && reportName !== "" && reportName.length <= 100 && regex.test(reportName?.trim()) && !judgeUniquePeriodName ?
                                <Form.Label className='text-danger mb-0 ms-2'>
                                  {t("nameAlreadyTaken")}
                                </Form.Label> :
                                <></>
                            }
                          </Col>
                          <Col xs={2}>

                          </Col>
                        </Row>
                      </>
                      :
                      <>
                        <span className="titile-main">{reportName}</span>
                        {renderPeriodReportType(periodReportType)}
                      </>
                  }
                </div>
                {/* <!--數值卡--> */}
                <div className="card-value-purpleblue">
                  <div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("equipmentUsed")}</div>
                        <div className="content">{reportContent?.equipmentSerialNumber}</div>
                      </div>
                      <div className="card">
                        <div className="title">{t("startTime")}</div>
                        <div className="content">
                          {(() => {
                            const trans = formatDateTimeRange(showFormatDate, showFormatTime, reportContent?.startTime);
                            return trans.startDate + " " + trans.startTime;
                          })()}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("endTime")}</div>
                        <div className="content">
                          {(() => {
                            const trans = formatDateTimeRange(showFormatDate, showFormatTime, reportContent?.endTime);
                            return trans.startDate + " " + trans.startTime;
                          })()}
                        </div>
                      </div>
                      <div className="card">
                        <div className="title">{t("durarion")}</div>
                        <div className="content">{formatTimeDays(reportContent?.duration, t)}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("powerConsumption")}</div>
                        <div className="content">{reportContent?.powerUsage.toFixed(2)}</div>
                      </div>
                      <div className="card">
                        <div className="title">{t("carbonEmissions")}</div>
                        <div className="content">
                          {(() => {
                            //碳排放量
                            let resultVal = "";
                            if (carbonElectricityErr) {
                              resultVal = "-"
                            }
                            else {
                              resultVal = (parseFloat(reportContent?.powerUsage) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                            }
                            return resultVal
                          })()}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="ml-0 card">
                        <div className="title">{t("averagePower")}</div>
                        <div className="content">
                          {(() => {
                            //平均功率 
                            let resultVal = (parseFloat(reportContent?.powerUsage) / reportContent?.duration * 3600).toFixed(2);
                            return resultVal
                          })()}
                        </div>
                      </div>
                      <div className="card">
                        <div className="title">{t("electricityCost")}</div>
                        <div className="content">$
                          {(() => {
                            //電費
                            let resultVal = "";
                            if (electricityPriceErr) {
                              resultVal = "-"
                            }
                            else {
                              resultVal = (parseFloat(reportContent?.powerUsage) * parseFloat(electricityPrice)).toFixed(2);
                            }
                            return resultVal;
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                periodReportType === 1 ?
                  <div className="part-overview-timePeriodCapture box-style-purple-shadow flat-top">
                    {/* <!--第1排 選擇的工件--> */}
                    <div className="part">
                      <div>
                        {/* <!--沒有圖片的話，在這裡加上 className="imgreplace"加上底色--> */}
                        {
                          wpImage != "" ?
                            <img src={wpImage} alt={wpNO} /> :
                            <div className="imgreplace"></div>
                        }
                      </div>
                      <div>
                        <div>
                          <div className="title">
                            {
                              judgeEdit ?
                                <>
                                  <Select
                                    value={selectedOptionPart}
                                    onChange={handleChangePart}
                                    options={optionsPart}
                                    isSearchable
                                    placeholder={t("selectPartProgram")}
                                    isClearable
                                  />
                                  <div className='d-flex align-content-end justify-content-end mt-3'>
                                    <span className='text-grey4'>{t("cantFindPart")}</span>
                                    <div className='cursor-pointer d-flex align-content-center' onClick={handleAddWorkpiece}>
                                      <span className='text-blue'>{t("createNewOne")}</span>
                                      <span className="material-symbols-rounded addPart">add</span>
                                    </div>
                                  </div>
                                </> : (
                                  wpID != 0 && programID != 0 ?
                                    <>
                                      <span className="title-sub-blue">{programName}</span><br />
                                      <span className="titile-main">{wpNO} {wpName}</span>
                                    </> :
                                    <>
                                      {wpID == 0 && programID === 0 ?
                                        <span className="noWorkpiece" onClick={() => setJudgeEdit(true)}>
                                          <span className="material-symbols-rounded-editWorkpiece mb-0">edit</span>{t("inputPartName")}
                                        </span> :
                                        wpID != 0 ? <span className="titile-main">{wpNO} {wpName}</span> :
                                          <span className="titile-main">{programName}</span>}
                                    </>
                                )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box card-value-white">
                      <div className="ml-0 card">
                        <div className="title">{t("producedCount")}</div>
                        <div className="content">
                          {
                            judgeEdit ?
                              <>
                                <input className='form-control'
                                  name="productionQty" value={productionQty}
                                  onChange={handleInputChange} />
                                {
                                  periodReportType === 1 && judgeCheckErr && productionQty === "" ?
                                    <Form.Label className='text-danger mb-0'>
                                      {t("requiredFields")}
                                    </Form.Label> :
                                    <></>
                                }
                                {
                                  periodReportType === 1 && judgeCheckErr && productionQty !== "" && (!isNumber(parseFloat(productionQty)) || !isPositiveInteger(parseFloat(productionQty))) ?
                                    <Form.Label className='text-danger mb-0'>
                                      {t("formatErrorGreaterZero")}
                                    </Form.Label> :
                                    <></>
                                }
                              </>
                              :
                              productionQty
                          }
                        </div>
                      </div>
                      <div className="card">
                        <div className="title">{t("averagePowerConsumptionPerPart")}</div>
                        <div className="content">
                          {(() => {
                            //每個部件平均生產用電量 (度 kWh)
                            let resultVal = "";
                            if (productionQty === "" || (!isNumber(parseFloat(productionQty)) || !isPositiveInteger(parseFloat(productionQty)))) {
                              resultVal = "-"
                            }
                            else {
                              resultVal = (parseFloat(reportContent?.powerUsage) / parseInt(productionQty)).toFixed(2);
                            }

                            return resultVal
                          })()}
                        </div>
                      </div>
                      <div className="card">
                        <div className="title">{t("averageCarbonEmissionsPerPart")}</div>
                        <div className="content">
                          {(() => {
                            //每個部件平均生產碳排放量 (克 g)
                            let resultVal = "";
                            let carbonEmissions = 0;
                            if (carbonElectricityErr || productionQty === "" || (!isNumber(parseFloat(productionQty)) || !isPositiveInteger(parseFloat(productionQty)))) {
                              resultVal = "-";
                            }
                            else {
                              carbonEmissions = (parseFloat(reportContent?.powerUsage) * parseFloat(carbonElectricity) * 1000).toFixed(2);
                              resultVal = parseFloat(carbonEmissions) / parseInt(productionQty);
                              resultVal = resultVal.toFixed(2);
                            }
                            return resultVal;
                          })()}
                        </div>
                      </div>
                      <div className="card">
                        <div className="title">{t("averageElectricityCostPerPart")} </div>
                        <div className="content">$
                          {(() => {
                            //每個部件平均電費
                            let resultVal = "";
                            let electricityCost = 0;
                            if (electricityPriceErr || productionQty === "" || (!isNumber(parseFloat(productionQty)) || !isPositiveInteger(parseFloat(productionQty)))) {
                              resultVal = "-"
                            }
                            else {
                              electricityCost = (parseFloat(reportContent?.powerUsage) * parseFloat(electricityPrice)).toFixed(2);
                              resultVal = parseFloat(electricityCost) / parseInt(productionQty);
                              resultVal = resultVal.toFixed(2);
                            }
                            return resultVal;
                          })()}
                        </div>
                      </div>
                    </div>
                  </div> : null
              }

              <main id="power_chart_timeline" className="box-style-white-shadow mtop20">
                <div className="margin20">
                  <div className="table-scroll-justify ">
                    <div>
                      <div className="apache_power_now power_now_timePeriod">
                        {
                          timelineOptions != null ?
                            <ReactEcharts option={timelineOptions} echarts={echarts} /> : null
                        }
                        {
                          !loadChart ?
                            <div className="loading chartbox">
                              <div className="spinner"></div>
                            </div> : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </main>

              <div className="layout-normal box-style-white-shadow mtop20">
                {/* <!--第3排 備註 Remark--> */}
                <div className="title">{t("remark")}<span className="remark-txt">({remark?.length ?? 0}/300)</span></div>
                <div className="content">
                  {
                    judgeEdit ?
                      <Form.Control
                        as="textarea" rows={4} name="remark" value={remark}
                        className={judgeEdit && remark?.length > 300 ? 'input-error' : ""}
                        onChange={handleInputChange} autoComplete="off"
                      /> :
                      remark
                  }
                  {
                    judgeEdit && remark?.length > 300 ?
                      <p className='text-danger mb-0'>{t("exceedsCharacterLimit")}</p> :
                      null
                  }
                </div>
              </div>
            </> :
            <PageErr404 judgeBackHome={false} backURL={`/Power/${tabName}`} />
        }

      </div>

      {/* 新增工件 */}
      <AddWorkpiece
        showModal={addWorkpieceModal}
        handleClose={handleAddWorkpieceClose}
        setSelectedOptionPart={setSelectedOptionPart}
        optionsPart={optionsPart}
        setOptionsPart={setOptionsPart}
        setWpID={setWpID}
        setWpNO={setWpNO}
        setWpName={setWpName}
        setWpImage={setWpImage}
        setProgramID={setProgramID}
        setProgramName={setProgramName}
      />

      {/* 確定離開嗎？ */}
      <MessageModal
        title={t("leavePage")}
        content={`${t("leavePageContent1")}<br /><br />${t("leavePageContent2")}`}
        showModal={showLeavePageModal}
        handleConfirmData={() => navigate(`/Power/${tabName}`)}
        size="xs"
        confirmModalTxt={t("leaveSaving")}
        cancelTxt={t("stayThisPage")}
        cancelModal={handleLeavePageClose}
        handleClose={handleLeavePageClose}
      />

      <ToastContainer />
    </>
  );
};

export default TimePeriodCaptureContent;