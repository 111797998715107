import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const MyPagination = ({ totalPages, currentPage, onPageChange }) => {
  const visiblePageCount = 10;
  
  const startPage = Math.max(1, currentPage - Math.floor(visiblePageCount / 2));
  const endPage = Math.min(totalPages, startPage + visiblePageCount - 1);

  const pageItems = Array.from({ length: endPage - startPage + 1 }, (_, index) => {
    const pageNumber = startPage + index;
    return (
      <Pagination.Item
        key={pageNumber}
        active={pageNumber === currentPage}
        onClick={() => handlePageChange(pageNumber)}
      >
        {pageNumber}
      </Pagination.Item>
    );
  });

  const handlePageChange = (page) => {
    // 在此处理页面更改事件
    onPageChange(page);
  };

  return (
    <Pagination>
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
      {pageItems}
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    </Pagination>
    // <Pagination>
    //   <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    //   {/* 创建页码按钮 */}
    //   {Array.from({ length: totalPages }).map((_, index) => (
    //     <Pagination.Item
    //       key={index + 1}
    //       active={index + 1 === currentPage}
    //       onClick={() => handlePageChange(index + 1)}
    //     >
    //       {index + 1}
    //     </Pagination.Item>
    //   ))}
    //   <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    // </Pagination>
  );
};

export default MyPagination;