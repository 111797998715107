import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Modal.css';
import './icon.css';
// import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from '../src/utils/i18n';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "page/Login"; //登入頁面
import LoginPreMain from "page/LoginPreMain"; //登入轉載頁面
import ForgetPaw from "page/ForgetPaw"; //忘記密碼

import SetPWD from "page/pwd/setPWD"; //重設密碼

import MenuOld from "page/menuOld"; //舊主畫面
import Menu from "page/menu"; //主畫面
import MonitorMain from "page/monitor/monitorMain"; //即時監控主畫面
import MachineStatus from "page/monitor/machineStatus"; //即時監控主畫面-設備狀態
import ComponentDiagnosis from "page/monitor/componentDiagnosis"; //即時監控主畫面-部件診斷
import LifetimeEstimation from "page/monitor/lifetimeEstimation"; //即時監控主畫面-壽命估算
import ConsumableSupplies from "page/monitor/consumableSupplies"; //即時監控主畫面-耗材
import PowerConsumption from "page/monitor/powerConsumption"; //即時監控主畫面-用電量
import UtilizationRate from "page/monitor/utilizationRate"; //即時監控主畫面-設備稼動率
import AnalyticsMain from "page/analytics/AnalyticsMain"; //分析
// import History from "page/history/history"; //歷史數據
import HistoryMain from "page/history/HistoryMain"; //歷史數據主頁
import AppsMain from "page/apps/appsMain"; //小程式
import RawDataMain from "page/rawData/rawDataMain"; //原始數據
import FolderMain from "page/folder/FolderMain"; //資料夾
import ReportsMain from "page/reports/ReportsMain"; //報告
import ReportsContent from "page/reports/ReportsContent"; //報告內容


import WorkpiecesMain from "page/power/WorkpiecesMain"; //工件
import WorkpiecesDeatil from "page/power/data/powerCarbon/workpiecesDetail"; //工件=>工件明細1:Workpieces明細 2:Programs明細
import ProgramDetail from "page/power/data/powerCarbon/programDetail";  //工件=>工件時間段明細
import TimePeriodCaptureContent from "page/power/data/timePeriodCapture/TimePeriodCaptureContent";  //手動擷取

import WorkPiece from "page/apps/data/workpieceCalc/WorkPiece"; //工件計算機
import APS from "page/apps/data/aps/APS"; //APS
import WorkOrderSchedulingMain from "page/apps/data/aps/workOrderScheduling/WorkOrderSchedulingMain"; //工單
import SchedulingChart from "page/apps/data/aps/schedulingChart/SchedulingChart"; //工單
import Folder from "page/apps/data/aps/folder/Folder"; //資料夾
import StorageStatus from "page/apps/data/aps/StorageStatus/StorageStatus"; //倉儲狀態

import Set from 'page/set/set'; //設定

import PageErr404 from "page/PageErr404";   //頁面不存在

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<div className="loading-circle-div"><div className="loading-circle"></div></div>}>
        <Routes>
          {/* <Route path="/" element={<MenuOld />} /> */}
          {/* 登入頁 */}
          <Route path="/"
            element={
              <Login />
            }
          />

          {/* 登入跳轉頁 */}
          <Route path="/loginPreMain"
            element={
              <LoginPreMain />
            }
          />

          {/* 忘記密碼 */}
          <Route path="/forgetPaw"
            element={
              <ForgetPaw />
            }
          />

          {/* 設定密碼 */}
          <Route path="/validation/:behavior/:token"
            element={
              <SetPWD />
            }
          />

          {/* <Route path="/Monitor/:tabName" 
            element={
              <Menu>
                <MonitorMain />
              </Menu>
            } 
          /> */}

          {/* 艾創點設備狀態 */}
          <Route path="/Monitor/MachineStatus"
            element={
              <Menu>
                <MachineStatus />
              </Menu>
            }
          />

          {/* 艾創點部件診斷 */}
          <Route path="/Monitor/ComponentDiagnosis"
            element={
              <Menu>
                <ComponentDiagnosis />
              </Menu>
            }
          />

          {/* 艾創點壽命估算 */}
          <Route path="/Monitor/LifetimeEstimation"
            element={
              <Menu>
                <LifetimeEstimation />
              </Menu>
            }
          />

          {/* 艾創點耗材 */}
          <Route path="/Monitor/ConsumableSupplies"
            element={
              <Menu>
                <ConsumableSupplies />
              </Menu>
            }
          />

          {/* 艾創點用電量 */}
          <Route path="/Monitor/PowerConsumption"
            element={
              <Menu>
                <PowerConsumption />
              </Menu>
            }
          />

          {/* 艾創點設備稼動率 */}
          <Route path="/Monitor/UtilizationRate"
            element={
              <Menu>
                <UtilizationRate />
              </Menu>
            }
          />

          {/* 分析主頁 */}
          <Route path="/Analytics/:tabName"
            element={
              <Menu>
                <AnalyticsMain />
              </Menu>
            }
          />

          <Route path="/History/:tabName"
            element={
              <Menu>
                <HistoryMain />
              </Menu>
            }
          />

          {/* 數據主頁 */}
          <Route path="/RawData/:tabName"
            element={
              <Menu>
                <RawDataMain />
              </Menu>
            }
          />

          {/* 小程式主頁 */}
          <Route path="/Apps"
            element={
              <Menu>
                <AppsMain />
              </Menu>
            }
          />

          {/* 資料夾主頁 */}
          <Route path="/Folder/:tabName"
            element={
              <Menu>
                <FolderMain />
              </Menu>
            }
          />

          {/* 報告主頁 */}
          <Route path="/Reports"
            element={
              <Menu>
                <ReportsMain />
              </Menu>
            }
          />

          {/* 報告內容 */}
          <Route path="/Reports/:reportID"
            element={
              <Menu>
                <ReportsContent />
              </Menu>
            }
          />

          {/* 工件主頁 */}
          <Route path="/Power/:tabName"
            element={
              <Menu>
                <WorkpiecesMain />
              </Menu>
            }
          />

          {/*  */}
          <Route path="/Power/:tabName/:workpieceID/:programID"
            element={
              <Menu>
                <WorkpiecesDeatil />
              </Menu>
            }
          />

          {/*  */}
          <Route path="/Power/:tabName/:workpieceID/:programID/:execuutionID"
            element={
              <Menu>
                <ProgramDetail />
              </Menu>
            }
          />

          <Route path="/Power/:tabName/:reportID"
            element={
              <Menu>
                <TimePeriodCaptureContent />
              </Menu>
            }
          />

          {/* 工件計算機 */}
          <Route path="/WorkPiece" element={<WorkPiece />} />

          {/* APS-工單和排程 */}
          <Route path="/APS/WorkOrderScheduling"
            element={
              <APS>
                <WorkOrderSchedulingMain />
              </APS>
            }
          />
          
          {/* APS-排程甘特圖 */}
          <Route path="/APS/SchedulingChart"
            element={
              <APS>
                <SchedulingChart />
              </APS>
            }
          />

          {/* APS-資料夾 */}
          <Route path="/APS/Folder"
            element={
              <APS>
                <Folder />
              </APS>
            }
          />

          {/* APS-倉儲狀態 */}
          <Route path="/APS/StorageStatus"
            element={
              <APS>
                <StorageStatus />
              </APS>
            }
          />

          {/* 404頁面 */}
          <Route path="*" element={<PageErr404 />} />
        </Routes>
      </Suspense>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();