import React from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';

//#region 圓餅圖
export const DonutChart = ({Operating, Idle, Error, NotUse, onChartReady, t}) => {
    const customColors = ['#7CC629', '#FFAA00', '#F66F6F', '#888888'];
    // ECharts options
    const options = {
      title: {
        show: false
      },
      series: [
        {
          name: 'Data',
          type: 'pie',
          radius: ['50%', '90%'], // Outer and inner radii for the pie chart (creating a hole)
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: Operating, name: t("running"), itemStyle: { color: customColors[0] } },
            { value: Idle, name: t("idle"), itemStyle: { color: customColors[1] } },
            { value: Error, name: t("error"), itemStyle: { color: customColors[2] } },
            { value: NotUse, name: t("offline"), itemStyle: { color: customColors[3] } }
          ],
        },
      ],
    };
  
    return <ReactEcharts option={options} echarts={echarts} className='w-100 h-100'
      onChartReady={onChartReady}
    />;
};
//#endregion