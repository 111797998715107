import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';
import "./modal.css";

import { formatDateTimeRange } from 'utils/commonFun';

import { apiCreateWorkpiece, apiPostPeriodReport } from 'utils/Api';

const CapturePeriodConfirm = ({showModal, handleClose, currentStep, setCurrentStep,
  handleNextStep, periodName, choiceMachine, choiceDate, periodReportType, wpID, setWpID, wpNO, wpName, 
  programID, setProgramID, programName, productionQty, remark, displayCreatPart, setCurrentReportID
}) => {
  const { t } = useTranslation("powerCarbon");
  const { showFormatDate, showFormatTime, periodReportTypeList } = useContext(MyUserContext);

  const [displayErr, setDisplayErr] = useState(false);

  useEffect(() => {
    if(showModal){
      setCurrentStep(3);
      setDisplayErr(false);
    }
  }, [showModal]);

  //#region 確認按鈕
  const handleConfirm = async () => {
    var judgeErr = false;
    let checkResult = { result: false, data: null };
    let confirmWpID = 0;
    let confirmProgramID = 0;

    if(periodReportType === 1){  //production類別有工件需要優先檢查
      checkResult = await checkUniquePart();
      judgeErr = checkResult.result;

      if (!judgeErr) {
        const data = checkResult.data;
        confirmWpID = data?.wpID || 0;
        confirmProgramID = data?.programID || 0;
      }
    }

    if(!judgeErr){
      const [httpStatus, reponseData] = await handleApiPostPeriodReport(confirmWpID, confirmProgramID);
      if(httpStatus == "200"){
        if(reponseData.statusCode === "20100"){
          setDisplayErr(false);
          setCurrentReportID(reponseData.data?.reportID);
          handleNextStep();
        }
        else if(reponseData.statusCode === "DL001"){
          setDisplayErr(true);
        }
        else{
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else{
      setDisplayErr(true);
    }
  }
  //#endregion

  //#region 工件資訊是否重複
  const checkUniquePart = async () => {
    var result = false;
    let data = null;
    const [httpStatus, reponseData] = await handleApiCreateWorkpiece(true);
    if(httpStatus == "200"){
      if(reponseData.statusCode === "DL001" || reponseData.statusCode === "42200"){
        result = true;
      }
      else if(reponseData.statusCode === "20100"){
        data = reponseData.data;

        if(data.wpID != null){
          setWpID(data.wpID)
        }
        else{
          setWpID(0);
        }

        if(data.programID != null){
          setProgramID(data.programID)
        }
        else{
          setProgramID(0);
        }
      }
    }

    return { result, data };
  }
  //#endregion

  //#region 檢查工件資訊是否重複API
  const handleApiCreateWorkpiece = async (doCreate) => {
    let postJson = {
      doCreate: doCreate
    };

    if(wpNO != "" && wpName != ""){
      postJson.wpNO = wpNO;
      postJson.wpName = wpName;
    }

    if(programName != ""){
      postJson.programName = programName;
    }
    
    let response = await apiCreateWorkpiece(postJson);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 新增以及修改手動擷取程式用電量分析報告
  const handleApiPostPeriodReport = async(confirmWpID, confirmProgramID) => {
    let postJson = {
      action: "insert",
      reportName: periodName,
      periodReportType: periodReportType,
      equipmentSerialNumber: choiceMachine?.machineSn,
      startDate: choiceDate[0],
      endDate: choiceDate[1]
    };

    if(periodReportType === 1){
      postJson.productionQty = parseInt(productionQty);

      if(confirmWpID !== 0){
        postJson.wpID = confirmWpID;
      }

      if(confirmProgramID !== 0){
        postJson.programID = confirmProgramID;
      }
    }

    if(remark != ""){
      postJson.remark = remark;
    }

    let programListResponse = await apiPostPeriodReport(postJson);
    if(programListResponse){
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  


  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("startCapturePeriod")}<br />
            <p className='planColTitle mb-0'>{t("startCapturePeriodSubTitle")}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className='mb-3'>
              <Col xs={4}>
                {t("periodName")}
              </Col>  
              <Col xs={8}>
                {periodName}
              </Col> 
            </Row>
            <Row className='mb-3'>
              <Col xs={4}>
                {t("equipmentUsed")}
              </Col>  
              <Col xs={8}>
                {choiceMachine?.mName}
              </Col> 
            </Row>
            <Row className='mb-3'>
              <Col xs={4}>
                {t("selectPeriod")}
              </Col>  
              <Col xs={8}>
              {
                choiceDate ?
                  <>
                    {/* {convertDateFormat(choiceDate[0])} ~ <br />
                    {convertDateFormat(choiceDate[1])} */}

                    {(() => {
                      const trans = formatDateTimeRange(showFormatDate, showFormatTime, choiceDate[0], choiceDate[1]);
                      return trans.startDate + " " + trans.startTime + " ~ " + trans.endDate + " " + trans.endTime;
                    })()}
                  </> : <></>
              }
              </Col> 
            </Row>
            <Row className='mb-3'>
              <Col xs={4}>
                {t("periodReportType")}
              </Col>  
              <Col xs={8}>
              {periodReportTypeList.find(d => d.key === periodReportType)?.name}
              </Col> 
            </Row>
            {
              periodReportType === 1 ?
                <>
                  <Row className='mb-3'>
                    <Col xs={4}>
                      {t("partProduced")}
                    </Col>  
                    <Col xs={8}>
                    {
                      wpNO !== "" && programName !== "" ?
                        <>{wpNO} {wpName} / {programName}</> : null
                    }
                    {
                      wpNO !== "" && programName === "" ?
                        <>{wpNO} {wpName}</> : null
                    }
                    {
                      wpNO === "" && programName !== "" ?
                        <>{programName}</> : null
                    }
                    {
                      wpNO === "" && programName === "" ?
                        <>-</> : null
                    }
                    </Col> 
                  </Row>
                  <Row className='mb-3'>
                    <Col xs={4}>
                      {t("producedCount")}
                    </Col>  
                    <Col xs={8}>
                      {productionQty}
                    </Col> 
                  </Row>
                </>
                 : null
            }
            <Row className='mb-3'>
              <Col xs={4}>
                {t("remark")}
              </Col>  
              <Col xs={8}>
              {
                remark != "" ? remark : "-"
              }
              </Col> 
            </Row>
            {
              displayErr ?
                <Row className='text-alert'>
                  <Col>
                    {t("captureConfirmError")}
                  </Col>
                </Row> : null
            }
          </Form>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
              <button type="button" className="btn btn-lv3 fat" onClick={()=>{handleClose(3)}}>
                {t("previous")}
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-lv1 fat" onClick={handleConfirm}>
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>

        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} className='ps-0'>
              <button type="button" className="button-previous" onClick={()=>{handleClose(3)}}>
                {t("previous")}
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end pe-0' onClick={handleConfirm}>
              <button className="button-primary">
                {t("confirm")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
}

export default CapturePeriodConfirm;