import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Modal } from 'react-bootstrap';
import { apiSingleOrder } from 'utils/Api';
import { convertDateFormat, formatTimeDays} from 'utils/commonFun';
import { ToastContainer, toast } from 'react-toastify';
import '../APS-modal-viewProcess.css';

import Process from "page/apps/data/aps/modal/Process"; //工序
import VewAllProcess from "page/apps/data/aps/modal/VewAllProcess"; //全部工序
import WorkOrderModal from "page/apps/data/aps/modal/WorkOrderModal"; //工單
import { faL } from '@fortawesome/free-solid-svg-icons';

const WorkOrderDetail = ({showModal, handleClose, loadOrderList, orderID, loadOrderCountAndStatus}) => {
  const { t, i18n } = useTranslation("aps");  
  const { overtime, authority } = useContext(MyUserContext);

  const [workOrderDetail, setWorkOrderDetail] = useState(null);

  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false); //是否工單Modal
  const handleWorkOrderClose = () => {
    setShowWorkOrderModal(false);
  }

  useEffect(() => {
    if(showModal){
      handleSingleOrder();
    }
  }, [showModal]);

  //#region 載入單一一筆工單
  const handleSingleOrder = async () => {
    const [httpStatus, reponseData] = await handleApiSingleOrder();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        let data = reponseData.data;

        setWorkOrderDetail(data);
      }
    }
  }
  //#endregion

  //#region 載入單一一筆工單API
  const handleApiSingleOrder = async () => {
    let postJson = {
      orderID: orderID
    };
    let response = await apiSingleOrder(postJson);

    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion


  //#regin 編輯
  const handleEdit = () => {
    handleClose();
    setShowWorkOrderModal(true);
    loadOrderList(false);
  }
  //#endregion

  const handleCancel = () => {
    handleClose();
    loadOrderList(true);
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <div className="modal-header">
          <div className='d-flex align-items-center'>
            <div className="modal-title">
              {workOrderDetail?.requirements?.orderNo}-{workOrderDetail?.requirements?.processNo}
            </div>
            {
              workOrderDetail?.requirements?.orderStatus === 0 ?
                <div className="status status-padding bg unscheduled">
                  {t("unscheduled")}
                </div> : null
            }
            {
              workOrderDetail?.requirements?.orderStatus === 1 ?
                <div className="status status-padding bg scheduled">
                  {t("scheduled")}
                </div> : null
            }
            {
              workOrderDetail?.requirements?.orderStatus === 2 ?
                <div className="status status-padding bg inproduction">
                  {t("started")}
                </div> : null
            }
            {
              workOrderDetail?.requirements?.orderStatus === 3 ?
                <div className="status status-padding bg paused">
                  {t("paused")}
                </div> : null
            }
            {
              workOrderDetail?.requirements?.orderStatus === 4 ?
                <div className="status status-padding bg aborted">
                  {t("aborted")}
                </div> : null
            }
            {
              workOrderDetail?.requirements?.orderStatus === 5 ?
                <div className="status status-padding bg completed">
                  {t("completed")}
                </div> : null
            }
          </div>
          <button type="button" className="close" 
            data-dismiss="modal" aria-label="Close" 
            onClick={handleClose}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          <div className="modal-view">
          <div className="gp">
            <h2>{t("requirements")}</h2>
            <ul className="gp-greyline">
              <li className="lv1 side2">
                <div className="name">{t("workOrderID")}</div>
                <div className="content">{workOrderDetail?.requirements?.orderNo}-{workOrderDetail?.requirements?.processNo}</div>
              </li>
              <li className="lv1 side2">
                <div className="name">{t("part")}/{t("process")}</div>
                <div className="content">
                  {workOrderDetail?.requirements?.wpNO}&nbsp;{workOrderDetail?.requirements?.wpName}&nbsp;/&nbsp;
                  {workOrderDetail?.requirements?.processNo}&nbsp;{workOrderDetail?.requirements?.processName}
                </div>
              </li>
              <li className="lv1 side2">
                <div className="name">{t("scheduledDeliveryDate")}</div>
                <div className="content">{convertDateFormat(workOrderDetail?.requirements?.scheduledDeliveryDate, "MMM DD")}</div>
              </li>
              <li className="lv1 side2">
                <div className="name">{t("scheduledProductionQuantity")}</div>
                <div className="content">{workOrderDetail?.requirements?.scheduledQuantity}</div>
              </li>
            </ul>
          </div>

          <div className="gp">
            <h2>{t("schedule")}</h2>
            <ul className="gp-greyline">
              <li className="lv1 side2">
                <div className="name">{t("estDuration")}</div>
                <div className="content">
                {(() => {
                  const minEstimatedDuration = workOrderDetail?.schedule?.minEstimatedDuration;
                  const maxEstimatedDuration = workOrderDetail?.schedule?.maxEstimatedDuration;

                  if(minEstimatedDuration != null){
                    return `${formatTimeDays(minEstimatedDuration, t)}${maxEstimatedDuration != null ? " ~ " + formatTimeDays(maxEstimatedDuration, t) : ""}`
                  }
                })()} 
                </div>
              </li>
              {
                workOrderDetail?.requirements?.orderStatus != 0 ?
                  <>
                    <li className="lv1 side2">
                      <div className="name">{t("scheduledTime")}</div>
                      <div className="content">
                      {(() => {
                        const datetimeStart = workOrderDetail?.schedule?.datetimeStart;
                        const datetimeEnd = workOrderDetail?.schedule?.datetimeEnd;
      
                        if(datetimeStart != null){
                          return `${convertDateFormat(datetimeStart, "MMM DD hh:mm:ss A")} ~ ${datetimeEnd != null ? convertDateFormat(datetimeEnd, "MMM DD hh:mm:ss A") : ""}`
                        }
                      })()}
                      </div>
                    </li>
                    <li className="lv1 side2">
                      <div className="name">{t("equipment")}</div>
                      <div className="content">{workOrderDetail?.schedule?.equipment?.mShortName}&nbsp;&nbsp;{workOrderDetail?.schedule?.equipment?.machineSn}</div>
                    </li>
                    <li className="lv1 side2">
                      <div className="name">{t("program")}</div>
                      <div className="content">{workOrderDetail?.schedule?.programName}</div>
                    </li>
                  </> : null
              }
            </ul>
          </div>

          <div className="gp">
            <h2>{t("processInfo")}</h2>
            <ul className="gp-greyline">
            {
                workOrderDetail?.requirements?.orderStatus != 0 ?
                  <>
                    <li className="lv1 side2">
                      <div className="name">{t("batchProductionQuantity")}</div>
                      <div className="content">{workOrderDetail?.processInfo?.batchProductionQuantity}</div>
                    </li>
                    <li className="lv1 side2">
                      <div className="name">{t("duration")}</div>
                      <div className="content">{formatTimeDays(workOrderDetail?.processInfo?.programDuration, t)}</div>
                    </li>
                  </> : null
            }
            
              <li className="lv1 side2">
                <div className="name">{t("applicableEquipment")}</div>
                <div className="content">
                  {(() => {
                    let result = "";

                    if(workOrderDetail?.processInfo?.equipment.length > 0){
                      for(let item of workOrderDetail?.processInfo?.equipment){
                        result += item.mShortName + ", ";
                      }
                      return result.substring(0, result.length - 2);
                    }
                  })()}
                </div>
              </li>
              <li className="lv1 side2">
                <div className="name">{t("remark")}</div>
                <div className="content">{workOrderDetail?.processInfo?.processRemark}</div>
              </li>
            </ul>
          </div>

          {
            workOrderDetail?.orderRemark != null ?
            <div className="gp">
              <h2>{t("remark")}</h2>
              <ul className="gp-greyline">
                <li className="lv1 side2">
                  <div className="name">{workOrderDetail?.orderRemark}</div>
                </li>
              </ul>
            </div> : null
          }
          
        </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-gp">
            <div>
              {
                workOrderDetail?.requirements?.orderStatus === 0 || workOrderDetail?.requirements?.orderStatus === 1 ?
                  <button className="btn-item btn-icontext-rwd btn-lv3" onClick={handleEdit}>
                    <div className="icon icon-Edit"></div>
                    <span>{t("edit")}</span>
                  </button> : null
              }
            </div>
            <button type="submit" className="btn btn-lv1 fat" onClick={handleCancel}>{t("cancel")}</button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

      <WorkOrderModal 
        showModal={showWorkOrderModal}
        handleClose={handleWorkOrderClose}
        behavior="update"
        loadOrderList={loadOrderList}
        orderID={orderID}
        loadOrderCountAndStatus={loadOrderCountAndStatus}
      />
    </>
  );
};

export default WorkOrderDetail;