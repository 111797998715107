import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { apiProcessList, apiDelProcess } from 'utils/Api';
import { formatTimeDays } from 'utils/commonFun';

import Process from "page/apps/data/aps/modal/Process"; //工序
import Detail from "page/apps/data/aps/modal/Detail"; //內容
import VewAllProcess from "page/apps/data/aps/modal/VewAllProcess"; //全部工序

import MyPagination from 'components/basic/Pagination';
import MessageModal from 'components/basic/MessageModal';
import { ToastContainer, toast } from 'react-toastify';

const Folder = () => {
  const { t, i18n } = useTranslation("aps");
  const { overtime, show404Page } = useContext(MyUserContext);

  const [processData, setProcessData] = useState([]);  //工序資料
  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字
  const [currentPage, setCurrentPage] = useState(1);
  const [descending, setDescending] = useState(false); //按工件正排 / 倒排 (預設為正排)
  const [behavior, setBehavior] = useState("insert"); //打開Modal是insert:新增 update:編輯
  const [processID, setProcessID] = useState(0); //processID
  const [wpID, setWpID] = useState(0); //工件流水號
  const [wpNO, setWpNO] = useState(0); //工件編號
  const [wpName, setWpName] = useState(0); //工件名稱

  const scrollRef = useRef(null);

  const [showProcessDataModal, setShowProcessDataModal] = useState(false); //是否顯示工序Modal
  const handleProcessDataClose = () => {
    setShowProcessDataModal(false);
  }

  const [showDelProcessModal, setShowDelProcessModal] = useState(false); //是否顯示刪除工序？Modal
  const handleDelProcessClose = () => setShowDelProcessModal(false);

  const [showDetailModal, setShowDetailModal] = useState(false); //是否顯示內容Modal
  const handleDetailClose = () => setShowDetailModal(false);

  const [showVewAllProcessModal, setShowVewAllProcessModal] = useState(false); //是否顯示全部工序Modal
  const handleVewAllProcessClose = () => setShowVewAllProcessModal(false);

  const [showCannotProcessModal, setShowCannotProcessModal] = useState(false); //是否顯示不可刪除工序Modal
  const handleCannotProcessClose = () => setShowCannotProcessModal(false);

  //#region 新增按鈕
  const handelCreate = () => {
    setBehavior("insert");
    setProcessID(0);
    setShowProcessDataModal(true);
  }
  //#endregion

  useEffect(() => {
    loadProcessList();
  }, [currentPage, descending]);

  // for search keywork return to page 1
  useEffect(() => {
    if (processData?.totalItems > 0 && processData?.detail?.length === 0) {
      setCurrentPage(1);
    }
  }, [processData]);

  //#region 載入工序列表
  const loadProcessList = async () => {
    const [httpStatus, reponseData] = await handleApiProcessList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setProcessData(reponseData.data);
      }
      else if (reponseData.statusCode === "40300") {
        show404Page();
      }
      else if (reponseData.statusCode === "40103") {
        overtime();
      }
    }
  }
  //#endregion

  //#region 載入Process列表API
  const handleApiProcessList = async () => {
    let postJson = {
      keyword: keyword,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30,
      descending: descending
    };

    let response = await apiProcessList(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
  };
  //#endregion

  //#region 查詢按鈕
  const handleSearch = () => {
    loadProcessList();
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region 編輯按鈕
  const handleEdit = (processID) => {
    setProcessID(processID);
    setBehavior("update");
    setShowProcessDataModal(true);
  }
  //#endregion

  //#region 刪除按鈕
  const handleDel = async (processID) => {
    setProcessID(processID);

    const [httpStatus, reponseData] = await handleApiDelProcess(false, processID);
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setShowDelProcessModal(true);
      }
      else if (reponseData.statusCode === "DL003") {
        setShowCannotProcessModal(true);
      }
    }
  }
  //#endregion

  //#region 刪除工序
  const handleDelProcess = async () => {
    const [httpStatus, reponseData] = await handleApiDelProcess(true, processID);
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<span>{t("deletedSuccessfully")}</span>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setShowDelProcessModal(false);
        loadProcessList();
      }
    }
  }
  //#endregion

  //#region API刪除工序
  const handleApiDelProcess = async (doDelete, processID) => {
    let postJson = {
      processID: processID,
      doDelete: doDelete
    }
    let response = await apiDelProcess(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 內容按鈕
  const handleDetail = (processID) => {
    setProcessID(processID);
    setShowDetailModal(true);
  }
  //#endregion

  //#region 全部工序按鈕
  const handleViewAllProcess = (wpID, wpNO, wpName) => {
    setWpID(wpID);
    setWpNO(wpNO);
    setWpName(wpName);
    setShowVewAllProcessModal(true);
  }
  //#endregion

  //#region 排序欄位
  //2025-03-11 新版JoJo問ChatGPT改成將排序按鈕直接做在表頭文字，箭頭變成只是輔助視覺效果
  const orderByCol = () => {
    setDescending(!descending); // 只切換升降序
  };
  //#endregion

  return (
    <div className="page">
      <div className="APS-tab scroll">
        <div className="tab-clicked">{t("process")}</div>
        {/* <div className="tab-unclick">{t("equipment")}</div> */}
      </div>
      <div className="page-content">
        <div className="filterRow">
          <div>
            <div className="btn-search">
              <input type="search" placeholder={t("search")} aria-label="Search"
                value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                  if (event.key === "Enter") { handleSearch(); }
                }} />

              <button type="submit" className="btn btn-onlyicon bounce btn-lv5"
                onClick={handleSearch}>
                <div className="icon icon-Search"></div>
              </button>
            </div>
          </div>
          <div>
            <button className="btn btn-lv1 btn-icontext-rwd" onClick={handelCreate}>
              <div className="icon icon-Create"></div>
              <span>{t("create")}</span>
            </button>
          </div>
        </div>
        {
          processData?.detail?.length > 0 ?
            <div className="table-wrapper rwd col4to2 text-keep-normal">
              <table className="APSTable scroll" ref={scrollRef}>
                <thead className="showTHead">
                  <tr className="tLeft">
                    <th className="hoverShowIcon cursor-pointer clicked" onClick={orderByCol}>
                      {t("part")}
                      <span className="sort-icons">
                        <span className={`grey8 icon-ArrowUp icon${!descending ? "-clicked" : ""}`}></span>
                        <span className={`grey8 icon-ArrowDown icon${descending ? "-clicked" : ""}`}></span>
                      </span>
                    </th>

                    <th className="noShowWhenRWD">{t("process")}</th>
                    <th className="noShowWhenRWD">{t("equipment")}</th>
                    <th className="noShowWhenRWD">{t("duration")}</th>
                    <th className="noShowWhenRWD">{t("program")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    processData?.detail?.map((obj, index) => {
                      return (
                        <tr className="tr" key={index}>
                          <td data-label={t("part")} className="f1">
                            <span className="strong2">{obj.wpNO}</span>
                            <span>{obj.wpName}</span>
                          </td>
                          <td data-label={t("process")} className="f1">
                            <span className="strong3">{obj.processNo}</span>
                            <span>{obj.processName}</span>
                          </td>
                          <td data-label={t("equipment")} className="f1">
                            {(() => {
                              let result = "";
                              let equipment = obj.equipment;
                              if (equipment.length === 0) {
                                return <span className='text-alert'>{t("noAssociatedData")}</span>;
                              }
                              for (let [itemIndex, item] of equipment.entries()) {
                                result += item.mShortName + (equipment.length - 1 !== itemIndex ? ", " : "");
                              }
                              return result;
                            })()}
                          </td>
                          <td data-label={t("duration")} className="f1">
                            {(() => {
                              let result = "";
                              let minimalDuration = obj.minimalDuration;
                              let maximalDuration = obj.maximalDuration;

                              if (minimalDuration != null) {
                                result = formatTimeDays(minimalDuration, t) + (maximalDuration != null ? " ~ " + formatTimeDays(maximalDuration, t) : "");
                              }
                              else {
                                return <span className='text-alert'>{t("noAssociatedData")}</span>;
                              }
                              return result;
                            })()}
                          </td>
                          <td data-label={t("program")} className="f1 span2">
                            {(() => {
                              let result = "";

                              for (let item of obj.programName) {
                                result += item + ", "
                              }

                              return result != "" ? result.slice(0, result.length - 2) : result;
                            })()}
                          </td>
                          <td className="td btns tableFuncBtn">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="custom-tooltip">
                                  <span>{t("edit")}</span>
                                </Tooltip>
                              }
                            >
                              <div className="btn-item" onClick={() => handleEdit(obj.processID)}>
                                <div className="icon-Edit"></div>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="custom-tooltip">
                                  <span>{t("details")}</span>
                                </Tooltip>
                              }
                            >
                              <div className="btn-item" onClick={() => handleDetail(obj.processID)}>
                                <div className="icon-Details"></div>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="custom-tooltip">
                                  <span>{t("viewAllProcesses")}</span>
                                </Tooltip>
                              }
                            >
                              <div className="btn-item" onClick={() => handleViewAllProcess(obj.wpID, obj.wpNO, obj.wpName)}>
                                <div className="icon-ViewAllProcess"></div>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="custom-tooltip">
                                  <span>{t("delete")}</span>
                                </Tooltip>
                              }
                            >
                              <div className="btn-item red" onClick={() => handleDel(obj.processID)}>
                                <div className="icon-Delete"></div>
                              </div>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>

              <div className="d-flex justify-content-center mt-3">
                <MyPagination totalPages={Math.ceil(processData?.totalItems / 30)}
                  currentPage={currentPage}
                  onPageChange={handlePageChange} />
              </div>
            </div> :
            <div className="table-nodata">
              <div className="nodata-text">{t("noDataFound")}</div>
            </div>
        }
      </div>

      <ToastContainer />

      <Process
        showModal={showProcessDataModal}
        handleClose={handleProcessDataClose}
        behavior={behavior}
        loadProcessList={loadProcessList}
        processID={processID}
      />

      <Detail
        showModal={showDetailModal}
        handleClose={handleDetailClose}
        loadProcessList={loadProcessList}
        processID={processID}
      />

      <VewAllProcess
        showModal={showVewAllProcessModal}
        handleClose={handleVewAllProcessClose}
        wpID={wpID}
        wpNO={wpNO}
        wpName={wpName}
      />

      {/* 刪除工序? */}
      <MessageModal
        title={t("deleteProcess")}
        content={`${t("deleteProcessContent1")}<br /><br />${t("deleteProcessContent2")}`}
        showModal={showDelProcessModal}
        handleConfirmData={handleDelProcess}
        size="xs"
        confirmModalTxt={t("delete")}
        cancelTxt={t("cancel")}
        cancelModal={handleDelProcessClose}
        handleClose={handleDelProcessClose}
      />

      {/* 刪除工序? */}
      <MessageModal
        title={t("processCannotDeleted")}
        content={`${t("processCannotDeletedContent")}`}
        showModal={showCannotProcessModal}
        handleConfirmData={handleCannotProcessClose}
        size="xs"
        confirmModalTxt={t("confirm")}
        handleClose={handleCannotProcessClose}
      />

    </div>
  );
};

export default Folder;
