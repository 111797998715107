import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Row, Col, OverlayTrigger, Tooltip, Form, Dropdown } from 'react-bootstrap';
import { MyUserContext } from 'contexts/MyUserContext';
import MyPagination from 'components/basic/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import MessageModal from 'components/basic/MessageModal';

import { roundToTwoDecimalPlaces, formatNumberWithCommas, isDimensions, isNumber, formatTime } from 'utils/commonFun';

import {
  apiWorkpieceList, apiWorkpieceUnique, apiKeywordWorkpiece, apiOptionCategory, apiPostWorkpiece, apiSingleLoadWorkpiece,
  apiDelWorkpiece, apiProgramList
} from 'utils/Api';

const Workpieces = ({ tabList, setTabList }) => {
  const { t } = useTranslation("folder");
  const navigate = useNavigate();
  const { overtime } = useContext(MyUserContext);

  const [tableResult, setTableResult] = useState(""); //查詢結果(設備運轉) 1=>尚未新增工件 2=>沒有搜尋到相關內容
  const [tableList, setTableList] = useState(null); //
  const [currentPage, setCurrentPage] = useState(1);

  const [showModal, setShowModal] = useState(false);  //工件Modal
  const handleClose = () => setShowModal(false);

  const [modalData, setModalData] = useState({
    wpImageList: [],
    wpID: 0,
    wpNO: "",
    wpName: "",
    drawingNumber: "",
    categoryID: "",
    dimensions: "",
    keywordIDList: [],
    suggestedPrice: ""
  });  //Modal資料
  const [inputDimensions, setInputDimensions] = useState(["", "", ""]);  //輸入框尺寸
  const [categorySetting, setCategorySetting] = useState([]); //工件類別設定檔
  const [mutiKeywordsSetting, setMutiKeywordsSetting] = useState([]); //關鍵字設定檔
  const [selKeywords, setSelKeywords] = useState([]);  //使用者選擇的關鍵字

  const [behavior, setBehavior] = useState(""); //insert:代表新增  update:代表編輯
  const [selectId, setSelectId] = useState(0);
  const [initWpNO, setInitWpNO] = useState("");  //工件代號
  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息
  const [uniqueFocus, setUniqueFocus] = useState(false); //檢查工序是否focus
  const [judgeUnique, setJudgeUnique] = useState(false); //檢查是否唯一值
  const [notUniqueWorkpiece, setNotUniqueWorkpiece] = useState(null); //重複值需要列出哪一個工件
  const fileInputRef = useRef(null);

  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字
  const [workpieceImg, setWorkpieceImg] = useState(null);
  const [judgeWorkpieceImg, setJudgeWorkpieceImg] = useState("");

  const allowedExtensions = /(\.png|\.jpg|\.jpeg|\.gif)$/i;

  const [showDelModal, setShowDelModal] = useState(false);  //刪除加工程序Modal
  const handleDelClose = () => setShowDelModal(false);

  const [showProgramModal, setShowProgramModal] = useState(false);  //工件詳細資訊Modal
  const handleProgramClose = () => setShowProgramModal(false);

  const [selectedMachineProgram, setSelectedMachineProgram] = useState({ wpNO: "", wpName: "" }); //目前選擇工件名稱
  const [programList, setProgramList] = useState("");

  const searchRef = useRef(null);
  const scrollRef = useRef(null);

  const [showDelInfoModal, setShowDelInfoModal] = useState(false); //刪除此項資訊嗎？Modal
  const handleDelInfoClose = () => setShowDelInfoModal(false);

  const [showDelProcessModal, setShowDelProcessModal] = useState(false); //刪除關聯的工序?Modal
  const handleDelProcessClose = () => setShowDelProcessModal(false);

  const [showDelPartModal, setShowDelPartModal] = useState(false); //不可刪除工件Modal
  const handleDelPartClose = () => setShowDelPartModal(false);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('workpieceKeyword')) {
      setKeyword(localStorage.getItem('workpieceKeyword'));
    }
    else {
      loadWorkpieceList();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('workpieceKeyword') && keyword != "") {
      if (searchRef.current) {
        searchRef.current.click();
      }
      localStorage.removeItem('workpieceKeyword');
    }
  }, [keyword]);

  useEffect(() => {
    loadWorkpieceList();
  }, [currentPage]);

  //#region 載入工件列表
  const loadWorkpieceList = async () => {
    const [httpStatus, reponseData] = await handleApiWorkpieceList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        const data = reponseData.data;

        if (Math.ceil(data?.totalItems / 30) >= currentPage) {
          setTableList(data);
        }
        else {
          setTableList(null);
          setCurrentPage(1);
        }

        if (reponseData?.data?.totalItems === 0 && keyword === "") {
          setTableResult("1");
        }
        else if (reponseData?.data?.totalItems === 0 && keyword !== "") {
          setTableResult("2");
        }
        else {
          setTableResult("");
        }
      }
      else if (reponseData.statusCode === "40103" || reponseData.statusCode === "40104") {
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = {
      keyword: keyword,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30
    };
    let programListResponse = await apiWorkpieceList(postJson);
    if (programListResponse) {
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    if (showModal) {
      loadKeywordsList();
      loadCategoryList();
    }
  }, [showModal]);

  //#region 載入關鍵字列表
  const loadKeywordsList = async () => {
    const [httpStatus, reponseData] = await handleApiKeywordsList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        let data = reponseData.data;
        let newMutiKeywordsSetting = [];
        for (let item of data) {
          newMutiKeywordsSetting.push({ value: item.id, label: item.value });
        }
        setMutiKeywordsSetting(newMutiKeywordsSetting);
      }
      else if (reponseData.statusCode === "40103" || reponseData.statusCode === "40104") {
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取關鍵字列表
  const handleApiKeywordsList = async () => {
    let response = await apiKeywordWorkpiece();
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 載入工件類別列表
  const loadCategoryList = async () => {
    const [httpStatus, reponseData] = await handleApiCategoryList();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        let data = reponseData.data;
        setCategorySetting(data);
      }
      else if (reponseData.statusCode === "40103" || reponseData.statusCode === "40104") {
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取工件類別
  const handleApiCategoryList = async () => {
    let response = await apiOptionCategory();
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 查詢
  const searchWorkpiece = () => {
    loadWorkpieceList();
  }
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
  };
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setWorkpieceImg(file);
    setJudgeWorkpieceImg("exists");

    if (allowedExtensions.exec(file.name) && file.size <= 3 * 1024 * 1024) {
      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          setModalData(prevData => ({
            ...prevData,
            "wpImageList": [e.target.result]
          }));
        }

        reader.readAsDataURL(file);
      }
    }
    else {
      setModalData(prevData => ({
        ...prevData,
        "wpImageList": []
      }));
    }
  };

  const handleRemveImg = () => {
    fileInputRef.current.value = '';
    setWorkpieceImg(null);
    setJudgeWorkpieceImg("notExists");
    setModalData(prevData => ({
      ...prevData,
      "wpImageList": []
    }));
  }

  //#region 新增編輯Modal
  const handleModal = async (behavior, workpieceID) => {
    setJudgeCheckErr(false);
    setJudgeUnique(false);
    setBehavior(behavior);
    setWorkpieceImg(null);
    setJudgeWorkpieceImg("");

    if (behavior === "update") {
      const [httpStatus, reponseData] = await handleApiSingleLoadWorkpiece(workpieceID);

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;
          setInitWpNO(data.wpNO);
          setJudgeUnique(true);

          if (!data.drawingNumber) {
            data.drawingNumber = "";
          }

          if (!data.categoryID) {
            data.categoryID = "";
          }
          else {
            data.categoryID = data.categoryID?.toString();
          }

          if (!data.dimensions) {
            data.dimensions = "";
            setInputDimensions(["", "", ""]);
          }
          else {
            const parts = data.dimensions.split('*');
            if (parts.length < 3) {
              let lostTimes = 3 - parts.length;
              for (var i = 0; i < lostTimes; i++) {
                parts.push("");
              }
            }

            setInputDimensions(parts);
          }

          if (!data.keywordIDList) {
            data.keywordIDList = [];
            setSelKeywords([]);
          }
          else {
            let newSelKeywords = [];
            for (let item of data.keywordIDList) {
              let filter = mutiKeywordsSetting.find(d => d.value === item);
              if (filter) {
                newSelKeywords.push(filter);
              }
            }
            setSelKeywords(newSelKeywords);
          }

          if (!data.suggestedPrice) {
            data.suggestedPrice = "";
          }
          else {
            data.suggestedPrice = data.suggestedPrice.toString();
          }
          setModalData(data);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else {
      setModalData({
        wpImageList: [],
        wpID: 0,
        wpNO: "",
        wpName: "",
        drawingNumber: "",
        categoryID: "",
        dimensions: "",
        keywordIDList: [],
        suggestedPrice: ""
      });

      setInputDimensions(["", "", ""]);
    }
    setSelectId(parseInt(workpieceID));

    if (behavior != "delete") {
      setShowModal(true);
    }
    else {
      const [httpStatus, reponseData] = await handleApiDelWorkpiece(parseInt(workpieceID), false);
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20100") {
          setShowDelInfoModal(true);
        }
        else if (reponseData.statusCode === "40301") {
          setShowDelProcessModal(true);
        }
        else if (reponseData.statusCode === "40302") {
          setShowDelPartModal(true);
        }
      }
      // setShowDelModal(true);
    }
  }
  //#endregion

  //#region 載入單一一筆工件
  const handleApiSingleLoadWorkpiece = async (selectId) => {
    let response = await apiSingleLoadWorkpiece(selectId);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "wpNO") {
      setUniqueFocus(true);
    }

    setModalData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  //#endregion

  //#region 檢查加工程序
  const checkUnique = async (e) => {
    setUniqueFocus(false);

    if (modalData.wpNO != initWpNO && modalData.wpNO != "") {
      let postData = {
        type: "wpNO",
        value: modalData.wpNO
      }
      const [httpStatus, reponseData] = await handleApiChkWorkpieceUnique(postData);

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          if (reponseData.data.wpNO !== null) {
            setNotUniqueWorkpiece(reponseData.data);
            setJudgeUnique(false);
          }
          else {
            setJudgeUnique(true);
          }
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else {
      setJudgeUnique(true);
    }
  }
  //#endregion

  //#region 檢查加工程序是否唯一
  const handleApiChkWorkpieceUnique = async (postData) => {
    let response = await apiWorkpieceUnique(postData);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region Input尺寸欄位改變值
  const handleInputDimensionsChange = (e) => {
    const { name, value } = e.target;
    let newInputDimensions = [...inputDimensions];
    if (name === "dimensions1") {
      newInputDimensions[0] = value;
    }
    else if (name === "dimensions2") {
      newInputDimensions[1] = value;
    }
    else {
      newInputDimensions[2] = value;
    }

    setInputDimensions(newInputDimensions);
  };
  //#endregion

  //#region 儲存
  const handleSave = async () => {
    setIsSaving(true);
    setJudgeCheckErr(true);

    //檢查欄位是否有錯
    let judgeErr = validateModalData();
    if (!judgeErr) {
      const [httpStatusUser, reponseDataUser] = await handleApiPostWorkpiece();
      if (httpStatusUser == "200") {
        if (reponseDataUser.statusCode === "20100") {
          toast(<>
            {
              behavior == "insert" ?
                <span>{t("createdSuccessfully")}</span> :
                <span>{t("editingCompletedSaved")}</span>
            }
          </>, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          loadWorkpieceList();
          handleClose();
        }
        else {
          toast.error(reponseDataUser.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    setIsSaving(false);
  }
  //#endregion

  //#region 新增以及編輯工件API
  const handleApiPostWorkpiece = async () => {
    let newModalData = { ...modalData };
    let newFormData = new FormData();
    newFormData.append('action', behavior);

    if (judgeWorkpieceImg === "exists") {
      newFormData.append('wpImageList', workpieceImg);
    }
    else if (judgeWorkpieceImg === "notExists") {
      newFormData.append('wpImageList', null);
    }

    if (newModalData.wpID != 0) {
      newFormData.append('wpID', newModalData.wpID);
    }

    newFormData.append('wpNO', newModalData.wpNO);
    newFormData.append('wpName', newModalData.wpName);

    if (newModalData.drawingNumber != "") {
      newFormData.append('drawingNumber', newModalData.drawingNumber);
    }

    if (newModalData.categoryID != "") {
      newFormData.append('categoryID', newModalData.categoryID);
    }

    const filteredArr = inputDimensions.filter(item => item !== '');
    if (filteredArr && filteredArr.length > 0) {
      var stringWithStar = filteredArr.join('*');
      newFormData.append('dimensions', stringWithStar);
    }

    if (selKeywords.length > 0) {
      for (let item of selKeywords) {
        newFormData.append('keywordIDList', item.value.toString());
      }
    }

    if (newModalData.suggestedPrice != "") {
      newFormData.append('suggestedPrice', roundToTwoDecimalPlaces(parseFloat(newModalData.suggestedPrice)).toString());
    }



    let response = await apiPostWorkpiece(newFormData);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 檢查欄位值
  const validateModalData = () => {
    let judgeErr = false;

    Object.keys(modalData).forEach((key) => {
      if (!judgeErr) {
        if (key === "wpNO") {
          if (modalData["wpNO"] === "" || !judgeUnique || modalData["wpNO"].length > 100) {
            judgeErr = true;
          }
        }
        else if (key === "wpName") {
          if (modalData["wpName"] === "" || modalData["wpName"].length > 100) {
            judgeErr = true;
          }
        }
        else if (key === "drawingNumber") {
          if (modalData["drawingNumber"] !== "" && modalData["drawingNumber"]?.length > 100) {
            judgeErr = true;
          }
        }
        else if (key === "suggestedPrice") {
          if (modalData["suggestedPrice"] !== "" && !isNumber(modalData?.suggestedPrice) && parseFloat(modalData?.suggestedPrice) < 0) {
            judgeErr = true;
          }
        }
      }
    });

    let newInputDimensions = { ...inputDimensions };
    if ((newInputDimensions[0] != "" && !isDimensions(newInputDimensions[0])) ||
      (newInputDimensions[1] != "" && !isDimensions(newInputDimensions[1])) ||
      (newInputDimensions[2] != "" && !isDimensions(newInputDimensions[2]))) {
      judgeErr = true;
    }

    return judgeErr;
  }
  //#endregion

  //#region 下拉多選樣式
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'blue' : 'white', // 选中项背景颜色
      color: state.isSelected ? 'white' : 'black', // 选中项文字颜色
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#EBEEFB', // 多选值的背景颜色
      color: '#3D71F8', // 多选值的文字颜色
      borderRadius: '8px', // 圆角边框
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#3D71F8', // 多选值的文字颜色
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#9FBAFF', // 移除按钮的文字颜色
      ':hover': {
        backgroundColor: '#3D71F8', // 移除按钮的背景颜色（悬停时）
        color: 'white', // 移除按钮的文字颜色（悬停时）
      }
    }),
  };
  //#endregion

  //#region 下拉多選 Change事件
  const handleSelChange = (e) => {
    setSelKeywords(e);
  };
  //#endregion

  //#region 刪除工件
  const handleDelete = async (doDelete, closeModal) => {
    const [httpStatus, reponseData] = await handleApiDelWorkpiece(selectId, doDelete);

    if (httpStatus == "200") {
      if (reponseData.statusCode === "20100") {
        toast(<>
          <span>{t("deletionCompleted")}</span>
        </>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        loadWorkpieceList();
        closeModal();
      }
      else {
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 刪除一筆加工程序
  const handleApiDelWorkpiece = async (id, doDelete) => {
    let postData = {
      wpID: id,
      doDelete: doDelete
    };
    let response = await apiDelWorkpiece(postData);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 顯示工序列表
  const handleProgramList = (programList, wpNO, wpName) => {
    let programIDList = [];
    for (let item of programList) {
      programIDList.push(item.programID);
    }

    loadProgramList(programIDList);

    setSelectedMachineProgram({ wpNO: wpNO, wpName: wpName });

    setShowProgramModal(true);


  }
  //#endregion

  //#region 載入加工程序列表
  const loadProgramList = async (programIDList) => {
    const [httpStatus, reponseData] = await handleApiProgramList(programIDList);
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setProgramList(reponseData.data);
      }
      else if (reponseData.statusCode === "40103" || reponseData.statusCode === "40104") {
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取加工程序列表
  const handleApiProgramList = async (programIDList) => {
    let postJson = {
      programIDList: programIDList
    }
    let programListResponse = await apiProgramList(postJson);
    if (programListResponse) {
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 編輯工序頁面
  const handleEditProgram = () => {
    localStorage.setItem('programKeyword', selectedMachineProgram.wpNO);

    let newTabList = [...tabList];
    let currentShowTab = newTabList.findIndex(d => d.active);
    newTabList[currentShowTab].active = false;

    let changeShowTab = newTabList.findIndex(d => d.tabName === "Programs");
    newTabList[changeShowTab].active = true;

    setTabList(newTabList);
  }
  //#endregion

  return (
    <>
      <div className="pageContent">
        <div className="filterRow">
          <div>
            <div className="btn-search">
              <input type="search" placeholder={t("search")} aria-label="Search"
                value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                  if (event.key === "Enter") { searchWorkpiece(); }
                }} />
              <button type="button" className="btn btn-onlyicon bounce btn-lv5" ref={searchRef}
                onClick={searchWorkpiece}>
                <div className="icon icon-Search"></div>
              </button>
            </div>
          </div>
          <div>
            <button type="button" className="btn btn-lv1 btn-icontext-rwd"
              onClick={() => handleModal("insert", 0)}>
              <div className="icon icon-Add"></div>
              <div className="text">{t("create")}</div>
            </button>
          </div>
        </div>
        <div className="table-wrapper border rwd firstColImg text-keep-all">
          <div className="table-minheight1" ref={scrollRef}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>{t("workpieceID")}</th>
                  <th>{t("workpieceName")} / {t("drawingNumber")}</th>
                  <th>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("machiningProgramToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {t("machiningProgram")}<div className="size-XS bg-blue-light1 icon-info hover"></div>
                      </div>
                    </OverlayTrigger>
                  </th>
                  <th>{t("category")}</th>
                  <th>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("dimensionsToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {t("dimensions")}<div className="size-XS bg-blue-light1 icon-info hover"></div>
                      </div>
                    </OverlayTrigger>
                  </th>
                  <th>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("keywordsToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {t("keywords")}<div className="size-XS bg-blue-light1 icon-info hover"></div>
                      </div>
                    </OverlayTrigger>
                  </th>
                  <th className='tRight'>{t("suggestedPrice")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  tableResult === "1" ?
                    <tr>
                      <td colSpan="9">
                        <div className='noDataDiv'><span className='noData'>{t("noWorkpieceDataCreatedYet")}</span></div>
                      </td>
                    </tr>
                    : ""
                }
                {
                  tableResult === "2" ?
                    <tr>
                      <td colSpan="9">
                        <div className='noDataDiv'><span className='noData'>{t("noSearchResultsFound")}</span></div>
                      </td>
                    </tr>
                    : ""
                }
                {
                  tableList?.detail?.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='imgBlock'>
                            {
                              obj.wpImageList && obj.wpImageList.length > 0 ?
                                <img src={obj.wpImageList[0]} className='w-100' /> : <></>
                            }
                          </div>
                          {/* <img src={obj.wpImageList[0]} className='w-100' /> */}
                          {/* <ScaleImageWorkpiece key={index} src={obj.wpImageList[0]} maxWidth="100" maxHeight="100" /> */}
                        </td>
                        <td className='text break-all' data-label={t("workpieceID")}>{obj.wpNO}</td>
                        <td className='text break-all' data-label={`${t("workpieceName")} / ${t("drawingNumber")}`}><b>{obj.wpName}</b><br /><span className='text-grey8 text12'>{obj.drawingNumber}</span></td>
                        <td className='text break-all' data-label={t("machiningProgram")}>
                          {obj.programList && obj.programList?.length === 1 ? <>{obj.programList[0]?.programNO}</> : ""}
                          {obj.programList && obj.programList?.length > 1 ? <>{obj.programList[0]?.programNO}
                            <span className='moreTxt cursor-pointer' onClick={() => handleProgramList(obj.programList, obj.wpNO, obj.wpName)}>{obj.programList?.length - 1}+</span></> : ""}
                        </td>
                        <td className='' data-label={t("category")}>{obj.categoryName}</td>
                        <td className='' data-label={t("dimensions")}>{obj.dimensions}</td>
                        <td className='' data-label={t("keywords")}>
                          {(() => {
                            var array = obj.keywords;
                            var stringWithSemicolon = array?.join('; ');
                            return stringWithSemicolon;
                          })()}
                        </td>
                        <td className='tRight' data-label={t("suggestedPrice")}> {obj.suggestedPrice ? `$${formatNumberWithCommas(obj.suggestedPrice)}` : ""}&nbsp;</td>
                        <td className="tableFuncBtn">
                          <Dropdown>
                            <Dropdown.Toggle className="dropdownIcon">
                              <div className="icon icon-More"></div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item className='d-flex align-items-center' onClick={() => handleModal("update", obj.wpID)}>
                                <div className="icon icon-Edit bg-grey8 size-M"></div>&nbsp;&nbsp;
                                <div className="text-grey4 text14">{t("edit")}</div>
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex align-items-center' onClick={() => handleModal("delete", obj.wpID)}>
                                <div className="icon icon-TrashCan bg-red size-M"></div>&nbsp;&nbsp;
                                <div className="text-red text14">{t("delete")}</div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <MyPagination totalPages={Math.ceil(tableList?.totalItems / 30)} currentPage={currentPage}
            onPageChange={handlePageChange} />
        </div>
      </div>

      {/* 工件Modal */}
      <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {behavior === "insert" ? t("create") : t("edit")}<br />
            <p className='remarkRequired mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger'>*</span></p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="flex-wrap gap1 mb-3" controlId="wpImageList">
              <div className={`imgBlock update
                      ${workpieceImg && (!allowedExtensions.exec(workpieceImg.name) || workpieceImg.size > 3 * 1024 * 1024) ? "input-error" : ""} `}
                onClick={handleDivClick}
              >
                {/* <div className={`workpieceImgBorder d-flex justify-content-center align-items-center 
                      ${workpieceImg && (!allowedExtensions.exec(workpieceImg.name) || workpieceImg.size > 3 * 1024 * 1024) ? "input-error" : ""} `}
                      onClick={handleDivClick}
                    > */}
                {
                  modalData?.wpImageList?.length > 0 ?
                    <img src={modalData?.wpImageList[0]} /> :
                    <>
                      <span className='text'>{t("upload")}<br />{t("image")}</span>
                      <div className='icon'>
                        <div className="icon icon-Image"></div>
                      </div>
                    </>
                }
                {/* <ScaleImageWorkpiece src={modalData?.wpImageList[0]} maxWidth="100" maxHeight="78" /> */}
                {/* <img src={modalData?.wpImageList[0]} /> */}
                {/* {console.log("workpieceImg", workpieceImg)} */}
                {/* <ScaleImageWorkpiece src={workpieceImg} maxWidth="100" maxHeight="78" /> */}
                {/* <span className='workpieceDefaultTxt'>{t("upload")}<br />{t("image")}</span>
                      <div className='workpieceDefault'>
                        <p className="material-symbols-rounded-defaultImg mb-0">image</p>
                      </div> */}
              </div>
              <div className='flex-wrap column'>
                <div className="flex-nowrap mb-3">
                  <button type="button" className="btn btn-icontext btn-txtBlue-bgLightBlue" onClick={handleDivClick}>
                    <div className="icon icon-Edit"></div>
                    <div className="text">{t("change")}</div>
                  </button>
                  <button type="button" className="btn btn-icontext btn-txtBlue-bgLightBlue mleft025" onClick={handleRemveImg}>
                    <div className="icon icon-TrashCan"></div>
                    <div className="text">{t("remove")}</div>
                  </button>
                </div>
                <ul className="ul-Description">
                  <li>{t("workpieceSuggest1")}</li>
                  <li>{t("workpieceSuggest2")}</li>
                  <li>{t("workpieceSuggest3")}</li>
                </ul>
              </div>
              <input type="file" onChange={handleFileInputChange} className='d-none' ref={fileInputRef}
                accept=".png, .jpg, .jpeg, .gif" />

              {
                workpieceImg && !allowedExtensions.exec(workpieceImg.name) ?
                  <Form.Label className='text-danger mb-0'>{t("uploadImageErrorFormat")}</Form.Label> :
                  <></>
              }
              {
                workpieceImg && allowedExtensions.exec(workpieceImg.name) && workpieceImg.size > 3 * 1024 * 1024 ?
                  <Form.Label className='text-danger mb-0'>{t("uploadImageError3MB")}</Form.Label> :
                  <></>
              }
            </Form.Group>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="wpNO">
                  <Form.Label className='form-label-custom'>
                    {t("workpieceID")}
                    <span className='text-danger ms-1'>*</span>
                    <span className='form-textnum ms-1'>({modalData?.wpNO.length ?? 0}/100)</span>
                  </Form.Label><br />
                  <div className="input-rightpadding w-100">
                    <Form.Control
                      type="text" name="wpNO" value={modalData?.wpNO}
                      onChange={handleInputChange} onBlur={checkUnique} autoComplete="off"
                      className={`input outline w-100 ${judgeCheckErr && (modalData?.wpNO === "" || modalData?.wpNO.length > 100 || !judgeUnique) ? "input-error" : ""}`}
                      disabled={behavior === "update"}
                    />
                    {
                      modalData?.wpNO != "" && modalData?.wpNO != null && judgeUnique && !uniqueFocus ?
                        <div className="bg-green icon icon-CheckCircle"></div> :
                        modalData?.wpNO != "" && modalData?.wpNO != null && !uniqueFocus ?
                          <div className="bg-red icon icon-CrossCircle"></div> : ""
                    }
                  </div>
                  {/* <div className="input-with-iconAccount">
                    <Form.Control
                      type="text" name="wpNO" value={modalData?.wpNO}
                      onChange={handleInputChange} onBlur={checkUnique} autoComplete="off"
                      className={`border-0 ${judgeCheckErr && (modalData?.wpNO === "" || modalData?.wpNO.length > 100 || !judgeUnique) ? "input-error" : ""}`}
                      disabled={behavior === "update"}
                    />
                    {
                      modalData?.wpNO != "" && modalData?.wpNO != null && judgeUnique && !uniqueFocus ?
                        <span className="material-symbols-rounded-unique inputIcon">check_circle</span> :
                        modalData?.wpNO != "" && modalData?.wpNO != null && !uniqueFocus ?
                          <span className="material-symbols-rounded-uniqueCancel inputIcon">cancel</span> : ""
                    }
                  </div> */}
                  {
                    judgeCheckErr && modalData?.wpNO === "" ?
                      <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && modalData?.wpNO !== "" && modalData?.wpNO.length > 100 ?
                      <Form.Label className='text-danger mb-0'>
                        {t("exceedsCharacterLimit")}
                      </Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && modalData?.wpNO !== "" && modalData?.wpNO.length <= 100 && !judgeUnique ?
                      <Form.Label className='text-danger mb-0'>
                        {
                          notUniqueWorkpiece?.wpNO === null && notUniqueWorkpiece?.wpName === null ?
                            t("workpieceIDAlreadyTakenNotWorkpiece") :
                            t("workpieceIDAlreadyTaken", { workpiece: `${notUniqueWorkpiece?.wpName}` })
                        }
                      </Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="wpName">
                  <Form.Label className='form-label-custom'>
                    {t("workpieceName")}
                    <span className='text-danger ms-1'>*</span>
                    <span className='form-textnum ms-1'>({modalData?.wpName.length ?? 0}/100)</span>
                  </Form.Label><br />
                  <Form.Control
                    type="text" name="wpName" value={modalData?.wpName}
                    onChange={handleInputChange} autoComplete="off"
                    className={`${judgeCheckErr && (modalData?.wpName === "" || modalData?.wpName.length > 100) ? "input-error" : ""}`}
                  />
                  {
                    judgeCheckErr && modalData?.wpName === "" ?
                      <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && modalData?.wpName !== "" && modalData?.wpName.length > 100 ?
                      <Form.Label className='text-danger mb-0'>
                        {t("exceedsCharacterLimit")}
                      </Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="drawingNumber">
                  <Form.Label className='form-label-custom'>
                    {t("drawingNumber")}
                    <span className='form-textnum ms-1'>({modalData?.drawingNumber.length ?? 0}/100)</span>
                  </Form.Label><br />
                  <Form.Control
                    type="text" name="drawingNumber" value={modalData?.drawingNumber}
                    onChange={handleInputChange} autoComplete="off"
                    className={`${judgeCheckErr && (modalData?.drawingNumber !== "" && modalData?.drawingNumber.length > 100) ? "input-error" : ""}`}
                  />
                  {
                    judgeCheckErr && modalData?.drawingNumber !== "" && modalData?.drawingNumber.length > 100 ?
                      <Form.Label className='text-danger mb-0'>
                        {t("exceedsCharacterLimit")}
                      </Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="categoryID">
                  <Form.Label className='form-label-custom'>
                    {t("category")}
                  </Form.Label><br />
                  <Form.Select name="categoryID" onChange={handleInputChange}
                    value={modalData?.categoryID}
                  >
                    <option value="">{t("selectCategory")}</option>
                    {
                      categorySetting?.map((obj, index) => {
                        return (
                          <option value={obj.id} key={index}>{obj.value}</option>
                        )
                      })
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-3" controlId="dimensions">
                  <Form.Label className='form-label-custom timeDurationTitle mb-0'>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("dimensionsToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-content-center'>
                        {t("dimensions")}<span className="material-symbols-rounded text-primary ms-1 h6">info</span>
                      </div>
                    </OverlayTrigger>
                  </Form.Label>
                  <div className={`timeDurationDiv ${judgeCheckErr && ((inputDimensions[0] != "" && !isDimensions(inputDimensions[0])) ||
                    (inputDimensions[1] != "" && !isDimensions(inputDimensions[1])) ||
                    (inputDimensions[2] != "" && !isDimensions(inputDimensions[2]))) ? "error" : ""}`}>
                    <input type="text" name="dimensions1" className='timeDuration'
                      value={inputDimensions[0]} onChange={handleInputDimensionsChange} />*
                    <input type="text" name="dimensions2" className='timeDuration'
                      value={inputDimensions[1]} onChange={handleInputDimensionsChange} />*
                    <input type="text" name="dimensions3" className='timeDuration'
                      value={inputDimensions[2]} onChange={handleInputDimensionsChange} />
                  </div>
                  {
                    judgeCheckErr && ((inputDimensions[0] != "" && !isDimensions(inputDimensions[0])) ||
                      (inputDimensions[1] != "" && !isDimensions(inputDimensions[1])) ||
                      (inputDimensions[2] != "" && !isDimensions(inputDimensions[2]))) ?
                      <Form.Label className='text-danger mb-0'>{t("formatDimensions")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-3" controlId="suggestedPrice">
                  <Form.Label className='form-label-custom'>
                    {t("suggestedPrice")}
                  </Form.Label>
                  <Row>
                    <Col xs={1} className='d-flex justify-content-center align-items-center pe-0'>$</Col>
                    <Col xs={11}>
                      <Form.Control
                        type="text" name="suggestedPrice" value={modalData?.suggestedPrice}
                        onChange={handleInputChange} autoComplete="off"
                        className={`${judgeCheckErr && (modalData?.suggestedPrice !== "" && !isNumber(modalData?.suggestedPrice) || parseFloat(modalData?.suggestedPrice) < 0) ? "input-error" : ""}`}
                      />
                    </Col>
                  </Row>
                  {
                    judgeCheckErr && (modalData?.suggestedPrice != "" && !isNumber(modalData?.suggestedPrice)) ?
                      <Form.Label className='text-danger mb-0'>{t("formatErrorNumber")}</Form.Label> :
                      <></>
                  }
                  {
                    judgeCheckErr && (modalData?.suggestedPrice != "" && isNumber(modalData?.suggestedPrice) && parseFloat(modalData?.suggestedPrice) < 0) ?
                      <Form.Label className='text-danger mb-0'>{t("formatErrorNumber")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="keywords">
                  <Form.Label className='form-label-custom'>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className='custom-tooltip'>
                          <span dangerouslySetInnerHTML={{ __html: t("keywordsToolTip") }} />
                        </Tooltip>
                      }
                    >
                      <div className='d-flex align-content-center'>
                        {t("keywords")}<span className="material-symbols-rounded text-primary ms-1 h6">info</span>
                      </div>
                    </OverlayTrigger>
                  </Form.Label>

                  <Select options={mutiKeywordsSetting} isMulti styles={customStyles}
                    value={selKeywords}
                    onChange={(e) => handleSelChange(e)} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
              <button type="button" className="btn btn-txt-black" onClick={handleClose}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-lv1 fat" onClick={handleSave} disabled={isSaving}>
                {t("save")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} >
              <button type="button" className="button-secondary button-formula" onClick={handleClose}>
                <b>{t("cancel")}</b>
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end' onClick={handleSave}>
              <button type="button" className="button-primary button-formula">
                <b>{t("save")}</b>
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      {/* 刪除工序(後續改) */}
      {/* <Modal show={showDelModal} onHide={handleDelClose} size="xs" backdrop="static" keyboard={false} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("deleteThisInformation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("deleteThisInformationMsg1")}<br /><br />
          {t("deleteThisInformationMsg2")}
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} >
              <button type="button" className="button-secondary button-formula" onClick={handleDelClose}>
                <b>{t("cancel")}</b>
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <button type="button" className="button-primary button-formula" onClick={handleDelete}>
                <b>{t("delete")}</b>
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal> */}

      {/* 要刪除此項資訊嗎？ */}
      <MessageModal
        title={t("deleteThisInformation")}
        content={`${t("deleteThisInformationMsg1")}<br /><br />${t("deleteThisInformationMsg2")}`}
        showModal={showDelInfoModal}
        handleConfirmData={() => handleDelete(true, handleDelInfoClose)}
        size="xs"
        confirmModalTxt={t("delete")}
        cancelTxt={t("cancel")}
        cancelModal={handleDelInfoClose}
        handleClose={handleDelInfoClose}
      />

      {/* 刪除關聯的工序? */}
      <MessageModal
        title={t("deleteLinkedProcess")}
        content={`${t("deleteLinkedProcessMsg1")}<br /><br />${t("deleteLinkedProcessMsg2")}`}
        showModal={showDelProcessModal}
        handleConfirmData={() => handleDelete(true, handleDelProcessClose)}
        size="xs"
        confirmModalTxt={t("delete")}
        cancelTxt={t("cancel")}
        cancelModal={handleDelProcessClose}
        handleClose={handleDelProcessClose}
      />

      {/* 不可刪除工件 */}
      <MessageModal
        title={t("partCannotDeleted")}
        content={`${t("partCannotDeletedMsg1")}`}
        showModal={showDelPartModal}
        handleConfirmData={handleDelPartClose}
        size="xs"
        confirmModalTxt={t("confirm")}
        handleClose={handleDelPartClose}
      />

      {/* 加工程式內容 */}
      <Modal show={showProgramModal} onHide={handleProgramClose} size="xl" backdrop="static" keyboard={false} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className='mb-0'>{t("machiningPrograms")}</h4>
            <div className='d-flex text14'>
              <p className='text-blue text14 mb-0'>{selectedMachineProgram.wpNO}</p>｜
              <p className='mb-0'>{selectedMachineProgram.wpName}</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrapper border">
            <div className="table-scroll">
              <table>
                <thead>
                  <tr>
                    <th>{t("machiningProgram")}</th>
                    <th className='tCenter'>{t("batchProductionQuantity")}</th>
                    <th className='tCenter'>{t("preProcessingDuration")}</th>
                    <th className='tCenter'>{t("programExecutionDuration")}</th>
                    <th className='tCenter'>{t("loadingUnloadingWaitingDuration")}</th>
                    <th>{t("programDescription")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    programList.detail?.map((obj, index) => {
                      return (
                        <tr className='table-tbody-border' key={index}>
                          <td>{obj.programName}</td>
                          <td className='tCenter'>{obj.batchProductionQuantity}</td>
                          <td className='tCenter'>{formatTime(obj.preProcessingDuration)}</td>
                          <td className='tCenter'>{formatTime(obj.programExecutionDuration)}</td>
                          <td className='tCenter'>{formatTime(obj.waitingDuration)}</td>
                          <td>{obj.programDescription}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
            </div>
            <div>
              <button type="button" className="btn btn-lv3 fat" onClick={handleEditProgram}>
                {t("editProgramsPage")}
              </button>
              <button type="button" className="btn btn-lv1 fat" onClick={handleProgramClose}>
                {t("back")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer>
          <Row>
            <Col xs={12} className='d-flex justify-content-end'>
              <button type="button" className="button-secondary button-formula" onClick={handleEditProgram}>
                {t("editProgramsPage")}
              </button>
              <button className='button-primary ms-2' onClick={handleProgramClose}>
                {t("back")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
};

/* 縮放圖片 */
const ScaleImageWorkpiece = ({ src, maxWidth, maxHeight }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    const scaleImage = (originalWidth, originalHeight, maxWidth, maxHeight) => {
      const ratioWidth = maxWidth / originalWidth;
      const ratioHeight = maxHeight / originalHeight;
      const scaleFactor = Math.min(ratioWidth, ratioHeight);
      const newWidth = Math.round(originalWidth * scaleFactor);
      const newHeight = Math.round(originalHeight * scaleFactor);
      return { width: newWidth, height: newHeight };
    };

    const updateDimensions = () => {
      const originalWidth = imageRef.current.naturalWidth;
      const originalHeight = imageRef.current.naturalHeight;
      const scaledDimensions = scaleImage(originalWidth, originalHeight, maxWidth, maxHeight);
      setDimensions(scaledDimensions);
    };

    // 监听图像加载完成事件，更新图像尺寸
    imageRef.current.addEventListener('load', updateDimensions);

    // 在组件卸载时移除事件监听器
    return () => {
      if (imageRef.current != null) {
        imageRef.current.removeEventListener('load', updateDimensions);
      }
    };
  }, [maxWidth, maxHeight, src]);

  return <img ref={imageRef} src={src} alt="Your Image" style={{ width: dimensions.width, height: dimensions.height }} />;
}

export default Workpieces;

