import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';
import Select from 'react-select';
import "./modal.css";

import { convertDateFormat } from 'utils/commonFun';

import { apiCreateWorkpiece, apiPostPeriodReport } from 'utils/Api';

const AddWorkpiece = ({showModal, handleClose, setSelectedOptionPart, optionsPart, setOptionsPart, setWpID,
  setWpNO, setWpName, setWpImage, setProgramID, setProgramName
}) => {
  const { t } = useTranslation("powerCarbon");
  const { overtime, authority, lang, periodReportTypeList } = useContext(MyUserContext);

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  //建立工件資訊
  const [createWpNO, setCreateWpNO] = useState("");
  const [createWpName, setCreateWpName] = useState("");
  const [createProgramName, setCreateProgramName] = useState("");
  
  const [judgeUniqueWpNO, setJudgeUniqueWpNO] = useState(true); //檢查是否唯一值工件代號
  const [judgeUniqueWpNOError, setJudgeUniqueWpNOError] = useState(""); //若工件不是唯一值，就顯示工件名稱
  const [judgeUniqueProgramName, setJudgeUniqueProgramName] = useState(true); //檢查是否唯一值加工程式
  const [judgeUniqueProgramNameError, setJudgeUniqueProgramNameError] = useState(""); //若加工程式不是唯一值，就顯示工件代號與工件名稱


  useEffect(() => {
    if(showModal){
      setJudgeCheckErr(false);
      setCreateWpNO("");
      setCreateWpName("");
      setCreateProgramName("");
    }
  }, [showModal]);

  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const setters = {
      createWpNO: setCreateWpNO,
      createWpName: setCreateWpName,
      createProgramName: setCreateProgramName
    };
  
    if (setters[name]) {
      setters[name](value);
    }
  };
  //#endregion

  //#region 工件資訊是否重複
  const checkUniquePart = async (judgeForceCheck = false, doCreate = false) => {
    var result = false;
    //格式正確再來檢查是否有重複
    if((judgeCheckErr && (createWpNO != "" && createWpName != "" && createWpNO.length <= 100 && createWpName.length <= 100) || 
      (createProgramName != "" && createProgramName.length <= 100) || judgeForceCheck)){
      const [httpStatus, reponseData] = await handleApiCreateWorkpiece(doCreate);
      if(httpStatus == "200"){
        let data = reponseData.data;
        if(reponseData.statusCode === "DL001"){
          result = true;
          if(data.notUnique.length === 2){
            setJudgeUniqueWpNO(false);
            setJudgeUniqueProgramName(false);

            setJudgeUniqueWpNOError(data.errorMsgWpNO);
            setJudgeUniqueProgramNameError(data.errorMsgPgmName);
          }
          else if(data.notUnique.length === 1){
            if(data.notUnique[0] === "wpNO"){
              setJudgeUniqueWpNO(false);
              setJudgeUniqueProgramName(true);

              setJudgeUniqueWpNOError(data.errorMsgWpNO);
              setJudgeUniqueProgramNameError("");
            }
            else{
              setJudgeUniqueWpNO(true);
              setJudgeUniqueProgramName(false);

              setJudgeUniqueWpNOError("");
              setJudgeUniqueProgramNameError(data.errorMsgPgmName);
            }
          }
        }
        else if(reponseData.statusCode === "20100"){
          setJudgeUniqueWpNO(true);
          setJudgeUniqueProgramName(true);

          setJudgeUniqueWpNOError("");
          setJudgeUniqueProgramNameError("");

          if(data.wpID != null){
            setWpID(data.wpID);
            setWpNO(data.wpNO);
            setWpName(data.wpName);
            setWpImage("");
          }
          else{
            setWpID(0);
            setWpNO("");
            setWpName("");
            setWpImage("");
          }

          if(data.programID != null){
            setProgramID(data.programID);
            setProgramName(data.programName);
          }
          else{
            setProgramID(0);
            setProgramName("");
          }

          let value = "";
          let label = "";
          if(data.wpID != null && data.programID != null){
            value = `${data.wpID}-${data.programID}`;
            label = `${data.wpNO} ${data.wpName}/${data.programName}`;
          }
          else if(data.wpID != null && data.programID == null){
            value = `${data.wpID}-0`;
            label = `${data.wpNO} ${data.wpName}`;
          }
          else if(data.wpID == null && data.programID != null){
            value = `0-${data.programID}`;
            label = `${data.programName}`;
          }

          if(value != ""){
            setSelectedOptionPart({ 
              value: value, 
              label: label,
              wpNO: data.wpNO,
              wpName: data.wpName,
              programName: data.programName,
              wpImage: ""
            });

            let currentOptionsPart = [{ 
              value: value, 
              label: label,
              wpNO: data.wpNO,
              wpName: data.wpName,
              programName: data.programName
            }, ...optionsPart];
            setOptionsPart(currentOptionsPart);
          }
          else{
            setSelectedOptionPart(null);
          }

        }
      }
    }
    else{
      setJudgeUniqueWpNO(true);
      setJudgeUniqueProgramName(true);

      setJudgeUniqueWpNOError("");
      setJudgeUniqueProgramNameError("");
    }

    return result;
  }
  //#endregion

  //#region 檢查工件資訊是否重複API
  const handleApiCreateWorkpiece = async (doCreate) => {
    let postJson = {
      doCreate: doCreate
    };

    if(createWpNO != "" && createWpName != ""){
      postJson.wpNO = createWpNO;
      postJson.wpName = createWpName;
    }

    if(createProgramName != ""){
      postJson.programName = createProgramName;
    }

    let response = await apiCreateWorkpiece(postJson);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 儲存按鈕
  const handleSave = async () => {
    setJudgeCheckErr(true);

    let checkError = await checkCol();
    if(!checkError){
      let checkUniquePartErr = await checkUniquePart(true, true);
      if(!checkUniquePartErr){

        handleClose();
      }
    }
  }
  //#endregion

  //#region 檢查欄位是否有問題
  const checkCol = async () => {
    let result = false;
    if((createWpNO === "" && createWpName !== "") || (createWpNO !== "" && createWpName === "")){
      result = true;
    }
    else{
      if((createWpNO !== "" && createWpNO.length > 100) || (createWpName !== "" && createWpNO.length > 100)){
        result = true;
      }
    }

    if(createProgramName !== "" && createProgramName.length > 100){
      result = true;
    }

    //上面錯誤訊息沒有錯再進入API檢查名稱重複問題
    if(!result){
      //檢查工件是否有重複
      let checkUniquePartErr = await checkUniquePart(true);
      if(checkUniquePartErr){
        return true;
      }
    }
    return result;
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("create")}<br />
            <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className='createPartCard'>
              <Row>
                <Col xs={12}>
                  <Form.Group className="mb-3" controlId="part">
                    <Form.Label className='form-label-custom'>
                      {t("partID")}
                      <span className='form-textnum ms-1'>({createWpNO.length ?? 0}/100)</span>
                      /{t("name")}
                      <span className='form-textnum ms-1'>({createWpName.length ?? 0}/100)</span>
                    </Form.Label><br />
                    <Row>
                      <Col xs={6} className='pe-0'>
                        <Form.Control
                            type="text" name="createWpNO" value={createWpNO}
                            onChange={handleInputChange} onBlur={checkUniquePart} autoComplete="off" 
                            className={`rounded-0 ${judgeCheckErr && ((createWpNO === ""  && createWpName !== "") || createWpNO.length > 100 || !judgeUniqueWpNO) ? "input-error" : ""}`}
                        />
                      </Col>
                      <Col xs={6} className='ps-0'>
                        <Form.Control
                            type="text" name="createWpName" value={createWpName}
                            onChange={handleInputChange} onBlur={checkUniquePart} autoComplete="off" 
                            className={`rounded-0 ${judgeCheckErr && ((createWpName === "" && createWpNO !== "") || createWpName.length > 100) ? "input-error" : ""}`}
                        />
                      </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className='pe-0'>
                        {
                            judgeCheckErr && createWpNO === "" && createWpName !== "" ?
                            <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                            <></>
                        }
                        {
                            judgeCheckErr && createWpNO !== "" && createWpNO.length > 100 ?
                            <Form.Label className='text-danger mb-0'>
                                {t("exceedsCharacterLimit")}
                            </Form.Label> :
                            <></>
                        }
                        {
                            judgeCheckErr && createWpNO !== "" && createWpNO.length <= 100 && !judgeUniqueWpNO ?
                            <Form.Label className='text-danger mb-0'>
                                {t("thisPartIDAlreadyTaken")}&nbsp;{judgeUniqueWpNOError}
                            </Form.Label> :
                            <></>
                        }
                        </Col>
                        <Col xs={6} className='ps-0'>
                        {
                            judgeCheckErr && createWpNO !== "" && createWpName === "" ?
                            <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                            <></>
                        }
                        {
                            judgeCheckErr && createWpName !== "" && createWpName.length > 100 ?
                            <Form.Label className='text-danger mb-0'>
                                {t("exceedsCharacterLimit")}
                            </Form.Label> :
                            <></>
                        }
                        </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group className="mb-3" controlId="programName">
                    <Form.Label className='form-label-custom'>
                      {t("machiningProgram")}
                      <span className='text-danger ms-1'>*</span>
                      <span className='form-textnum ms-1'>({createProgramName.length ?? 0}/100)</span>
                    </Form.Label><br />
                    <Form.Control
                      type="text" name="createProgramName" value={createProgramName}
                      onChange={handleInputChange} onBlur={checkUniquePart} autoComplete="off" 
                      className={`${judgeCheckErr && (createProgramName !== "" && (createProgramName.length > 100 || !judgeUniqueProgramName)) ? "input-error" : ""}`}
                    />
                    {
                      judgeCheckErr && createProgramName !== "" && createProgramName.length > 100 ?
                        <Form.Label className='text-danger mb-0'>
                        {t("exceedsCharacterLimit")}
                        </Form.Label> :
                        <></>
                    }
                    {
                      judgeCheckErr && createProgramName !== "" && createProgramName.length <= 100 && !judgeUniqueProgramName ?
                        <Form.Label className='text-danger mb-0'>
                        {
                            judgeUniqueProgramNameError === "" ? 
                            t("thisProgramAlreadyTaken2") : <>{t("thisProgramAlreadyTaken")}&nbsp;{judgeUniqueProgramNameError}</>
                        }
                        </Form.Label> :
                        <></>
                    } 
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="btn-gp">
            <div>
              <button type="button" className="btn btn-txt-black" onClick={handleClose}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-lv1 fat" onClick={handleSave}>
                {t("save")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} className='ps-0'>
              <button type="button" className="button-not-border" onClick={handleClose}>
                {t("cancel")}
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end pe-0'>
              <button className="button-primary" onClick={handleSave}>
                {t("save")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
}

export default AddWorkpiece;