import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Modal } from 'react-bootstrap';
import { apiCreatedProcessList } from 'utils/Api';
import { isNumber, isPositiveInteger, formatTimeDays} from 'utils/commonFun';
import { ToastContainer, toast } from 'react-toastify';

const VewAllProcess = ({showModal, handleClose, wpID, wpNO, wpName}) => {
  const { t, i18n } = useTranslation("aps");  
  const { overtime, authority } = useContext(MyUserContext);

  const [processDetail, setProcessDetail] = useState(null);

  useEffect(() => {
    if(showModal){
      GetCreatedProcessList();
    }
  }, [showModal]);

  //#region 取得該工件已建立工序
  const GetCreatedProcessList = async () => {
    const [httpStatus, reponseData] = await handleApiCreatedProcessList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        setProcessDetail(reponseData.data);
      }
    }
  }
  //#endregion

  //#region API取得該工件已建立工序
  const handleApiCreatedProcessList = async () => {
    let postJson = {
      wpID: wpID
    };
    let response = await apiCreatedProcessList(postJson);
    if(response){
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion


  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{wpNO} {wpName}</div>
          </div>
          <button type="button" className="close" 
            data-dismiss="modal" aria-label="Close" 
            onClick={handleClose}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          <div className="modal-view">
            <div className="gp">
              <ul className="gp-greyline">
                <li className="lv1 side2">
                  <div className="name">{t("processCount")}</div>
                  <div className="content">{processDetail?.length ?? 0}</div>
                </li>
              </ul>
            </div>
            <div className="gp">
              <h2>{t("processes")}</h2>
              <ul className="gp-greyline">
                {
                  processDetail?.map((obj, index) => {
                    return <li className="lv1 side1 btn" tabIndex="0" key={index}>
                      <div className="name">{obj.processNo} {obj.processName}</div>
                    </li>;
                  })
                }
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-gp">
            <div>
            </div>
            <button className="btn btn-lv1 fat" onClick={handleClose}>{t("cancel")}</button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default VewAllProcess;