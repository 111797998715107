import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { getCookieLang } from 'utils/langUtil';
import { useNavigate, useLocation } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { getCookieLogin, removeCookieLogin } from 'utils/loginUtil';
import { getMisc } from 'utils/commonFun';
import PageErr404 from "page/PageErr404";   //頁面不存在
import moment from 'moment';

import './APS.css';
import './APS-table.css';
import './APS-modal.css';

import { apiQueryAuthority, apiMachineListData, apiIdeaLogin} from 'utils/Api';

const APS = (props) => {
  const { t, i18n } = useTranslation("aps");

  const navigate = useNavigate();
  let location = useLocation();

  const [showOverTimeMsg, setShowOverTimeMsg] = useState(false);
  const [showPageErr404, setShowPageErr404] = useState(false); //是否要顯示404頁面

  const [authority, setAuthority] = useState(null); //權限列表
  const [machineList, setMachineList] = useState([]);  //所有設備的列表
  const [lang, setLang] = useState("en"); //語系

  const [currentFunPage, setCurrentFunPage] = useState("SchedulingChart"); //SchedulingChart:排程甘特圖 folder:資料夾 WorkOrderScheduling:工單排程 StorageStatus: 儲存狀態

  useEffect(() => {
    i18n.changeLanguage(getCookieLang() ?? "en");    
    setLang(getCookieLang() ?? "en");
    moment.locale(lang); //設定日曆套件語系

    handleAuthority();
    loadMachineList();

    let pathname = location.pathname;
    let first_slash_index = pathname.indexOf("/");
    let second_slash_index = pathname.indexOf("/", first_slash_index + 1);
    
    if(pathname.substring(second_slash_index + 1) === "folder"){
      setCurrentFunPage("folder");
    }
    else if(pathname.substring(second_slash_index + 1) === "StorageStatus"){
      setCurrentFunPage("StorageStatus");
    }

    let misc = getMisc();
    document.body.className = misc?.darkTheme ? "Dark" : "Light";
   }, []);

   useEffect(() => {
     loadMachineList();
   }, [authority]);

  //#region 載入權限
  const handleAuthority = async () => {
    const [httpStatusUser, reponseDataUser] = await handleApiAuthority();
    if (httpStatusUser == "200") {
      if (reponseDataUser.statusCode === "20000") {
        setAuthority(reponseDataUser.data);
      }
      else if (reponseDataUser.statusCode === "40103") {
        overtime();
      }
      else {
        setAuthority(null);
      }
    }
    else {
      setAuthority(null);
    }
  }
  //#endregion

  //#region 權限API
  const handleApiAuthority = async () => {
    let postData = {};

    let response = await apiQueryAuthority(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
    const handleApiIdeaLogin = async () => {
      const formData = new FormData();
      let userAccount = authority?.userAccount;

      if (userAccount === undefined) {
        const [httpStatusUser, reponseDataUser] = await handleApiAuthority();
        if (httpStatusUser == "200") {
          if (reponseDataUser.statusCode === "20000") {
            userAccount = reponseDataUser.data.userAccount;            
          }
        }
      }

      formData.append('accountId', userAccount);
      formData.append('password', 'Demo2023');
      
      let ideaLoginResponse = await apiIdeaLogin(formData);
      
      if(ideaLoginResponse){
          const httpStatus = ideaLoginResponse.request.status;
          const reponseData = ideaLoginResponse.data;
  
          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

  //#region 下拉選單機台列表API
    const handleApiMachineList = async () => {
      let machineListResponse = await apiMachineListData();
    
      if (machineListResponse) {
        const httpStatus = machineListResponse.request.status;
        const reponseData = machineListResponse.data;
  
        return [httpStatus, reponseData];
      }
      else {
        return ["500", ""]
      }
    }
    //#endregion

  //#region 載入機器列表
  const loadMachineList = async () => {
    const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
    let newMachineList = [];

    if (httpStatusMachine == "200") {
      if (reponseDataMachine.statusCode === "20000") {
        let artMachine = reponseDataMachine.data;
        artMachine.unshift({ mid: 0, mShortName: t("all") });

        // 加上只顯示艾創點的機器列表（讓APS顯示的機器與Monitor一致）
        const [httpStatus, reponseData] = await handleApiIdeaLogin();

        if(httpStatus === 200){
          let ideaMachine = reponseData?.artUserInfo?.owingMachines;

          for(let item of ideaMachine){
            let filter = artMachine.find(d => d.machineSn === item.serialNumber);

            if(filter){
              newMachineList.push({
                mShortName: filter.mShortName,
                mid: filter.mid                      
              });
            }
          }
        }
        else {
          for(let item of artMachine){
            newMachineList.push({
              mShortName: item.mShortName,
              mid: item.mid      
            });
          }
        }
        
        setMachineList(newMachineList);
      }
      else if (reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104") {
        overtime();
      }
    }
  };
  //#endregion

  //#region token過期無效登出訊息
  const overtime = async () => {
    if (getCookieLogin()) {
      setShowOverTimeMsg(true);
    }
    else {
      removeCookieLogin();    
    }
  }
  //#endregion

  //#region 登出
  const logInAgain = async () => {
    removeCookieLogin();
    navigate("/");

    localStorage.clear();
  }
  //#endregion

  //#region 顯示404頁面
  const show404Page = () => {
    setShowPageErr404(true);
  }
  //#endregion

  //#region 切換頁面
  const handleMenu = (page) => {
    setCurrentFunPage(page);
    navigate(`/APS/${page}`);
  }
  //#endregion

  return (
    <>
      <MyUserContext.Provider value={{ overtime: overtime, show404Page: show404Page, authority: authority, handleApiAuthority: handleApiAuthority, machineList: machineList }}>
        {
          !showPageErr404 ?
            <div className='aps'>
              <nav className="nav">
                {/* <div className="hamburger">
                  <div className="icon"></div>
                </div> */}
                <div className="list scroll">
                  {/* <!--生產監控--> */}
                  {/* <div className="item">
                      <div className="icon icon-Monitor"></div>
                      <div className="text">Production Monitoring</div>
                  </div> */}
                  {/* <!--排程甘特圖--> */}
                  <div className={`item ${currentFunPage === "SchedulingChart" ? "active" : ""} cursor-pointer`}
                    onClick={() => handleMenu("SchedulingChart")}>
                    <div className="icon icon-Timeline"></div>
                    <div className="text">{t("schedulingChart")}</div>
                  </div>
                  {/* <!--工單和排程--> */}
                  <div className={`item ${currentFunPage === "WorkOrderScheduling" ? "active" : ""} cursor-pointer`}
                    onClick={() => handleMenu("WorkOrderScheduling")}>
                    <div className="icon icon-Schedule"></div>
                    <div className="text">{t("workOrderScheduling")}</div>
                  </div>
                  {/* <!--資料夾--> */}
                  <div className={`item ${currentFunPage === "folder" ? "active" : ""} cursor-pointer`}
                    onClick={() => handleMenu("folder")}>
                    <div className="icon icon-Folder"></div>
                    <div className="text">{t("folder")}</div>
                  </div>
                  {/* <!--倉儲狀態--> */}
                  <div className={`item ${currentFunPage === "StorageStatus" ? "active" : ""} cursor-pointer`}
                    onClick={() => handleMenu("StorageStatus")}>
                    <div className="icon icon-Storage"></div>
                    <div className="text">{t("storageStatus")}</div>
                  </div>
                </div>
              </nav>
              <main className="main">
                <section>
                  {props.children}
                </section>
              </main>
            </div> : <PageErr404 judgeBackHome={false} />
        }

      </MyUserContext.Provider>

      <div className={`fade modal-backdrop ${showOverTimeMsg ? "show" : "d-none"}`}></div>
      <div className={`token-circle-div ${!showOverTimeMsg ? "d-none" : ""}`}>
        <div className="pwd-container">
          <div className='message-board'>
            <p className='message-board-title mb-0'>
              <b>{t("connectionExpired")}</b>
            </p>
            <p className='message-board-content'>
              {t("connectionExpiredMsg1")}<br />
              {t("connectionExpiredMsg2")}<br />
            </p>
            <input type="button" className='btn btn-lv1 w-100' value={t("logInAgain")} onClick={logInAgain} />
          </div>
        </div>
      </div>
    </>
  );
};

export default APS;
