import React, {useState, useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useLocation, useNavigate  } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';

import { capitalizeFirstLetter } from 'utils/commonFun';

import './sidebarMenu.css';

const SidebarMenu = () => {
  let location = useLocation(); 
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { authority, permission } = useContext(MyUserContext);

  const [menuList, setMenuList] = useState([]);

  //#region 初始載入
  // useEffect(() => {
  //   if(authority != null){
  //     //要隱藏歷史數據選單的人
  //     // const authHistory = window.authHistory;
  //     // let filter = authHistory.find(d => d === authority.userAccount);
      
  //     // const isLocalServer = window.isLocalServer;
  //   }
  // }, [authority]);
  //#endregion

  useEffect(() => {
    let auth = [];
    var pathname = location.pathname;
    var first_slash_index = pathname.indexOf("/");
    var second_slash_index = pathname.indexOf("/", first_slash_index + 1);
    const defaultTabs = [
      {
        tabName: "Monitor",
        iconName: "icon-Monitor",
        // iconName: "desktop_windows",
        component: "/Monitor/MachineStatus",
        active: second_slash_index !== -1 && pathname.substring(0, second_slash_index) === "/Monitor"
      },
      {
        tabName: "Analytics",
        iconName: "icon-Analytics",
        // iconName: "signal_cellular_alt ",
        component: "/Analytics/OverallEquipmentEfficiency",
        active: second_slash_index !== -1 && pathname.substring(0, second_slash_index) ===  '/Analytics'
      },
      {
        tabName: "Power",
        iconName: "icon-Power",
        // iconName: "bolt",
        component: "/Power/PartsPrograms",
        active: second_slash_index !== -1 && pathname.substring(0, second_slash_index) === '/Power'
      },
      {
        tabName: "History",
        iconName: "icon-History",
        // iconName: "timeline",
        component: "/History/Messages",
        active: second_slash_index !== -1 && pathname.substring(0, second_slash_index) === '/History'
      },
      {
        tabName: "RawData",
        iconName: "icon-RawData",
        // iconName: "library_books",
        component: "/RawData/EquipmentUtilizationShifts",
        active: second_slash_index !== -1 && pathname.substring(0, second_slash_index) === '/RawData'
      },
      {
        tabName: "Apps",
        iconName: "icon-Apps",
        // iconName: "widgets",
        component: "/Apps",
        active: location.pathname === "/Apps"
      },
      {
        tabName: "Folder",
        iconName: "icon-Folder",
        // iconName: "folder_open",
        component: "/Folder/Workpieces",
        active: second_slash_index !== -1 && pathname.substring(0, second_slash_index) === '/Folder'
      },
      {
        tabName: "Reports",
        iconName: "icon-Reports",
        // iconName: "lab_profile",
        component: "/Reports",
        active: (second_slash_index !== -1 && pathname.substring(0, second_slash_index) === '/Reports') || location.pathname === "/Reports"
      }
    ];
    
    if(permission !== null){
      for(let index = 0; index < permission.length; index++){
        const tab = defaultTabs.find(tab => tab.tabName === permission[index].tabName);
        if(tab){
          let url = `/${permission[index].tabName}${permission[index].tabList.length > 0 ? `/${permission[index].tabList[0]}` : ""}`;
          if(tab.tabName === "Apps"){
            url = `/${permission[index].tabName}`;
          }
          
          tab.component = url;
          auth.push(tab);
        }
      }
    }
    else{
      auth = defaultTabs;
    }
    setMenuList(auth);
  }, [permission, location]);

  const hanlderMenu = (tabName, url) => {
    if(tabName === "Parts"){
      localStorage.removeItem("workpieces_choicePage");
      localStorage.removeItem("workpieces_detailPage");
      localStorage.removeItem("workpieces_calMethod");
      localStorage.removeItem("workpieces_choiceWorkpiece");
      localStorage.removeItem("workpieces_choiceProgram");
      localStorage.removeItem("workpieces_baseInfo");

      localStorage.removeItem("power_keyword");
      localStorage.removeItem("power_startDate");
      localStorage.removeItem("power_endDate");
    }

    if(tabName === "Reports"){
      localStorage.removeItem("reports_keyword");
      localStorage.removeItem("report_startDate");
      localStorage.removeItem("report_endDate");
      localStorage.removeItem("programListRequest");
      localStorage.removeItem("reportContent");
      localStorage.removeItem("executeListRequest");
    }

    //需要刪掉報告查詢起訖時間

    setMenuList((prevMenuList) => 
        prevMenuList.map((menu) => ({
        ...menu,
        active: menu.tabName === tabName,
        }))
    );

    navigate(url);
  }

  return (
      <nav className='sidebar'>
        <ol>
        {
            menuList && menuList.map((obj, index) => {
              return <li key={index} className={`${obj.active ? "active" : ""}`} onClick={(e)=>hanlderMenu(obj.tabName, obj.component)}>
                  <div className={`icon ${obj.iconName}`}></div>
                  <div className="text">{t(capitalizeFirstLetter(obj.tabName))}</div>
              </li>;
            })
        }
        {/* {
            menuList && menuList.map((obj, index) => {
              return <li key={index}>
                <div className={`${obj.active ? "active" : ""}`} onClick={(e)=>hanlderMenu(obj.tabName, obj.component)}>
                  <div class="icon">{obj.iconName}</div>
                  <div>{t(capitalizeFirstLetter(obj.tabName))}</div>
                </div>
              </li>;
            })
        } */}
        </ol>
        {/* {
            menuList && menuList.map((obj, index) => {
              return <div className={`menuBtn ${obj.active ? "active" : ""}`} key={index} onClick={(e)=>hanlderMenu(obj.tabName, obj.component)}>
                <p className="material-symbols-rounded mb-0">{obj.iconName}</p>
                <p className='font10 mb-0'>{t(capitalizeFirstLetter(obj.tabName))}</p>
              </div>;
            })
        } */}
      </nav>
    
  );
};

export default SidebarMenu;