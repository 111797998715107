import Cookies from 'universal-cookie';
const cookies = new Cookies();
const domain = window.cookieDomain;

//#region ¥Î¤átoken
const LOGIN_NAME = 'login';
export const setCookieLogin = (login) => {
    cookies.set(LOGIN_NAME, login, { HttpOnly: true, path: "/", domain: domain });
}
export const getCookieLogin = () => {
    var login = cookies.get(LOGIN_NAME);
    if (login != null) {
        return login;
    } else {
        return null;
    }
}

export const removeCookieLogin = () => {
    cookies.remove(LOGIN_NAME, { path: "/", domain: domain });
}

export const checkCookieLogin = () => {
    var login = cookies.get(LOGIN_NAME);
    if (login != undefined && login != null) {
        return true;
    } else {
        return false;
    }
}
//#endregion