import React, {useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MyUserContext } from 'contexts/MyUserContext';
import { convertDateFormat, decimalWithOneDecimal, formatTime, 
  generateTimeSlots, formatDateTimeRange } from 'utils/commonFun';
import { apiDailyShiftStatusDurationData, apiGetWorkHours, apiDailyProgramDuration } from 'utils/Api';

//#region 機台稼動率概要
const TimeLine = (props) => {
  const {startDate, endDate, choiceMachine} = props;

  const { t } = useTranslation("equipmentRuntime");
  const { lang, showFormatDate, showFormatTime } = useContext(MyUserContext);

  const [statusDuration, setStatusDuration] = useState(null); //顯示長條圖

  const [workHours, setWorkHours] = useState(null); //顯示上班時間
  const [timeList, setTimeList] = useState([]); //時間軸上方列表

  const [dailyProgramDuration, setDailyProgramDuration] = useState(null); //顯示加工程式長條圖

  //#region 初始載入
  useEffect(() => {
    if(startDate != "" && endDate != "" && choiceMachine != null){
      const fetchData = async () => {
          //#region 各班別長條圖稼動率API
          const [httpStatusShiftStatusDuration, reponseDataShiftStatusDuration] = await handleApiShiftStatusDurationData();
          if(httpStatusShiftStatusDuration == "200"){
              if(reponseDataShiftStatusDuration.statusCode === "20000"){
                  setStatusDuration(reponseDataShiftStatusDuration.data);
              }
          }
          //#endregion

          //#region 抓取上班時間
          const [httpStatusWorkHours, reponseDataWorkHours] = await handleApiGetWorkHours();
          if(httpStatusWorkHours == "200"){
            if(reponseDataWorkHours.statusCode === "20000"){
                setWorkHours(reponseDataWorkHours.data);
            }
          }
          //#endregion

          //#region 顯示加工程式長條圖API
          const [httpStatusDailyProgramDuration, reponseDataDailyProgramDurationData] = await handleApiDailyProgramDurationData();
          if(httpStatusDailyProgramDuration == "200"){
            if(reponseDataDailyProgramDurationData.statusCode === "20000"){
              setDailyProgramDuration(reponseDataDailyProgramDurationData.data);
            }
          }
          //#endregion
      }
      fetchData();
    }
  }, [startDate, endDate, choiceMachine]);
  //#endregion

  useEffect(() => {
    if(workHours != null){
      const updatedTime = moment(workHours.dailyStartTime, "HH:mm").add(1, 'hours').format("HH:mm");
      setTimeList(generateTimeSlots(updatedTime));
    }
  }, [workHours]);

  //#region 各班別長條圖稼動率API
  const handleApiShiftStatusDurationData = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        id: choiceMachine.mid,
        useCustomStartTime: true
    }

    let dailyShiftStatusDurationResponse = await apiDailyShiftStatusDurationData(postData);
    if(dailyShiftStatusDurationResponse){
        const httpStatus = dailyShiftStatusDurationResponse.request.status;
        const reponseData = dailyShiftStatusDurationResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 抓取上班時間
  const handleApiGetWorkHours = async () => {
    let workHoursResponse = await apiGetWorkHours();
    if(workHoursResponse){
        const httpStatus = workHoursResponse.request.status;
        const reponseData = workHoursResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 顯示加工程式長條圖API
  const handleApiDailyProgramDurationData = async () => {
    const postData = {
        startDate: startDate,
        endDate: endDate,
        mid: choiceMachine.mid,
        useCustomStartTime: true
    }

    let response = await apiDailyProgramDuration(postData);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  return(
      <>
        {/* <!--第5排-時間軸--> */}
        <section>
          <div className="box-style-white-shadow mtop10 chart_machinestatus">
              <div className="margin20">
                <div className="timeline">
                    <table>
                        <thead className="sticky top-32">
                            <tr className="smallScreen">
                              <th></th>
                              <td colSpan="6">{t("AM")}</td>
                              <td colSpan="6">{t("PM")}</td>
                            </tr>
                            <tr className="time">
                                <th></th>
                                {
                                  timeList?.map((obj, objIndex) => {
                                    return (
                                      <td key={`time${objIndex}`} className={objIndex < timeList.length - 1 && convertDateFormat(timeList[objIndex + 1], "A") != convertDateFormat(timeList[objIndex], "A") ? "crossline" : ""}>
                                        {
                                          lang === "en" ?
                                          <>
                                            {convertDateFormat(obj, `${showFormatTime.startsWith("H") ? "H" : "h"}`)}
                                            <span>:{convertDateFormat(obj, "mm")}</span>
                                            {showFormatTime.includes("A") ? <><br /><span>{convertDateFormat(obj, "A")}</span></> : null}
                                          </>:
                                          <>
                                            {showFormatTime.includes("A") ? <span>{t(`${convertDateFormat(obj, "A")}`)}<br /></span> : null}
                                            {convertDateFormat(obj, `${showFormatTime.startsWith("H") ? "H" : "h"}`)}<span>:{convertDateFormat(obj, "mm")}</span>
                                          </>
                                        }
                                      </td>
                                    )
                                  })
                                }
                            </tr>
                        </thead>
                        <tbody className="scroll-body scroll">
                          {
                            statusDuration?.map((obj, objIndex) => {
                              let leftWidth = 0;
                              let programLeftWidth = 0;
                              return(
                                <tr key={objIndex}>
                                  <th className="date">
                                    {(() => {
                                      const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");
                                      const removeYear = cleanString(showFormatDate.replace(/YYYY/g, ""));
                                      const trans = formatDateTimeRange(removeYear, null, obj.showDate);
                                      return `${trans.startDate}`;
                                    })()}
                                    <span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span>
                                  </th>
                                  <td colSpan="12">
                                    <div className="chart_timeline_machinestatus">
                                      <div>
                                      {
                                        obj.shiftList[0].statusList?.map((statusObj, statusObjIndex) => {
                                          let statusCss = "";
                                          if(statusObj.status === "Machining"){
                                            statusCss = "running";
                                          }
                                          else if(statusObj.status === "Idle"){
                                            statusCss = "idle";
                                          }
                                          else if(statusObj.status === "Alarm"){
                                            statusCss = "error";
                                          }
                                          else if(statusObj.status === "Offline"){
                                            statusCss = "offline";
                                          }
                                          else{
                                            statusCss = "nodata";
                                          }
                                          
                                          let width = decimalWithOneDecimal(statusObj.seconds, 86400, 2);
                                          const statusElement = (
                                              <div className={statusCss} key={statusObjIndex}>
                                                <div style={{width: `${String(leftWidth)}%`}}></div>
                                                <OverlayTrigger
                                                  key={statusObjIndex}
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip>
                                                      <div className={`tp_machinestatus ${statusCss}`}>
                                                        {
                                                          statusObj.status === "Upcoming" ?
                                                          <div>
                                                            <div className="status">{t("upcoming")}</div>
                                                            <div className="datetime">
                                                              {t("periodOperationalDataYet")}
                                                            </div>
                                                          </div> :
                                                          <>
                                                            <div>
                                                              <div className="dot"></div>
                                                            </div>
                                                            <div>
                                                                <div className="status">
                                                                {(() => {
                                                                  if(statusObj.status === "Machining"){
                                                                    return t("running");
                                                                  }
                                                                  else if(statusObj.status === "Idle"){
                                                                    return t("idlePreparing");
                                                                  }
                                                                  else if(statusObj.status === "Alarm"){
                                                                    return t("error");
                                                                  }
                                                                  else if(statusObj.status === "Offline"){
                                                                    return t("powerOffOffline");
                                                                  }
                                                                  else{
                                                                    return t("noData");
                                                                  }  
                                                                })()}
                                                                </div>
                                                                <div className="datetime">
                                                                <div
                                                                  dangerouslySetInnerHTML={{
                                                                    __html: (() => {
                                                                      const trans = formatDateTimeRange(showFormatDate, showFormatTime, statusObj.datetimeStart, statusObj.datetimeEnd);
                                                                      return `${trans.startDate}<br /> ${trans.startTime} ~ ${trans.endDate}<br /> ${trans.endTime}`;
                                                                    })(),
                                                                  }}
                                                                ></div>
                                                                </div>
                                                                <div className="duration">
                                                                    <div className="title">{t("duration")}</div>
                                                                    <div className="content">{formatTime(statusObj.seconds)}</div>
                                                                </div>
                                                                <hr />
                                                                <div className="program">
                                                                  {(() => {
                                                                    let programExecution = 0; //執行中的程式 1:執行中的程式 2:沒有程式資料 3:暫停中的程式 4:沒有選擇程式
                                                                    if(statusObj.status === "Machining"){
                                                                      if(statusObj.cnc_pgm_name !== "-"){
                                                                        programExecution = 1;
                                                                      }
                                                                      else{
                                                                        programExecution = 2;
                                                                      }
                                                                    }
                                                                    else if(statusObj.status === "Idle" || statusObj.status === "Alarm"){
                                                                      if(statusObj.cnc_pgm_name !== "-"){
                                                                        programExecution = 3;
                                                                      }
                                                                      else{
                                                                        programExecution = 4;
                                                                      }
                                                                    } 
                                                                    else if(statusObj.status === "Offline"){
                                                                      programExecution = 4;
                                                                    }

                                                                    if(programExecution === 1){
                                                                      return <div className="title">{t("programExecution")}</div>;
                                                                    }
                                                                    else if(programExecution === 2){
                                                                      return <div className="title">{t("noProgramData")}</div>;
                                                                    }
                                                                    else if(programExecution === 3){
                                                                      return <div className="title">{t("programHold")}</div>;
                                                                    }
                                                                    else if(programExecution === 4){
                                                                      return <div className="title">{t("noProgramSelected")}</div>;
                                                                    }
                                                                  })()}
                                                                  {
                                                                      statusObj.status !== "Offline" && statusObj.cnc_pgm_name !== "-" ?
                                                                      <div className="content">
                                                                        <div>
                                                                          {
                                                                            statusObj.wpName ?
                                                                            <span className="part">{statusObj.wpName}</span> :
                                                                            null
                                                                          }
                                                                            <span className="programname">{statusObj.cnc_pgm_name}</span>
                                                                        </div>
                                                                      </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                          </>
                                                        }
                                                        
                                                    </div>
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div className="status" style={{width: `${String(width)}%`}}></div>
                                                </OverlayTrigger> 
                                              </div>
                                            
                                          );

                                          leftWidth += parseFloat(width);
                                          return statusElement;
                                        })
                                      }
                                      </div>
                                    </div>
                                    <div className="chart_timeline_programrun">
                                      <div>
                                      {(() => {
                                        if(dailyProgramDuration){
                                          const isFirstNeedStart = dailyProgramDuration[objIndex].isFirstNeedStart;
                                          const isLastNeedEnd = dailyProgramDuration[objIndex].isLastNeedEnd;
                                          const programList = dailyProgramDuration[objIndex].programList;

                                          if(Array.isArray(programList)) {
                                            const statusElements = []; // 建立一個陣列存放元素
                                            for(let [programObjIndex, programObj] of programList?.entries()){
                                              let cssStart = "run-start";
                                              let cssEnd = "run-finished";
                                              let width = decimalWithOneDecimal(programObj.seconds, 86400, 2);
                                              if(programObjIndex === 0 && !isFirstNeedStart){
                                                cssStart = "";
                                              }

                                              if(programObjIndex === programList.length - 1 && !isLastNeedEnd){
                                                cssEnd = "";
                                              }

                                              const statusElement = (
                                                <div key={`${objIndex}${programObjIndex}`}>
                                                  <div style={{width: `${String(programLeftWidth)}%`}}></div>
                                                  {
                                                    programObj.programName != "-" ?
                                                    <OverlayTrigger
                                                      placement="top"
                                                      overlay={
                                                        <Tooltip>
                                                          <div className="tp_machinestatus offline">
                                                            <div>
                                                              <div className="program">
                                                                <div className="title">{t("program")}</div>
                                                                <div className="content">
                                                                  <div>
                                                                    {
                                                                      programObj.wpName ?
                                                                      <span className="part">{programObj.wpName}</span> :
                                                                      null
                                                                    }
                                                                    <span className="programname">{programObj.programName}</span>
                                                                  </div>
                                                                </div>
                                                                <div className="datetime">
                                                                  <div
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: (() => {
                                                                        const trans = formatDateTimeRange(showFormatDate, showFormatTime, programObj.datetimeStart, programObj.datetimeEnd);
                                                                        return `${trans.startDate}<br /> ${trans.startTime} ~ ${trans.endDate}<br /> ${trans.endTime}`;
                                                                      })(),
                                                                    }}
                                                                  ></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <div className={`run ${cssStart} ${cssEnd}`} style={{width: `${String(width)}%`}}>
                                                          <div className="runshadow"></div>
                                                      </div>
                                                    </OverlayTrigger>:
                                                    <div className={`run`} style={{width: `${String(width)}%`}}>
                                                      <div></div>
                                                    </div>
                                                  }
                                                </div>
                                              );

                                              programLeftWidth += parseFloat(width);
                                              statusElements.push(statusElement); // 將元素推入陣列
                                            }
                                            return statusElements; // 返回所有元素
                                          }
                                        }
                                      })()}  
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                    </table>
                </div>
                <ChartWithMouseLine />
              </div>
          </div>
      </section>


      </>
  );
}
//#endregion

const ChartWithMouseLine = () => {
  const chartRef = useRef(null);
  const verticalLineRef = useRef(null);
  const [mouseX, setMouseX] = useState(null);

  const handleMouseMove = (event) => {
    const chart = chartRef.current;
    if (chart) {
      const chartRect = chart.getBoundingClientRect();
      const mouseXPosition = event.clientX - chartRect.left;
      const mouseYPosition = event.clientY;

      if (event.clientX >= chartRect.left && event.clientX <= chartRect.right &&
        mouseYPosition >= chartRect.top && mouseYPosition <= chartRect.bottom) {
        setMouseX(mouseXPosition);
        verticalLineRef.current.style.display = 'block';
      } else {
        verticalLineRef.current.style.display = 'none';
      }
    }
  };

  const handleTouchMove = (event) => {
    const chart = chartRef.current;
    if (chart) {
      const chartRect = chart.getBoundingClientRect();
      const touch = event.touches[0]; // First touch point
      const touchXPosition = touch.clientX - chartRect.left;
      const touchYPosition = touch.clientY;

      if (touch.clientX >= chartRect.left && touch.clientX <= chartRect.right &&
        touchYPosition >= chartRect.top && touchYPosition <= chartRect.bottom) {
        setMouseX(touchXPosition);
        verticalLineRef.current.style.display = 'block';
      } else {
        verticalLineRef.current.style.display = 'none';
      }
    }
  };

  const handleTouchEnd = () => {
    verticalLineRef.current.style.display = 'none'; // Hide line when touch ends
  };

  useEffect(() => {
    const chart = chartRef.current;

    // Add mouse and touch event listeners
    document.addEventListener('mousemove', handleMouseMove);
    chart.addEventListener('touchmove', handleTouchMove);
    chart.addEventListener('touchend', handleTouchEnd);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      if (chart) {
        chart.removeEventListener('touchmove', handleTouchMove);
        chart.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, []);

  return (
    <div className="deco_timeline_mouseLine">
      <div className="space"></div>
      <div className="chart">
        <div>
          <div id="chartMouseMove" ref={chartRef} style={{ position: 'relative' }}>
            <div
              id="vertical-line"
              ref={verticalLineRef}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                width: '1px',
                backgroundColor: 'black',
                left: mouseX !== null ? `${mouseX}px` : '0',
                display: 'none',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLine;