import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { useParams, useNavigate } from 'react-router-dom';

import './FolderMain.css';

import Tab from 'components/basic/tab';
import Workpieces from "page/folder/data/Workpieces"; //工件
import Programs from "page/folder/data/Programs"; //加工程序

const FolderMain = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  let { tabName } = useParams();
  const { permission } = useContext(MyUserContext);
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "Workpieces",
        active: tabName === "Workpieces"
      },
      {
        tabName: "Programs",
        active: tabName === "Programs"
      }
    ];

    if(permission !== null){
      let filterMainTab = permission.find(d => d.tabName === "Folder");
      if(filterMainTab){
        for(let item of filterMainTab.tabList){
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if(tab) newTabList.push(tab);
        }
      }
      else{
        newTabList = defaultTabs;
      }
    }
    else{
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if(filter){
      navigate(`/Folder/${filter.tabName}`);
    }
  }, [tabList]);

  return (
    <>
      <div>
        <Tab tabList={tabList} setTabList={setTabList} />
        
          {
            tabList?.find(d => d.active && d.tabName === "Workpieces") ?
              <Workpieces tabList={tabList} setTabList={setTabList} /> :
              null
          }
          {
            tabList?.find(d => d.active && d.tabName === "Programs") ?
              <Programs tabList={tabList} setTabList={setTabList} /> :
              null
          }
      </div>
    </>
  );
};

export default FolderMain;