import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import MessageModal from 'components/basic/MessageModal';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { apiSchedulePreview, apiScheduleConfirm } from 'utils/Api';
import { convertDateFormat } from 'utils/commonFun';
import Scheduling from '../workOrderScheduling/Scheduling';

const AutoScheduling = ({ showModal, handleClose, chooseOrderID, loadOrderList, isChangeSchedule, setManualSchedulingModal, loadOrderCountAndStatus, ratingCriteria}) => {
  const { t, i18n } = useTranslation("aps");
  const { overtime, authority } = useContext(MyUserContext);

  const [algorithm, setAlgorithm] = useState(2);  //演算法 2:依照交付日期 3:依照設備 4:依照插單
  const [schedulingResults, setSchedulingResults] = useState([]); //排程結果
  const [showAbandonSchedulingModal, setShowAbandonSchedulingModal] = useState(false); //是否顯示放棄這個排程？Modal
  const [schedulingKPIs, setschedulingKPIs] = useState([]); //排程結果  

  useEffect(() => {
    if (showModal) {
      loadSchedulePreview();
    }
    return () => {
      setschedulingKPIs([]);
    }
  }, [showModal, algorithm]);

  //#region 切換演算法
  const changeAlgorithm = (type) => {
    setAlgorithm(type);
  }
  //#endregion

  const handleSchedulingKPIs = (reponseData) => {
    let KPIResult = {
      SchedulingMethod: algorithm,
      OKRate: reponseData.data.OKRate,
      totalDelays : reponseData.data.totalDelays
    }

    let tmpKPIs = [...schedulingKPIs];
    // check if schedulingKPIs has SchedulingMethod than update it, else push new data
    let index = tmpKPIs.findIndex(d => d.SchedulingMethod === algorithm);

    if(index > -1){
      tmpKPIs[index] = KPIResult;
    }
    else{
      tmpKPIs.push(KPIResult);
    }

    setschedulingKPIs(tmpKPIs);
  }

  //#region 載入預覽排程
  const loadSchedulePreview = async () => {
    const [httpStatus, reponseData] = await handleApiSchedulePreview();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        setSchedulingResults(reponseData.data.orderList);
        handleSchedulingKPIs(reponseData);
      }
      else {
        setSchedulingResults([]);
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 預覽排程API
  const handleApiSchedulePreview = async () => {
    let postJson = {
      schedulingMethod: algorithm,
      orderID: chooseOrderID
    };

    let response = await apiSchedulePreview(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 套用排程
  const loadScheduleConfirm = async () => {
    const [httpStatus, reponseData] = await handleApiScheduleConfirm();
    if (httpStatus == "200") {
      handleClose();
      if (reponseData.statusCode === "20000") {
        loadOrderList();
        loadOrderCountAndStatus();
        toast(t("appliedSuccessfully"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else {
        toast.error(t("appliedFailed"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 套用排程API
  const handleApiScheduleConfirm = async () => {
    let postJson = {
      schedulingMethod: algorithm
    };

    let response = await apiScheduleConfirm(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 取消排程按鈕
  const cancelSchedule = () => {
    setShowAbandonSchedulingModal(true);
    document.querySelector("#autoScheduling").classList.add('d-none');
  }
  //#endregion

  //#region 前往指定排程
  const jumpManual = () => {
    handleClose();
    setManualSchedulingModal(true);
  }
  //#endregion 

  const judgeOKRate = (OKRate) => {
    let value = "";

    if(OKRate >= ratingCriteria?.onTimeDeliveryRate?.great){
      value = "Great";
    }
    else if(OKRate >= ratingCriteria?.onTimeDeliveryRate?.good){
      value = "Good";
    }
    else if(OKRate >= ratingCriteria?.onTimeDeliveryRate?.fair){
      value = "Fair";
    }
    else{
      value = "Poor";
    }
  
    return value;
  }

  const handleShowSchedulingKPI = (SchedulingMethod) => {
    let checkKPIResult = schedulingKPIs.find(d => d.SchedulingMethod === SchedulingMethod);

    if(checkKPIResult !== undefined) {
      let OKRate = checkKPIResult.OKRate;
      let totalDelays = checkKPIResult.totalDelays;

      return (
        <div className="item icon bounce">
          <div className={`bubble bg-${judgeOKRate(OKRate)}`}>{OKRate}%</div>
          <div className="text">{totalDelays}{t("day")}</div>
        </div>
      )
    }
    else {
      return null;
    }
  }

  return (
    <>
      <Modal id="autoScheduling" show={showModal} onHide={handleClose} backdrop="static" size="xl" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{t("autoScheduling")}</div>
          </div>
          <button type="button" className="close"
            data-dismiss="modal" aria-label="Close" onClick={cancelSchedule}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          {/*固定在上方*/}
          <div className="stickyTop bgWhite">
            {/*排程方法tab*/}
            {/* <div className='schedulingResults'>{t("schedulingResults")}</div> */}
            <div className="filterRow">
              <div className="filling38"></div>
              <div>
                <div className='remark remark-div flex-nowrap gap025 rwd-noshow-1000'>
                  <div className="size-S icon icon-ChatBubble"></div>
                  <div className="text">{t("onTimeDeliveryRate")} ({t("TotalLateDeliveryDays")})</div>
                </div>
              </div>
            </div>
            <div className="APS-tab small-tab">
              <div className={`floatitem capitalize tab-${algorithm === 2 ? "clicked" : "unclick"}`} onClick={() => changeAlgorithm(2)}>
                {t("byDeliveryDate")}
                {/*如果沒有載數據進來的話，這整個↓<div>就都不要；有載進來後，就留著這塊<div>*/}
                {(() => { return handleShowSchedulingKPI(2); })()}
              </div>
              <div className={`floatitem capitalize tab-${algorithm === 3 ? "clicked" : "unclick"}`} onClick={() => changeAlgorithm(3)}>
                {t("byEquipment")}
                {(() => { return handleShowSchedulingKPI(3); })()}               
              </div>
              <div className={`floatitem capitalize tab-${algorithm === 4 ? "clicked" : "unclick"}`} onClick={() => changeAlgorithm(4)}>
                {t("byNewOrders")}                       
                {(() => { return handleShowSchedulingKPI(4); })()}
              </div>
            </div>
            {/*表頭*/}
            <div className="APSTable-gp">
              <div className="gpcolor-left gpcolor-flatall bg-black"></div>
              <div className="APSTable" id="WorkOrder">
                <div className="thead">
                  <div className="tr tLeft">
                    <div className="th w1"></div>
                    <div className="td f3">{t("workOrderID")}</div>
                    <div className="td f5">{t("scheduledTime")}</div>
                    <div className="td f2">{t("equipment")}</div>
                    <div className="td f2">{t("scheduledDeliveryDate")}</div>
                    <div className="td f5">{t("part")}/{t("process")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*表格內容*/}
          <div className="APSTable-gp">
            <div className="gpcolor-left gpcolor-flattop bg-black"></div>
            <div className="APSTable" id="WorkOrder">
              {/*為了將表頭固定在上方，所以將原本放在表格區塊中的表頭往外移動到上方的sticky區塊*/}
              {/* <div className="thead">
                <div className="tr tLeft">
                  <div className="th w1"></div>
                  <div className="td f3">{t("workOrderID")}</div>
                  <div className="td f5">{t("scheduledTime")}</div>
                  <div className="td f2">{t("equipment")}</div>
                  <div className="td f2">{t("scheduledDeliveryDate")}</div>
                  <div className="td f5">{t("part")}/{t("process")}</div>
                </div>
              </div> */}
              <div className="tbody">
                {
                  schedulingResults?.map((obj, index) => {
                    return (
                      <div className={`tr ${chooseOrderID.find(d => d === obj.orderID) ? "clicked" : ""}`} key={index}>
                        {
                          obj.orderStatus === 2 ?
                            <div className="th w1 dot">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip className="custom-tooltip">
                                    <span>{t("started")}</span>
                                  </Tooltip>
                                }
                              >
                                <div className="green"></div>
                              </OverlayTrigger>

                            </div> : <div className="th w1"></div>
                        }
                        <div className="td f3">{obj.orderNo}-{obj.processNo}</div>
                        <div className="td f5">
                          <div>
                            {
                              obj.startTime != null ?
                                <div>
                                  <b>{convertDateFormat(obj.startTime, "MMM DD")}</b>
                                  <span>{convertDateFormat(obj.startTime, "hh:mm:ss A")}</span>
                                  <span> - </span>
                                  {
                                    convertDateFormat(obj.startTime, "MMM DD") != convertDateFormat(obj.endTime, "MMM DD") ?
                                      <b>{convertDateFormat(obj.endTime, "MMM DD")}</b> :
                                      null
                                  }
                                  <span>{convertDateFormat(obj.endTime, "hh:mm:ss A")}</span>
                                </div> : "-"
                            }
                          </div>
                        </div>
                        <div className="td f2">{obj.mShortName}</div>
                        <div className="td f2 inline">
                          <span className={`bold ${obj.deliveryDelay ? "text-red" : ""}`}>
                            {convertDateFormat(obj.scheduledDeliveryDate, "MMM DD")}
                            {convertDateFormat(obj.scheduledDeliveryDate, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.scheduledDeliveryDate, "YYYY")}</> : null}
                          </span>
                          {
                            obj.deliveryDelay ?
                              <span className='schedulingDelayedTag'>{t("delayed")}</span> : null
                          }
                        </div>
                        <div className="td f5">
                          <div>
                            <b>{obj.wpNO}</b>
                            <span>{obj.wpName}</span>
                            <span>/</span>
                            <b>{obj.processNo}</b>
                            <span>{obj.processName}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="spaceHeight1"></div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <div>
              <button className="button btn-txt-black" onClick={cancelSchedule}>
                {t("cancel")}
              </button>
              {
                isChangeSchedule ?
                  <button className="button btn-lv2" onClick={jumpManual}>
                    {t("goManualScheduling")}
                  </button> : null
              }
            </div>
            <button className="button btn-lv1" onClick={loadScheduleConfirm}>
              {t("applyAlgorithm", { Algorithm: algorithm === 2 ? t("byDeliveryDate") : algorithm === 3 ? t("byEquipment") : t("byNewOrders") })}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

      {/* 放棄這個排程？ */}
      <MessageModal
        title={t("abandonScheduling")}
        content={`${t("abandonSchedulingContent1")}<br /><br />${t("abandonSchedulingContent2")}`}
        showModal={showAbandonSchedulingModal}
        handleConfirmData={() => { handleClose(); setShowAbandonSchedulingModal(false); loadOrderList(true); }}
        size="xs"
        confirmModalTxt={t("abandon")}
        cancelTxt={t("cancel")}
        cancelModal={() => { document.querySelector("#autoScheduling").classList.remove('d-none'); setShowAbandonSchedulingModal(false); loadOrderList(false); }}
        handleClose={() => { document.querySelector("#autoScheduling").classList.remove('d-none'); setShowAbandonSchedulingModal(false); loadOrderList(false); }}
      />
    </>
  );
};

export default AutoScheduling;