import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';

import DateTimeRangePicker from 'components/dateTimeRangePicker/DateTimeRangePicker';

import { checkIfOver7Days, calculateDuration, formatTimeDays } from 'utils/commonFun';

import { apiMachineListData, apiIdeaLogin } from 'utils/Api';

const CapturePeriodEquipment = ({showModal, handleClose, choiceMachine, setChoiceMachine, 
  choiceDate, setChoiceDate, currentStep, setCurrentStep, handleNextStep}) => {
  const { t, i18n } = useTranslation("powerCarbon");
  const { overtime, authority, lang } = useContext(MyUserContext);

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  const [machineList, setMachineList] = useState(null); //機台列表
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    if(showModal){
      setJudgeCheckErr(false);
      setDateError(false);
      setCurrentStep(1);

      if(currentStep === 0){
        setChoiceDate([]);
      }
    }
  }, [showModal]);

  useEffect(() => {
    if(authority?.userAccount != null){
        const fetchData = async () => {
          const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
          if(httpStatusMachine == "200"){
              if(reponseDataMachine.statusCode === "20000"){
                let artMachine = reponseDataMachine.data;
                let newMachineList = [];
                const [httpStatus, reponseData] = await handleApiIdeaLogin();
                if(httpStatus === 200){
                  let ideaMachine = reponseData?.artUserInfo?.owingMachines;
                  for(let item of ideaMachine){
                    let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                    if(filter){
                      newMachineList.push(filter);
                    }
                  }
                }
                else{
                  newMachineList = artMachine;
                }
                
                setMachineList(newMachineList);

                if (localStorage.getItem("choiceMachine") !== null){
                  setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
                }
                else{
                  if(newMachineList.length > 0){
                    localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                    setChoiceMachine(newMachineList[0]);
                  }
                  else{
                    localStorage.removeItem("choiceMachine");
                    setChoiceMachine(null);
                  }
                }
              }
              else if(reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104"){
                overtime();
              }
          }
      };
      fetchData();
    }
  }, [authority]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if(machineListResponse){
        const httpStatus = machineListResponse.request.status;
        const reponseData = machineListResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');
    
    let ideaLoginResponse = await apiIdeaLogin(formData);
    
    if(ideaLoginResponse){
        const httpStatus = ideaLoginResponse.request.status;
        const reponseData = ideaLoginResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 機台下拉選單
  const handleEquipmentUsed = (e) => {
    const { value } = e.target;
    let filter = machineList.find(d => d.mid === parseInt(value));
    if(filter){
      setChoiceMachine(filter);
      localStorage.setItem("choiceMachine", JSON.stringify(filter));
    }
  };
  //#endregion

  useEffect(() => {
    if(judgeCheckErr){
      if(choiceDate.length > 0){
        setDateError(checkIfOver7Days(choiceDate));
      }
      else{
        setDateError(true);
      }
    }
  }, [choiceDate, judgeCheckErr]);

  //#region 下一步按鈕
  const handleNext = () => {
    setJudgeCheckErr(true);

    if(choiceDate.length > 0 && checkIfOver7Days(choiceDate) === false){
      handleNextStep();
    }
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={()=>handleClose(0)} size="md" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("capturePeriod")}<br />
            <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className='mt-2'>
              <Col xs={12}>
                <Form.Group controlId="equipmentUsed">
                  <Form.Label className='form-label-custom'>
                    {t("equipmentUsed")}
                    <span className='text-danger ms-1'>*</span>
                  </Form.Label><br />
                  <Form.Select as="select" 
                    onChange={handleEquipmentUsed}
                    value={choiceMachine?.mid || ''} 
                    className={`equipmentUsed`}
                    >
                      {
                        machineList?.map((obj, index) => {
                          return <option key={index} value={obj.mid}>{obj.mName}</option>;
                        })
                      }
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={12}>
                <Form.Group controlId="selectPeriod">
                  <Form.Label className='form-label-custom'>
                    {t("selectPeriod")}
                    <span className='text-danger ms-1'>*</span>
                  </Form.Label><br />
                  <div className={`input ${dateError ? "input-error" : ""}`}>
                    <DateTimeRangePicker dateValue={choiceDate} setDateValue={setChoiceDate} lang={lang} />
                  </div>
                  {
                    judgeCheckErr && dateError && choiceDate.length === 0 ?
                      <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                      <></>
                  }

                  {
                    judgeCheckErr && dateError && choiceDate.length > 0  ?
                      <Form.Label className='text-danger mb-0'>{t("exceedOver7Days")}</Form.Label> :
                      <></>
                  }
                </Form.Group>
              </Col>
            </Row>
            {
              choiceDate.length === 2 ?
                <Row className='mt-3'>
                  <Col>
                      <span className='text-blue me-2'>{t("duration2")}</span>
                      <span className='durationDay'>{formatTimeDays(calculateDuration(choiceDate[0], choiceDate[1]), t)}</span>
                  </Col>
                </Row> : <></>
            }
          </Form>
        </Modal.Body>
        <div className="modal-footer-custom">
        <div className="btn-gp">
            <div>
              <button className="btn btn-txt-black" onClick={()=>handleClose(0)}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button className="btn btn-lv1 fat" onClick={handleNext}>
                {t("next")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} >
              <button className="button-not-border" onClick={()=>handleClose(0)}>
                {t("cancel")}
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <button className="button-primary" onClick={handleNext}>
                {t("next")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
}

export default CapturePeriodEquipment;