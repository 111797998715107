import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MyUserContext } from 'contexts/MyUserContext';
import MyPagination from 'components/basic/Pagination';
import { apiPowerWorkpieceList, apiPowerProgramList, apiMachineListData, apiIdeaLogin } from 'utils/Api';
import moment from 'moment';
import { formatTime, weekCalc } from 'utils/commonFun';

import DatePicker from 'components/datePicker/DatePicker';
import { parseDate } from '@internationalized/date';

import { encrypt } from 'utils/crypt';
import Tab from 'components/basic/tab';

import DateRangePicker from 'components/dateRangePicker/DateRangePicker';

const PowerCarbon = () => {
  const { t } = useTranslation("powerCarbon");
  const navigate = useNavigate();

  const { overtime, authority, lang } = useContext(MyUserContext);

  const [choicePage, setChoicePage] = useState(1);  //1:Workpieces 2:Programs
  const [searchBtn, setSearchBtn] = useState(0); //
  const [keyword, setKeyword] = useState(""); //上方使用者輸入關鍵字

  const [machineList, setMachineList] = useState(null); //機台列表
  const [choiceMachine, setChoiceMachine] = useState(""); //選擇機器
  // let [selectedDates, setSelectedDates] = useState({
  //   start: null,
  //   end: null
  // }); //查詢區間
  let [dateValue, setDateValue] = useState([]); //查詢區間
  const [calMethod, setCalMethod] = useState(1);  //0:只計算完整執行 1:計算全部(目前用不到)

  const [workpieceList, setWorkpieceList] = useState({});
  const [programList, setProgramList] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  //#region 初始載入
  useEffect(() => {
    if (localStorage.getItem("power_startDate") !== null && localStorage.getItem("power_endDate") !== null) {
      setDateValue([localStorage.getItem("power_startDate"), localStorage.getItem("power_endDate")]);
    }
    else {
      //預載本週
      const calDate = moment().subtract(1, 'days'); //預先載入是昨天
      const [sundayThisWeek, saterdayThisWeek, isoWeekThisWeek] = weekCalc(calDate, 0);

      setDateValue([sundayThisWeek.format('yyyy-MM-DD'), saterdayThisWeek.format('yyyy-MM-DD')]);
    }

    if (localStorage.getItem("power_keyword") !== null) {
      setKeyword(localStorage.getItem("power_keyword"));
    }

    if (localStorage.getItem("workpieces_choicePage") !== null) {
      const workpieces_choicePage = localStorage.getItem("workpieces_choicePage");
      setChoicePage(parseInt(workpieces_choicePage));
    }
  }, []);
  //#endregion

  useEffect(() => {
    if (choicePage === 1) {
      loadPowerWorkpieceList();
    }
    else {
      loadPowerProgramList();
    }
  }, [choiceMachine, dateValue, choicePage, currentPage]);

  //#region 切換左上角功能頁籤
  const changeChoicePage = (page) => {
    setChoicePage(page);
    localStorage.setItem("workpieces_choicePage", page);
  }
  //#endregion

  //#region Input欄位關鍵字改變值
  const handleInputKeywordChange = (e) => {
    const { value } = e.target;

    // replace special characters like /t and space for value
    let keyword = value.replace(/\s/g, "");
    setKeyword(keyword);
    localStorage.setItem("power_keyword", keyword);
  };
  //#endregion

  useEffect(() => {
    if (authority?.userAccount != null) {
      const fetchData = async () => {
        const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
        if (httpStatusMachine == "200") {
          if (reponseDataMachine.statusCode === "20000") {
            let artMachine = reponseDataMachine.data;
            let newMachineList = [];
            const [httpStatus, reponseData] = await handleApiIdeaLogin();
            if (httpStatus === 200) {
              let ideaMachine = reponseData?.artUserInfo?.owingMachines;
              for (let item of ideaMachine) {
                let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                if (filter) {
                  newMachineList.push(filter);
                }
              }
            }
            else {
              newMachineList = artMachine;
            }

            setMachineList(newMachineList);

            if (localStorage.getItem("choiceMachine") !== null) {
              setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
            }
            else {
              if (newMachineList.length > 0) {
                localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                setChoiceMachine(newMachineList[0]);
              }
              else {
                localStorage.removeItem("choiceMachine");
                setChoiceMachine(null);
              }
            }
          }
          else if (reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104") {
            overtime();
          }
        }
      };
      fetchData();
    }
  }, [authority]);

  useEffect(() => {
    if (dateValue.length > 0) {
      localStorage.setItem("power_startDate", dateValue[0]);
      localStorage.setItem("power_endDate", dateValue[1]);
    }
    else if (localStorage.getItem("power_startDate") !== null && localStorage.getItem("power_endDate") !== null) {

    }
    else {
      localStorage.removeItem("power_startDate");
      localStorage.removeItem("power_endDate");
    }
  }, [dateValue]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if (machineListResponse) {
      const httpStatus = machineListResponse.request.status;
      const reponseData = machineListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');

    let ideaLoginResponse = await apiIdeaLogin(formData);

    if (ideaLoginResponse) {
      const httpStatus = ideaLoginResponse.request.status;
      const reponseData = ideaLoginResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 機台下拉選單
  const handleDropdownSelect = (obj) => {
    setChoiceMachine(obj);
    localStorage.setItem("choiceMachine", JSON.stringify(obj));
  };
  //#endregion

  //#region 工件用電列表
  const loadPowerWorkpieceList = async () => {
    if (choiceMachine != null && dateValue.length > 0 && choiceMachine?.mid != undefined) {
      const [httpStatus, reponseData] = await handleApiPowerWorkpieceList();
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          setWorkpieceList(reponseData.data);
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 工件用電列表API
  const handleApiPowerWorkpieceList = async () => {
    let postData = {
      startDate: dateValue[0],
      endDate: dateValue[1],
      midList: [parseInt(choiceMachine?.mid)],
      calcMethod: calMethod,
      keyword: keyword
    };

    let response = await apiPowerWorkpieceList(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 加工程式用電列表
  const loadPowerProgramList = async () => {
    if (choiceMachine != null && dateValue.length > 0 && choiceMachine?.mid != undefined) {
      const [httpStatus, reponseData] = await handleApiPowerProgramList();
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          setProgramList(reponseData.data);
        }
      }
      else {
        toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 加工程式用電列表API
  const handleApiPowerProgramList = async () => {
    let postData = {
      startDate: dateValue[0],
      endDate: dateValue[1],
      midList: [parseInt(choiceMachine?.mid)],
      calcMethod: calMethod,
      keyword: keyword,
      doExcludeNoExecution: 1,
      intLimit: 30,
      intOffset: (currentPage - 1) * 30
    };

    let response = await apiPowerProgramList(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region選擇明細
  const choiceDetail = (type, index) => {
    if (type === "workpiece") {
      navigate(`/Power/PartsPrograms/${encrypt(workpieceList.detail[index].wpID)}/${encrypt(0)}`);
    }
    else {
      navigate(`/Power/PartsPrograms/${programList.detail[index].wpID === null ? encrypt(0) : encrypt(programList.detail[index].wpID)}/${encrypt(programList.detail[index].programID)}`);
    }
  }
  //#endregion

  //#region 搜尋按鈕
  const handleSearch = () => {
    if (choicePage === 1) {
      loadPowerWorkpieceList();
    }
    else {
      loadPowerProgramList();
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //#endregion

  return (
    <>
      <div className="pageContent">
        <div className="filterRow">
          <div>
            <div className='btnSwitch-icontext blue'>
              <div type="button" className={`${choicePage === 1 ? "selected" : ""}`} onClick={() => changeChoicePage(1)}>
                <div className="icon icon-Grid"></div>
                <div className="text">
                  {
                    choicePage === 1 ?
                      t("workpieces") : ""
                  }
                </div>
              </div>
              <div type="button" className={`${choicePage === 2 ? "selected" : ""}`} onClick={() => changeChoicePage(2)}>
                <div className="icon icon-List"></div>
                <div className="text">
                  {
                    choicePage === 2 ?
                      t("programs") : ""
                  }
                </div>
              </div>
            </div>
            <div>
              <div className="btn-search">
                <input type="search" placeholder={t("search")} aria-label="Search"
                  value={keyword} onChange={handleInputKeywordChange} onKeyDown={(event) => {
                    if (event.key === "Enter") { handleSearch(); }
                  }} />
                <button type="button" className="btn btn-onlyicon bounce btn-lv5"
                  onClick={handleSearch}>
                  <div className="icon icon-Search"></div>
                </button>
              </div>
            </div>

            {/* <div className='row col-sm-6 col-md-7 col-xxl-9 mb-2 groupSearch'>
            <div className='d-flex'>
              <input type="search" className='search-Input' placeholder={t("search")}
                value={keyword} onChange={handleInputKeywordChange} />
              <button type="button" className="btn btn-lv1 btn-icontext ms-2"
                onClick={handleSearch}>
                <div className='d-flex justify-content-start align-items-center'>
                  <span className="material-symbols-rounded btn-SearchIcon">search</span>&nbsp;
                  {t("search")}
                </div>
              </button>
            </div>
          </div> */}
          </div>
          <div>
            <div className="input-rightpadding">
              <button className="input outline" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <b>{choiceMachine?.mName}</b>
              </button>
              <div className="icon icon-Dropdown bg-greyA small"></div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  machineList?.map((obj, index) => {
                    return <a className="dropdown-item cursor-pointer"
                      onClick={() => handleDropdownSelect(obj)}
                      key={index}>{obj.mName}</a>;
                  })
                }
              </div>
            </div>
            
            {/* <DatePicker dateValue={selectedDates} setDateValue={setSelectedDates} lang={lang} /> */}
            <DateRangePicker dateValue={dateValue} setDateValue={setDateValue} lang={lang} />
          </div>
        </div>

        {
          choicePage === 1 && workpieceList?.totalItems > 0 ?
            <main className="group-card-4pcs mt-2">
              {
                workpieceList?.detail?.map((obj, index) => {
                  return (
                    <div className="card-style1" key={index}>
                      <div>
                        <div className='cursor-pointer' onClick={() => choiceDetail("workpiece", index)}>
                          <img src={obj?.wpImageList} />
                        </div>
                      </div>
                      <div className='cursor-pointer' onClick={() => choiceDetail("workpiece", index)}>
                        <div>{obj.wpNO}</div>
                        <div>{obj.wpName}</div>
                      </div>
                      <div className="box-style1">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className='custom-tooltip'>
                              {t("averageProgramExecutionDuration")}
                            </Tooltip>
                          }
                        >
                          <div className="box-color-green ml-0">
                            <div className="material-symbols-rounded icon16">schedule</div>
                            <div>{formatTime(obj.averageProgramExecutionDuration)}</div>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className='custom-tooltip'>
                              {t("averagePowerConsumption")}
                            </Tooltip>
                          }
                        >
                          <div className="box-color-yellow">
                            <div className="material-symbols-rounded icon16">bolt</div>
                            <div>{parseFloat(obj.averagePowerConsumption).toFixed(2)}</div>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className='custom-tooltip'>
                              {t("averageCarbonEmissions")}
                            </Tooltip>
                          }
                        >
                          <div className="box-color-lakeblue">
                            <div className="material-symbols-rounded icon16">cloud</div>
                            <div>{parseFloat(obj.averageCarbonEmissions).toFixed(2)}</div>
                          </div>
                        </OverlayTrigger>

                      </div>
                      <div className="box-style2">
                        <div>
                          <div>
                            <div className="box-color-purpleblue">
                              <div className="material-symbols-rounded icon12">dns</div>
                            </div>
                            <div>{t("numberMachiningPrograms")}</div>
                          </div>
                          <div>{obj.numberMachingPrograms}</div>
                        </div>
                        <div>
                          <div>
                            <div className="box-color-purpleblue">
                              <div className="material-symbols-rounded icon12">play_circle</div>
                            </div>
                            <div>{t("totalExecutionCount")}</div>
                          </div>
                          <div>{obj.totalExecutionCount}</div>
                        </div>
                        {/* <div> 
                            <div>
                                <div className="box-color-purpleblue">
                                    <div className="material-symbols-rounded icon12">stop_circle</div>
                                </div>
                                <div>{t("totalIncompleteExecutionCount")}</div>
                            </div>
                            <div>{obj.totalIncompleteExecutionCount}</div>
                        </div> */}
                      </div>
                    </div>
                  )
                })
              }


              {/* <!--2-6 結尾區塊--> */}
              <div className="card-style1-end">
                <div>
                  <div>{t("searchMachiningPrograms")}</div>
                  <button className="btn btn-lv3 btn-icontext" onClick={() => { setChoicePage(2); }}>
                    <div className="icon icon-List"></div>
                    <div className="text">{t("programs")}</div>
                  </button>
                </div>
              </div>
            </main> :
            // choicePage === 1 ? <div className='noDataDiv'><span className='noData'>{t("noWorkpieceFound")}</span></div> : null
            choicePage === 1 ?
              <main className="group-card-4pcs mt-2">
                <div className="nodata">
                  <div className="notice">{t("noWorkpieceFound")}</div>
                  <div className="remark">
                    <button className="btn btn-lv3 btn-icontext h38" onClick={() => { setChoicePage(2); }}>
                      <div className="icon icon-List"></div>
                      <div className="text">{t("programs")}</div>
                    </button>
                    <div>
                      {t("noWorkpieceFoundDetail1")}<br />
                      {t("noWorkpieceFoundDetail2")}
                    </div>
                  </div>
                </div>
              </main> : null
        }

        {
          choicePage === 2 ?
            <>
              <div className="table-wrapper border rwd firstColImg text-keep-all">
                <div className="table-minheight2">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("machiningProgram")}</th>
                        <th>{t("workpiece")}</th>
                        <th className="tCenter">{t("executionCount")}</th>
                        {/* <th>{t("incompleteExecutionCount")}</th> */}
                        <th className="tCenter">{t("averageErrorCount")}</th>
                        <th className="tCenter">{t("averageExecutionDuration")}</th>
                        <th className="tRight">{t("averagePowerConsumption")}</th>
                        <th className="tRight">{t("averageCarbonEmissions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        programList?.totalItems === 0 ?
                          <tr>
                            <td colSpan="9">
                              <div className='noDataDiv'><span className='noData'>{t("noProgramFound")}</span></div>
                            </td>
                          </tr>
                          : ""
                      }
                      {
                        programList?.detail?.map((obj, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className='imgBlock' onClick={() => choiceDetail("program", index)}>
                                  {
                                    obj.wpImage ?
                                      <img src={obj.wpImage} style={{ width: 100, height: 78 }} /> : <></>
                                  }
                                  {/* <ScaleImageWorkpiece src={obj.wpImage} maxWidth="100" maxHeight="100" /> */}
                                </div>
                              </td>
                              <td data-label={t("machiningProgram")} className='cursor-pointer text break-all' onClick={() => choiceDetail("program", index)}>{obj.programName}</td>
                              <td data-label={t("workpiece")}><span className='text-grey8 text12'>{obj.wpNO}</span><br /><b>{obj.wpName}</b></td>
                              <td data-label={t("executionCount")}  className="tCenter">{obj.totalExecutionCount}</td>
                              {/* <td data-label={t("incompleteExecutionCount")}>{obj.totalIncompleteExecutionCount}</td> */}
                              <td data-label={t("averageErrorCount")}  className="tCenter">{parseFloat(obj.averageErrorCount).toFixed(1)}</td>
                              <td data-label={t("averageExecutionDuration")}  className="tCenter">{formatTime(obj.averageProgramExecutionDuration)}</td>
                              <td data-label={t("averagePowerConsumption")}  className="tRight">{parseFloat(obj.averagePowerConsumption).toFixed(2)}</td>
                              <td data-label={t("averageCarbonEmissions")} className="tRight">{parseFloat(obj.averageCarbonEmissions).toFixed(2)}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <MyPagination totalPages={Math.ceil(programList?.totalItems / 30)} currentPage={currentPage}
                  onPageChange={handlePageChange} />
              </div>

            </>
            : ""
        }
      </div >

      <ToastContainer />
    </>
  );
};

export default PowerCarbon;