import React from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import '../../index.css';
import '../../Modal.css';

const MessageModal = ({ showModal, handleClose, title, content = "", size = "sm", cancelModal, cancelTxt = "", confirmModalTxt, handleConfirmData }) => {
  const { t } = useTranslation("common");

  //之後要補寫確定刪除事件
  // const handleConfirm = () => {
  //   handleConfirmData();
  // }

    // 2025-01-21 手刻Modal，取代原本Boostrap的Modal
  return (
    <Modal show={showModal} onHide={handleClose} size={size} backdrop="static" keyboard={false} centered>
      <div className="modal-header">
            <div>
                <div className="modal-title">{title}</div>
            </div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
              onClick={handleClose}>
                <div className="icon-Cross"></div>
            </button>
          </div>
          <div className="Modal-body">
            <div className="text">
              <br />
              {parse(content)}</div>
          </div>
          <div className="modal-footer">
            <div className="btn-gp">
              <div>
              {
                cancelModal ?
                <button type="button" className="btn btn-txt-black" onClick={cancelModal}>
                  {t("cancel")}
                </button> : null
              }
            </div>
            <div>
              {
                cancelModal ?
                <button type="button" className="btn btn-lv1 fat" onClick={handleConfirmData}>
                  {confirmModalTxt}
                </button>:
                <button type="button" className="btn btn-lv1 fat"  onClick={handleClose}>
                  {confirmModalTxt}
                </button>
              }
            </div>
            </div>
          </div>
    </Modal>
  );

  // 2025-01-21 原本Boostrap的Modal，先註解掉的原本的
  // return (
  //   <Modal show={showModal} onHide={handleClose} size={size} backdrop="static" keyboard={false} centered>
  //     <Modal.Header closeButton>
  //       <Modal.Title>{title}</Modal.Title>
  //     </Modal.Header>
  //     <Modal.Body>
  //       {parse(content)}
  //       <div className="modal-footer-custom">
  //         <div className="btn-gp">
  //           <div>
  //             {
  //               cancelModal ?
  //               <button type="button" className="btn btn-txt-black" onClick={cancelModal}>
  //                 {t("cancel")}
  //               </button> : null
  //             }
  //           </div>
  //           <div>
  //             {
  //               cancelModal ?
  //               <button type="button" className="btn btn-lv1 fat" onClick={handleConfirmData}>
  //                 {confirmModalTxt}
  //               </button>:
  //               <button type="button" className="btn btn-lv1 fat"  onClick={handleClose}>
  //                 {confirmModalTxt}
  //               </button>
  //             }
  //           </div>
  //         </div>
  //       </div>
  //     </Modal.Body>
  //   </Modal>
  // );
};

export default MessageModal;