import React, {useState, useEffect, useContext, useRef} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import MyPagination from 'components/basic/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';
import { apiRawDataSet } from 'utils/Api';
import { formatTime, formatDateTimeRange } from 'utils/commonFun';

const EquipmentRuntimeEachShift = (props) => {
  const {tableResult, showData, currentPage, setCurrentPage, setFieldInstructionsJSON} = props;

  const { t } = useTranslation("equipmentRuntimeEachShift");
  const { lang, showFormatDate, showFormatTime } = useContext(MyUserContext);
  const [tableHeader, setTableHeader] = useState(null); //Header名稱
  const scrollRef = useRef(null);
  
  useEffect(() => {
    loadRawDataSet();
  }, [lang]);

  //#region 載入原始數據表頭設定檔
  const loadRawDataSet = async () => {
    const [httpStatus, response] = await handleApiRawDataSet("dailyShiftStatusDuration");
    if(httpStatus == "200"){
      if(response.statusCode === "20000"){
        setTableHeader(response.data);

        if(response.data.length > 0){
          let json = [];
          response.data.forEach(item => {
            if(item.key === "showDate"){
              json.push({ colName: item.value, colDescribe: [t("showDate1")]});
            }
            else if(item.key === "shiftName"){
              json.push({ colName: item.value, colDescribe: [t("shiftName1")]});
            }
            else if(item.key === "status"){
              json.push({ colName: item.value, colDescribe: [t("status1"), t("status2"), t("status3"), t("status4"), t("status5")]});
            }
            else if(item.key === "datetimeStart"){
              json.push({ colName: item.value, colDescribe: [t("datetimeStart1")]});
            }
            else if(item.key === "datetimeEnd"){
              json.push({ colName: item.value, colDescribe: [t("datetimeEnd1")]});
            }
            else if(item.key === "seconds"){
              json.push({ colName: item.value, colDescribe: [t("seconds1")]});
            }
            else if(item.key === "machineSn"){
              json.push({ colName: item.value, colDescribe: [t("machineSn1")]});
            }
          });
          setFieldInstructionsJSON(json);
        }
      }
    }
    else{
      toast.error(`HTTP Status Code:${httpStatus}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
      });
    }
  }
  //#endregion

  //#region 原始數據表頭設定檔API
  const handleApiRawDataSet = async (type) => {
    let response = await apiRawDataSet(type);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  return (
    <>
      <div>
        {(() => {
          if(tableResult === "1"){
            return <div className='noDataDiv'><span className='noData'>{t("noDataSelected")}</span></div>;
          }
          else if(tableResult === "2"){
            return <div className='noDataDiv'><span className='noData'>{t("invalidQueryConditions")}</span></div>;
          }
          else{
            return (
              <>
                <div className="table-wrapper border">
                    <div className="table-minheight1" ref={scrollRef}>
                        <table>
                            <thead>
                                <tr>
                                  {
                                    tableHeader?.map((obj, index) => (
                                      <th key={index}>
                                        <span>{obj.value}</span>
                                      </th>
                                    ))
                                  }
                                </tr>
                            </thead>
                            <tbody>
                              {
                                showData?.detail?.map((obj, index) => (
                                  <tr key={index}> 
                                    <td>
                                      {(() => {
                                        const trans = formatDateTimeRange(showFormatDate, null, obj.showDate);
                                        return trans.startDate;
                                      })()}
                                    </td> 
                                    <td>{obj.shiftName}</td> 
                                    <td>{obj.status}</td>
                                    <td>
                                      {(() => {
                                        const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.datetimeStart);
                                        return trans.startDate + " " + trans.startTime;
                                      })()}
                                    </td> 
                                    <td>
                                      {(() => {
                                        const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.datetimeEnd);
                                        return trans.startDate + " " + trans.startTime;
                                      })()}
                                    </td> 
                                    <td>{formatTime(obj.seconds)}</td>
                                    <td>{obj.machineSn}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <MyPagination totalPages={Math.ceil(showData?.totalItems / 30)} currentPage={currentPage} 
                    onPageChange={handlePageChange} />
                </div>
              </>
            )
          }
        })()}
        
      </div>

      <ToastContainer />
    </>
  );
};

export default EquipmentRuntimeEachShift;