import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Container, Row, Col  } from 'react-bootstrap';
import { MyUserContext } from 'contexts/MyUserContext';

const AppsMain = () => {
  const { t } = useTranslation("common");
  const { checkLogin, permission } = useContext(MyUserContext);
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = ["Calculator", "APS"];

    if(permission !== null){
      let filterMainTab = permission.find(d => d.tabName === "Apps");
      if(filterMainTab){
        newTabList = filterMainTab.tabList;
      }
      else{
        newTabList = defaultTabs;
      }
    }
    else{
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  const handlePage = (itemName) => {
    if(itemName == "workpiece"){
        window.open('/WorkPiece','_blank'); //暫時另開分頁
    }
    else if(itemName == "aps"){
      window.open('/APS/SchedulingChart','_blank'); //暫時另開分頁
    }
    else if(itemName == "utilizationRate"){
        window.open('/UtilizationRate','_blank'); //暫時另開分頁
    }
    else if(itemName == "set"){
        window.open('/Set','_blank');
    }
  }

  return (
    <Container fluid className='p-4 sidebarContent'>
      <h3><b>{t("apps")}</b></h3>
      <Row>
      {
        tabList?.find(d => d === "Calculator") ?
          <Col sm={12} xs={12} md={6} lg={4} xl={4} xxl={2} className='mb-4' onClick={(e) => handlePage("workpiece")}>
            <CardItem itemName="workpiece" />
          </Col> : null
      }
      {
        tabList?.find(d => d === "APS") ?
        <Col sm={12} xs={12} md={6} lg={4} xl={4} xxl={2} className='mb-4' onClick={(e) => handlePage("aps")}>
          <CardItem itemName="aps" />
        </Col> : null
      }
      </Row>
    </Container>
  );
};

//#region 卡片細項
const CardItem = ({itemName}) => {
    const { t } = useTranslation("common");
    const [bgColor, setBgColor] = useState("");
    const [iconName, setIconName] = useState("");
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");

    //#region 初始載入
    useEffect(() => {
      if(itemName == "workpiece"){
        setBgColor("bg-lightblue1");
        // setIconName("calculate");
        setIconName("icon-Calculator");
        setTitle("menu_workpiece");
        setSubTitle("menu_workpiece_description");
      }
      else if(itemName == "aps"){
        setBgColor("bg-lightblue1");
        // setIconName("event_note");
        setIconName("icon-Calendar");
        setTitle("menu_aps");
        setSubTitle("menu_aps_description");
      }
    }, []);
    //#endregion

    return(
        <div className='card-menu-div h-100'>
            <div className={`card-menu ${bgColor}`}>
                {/* <span className="material-symbols-rounded-tools"> {iconName} </span> */}
                <div className={`icon ${iconName}`}></div>
            </div>
            <div className='title'><b>{t(title)}</b></div>
            <div className='text'>{t(subTitle)}</div>
        </div>
    )
}
//#endregion

export default AppsMain;