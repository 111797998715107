import React from 'react';
import GanttChart from '../workOrderScheduling/GanttChart'; //甘特圖

const SchedulingChart = () => {

    return (
        <div className="page">
            <div className="page-content round">
                <GanttChart />
            </div>
        </div>
    );
};

export default SchedulingChart;
