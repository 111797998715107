import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import MyPagination from 'components/basic/Pagination';
import { isBeforeLastMonthFirstDay, convertDateFormat, secondsToHours, 
  isTodayAfter, isNumber, isFormatHours, formatDateTimeRange } from 'utils/commonFun';
import { apiRawDataSet } from 'utils/Api';
import { MyUserContext } from 'contexts/MyUserContext';
import { ToastContainer, toast } from 'react-toastify';

const EquipmentRuntime = (props) => {
  const { tableResult, behavior, showData, setShowData, currentPage, setCurrentPage,
    machineOperatingCalHours, equipmentRuntimeUpdateData, setEquipmentRuntimeUpdateData, choiceMachine,
    setShowLeavePageModal, setEquipmentRuntimeTempPage, setFieldInstructionsJSON } = props;

  const { t } = useTranslation("equipmentRuntime");
  const { lang, judgeLoadToday, showFormatDate } = useContext(MyUserContext);
  const [tableHeader, setTableHeader] = useState(null); //Header名稱
  const scrollRef = useRef(null);

  useEffect(() => {
    loadRawDataSet();
  }, [lang]);

  //#region 載入原始數據表頭設定檔
  const loadRawDataSet = async () => {
    const [httpStatus, response] = await handleApiRawDataSet("equipmentRuntime");
    if (httpStatus == "200") {
      if (response.statusCode === "20000") {
        setTableHeader(response.data);

        if (response.data.length > 0) {
          let json = [];
          response.data.forEach(item => {
            if (item.key === "showDate") {
              json.push({ colName: item.value, colDescribe: [t("showDate")] });
            }
            else if (item.key === "plannedLoadingTime") {
              json.push({ colName: item.value, colDescribe: [t("plannedLoadingTime1"), t("plannedLoadingTime2")] });
            }
            else if (item.key === "actualRunningHours") {
              json.push({ colName: item.value, colDescribe: [t("actualRunningHours1"), t("actualRunningHours2")] });
            }
            else if (item.key === "actualIdlePreparingHours") {
              json.push({ colName: item.value, colDescribe: [t("actualIdlePreparingHours1"), t("actualIdlePreparingHours2")] });
            }
            else if (item.key === "actualErrorShutdownHours") {
              json.push({ colName: item.value, colDescribe: [t("actualErrorShutdownHours1"), t("actualErrorShutdownHours2")] });
            }
            else if (item.key === "actualPowerOffOfflineHours") {
              json.push({ colName: item.value, colDescribe: [t("actualPowerOffOfflineHours1"), t("actualPowerOffOfflineHours2")] });
            }
            else if (item.key === "equipmentSerialNumber") {
              json.push({ colName: item.value, colDescribe: [t("equipmentSerialNumber")] });
            }
          });
          setFieldInstructionsJSON(json);
        }
      }
    }
    else {
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 原始數據表頭設定檔API
  const handleApiRawDataSet = async (type) => {
    let response = await apiRawDataSet(type);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    //切換分頁也要儲存

    if (behavior === "save" && equipmentRuntimeUpdateData.length > 0) {
      setEquipmentRuntimeTempPage(page);
      setShowLeavePageModal(true);
    }
    else {
      setCurrentPage(page);
    }

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  //#region 切換計畫時數
  const handlePlannedHours = (e, date) => {
    let { value } = e.target;
    let newShowData = { ...showData };
    let detail = newShowData.detail;
    let filterIndex = detail.findIndex(d => d.showDate === date);
    detail[filterIndex].plannedHours = value;
    newShowData.detail = detail;
    setShowData(newShowData);
  }
  //#endregion

  //#region 確定要儲存的JSON
  const handleBlurPlannedHours = (e, date) => {
    let newShowData = { ...showData };
    let detail = newShowData.detail;
    let filterIndex = detail.findIndex(d => d.showDate === date);
    let plannedHours = detail[filterIndex].plannedHours;
    if (plannedHours === "") {
      detail[filterIndex].plannedHours = "";
    }
    else {
      //若格式正確
      if (!(behavior === "save" && plannedHours !== "" && (!isNumber(plannedHours) || (parseFloat(plannedHours) < 0 || parseFloat(plannedHours) > 24) || !isFormatHours(plannedHours)))) {
        //需要判斷數值是不是跟設定預設值不一樣，不一樣才要修改數值
        // if(parseFloat(plannedHours) !== machineOperatingCalHours){

        //需要檢查裡面是有重複日期，重複不可以再push，要更新計畫時數
        let newEquipmentRuntimeUpdateData = [...equipmentRuntimeUpdateData];
        let filter = newEquipmentRuntimeUpdateData.findIndex(d => d.showDate === detail[filterIndex].showDate);

        if (filter != -1) {
          newEquipmentRuntimeUpdateData[filter].plannedHours = parseFloat(plannedHours);
        }
        else {
          newEquipmentRuntimeUpdateData.push({
            mid: choiceMachine.mid,
            showDate: detail[filterIndex].showDate,
            plannedHours: parseFloat(plannedHours)
          });
        }

        setEquipmentRuntimeUpdateData(newEquipmentRuntimeUpdateData);
        // }
      }
    }

    newShowData.detail = detail;
    setShowData(newShowData);
  }
  //#endregion


  // useEffect(() => {
  //   console.log("tableResult", tableResult)
  //   console.log("showData", showData)
  // }, [showData]);

  return (
    <>
      <div>
        {/*上排備註*/}
        <div className='remarkDiv'>
          <div className='remarkTxt'><div>{t("remark")}</div></div>
          <div className='remarkContent'>{t("remarkContent")}</div>
        </div>
        {/*「沒有數據」提醒*/}
        {(() => {
          if (tableResult === "1") {
            return <div className='noDataDiv'><span className='noData'>{t("noDataSelected")}</span></div>;
          }
          else if (tableResult === "2") {
            return <div className='noDataDiv'><span className='noData'>{t("invalidQueryConditions")}</span></div>;
          }
          else {
            return (
              <>
                {/*表格*/}
                <div className="table-wrapper border">
                  <div className="table-minheight2" ref={scrollRef}>
                    <table>
                      <thead>
                        <tr>
                          {
                            tableHeader?.map((obj, index) => (
                              <th key={index}>
                                <span>{obj.value}</span>
                              </th>
                            ))
                          }
                          {/* <th>
                                        <span className="text-greyA">{t("date")}</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">{t("plannedLoadingTimeHours")}</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">{t("actualRunningHours")}</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">{t("actualIdlePreparingHours")}</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">{t("actualErrorShutdownHours")}</span>
                                    </th>
                                    <th>
                                        <span className="text-greyA">{t("machineSerialNumber")}</span>
                                    </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        tableResult === "1" ?
                          <tr>
                            <td colSpan="6">
                              <div className='noDataDiv'><span className='noData'>{t("noDataSelected")}</span></div>
                            </td>
                          </tr>
                          : ""
                      }
                      {
                        tableResult === "2" ?
                          <tr>
                            <td colSpan="6">
                              <div className='noDataDiv'><span className='noData'>{t("invalidQueryConditions")}</span></div>
                            </td>
                          </tr>
                          : ""
                      } */}
                        {
                          showData?.detail?.map((obj, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {/* {convertDateFormat(obj.showDate, "YYYY/MM/DD")} */}
                                  {(() => {
                                    const trans = formatDateTimeRange(showFormatDate, null, obj.showDate);
                                    return trans.startDate;
                                  })()}
                                </td>
                                <td>
                                  {
                                    behavior === "save" && !isBeforeLastMonthFirstDay(convertDateFormat(obj.showDate, "YYYY/MM/DD")) ?
                                      <input value={obj.plannedHours}
                                        onChange={(e) => handlePlannedHours(e, convertDateFormat(obj.showDate, "YYYY-MM-DD"))}
                                        onBlur={(e) => handleBlurPlannedHours(e, convertDateFormat(obj.showDate, "YYYY-MM-DD"))}
                                        className={`inputText ${behavior === "save" && obj?.plannedHours !== "" && (!isNumber(obj?.plannedHours) || (parseFloat(obj?.plannedHours) < 0 || parseFloat(obj?.plannedHours) > 24) || !isFormatHours(obj?.plannedHours)) ? "input-error" : ""}`}
                                      /> : parseFloat(obj.plannedHours).toFixed(1)
                                  }
                                  {
                                    behavior === "save" && obj?.plannedHours !== "" && !isNumber(obj?.plannedHours) ?
                                      <p className='text-danger mb-0'>{t("formatErrorOnlyNumbers")}</p> :
                                      <></>
                                  }
                                  {
                                    behavior === "save" && obj?.plannedHours !== "" && isNumber(obj?.plannedHours) &&
                                      (parseFloat(obj?.plannedHours) < 0 || parseFloat(obj?.plannedHours) > 24) ?
                                      <p className='text-danger mb-0'>{t("format24Hours")}</p> :
                                      <></>
                                  }
                                  {
                                    behavior === "save" && obj?.plannedHours !== "" && isNumber(obj?.plannedHours) &&
                                      !(parseFloat(obj?.plannedHours) < 0 || parseFloat(obj?.plannedHours) > 24) &&
                                      !isFormatHours(obj?.plannedHours) ?
                                      <p className='text-danger mb-0'>{t("formatDecimalFirst")}</p> :
                                      <></>
                                  }
                                </td>
                                {
                                  !isTodayAfter(obj.showDate) ? (
                                    obj?.statusList?.map((detail, detailIndex) => (
                                      <td key={detailIndex}>{secondsToHours(detail.seconds)}</td>
                                    ))
                                  ) : judgeLoadToday && !isTodayAfter(obj.showDate, false) ? (
                                    obj?.statusList?.map((detail, detailIndex) => (
                                      <td key={detailIndex}>{secondsToHours(detail.seconds)}</td>
                                    ))
                                  ) : (
                                    <>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </>
                                  )
                                }
                                <td>{obj.machineSn}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <MyPagination totalPages={Math.ceil((showData?.totalItems) / 30)} currentPage={currentPage}
                    onPageChange={handlePageChange} />
                </div>
              </>
            )
          }
        })()}

      </div>

      <ToastContainer />
    </>
  );
};

export default EquipmentRuntime;