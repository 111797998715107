import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import "./powerCarbonDetail.css";
import { MyUserContext } from 'contexts/MyUserContext';

import WorkpieceBreadCrumb from 'components/workpieceBreadCrumb/WorkpieceBreadCrumb';
import Tab from 'components/basic/tab';

import moment from 'moment';

import {
  apiMachineListData, apiIdeaLogin, apiSingleLoadWorkpiece, apiSingleLoadProgram,
  apiPowerProgramExecList, apiPowerWorkpieceList, apiRawDataPowerCarbon
} from 'utils/Api';
import { formatTime, decimalWithOneDecimal, weekCalc, formatDateTimeRange } from 'utils/commonFun';

import { encrypt, decrypt } from 'utils/crypt';

import DateRangePicker from 'components/dateRangePicker/DateRangePicker';

import PageErr404 from "page/PageErr404";   //頁面不存在

const WorkpiecesDeatil = () => {
  const { t } = useTranslation("powerCarbon");

  const { overtime, authority, lang, permission, showFormatDate, showFormatTime } = useContext(MyUserContext);


  const navigate = useNavigate();
  let { tabName, workpieceID, programID } = useParams();
  const [tabList, setTabList] = useState([]);  //上方Tab

  const [machineList, setMachineList] = useState(null); //機台列表
  const [choiceMachine, setChoiceMachine] = useState(""); //選擇機器
  // let [selectedDates, setSelectedDates] = useState({
  //   start: null,
  //   end: null
  // }); //查詢區間
  let [dateValue, setDateValue] = useState([]); //查詢區間
  const [calMethod, setCalMethod] = useState(1);  //0:只計算完整執行 1:計算全部(目前用不到)

  const [workpieceBasicInfo, setWorkpieceBasicInfo] = useState(null);
  const [machiningPrograms, setMachiningPrograms] = useState(null);  //加工程式明細(第二層)
  // const [calExecutionCount, setCalExecutionCount] = useState(0);  //加工程式明細(第二層)-合計執行次數
  const [executionRecords, setExecutionRecords] = useState(null);   //執行紀錄(第三層)
  const [middleInfo, setMiddleInfo] = useState(null); //中間資訊

  const [fewestErrors, setFewestErrors] = useState("");  //錯誤最少數值
  const [fastestExecution, setFastestExecution] = useState("");  //執行最快數值
  const [lowestPowerConsumption, setLowestPowerConsumption] = useState("");  //用電量最少數值
  const [mostExecutions, setMostExecutions] = useState("");  //執行最多次數值
  const [mostFrequentCompletion, setMostFrequentCompletion] = useState("");  //完整執行最多次數值

  const [slowExecution, setSlowExecution] = useState(0); //執行最久數值
  const [mostPowerConsumption, setMostPowerConsumption] = useState(0); //用電量最多數值
  const [mostCarbonEmissions, setMostCarbonEmissions] = useState(0); //碳排放量最多數值

  const [showPageErr404, setShowPageErr404] = useState(false); //是否要顯示404頁面
  const [judgeBackHome, setJudgeBackHome] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("power_startDate") !== null && localStorage.getItem("power_endDate") !== null) {
      // setSelectedDates({
      //   start: parseDate(localStorage.getItem("power_startDate")),
      //   end: parseDate(localStorage.getItem("power_endDate"))
      // });
      setDateValue([localStorage.getItem("power_startDate"), localStorage.getItem("power_endDate")]);
    }
    else {
      //預載本週
      const calDate = moment().subtract(1, 'days'); //預先載入是昨天
      const [sundayThisWeek, saterdayThisWeek, isoWeekThisWeek] = weekCalc(calDate, 0);

      // setSelectedDates({ start: parseDate(sundayThisWeek.format('yyyy-MM-DD')), end: parseDate(saterdayThisWeek.format('yyyy-MM-DD')) });
      setDateValue([sundayThisWeek.format('yyyy-MM-DD'), saterdayThisWeek.format('yyyy-MM-DD')]);
    }


    if (decrypt(workpieceID) === "" || decrypt(programID) === "") {
      setShowPageErr404(true);
      setJudgeBackHome(true);
    }
  }, [workpieceID, programID]);

  useEffect(() => {
    if (choiceMachine != null && dateValue.length > 0 && choiceMachine?.mid != undefined) {
      loadWorkpieceBasicInfo();  //上方工件基本資訊
      loadMiddleInfo(); //中間資訊
      loadTableInfo(); //下方表格
    }
    else {
      // setShowPageErr404(true);
      // setJudgeBackHome(true);
    }
  }, [choiceMachine, dateValue]);

  // useEffect(() => {
  //   console.log('Current path:', location.pathname);
  // }, [location]);

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "PartsPrograms",
        active: tabName === "PartsPrograms"
      },
      {
        tabName: "TimePeriodCapture",
        active: tabName === "TimePeriodCapture"
      }
    ];

    if (permission !== null) {
      let filterMainTab = permission.find(d => d.tabName === "Power");
      if (filterMainTab) {
        for (let item of filterMainTab.tabList) {
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if (tab) newTabList.push(tab);
        }
      }
      else {
        newTabList = defaultTabs;
      }
    }
    else {
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    let filter = tabList.find(d => d.active);
    if (filter) {
      if (filter.tabName === "PartsPrograms") {
        navigate(`/Power/${filter.tabName}/${workpieceID}/${programID}`);
      }
      else {
        navigate(`/Power/${filter.tabName}`);
      }
    }
  }, [tabList]);

  useEffect(() => {
    if (decrypt(programID) === "0") {
      if (machiningPrograms?.totalItems >= 2) {
        let currentFewestErrors = Number(machiningPrograms.detail[0].averageErrorCount.toFixed(1)); //錯誤最少
        let currentFastestExecution = Number(machiningPrograms.detail[0].averageProgramExecutionDuration); //執行最快
        let currentLowestPowerConsumption = Number(machiningPrograms.detail[0].averagePowerConsumption.toFixed(2)); //用電量最少
        let currentMostExecutions = Number(machiningPrograms.detail[0].totalExecutionCount); //執行最多次
        let currentMostFrequentCompletion = Number(machiningPrograms.detail[0].totalExecutionCount) - Number(machiningPrograms.detail[0].totalIncompleteExecutionCount); //完整執行最多次

        for (let [index, program] of machiningPrograms.detail.entries()) {
          if (index != 0) {
            if (currentFewestErrors > Number(program.averageErrorCount.toFixed(1))) {
              currentFewestErrors = Number(program.averageErrorCount.toFixed(1));
            }

            if (currentFastestExecution > program.averageProgramExecutionDuration) {
              currentFastestExecution = program.averageProgramExecutionDuration;
            }

            if (currentLowestPowerConsumption > Number(program.averagePowerConsumption.toFixed(2))) {
              currentLowestPowerConsumption = Number(program.averagePowerConsumption.toFixed(2));
            }

            if (currentMostExecutions < Number(program.totalExecutionCount)) {
              currentMostExecutions = Number(program.totalExecutionCount);
            }

            if (currentMostFrequentCompletion < Number(program.totalExecutionCount) - Number(program.totalIncompleteExecutionCount)) {
              currentMostFrequentCompletion = Number(program.totalExecutionCount) - Number(program.totalIncompleteExecutionCount);
            }
          }
        }

        setFewestErrors(currentFewestErrors.toFixed(1));
        setFastestExecution(formatTime(currentFastestExecution));
        setLowestPowerConsumption(currentLowestPowerConsumption.toFixed(2));
        setMostExecutions(currentMostExecutions);
        setMostFrequentCompletion(currentMostFrequentCompletion);
      }
      else {
        setFewestErrors("");
        setFastestExecution("");
        setLowestPowerConsumption("");
        setMostExecutions("");
        setMostFrequentCompletion("");
      }

      if (machiningPrograms?.totalItems > 0) {
        let currentSlowExecution = Number(machiningPrograms.detail[0].averageProgramExecutionDuration); //執行最久數值
        let currentMostPowerConsumption = Number(machiningPrograms.detail[0].averagePowerConsumption.toFixed(2)); //用電量最多
        let currentMostCarbonEmissions = Number(machiningPrograms.detail[0].averageCarbonEmissions.toFixed(2)); //碳排放量最多

        for (let [index, program] of machiningPrograms.detail.entries()) {
          if (index != 0) {
            if (currentSlowExecution < Number(program.averageProgramExecutionDuration)) {
              currentSlowExecution = Number(program.averageProgramExecutionDuration);
            }

            if (currentMostPowerConsumption < Number(program.averagePowerConsumption.toFixed(2))) {
              currentMostPowerConsumption = Number(program.averagePowerConsumption.toFixed(2));
            }

            if (currentMostCarbonEmissions < Number(program.averageCarbonEmissions.toFixed(2))) {
              currentMostCarbonEmissions = Number(program.averageCarbonEmissions.toFixed(2));
            }
          }
        }
        setSlowExecution(currentSlowExecution);
        setMostPowerConsumption(Number(currentMostPowerConsumption.toFixed(2)));
        setMostCarbonEmissions(Number(currentMostCarbonEmissions.toFixed(2)))
      }
    }
  }, [machiningPrograms]);

  useEffect(() => {
    if (decrypt(programID) !== "0") {
      if (executionRecords?.totalItems > 0) {
        let currentSlowExecution = Number(executionRecords.detail[0].duration); //執行最久數值
        let currentMostPowerConsumption = Number(executionRecords.detail[0].powerUsage.toFixed(2)); //用電量最多
        let currentMostCarbonEmissions = Number(executionRecords.detail[0].carbonEmissions.toFixed(2)); //碳排放量最多

        for (let [index, program] of executionRecords.detail.entries()) {
          if (index != 0) {
            if (currentSlowExecution < Number(program.duration)) {
              currentSlowExecution = Number(program.duration);
            }

            if (currentMostPowerConsumption < Number(program.powerUsage.toFixed(2))) {
              currentMostPowerConsumption = Number(program.powerUsage.toFixed(2));
            }

            if (currentMostCarbonEmissions < Number(program.carbonEmissions.toFixed(2))) {
              currentMostCarbonEmissions = Number(program.carbonEmissions.toFixed(2));
            }
          }
        }
        setSlowExecution(currentSlowExecution);
        setMostPowerConsumption(Number(currentMostPowerConsumption.toFixed(2)));

        setMostCarbonEmissions(Number(currentMostCarbonEmissions.toFixed(2)))
      }
    }
  }, [executionRecords]);

  //#region 載入工件上方基本資訊
  const loadWorkpieceBasicInfo = async () => {
    if (decrypt(programID) === "0") {
      const [httpStatus, reponseData] = await handleApiSingleLoadWorkpiece(decrypt(workpieceID));

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;

          //路徑:工件代號/0
          setWorkpieceBasicInfo({
            wpID: decrypt(workpieceID),
            wpNO: data.wpNO,
            wpName: data.wpName,
            wpImage: data.wpImageList != null ? data.wpImageList[0] : "",
            programID: decrypt(programID),
            programName: decrypt(programID) === "0" ? "" : data.programName
          });
        }
        else if (reponseData.statusCode === "40300") {
          setShowPageErr404(true);
          setJudgeBackHome(true);
        }
        else if (reponseData.statusCode === "40400") {
          setShowPageErr404(true);
          setJudgeBackHome(false);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else {
      const [httpStatus, reponseData] = await handleApiSingleLoadProgram(decrypt(programID));

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;

          if (decrypt(workpieceID) === "0") {  //路徑:0/加工程式代號
            setWorkpieceBasicInfo({
              wpID: "0",
              wpNO: "",
              wpName: "",
              wpImage: "",
              programID: decrypt(programID),
              programName: data.programName
            });
          }
          else {  //路徑:工件代號/加工程式代號
            setWorkpieceBasicInfo({
              wpID: decrypt(workpieceID),
              wpNO: data.wpNO,
              wpName: data.wpName,
              wpImage: data.wpImage,
              programID: decrypt(programID),
              programName: data.programName
            });
          }
        }
        else if (reponseData.statusCode === "40300") {
          setShowPageErr404(true);
          setJudgeBackHome(true);
        }
        else if (reponseData.statusCode === "40400") {
          setShowPageErr404(true);
          setJudgeBackHome(false);
        }
        else if (reponseData.statusCode === "42200") {
          if (decrypt(workpieceID) !== "0") {
            setShowPageErr404(true);
            setJudgeBackHome(false);
          }
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
  }
  //#endregion

  //#region 載入單一一筆工件
  const handleApiSingleLoadWorkpiece = async (selectId) => {
    let response = await apiSingleLoadWorkpiece(selectId);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 載入單一一筆加工程式
  const handleApiSingleLoadProgram = async (selectId) => {
    let postData = {};
    if (decrypt(workpieceID) !== "0") {
      postData = {
        wpID: decrypt(workpieceID)
      };
    }
    let response = await apiSingleLoadProgram(selectId, postData);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 載入下方表格資訊
  const loadTableInfo = async () => {
    if (decrypt(programID) !== "0") {
      const [httpStatus, reponseData] = await handleApiRawDataPowerCarbon("json");

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;
          setExecutionRecords(data);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else {
      const [httpStatus, reponseData] = await handleApiPowerWorkpieceExecList();

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;
          setMachiningPrograms(data);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
  }
  //#endregion

  //#region 加工程式明細API(第二層下方)
  const handleApiPowerWorkpieceExecList = async () => {
    let postData = {
      startDate: dateValue[0],
      endDate: dateValue[1],
      midList: [parseInt(choiceMachine?.mid)],
      calcMethod: calMethod,
      doExcludeNoExecution: 1
    };

    if (decrypt(workpieceID) != "0") {
      postData.wpIDList = [parseInt(decrypt(workpieceID))];
    }

    if (decrypt(programID) != "0") {
      postData.programIDList = [parseInt(decrypt(programID))];
    }

    let response = await apiPowerProgramExecList(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 用電量API(第三層下方)
  const handleApiRawDataPowerCarbon = async (format) => {
    let postData = {
      startDate: dateValue[0],
      endDate: dateValue[1],
      midList: [parseInt(choiceMachine?.mid)],
      format: format,
      programID: parseInt(decrypt(programID)),
      calcMethod: calMethod
    };

    if (decrypt(workpieceID) != "0") {
      postData.workpieceID = parseInt(decrypt(workpieceID));
    }

    let response = await apiRawDataPowerCarbon(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 載入中間資訊
  const loadMiddleInfo = async () => {
    if (decrypt(programID) !== "0") {
      const [httpStatus, reponseData] = await handleApiPowerWorkpieceExecList();

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;
          console.log("data", data)
          setMiddleInfo(data);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
    else {
      const [httpStatus, reponseData] = await handleApiPowerWorkpieceList();

      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          let data = reponseData.data;
          setMiddleInfo(data);
        }
        else {
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
  }
  //#endregion

  //#region 工件用電列表API
  const handleApiPowerWorkpieceList = async () => {
    let postData = {
      startDate: dateValue[0],
      endDate: dateValue[1],
      midList: [parseInt(choiceMachine?.mid)],
      calcMethod: calMethod,
      wpIDList: [parseInt(decrypt(workpieceID))]
    };

    let response = await apiPowerWorkpieceList(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  useEffect(() => {
    if (authority?.userAccount != null) {
      const fetchData = async () => {
        const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
        if (httpStatusMachine == "200") {
          if (reponseDataMachine.statusCode === "20000") {
            let artMachine = reponseDataMachine.data;
            let newMachineList = [];
            const [httpStatus, reponseData] = await handleApiIdeaLogin();
            if (httpStatus === 200) {
              let ideaMachine = reponseData?.artUserInfo?.owingMachines;
              for (let item of ideaMachine) {
                let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                if (filter) {
                  newMachineList.push(filter);
                }
              }
            }
            else {
              newMachineList = artMachine;
            }

            setMachineList(newMachineList);

            if (localStorage.getItem("choiceMachine") !== null) {
              setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
            }
            else {
              if (newMachineList.length > 0) {
                localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                setChoiceMachine(newMachineList[0]);
              }
              else {
                localStorage.removeItem("choiceMachine");
                setChoiceMachine(null);
              }
            }

            if (newMachineList.length === 0) {
              setShowPageErr404(true);
              setJudgeBackHome(true);
            }
          }
          else if (reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104") {
            overtime();
          }
        }
      };
      fetchData();
    }
  }, [authority]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if (machineListResponse) {
      const httpStatus = machineListResponse.request.status;
      const reponseData = machineListResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');

    let ideaLoginResponse = await apiIdeaLogin(formData);

    if (ideaLoginResponse) {
      const httpStatus = ideaLoginResponse.request.status;
      const reponseData = ideaLoginResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 機台下拉選單
  const handleDropdownSelect = (obj) => {
    setChoiceMachine(obj);
    localStorage.setItem("choiceMachine", JSON.stringify(obj));
  };
  //#endregion

  //#region (工件列表頁面)點選加工程式列表其中一個加工程式
  const handleProgrameName = (item) => {
    navigate(`/Power/PartsPrograms/${workpieceID}/${encrypt(item.programID)}`);
  }
  //#endregion

  //#region (加工程式頁面)點選加工程式開始時間
  const handleProgrameStarttime = (item) => {
    navigate(`/Power/PartsPrograms/${workpieceID}/${programID}/${encrypt(item.executionId)}`);
  }
  //#endregion

  return (
    <>
      <Tab tabList={tabList} setTabList={setTabList} />
      {
        !showPageErr404 ?
          <div className='pageContent'>
            <div className="filterRow">
              <div>
                {/*麵包屑*/}
                <WorkpieceBreadCrumb basinInfo={workpieceBasicInfo} workpieceID={workpieceID} programID={programID} />
                <div className="filling" id="pageWorkpiecesDetail"></div>
              </div>
              <div>
                {/*設備下拉選單*/}
                <div className="input-rightpadding">
                  <button className="input outline" type="button" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <b>{choiceMachine?.mName}</b>
                  </button>
                  <div className="icon icon-Dropdown bg-greyA small"></div>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                      machineList?.map((obj, index) => {
                        return <a className="dropdown-item cursor-pointer"
                          onClick={() => handleDropdownSelect(obj)}
                          key={index}>{obj.mName}</a>;
                      })
                    }
                  </div>
                </div>
                
                {/* <DatePicker dateValue={selectedDates} setDateValue={setSelectedDates} lang={lang} /> */}
                {/*日期區間選擇器*/}
                <DateRangePicker dateValue={dateValue} setDateValue={setDateValue} lang={lang} />
              </div>
            </div>
            <main className="mtop20">
              {/* <!--第1排 選擇的工件--> */}
              <div className="part-overview box-style-white-shadow">
                <div>
                  {/* <!--沒有圖片的話，在這裡加上 className="imgreplace"加上底色--> */}
                  <div>
                    {
                      workpieceBasicInfo?.wpImage != "" ?
                        <img src={workpieceBasicInfo?.wpImage} alt={workpieceBasicInfo?.wpNO} /> : <div></div>
                    }
                  </div>
                  <div>
                    <div>
                      {workpieceBasicInfo?.wpNO != "" ? <span>{t("workpieceID")} / {t("name")} {decrypt(programID) !== "0" ? "/" : ""} </span> : ""}
                      {decrypt(programID) !== "0" ? t("machiningProgram") : ""}
                    </div>
                    <div>
                      <div className='title'>
                        {
                          decrypt(programID) === "0" ?
                            <>
                              <span className='title-sub-blue'>{workpieceBasicInfo?.wpNO}</span><br />
                              <span className="titile-main">{workpieceBasicInfo?.wpName}</span>
                            </> : null
                        }
                        {
                          decrypt(programID) !== "0" && workpieceBasicInfo?.wpNO != "" ?
                            <>
                              <span className='title-sub-blue'>{workpieceBasicInfo?.wpNO}</span>
                              <span className='title-sub-black title-sub2'>{workpieceBasicInfo?.wpName}</span><br />
                              <span className="titile-main">{workpieceBasicInfo?.programName}</span>
                            </> :
                            decrypt(programID) !== "0" ? <span className="titile-main">{workpieceBasicInfo?.programName}</span> : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {
                    decrypt(programID) === "0" ?
                      <div className="box-score-scoreup">
                        <div>
                          <div className="material-symbols-rounded">dns</div>
                          <div>{t("numberMachiningPrograms")}</div>
                        </div>
                        <div className="text-black-20-bold">{middleInfo?.detail[0]?.numberMachingPrograms ?? 0}</div>
                      </div> : null
                  }

                  <div className="box-score-scoreup">
                    <div>
                      <div className="material-symbols-rounded">play_circle</div>
                      <div>{t("totalExecutionCount")}</div>
                    </div>
                    <div className="text-black-20-bold">
                      {
                        middleInfo?.detail[0]?.totalExecutionCount ?? 0
                      }
                    </div>
                  </div>

                  {/* <div className="box-score-scoreup">
                        <div>
                            <div className="material-symbols-rounded">stop_circle</div>
                            <div>{t("totalIncompleteExecutionCount")}</div>
                        </div>
                        <div className="text-black-20-bold">{workpieceData?.totalIncompleteExecutionCount ?? 0}</div>
                    </div> */}
                </div>
              </div>

              {/* <!--第2排 總和&平均--> */}
              <div className="flex-flexible mtop20">
                {/* <!--第1塊 程式執行時長--> */}
                <div className="box-style-white-shadow box-score-and-barchart ml-0">
                  {/* <!--Grid 1-左上「圓形」--> */}
                  <div className="box-color-green">
                    <div className="material-symbols-rounded">schedule</div>
                  </div>
                  {/* <!--Grid 2-右上「標題」--> */}
                  <div>{t("programExecutionDuration")}</div>
                  {/* <!--Grid 3-左下「空間」--> */}
                  <div>
                  </div>
                  {/* <!--Grid 4-右下「分數 & 長條圖」--> */}
                  <div>
                    <div>
                      <div className="text-black-20-bold">
                        <span className="text-grey-12">{t("total")}</span><br />
                        {middleInfo?.detail[0]?.totalProgramExecutionDuration != null ? formatTime(middleInfo?.detail[0]?.totalProgramExecutionDuration) : "-"}
                      </div>
                      <div className="text-black-20-bold">
                        <span className="text-grey-12">{t("average")}</span><br />
                        {middleInfo?.detail[0]?.averageProgramExecutionDuration != null ? formatTime(middleInfo?.detail[0]?.averageProgramExecutionDuration) : "-"}
                      </div>
                    </div>
                    <div className="chart-bar-radius scroll">
                      {
                        decrypt(programID) === "0" && middleInfo?.detail[0]?.numberMachingPrograms > 0 ?
                          <>
                            {
                              machiningPrograms?.detail?.slice(0, 15)?.map((obj, index) => {
                                let total = slowExecution;
                                let current = obj.averageProgramExecutionDuration;
                                let result = decimalWithOneDecimal(current, total);
                                return (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className='custom-tooltip'>
                                        {obj.programName}<br />
                                        {t("average")}&nbsp;{formatTime(current)}
                                      </Tooltip>
                                    }
                                    key={index}
                                  >
                                    <div style={{ height: `${result}%` }}></div>
                                  </OverlayTrigger>
                                )
                              })
                            }
                          </> : null
                      }
                      {
                        decrypt(programID) !== "0" && middleInfo?.detail[0]?.totalExecutionCount > 0 ?
                          <>
                            {
                              executionRecords?.detail?.slice(0, 15)?.map((obj, index) => {
                                let total = slowExecution;
                                let current = obj.duration;

                                let result = decimalWithOneDecimal(current, total);
                                return (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className='custom-tooltip'>
                                        {obj.programName}<br />
                                        {t("average")}&nbsp;{formatTime(current)}
                                      </Tooltip>
                                    }
                                    key={index}
                                  >
                                    <div style={{ height: `${result}%` }}></div>
                                  </OverlayTrigger>
                                )
                              })
                            }
                          </> : null
                      }
                    </div>
                  </div>
                </div>
                {/* <!--第1塊 程式執行時長 END-->  */}
                {/* <!--第2塊 用電量--> */}
                <div className="box-style-white-shadow box-score-and-barchart">
                  {/* <!--Grid 1-左上「圓形」--> */}
                  <div className="box-color-yellow">
                    <div className="material-symbols-rounded">bolt</div>
                  </div>
                  {/* <!--Grid 2-右上「標題」--> */}
                  <div>{t("powerConsumption")}</div>
                  {/* <!--Grid 3-左下「空間」--> */}
                  <div></div>
                  {/* <!--Grid 4-右下「分數 & 長條圖」--> */}
                  <div>
                    <div>
                      <div className="text-black-20-bold">
                        <span className="text-grey-12">{t("total")}</span><br />
                        {middleInfo?.detail[0]?.totalPowerConsumption.toFixed(2) ?? "-"}
                      </div>
                      <div className="text-black-20-bold">
                        <span className="text-grey-12">{t("average")}</span><br />
                        {middleInfo?.detail[0]?.averagePowerConsumption.toFixed(2) ?? "-"}
                      </div>
                    </div>
                    {/* <!--最多15條--> */}
                    <div className="chart-bar-radius scroll">
                      {
                        decrypt(programID) === "0" && middleInfo?.detail[0]?.numberMachingPrograms > 0 ?
                          <>
                            {
                              machiningPrograms?.detail?.slice(0, 15)?.map((obj, index) => {
                                let total = mostPowerConsumption;
                                let current = obj.averagePowerConsumption;
                                let result = decimalWithOneDecimal(current, total);
                                return (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className='custom-tooltip'>
                                        {obj.programName}<br />
                                        {t("average")}&nbsp;{current?.toFixed(2)}
                                      </Tooltip>
                                    }
                                    key={index}
                                  >
                                    <div style={{ height: `${result}%` }}></div>
                                  </OverlayTrigger>
                                )
                              })
                            }
                          </> : null
                      }
                      {
                        decrypt(programID) !== "0" && middleInfo?.detail[0]?.totalExecutionCount > 0 ?
                          <>
                            {
                              executionRecords?.detail?.slice(0, 15)?.map((obj, index) => {
                                let total = mostPowerConsumption;
                                let current = obj.powerUsage;
                                let result = decimalWithOneDecimal(current, total);
                                return (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className='custom-tooltip'>
                                        {obj.programName}<br />
                                        {t("average")}&nbsp;{current?.toFixed(2)}
                                      </Tooltip>
                                    }
                                    key={index}
                                  >
                                    <div style={{ height: `${result}%` }}></div>
                                  </OverlayTrigger>
                                )
                              })
                            }
                          </> : null
                      }
                    </div>
                  </div>
                </div>
                {/* <!--第2塊 用電量 END-->  */}
                {/* <!--第3塊 碳排放量--> */}
                <div className="box-style-white-shadow box-score-and-barchart mr-0">
                  {/* <!--Grid 1-左上「圓形」--> */}
                  <div className="box-color-lakeblue">
                    <div className="material-symbols-rounded">cloud</div>
                  </div>
                  {/* <!--Grid 2-右上「標題」--> */}
                  <div>{t("carbonEmissions")}</div>
                  {/* <!--Grid 3-左下「空間」--> */}
                  <div></div>
                  {/* <!--Grid 4-右下「分數 & 長條圖」--> */}
                  <div>
                    <div>
                      <div className="text-black-20-bold">
                        <span className="text-grey-12">{t("total")}</span><br />
                        {middleInfo?.detail[0]?.totalCarbonEmissions.toFixed(2) ?? "-"}
                      </div>
                      <div className="text-black-20-bold">
                        <span className="text-grey-12">{t("average")}</span><br />
                        {middleInfo?.detail[0]?.averageCarbonEmissions.toFixed(2) ?? "-"}
                      </div>
                    </div>
                    {/* <!--最多15條--> */}
                    <div className="chart-bar-radius scroll">
                      {
                        decrypt(programID) === "0" && middleInfo?.detail[0]?.numberMachingPrograms > 0 ?
                          <>
                            {
                              machiningPrograms?.detail?.slice(0, 15)?.map((obj, index) => {
                                let total = mostCarbonEmissions;
                                let current = obj.averageCarbonEmissions;
                                let result = decimalWithOneDecimal(current, total);
                                return (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className='custom-tooltip'>
                                        {obj.programName}<br />
                                        {t("average")}&nbsp;{current?.toFixed(2)}
                                      </Tooltip>
                                    }
                                    key={index}
                                  >
                                    <div style={{ height: `${result}%` }}></div>
                                  </OverlayTrigger>
                                )
                              })
                            }
                          </> : null
                      }
                      {
                        decrypt(programID) !== "0" && middleInfo?.detail[0]?.totalExecutionCount > 0 ?
                          <>
                            {
                              executionRecords?.detail?.slice(0, 15)?.map((obj, index) => {
                                let total = mostCarbonEmissions;
                                let current = obj.carbonEmissions;
                                let result = decimalWithOneDecimal(current, total);
                                return (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className='custom-tooltip'>
                                        {obj.programName}<br />
                                        {t("average")}&nbsp;{current?.toFixed(2)}
                                      </Tooltip>
                                    }
                                    key={index}
                                  >
                                    <div style={{ height: `${result}%` }}></div>
                                  </OverlayTrigger>
                                )
                              })
                            }
                          </> : null
                      }
                    </div>
                  </div>
                </div>
              </div>

              {
                decrypt(programID) === "0" ?
                  <div className="box-list-whiterow flex-table mtop20">
                    <div className="title">{t("machiningPrograms")}</div>
                    {
                      machiningPrograms?.totalItems > 0 ?
                        <div className="table-scroll-justify">
                          <div className="dmtable">
                            <div className="thead sticky-top">
                              <div className="tr">
                                <div className="tflex2 min-width7 sticky-left">{t("machiningProgram")}</div>
                                <div className="tflex1 min-width5 tCenter">{t("executionCount")}</div>
                                {/* <div className="tflex1 min-width5 tCenter">{t("incompleteExecutionCount")}</div> */}
                                <div className="tflex1 min-width5 tCenter">{t("averageErrorCount")}</div>
                                <div className="tflex1 min-width5 tRight">{t("averageExecutionDuration")}</div>
                                <div className="tflex1 min-width5 tRight">{t("averagePowerConsumption")}</div>
                                <div className="tflex1 min-width5 tRight">{t("averageCarbonEmissions")}</div>
                                <div className="tflex1 min-width11 tablecell-tag"></div>
                              </div>
                            </div>
                            <div className="tbody">
                              {/* <!--把第一欄的div中加上span，是為了在hover效果時，觸發範圍能限制在文字上，而不是整個div--> */}
                              {
                                machiningPrograms?.detail?.map((obj, index) => {
                                  return (
                                    <div className="tr" key={index}>
                                      <div className="tflex2 min-width7 sticky-left" onClick={() => handleProgrameName(obj)}><span>{obj.programName}</span></div>
                                      <div className="tflex1 min-width5 tCenter">{obj.totalExecutionCount}</div>
                                      {/* <div className="tflex1 min-width5 tCenter">{obj.totalIncompleteExecutionCount}</div> */}
                                      <div className="tflex1 min-width5 tCenter">{obj.averageErrorCount.toFixed(1)}</div>
                                      <div className="tflex1 min-width5 tRight">{formatTime(obj.averageProgramExecutionDuration)}</div>
                                      <div className="tflex1 min-width5 tRight">{obj.averagePowerConsumption.toFixed(2)}</div>
                                      <div className="tflex1 min-width5 tRight">{obj.averageCarbonEmissions.toFixed(2)}</div>
                                      <div className="tflex1 min-width11 tablecell-tag">
                                        {
                                          obj.averageErrorCount.toFixed(1) === fewestErrors ?
                                            <div className="tag box-color-orange">{t("fewestErrors")}</div> : null
                                        }
                                        {
                                          formatTime(obj.averageProgramExecutionDuration) === fastestExecution ?
                                            <div className="tag box-color-purple">{t("fastestExecution")}</div> : null
                                        }
                                        {
                                          obj.averagePowerConsumption.toFixed(2) === lowestPowerConsumption ?
                                            <div className="tag box-color-yellow">{t("lowestPowerUsage")}</div> : null
                                        }
                                        {
                                          obj.totalExecutionCount === mostExecutions ?
                                            <div className="tag box-color-blue">{t("mostExecutions")}</div> : null
                                        }
                                        {/* {
                                            obj.totalExecutionCount - obj.totalIncompleteExecutionCount  == mostFrequentCompletion ?
                                              <div className="tag box-color-lakeblue">{t("mostFrequentCompletion")}</div> : null
                                          } */}
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div> :
                        <div className="nodata">
                          {/* <!--選取的條件沒有執行紀錄--> */}
                          <div className="notice">{t("noRecord")}</div>
                        </div>
                    }
                  </div> :
                  <div className="box-list-whiterow flex-table mtop20">
                    <div className="title">{t("machiningPrograms")}</div>
                    {
                      executionRecords?.totalItems > 0 ?
                        <div className="table-scroll-justify">
                          <div className="dmtable">
                            <div className="thead sticky-top">
                              <div className="tr">
                                <div className="tflex2 min-width7 sticky-left">{t("startTime")}</div>
                                <div className="tflex1 min-width5 tLeft">{t("endTime")}</div>
                                <div className="tflex1 min-width5 tCenter">{t("durarion")}</div>
                                {/* <div className="tflex1 min-width5 tCenter">{t("executionCondition")}</div> */}
                                <div className="tflex1 min-width5 tCenter">{t("numberErrors")}</div>
                                <div className="tflex1 min-width5 tRight">{t("powerConsumption")}</div>
                                <div className="tflex1 min-width5 tRight">{t("carbonEmissions")}</div>
                                <div className="tflex1 min-width5 tCenter">{t("equipmentUsed")}</div>
                              </div>
                            </div>
                            <div className="tbody">
                              {
                                executionRecords?.detail?.map((obj, index) => {
                                  return (
                                    <div className="tr" key={`executionRecords${index}`}>
                                      <div className="tflex2 min-width7 sticky-left" onClick={() => handleProgrameStarttime(obj)}>
                                        <span>
                                          {(() => {
                                            const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.programTimeStart);
                                            return trans.startDate + " " + trans.startTime;
                                          })()}
                                        </span>
                                      </div>
                                      <div className="tflex1 min-width5 tLeft">
                                        {(() => {
                                          const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.programTimeEnd);
                                          return trans.startDate + " " + trans.startTime;
                                        })()}
                                      </div>
                                      <div className="tflex1 min-width5 tCenter">{formatTime(obj.duration)}</div>
                                      {/* <div className="tflex1 min-width5 tCenter">{obj.executionCondition === "B" ? t("incomplete") : t("finished")}</div> */}
                                      <div className="tflex1 min-width5 tCenter">{obj.numberErrors}</div>
                                      <div className="tflex1 min-width5 tRight">{obj.powerUsage.toFixed(2)}</div>
                                      <div className="tflex1 min-width5 tRight">{obj.carbonEmissions.toFixed(2)}</div>
                                      <div className="tflex1 min-width5 tCenter">{obj.equipmentSerialNumber}</div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div> :
                        <div className="nodata">
                          {/* <!--選取的條件沒有執行紀錄--> */}
                          <div className="notice">{t("noRecord")}</div>
                        </div>
                    }
                  </div>
              }
            </main>
          </div>
          : <PageErr404 judgeBackHome={judgeBackHome} backURL={`/Power/${tabName}`} />
      }

      <ToastContainer />
    </>
  );
};

export default WorkpiecesDeatil;