import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './modal.css';
import { MyUserContext } from 'contexts/MyUserContext';
import { convertDateFormat, formatTime } from 'utils/commonFun';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import MyPagination from 'components/basic/Pagination';

import DatePicker from 'components/datePicker/DatePicker';
import {parseDate} from '@internationalized/date';

import { apiWorkpieceList, apiProgramList, apiRawDataPowerCarbon } from 'utils/Api';

const SingleExecutionLogModal = ({ showModal, handleClose, selectedDates, setSelectedDates, 
  singleExecutionBehavior, showExecutionRef, setShowExecutionRef, showExecutionList, setShowExecutionList }) => {
  const { t } = useTranslation("reports");
  const { overtime } = useContext(MyUserContext);

  const [mainProgram, setMainProgram] = useState("");

  const [workpieceList, setWorkpieceList] = useState(null); //下拉選單-工件列表
  const [programList, setProgramList] = useState(null); //下拉選單-工件列表未分類
  const [executionList, setExecutionList] = useState(null); //執行紀錄
  const [powerCarbonCurrentPage, setPowerCarbonCurrentPage] = useState(1); //目前頁碼(用電量)

  const [initSelectedRecords, setInitSelectedRecords] = useState([]); //上一次選擇紀錄
  const [finalSelectedRecords, setFinalSelectedRecords] = useState([]); //這次最終結果

  let [singleExecutionDate, setSingleExecutionDate] = useState({
    start: null,
    end: null
  }); //查詢區間

  //#region 初始載入
  useEffect(() => {
    if(showModal){
      loadWorkpieceList();
      loadProgramList();

      if(singleExecutionBehavior === 1){
        if(showExecutionRef != null){
          setInitSelectedRecords([showExecutionRef]);
          setFinalSelectedRecords([showExecutionRef]);
        }
        else{
          setInitSelectedRecords([]);
          setFinalSelectedRecords([]);
        }
      }
      else{
        setInitSelectedRecords(showExecutionList);
        setFinalSelectedRecords(showExecutionList);
      }

      
    }
  }, [showModal]);
  //#endregion

  useEffect(() => {
    if(selectedDates?.startDate != null && selectedDates?.endDate != null && mainProgram != null){
      loadRawDataPowerCarbon();

      console.log({
        start: parseDate(selectedDates?.startDate),
        end: parseDate(selectedDates?.endDate)
      })
      setSingleExecutionDate({
        start: parseDate(selectedDates?.startDate),
        end: parseDate(selectedDates?.endDate)
      });
    }
  }, [selectedDates, powerCarbonCurrentPage, mainProgram]);

  useEffect(() => {
    if(mainProgram == ""){
      if(workpieceList != null){
        setMainProgram(workpieceList.detail[0].programList[0].programID);
      }
      if(workpieceList == null && programList != null){
        setMainProgram(programList.detail[0].programID);
      }
    }
  }, [workpieceList, programList]);

  //#region 日期轉換
   const handleDateRangeChange = (event, picker) => {
    setSelectedDates({
      startDate: picker.startDate.format('yyyy-MM-DD'),
      endDate: picker.endDate.format('yyyy-MM-DD')
    });
    // localStorage.setItem("workpieces_startDate", picker.startDate.format('yyyy-MM-DD'));
    // localStorage.setItem("workpieces_endDate", picker.endDate.format('yyyy-MM-DD'));
  };
  //#endregion

  //#region 載入工件列表
  const loadWorkpieceList = async () => {
    const [httpStatus, reponseData] = await handleApiWorkpieceList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        if(data.totalItems > 0){
          setWorkpieceList(data);
        }
        else{
          setWorkpieceList(null);
          setMainProgram("");
        }
      }
      else if(reponseData.statusCode === "40103" || reponseData.statusCode === "40104"){
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = {
      doIncludeNoProgram: 0
    };
    let workpieceListResponse = await apiWorkpieceList(postJson);
    if(workpieceListResponse){
      const httpStatus = workpieceListResponse.request.status;
      const reponseData = workpieceListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

   //#region 載入加工程序列表
   const loadProgramList = async () => {
    const [httpStatus, reponseData] = await handleApiProgramList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        if(data.totalItems > 0){
          setProgramList(data);
        }
        else{
          setProgramList(null);
        }
      }
      else if(reponseData.statusCode === "40103" || reponseData.statusCode === "40104"){
          overtime();
      }
    }
  }
  //#endregion

  //#region API抓取加工程序列表
  const handleApiProgramList = async () => {
    let postJson = {
      filterWPCondition: 2
    }
    let programListResponse = await apiProgramList(postJson);
    if(programListResponse){
        const httpStatus = programListResponse.request.status;
        const reponseData = programListResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 用電量
  const loadRawDataPowerCarbon = async () => {
    const [httpStatus, reponseData] = await handleApiRawDataPowerCarbon("json");
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        if(data.totalItems > 0){
          setExecutionList(data);
        }
        else{
          setExecutionList(null);
        }
      }
    }
    else{
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 用電量API
  const handleApiRawDataPowerCarbon = async (format) => {
    let postData = {
      startDate: selectedDates.startDate,
      endDate: selectedDates.endDate,
      midList: [],
      format: format,
      intLimit: 30,
      intOffset: (powerCarbonCurrentPage - 1) * 30,
      programID: parseInt(mainProgram),
      orderByTimeStart: 1
    };

    let response = await apiRawDataPowerCarbon(postData);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion
  
  //#region 切換分頁
  const handlePageChange = (page) => {
    setPowerCarbonCurrentPage(page);
  };
  //#endregion

  //#region 選取Log
  const handleExecutionLog = (execution) => {
    if(singleExecutionBehavior === 1){
      if(finalSelectedRecords.length > 1 || (finalSelectedRecords.length === 1 && finalSelectedRecords[0].executionId !== execution.executionId) || finalSelectedRecords.length === 0){
        setFinalSelectedRecords([execution]);
      }
      else{
        setFinalSelectedRecords([]);
      }
    }
    else{
      setFinalSelectedRecords(prevList => {
        const isExisting = prevList.find(d => d.executionId === execution.executionId);
        if (isExisting) {
            // 存在就移除
            return prevList.filter(id => id.executionId !== execution.executionId);
        } else {
            // 不存在就新增
            return [...prevList, execution];
        }
      });
    }
    
  }
  //#endregion

  // useEffect(() => {
  //   console.log("finalSelectedRecords", finalSelectedRecords)
  // }, [finalSelectedRecords]);

  //#region 取消按鈕
  const handleCancel = () => {
    if(singleExecutionBehavior === 1){
      if(initSelectedRecords.length > 0){
        setShowExecutionRef(initSelectedRecords[0]);
      }
      else{
        setShowExecutionRef(null);
      }
    }
    else{
      setShowExecutionList(initSelectedRecords);
    }
    handleClose();
  }
  //#endregion

  //#region 套用按鈕
  const handleApply = () => {
    let judgeErr = false;

    if(singleExecutionBehavior === 1){
      if(finalSelectedRecords.length > 0){
        setShowExecutionRef(finalSelectedRecords[0]);
      }
      else{
        setShowExecutionRef(null);
      }
    }
    else{
      if(finalSelectedRecords?.length > 10){
        judgeErr = true;
      }
      else{
        setShowExecutionList(finalSelectedRecords);
      }
    }
    
    if(!judgeErr){
      handleClose();
    }
  }
  //#endregion

  //#region 切換加工程式
  const handleMachineProgram = (e) => {
    const { value } = e.target;
    setMainProgram(value);
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md" backdrop="static" keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("selectSingleExecutionLog")}<br />
              <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="machineProgram">
                    <Form.Label className='form-label-custom'>
                      {t("machiningProgram")}
                      <span className='text-danger ms-1'>*</span>
                    </Form.Label><br />
                    <Form.Select as="select" className='machineProgram' 
                      value={mainProgram} onChange={handleMachineProgram}>
                    {
                      workpieceList?.detail?.map((obj, index) => {
                        return(
                          <optgroup label={`${obj.wpNO} ${obj.wpName}`} key={index}>
                            {obj.programList?.map((item, idx) => (
                              <option value={item.programID} key={idx}>{item.programNO}</option>
                            ))}
                          </optgroup>
                        )
                      }) 
                    }
                    {
                      programList ?
                      <optgroup label="Unclassified" style={{ borderBottom: '1px solid black' }}>
                        {programList?.detail.map((item, idx) => {
                          return(
                            <option value={item.programID} key={idx}>{item.programName}</option>
                          )})
                        }
                      </optgroup> :
                      null
                    }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col xs={12}>
                  <Form.Group controlId="filterPeriod">
                    <Form.Label className='form-label-custom'>
                      {t("filterPeriod")}
                      <span className='text-danger ms-1'>*</span>
                    </Form.Label><br />
                  </Form.Group>
                  {/* <DatePicker dateValue={singleExecutionDate} setDateValue={setSingleExecutionDate} /> */}
                  <DateRangePicker onApply={handleDateRangeChange} 
                    autoApply={true}>
                    <input
                      type="text"
                      value={
                        selectedDates?.startDate && selectedDates?.endDate
                          ? `${convertDateFormat(selectedDates?.startDate, "YYYY")  === convertDateFormat(selectedDates?.endDate, "YYYY") ? 
                            convertDateFormat(selectedDates?.startDate, "MMM DD") : convertDateFormat(selectedDates?.startDate, "MMM DD, YYYY")} ~ ${convertDateFormat(selectedDates?.endDate, "MMM DD, YYYY")}`
                          : ''
                      }
                      className='form-control'
                      readOnly
                    />
                  </DateRangePicker>
                </Col>
              </Row>
              <hr />
              <div className='singleExecutionLog-table-container'>
                <div className="box-list-datediv">
                  <div className="dmtable">
                      <div className="thead sticky-top">
                          <div className="tr">
                              <div className="checkbox"></div>
                              <div>{t("startTime")}</div>
                              <div>{t("durarion")}</div>
                              <div>{t("equipmentUsed")}</div>
                          </div>
                      </div>
                      <div className="tbody table-scroll-justify">
                        {
                          !executionList ?
                          <div className='noDataDiv'><span className='noData'>{t("noRecord")}</span></div> : null
                        }
                        <div className="tgroup">
                        {(() => {
                          let newGroupDate = [];
                          return (
                            <>
                              {executionList?.detail?.map((obj, index) => {
                                let currentDate = convertDateFormat(obj.programTimeStart, "MMM DD, YYYY");
                                let displayDateHeader = !newGroupDate.includes(currentDate);
            
                                if (displayDateHeader) {
                                  newGroupDate.push(currentDate);
                                }
                                return (
                                  <React.Fragment key={index}>
                                    {displayDateHeader ? <div className="tr tgroup-title">{currentDate}</div> : null}
                                    <div key={index} className={`tr ${finalSelectedRecords?.find(d => d.executionId === obj.executionId) ? "active" : ""}`}
                                      onClick={()=>handleExecutionLog(obj)}>
                                      <div className="checkbox">{finalSelectedRecords?.find(d => d.executionId === obj.executionId) ? <p className="material-symbols-rounded mb-0 text-blue">check</p> : null}</div>
                                      <div>{convertDateFormat(obj.programTimeStart, "hh:mm:ss A")}</div>
                                      <div>{formatTime(obj.duration)}</div>
                                      <div>{obj.equipmentSerialNumber}</div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </>
                          );
                        })()}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <MyPagination totalPages={Math.ceil((executionList?.totalItems) / 30)} currentPage={powerCarbonCurrentPage} 
                  onPageChange={handlePageChange} />
              </div>
              {
                singleExecutionBehavior === 2 && finalSelectedRecords?.length > 10 ?
                  <Form.Label className='text-danger mb-2'>{t("limit10")}</Form.Label> :
                  <></>
              }
            </Form>
           
          </Modal.Body>
          <div className="modal-footer-custom">
            <div className="btn-gp">
              <div>
                <button className="btn btn-txt-black" onClick={handleCancel}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button className="btn btn-lv1 fat" onClick={handleApply}>
                  {t("apply")}
                </button>
              </div>
            </div>
          </div>
          {/* <Modal.Footer className="modal-footer-custom">
            <Row>
              <Col xs={6} >
                <button className="button-not-border" onClick={handleCancel}>
                  {t("cancel")}
                </button>
              </Col>
              <Col xs={6} className='d-flex justify-content-end' onClick={handleApply}>
                <button className="button-primary">
                  {t("apply")}
                </button>
              </Col>
            </Row>
          </Modal.Footer> */}
        </Modal>

        <ToastContainer />
    </>
  );
};

export default SingleExecutionLogModal;