import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import MyPagination from 'components/basic/Pagination';
import { formatTime, formatDateTimeRange } from 'utils/commonFun';
import { apiRawDataSet } from 'utils/Api';
import { MyUserContext } from 'contexts/MyUserContext';
import { ToastContainer, toast } from 'react-toastify';

const PowerCarbon = (props) => {
  const { tableResult, showData, currentPage, setCurrentPage, setFieldInstructionsJSON } = props;

  const { t } = useTranslation("powerCarbon");
  const { lang, showFormatDate, showFormatTime } = useContext(MyUserContext);
  const [tableHeader, setTableHeader] = useState(null); //Header名稱
  const scrollRef = useRef(null);

  useEffect(() => {
    loadRawDataSet();
  }, [lang]);

  //#region 載入原始數據表頭設定檔
  const loadRawDataSet = async () => {
    const [httpStatus, response] = await handleApiRawDataSet("powerUsage");
    if (httpStatus == "200") {
      if (response.statusCode === "20000") {

        setTableHeader(response.data);

        if (response.data.length > 0) {
          let json = [];
          response.data.forEach(item => {
            if (item.key === "programTimeStart") {
              json.push({ colName: item.value, colDescribe: [t("programTimeStart1")] });
            }
            else if (item.key === "programTimeEnd") {
              json.push({ colName: item.value, colDescribe: [t("programTimeEnd1")] });
            }
            else if (item.key === "programName") {
              json.push({ colName: item.value, colDescribe: [t("programName1")] });
            }
            else if (item.key === "workpieceID") {
              json.push({ colName: item.value, colDescribe: [t("workpieceID1")] });
            }
            else if (item.key === "workpieceName") {
              json.push({ colName: item.value, colDescribe: [t("workpieceName1")] });
            }
            else if (item.key === "duration") {
              json.push({ colName: item.value, colDescribe: [t("duration1")] });
            }
            // else if(item.key === "executionCondition"){
            //   json.push({ colName: item.value, colDescribe: [t("executionCondition1"), t("executionCondition2")]});
            // }
            else if (item.key === "numberErrors") {
              json.push({ colName: item.value, colDescribe: [t("numberErrors1")] });
            }
            else if (item.key === "powerUsage") {
              json.push({ colName: item.value, colDescribe: [t("powerUsage1")] });
            }
            else if (item.key === "carbonEmissions") {
              json.push({ colName: item.value, colDescribe: [t("carbonEmissions1")] });
            }
            else if (item.key === "equipmentSerialNumber") {
              json.push({ colName: item.value, colDescribe: [t("equipmentSerialNumber1")] });
            }
          });
          setFieldInstructionsJSON(json);
        }
        else {
          setFieldInstructionsJSON(null);
        }
      }
    }
    else {
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 原始數據表頭設定檔API
  const handleApiRawDataSet = async (type) => {
    let response = await apiRawDataSet(type);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 切換分頁
  const handlePageChange = (page) => {
    setCurrentPage(page);

    scrollRef.current.scrollTop = 0;
  };
  //#endregion

  return (
    <>
      <div>
        {/*「沒有數據」提醒*/}
        {(() => {
          if (tableResult === "1") {
            return <div className='noDataDiv'><span className='noData'>{t("noDataSelected")}</span></div>;
          }
          else if (tableResult === "2") {
            return <div className='noDataDiv'><span className='noData'>{t("invalidQueryConditions")}</span></div>;
          }
          else {
            return (
              <>
                {/*表格*/}
                <div className="table-wrapper border">
                  <div className="table-minheight1" ref={scrollRef}>
                    <table>
                      <thead>
                        <tr>
                          {
                            tableHeader?.map((obj, index) => (
                              <th key={index}>
                                <span>{obj.value}</span>
                              </th>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          showData?.detail?.map((obj, index) => (
                            <tr key={index}>
                              <td>
                                {(() => {
                                  const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.programTimeStart);
                                  return trans.startDate + " " + trans.startTime;
                                })()}
                              </td>
                              <td>
                                {(() => {
                                  const trans = formatDateTimeRange(showFormatDate, showFormatTime, obj.programTimeEnd);
                                  return trans.startDate + " " + trans.startTime;
                                })()}
                              </td>
                              <td>{obj.programName}</td>
                              <td>{obj.workpieceID}</td>
                              <td>{obj.workpieceName}</td>
                              <td>{formatTime(obj.duration)}</td>
                              {/* <td>{obj.executionCondition === "B" ? t("incomplete") : t("finished")}</td> */}
                              <td>{obj.numberErrors}</td>
                              <td className='tRight'>{obj.powerUsage.toFixed(2)}</td>
                              <td className='tRight'>{obj.carbonEmissions.toFixed(2)}</td>
                              <td>{obj.equipmentSerialNumber}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <MyPagination totalPages={Math.ceil(showData?.totalItems / 30)} currentPage={currentPage}
                    onPageChange={handlePageChange} />
                </div>
              </>
            )
          }
        })()}
      </div>

      <ToastContainer />
    </>
  );
};

export default PowerCarbon;