import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Container, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faCheck, faTrashCan, faXmark, faCaretUp, faCaretDown, faCircleInfo, faSearch, faL } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink, Document, Page, Text, Font, View, Image } from '@react-pdf/renderer';
import font from 'fonts/MicrosoftBlack.ttf';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./WorkPiece.css";
import MessageModal from 'components/basic/MessageModal';

import {
    apiQueryWorkPieceData,
    apiWorkPieceOptions,
    apiWorkPieceCostBatch,
    apiSpreadsheet,
    apiGetWorkpiece,
    apiUploadImage
} from "utils/Api";

import {
    formatTime,
    addThousandsSeparator,
    removeThousandsSeparator,
    getCurrentTimeInUSFormat,
    isNullDisplay,
    isNullCal,
    getNowDateTime,
    getMisc
} from "utils/commonFun"; //共用Function

import { useTranslation } from 'react-i18next'; //語系
import { getCookieLang } from 'utils/langUtil';

const WorkPiece = () => {
    const { t, i18n } = useTranslation("common");
    const [activeTab, setActiveTab] = useState(0); //頁籤
    const [activeTabCSS, setActiveTabCSS] = useState([
        {
            tabName: "Calculator",
            fill: activeTab == 0 ? 1 : 0
        },
        {
            tabName: "UploadData",
            fill: activeTab == 1 ? 1 : 0
        },
        {
            tabName: "UploadImage",
            fill: activeTab == 2 ? 1 : 0
        }
    ]); //頁籤


    const [formula, setFormula] = useState("A"); //目前公式(Modal)
    const [confirmFormula, setConfirmFormula] = useState("A"); //目前公式(確定)
    const [showModalFormula, setShowModalFormula] = useState(false); //是否顯示Modal(計算方式)

    const SearchInputRef = useRef(null); //搜尋按鈕
    const [showModalUpdateValue, setShowModalUpdateValue] = useState(false); //是否顯示Modal(修改數值)
    const [selectedModalUpdateValueIndex, setSelectedModalUpdateValueIndex] = useState(0); //目前選擇修改數值Index
    const [currentMachineId, setCurrentMachineId] = useState(""); //目前使用設備
    const [hourEquipmentUsageFee, setHourEquipmentUsageFee] = useState({ init: 0, format: '0', inputVal: '0', singleVal: '0' }); //每小時設備使用費
    const [hourPersonnelCosts, setHourPersonnelCosts] = useState({ init: 0, format: '0', inputVal: '0', singleVal: '0' }); //每小時人員費用
    const [materialCosts, setMaterialCosts] = useState({ init: 0, format: '0', inputVal: '0' }); //單顆素材費用
    const [updateValueTools, setUpdateValueTools] = useState([]); //刀具列表
    const hourEquipmentUsageFeeRef = useRef(null); //每小時設備使用費物件
    const hourPersonnelCostsRef = useRef(null); //每小時人員費用物件
    const materialCostsRef = useRef(null); //單顆素材費用物件
    const toolRefs = useRef([]); //刀具物件

    const [showModalWorkPiece, setShowModalWorkPiece] = useState(false); //是否顯示Modal(選擇工件)
    const [modalKeywordWorkPiece, setModalKeywordWorkPiece] = useState(""); //Modal工件列表-關鍵字
    const [modalWorkPiece, setModalWorkPiece] = useState([]); //Modal工件列表
    const [modalSelectedWorkPiece, setModalSelectedWorkPiece] = useState([]); //Modal工件列表-已選取(id列表以及wpNo列表)
    const [modalSelectedWorkPieceApi, setModalSelectedWorkPieceApi] = useState([]); //Modal工件列表-已選取(id列表=>給API用的)

    const [modalCategoriesWorkPiece, setModalCategoriesWorkPiece] = useState([]); //下拉選單(類別)
    const [modalMaterialsWorkPiece, setModalMaterialsWorkPiece] = useState([]); //下拉選單(材質)
    const [modalDimensionsWorkPiece, setModalDimensionsWorkPiece] = useState([]); //下拉選單(尺寸)

    const [modalCategoriesWorkPieceVal, setModalCategoriesWorkPieceVal] = useState(""); //下拉選單(選取類別值)
    const [modalMaterialsWorkPieceVal, setModalMaterialsWorkPieceVal] = useState(""); //下拉選單(選取材質值)
    const [modalDimensionsWorkPieceVal, setModalDimensionsWorkPieceVal] = useState(""); //下拉選單(選取尺寸值)

    const [selectedWorkPiece, setSelectedWorkPiece] = useState([]); //目前選取工件詳細資料
    const [inputQuantityCollapse, setInputQuantityCollapse] = useState([]); //目前選取工件詳細資料-收合
    const [selectedWorkPieceMachine, setSelectedWorkPieceMachine] = useState([]); //目前使用設備是下拉選單Id


    const [showModalDelWorkpiece, setShowModalDelWorkpiece] = useState(false); //是否顯示Modal(刪除工件)
    const [showModalDelWorkpieceId, setShowModalDelWorkpieceId] = useState(""); //選取要刪除工件

    const [totalPrice, setTotalPrice] = useState(0); //總金額
    const [currentTotalPrice, setCurrentotalPrice] = useState(0); //目前要刪除金額

    const [totalWorkHours, setTotalWorkHours] = useState(0); //工時
    const [currentTotalWorkHours, setCurrentTotalWorkHours] = useState(0); //目前要刪除工時

    const [totalQuantity, setTotalQuantity] = useState(0); //數量
    const [currentTotalQuantity, setCurrentTotalQuantity] = useState(0); //目前要刪除數量

    const fileInputRef = useRef(null); //上傳Excel檔案input file
    const [excelUploadStatus, setExcelUploadStatus] = useState(1); //excel上傳結果狀態 1=>沒有上傳 2=>錯誤(Sheet有問題) 3=>錯誤(資料表內容) 4=>全部成功(只出現一次) 5=>看全部資料(上傳成功有資料之後都是這個) 6=>未選擇檔案
    const [excelUploadResult, setExcelUploadResult] = useState([]); //excel上傳結果 如果是空陣列=>代表沒有錯誤

    const fileInputImageRef = useRef(null); //上傳圖片檔案input file
    const [imgWorkPiece, setImgWorkPiece] = useState([]); //目前所有圖片列表
    const [imgUploadStatus, setImgUploadStatus] = useState(3); //圖片上傳結果狀態 1=>沒有建置過工件(需導過去上傳Excel) 2=>建置了產品(還沒有圖片) 3=>建置了產品(有圖片) 4=>全部上傳成功 5=>部分上傳成功 6=>全部上傳失敗
    const [imgLastUpdateTime, setImgLastUpdateTime] = useState(""); //最後更新時間(圖片)
    const [imgUploadErrDisplay, setImgUploadErrDisplay] = useState(false); //是否顯示上方紅色錯誤訊息卡片
    const [imgUploadErr, setImgUploadErr] = useState([]);
    const [imgUploadSuccessDisplay, setImgUploadSuccessDisplay] = useState(false); //是否顯示上方綠色訊息卡片

    const pdfDownload = useRef(null); //檔案名稱下載按鈕
    const [showModalPDFfileName, setShowModalPDFfileName] = useState(false); //是否顯示Modal(檔案名稱)
    const [tempPdfFileName, setTempPdfFileName] = useState(""); //檔案名稱(Modal)

    const [showModalFileNameTemplate, setShowModalFileNameTemplate] = useState(false); //是否顯示Modal(圖檔命名範例)

    const [showConfirmCalWayModal, setShowConfirmCalWayModal] = useState(false); //是否顯示使用原本的計算方式？Modal
    const handleConfirmCalWayClose = () => setShowConfirmCalWayModal(false);

    const regex = /^[^\/\\\*\?\$\@]+$/;
    const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

    //#region 初始載入
    useEffect(() => {
        i18n.changeLanguage(getCookieLang() ?? "en");

        if (localStorage.getItem("selectedWorkPiece")) {
            let oldSelectedWorkPiece = JSON.parse(localStorage.getItem("selectedWorkPiece"));
            setSelectedWorkPiece(oldSelectedWorkPiece);

            let newInputQuantityCollapse = [];
            let newSelectedWorkPieceMachine = [];
            for (let obj of oldSelectedWorkPiece) {
                newInputQuantityCollapse.push(false);
                newSelectedWorkPieceMachine.push(obj && obj.objReference.arrMachineServiceCost.length > 0 ? obj.objReference.arrMachineServiceCost[0].id : "");
            }
            setInputQuantityCollapse(newInputQuantityCollapse);
            setSelectedWorkPieceMachine(newSelectedWorkPieceMachine);
        }

        if (localStorage.getItem("activeTab")) {
            let oldActiveTab = parseInt(localStorage.getItem("activeTab"));
            setActiveTab(oldActiveTab);
        }

        if (localStorage.getItem("formula")) {
            let oldFormula = localStorage.getItem("formula");
            setConfirmFormula(oldFormula);
        }
        else {
            setConfirmFormula("A");
        }
        
        let misc = getMisc();
        document.body.className = misc?.darkTheme ? "Dark" : "Light";
    }, []);
    //#endregion

    //#region 異動資料處理
    useEffect(() => {
        if (modalKeywordWorkPiece != "") {
            const fetchData = async () => {
                let apiResult = await handleApiQueryWorkPieceData();
                setModalWorkPiece(apiResult.data);
            };
            fetchData();
        }
    }, [modalKeywordWorkPiece]);

    useEffect(() => {
        const totalPriceElement = document.getElementById("totalPrice");
        if (totalPriceElement) {
            setTotalPrice(parseInt(totalPriceElement.textContent));
        }

        const totalWorkHoursElement = document.getElementById("totalWorkHours");
        if (totalWorkHoursElement) {
            setTotalWorkHours(parseInt(totalWorkHoursElement.textContent));
        }

        const totalQuantityElement = document.getElementById("totalQuantity");
        if (totalQuantityElement) {
            setTotalQuantity(parseInt(totalQuantityElement.textContent));
        }
    }, [selectedWorkPiece, confirmFormula]);

    useEffect(() => {
        const fetchData = async () => {
            let apiResult = await handleApiQueryWorkPieceData();
            if (apiResult != "500") {
                if (apiResult.statusCode === "20000") {
                    setModalWorkPiece(apiResult.data);
                }
            }
            else {
                setModalWorkPiece([]);
            }
        };

        fetchData();
    }, [modalCategoriesWorkPieceVal, modalMaterialsWorkPieceVal, modalDimensionsWorkPieceVal]);

    useEffect(() => {
        if (activeTab == "2") {
            const fetchData = async () => {
                let apiResult = await handleApiGetWorkpiece();
                setImgLastUpdateTime(apiResult.lastUpdateTime);
                setImgWorkPiece(apiResult.data);
            };
            fetchData();
        }
        else if (activeTab == "0") {
            setExcelUploadStatus(1);
        }
    }, [activeTab]);
    //#endregion

    //#region 處理關閉Modal(計算方式)
    const handleCloseModalFormula_fun = () => setShowModalFormula(false);
    //#endregion

    //#region 切換公式(上方成本估算文字)
    const handleFormula_fun = (e) => {
        setFormula(confirmFormula);
        setShowModalFormula(true);
    }
    //#endregion

    //#region 切換公式選項
    const handleFormulaItems_fun = (items) => {
        setFormula(items);

    }
    //#endregion

    //#region 切換公式Modal確認按鈕
    const handleFormulaConfirm_fun = () => {
        if (confirmFormula != formula) {
            setShowModalFormula(false);
            setShowConfirmCalWayModal(true);
        }
        else {
            setConfirmFormula(formula);
            handleCloseModalFormula_fun();

            localStorage.setItem('formula', formula);
        }
    }
    //#endregion

    //#region 處理關閉Modal(選擇工件)
    const handleCloseModalWorkPiece_fun = () => setShowModalWorkPiece(false);
    //#endregion

    //#region 選擇工件Modal確認按鈕
    const handleConfirmModalWorkPiece_fun = async (e) => {
        e.preventDefault();

        let apiResult = await handleApiWorkPieceCostBatch();

        if (apiResult == "500") {
            setModalWorkPiece([]);
            setShowModalWorkPiece(false);
        }
        else {

            let newSelectedWorkPiece = [...selectedWorkPiece];
            const concatenatedArray = newSelectedWorkPiece.concat(apiResult);
            setSelectedWorkPiece(concatenatedArray);

            localStorage.setItem('selectedWorkPiece', JSON.stringify(concatenatedArray));

            let newInputQuantityCollapse = [...inputQuantityCollapse];
            let newSelectedWorkPieceMachine = [...selectedWorkPieceMachine];

            for (let i = newInputQuantityCollapse.length; i < concatenatedArray.length; i++) {
                newInputQuantityCollapse.push(false);
                newSelectedWorkPieceMachine.push(concatenatedArray[i].objReference.arrMachineServiceCost[0].id);
            }

            setInputQuantityCollapse(newInputQuantityCollapse);
            setSelectedWorkPieceMachine(newSelectedWorkPieceMachine);

            setShowModalWorkPiece(false);
        }
    }
    //#endregion

    //#region 點選工件增加按鈕
    const handleWorkPiece_fun = async (e) => {
        setShowModalWorkPiece(true);
        setModalSelectedWorkPiece([]);
        setModalSelectedWorkPieceApi([]);
        setModalKeywordWorkPiece("");
        setModalCategoriesWorkPieceVal("");
        setModalMaterialsWorkPieceVal("");
        setModalDimensionsWorkPieceVal("");

        let apiResult = await handleApiQueryWorkPieceData();
        setModalWorkPiece(apiResult.data);

        let apiResultOptions = await handleApiWorkPieceOptions();
        if (apiResultOptions != "500") {
            setModalCategoriesWorkPiece(apiResultOptions.arrCategories);
            setModalMaterialsWorkPiece(apiResultOptions.arrMaterials);
            setModalDimensionsWorkPiece(apiResultOptions.arrSizes);


        }
        else {
            setModalCategoriesWorkPiece([]);
            setModalMaterialsWorkPiece([]);
            setModalDimensionsWorkPiece([]);
        }
    }
    //#endregion

    //#region 清除工件
    const handleClearWorkPiece_fun = () => {
        setSelectedWorkPiece([]);
        setModalSelectedWorkPieceApi([]);
        setInputQuantityCollapse([]);
        setSelectedWorkPieceMachine([]);
        setTotalPrice(0);
        setTotalWorkHours(0);
        setTotalQuantity(0);

        localStorage.removeItem('selectedWorkPiece');
    }
    //#endregion

    //#region 查詢工件
    const handleQueryWorkpiece_fun = async () => {
        let apiResult = await handleApiQueryWorkPieceData();
        if (apiResult != "500") {
            setModalWorkPiece(apiResult.data);
        }
        else {
            setModalWorkPiece([]);
        }
    }
    //#endregion

    useEffect(() => {
        handleQueryWorkpiece_fun();
    }, [modalCategoriesWorkPieceVal, modalMaterialsWorkPieceVal, modalDimensionsWorkPieceVal]);

    //#region input欄位change事件
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name == "queryWorkPiece") {
            setModalKeywordWorkPiece(value);
        }
        else if (name == "selCategories") {
            setModalCategoriesWorkPieceVal(value)
        }
        else if (name == "selMaterial") {
            setModalMaterialsWorkPieceVal(value)
        }
        else if (name == "selDimension") {
            setModalDimensionsWorkPieceVal(value)
        }
        else if (name == "selMachine") {
            setCurrentMachineId(value);

            //更改每小時設備使用費以及每小時人員費用
            // let filterMachine = selectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrMachineServiceCost.find(d => d.id == value);
            // // console.log("filterMachine", filterMachine)
            // if(filterMachine){
            //     let newHourEquipmentUsageFee = {...hourEquipmentUsageFee};
            //     let equipmentUsageFee = arrMachineServiceCost.equipmentCost;
            //     let equipmentUsageFeeSingle = Math.ceil((arrMachineServiceCost.equipmentCost / 3600) * (updateWorkPiece.durationPrePgmSec + (1 * (updateWorkPiece.durationPgmSec + updateWorkPiece.durationPgmWaitSec))));
            //     newHourEquipmentUsageFee.init = filterMachine.equipmentCost;
            //     newHourEquipmentUsageFee.format = addThousandsSeparator(filterMachine.equipmentCost);
            //     newHourEquipmentUsageFee.inputVal = addThousandsSeparator(filterMachine.equipmentCost);
            //     newHourEquipmentUsageFee.singleVal = addThousandsSeparator(filterMachine.equipmentCost);
            //     setHourEquipmentUsageFee(newHourEquipmentUsageFee);

            //     let newHourPersonnelCosts = {...hourPersonnelCosts};
            //     newHourPersonnelCosts.init = filterMachine.operatorCost;
            //     newHourPersonnelCosts.format = addThousandsSeparator(filterMachine.operatorCost);
            //     newHourPersonnelCosts.inputVal = addThousandsSeparator(filterMachine.operatorCost);
            //     newHourPersonnelCosts.singleVal = addThousandsSeparator(filterMachine.operatorCost);
            //     setHourPersonnelCosts(newHourPersonnelCosts);
            // }


            let updateWorkPiece = selectedWorkPiece[selectedModalUpdateValueIndex];
            let arrMachineServiceCost = updateWorkPiece.objReference.arrMachineServiceCost.find(d => d.id == value);

            if (arrMachineServiceCost) {
                let equipmentUsageFee = arrMachineServiceCost.equipmentCost;
                let equipmentUsageFeeSingle = Math.ceil((arrMachineServiceCost.equipmentCost / 3600) * (updateWorkPiece.durationPrePgmSec + (1 * (updateWorkPiece.durationPgmSec + updateWorkPiece.durationPgmWaitSec))));
                let newHourEquipmentUsageFee = { ...hourEquipmentUsageFee };
                newHourEquipmentUsageFee.init = equipmentUsageFee;
                newHourEquipmentUsageFee.format = addThousandsSeparator(equipmentUsageFee);
                newHourEquipmentUsageFee.inputVal = addThousandsSeparator(equipmentUsageFee);
                newHourEquipmentUsageFee.singleVal = addThousandsSeparator(equipmentUsageFeeSingle);
                setHourEquipmentUsageFee(newHourEquipmentUsageFee);

                let personnelCosts = arrMachineServiceCost.operatorCost;
                let personnelCostsSingle = Math.ceil((arrMachineServiceCost.operatorCost / 3600) * (updateWorkPiece.durationPrePgmSec + (1 * (updateWorkPiece.durationPgmSec + updateWorkPiece.durationPgmWaitSec))));
                let newHourPersonnelCosts = { ...hourPersonnelCosts };
                newHourPersonnelCosts.init = personnelCosts;
                newHourPersonnelCosts.format = addThousandsSeparator(personnelCosts);
                newHourPersonnelCosts.inputVal = addThousandsSeparator(personnelCosts);
                newHourPersonnelCosts.singleVal = addThousandsSeparator(personnelCostsSingle);
                setHourPersonnelCosts(newHourPersonnelCosts);
            }
        }
    }
    //#endregion

    //#region 勾選工件(Modal)
    const handleModalSelectedWorkPiece_Fun = (id, wpNo, pgmPdcQuantity) => {
        let needPush = true;
        if (selectedWorkPiece.length > 0) {

            if (selectedWorkPiece.find(d => d.id == id) !== undefined) {
                needPush = false;
            }
        }

        if (needPush) {
            let newModalSelectedWorkPiece = [...modalSelectedWorkPiece];
            let newModalSelectedWorkPieceApi = [...modalSelectedWorkPieceApi];

            let result = newModalSelectedWorkPiece.findIndex(d => d.id == id);
            if (result == -1) {
                let currentValue = {
                    id: id,
                    wpNo: wpNo
                };

                newModalSelectedWorkPiece.push(currentValue);
                setModalSelectedWorkPiece(newModalSelectedWorkPiece);

                newModalSelectedWorkPieceApi.push(id);
                setModalSelectedWorkPieceApi(newModalSelectedWorkPieceApi);
            }
            else {
                newModalSelectedWorkPiece.splice(result, 1);
                setModalSelectedWorkPiece(newModalSelectedWorkPiece);

                newModalSelectedWorkPieceApi.splice(result, 1);
                setModalSelectedWorkPieceApi(newModalSelectedWorkPieceApi);
            }
        }
    }
    //#endregion

    //#region 刪除工件(Modal)
    const handleModalDeleteWorkpiece_fun = (e, index) => {
        e.preventDefault();
        let newModalSelectedWorkPiece = [...modalSelectedWorkPiece];
        newModalSelectedWorkPiece.splice(index, 1);
        setModalSelectedWorkPiece(newModalSelectedWorkPiece);

        let newModalSelectedWorkPieceApi = [...modalSelectedWorkPieceApi];
        newModalSelectedWorkPieceApi.splice(index, 1);
        setModalSelectedWorkPieceApi(newModalSelectedWorkPieceApi);
    }
    //#endregion

    //#region 重新計算總成本+總工時(input改變數量)-change
    const handleCalQuantity = (e, index) => {
        const { value } = e.target;
        let newSelectedWorkPiece = [...selectedWorkPiece];
        newSelectedWorkPiece[index].intQuantity = value;
        setSelectedWorkPiece(newSelectedWorkPiece);


    }
    //#endregion

    //#region 重新計算總成本+總工時(input改變數量)-blur
    const blurCalQuantity = (e, index) => {
        const { value } = e.target;

        let newSelectedWorkPiece = [...selectedWorkPiece];
        //判斷是不是數字
        if (!isNaN(value)) {
            if (parseInt(value) > 0) {
                newSelectedWorkPiece[index].intQuantity = parseInt(value);
                setSelectedWorkPiece(newSelectedWorkPiece);

                localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
            }
            else if (parseInt(value) < 0) {
                newSelectedWorkPiece[index].intQuantity = newSelectedWorkPiece[index].pgmPdcQuantity;
                setSelectedWorkPiece(newSelectedWorkPiece);

                localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
            }
        }
        else {
            newSelectedWorkPiece[index].intQuantity = newSelectedWorkPiece[index].pgmPdcQuantity;
            setSelectedWorkPiece(newSelectedWorkPiece);

            localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
        }

        //需檢查是否納入計算
        // let calcQuantity = newSelectedWorkPiece[index].intQuantity % newSelectedWorkPiece[index].pgmPdcQuantity;
        // if(calcQuantity != 0){ //不計算

        // }

    }
    //#endregion

    //#region 減按鈕
    const minusNum = (e, index) => {
        e.preventDefault();

        let newSelectedWorkPiece = [...selectedWorkPiece];
        let currentWorkPieceQuantity = newSelectedWorkPiece[index].intQuantity;

        let calcQuantity = currentWorkPieceQuantity % newSelectedWorkPiece[index].pgmPdcQuantity;

        if (calcQuantity != 0) {
            const nearestMultiple = parseInt(currentWorkPieceQuantity / newSelectedWorkPiece[index].pgmPdcQuantity) * newSelectedWorkPiece[index].pgmPdcQuantity;
            newSelectedWorkPiece[index].intQuantity = nearestMultiple;
            setSelectedWorkPiece(newSelectedWorkPiece);

            localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
        }
        else {
            const changeValue = currentWorkPieceQuantity - newSelectedWorkPiece[index].pgmPdcQuantity;
            newSelectedWorkPiece[index].intQuantity = changeValue;
            setSelectedWorkPiece(newSelectedWorkPiece);

            localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
        }
    }
    //#endregion

    //#region 加按鈕
    const plusNum = (e, index) => {
        e.preventDefault();

        let newSelectedWorkPiece = [...selectedWorkPiece];
        let currentWorkPieceQuantity = parseInt(newSelectedWorkPiece[index].intQuantity);

        let calcQuantity = currentWorkPieceQuantity % newSelectedWorkPiece[index].pgmPdcQuantity;

        if (calcQuantity != 0) {
            const nearestMultiple = Math.ceil(currentWorkPieceQuantity / newSelectedWorkPiece[index].pgmPdcQuantity) * newSelectedWorkPiece[index].pgmPdcQuantity;
            newSelectedWorkPiece[index].intQuantity = nearestMultiple;
            setSelectedWorkPiece(newSelectedWorkPiece);

            localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
        }
        else {
            const changeValue = currentWorkPieceQuantity + newSelectedWorkPiece[index].pgmPdcQuantity;
            newSelectedWorkPiece[index].intQuantity = changeValue;
            setSelectedWorkPiece(newSelectedWorkPiece);

            localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
        }
    }
    //#endregion

    //#region 細項展開
    const detailCollapse = (e, index) => {
        let newInputQuantityCollapse = [...inputQuantityCollapse];
        newInputQuantityCollapse[index] = !newInputQuantityCollapse[index];
        setInputQuantityCollapse(newInputQuantityCollapse);
    }
    //#endregion

    //#region 刪除工件Modal
    const handleDelWorkpiece_fun = (id, price, workHours, quantity) => {
        setShowModalDelWorkpiece(true);
        setShowModalDelWorkpieceId(id);

        setCurrentotalPrice(parseInt(price));
        setCurrentTotalWorkHours(workHours);
        setCurrentTotalQuantity(parseInt(quantity));
    }
    //#endregion

    //#region 處理關閉Modal(取消按鈕)
    const handleDelWorkpieceCancel_fun = () => setShowModalDelWorkpiece(false);
    //#endregion

    //#region 確定刪除工件
    const handleDelWorkpieceConfirm_fun = () => {
        let newSelectedWorkPiece = [...selectedWorkPiece];
        let newSelectedWorkPieceMachine = [...selectedWorkPieceMachine];
        let result = newSelectedWorkPiece.findIndex(d => d.id == showModalDelWorkpieceId);
        if (result != -1) {
            newSelectedWorkPiece.splice(result, 1);
            setSelectedWorkPiece(newSelectedWorkPiece);

            newSelectedWorkPieceMachine.splice(result, 1);
            setSelectedWorkPieceMachine(newSelectedWorkPieceMachine);
        }

        setTotalPrice(totalPrice - currentTotalPrice);
        setTotalWorkHours(totalWorkHours - currentTotalWorkHours);
        setTotalQuantity(totalQuantity - currentTotalQuantity);


        localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));

        handleDelWorkpieceCancel_fun();
    }
    //#endregion

    //#region 處理關閉Modal(修改數值)
    const handleCloseModalUpdateValue_fun = () => setShowModalUpdateValue(false);
    //#endregion

    //#region 修改數值
    const handleUpdateValue_fun = async (e, index, toolData) => {
        e.preventDefault();
        setSelectedModalUpdateValueIndex(index);

        let updateWorkPiece = selectedWorkPiece[index];
        let arrMachineServiceCost = updateWorkPiece.objReference.arrMachineServiceCost.find(d => d.id == selectedWorkPieceMachine[index]);

        if (arrMachineServiceCost) {
            let equipmentUsageFee = arrMachineServiceCost.equipmentCost;
            let equipmentUsageFeeSingle = Math.ceil((arrMachineServiceCost.equipmentCost / 3600) * (updateWorkPiece.durationPrePgmSec + (1 * (updateWorkPiece.durationPgmSec + updateWorkPiece.durationPgmWaitSec))));
            let newHourEquipmentUsageFee = { ...hourEquipmentUsageFee };
            newHourEquipmentUsageFee.init = equipmentUsageFee;
            newHourEquipmentUsageFee.format = addThousandsSeparator(equipmentUsageFee);
            newHourEquipmentUsageFee.inputVal = addThousandsSeparator(equipmentUsageFee);
            newHourEquipmentUsageFee.singleVal = addThousandsSeparator(equipmentUsageFeeSingle);
            setHourEquipmentUsageFee(newHourEquipmentUsageFee);

            let personnelCosts = arrMachineServiceCost.operatorCost;
            let personnelCostsSingle = Math.ceil((arrMachineServiceCost.operatorCost / 3600) * (updateWorkPiece.durationPrePgmSec + (1 * (updateWorkPiece.durationPgmSec + updateWorkPiece.durationPgmWaitSec))));
            let newHourPersonnelCosts = { ...hourPersonnelCosts };
            newHourPersonnelCosts.init = personnelCosts;
            newHourPersonnelCosts.format = addThousandsSeparator(personnelCosts);
            newHourPersonnelCosts.inputVal = addThousandsSeparator(personnelCosts);
            newHourPersonnelCosts.singleVal = addThousandsSeparator(personnelCostsSingle);
            setHourPersonnelCosts(newHourPersonnelCosts);

            setCurrentMachineId(arrMachineServiceCost.id);
        }

        if (updateWorkPiece.objReference.arrMaterialCost.length > 0) {
            let materialCost = updateWorkPiece.objReference.arrMaterialCost[0].price;
            let newMaterialCosts = { ...materialCosts };
            newMaterialCosts.init = materialCost;
            newMaterialCosts.format = addThousandsSeparator(materialCost * updateWorkPiece.pgmPdcQuantity);
            newMaterialCosts.inputVal = addThousandsSeparator(materialCost);
            setMaterialCosts(newMaterialCosts);

            setCurrentMachineId("");
        }


        let toolIndex = 0;
        for (let item of toolData) {
            toolData[toolIndex].stringPrice = addThousandsSeparator(Math.ceil(item.price * item.quantity * 10) / 10);
            toolIndex++;
        }

        setUpdateValueTools(toolData);
        setShowModalUpdateValue(true);
    }
    //#endregion

    //#region 修改數值(input改變數量)-change
    const handleCalAdjustingCost = (e, index) => {
        const { name, value } = e.target;

        if (name == "hourEquipmentUsageFee") {
            let newHourEquipmentUsageFee = { ...hourEquipmentUsageFee };

            newHourEquipmentUsageFee.inputVal = value;
            setHourEquipmentUsageFee(newHourEquipmentUsageFee);
        }
        else if (name == "hourPersonnelCosts") {
            let newHourPersonnelCosts = { ...hourPersonnelCosts };
            newHourPersonnelCosts.inputVal = value;
            setHourPersonnelCosts(newHourPersonnelCosts);
        }
        else if (name == "materialCosts") {
            let newMaterialCosts = { ...materialCosts };
            newMaterialCosts.inputVal = value;
            setMaterialCosts(newMaterialCosts);
        }
        else if (name == "updateValueTools") {
            let newUpdateValueTools = [...updateValueTools];
            newUpdateValueTools[index].inputVal = value;
            setUpdateValueTools(newUpdateValueTools);
        }
        else if (name == "tempPdfFileName") {
            setTempPdfFileName(value);
        }
    }
    //#endregion

    //#region 修改數值(input改變數量)-blur
    const blurCalAdjustingCost = (e, index) => {
        const { name, value } = e.target;
        let currentValue = parseInt(removeThousandsSeparator(value));

        if (name == "hourEquipmentUsageFee") {
            let newHourEquipmentUsageFee = { ...hourEquipmentUsageFee };
            if (!isNaN(removeThousandsSeparator(value))) {
                if (currentValue > 0) {
                    newHourEquipmentUsageFee.init = currentValue;
                    newHourEquipmentUsageFee.format = addThousandsSeparator(currentValue);
                    newHourEquipmentUsageFee.inputVal = addThousandsSeparator(currentValue);

                    let updateWorkPiece = selectedWorkPiece[selectedModalUpdateValueIndex];
                    let equipmentUsageFeeSingle = 0;
                    if (updateWorkPiece.durationPrePgmSec != null && updateWorkPiece.durationPgmSec != null && updateWorkPiece.durationPgmWaitSec != null) {
                        equipmentUsageFeeSingle = Math.ceil((currentValue / 3600) * (updateWorkPiece.durationPrePgmSec + (1 * (updateWorkPiece.durationPgmSec + updateWorkPiece.durationPgmWaitSec))));
                    }

                    newHourEquipmentUsageFee.singleVal = addThousandsSeparator(equipmentUsageFeeSingle);

                }
                else {
                    newHourEquipmentUsageFee.inputVal = newHourEquipmentUsageFee.format;
                }
            }
            else {
                newHourEquipmentUsageFee.inputVal = newHourEquipmentUsageFee.format;
            }
            setHourEquipmentUsageFee(newHourEquipmentUsageFee);
        }
        else if (name == "hourPersonnelCosts") {
            let newHourPersonnelCosts = { ...hourPersonnelCosts };
            if (!isNaN(removeThousandsSeparator(value))) {
                if (currentValue > 0) {
                    newHourPersonnelCosts.init = currentValue;
                    newHourPersonnelCosts.format = addThousandsSeparator(currentValue);
                    newHourPersonnelCosts.inputVal = addThousandsSeparator(currentValue);

                    let updateWorkPiece = selectedWorkPiece[selectedModalUpdateValueIndex];
                    let personnelCostsSingle = 0;

                    if (updateWorkPiece.durationPrePgmSec != null && updateWorkPiece.durationPgmSec != null && updateWorkPiece.durationPgmWaitSec != null) {
                        personnelCostsSingle = Math.ceil((currentValue / 3600) * (updateWorkPiece.durationPrePgmSec + (1 * (updateWorkPiece.durationPgmSec + updateWorkPiece.durationPgmWaitSec))));
                    }

                    newHourPersonnelCosts.singleVal = addThousandsSeparator(personnelCostsSingle);
                }
                else {
                    newHourPersonnelCosts.inputVal = newHourPersonnelCosts.format;
                }
            }
            else {
                newHourPersonnelCosts.inputVal = newHourPersonnelCosts.format;
            }
            setHourPersonnelCosts(newHourPersonnelCosts);
        }
        else if (name == "materialCosts") {
            let newMaterialCosts = { ...materialCosts };
            if (!isNaN(removeThousandsSeparator(value))) {
                if (currentValue > 0) {
                    newMaterialCosts.init = currentValue;

                    let updateWorkPiece = selectedWorkPiece[selectedModalUpdateValueIndex];
                    newMaterialCosts.format = addThousandsSeparator(currentValue * updateWorkPiece.pgmPdcQuantity);
                    newMaterialCosts.inputVal = addThousandsSeparator(currentValue);
                }
                else {
                    newMaterialCosts.inputVal = newMaterialCosts.format;
                }
            }
            else {
                newMaterialCosts.inputVal = newMaterialCosts.format;
            }
            setMaterialCosts(newMaterialCosts);
        }
        else if (name == "updateValueTools") {
            let newUpdateValueTools = [...updateValueTools];
            if (!isNaN(removeThousandsSeparator(value))) {
                if (currentValue > 0) {
                    newUpdateValueTools[index].price = currentValue;
                    newUpdateValueTools[index].stringPrice = addThousandsSeparator(Math.ceil(currentValue * newUpdateValueTools[index].quantity * 10) / 10);
                    newUpdateValueTools[index].inputVal = addThousandsSeparator(currentValue);
                }
                else {
                    newUpdateValueTools[index].inputVal = newUpdateValueTools[index].format;
                }
            }
            else {
                newUpdateValueTools[index].inputVal = newUpdateValueTools[index].format;
            }
            setUpdateValueTools(newUpdateValueTools);
        }
        else if (name == "tempPdfFileName") {
            setTempPdfFileName(value);
        }
    }
    //#endregion

    //#region 修改數值Modal確認按鈕
    const handleConfirmModalWorkPieceUpdateValue_fun = (e) => {
        e.preventDefault();

        let newSelectedWorkPiece = [...selectedWorkPiece];
        let updateWorkPiece = newSelectedWorkPiece[selectedModalUpdateValueIndex];
        let newCurrentMachineId = currentMachineId;
        if (newCurrentMachineId === "") {
            newCurrentMachineId = updateWorkPiece.objReference.arrMachineServiceCost[0].id;
            setCurrentMachineId(newCurrentMachineId);
        }


        let filterArrMachineServiceCostIndex = updateWorkPiece.objReference.arrMachineServiceCost.findIndex(d => d.id == newCurrentMachineId);
        if (filterArrMachineServiceCostIndex != -1) {
            newSelectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrMachineServiceCost[filterArrMachineServiceCostIndex].equipmentCost = hourEquipmentUsageFee.init;
            newSelectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrMachineServiceCost[filterArrMachineServiceCostIndex].operatorCost = hourPersonnelCosts.init;
        }
        else {
            if (updateWorkPiece.objReference.arrMachineServiceCost.length > 0) {
                updateWorkPiece.objReference.arrMachineServiceCost[0].equipmentCost = hourEquipmentUsageFee.init;
                updateWorkPiece.objReference.arrMachineServiceCost[0].operatorCost = hourPersonnelCosts.init;
            }
            else {
                updateWorkPiece.objReference.arrMachineServiceCost.push({
                    equipmentCost: hourEquipmentUsageFee.init,
                    operatorCost: hourPersonnelCosts.init
                });
            }
        }

        if (newSelectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrMaterialCost.length > 0) {
            newSelectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrMaterialCost[0].price = materialCosts.init;
        }
        else {
            updateWorkPiece.objReference.arrMaterialCost.push({
                price: materialCosts.init
            });
        }

        if (updateWorkPiece.objReference.arrToolCost) {
            for (let i = 0; i < updateValueTools.length; i++) {
                newSelectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrToolCost[i].unitPrice = updateValueTools[i].price;
            }
        }


        let newSelectedWorkPieceMachine = [...selectedWorkPieceMachine];
        newSelectedWorkPieceMachine[selectedModalUpdateValueIndex] = newCurrentMachineId;
        setSelectedWorkPieceMachine(newSelectedWorkPieceMachine);


        setSelectedWorkPiece(newSelectedWorkPiece);
        localStorage.setItem('selectedWorkPiece', JSON.stringify(newSelectedWorkPiece));
        setShowModalUpdateValue(false);
    }
    //#endregion

    //#region API部分
    //工件列表(不包含細項)
    const handleApiQueryWorkPieceData = async () => {
        let param = {
            intLimit: 30,
            intOffset: 0
        };

        if (modalKeywordWorkPiece != "") {
            param = {
                arrQuery: [
                    {
                        "colname": "keyword",
                        "op": "like",
                        "value": modalKeywordWorkPiece
                    }
                ],
                intLimit: 30,
                intOffset: 0
            };
        }

        const newParam = { ...param };

        if (modalCategoriesWorkPieceVal !== "") {
            newParam.arrQuery = [
                ...newParam.arrQuery || [], // 复制旧的 arrQuery 属性或创建一个新数组
                {
                    colname: "wpCategoryId",
                    op: "==",
                    value: parseInt(modalCategoriesWorkPieceVal)
                }
            ];
        }

        if (modalMaterialsWorkPieceVal !== "") {
            newParam.arrQuery = [
                ...newParam.arrQuery || [],
                {
                    colname: "mtType01Id",
                    op: "==",
                    value: parseInt(modalMaterialsWorkPieceVal)
                }
            ];
        }

        if (modalDimensionsWorkPieceVal !== "") {
            newParam.arrQuery = [
                ...newParam.arrQuery || [],
                {
                    colname: "size",
                    op: "==",
                    value: modalDimensionsWorkPieceVal
                }
            ];
        }

        param = newParam;
        let queryWorkPieceDataResponse = await apiQueryWorkPieceData(param);
        if (queryWorkPieceDataResponse) {
            if (queryWorkPieceDataResponse.request.status == 200) {
                if (queryWorkPieceDataResponse.data.statusCode == "20000") {
                    return queryWorkPieceDataResponse.data.data;
                }
                else {
                    return "500";
                }
            }
            //後續須處理500
        }
        else {
            return "500";
        }
    }

    //下拉選單篩選(類別、材質以及尺寸)
    const handleApiWorkPieceOptions = async () => {
        let workPieceOptionsResponse = await apiWorkPieceOptions();
        if (workPieceOptionsResponse) {
            if (workPieceOptionsResponse.request.status == 200) {
                if (workPieceOptionsResponse.data.statusCode == "20000") {
                    return workPieceOptionsResponse.data.data;
                }
            }
            //後續須處理500
        }
        else {
            return "500";
        }

    }

    //#regin 工件列表(包含細項)
    const handleApiWorkPieceCostBatch = async () => {
        let param = {
            id: modalSelectedWorkPieceApi,
            intLimit: 30,
            intOffset: 0
        };

        let workPieceCostBatchResponse = await apiWorkPieceCostBatch(param);
        if (workPieceCostBatchResponse) {
            if (workPieceCostBatchResponse.request.status == 200) {
                if (workPieceCostBatchResponse.data.statusCode == "20000") {
                    return workPieceCostBatchResponse.data.data;
                }
            }
            //後續須處理500
        }
        else {
            return "500";
        }
    }
    //#endregion

    //#regin 上傳Excel
    const handleApiSpreadsheet = async (formData) => {
        let param = formData;

        let spreadsheetResponse = await apiSpreadsheet(param);

        if (spreadsheetResponse) {

            if (spreadsheetResponse.request.status == 200) {
                if (spreadsheetResponse.data.statusCode == "20000") {
                    return spreadsheetResponse.data.data;
                }
            }
            //後續須處理500
        }
        else {
            return "500";
        }
    }
    //#endregion

    //#regin 上傳圖片(預載)
    const handleApiGetWorkpiece = async () => {
        let getWorkpieceResponse = await apiGetWorkpiece();
        if (getWorkpieceResponse) {
            if (getWorkpieceResponse.request.status == 200) {
                if (getWorkpieceResponse.data.statusCode == "20000") {
                    return getWorkpieceResponse.data.data;
                }
            }
            //後續須處理500
        }
        else {
            return "500";
        }
    }
    //#endregion

    const handleApiUploadImage = async (formData) => {
        let param = formData;

        let uploadImageResponse = await apiUploadImage(param);
        if (uploadImageResponse) {
            if (uploadImageResponse.request.status == 200) {
                if (uploadImageResponse.data.statusCode == "20000") {
                    return uploadImageResponse.data.data;
                }
            }
            //後續須處理500
        }
        else {
            return "500";
        }
    }

    //#endregion

    //#region 切換頁籤
    const changeTab = (e, index) => {
        e.preventDefault();

        setActiveTab(index);
        localStorage.setItem('activeTab', index);

        let newActiveTabCSS = [...activeTabCSS];
        for (let i = 0; i < newActiveTabCSS.length; i++) {
            if (i == index) {
                newActiveTabCSS[i].fill = 1;
            }
            else {
                newActiveTabCSS[i].fill = 0;
            }
        }

        setActiveTabCSS(newActiveTabCSS);
    }
    //#endregion

    //#region 上傳Excel按鈕
    const uploadExcel = (e) => {
        e.preventDefault();
        fileInputRef.current.value = '';
        fileInputRef.current.click();
    }
    //#endregion

    //#region 上傳Excel Change事件
    const handleFileChangeExcel = async (e) => {
        if (e.target.files.length > 0) {
            const selectedFile = e.target.files[0];

            const allowedExtensions = /(\.xls|\.xlsx)$/i;

            if (allowedExtensions.exec(selectedFile.name)) {
                let newFormDataUploadExcel = new FormData();
                newFormDataUploadExcel.append('file', selectedFile);


                let result = await handleApiSpreadsheet(newFormDataUploadExcel);

                if (result.status == "Success") {
                    setExcelUploadStatus("4");
                    setExcelUploadResult(result.detail);
                }
                else if (result.status == "Sheet Error") {
                    setExcelUploadStatus("2");
                    setExcelUploadResult(result.detail);
                }
                else {
                    setExcelUploadStatus("3");
                    setExcelUploadResult(result.detail);

                }
            }
            else {
                alert("請選擇一個 xls 或 xlsx 檔案");
            }
        }
        else {
            // setExcelUploadStatus(6);
            alert("至少選擇一個xls 或 xlsx 檔案");
        }
    };
    //#endregion

    //#region 動態創建刀具列表的物件
    const createInputRef = (index) => (element) => {
        toolRefs.current[index] = element;
    };
    //#endregion

    //#region 觸發搜尋按鈕
    const handleKeyPress = (e, name, index) => {
        if (e.keyCode === 13) {
            if (showModalWorkPiece) {
                SearchInputRef.current.click();
            }
            else if (name == "hourEquipmentUsageFee") { //每小時設備使用費
                if (hourEquipmentUsageFeeRef.current) {
                    hourEquipmentUsageFeeRef.current.blur();
                }
            }
            else if (name == "hourPersonnelCosts") { //每小時人員費用
                if (hourPersonnelCostsRef.current) {
                    hourPersonnelCostsRef.current.blur();
                }
            }
            else if (name == "materialCosts") { //單顆素材費用
                if (materialCostsRef.current) {
                    materialCostsRef.current.blur();
                }
            }
            else if (name == "updateValueTools") { //刀具
                if (toolRefs.current) {
                    toolRefs.current[index].blur();
                }
            }
        }
    }
    //#endregion

    //#region 點選輸入框一下的時候要全選
    const selectAllText = (e, name, index) => {
        if (name == "hourEquipmentUsageFee") { //每小時設備使用費
            if (hourEquipmentUsageFeeRef.current) {
                hourEquipmentUsageFeeRef.current.select();
            }
        }
        else if (name == "hourPersonnelCosts") { //每小時人員費用
            if (hourPersonnelCostsRef.current) {
                hourPersonnelCostsRef.current.select();
            }
        }
        else if (name == "materialCosts") { //單顆素材費用
            if (materialCostsRef.current) {
                materialCostsRef.current.select();
            }
        }
        else if (name == "updateValueTools") { //刀具
            if (toolRefs.current) {
                toolRefs.current[index].select();
            }
        }
    };
    //#endregion

    //#region 下載檔案
    const handleDownload = () => {
        const url = '/images/workpiece/Calculator_Excel_Template.xlsx';
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Calculator_Excel_Template.xlsx';
        link.click();
    };
    //#endregion

    //#region 上傳圖片
    const uploadImage = (e) => {
        e.preventDefault();
        fileInputImageRef.current.value = '';
        fileInputImageRef.current.click();
    }
    //#endregion

    //#region 上傳Image Change事件
    const handleFileChangeImage = async (e) => {
        if (e.target.files.length > 0) {
            let newImgUploadErr = [];
            let uploadImgFormData = new FormData();
            let uploadNumber = 0;

            for (const element of e.target.files) {
                const selectedFile = element;
                const fileNameWithoutExtension = selectedFile.name.split('.')[0]; // 去除副檔名

                //檢查有沒有這個工件名稱
                let filterImg = modalWorkPiece.find(d => d.wpNo == fileNameWithoutExtension);
                let err = [];

                if (filterImg == undefined) {
                    err.push("找不到與這個圖檔名稱相同的工件代號。");
                }

                const allowedExtensions = /(\.png|\.jpg|\.jpeg|\.gif)$/i;
                if (!allowedExtensions.exec(selectedFile.name)) {
                    err.push("不是系統支援的圖檔格式。");
                }

                const maxSize = 3 * 1024 * 1024; // 3MB的大小（以位元組為單位）
                if (selectedFile.size > maxSize) {
                    err.push("圖片大小超過3MB");
                }

                if (err.length > 0) {
                    newImgUploadErr.push({
                        fileName: selectedFile.name,
                        err: err
                    });
                }
                else {
                    uploadImgFormData.append('files', selectedFile);
                    uploadNumber++;
                }
            }

            setImgUploadErr(newImgUploadErr);

            if (newImgUploadErr.length == e.target.files.length) {
                setImgUploadStatus(6);
                setImgUploadErrDisplay(true);
                setImgUploadSuccessDisplay(false);
            }
            else {
                if (uploadNumber > 0) {
                    let result = await handleApiUploadImage(uploadImgFormData);
                    if (e.target.files.length == result.data.length) {
                        setImgUploadStatus(4);
                        setImgUploadErrDisplay(false);
                        setImgUploadSuccessDisplay(true);
                    }
                    else {
                        setImgUploadStatus(5);
                        setImgUploadErrDisplay(true);
                        setImgUploadSuccessDisplay(false);
                    }

                    //需要蓋掉原本存在本機端圖片路徑
                    let oldSelectedWorkPiece = JSON.parse(localStorage.getItem("selectedWorkPiece"));
                    for (let i = 0; i < result.data.length; i++) {
                        let filterIndex = oldSelectedWorkPiece.findIndex(d => d.wpNo == result.data[i].wpNo && d.wpName == result.data[i].wpName);
                        if (filterIndex != -1) {
                            oldSelectedWorkPiece[filterIndex].images = result.data[i].images;
                        }
                    }
                    localStorage.setItem("selectedWorkPiece", JSON.stringify(oldSelectedWorkPiece));
                    setSelectedWorkPiece(oldSelectedWorkPiece);

                    let apiResult = await handleApiGetWorkpiece();
                    setImgLastUpdateTime(apiResult.lastUpdateTime);
                    setImgWorkPiece(apiResult.data);
                }
            }
        }
        else {
            alert("至少選擇一個png、 jpg、 jpeg、gif 檔案");
        }
    }
    //#endregion

    //#region 關掉錯誤訊息卡片
    const closeErrCard = () => {
        setImgUploadErrDisplay(false);
    }
    //#endregion

    //#region 關掉成功訊息卡片
    const closeSuccessCard = () => {
        setImgUploadSuccessDisplay(false);
    }
    //#endregion

    //#region 下載按鈕
    const handleDownloadPDF_fun = () => {
        setJudgeCheckErr(false);
        setShowModalPDFfileName(true);
        setTempPdfFileName(`Untitled_${getNowDateTime()}`);
    }
    //#endregion

    //#region 處理檔案名稱關閉Modal(取消按鈕)
    const handlePDFfileNameCancel_fun = () => setShowModalPDFfileName(false);
    //#endregion

    //#region 處理檔案名稱關閉Modal(確認按鈕)
    const handleConfirmDownloadPDF_fun = () => {
        setJudgeCheckErr(true);

        if (regex.test(tempPdfFileName?.trim()) && pdfDownload.current) {
            pdfDownload.current.click();
            setShowModalPDFfileName(false);
        }
    }
    //#endregion

    //#region 處理檔案名稱關閉Modal(取消按鈕)
    const handlefileNameTemplate_fun = () => setShowModalFileNameTemplate(false);
    //#endregion

    //#region 確認計算方式-確認按鈕
    const handleConfirmCalWay = () => {
        setConfirmFormula(formula);
        handleConfirmCalWayClose();
        // handleCloseModalFormula_fun();

        localStorage.setItem('formula', formula);
    }
    //#endregion

    //#region 確認計算方式-返回按鈕
    const handleReturnCalWay = () => {
        setShowModalFormula(true);
    }
    //#endregion

    return (
        <>
            {/*計算機頁面 - start*/}
            {
                activeTab === 0 ?
                    <Container fluid className='my-3'>
                        <div className='fixed-header'>
                            <Row>
                                <Col>
                                    <Card className='card-total'>
                                        <Card.Body>
                                            <Row>
                                                <Col sm={12} xs={12} md={9} lg={9} xl={10} xxl={10}>
                                                    <Row>
                                                        <Col>
                                                            <p className='text-grey8'>{t("costEstimation")}:&nbsp;<span className='cursor-pointer' onClick={(e) => handleFormula_fun(e)}>({t("adoptingFormula", { e: confirmFormula })})&nbsp;<FontAwesomeIcon icon={faCircleInfo} className='text-dark' /></span></p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='text-grey4 font-weight-bolder'>
                                                            <h2><b>$&nbsp;{addThousandsSeparator(totalPrice)}</b></h2>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col>
                                                            <p className='text-grey8 mb-0'>{t("productionTotal")}: {totalQuantity}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <p className='text-grey8'>{t("estimatedWorkHours")}: {formatTime(totalWorkHours)}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {/*  */}
                                                <Col sm={12} xs={12} md={3} lg={3} xl={2} xxl={2} className='d-none d-md-block d-flex justify-content-end align-items-center mt-4'>
                                                    <Row md={12} lg={12} xl={12} xxl={12} className='px-0'>
                                                        <Col className='pe-0'>
                                                            <button className='btn btn-lv4 w-100 h38' onClick={handleClearWorkPiece_fun}>{t("clear")}</button>
                                                        </Col>
                                                        <Col className='ps-1'>
                                                            <button className='btn btn-lv4 w-100 h38' onClick={handleWorkPiece_fun}>{t("add")}</button>
                                                        </Col>
                                                    </Row>

                                                    <Row md={12} lg={12} xl={12} xxl={12}>
                                                        <Col className='mt-2'>
                                                            {
                                                                totalPrice == 0 && totalQuantity == 0 && totalWorkHours == 0 ?
                                                                    <button className='btn btn-lv1 w-100' disabled={true}>
                                                                        <span className="material-symbols-rounded download">download</span>{t("export")}
                                                                    </button> :
                                                                    <button className='btn btn-lv1 w-100' onClick={handleDownloadPDF_fun}>
                                                                        <span className="material-symbols-rounded download">download</span>{t("export")}
                                                                    </button>
                                                            }

                                                            {/* <button className='button-primary w-100 d-flex align-items-center justify-content-center' onClick={handleExportWorkPiece_fun}>
                                                                <span className="material-symbols-rounded download">download</span>{t("export")}
                                                            </button> */}
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col sm={12} xs={12} md={3} lg={3} xl={1} xxl={1} className='d-sm-block d-md-none'>
                                                    <Row>
                                                        <Col sm={3} xs={3} md={3} lg={3} xl={3} xxl={3} className='pe-0'>
                                                            <button className='btn btn-lv4 w-100 h38' onClick={handleClearWorkPiece_fun}>{t("clear")}</button>
                                                        </Col>
                                                        <Col sm={3} xs={3} md={3} lg={3} xl={3} xxl={3} className='ps-2 pe-1'>
                                                            <button className='btn btn-lv4 w-100 h38' onClick={handleWorkPiece_fun}>{t("add")}</button>
                                                        </Col>
                                                        <Col sm={6} xs={6} md={6} lg={6} xl={6} xxl={6} className='ps-1'>
                                                            {
                                                                totalPrice == 0 && totalQuantity == 0 && totalWorkHours == 0 ?
                                                                    <button className='btn btn-lv1 w-100' disabled={true}>
                                                                        <span className="material-symbols-rounded download">download</span>{t("export")}
                                                                    </button> :
                                                                    <button className='btn btn-lv1 w-100' onClick={handleDownloadPDF_fun}>
                                                                        <span className="material-symbols-rounded download">download</span>{t("export")}
                                                                    </button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div className="fixed-header-overlay"></div>

                        <div className='workPieceContent'>
                            <Row className='mt-3' >
                                {(() => {
                                    let workpieceResult = [];

                                    let totalPrice = 0; //總金額
                                    let totalWorkHours = 0; //預估工時
                                    let totalQuantity = 0;  //製作總數

                                    if (selectedWorkPiece.length > 0) {
                                        for (let i = 0; i < selectedWorkPiece.length; i++) {
                                            let obj = selectedWorkPiece[i]; //obj選擇工件列表

                                            let totalFee = 0; //單顆成本
                                            let batchCost = 0; //批量成本

                                            let equipmentCost = 0; //設備使用費(單顆)
                                            let operatorCost = 0; //人員費用(單顆)
                                            let materialFee = 0; //素材費用(單顆)

                                            let equipmentCostMultiple = 0; //設備使用費(批量)
                                            let operatorCostMultiple = 0; //人員費用(批量)
                                            let materialFeeMultiple = 0; //素材費用(批量)

                                            let singleToolCost = 0;  //刀具費用
                                            let singleToolCostBulk = 0; //刀具費用(不算批量次數)

                                            let arr = obj.objReference.arrToolCost; //API刀具列表
                                            let toolListResult = []; //顯示下方刀具列表
                                            let singleToolList = []; //刀具列表
                                            let productionDuration = 0; //製作時長(秒數顯示)
                                            let multiple = 0; //倍數

                                            let toolQuantity = 0; //刀具數量

                                            //輸入數量輸入框CSS錯誤
                                            let errCss = "";
                                            let calcQuantity = isNullCal(obj.intQuantity) % isNullCal(obj.pgmPdcQuantity);
                                            if (obj.intQuantity == 0) {
                                                errCss = "warning";
                                            }
                                            else if (calcQuantity != 0) {
                                                errCss = "alert";
                                            }

                                            if (errCss == "") {
                                                multiple = isNullCal(obj.intQuantity) / isNullCal(obj.pgmPdcQuantity);
                                                productionDuration = isNullCal(obj.durationPrePgmSec) + (multiple * (isNullCal(obj.durationPgmSec) + isNullCal(obj.durationPgmWaitSec)));
                                                totalQuantity += isNullCal(obj.intQuantity);
                                            }

                                            if (arr.length > 0) {
                                                //刀具費用
                                                for (let j = 0; j < arr.length; j++) {
                                                    let quantity = 1; //計算數量(不是刀具用的)
                                                    let singleToolQuantity = 1; //刀具數量
                                                    let singleToolQuantityNotBulk = 1; //刀具數量(不算批量次數)
                                                    let singleToolFee = 0;
                                                    let singleToolFeeNotBulk = 0;

                                                    if (isNullCal(obj.pgmPdcQuantity) == 1) {
                                                        if (isNullCal(parseFloat(arr[j].pgtaPercentage)) != 0) {
                                                            quantity = isNullCal(arr[j].pgtaPercentage) * isNullCal(obj.pgmPdcQuantity);

                                                            let quotient = isNullCal(obj.intQuantity) / isNullCal(obj.pgmPdcQuantity);
                                                            singleToolQuantity = isNullCal(arr[j].pgtaPercentage) * quotient;

                                                            if (confirmFormula != "C") { //數量要進位
                                                                quantity = Math.ceil(quantity);
                                                                singleToolQuantity = Math.ceil(singleToolQuantity);
                                                            }
                                                        }
                                                        else {
                                                            if (confirmFormula == "B") {
                                                                quantity = 0;
                                                                singleToolQuantity = 0;
                                                            }
                                                            else if (confirmFormula == "C") {
                                                                quantity = 0.001;
                                                                singleToolQuantity = 0.001;
                                                            }
                                                        }

                                                        singleToolQuantity = parseFloat(singleToolQuantity.toFixed(3));
                                                        singleToolFee = Math.ceil(quantity * isNullCal(arr[j].unitPrice));
                                                        totalFee += singleToolFee;

                                                        toolQuantity += singleToolQuantity;

                                                        singleToolList.push({
                                                            toolName: arr[j].ttNo,
                                                            quantity: singleToolQuantity,
                                                            price: arr[j].unitPrice,
                                                            stringPrice: addThousandsSeparator(singleToolFee),
                                                            inputVal: addThousandsSeparator(arr[j].unitPrice)
                                                        });

                                                        singleToolFee = Math.ceil(singleToolQuantity * isNullCal(arr[j].unitPrice));
                                                        batchCost += singleToolFee;
                                                    }
                                                    else {

                                                        if (isNullCal(parseFloat(arr[j].pgtaPercentage)) != 0) {
                                                            quantity = isNullCal(arr[j].pgtaPercentage) * isNullCal(obj.intQuantity);

                                                            let quotient = isNullCal(obj.intQuantity) / isNullCal(obj.pgmPdcQuantity);
                                                            singleToolQuantity = isNullCal(arr[j].pgtaPercentage) * quotient;
                                                            singleToolQuantityNotBulk = isNullCal(arr[j].pgtaPercentage);

                                                            if (confirmFormula != "C") { //數量要進位
                                                                quantity = Math.ceil(quantity);
                                                                singleToolQuantity = Math.ceil(singleToolQuantity);
                                                                singleToolQuantityNotBulk = Math.ceil(singleToolQuantityNotBulk);
                                                            }
                                                        }
                                                        else {
                                                            if (confirmFormula == "B") {
                                                                quantity = 0;
                                                                singleToolQuantity = 0;
                                                                singleToolQuantityNotBulk = 0;
                                                            }
                                                            else if (confirmFormula == "C") {
                                                                quantity = 0.001;
                                                                singleToolQuantity = 0.001;
                                                                singleToolQuantityNotBulk = 0.001;
                                                            }
                                                        }

                                                        singleToolQuantity = parseFloat(singleToolQuantity.toFixed(3));
                                                        singleToolFee = Math.ceil(singleToolQuantity * isNullCal(arr[j].unitPrice));
                                                        singleToolFeeNotBulk = Math.ceil(singleToolQuantityNotBulk * isNullCal(arr[j].unitPrice));
                                                        totalFee += singleToolFee;
                                                        singleToolCostBulk += singleToolFeeNotBulk;

                                                        singleToolList.push({
                                                            toolName: arr[j].ttNo,
                                                            quantity: singleToolQuantity,
                                                            price: arr[j].unitPrice,
                                                            stringPrice: addThousandsSeparator(singleToolFee),
                                                            inputVal: addThousandsSeparator(arr[j].unitPrice)
                                                        });

                                                        toolQuantity += singleToolQuantity;
                                                    }

                                                    toolListResult.push(
                                                        <Row className='mb-1' key={j}>
                                                            <Col xs={7}>
                                                                {arr[j].ttNo}&nbsp;<span className='text-greyA'>x{singleToolQuantity}</span>
                                                            </Col>
                                                            <Col xs={5} className='d-flex justify-content-end'>
                                                                $&nbsp;{addThousandsSeparator(singleToolFee)}
                                                            </Col>
                                                        </Row>
                                                    );
                                                }
                                            }

                                            let arrMachineServiceCost = obj.objReference.arrMachineServiceCost.find(d => d.id == selectedWorkPieceMachine[i]);


                                            if (obj.objReference.arrMaterialCost.length > 0) {
                                                materialFee = obj.objReference.arrMaterialCost[0].price * isNullCal(obj.pgmPdcQuantity);
                                            }

                                            if (arrMachineServiceCost) {
                                                equipmentCost = Math.ceil((isNullCal(arrMachineServiceCost.equipmentCost) / 3600) * (isNullCal(obj.durationPrePgmSec) + (1 * (isNullCal(obj.durationPgmSec) + isNullCal(obj.durationPgmWaitSec)))));
                                                operatorCost = Math.ceil((isNullCal(arrMachineServiceCost.operatorCost) / 3600) * (isNullCal(obj.durationPrePgmSec) + (1 * (isNullCal(obj.durationPgmSec) + isNullCal(obj.durationPgmWaitSec)))));
                                            }
                                            else {
                                                if (obj.objReference.arrMachineServiceCost.length > 0) {
                                                    equipmentCost = Math.ceil((isNullCal(obj.objReference.arrMachineServiceCost[0].equipmentCost) / 3600) * (isNullCal(obj.durationPrePgmSec) + (1 * (isNullCal(obj.durationPgmSec) + isNullCal(obj.durationPgmWaitSec)))));
                                                    operatorCost = Math.ceil((isNullCal(obj.objReference.arrMachineServiceCost[0].operatorCost) / 3600) * (isNullCal(obj.durationPrePgmSec) + (1 * (isNullCal(obj.durationPgmSec) + isNullCal(obj.durationPgmWaitSec)))));
                                                }
                                            }

                                            if (obj.objReference.arrMaterialCost.length > 0) {
                                                materialFeeMultiple = obj.objReference.arrMaterialCost[0].price * obj.intQuantity;
                                            }

                                            if (arrMachineServiceCost) {
                                                equipmentCostMultiple = Math.ceil((isNullCal(arrMachineServiceCost.equipmentCost) / 3600) * productionDuration);
                                                operatorCostMultiple = Math.ceil((isNullCal(arrMachineServiceCost.operatorCost) / 3600) * productionDuration);
                                            }


                                            if (obj.pgmPdcQuantity == 1) {
                                                singleToolCost = batchCost;
                                                singleToolCostBulk = batchCost;
                                            }
                                            else {
                                                singleToolCost = totalFee;
                                            }

                                            totalFee = Math.ceil((singleToolCostBulk + equipmentCost + operatorCost + materialFee) / isNullCal(obj.pgmPdcQuantity));
                                            batchCost = Math.ceil((singleToolCost + equipmentCostMultiple + operatorCostMultiple + materialFeeMultiple));

                                            if (obj.intQuantity > obj.pgmPdcQuantity) {
                                                totalPrice += batchCost;
                                            }
                                            else {
                                                if (errCss == "") {
                                                    totalPrice += totalFee;
                                                }
                                            }

                                            totalWorkHours += productionDuration;

                                            workpieceResult.push(
                                                <Col sm={12} xs={12} md={4} lg={4} xl={4} xxl={3} className='mb-3'>
                                                    <Card className='card-workpiece'>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col sm={10}>
                                                                    <h4><b>{obj.wpName}</b></h4>
                                                                </Col>
                                                                <Col sm={2} className='d-flex justify-content-end'>
                                                                    <FontAwesomeIcon icon={faTrashCan} className='button-icon-trash cursor-pointer' onClick={(e) => handleDelWorkpiece_fun(obj.id, `${isNullCal(obj.intQuantity) > isNullCal(obj.pgmPdcQuantity) ? batchCost : totalFee}`, isNullDisplay('number', isNullCal(productionDuration)), `${errCss == "" ? isNullCal(obj.intQuantity) : 0}`)} />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={10}>
                                                                    <h6>{obj.wpNo}</h6>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className='d-flex justify-content-center'>
                                                                    {
                                                                        obj.images?.length > 0 ?
                                                                            <img src={obj.images[0]} className='img-workpiece' /> :
                                                                            <img src="/images/workpiece/image_no picture placeholder.svg" className='img-workpiece' />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-1'>
                                                                <Col className='text-greyA'>
                                                                    {t("material")}
                                                                </Col>
                                                                <Col className='tRight'>
                                                                    {obj.mtType01} ({obj.mtNo})
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-1'>
                                                                <Col className='text-greyA'>
                                                                    {t("unitSuggestedRetailPrice")}
                                                                </Col>
                                                                <Col className='d-flex justify-content-end'>
                                                                    $&nbsp;{addThousandsSeparator(obj.wpPrice)}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-1'>
                                                                <Col className='text-greyA'>
                                                                    {t("unitCost")}
                                                                </Col>
                                                                <Col className='d-flex justify-content-end'>

                                                                    {(() => {
                                                                        return errCss == "" ? `$ ${addThousandsSeparator(totalFee)}` : "-";
                                                                    })()}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-1'>
                                                                <Col className='text-greyA'>
                                                                    {t("batchCostSingle")}
                                                                </Col>
                                                                <Col className='d-flex justify-content-end'>
                                                                    {errCss == "" && batchCost > 0 && obj.intQuantity > obj.pgmPdcQuantity ? `$ ${addThousandsSeparator(Math.ceil(batchCost / obj.intQuantity))}` : "-"}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-1'>
                                                                <Col className='text-greyA'>
                                                                    {t("batchCost")}
                                                                </Col>
                                                                <Col className='d-flex justify-content-end'>
                                                                    {errCss == "" && batchCost > 0 && obj.intQuantity > obj.pgmPdcQuantity ? `$ ${addThousandsSeparator(batchCost)}` : "-"}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col className='cursor-pointer' onClick={(e) => detailCollapse(e, i)}>
                                                                    <FontAwesomeIcon icon={inputQuantityCollapse[i] ? faCaretUp : faCaretDown} />&nbsp;{t("detailedInformation")}
                                                                </Col>
                                                            </Row>
                                                            <div className={inputQuantityCollapse[i] ? "" : "d-none"}>
                                                                <hr className='hr' />
                                                                <Row className='ms-1'>
                                                                    <Col>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("utilizedEquipment")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {arrMachineServiceCost ? arrMachineServiceCost.machineSn : ""}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("workpieceCategory")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {obj.wpCategory}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("dimension")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {obj.wpDimension}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("manufacturingProcess")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {obj.pgmNo}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("batchQuantity")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {obj.pgmPdcQuantity}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <hr className='hr' />
                                                                <Row className='ms-1'>
                                                                    <Col>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("productionTime")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {errCss != "alert" ? formatTime(productionDuration) : "-"}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("equipmentUsageFee")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {errCss == "" ? `$ ${addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? equipmentCostMultiple : equipmentCost)}` : "-"}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("personnelCosts")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {errCss == "" ? `$ ${addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? operatorCostMultiple : operatorCost)}` : "-"}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("materialCosts")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {errCss == "" ? `$ ${addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? materialFeeMultiple : materialFee)}` : "-"}
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("toolingCosts")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {(() => {
                                                                                    if (errCss != "") {
                                                                                        return "-";
                                                                                    }
                                                                                    return `$ ${addThousandsSeparator(singleToolCost)}`;
                                                                                })()}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mb-1'>
                                                                            <Col className='text-greyA'>
                                                                                {t("numberOfTools")}
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {parseFloat(toolQuantity.toFixed(3))}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <hr className='hr' />
                                                                <Row className='ms-1'>
                                                                    <Col>
                                                                        <Row className='mb-1'>
                                                                            <Col xs={12} className='text-greyA'>
                                                                                {t("requiredToolingList")}
                                                                            </Col>
                                                                        </Row>
                                                                        {toolListResult}
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mt-2'>
                                                                    <Col>
                                                                        <button type="button" className="btn btn-lv4 w-100 h38" onClick={(e) => handleUpdateValue_fun(e, i, singleToolList)}>
                                                                            <b>{t("updateValue")}</b>
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                            </div>



                                                            <hr className='hr' />

                                                            <Row className='mt-2'>
                                                                <Col className='d-flex justify-content-center'>
                                                                    <button type="button" className="btn btn-lv1 h40px w40px" disabled={obj.intQuantity <= obj.pgmPdcQuantity}
                                                                        onClick={(e) => minusNum(e, i)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faMinus} />
                                                                    </button>
                                                                </Col>
                                                                <Col sm={5} xs={5} md={5} lg={5} xl={5} xxl={5}>
                                                                    <input className={`input-big-text${errCss != "" ? `-${errCss}` : ""} w-100`} value={obj.intQuantity}
                                                                        onChange={(e) => handleCalQuantity(e, i)}
                                                                        onBlur={(e) => blurCalQuantity(e, i)}
                                                                    />
                                                                    <p className={`${errCss != "" ? `text-${errCss}` : ""} tRight`}>{`${errCss != "" ? errCss == "warning" ? t("inputNumber0") : t("inputNumberMultiple") : ""}`}</p>
                                                                </Col>
                                                                <Col className='d-flex justify-content-center'>
                                                                    <button type="button" className="btn btn-lv1 h40px w40px"
                                                                        onClick={(e) => plusNum(e, i)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faPlus} />
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            );
                                        }

                                        workpieceResult.push(<p id="totalPrice" className='d-none'>{totalPrice}</p>);
                                        workpieceResult.push(<p id="totalWorkHours" className='d-none'>{totalWorkHours}</p>);
                                        workpieceResult.push(<p id="totalQuantity" className='d-none'>{totalQuantity}</p>);
                                    }
                                    else {
                                        workpieceResult.push(
                                            <Row className='text-unselected-div' key={1}>
                                                <Col className='d-flex justify-content-center align-items-center'>
                                                    <p className='text-unselected py-2 px-4'>{t("unSelectedWorkpiece")}</p>
                                                </Col>
                                            </Row>
                                        );
                                    }

                                    return workpieceResult;
                                })()}
                            </Row>
                        </div>

                    </Container> :
                    <></>
            }
            {/*計算機頁面 - end*/}

            {
                activeTab === 1 && excelUploadStatus == 6 ?
                    <div className={`card-message-warning`}>您沒有選擇檔案喔！</div> : <></>
            }

            {/*未上傳成功以及第一次上傳 - start*/}
            {
                activeTab === 1 && excelUploadStatus == 1 ?
                    <div className='w-100 d-flex justify-content-center align-items-center' style={{ height: "90vh" }}>
                        <div className='card-upload-div'>
                            <div className='card-upload'>
                                <div className='card-upload-in-div'>
                                    <div className='card-upload-in-topCard cursor-pointer' onClick={(e) => uploadExcel(e)}>
                                        <div className='card-upload-in-topCard-border'>
                                            <span className="material-symbols-rounded note_add h1">note_add</span>
                                        </div>

                                    </div>
                                    <button type="button" className="btn btn-lv1 h38" onClick={(e) => uploadExcel(e)}>
                                        <b>{t("uploadExcel")}</b>
                                    </button>
                                </div>
                            </div>
                            <div className='btn btn-lv4 h38' onClick={handleDownload}>
                                {t("downloadExcelTemplate")}
                            </div>
                        </div>
                    </div> :
                    <></>
            }
            {/*未上傳成功以及第一次上傳  - end*/}


            {/*有上傳(不管成功與失敗) - start*/}
            {
                activeTab === 1 && (excelUploadStatus == 2 || excelUploadStatus == 3 || excelUploadStatus == 4) ?
                    <>
                        <div className='header-calculator-container'>
                            <Row className='d-flex align-content-center h-100 mx-2'>
                                {/* <Col>
                                <button type="button" className="button-secondary button-formula">
                                    <b>{t("viewallData")}</b>
                                </button>
                            </Col> */}
                                <Col className='d-flex justify-content-end'>
                                    <button type="button" className="btn btn-lv1" onClick={(e) => uploadExcel(e)}>
                                        <b>{t("uploadExcel")}</b>
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <div className='mx-4'>
                            <div className='d-flex justify-content-center mt-4'>
                                <div className={`card-message-${excelUploadStatus != 4 ? "error" : "success"}`}>
                                    {
                                        excelUploadStatus != 4 ? t("excelUploadError") : t("excelUploadSuccess")
                                    }
                                </div>
                            </div>
                        </div>
                    </> :
                    <></>
            }
            {/*有上傳(不管成功與失敗) - end*/}

            {/*上傳Excel成功(不只出現一次) - start*/}
            {
                activeTab === 1 && (excelUploadStatus == 5) ?
                    <div className='header-calculator-container'>
                        <Row className='d-flex align-content-center h-100 mx-2'>
                            <Col>
                                <h4><b>{t("dataList")}</b></h4>
                                <p className='text-blue mb-0'>{t("lastUpdated")}: 2023-10-23 11:25</p>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <button type="button" className="btn btn-lv4 me-2">
                                    <b>{t("downloadExcel")}</b>
                                </button>
                                <button type="button" className="btn btn-lv1" onClick={(e) => uploadExcel(e)}>
                                    <b>{t("uploadExcel")}</b>
                                </button>
                            </Col>
                        </Row>
                    </div> :
                    <></>
            }
            {/*上傳Excel成功(不只出現一次) - end*/}

            {/*上傳Excel成功 - start*/}
            {
                activeTab === 1 && (excelUploadStatus == 4 || excelUploadStatus == 5) ?
                    <div className='mb-5 pb-5'>
                        <div className='mx-4 mt-3'>
                            <span className='mb-0'><b>{t("workpiece")}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>(01_Workpiece)</span>
                            <div className="table-wrapper border">
                                <div className="table-scroll">
                                    <table>
                                        <thead className='table-header-border'>
                                            <tr>
                                                <th>
                                                    <span className="text-greyA">A</span><br />
                                                    <span className="text-blue">{t("workpieceID")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">B</span><br />
                                                    <span className="text-blue">{t("workpieceName")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">C</span><br />
                                                    <span className="text-blue">{t("drawingNumber")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">D</span><br />
                                                    <span className="text-blue">{t("workpieceCategory")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">E</span><br />
                                                    <span className="text-blue">{t("workpieceDimensions")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">F</span><br />
                                                    <span className="text-blue">{t("workpieceKeyword")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">G</span><br />
                                                    <span className="text-blue">{t("suggestedPrice")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                excelUploadResult && excelUploadResult[0] ?
                                                    excelUploadResult[0].data.map((obj, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{obj.wpNo}</td>
                                                                <td>{obj.wpName}</td>
                                                                <td>{obj.dwgNo}</td>
                                                                <td>{obj.wpCategory}</td>
                                                                <td>{obj.wpDimension}</td>
                                                                <td>{obj.wpCharacteristics}</td>
                                                                <td className='tRight'>{obj.wpPrice ? addThousandsSeparator(obj.wpPrice) : "-"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='mx-4 mt-5'>
                            <span className='mb-0'><b>{t("programTime")}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>(02_Program_Time)</span>
                            <div className="table-wrapper">
                                <div className="table-scroll">
                                    <table>
                                        <thead className='table-header-border'>
                                            <tr>
                                                <th>
                                                    <span className="text-greyA">A</span><br />
                                                    <span className="text-blue">{t("workpieceID")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">B</span><br />
                                                    <span className="text-blue">{t("machiningProgram")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">C</span><br />
                                                    <span className="text-blue">{t("rawMaterialName")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">D</span><br />
                                                    <span className="text-blue">{t("batchProductionQuantity")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">E</span><br />
                                                    <span className="text-blue">{t("machinesApplicableProgram")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">F</span><br />
                                                    <span className="text-blue">{t("preProcessingDuration")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">G</span><br />
                                                    <span className="text-blue">{t("programExecutionDuration")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">H</span><br />
                                                    <span className="text-blue">{t("loadingUnloadingWaitingDuration")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">I</span><br />
                                                    <span className="text-blue">{t("programDescription")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                excelUploadResult && excelUploadResult[1] ?
                                                    excelUploadResult[1].data.map((obj, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{obj.wpNo}</td>
                                                                <td>{obj.pgmNo}</td>
                                                                <td>{obj.mtNo}</td>
                                                                <td>{obj.pgmPdcQuantity}</td>
                                                                <td>{obj.pgmMachineSNs}</td>
                                                                <td>{obj.durationPrePgm}</td>
                                                                <td>{obj.durationPgm}</td>
                                                                <td>{obj.durationPgmWait}</td>
                                                                <td>{obj.pgmDescription}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='mx-4 mt-5'>
                            <span className='mb-0'><b>{t("programToolWear")}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>(03_Program_Tool_Wear)</span>
                            <div className="table-wrapper">
                                <div className="table-scroll">
                                    <table>
                                        <thead className='table-header-border'>
                                            <tr>
                                                <th>
                                                    <span className="text-greyA">A</span><br />
                                                    <span className="text-blue">{t("workpieceID")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">B</span><br />
                                                    <span className="text-blue">{t("machiningProgram")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">C</span><br />
                                                    <span className="text-blue">{t("toolsUsedMachiningProgram")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">D</span><br />
                                                    <span className="text-blue">{t("singleProgramToolWear")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                excelUploadResult && excelUploadResult[2] ?
                                                    excelUploadResult[2].data.map((obj, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{obj.wpNo}</td>
                                                                <td>{obj.pgmNo}</td>
                                                                <td>{obj.ttNo}</td>
                                                                <td>{obj.pgtaPercentage}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='mx-4 mt-5'>
                            <span className='mb-0'><b>{t("toolInfoPrice")}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>(04_Tool_Price)</span>
                            <div className="table-wrapper">
                                <div className="table-scroll">
                                    <table>
                                        <thead className='table-header-border'>
                                            <tr>
                                                <th>
                                                    <span className="text-greyA">A</span><br />
                                                    <span className="text-blue">{t("dateToolProcurement")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">B</span><br />
                                                    <span className="text-blue">{t("batchNumberToolProcurement")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">C</span><br />
                                                    <span className="text-blue">{t("toolID")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">D</span><br />
                                                    <span className="text-blue">{t("toolName")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">E</span><br />
                                                    <span className="text-blue">{t("toolCategory")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">F</span><br />
                                                    <span className="text-blue">{t("toolPrice")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">G</span><br />
                                                    <span className="text-blue">{t("toolRemarks")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                excelUploadResult && excelUploadResult[3] ?
                                                    excelUploadResult[3].data.map((obj, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{obj.arrivalDate}</td>
                                                                <td>{obj.tLotNo}</td>
                                                                <td>{obj.ttNo}</td>
                                                                <td>{obj.ttName}</td>
                                                                <td>{obj.tType}</td>
                                                                <td className='tRight'>{obj.price ? addThousandsSeparator(obj.price) : "-"}</td>
                                                                <td>{obj.toolRemarks}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='mx-4 mt-5'>
                            <span className='mb-0'><b>{t("materialPrice")}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>(05_Material_Price)</span>
                            <div className="table-wrapper">
                                <div className="table-scroll">
                                    <table>
                                        <thead className='table-header-border'>
                                            <tr>
                                                <th>
                                                    <span className="text-greyA">A</span><br />
                                                    <span className="text-blue">{t("dateMaterialProcurement")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">B</span><br />
                                                    <span className="text-blue">{t("batchNumberMaterialProcurement")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">C</span><br />
                                                    <span className="text-blue">{t("rawMaterialName")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">D</span><br />
                                                    <span className="text-blue">{t("materialAlloyNumber")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">E</span><br />
                                                    <span className="text-blue">{t("materialGroups")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">F</span><br />
                                                    <span className="text-blue">{t("dimensionsCustomizedMaterial")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">G</span><br />
                                                    <span className="text-blue">{t("weightCustomizedMaterial")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">H</span><br />
                                                    <span className="text-blue">{t("materialUnitPrice")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                excelUploadResult && excelUploadResult[4] ?
                                                    excelUploadResult[4].data.map((obj, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{obj.arrivalDate}</td>
                                                                <td>{obj.lotNo}</td>
                                                                <td>{obj.mtNo}</td>
                                                                <td>{obj.mtNo2}</td>
                                                                <td>{obj.mtType01}</td>
                                                                <td>{obj.dimension}</td>
                                                                <td>{obj.weight}</td>
                                                                <td className='tRight'>{obj.price ? addThousandsSeparator(obj.price) : "-"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='mx-4 mt-5'>
                            <span className='mb-0'><b>{t("machineOperatorCost")}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>(06_Machine_Operator_Cost)</span>
                            <div className="table-wrapper">
                                <div className="table-scroll">
                                    <table>
                                        <thead className='table-header-border'>
                                            <tr>
                                                <th>
                                                    <span className="text-greyA">A</span><br />
                                                    <span className="text-blue">{t("machineID")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">B</span><br />
                                                    <span className="text-blue">{t("machineGroup")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">C</span><br />
                                                    <span className="text-blue">{t("serviceOfferings")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">D</span><br />
                                                    <span className="text-blue">{t("machineType1")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">E</span><br />
                                                    <span className="text-blue">{t("machineType2")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">F</span><br />
                                                    <span className="text-blue">{t("hourlyCostperOperatorMachine")}</span>
                                                </th>
                                                <th>
                                                    <span className="text-greyA">G</span><br />
                                                    <span className="text-blue">{t("depreciationMachineHourlyCost")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                excelUploadResult && excelUploadResult[5] ?
                                                    excelUploadResult[5].data.map((obj, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{obj.machineSn}</td>
                                                                <td>{obj.mtypeNo}</td>
                                                                <td>{obj.msName}</td>
                                                                <td>{obj.machineType01}</td>
                                                                <td>{obj.machineType02}</td>
                                                                <td className='tRight'>{obj.operatorCost ? addThousandsSeparator(obj.operatorCost) : "-"}</td>
                                                                <td className='tRight'>{obj.equipmentCost ? addThousandsSeparator(obj.equipmentCost) : "-"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
            {/*上傳Excel成功 - end*/}

            {/*上傳Excel 錯誤(Sheet有問題) - start*/}
            {
                activeTab === 1 && excelUploadStatus == 2 ?
                    <div className='mx-4 mt-3'>
                        <div className="table-wrapper">
                            <div className="table-scroll">
                                <table>
                                    <thead className='table-header-border'>
                                        <tr>
                                            <th>
                                                <span className="text-blue">工作表名稱</span>
                                            </th>
                                            <th>
                                                <span className="text-blue">欄位名稱</span>
                                            </th>
                                            <th>
                                                <span className="text-blue">{t("errorMessage")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            excelUploadResult && excelUploadResult.length > 0 ?
                                                excelUploadResult.map((obj, index) => {
                                                    return obj.arrMessages.map((detail, objIndex) => {
                                                        return (
                                                            <tr key={`${index}-${objIndex}`}>
                                                                <td>{obj.tabName == "Error Message" ? detail.content : obj.tabName}</td>
                                                                <td>{obj.tabName == "Error Message" ? "" : detail.content}</td>
                                                                <td>{detail.errorType}</td>
                                                            </tr>
                                                        );
                                                    });
                                                })
                                                : <></>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> :
                    <></>
            }
            {/*上傳Excel 錯誤(資料表內容) - end*/}

            {/*上傳Excel 錯誤(資料表內容) - start*/}
            {(() => {
                let resultObj = [];

                if (activeTab === 1 && excelUploadStatus == 3 && excelUploadResult) {
                    for (const element of excelUploadResult) {
                        let title = "";
                        let subTitle = "";

                        switch (element.tabName) {
                            case '01_Workpiece':
                                title = t("workpiece");
                                subTitle = "(01_Workpiece)";
                                break;
                            case '02_Program_Time':
                                title = t("programTime");
                                subTitle = "(02_Program_Time)";
                                break;
                            case '03_Program_Tool_Wear':
                                title = t("programToolWear");
                                subTitle = "(03_Program_Tool_Wear)";
                                break;
                            case '04_Tool_Price':
                                title = t("toolInfoPrice");
                                subTitle = "(04_Tool_Price)";
                                break;
                            case '05_Material_Price':
                                title = t("materialPrice");
                                subTitle = "(05_Material_Price)";
                                break;
                            case '06_Machine_Operator_Cost':
                                title = t("machineOperatorCost");
                                subTitle = "(06_Machine_Operator_Cost)";
                                break;
                            default:
                                title = "";
                                subTitle = "";
                                break;
                        }

                        let detailObj = [];
                        for (let j = 0; j < element.arrMessages.length; j++) {
                            detailObj.push(
                                <tr key={j}>
                                    <td>{element.arrMessages[j].row}</td>
                                    <td>{element.arrMessages[j].column}</td>
                                    <td>{element.arrMessages[j].content}</td>
                                    <td>{element.arrMessages[j].errorType}</td>
                                </tr>
                            );
                        }

                        resultObj.push(
                            <div className='mx-4 mb-5'>
                                <span className='mb-0'>
                                    <b>{title}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>{subTitle}
                                </span>
                                <div className="table-wrapper">
                                    <div className="table-scroll">
                                        <table>
                                            <thead className='table-header-border'>
                                                <tr>
                                                    <th>
                                                        <span className="text-blue">{t("row")}</span>
                                                    </th>
                                                    <th>
                                                        <span className="text-blue">{t("column")}</span>
                                                    </th>
                                                    <th>
                                                        <span className="text-blue">{t("content")}</span>
                                                    </th>
                                                    <th>
                                                        <span className="text-blue">{t("errorMessage")}</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detailObj}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        );
                    }

                    return resultObj;
                }

                // activeTab === 1 && excelUploadStatus == 3 && excelUploadResult ?
                //  excelUploadResult.arrMessages.map((obj, index) => {
                //     return(
                //         <div className='mx-4'>
                //             <span className='mb-0'>
                //                 <b>{t("workpiece")}</b></span>&nbsp;&nbsp;<span className='text-greyA mb-0'>(01_Workpiece)
                //             </span>
                //         <div className="table-wrapper">
                //             <div className="table-scroll">
                //                 <table>
                //                     <thead className='table-header-border'>
                //                         <tr>
                //                             <th>
                //                                 <span className="text-blue">{t("row")}</span>
                //                             </th>
                //                             <th>
                //                                 <span className="text-blue">{t("column")}</span>
                //                             </th>
                //                             <th>
                //                                 <span className="text-blue">{t("content")}</span>
                //                             </th>
                //                             <th>
                //                                 <span className="text-blue">{t("errorMessage")}</span>
                //                             </th>
                //                         </tr>
                //                     </thead>
                //                     <tbody>
                //                         {/* {
                //                             excelUploadResult ? 
                //                                 excelUploadResult.arrMessages.map((obj, index) => {
                //                                     return(
                //                                         <tr key={index}> 
                //                                             <td>{obj.content}</td> 
                //                                         </tr>
                //                                     )
                //                                 })
                //                             : <></>
                //                         } */}
                //                         <tr> 
                //                             <td>1</td>
                //                             <td>F (Characteristic)</td>
                //                             <td>平銑刀;標準型;3刃;Square;Standard;3F;30°</td>
                //                             <td>Unidentifiable characters.</td>
                //                         </tr>
                //                     </tbody>
                //                 </table>
                //             </div>
                //         </div>
                //     </div>
                //     )
                // })

                //  :
                // <></>
            })()}
            {/*上傳Excel 錯誤(資料表內容) - end*/}

            {/*上傳圖片 - start*/}
            {
                activeTab === 2 ?
                    <div className='header-calculator-container'>
                        <Row className='d-flex align-content-center h-100 mx-2'>
                            <Col>
                                <h4><b>{t("imageList")}</b></h4>
                                <p className='text-blue mb-0'>{t("lastUpdated")}: {imgLastUpdateTime}</p>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <button type="button" className="btn btn-lv1" onClick={(e) => uploadImage(e)} disabled={imgUploadStatus == 1}>
                                    <b>{t("uploadImage")}</b>
                                </button>
                            </Col>
                        </Row>
                    </div>
                    :
                    <></>
            }
            {/*上傳圖片 - end*/}

            {
                activeTab === 2 && (imgUploadStatus == 5 || imgUploadStatus == 6) && imgUploadErrDisplay ?
                    <div className='card-message-error mx-5 mt-3'>
                        <Row>
                            <Col sm={10} xs={10} md={10} lg={10} xl={10} xxl={10}>
                                {
                                    imgUploadStatus == 4 ? <p className='mb-0'>File uploaded successfully.</p> : ""
                                }
                                <p>Some file data is incorrect. Please make adjustments as suggested below and upload it again.</p>
                            </Col>
                            <Col sm={2} xs={2} md={2} lg={2} xl={2} xxl={2} className='d-flex justify-content-end'>
                                <span className="material-symbols-rounded text-alert cancel cursor-pointer" style={{ fontSize: "2rem" }} onClick={closeErrCard}>cancel</span>
                            </Col>
                        </Row>
                        <div className="table-wrapper bg-lightblue3">
                            <div className="table-scroll">
                                <table>
                                    <thead className='table-header-border'>
                                        <tr>
                                            <th>
                                                <span className="text-blue">File Name</span>
                                            </th>
                                            <th>
                                                <span className="text-blue">Error Message</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            imgUploadErr.length > 0 ?
                                                imgUploadErr.map((obj, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{obj.fileName}</td>
                                                            <td>
                                                                {
                                                                    obj.err.map((detailErr, detailIndex) => {
                                                                        return (
                                                                            <div key={detailIndex}>{obj.length > 0 ? `${(detailIndex + 1)}.` : ""}{detailErr}</div>
                                                                        );
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : <></>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }

            {
                activeTab === 2 && imgUploadStatus == 4 && imgUploadSuccessDisplay ?
                    <div className='d-flex justify-content-end me-5 mt-3'>
                        <div className='card-message-success d-flex align-items-center' style={{ display: "inline-block" }}>
                            {/* <div className=''> */}
                            File content is correct, data upload completed.
                            <span className="material-symbols-rounded cancel cursor-pointer text-success-msg" style={{ fontSize: "2rem" }} onClick={closeSuccessCard}>cancel</span>
                            {/* </div> */}
                        </div>
                    </div>

                    :
                    <></>
            }


            {/*上傳圖片說明 - start*/}
            {
                activeTab === 2 ?
                    <div className='card-uploadImage-illustrate mx-5 mt-3 p-3'>
                        <Row>
                            <Col><h5><b>圖片上傳說明：</b></h5></Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={4} md={3} lg={2} xl={2} xxl={2}><h5><b>1.尺寸比例：</b></h5></Col>
                            <Col><h5>最佳尺寸比例為 180 x 140 px。</h5></Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={4} md={3} lg={2} xl={2} xxl={2}><h5><b>2.圖片大小：</b></h5></Col>
                            <Col><h5>每張圖片最多 3Mb。</h5></Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={4} md={3} lg={2} xl={2} xxl={2}><h5><b>3.數量：</b></h5></Col>
                            <Col><h5>每批次最多上傳 10 張圖片。</h5></Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={4} md={3} lg={2} xl={2} xxl={2}><h5><b>4.檔案名稱：</b></h5></Col>
                            <Col className='d-flex'><h5>圖片的「檔案名稱」須與「工件代號」完全相符，且不可在名稱後面額外加上任何數字、符號、空白鍵。</h5><span><FontAwesomeIcon icon={faCircleInfo} className='text-dark cursor-pointer' onClick={() => setShowModalFileNameTemplate(true)} /></span></Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={4} md={3} lg={2} xl={2} xxl={2}><h5><b>5.檔案格式：</b></h5></Col>
                            <Col><h5>僅限 png、 jpg、 jpeg、gif。</h5></Col>
                        </Row>
                    </div>
                    :
                    <></>
            }
            {/*上傳圖片說明 - end*/}

            {/*上傳圖片 - start*/}
            {
                activeTab === 2 && imgUploadStatus != 1 ?
                    <div className='mx-5 mt-3 mb-5'>
                        <Row>
                            {
                                imgWorkPiece && imgWorkPiece.length > 0 ?
                                    imgWorkPiece.map((obj, index) => {
                                        return (
                                            <Col key={index} xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
                                                <div className='card-workpiece-modal-items p-4 mb-3'>
                                                    <Row>
                                                        <Col className='d-flex justify-content-center'>
                                                            {
                                                                obj.images != null ?
                                                                    <img src={obj.images[0]} className={`img-workpiece`} />
                                                                    :
                                                                    <div className={`card-noImage`}>
                                                                        <span className="material-symbols-rounded text-greyA-img" style={{ fontSize: "5rem" }}>image</span>
                                                                    </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={`tCenter my-2`}>
                                                            <h4 className='mb-0'><b>{obj.wpName}</b></h4>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={`tCenter`}>
                                                            <p className='mb-0'>{obj.wpNo}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        );
                                    }) :
                                    <></>
                            }
                        </Row>
                    </div>
                    :
                    <></>
            }
            {/*上傳圖片 - end*/}


            {/*圖檔CSS - start*/}
            <style>
                {`
                    .calculate {
                        font-variation-settings:
                        'FILL' ${activeTabCSS[0].fill},
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 24
                    }

                    .upload_file {
                        font-variation-settings:
                        'FILL' ${activeTabCSS[1].fill},
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 24
                    }

                    .note_add {
                        font-variation-settings:
                        'FILL' 0,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 24
                    }

                    .download {
                        font-variation-settings:
                        'FILL' 0,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 24
                    }

                    .add_photo_alternate {
                        font-variation-settings:
                        'FILL' ${activeTabCSS[2].fill},
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 24
                    }

                    .cancel {
                        font-variation-settings:
                        'FILL' 1,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 24
                    }
                `}
            </style>
            {/*圖檔CSS - end*/}

            {/*下方Tab - start*/}
            {/* <Row className="tab-container mx-0">
                <Col className={`tab ${activeTab === 0 ? 'active-tab' : ''}`} onClick={(e) => changeTab(e, 0)}>
                    <Row>
                        <Col sm={12} xs={12} md={2} lg={2} xl={2} xxl={2} className='tCenter'>
                            <span className="material-symbols-rounded calculate">calculate</span>
                        </Col>
                        <Col sm={12} xs={12} md={8} lg={8} xl={8} xxl={8} className='tCenter'>
                            {t("calculator")}
                        </Col>
                    </Row>
                </Col>
                <Col className={`tab ${activeTab === 2 ? 'active-tab' : ''}`} onClick={(e) => changeTab(e, 2)}>
                    <Row>
                        <Col sm={12} xs={12} md={2} lg={2} xl={2} xxl={2} className='tCenter'>
                            <span className="material-symbols-rounded add_photo_alternate">add_photo_alternate</span>
                        </Col>
                        <Col sm={12} xs={12} md={8} lg={8} xl={8} xxl={8} className='tCenter'>
                            {t("uploadImage")}
                        </Col>
                    </Row>
                </Col>
                <Col className={`tab ${activeTab === 1 ? 'active-tab' : ''}`} onClick={(e) => changeTab(e, 1)}>
                    <Row>
                        <Col sm={12} xs={12} md={2} lg={2} xl={2} xxl={2} className='tCenter'>
                            <span className="material-symbols-rounded upload_file">upload_file</span>
                        </Col>
                        <Col sm={12} xs={12} md={8} lg={8} xl={8} xxl={8} className='tCenter'>
                            {t("uploadData")}
                        </Col>
                    </Row>
                </Col>
            </Row> */}
            {/*下方Tab - end*/}

            <input
                type="file"
                ref={fileInputRef}
                className='d-none'
                onChange={handleFileChangeExcel}
                accept=".xls,.xlsx"
            />
            <input
                type="file"
                ref={fileInputImageRef}
                className='d-none'
                onChange={handleFileChangeImage}
                accept=".png, .jpg, .jpeg, .gif"
                multiple
            />
            <div tabIndex={0} />

            <PDFDownloadLink document={<MyDoc selectedWorkPiece={selectedWorkPiece} confirmFormula={confirmFormula} selectedWorkPieceMachine={selectedWorkPieceMachine} tempPdfFileName={tempPdfFileName} />}
                fileName={`${tempPdfFileName}.pdf`} className='d-none'>
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <><span className="material-symbols-rounded download" ref={pdfDownload}>download</span>{t("export")}</>)}
            </PDFDownloadLink>

            {/*計算方式 modal - start*/}
            <Modal show={showModalFormula} onHide={handleCloseModalFormula_fun} size="xl" backdrop="static" keyboard={false}>
                <Modal.Body className='p-4 mx-3'>
                    <Row>
                        <Col>
                            <h4><b>{t("calculationMethod")}</b></h4>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <FontAwesomeIcon icon={faXmark} className='text-dark fa-2x cursor-pointer' onClick={handleCloseModalFormula_fun} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='text-greyA'>{t("calculationMethodSubTitle")}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='card-workpiece-formula py-2 px-0'>
                            <Row className='px-4'>
                                <Col sm={12} xs={12} md={4} lg={4}>
                                    <h6 className='mb-0'><b>{t("machineUtilization")}</b></h6>
                                </Col>
                                <Col sm={12} xs={12} md={8} lg={8}>
                                    <p className='mb-0'>{t("muDescription")}</p>
                                    <p className='mb-0'>{t("muFormula")}</p>
                                    <p className='mb-0'>({t("muSubTitle")})</p>
                                </Col>
                            </Row>
                            <hr className='hr mx-0' />
                            <Row className='px-4'>
                                <Col sm={12} xs={12} md={4} lg={4}>
                                    <h6 className='mb-0'><b>{t("operator")}</b></h6>
                                </Col>
                                <Col sm={12} xs={12} md={8} lg={8}>
                                    <p className='mb-0'>{t("operatorDescription")}</p>
                                    <p className='mb-0'>{t("operatorFormula")}</p>
                                    <p className='mb-0'>({t("muSubTitle")})</p>
                                </Col>
                            </Row>
                            <hr className='hr mx-0' />
                            <Row className='px-4'>
                                <Col sm={12} xs={12} md={4} lg={4}>
                                    <h6 className='mb-0'><b>{t("workpieceMaterial")}</b></h6>
                                </Col>
                                <Col sm={12} xs={12} md={8} lg={8}>
                                    <p className='mb-0'>{t("wmDescription")}</p>
                                    <p className='mb-0'>{t("wmFormula")}</p>
                                </Col>
                            </Row>
                            <hr className='hr mx-0' />
                            <Row className='px-4 '>
                                <Col sm={12} xs={12} md={4} lg={4}>
                                    <h6 className='mb-0'><b>{t("tool")}</b></h6>
                                </Col>
                                <Col sm={12} xs={12} md={6} lg={6}>
                                    <p className='mb-0'>{t("manufacturingTool")}</p>
                                    <p className='mb-0'>{t("calculationFormula")}</p>
                                    <Row className='mt-2 cursor-pointer' onClick={(e) => handleFormulaItems_fun("A")}>
                                        <Col className={`p-3 formula card-workpiece-formula-items-${formula == "A" ? "selected" : ""}`}>
                                            <FontAwesomeIcon icon={faCheck} className={`formula-icon ${formula == "A" ? "" : "d-none"}`} />
                                            <Row>
                                                <Col sm={12} xs={12} md={2} lg={2} xl={2} xxl={2}>
                                                    <b className='text-grey4'>{t("method")}A</b>
                                                </Col>
                                                <Col sm={12} xs={12} md={10} lg={10} xl={10} xxl={10}>
                                                    <Row>
                                                        <Col>
                                                            <b className='text-grey4'>{t("methodATitle")}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col>
                                                            <p className='text-grey4 mb-0'>{t("methodAX")}</p>
                                                            <p className='text-grey4 mb-0'>{t("methodAY")}</p>
                                                            <p className='text-grey4 mb-0 mt-2'>{t("methodACosts")}</p>
                                                        </Col>
                                                    </Row>

                                                    <hr className={`${formula == "A" ? "hr-blue" : "hr"}`} />

                                                    <Row className={`${formula == "A" ? "text-blue" : "text-greyA"}`}>
                                                        <Col>
                                                            <p className='mb-0'>[{t("example")}]</p>
                                                            <p className='mb-0'>{t("methodATool1")}</p>
                                                            <p className='mb-0'>{t("methodATool2")}</p>
                                                            <p className='mb-0 mt-2'>{t("methodAToolCosts")}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2 cursor-pointer' onClick={(e) => handleFormulaItems_fun("B")}>
                                        <Col className={`p-3 formula ${formula == "B" ? "card-workpiece-formula-items-selected" : "card-workpiece-formula-items"}`}>
                                            <FontAwesomeIcon icon={faCheck} className={`formula-icon ${formula == "B" ? "" : "d-none"}`} />
                                            <Row>
                                                <Col sm={12} xs={12} md={2} lg={2} xl={2} xxl={2}>
                                                    <b className='text-grey4'>{t("method")}B</b>
                                                </Col>
                                                <Col sm={12} xs={12} md={10} lg={10} xl={10} xxl={10}>
                                                    <Row>
                                                        <Col>
                                                            <b className='text-grey4'>{t("methodBTitle")}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col>
                                                            <p className='text-grey4 mb-0'>{t("methodBX")}</p>
                                                            <p className='text-grey4 mb-0 mt-2'>{t("methodBCosts")}</p>
                                                        </Col>
                                                    </Row>

                                                    <hr className={`${formula == "B" ? "hr-blue" : "hr"}`} />

                                                    <Row className={`${formula == "B" ? "text-blue" : "text-greyA"}`}>
                                                        <Col>
                                                            <p className='mb-0'>[{t("example")}]</p>
                                                            <p className='mb-0'>{t("methodBTool1")}</p>
                                                            <p className='mb-0 mt-2'>{t("methodBToolCosts")}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2 cursor-pointer' onClick={(e) => handleFormulaItems_fun("C")}>
                                        <Col className={`p-3 formula ${formula == "C" ? "card-workpiece-formula-items-selected" : "card-workpiece-formula-items"}`}>
                                            <FontAwesomeIcon icon={faCheck} className={`formula-icon ${formula == "C" ? "" : "d-none"}`} />
                                            <Row>
                                                <Col sm={12} xs={12} md={2} lg={2} xl={2} xxl={2}>
                                                    <b className='text-grey4'>{t("method")}C</b>
                                                </Col>
                                                <Col sm={12} xs={12} md={10} lg={10} xl={10} xxl={10}>
                                                    <Row>
                                                        <Col>
                                                            <b className='text-grey4'>{t("methodCTitle")}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col>
                                                            <p className='text-grey4 mb-0'>{t("methodCX")}</p>
                                                            <p className='text-grey4 mb-0'>{t("methodCY")}</p>
                                                            <p className='text-grey4 mb-0 mt-2'>{t("methodCCosts")}</p>
                                                        </Col>
                                                    </Row>

                                                    <hr className={`${formula == "C" ? "hr-blue" : "hr"}`} />

                                                    <Row className={`${formula == "C" ? "text-blue" : "text-greyA"}`}>
                                                        <Col>
                                                            <p className='mb-0'>[{t("example")}]</p>
                                                            <p className='mb-0'>{t("methodCTool1")}</p>
                                                            <p className='mb-0'>{t("methodCTool2")}</p>
                                                            <p className='mb-0 mt-2'>{t("methodCToolCosts")}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Modal.Body>
                <div className="modal-footer-custom">
                    <div className="btn-gp">
                        <div>
                            <button type="button" className="btn btn-txt-black" onClick={handleCloseModalFormula_fun}>
                                {t("cancel")}
                            </button>
                        </div>
                        <div>
                            <button type="button" className="btn btn-lv1 fat" onClick={handleFormulaConfirm_fun}>
                                {t("apply")}
                            </button>
                        </div>
                    </div>
                </div>
                {/* <Modal.Footer>
                    <hr />
                    <Row className='mt-4'>
                        <Col>
                            <button type="button" className="button-secondary button-formula" onClick={handleCloseModalFormula_fun}>
                                <b>{t("cancel")}</b>
                            </button>
                        </Col>
                        <Col className='d-flex justify-content-end' onClick={handleFormulaConfirm_fun}>
                            <button type="button" className="button-primary button-formula">
                                <b>{t("apply")}</b>
                            </button>
                        </Col>
                    </Row>
                </Modal.Footer> */}
            </Modal>
            {/*計算方式 modal - end*/}

            {/*選擇工件 modal - start*/}
            <Modal show={showModalWorkPiece} onHide={handleCloseModalWorkPiece_fun} size="xl" backdrop="static" keyboard={false}>
                {/* className='py-4 px-5' */}
                <Modal.Body className='choiceWorkpieceModalBody'>
                    <Row>
                        <Col>
                            <h3><b>{t("chioceWorkpiece")}</b></h3>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <FontAwesomeIcon icon={faXmark} className='text-dark fa-2x cursor-pointer' onClick={handleCloseModalWorkPiece_fun} />
                        </Col>
                    </Row>
                    {/* py-3 pe-4 ps-2 */}
                    <Row>
                        <Col xs={8} sm={8} md={10} lg={10} xl={10} xxl={10}>
                            <div>
                                <div className='input-leftpadding'>
                                    <input className="input outline w-100" type="search" placeholder={t("keywordSearchWorkpiece")} name="queryWorkPiece"
                                        value={modalKeywordWorkPiece}
                                        onChange={(e) => handleOnChange(e)}
                                        onKeyDown={(e) => handleKeyPress(e, "queryWorkPiece")}
                                    />
                                    <div className="icon icon-Search black"></div>
                                </div>
                                {/* <Col className='input-search-div d-flex align-items-center'> */}
                                    {/* <FontAwesomeIcon icon={faSearch} className='mx-2' />
                                    <div className="divider"></div>
                                    <input type="search" placeholder={t("keywordSearchWorkpiece")} name="queryWorkPiece" className='input-search py-3 pe-4 ps-2 w-100'
                                        value={modalKeywordWorkPiece}
                                        onChange={(e) => handleOnChange(e)}
                                        onKeyDown={(e) => handleKeyPress(e, "queryWorkPiece")}
                                    /> */}
                                    {/* <input type="search" placeholder={t("keywordSearchWorkpiece")} name="queryWorkPiece" className='d-sm-block d-md-none input-search py-3' 
                                        value={modalKeywordWorkPiece} 
                                        onChange={(e) => handleOnChange(e)} 
                                        onKeyDown={(e) => handleKeyPress(e, "queryWorkPiece")}
                                    /> */}
                                {/* </Col> */}
                            </div>
                        </Col>
                        <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2} className='d-flex justify-content-end'>
                            <button type="button" className="btn btn-lv4 w-100" onClick={handleQueryWorkpiece_fun} ref={SearchInputRef}>
                                {t("search")}
                            </button>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        {
                            modalSelectedWorkPiece.length > 0 ?
                                modalSelectedWorkPiece.map((obj, index) => {
                                    return (
                                        <span className='card-search px-3 py-2 w-auto me-1 mb-2 d-flex align-content-center' key={index}>
                                            <span className='mt-1 me-2'>{obj.wpNo}</span>

                                            <span className="material-symbols-rounded cancel cursor-pointer text-lightBlue" style={{ fontSize: "2rem" }}
                                                onClick={(e) => handleModalDeleteWorkpiece_fun(e, index)}>
                                                cancel
                                            </span>
                                            {/* <span className='card-search-circle ms-2' onClick={(e) => handleModalDeleteWorkpiece_fun(e, index)}>
                                            <FontAwesomeIcon icon={faXmark} className='mx-2' /> 
                                        </span> */}
                                        </span>
                                    )
                                })
                                :
                                <></>
                        }
                    </Row>

                    <Row className='mt-3'>
                        <Col className='text-grey8'>{t("filter")}</Col>
                    </Row>
                    <Row className='d-none d-md-block'>
                        <Col>
                            <select className='custom-dropdown' name="selCategories" onChange={(e) => handleOnChange(e)}>
                                <option value="">{t("category")}{t("all")}</option>
                                {
                                    modalCategoriesWorkPiece.length > 0 ?
                                        modalCategoriesWorkPiece.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.id}>{obj.value}</option>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </select>
                            <select className='custom-dropdown ms-3' name="selMaterial" onChange={(e) => handleOnChange(e)}>
                                <option value="">{t("material")}{t("all")}</option>
                                {
                                    modalMaterialsWorkPiece.length > 0 ?
                                        modalMaterialsWorkPiece.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.id}>{obj.value}</option>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </select>
                            <select className='custom-dropdown ms-3' name="selDimension" onChange={(e) => handleOnChange(e)}>
                                <option value="">{t("dimension")}{t("all")}</option>
                                {
                                    modalDimensionsWorkPiece.length > 0 ?
                                        modalDimensionsWorkPiece.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj}>{obj}</option>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </select>
                        </Col>
                    </Row>
                    <Row className='d-sm-block d-md-none'>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <select className='custom-dropdown-phone w-100' name="selCategories" onChange={(e) => handleOnChange(e)}>
                                <option value="">{t("category")}{t("all")}</option>
                                {
                                    modalCategoriesWorkPiece.length > 0 ?
                                        modalCategoriesWorkPiece.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.id}>{obj.value}</option>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </select>
                        </Col>
                        <Col xs={12}>
                            <select className='custom-dropdown-phone mt-2 me-1' style={{ width: "49%" }} name="selMaterial" onChange={(e) => handleOnChange(e)}>
                                <option value="">{t("material")}{t("all")}</option>
                                {
                                    modalMaterialsWorkPiece.length > 0 ?
                                        modalMaterialsWorkPiece.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.id}>{obj.value}</option>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </select>
                            <select className='custom-dropdown-phone mt-2' style={{ width: "49%" }} name="selDimension" onChange={(e) => handleOnChange(e)}>
                                <option value="">{t("dimension")}{t("all")}</option>
                                {
                                    modalDimensionsWorkPiece.length > 0 ?
                                        modalDimensionsWorkPiece.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj}>{obj}</option>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </select>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        {
                            modalWorkPiece && modalWorkPiece.length > 0 ?
                                modalWorkPiece.map((obj, index) => {
                                    return (
                                        <>
                                            <Col key={index} xs={12} sm={12} md={6} lg={6} xl={4} xxl={4} data-tooltip-id={`tooltip${index}`} className={`mb-3 ${modalSelectedWorkPiece.length > 0 ? modalSelectedWorkPiece.find(d => d.id == obj.id) === undefined ? "cursor-pointer" : "" : ""}`} onClick={(e) => handleModalSelectedWorkPiece_Fun(obj.id, obj.wpNo, obj.pgmPdcQuantity)}>
                                                <div className={`formula card-workpiece-modal-items${selectedWorkPiece.find(d => d.id == obj.id) !== undefined ? "-exists" : modalSelectedWorkPiece.find(d => d.id == obj.id) !== undefined ? "-selected" : ""} p-3`}>
                                                    <FontAwesomeIcon icon={faCheck} className={`formula-icon ${modalSelectedWorkPiece.length > 0 ? modalSelectedWorkPiece.find(d => d.id == obj.id) === undefined ? "d-none" : "" : "d-none"}`} />
                                                    <Row>
                                                        <Col className='d-flex justify-content-center'>
                                                            {
                                                                obj.images != null ?
                                                                    <img src={obj.images[0]} className={`img-workpiece ${selectedWorkPiece.length > 0 ? selectedWorkPiece.find(d => d.id == obj.id) !== undefined ? "opacity2" : "" : ""}`} />
                                                                    : <img src="/images/workpiece/image_no picture placeholder.svg" className={`img-workpiece`} />
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={`tCenter ${selectedWorkPiece.length > 0 ? selectedWorkPiece.find(d => d.id == obj.id) !== undefined ? "text-greyA" : "" : ""}`}>
                                                            <h4 className='mb-0'><b>{obj.wpName}</b></h4>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={`tCenter ${selectedWorkPiece.length > 0 ? selectedWorkPiece.find(d => d.id == obj.id) !== undefined ? "text-greyA" : "" : ""}`}>
                                                            <p className='mb-0'>{obj.wpNo}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`mt-3 ${modalSelectedWorkPiece.length > 0 ? modalSelectedWorkPiece.find(d => d.id == obj.id) !== undefined ? "text-blue" : "text-greyA" : "text-greyA"}`}>
                                                        <Col>
                                                            <p className='mb-0'>{t("dimension")}: {obj.wpDimension}</p>
                                                            <p className='mb-0'>{t("material")}: {obj.mtType01} ({obj.mtNo})</p>
                                                            <p className='mb-0'>{t("quantity")}: {obj.pgmPdcQuantity}</p>
                                                            <p className='mb-0'>{t("time")}: {formatTime(obj.durationPgmSubtotalSec)}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <ReactTooltip id={`tooltip${index}`} place="bottom" effect="solid" style={{ zIndex: 1000000 }}>
                                                工件名稱:{obj.wpName}<br />
                                                類別:{obj.wpCategory}<br />
                                                {obj.pgmDescription ? `備註:${obj.pgmDescription}` : <></>}
                                            </ReactTooltip>
                                        </>

                                    )
                                })
                                :
                                <>
                                    {
                                        modalKeywordWorkPiece != "" ?
                                            <Row className='text-unselected-div'>
                                                <Col className='d-flex justify-content-center align-items-center'>
                                                    <p className='text-unselected py-2 px-4'>{t("searchNotResult", { e: modalKeywordWorkPiece })}</p>
                                                </Col>
                                            </Row> :
                                            modalCategoriesWorkPieceVal != "" || modalMaterialsWorkPieceVal != "" || modalDimensionsWorkPieceVal != "" ?
                                                <Row className='text-unselected-div'>
                                                    <Col className='d-flex justify-content-center align-items-center'>
                                                        <p className='text-unselected py-2 px-4'>{t("noSearchResultFound")}</p>
                                                    </Col>
                                                </Row> :
                                                <Row className='text-unselected-div'>
                                                    <Col className='d-flex justify-content-center align-items-center'>
                                                        <p className='text-unselected py-2 px-4'>{t("noDataEstablished")}</p>
                                                    </Col>
                                                </Row>
                                    }
                                </>
                        }
                    </Row>
                    {/* {t("importWorkpiece")} */}
                </Modal.Body>
                <div className="modal-footer-custom">
                    <div className="btn-gp">
                        <div>
                            <button type="button" className="btn btn-txt-black" onClick={handleCloseModalWorkPiece_fun}>
                                {t("return")}
                            </button>
                        </div>
                        <div>
                            <button type="button" className="btn btn-lv1 fat" onClick={(e) => handleConfirmModalWorkPiece_fun(e)}
                                disabled={modalWorkPiece?.length === 0}>
                                {t("confirm")}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            {/*選擇工件 modal - end*/}

            {/*要移除這個工件嗎？ modal - start*/}
            <Modal show={showModalDelWorkpiece} onHide={handleDelWorkpieceCancel_fun} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <h3>{t("removeWorkpieceTitle")}</h3>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    {/* <h2><b>{t("removeWorkpieceTitle")}</b></h2> */}
                    <p className='mb-5'>{t("removeWorkpieceSubTitle")}</p>
                    {/* <h5 className='mb-5'>{t("removeWorkpieceSubTitle")}</h5> */}

                    {/* <Row className='mt-5 pt-3'>
                        <Col>
                            <button type="button" className="-secondary -formula" onClick={handleDelWorkpieceCancel_fun}>
                                <b>{t("cancel")}</b>
                            </button>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <button type="button" className="button-primary button-formula" onClick={handleDelWorkpieceConfirm_fun}>
                                <b>{t("remove")}</b>
                            </button>
                        </Col>
                    </Row> */}
                </Modal.Body>
                <div className="modal-footer-custom">
                    <div className="btn-gp">
                        <div>
                            <button type="button" className="btn btn-txt-black" onClick={handleDelWorkpieceCancel_fun}>
                                {t("cancel")}
                            </button>
                        </div>
                        <div>
                            <button type="button" className="btn btn-lv1 fat" onClick={handleDelWorkpieceConfirm_fun}>
                                {t("remove")}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            {/*要移除這個工件嗎？ modal - end*/}

            {/*調整成本數值 modal - start*/}
            <Modal show={showModalUpdateValue} onHide={handleCloseModalUpdateValue_fun} size="xl" backdrop="static" keyboard={false}>
                {
                    selectedWorkPiece[selectedModalUpdateValueIndex] ?
                        <>
                            <Modal.Body className='p-4'>
                                <Row>
                                    <Col>
                                        <h4 className='mb-0'><b>{t("adjustingCostValues")}</b></h4>
                                    </Col>
                                    <Col className='d-flex justify-content-end'>
                                        <FontAwesomeIcon icon={faXmark} className='text-dark fa-2x cursor-pointer' onClick={handleCloseModalUpdateValue_fun} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className='mb-1'>{selectedWorkPiece[selectedModalUpdateValueIndex].wpNo}</p>
                                        <p className='mb-0 text-greyA'>{t("adjustingCostValuesSubTitle")}</p>
                                    </Col>
                                </Row>
                                <hr className='hr' />
                                <Row>
                                    <Col>
                                        <p className='mb-0 text-greyA'>{t("EquipmentAndPersonnel")}</p>
                                    </Col>
                                </Row>
                                <Row className='mt-2 px-2'>
                                    <Col className='card-adjustingCost p-4'>
                                        <Row>
                                            <Col sm={6} xs={6} md={5} lg={3} xl={3} xxl={3}>
                                                <h5>{t("singleBatchProductionTime")}</h5>
                                            </Col>
                                            <Col sm={6} xs={6} md={3} lg={2} xl={2} xxl={2} className='tRight'>
                                                <h5>{formatTime(selectedWorkPiece[selectedModalUpdateValueIndex].durationPrePgmSec + (1 * (selectedWorkPiece[selectedModalUpdateValueIndex].durationPgmSec + selectedWorkPiece[selectedModalUpdateValueIndex].durationPgmWaitSec)))}</h5>
                                            </Col>
                                        </Row>
                                        <Row className='my-2'>
                                            <Col sm={6} xs={6} md={5} lg={3} xl={3} xxl={3}>
                                                <h5>{t("equipmentUsageFee")}</h5>
                                            </Col>
                                            <Col sm={6} xs={6} md={3} lg={2} xl={2} xxl={2} className='tRight'>
                                                <h5>$ {hourEquipmentUsageFee.singleVal}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6} xs={6} md={5} lg={3} xl={3} xxl={3}>
                                                <h5>{t("personnelCosts")}</h5>
                                            </Col>
                                            <Col sm={6} xs={6} md={3} lg={2} xl={2} xxl={2} className='tRight'>
                                                <h5>$ {hourPersonnelCosts.singleVal}</h5>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={6} xs={6} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'>
                                        <h5>{t("utilizedEquipment")}</h5>
                                    </Col>
                                    <Col sm={6} xs={6} md={3} lg={3} xl={3} xxl={3}>
                                        <select className='custom-dropdown-adjustingCost w-100' name="selMachine" onChange={(e) => handleOnChange(e)} value={currentMachineId}>
                                            {
                                                selectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrMachineServiceCost.length > 0 ?
                                                    selectedWorkPiece[selectedModalUpdateValueIndex].objReference.arrMachineServiceCost.map((obj, index) => {
                                                        return (
                                                            <option value={obj.id} key={index}>{obj.machineSn}</option>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                            }
                                        </select>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={6} xs={6} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'>
                                        <h5>{t("hourEquipmentUsageFee")}</h5>
                                    </Col>
                                    <Col sm={6} xs={6} md={3} lg={3} xl={3} xxl={3}>
                                        <Row>
                                            <Col sm={1} xs={1} md={1} lg={1} xl={1} xxl={1} className='d-flex align-items-center'>
                                                <h5>$</h5>
                                            </Col>
                                            <Col>
                                                <input className="input-text w-100 float-end" value={hourEquipmentUsageFee.inputVal}
                                                    name="hourEquipmentUsageFee"
                                                    ref={hourEquipmentUsageFeeRef}
                                                    onChange={(e) => handleCalAdjustingCost(e)}
                                                    onBlur={(e) => blurCalAdjustingCost(e)}
                                                    onKeyDown={(e) => handleKeyPress(e, "hourEquipmentUsageFee")}
                                                    onClick={(e) => selectAllText(e, "hourEquipmentUsageFee")}
                                                    autoComplete='off'
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={6} xs={6} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'>
                                        <h5>{t("hourPersonnelCosts")}</h5>
                                    </Col>
                                    <Col sm={6} xs={6} md={3} lg={3} xl={3} xxl={3}>
                                        <Row>
                                            <Col sm={1} xs={1} md={1} lg={1} xl={1} xxl={1} className='d-flex align-items-center'>
                                                <h5>$</h5>
                                            </Col>
                                            <Col>
                                                <input className="input-text w-100 float-end" value={hourPersonnelCosts.inputVal}
                                                    name="hourPersonnelCosts"
                                                    ref={hourPersonnelCostsRef}
                                                    onChange={(e) => handleCalAdjustingCost(e)}
                                                    onBlur={(e) => blurCalAdjustingCost(e)}
                                                    onKeyDown={(e) => handleKeyPress(e, "hourPersonnelCosts")}
                                                    onClick={(e) => selectAllText(e, "hourPersonnelCosts")}
                                                    autoComplete='off'
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr className='hr' />
                                <Row>
                                    <Col>
                                        <p className='mb-0 text-greyA'>{t("material2")}</p>
                                    </Col>
                                </Row>
                                <Row className='mt-2 px-2'>
                                    <Col className='card-adjustingCost p-4'>
                                        <Row>
                                            <Col sm={6} xs={6} md={5} lg={3} xl={3} xxl={3}>
                                                <h5>{t("materialAndFixtureCosts")}</h5>
                                            </Col>
                                            <Col sm={6} xs={6} md={3} lg={2} xl={2} xxl={2} className='tRight'>
                                                <h5>$ {materialCosts.format}</h5>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={6} xs={6} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'>
                                        <h5>{t("unitMaterialCost")}</h5>
                                    </Col>
                                    <Col sm={6} xs={6} md={3} lg={3} xl={3} xxl={3}>
                                        <Row>
                                            <Col sm={1} xs={1} md={1} lg={1} xl={1} xxl={1} className='d-flex align-items-center'>
                                                <h5>$</h5>
                                            </Col>
                                            <Col>
                                                <input className="input-text w-100 float-end" value={materialCosts.inputVal}
                                                    name="materialCosts"
                                                    ref={materialCostsRef}
                                                    onChange={(e) => handleCalAdjustingCost(e)}
                                                    onBlur={(e) => blurCalAdjustingCost(e)}
                                                    onKeyDown={(e) => handleKeyPress(e, "materialCosts")}
                                                    onClick={(e) => selectAllText(e, "materialCosts")}
                                                    autoComplete='off'
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr className='hr' />
                                <Row>
                                    <Col>
                                        <p className='mb-0 text-greyA'>{t("tool")}</p>
                                    </Col>
                                </Row>
                                <Row className='mt-2 px-2'>
                                    <Col className='card-adjustingCost p-4'>
                                        {(() => {
                                            let result = [];
                                            let tool = [];
                                            let totalFee = 0;
                                            for (let i = 0; i < updateValueTools.length; i++) {
                                                totalFee += parseInt(removeThousandsSeparator(updateValueTools[i].stringPrice));
                                                tool.push(
                                                    <Row className='mb-1' key={i}>
                                                        <Col sm={6} xs={6} md={5} lg={3} xl={3} xxl={3}>
                                                            {updateValueTools[i].toolName}&nbsp;<span className='text-blue'>x{Math.ceil(updateValueTools[i].quantity * 1000) / 1000}</span>
                                                        </Col>
                                                        <Col sm={6} xs={6} md={3} lg={2} xl={2} xxl={2} className='d-flex justify-content-end'>
                                                            $&nbsp;{updateValueTools[i].stringPrice}
                                                        </Col>
                                                    </Row>
                                                );
                                            }

                                            result.push(
                                                <Row key={updateValueTools.length}>
                                                    <Col sm={6} xs={6} md={5} lg={3} xl={3} xxl={3}>
                                                        <h5>{t("SubtotalToolCost")}</h5>
                                                    </Col>
                                                    <Col sm={6} xs={6} md={3} lg={2} xl={2} xxl={2} className='tRight'>
                                                        <h5>$ {addThousandsSeparator(totalFee)}</h5>
                                                    </Col>
                                                </Row>
                                            );
                                            result.push(tool);
                                            return result;
                                        })()}

                                    </Col>
                                </Row>

                                {(() => {
                                    let result = [];
                                    let inputTool = [];
                                    for (let i = 0; i < updateValueTools.length; i++) {
                                        inputTool.push(
                                            <Row className='mt-3' key={i}>
                                                <Col sm={6} xs={6} md={3} lg={3} xl={3} xxl={3} className='d-flex align-items-center'>
                                                    <h5>{updateValueTools[i].toolName}</h5>
                                                </Col>
                                                <Col sm={6} xs={6} md={2} lg={2} xl={2} xxl={2}>
                                                    <Row>
                                                        <Col sm={1} xs={1} md={1} lg={1} xl={1} xxl={1} className='d-flex align-items-center'>
                                                            <h5>$</h5>
                                                        </Col>
                                                        <Col>
                                                            <input className="input-text w-100 float-end" value={updateValueTools[i].inputVal}
                                                                name="updateValueTools"
                                                                key={i}
                                                                ref={createInputRef(i)}
                                                                onChange={(e) => handleCalAdjustingCost(e, i)}
                                                                onBlur={(e) => blurCalAdjustingCost(e, i)}
                                                                onKeyDown={(e) => handleKeyPress(e, "updateValueTools", i)}
                                                                onClick={(e) => selectAllText(e, "updateValueTools", i)}
                                                                autoComplete='off'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        );

                                    }

                                    result.push(inputTool);
                                    return result;
                                })()}

                            </Modal.Body>
                            <div className="modal-footer-custom">
                                <div className="btn-gp">
                                    <div>
                                        <button type="button" className="btn btn-txt-black" onClick={handleCloseModalUpdateValue_fun}>
                                            {t("cancel")}
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-lv1 fat" onClick={(e) => handleConfirmModalWorkPieceUpdateValue_fun(e)}>
                                            {t("apply")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <Modal.Footer>
                                <hr />

                                <Row className='mt-4'>
                                    <Col>
                                        <button type="button" className="button-secondary button-formula" onClick={handleCloseModalUpdateValue_fun}>
                                            <b>{t("cancel")}</b>
                                        </button>
                                    </Col>
                                    <Col className='d-flex justify-content-end'>
                                        <button type="button" className="button-primary button-formula" onClick={(e) => handleConfirmModalWorkPieceUpdateValue_fun(e)}>
                                            <b>{t("apply")}</b>
                                        </button>
                                    </Col>
                                </Row>
                            </Modal.Footer> */}
                        </>
                        : ""
                }

            </Modal>
            {/*調整成本數值 modal - end*/}

            {/*檔案名稱 modal - start*/}
            <Modal show={showModalPDFfileName} onHide={handlePDFfileNameCancel_fun} backdrop="static" keyboard={false} centered={true}>
                <Modal.Body className='p-4'>
                    <Row>
                        <Col>
                            <h4 className='mb-0'><b>{t("fileName")}</b></h4>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <FontAwesomeIcon icon={faXmark} className='text-dark fa-2x cursor-pointer' onClick={handlePDFfileNameCancel_fun} />
                        </Col>
                    </Row>
                    <input className={`input-text-number w-100 my-3 ${judgeCheckErr && !regex.test(tempPdfFileName?.trim()) ? "input-error" : ""}`} value={tempPdfFileName}
                        name="tempPdfFileName"
                        onChange={(e) => handleCalAdjustingCost(e)}
                        onBlur={(e) => blurCalAdjustingCost(e)}
                        autoComplete='off'
                    />
                    {
                        judgeCheckErr && !regex.test(tempPdfFileName?.trim()) ?
                            <Form.Label className='text-danger mb-0'>
                                {t("specialCharactersError")}
                            </Form.Label> :
                            <></>
                    }

                    {/* <Row className='mt-5 pt-3'>
                        <Col>
                            <button type="button" className="button-secondary button-formula" onClick={handlePDFfileNameCancel_fun}>
                                <b>{t("cancel")}</b>
                            </button>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <button type="button" className="button-primary button-formula" onClick={handleConfirmDownloadPDF_fun}>
                                <b>{t("download")}</b>
                            </button>
                        </Col>
                    </Row> */}
                </Modal.Body>
                <div className="modal-footer-custom">
                    <div className="btn-gp">
                        <div>
                            <button type="button" className="btn btn-txt-black" onClick={handlePDFfileNameCancel_fun}>
                                {t("cancel")}
                            </button>
                        </div>
                        <div>
                            <button type="button" className="btn btn-lv1 fat" onClick={handleConfirmDownloadPDF_fun}>
                                {t("download")}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            {/*檔案名稱 modal - end*/}

            {/*圖檔命名範例 modal - start*/}
            <Modal show={showModalFileNameTemplate} onHide={handlefileNameTemplate_fun} backdrop="static" keyboard={false} centered={true}>
                <Modal.Body className='p-4'>
                    <Row>
                        <Col>
                            <h4 className='text-grey4'><b>圖檔命名範例</b></h4>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <FontAwesomeIcon icon={faXmark} className='text-dark fa-2x cursor-pointer' onClick={handlefileNameTemplate_fun} />
                        </Col>
                    </Row>
                    <p>
                        圖片的「檔案名稱」須與「工件代號」完全相符，且不可在名稱後面額外加上任何數字、符號、空白鍵。<br />*檔案格式：僅限 png、 jpg、 jpeg、gif。
                    </p>
                    <hr />
                    <p>
                        【範例】<br />工件名稱：END MILL 3f-85/245<br />工件代號：EM-A05<br /><span className='text-success-msg'>正確</span>圖檔名稱：EM-A05.png<br />
                        <span className='text-alert'>錯誤</span>圖檔名稱：EM-A05_1.png、EM-A05 (2).png、A05.png、EM.png
                    </p>
                </Modal.Body>
            </Modal>
            {/*圖檔命名範例 modal - end*/}

            {/* 使用原本計算方式 */}
            <MessageModal
                title={t("useOriginCalWay")}
                content={`${t("useOriginCalWayContent1")}<br />${t("useOriginCalWayContent2")}`}
                showModal={showConfirmCalWayModal}
                handleConfirmData={handleConfirmCalWay}
                size="xs"
                confirmModalTxt={t("confirm")}
                cancelTxt={t("return")}
                cancelModal={handleReturnCalWay}
                handleClose={handleConfirmCalWayClose}
            />
        </>
    );
}


//#region 下載Excel
const MyDoc = ({ selectedWorkPiece, confirmFormula, selectedWorkPieceMachine, tempPdfFileName }) => {
    // const [totalPriceGlobal, setTotalPriceGlobal] = useState(0); //總成本小計
    // const [totalWorkHoursGlobal, setTotalWorkHoursGlobal] = useState(0); //預估工時
    // const [totalQuantityGlobal, setTotalQuantityGlobal] = useState(0); //製作總數

    Font.register({
        family: "MicrosoftBlack",
        src: font
    });

    const styles = {
        page: {
            fontFamily: "MicrosoftBlack",
            fontSize: 10,
            padding: 20
        },
        fontSize14: {
            fontSize: 14
        },
        marginTop5: {
            marginTop: 5
        },
        marginTop15: {
            marginTop: 15
        },
        marginLeft8: {
            marginLeft: 8
        },
        lineHeight100: {
            lineHeight: 100
        },
        padding5: {
            padding: 5
        },
        table: {
            display: "flex",
            flexDirection: "column",
            border: "1px solid black",
            width: "auto"
        },
        tableRowHeader: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#D6DEFF"
            // fontWeight: "bold"
        },
        tableRow: {
            display: "flex",
            flexDirection: "row"
        },
        tableCol: {
            border: "1px solid black"
        },
        flex1: {
            flex: "1",
        },
        flex2: {
            flex: "2"
        },
        flex3: {
            flex: "3"
        },
        flex4: {
            flex: "4"
        },
        flex5: {
            flex: "5"
        },
        flex7: {
            flex: "7"
        },
        flex12: {
            flex: "12"
        },
        tableCell: {
            fontSize: 10,
            padding: 2,
            // border: "1px solid black"
        },
        textLeft: {
            textAlign: "left"
        },
        textCenter: {
            textAlign: "center"
        },
        textRight: {
            textAlign: "right"
        },
        backgroundColorPurple: {
            backgroundColor: "#D6DEFF"
        },
        backgroundColorOrange: {
            backgroundColor: "orange"
        },
        borderLeft1: {
            borderLeft: "1px solid black"
        },
        container: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        image: {
            width: 121,
            height: 94,
        }
    };

    let totalPrice = 0;  //成本小計
    let totalWorkHours = 0; //預估工時
    let totalQuantity = 0;  //製作總數
    let totalFeeArry = []; //單顆成本(全部)
    let batchSingleCosts = []; //批量單顆成本(全部)
    let totalBatchCosts = []; //成本小計(全部)
    let totalProductionDuration = []; //製作時長(全部)
    let totalRemark = []; //備註(全部)
    let totalRemarkText = []; //下方備註(全部)
    let remarkIndex = 1;
    let machine = []; //使用設備(全部)
    let equipmentCostSingleArry = []; //設備使用費(單顆)
    let equipmentCostArry = []; //設備使用費(批量)
    let operatorCostSingleArry = []; //人員費用(單顆)
    let operatorCostArry = []; //人員費用(批量)
    let materialFeeSingleArry = []; //素材費用(單顆)
    let materialFeeArry = []; //素材費用(批量)
    let singleToolCostArry = []; //刀具費用
    let toolArry = []; //刀具明細
    let quantityArry = []; //刀具使用量
    let priceArry = []; //刀具小計

    if (selectedWorkPiece.length > 0) {
        for (let i = 0; i < selectedWorkPiece.length; i++) {
            let obj = selectedWorkPiece[i]; //obj選擇工件列表

            let totalFee = 0; //單顆成本
            let batchCost = 0; //批量成本

            let equipmentCost = 0; //設備使用費(單顆)
            let operatorCost = 0; //人員費用(單顆)
            let materialFee = 0; //素材費用(單顆)

            let equipmentCostMultiple = 0; //設備使用費(批量)
            let operatorCostMultiple = 0; //人員費用(批量)
            let materialFeeMultiple = 0; //素材費用(批量)

            let singleToolCost = 0;  //刀具費用
            let singleToolCostBulk = 0; //刀具費用(不算批量次數)

            let arr = obj.objReference.arrToolCost; //API刀具列表
            let productionDuration = 0; //製作時長(秒數顯示)
            let multiple = 0; //倍數

            let toolQuantity = 0; //刀具數量

            //輸入數量輸入框CSS錯誤
            let errCss = "";
            let calcQuantity = obj.intQuantity % obj.pgmPdcQuantity;
            if (obj.intQuantity == 0) {
                errCss = "warning";
            }
            else if (calcQuantity != 0) {
                errCss = "alert";
            }

            if (errCss == "") {
                multiple = obj.intQuantity / obj.pgmPdcQuantity;
                productionDuration = obj.durationPrePgmSec + (multiple * (obj.durationPgmSec + obj.durationPgmWaitSec));
                totalQuantity += obj.intQuantity;
            }

            if (arr.length > 0) {
                let list = [];
                let quantityCal = 0;
                let priceCal = 0;

                //刀具費用
                for (let arrObj of arr) {
                    let quantity = 1; //計算數量(不是刀具用的)
                    let singleToolQuantity = 1; //刀具數量
                    let singleToolQuantityNotBulk = 1; //刀具數量(不算批量次數)
                    let singleToolFee = 0;
                    let singleToolFeeNotBulk = 0;

                    if (obj.pgmPdcQuantity === 1) {
                        if (parseFloat(arrObj.pgtaPercentage) != 0) {
                            quantity = arrObj.pgtaPercentage * obj.pgmPdcQuantity;

                            let quotient = obj.intQuantity / obj.pgmPdcQuantity;
                            singleToolQuantity = arrObj.pgtaPercentage * quotient;

                            if (confirmFormula != "C") { //數量要進位
                                quantity = Math.ceil(quantity);
                                singleToolQuantity = Math.ceil(singleToolQuantity);
                            }
                        }
                        else {
                            if (confirmFormula == "B") {
                                quantity = 0;
                                singleToolQuantity = 0;
                            }
                            else if (confirmFormula == "C") {
                                quantity = 0.001;
                                singleToolQuantity = 0.001;
                            }
                        }

                        singleToolFee = Math.ceil(quantity * arrObj.unitPrice);
                        totalFee += singleToolFee;

                        toolQuantity += singleToolQuantity;

                        if (parseFloat(arrObj.pgtaPercentage) != 0) {
                            quantity = arrObj.pgtaPercentage * obj.intQuantity;

                            let quotient = obj.intQuantity / obj.pgmPdcQuantity;
                            singleToolQuantity = arrObj.pgtaPercentage * quotient;

                            if (confirmFormula != "C") { //數量要進位
                                quantity = Math.ceil(quantity);
                                singleToolQuantity = Math.ceil(singleToolQuantity);
                            }
                        }
                        else {
                            if (confirmFormula == "B") {
                                quantity = 0;
                                singleToolQuantity = 0;
                            }
                            else if (confirmFormula == "C") {
                                quantity = 0.001;
                                singleToolQuantity = 0.001;
                            }
                        }

                        singleToolFee = Math.ceil(singleToolQuantity * arrObj.unitPrice);
                        batchCost += singleToolFee;
                    }
                    else {
                        if (parseFloat(arrObj.pgtaPercentage) != 0) {
                            quantity = arrObj.pgtaPercentage * obj.intQuantity;

                            let quotient = obj.intQuantity / obj.pgmPdcQuantity;
                            singleToolQuantity = arrObj.pgtaPercentage * quotient;
                            singleToolQuantityNotBulk = isNullCal(arrObj.pgtaPercentage);

                            if (confirmFormula != "C") { //數量要進位
                                quantity = Math.ceil(quantity);
                                singleToolQuantity = Math.ceil(singleToolQuantity);
                                singleToolQuantityNotBulk = Math.ceil(singleToolQuantityNotBulk);
                            }
                        }
                        else {
                            if (confirmFormula == "B") {
                                quantity = 0;
                                singleToolQuantity = 0;
                                singleToolQuantityNotBulk = 0;
                            }
                            else if (confirmFormula == "C") {
                                quantity = 0.001;
                                singleToolQuantity = 0.001;
                                singleToolQuantityNotBulk = 0.001;
                            }
                        }

                        singleToolFee = Math.ceil(singleToolQuantity * arrObj.unitPrice);
                        totalFee += singleToolFee;
                        toolQuantity += singleToolQuantity;

                        singleToolFeeNotBulk = Math.ceil(singleToolQuantityNotBulk * isNullCal(arrObj.unitPrice));
                        singleToolCostBulk += singleToolFeeNotBulk;
                    }

                    list.push({
                        item: arrObj.ttNo,
                        price: arrObj.unitPrice,
                        quantity: singleToolQuantity,
                        total: singleToolFee
                    });

                    quantityCal += singleToolQuantity;
                    priceCal += singleToolFee;
                }

                // for(let j = 0; j < arr.length; j++){
                //     let quantity = 1; //計算數量(不是刀具用的)
                //     let singleToolQuantity = 1; //刀具數量
                //     let singleToolFee = 0;

                //     if(obj.pgmPdcQuantity === 1){
                //         if(parseFloat(arr[j].pgtaPercentage) != 0){
                //             quantity = arr[j].pgtaPercentage * obj.pgmPdcQuantity;

                //             let quotient = obj.intQuantity / obj.pgmPdcQuantity;
                //             singleToolQuantity = arr[j].pgtaPercentage * quotient;

                //             if(confirmFormula != "C"){ //數量要進位
                //                 quantity = Math.ceil(quantity);
                //                 singleToolQuantity = Math.ceil(singleToolQuantity);
                //             }
                //         }
                //         else{
                //             if(confirmFormula == "B"){
                //                 quantity = 0;
                //                 singleToolQuantity = 0;
                //             }
                //             else if(confirmFormula == "C"){
                //                 quantity = 0.001;
                //                 singleToolQuantity = 0.001;
                //             }
                //         }

                //         singleToolFee = Math.ceil(quantity * arr[j].unitPrice);
                //         totalFee += singleToolFee;

                //         toolQuantity += singleToolQuantity;

                //         if(parseFloat(arr[j].pgtaPercentage) != 0){
                //             quantity = arr[j].pgtaPercentage * obj.intQuantity;

                //             let quotient = obj.intQuantity / obj.pgmPdcQuantity;
                //             singleToolQuantity = arr[j].pgtaPercentage * quotient;

                //             if(confirmFormula != "C"){ //數量要進位
                //                 quantity = Math.ceil(quantity);
                //                 singleToolQuantity = Math.ceil(singleToolQuantity);
                //             }
                //         }
                //         else{
                //             if(confirmFormula == "B"){
                //                 quantity = 0;
                //                 singleToolQuantity = 0;
                //             }
                //             else if(confirmFormula == "C"){
                //                 quantity = 0.001;
                //                 singleToolQuantity = 0.001;
                //             }
                //         }

                //         singleToolFee = Math.ceil(singleToolQuantity * arr[j].unitPrice);
                //         batchCost += singleToolFee;
                //     }
                //     else{
                //         if(parseFloat(arr[j].pgtaPercentage) != 0){
                //             quantity = arr[j].pgtaPercentage * obj.intQuantity;

                //             // let quotient = obj.intQuantity / obj.pgmPdcQuantity;
                //             singleToolQuantity = arr[j].pgtaPercentage;

                //             if(confirmFormula != "C"){ //數量要進位
                //                 quantity = Math.ceil(quantity);
                //                 singleToolQuantity = Math.ceil(singleToolQuantity);
                //             }
                //         }
                //         else{
                //             if(confirmFormula == "B"){
                //                 quantity = 0;
                //                 singleToolQuantity = 0;
                //             }
                //             else if(confirmFormula == "C"){
                //                 quantity = 0.001;
                //                 singleToolQuantity = 0.001;
                //             }
                //         }

                //         singleToolFee = Math.ceil(singleToolQuantity * arr[j].unitPrice);
                //         totalFee += singleToolFee;

                //         toolQuantity += singleToolQuantity;
                //     }

                //     list.push({
                //         item: arr[j].ttNo,
                //         price: arr[j].unitPrice,
                //         quantity: singleToolQuantity,
                //         total: singleToolFee
                //     });

                //     quantityCal += singleToolQuantity;
                //     priceCal += singleToolFee;
                // }

                quantityArry.push(quantityCal);
                priceArry.push(priceCal);

                toolArry.push({
                    id: obj.id,
                    list: list
                });
            }

            let arrMachineServiceCost = obj.objReference.arrMachineServiceCost.find(d => d.id == selectedWorkPieceMachine[i]);
            machine.push(arrMachineServiceCost);


            if (obj.objReference.arrMaterialCost.length > 0) {
                materialFee = obj.objReference.arrMaterialCost[0].price * obj.pgmPdcQuantity;
            }

            if (arrMachineServiceCost) {
                equipmentCost = Math.ceil((arrMachineServiceCost.equipmentCost / 3600) * (obj.durationPrePgmSec + (1 * (obj.durationPgmSec + obj.durationPgmWaitSec))));
                operatorCost = Math.ceil((arrMachineServiceCost.operatorCost / 3600) * (obj.durationPrePgmSec + (1 * (obj.durationPgmSec + obj.durationPgmWaitSec))));
            }

            if (obj.objReference.arrMaterialCost.length > 0) {
                materialFeeMultiple = obj.objReference.arrMaterialCost[0].price * obj.intQuantity;
            }


            if (arrMachineServiceCost) {
                equipmentCostMultiple = Math.ceil((arrMachineServiceCost.equipmentCost / 3600) * productionDuration);
                operatorCostMultiple = Math.ceil((arrMachineServiceCost.operatorCost / 3600) * productionDuration);
            }


            if (obj.pgmPdcQuantity == 1) {
                singleToolCost = batchCost;
                singleToolCostBulk = batchCost;
            }
            else {
                singleToolCost = totalFee;
            }

            totalFee = Math.ceil((singleToolCostBulk + equipmentCost + operatorCost + materialFee) / obj.pgmPdcQuantity);
            batchCost = Math.ceil((singleToolCost + equipmentCostMultiple + operatorCostMultiple + materialFeeMultiple));

            if (obj.intQuantity > obj.pgmPdcQuantity) {
                totalPrice += batchCost;
            }
            else {
                if (errCss == "") {
                    totalPrice += totalFee;
                }
            }

            totalWorkHours += productionDuration;

            totalFeeArry.push(totalFee);
            if (errCss == "" && batchCost > 0 && obj.intQuantity > obj.pgmPdcQuantity) {
                batchSingleCosts.push((Math.ceil(batchCost / obj.intQuantity)).toString());
            }
            else {
                batchSingleCosts.push("");
            }

            if (obj.intQuantity != "") {
                totalBatchCosts.push(batchCost);
            }

            totalProductionDuration.push(productionDuration);

            if (obj.pgmDescription != null) {
                totalRemark.push(`*${remarkIndex.toString()}`);
                totalRemarkText.push(`*${remarkIndex.toString()}.${obj.pgmDescription}`);
                remarkIndex++;
            }
            else {
                totalRemark.push("")
            }

            equipmentCostSingleArry.push(equipmentCost);
            equipmentCostArry.push(equipmentCostMultiple);
            operatorCostSingleArry.push(operatorCost);
            operatorCostArry.push(operatorCostMultiple);
            materialFeeSingleArry.push(materialFee);
            materialFeeArry.push(materialFeeMultiple);
            singleToolCostArry.push(singleToolCost);
        }
    }

    //#endregion

    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <Text>匯出時間：{getCurrentTimeInUSFormat()}</Text>
                <Text style={styles.marginTop5}>檔案名稱：{tempPdfFileName}</Text>
                <View style={[styles.table, styles.marginTop15]}>
                    <View style={[styles.tableRowHeader, styles.textCenter]}>
                        <View style={[styles.tableCol, styles.flex1]}>
                            <Text style={styles.tableCell}>項</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>工件編號</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex3]}>
                            <Text style={styles.tableCell}>工件名稱</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>建議售價</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>單顆成本</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>批量的單顆成本</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex1]}>
                            <Text style={styles.tableCell}>數量</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>批量成本小計</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>製作時間</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex1]}>
                            <Text style={styles.tableCell}>備註</Text>
                        </View>
                    </View>
                    {
                        selectedWorkPiece.length > 0 ?
                            selectedWorkPiece.map((obj, index) => {
                                return (
                                    obj.intQuantity != "" ?
                                        <View style={styles.tableRow} key={index}>
                                            <View style={[styles.tableCol, styles.flex1, styles.textCenter]}>
                                                <Text style={styles.tableCell}>{index + 1}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={[styles.tableCell]}>{obj.wpNo}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.wpName}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <View style={styles.tableRow}>
                                                    <View style={[styles.flex1]}>
                                                        <Text style={styles.tableCell}>$</Text>
                                                    </View>
                                                    <View style={[styles.textRight, styles.flex3]}>
                                                        <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.wpPrice)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <View style={styles.tableRow}>
                                                    <View style={[styles.flex1]}>
                                                        <Text style={styles.tableCell}>$</Text>
                                                    </View>
                                                    <View style={[styles.textRight, styles.flex3]}>
                                                        <Text style={[styles.tableCell]}>{addThousandsSeparator(totalFeeArry[index])}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                {
                                                    batchSingleCosts[index] != "" ?
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>{addThousandsSeparator(batchSingleCosts[index])}</Text>
                                                            </View>
                                                        </View> :
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.textRight, styles.flex1]}>
                                                                <Text style={[styles.tableCell]}>-</Text>
                                                            </View>
                                                        </View>
                                                }

                                            </View>
                                            <View style={[styles.tableCol, styles.flex1]}>
                                                <Text style={[styles.tableCell, styles.textRight]}>{obj.intQuantity}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <View style={styles.tableRow}>
                                                    <View style={[styles.flex1]}>
                                                        <Text style={styles.tableCell}>$</Text>
                                                    </View>
                                                    <View style={[styles.textRight, styles.flex3]}>
                                                        <Text style={[styles.tableCell]}>{addThousandsSeparator(totalBatchCosts[index])}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2, styles.textRight]}>
                                                <Text style={styles.tableCell}>{formatTime(totalProductionDuration[index])}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex1]}>
                                                <Text style={styles.tableCell}>{totalRemark[index]}</Text>
                                            </View>
                                        </View> : <></>
                                )
                            })
                            :
                            <></>
                    }
                    <View style={[styles.tableRow, styles.textRight]}>
                        <View style={[styles.tableCol, styles.flex12, styles.backgroundColorPurple]}>
                            <Text style={styles.tableCell}>總計</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex1]}>
                            <Text style={styles.tableCell}>{totalQuantity}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>{addThousandsSeparator(totalPrice)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex2]}>
                            <Text style={styles.tableCell}>{formatTime(totalWorkHours)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flex1]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                </View>

                <View style={[styles.table, styles.marginTop15, styles.padding5]}>
                    <Text>備註：</Text>
                    {(() => {
                        return totalRemarkText.map((text, index) => <Text key={index}>{text}</Text>);
                        // let resultObj = [];
                        // totalRemarkText.map((text, index) => {
                        //     resultObj.push(<Text>{text}</Text>)
                        // })
                        // return (resultObj);
                    })()}
                </View>
            </Page>
            {
                selectedWorkPiece.length > 0 ?
                    selectedWorkPiece.map((obj, index) => {
                        return (
                            obj.intQuantity != "" ?
                                <Page size="A4" style={styles.page} key={index}>
                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.flex1]}>
                                            <View style={[styles.borderLeft1]}>
                                                <Text style={[styles.marginLeft8]}>工件名稱/代號:</Text>
                                                <Text style={[styles.fontSize14, styles.marginLeft8, styles.marginButtom30]}>{obj.wpName}</Text>
                                                <Text style={styles.marginLeft8}>{obj.wpNo}</Text>
                                            </View>
                                        </View>
                                        <View style={[styles.flex1]}>
                                            <View style={styles.container}>
                                                {obj.images && obj.images.length > 0 ? <Image src={obj.images[0]} style={styles.image} /> : <></>}
                                            </View>
                                        </View>
                                    </View>

                                    <View style={[styles.marginTop15]}>
                                        <Text>1.詢價數量：{obj.intQuantity}</Text>
                                        <View style={[styles.table]}>
                                            <View style={[styles.tableRowHeader, styles.textCenter]}>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>項目</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>單顆金額</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex1]}>
                                                    <Text style={styles.tableCell}>數量</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>小計</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>備註</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>單顆建議售價</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.wpPrice)}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex1, styles.textRight]}>
                                                    <Text style={styles.tableCell}>{obj.intQuantity}</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.wpPrice * obj.intQuantity)}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}></Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>單顆成本</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(totalFeeArry[index])}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex1, styles.textRight]}>
                                                    <Text style={styles.tableCell}>{obj.intQuantity}</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(totalFeeArry[index] * obj.intQuantity)}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>
                                                        {
                                                            (totalFeeArry[index] * obj.intQuantity) > (obj.wpPrice * obj.intQuantity) ? "成本高於建議售價" : ""
                                                        }
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>批量成本</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    {
                                                        batchSingleCosts[index] != "" ?
                                                            <View style={styles.tableRow}>
                                                                <View style={[styles.flex1]}>
                                                                    <Text style={styles.tableCell}>$</Text>
                                                                </View>
                                                                <View style={[styles.textRight, styles.flex3]}>
                                                                    <Text style={[styles.tableCell]}>{addThousandsSeparator(batchSingleCosts[index])}</Text>
                                                                </View>
                                                            </View> :
                                                            <View style={styles.tableRow}>
                                                                <View style={[styles.textRight, styles.flex1]}>
                                                                    <Text style={[styles.tableCell]}>-</Text>
                                                                </View>
                                                            </View>
                                                    }
                                                </View>
                                                <View style={[styles.tableCol, styles.flex1, styles.textRight]}>
                                                    <Text style={styles.tableCell}>{batchSingleCosts[index] != "" ? obj.intQuantity : "-"}</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    {
                                                        batchSingleCosts[index] != "" ?
                                                            <View style={styles.tableRow}>
                                                                <View style={[styles.flex1]}>
                                                                    <Text style={styles.tableCell}>$</Text>
                                                                </View>
                                                                <View style={[styles.textRight, styles.flex3]}>
                                                                    <Text style={[styles.tableCell]}>{addThousandsSeparator(batchSingleCosts[index] * obj.intQuantity)}</Text>
                                                                </View>
                                                            </View> :
                                                            <View style={styles.tableRow}>
                                                                <View style={[styles.textRight, styles.flex1]}>
                                                                    <Text style={[styles.tableCell]}>-</Text>
                                                                </View>
                                                            </View>
                                                    }
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>
                                                        {
                                                            batchSingleCosts[index] != "" ?
                                                                `為單顆成本的${(batchSingleCosts[index] / totalFeeArry[index] * 100).toFixed(1)}%` :
                                                                ""
                                                        }
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={[styles.marginTop15]}>
                                        <Text>2.基本資訊</Text>
                                        <View style={[styles.tableRowHeader, styles.textCenter]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>項目</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>說明</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>備註</Text>
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>材質</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.mtType01}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}></Text>
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>類別</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.wpCategory}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}></Text>
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>尺寸</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.wpDimension}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}></Text>
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>單批製作數量</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.pgmPdcQuantity}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}></Text>
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>加工程序</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.pgmNo}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}></Text>
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>素材</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.mtNo}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                {
                                                    obj.objReference.arrMaterialCost.length > 0 ?
                                                        <>
                                                            <Text style={styles.tableCell}>材料: {obj.objReference.arrMaterialCost[0].mtType01}({obj.objReference.arrMaterialCost[0].mtNo2})</Text>
                                                            <Text style={styles.tableCell}>尺寸: {obj.objReference.arrMaterialCost[0].dimension} {obj.objReference.arrMaterialCost[0].dimensionUnit}&nbsp;mm</Text>
                                                            <Text style={styles.tableCell}>重量: {obj.objReference.arrMaterialCost[0].weight} {obj.objReference.arrMaterialCost[0].weightUnit}&nbsp;kg</Text>
                                                        </> : ""
                                                }
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>使用設備</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{machine[index] ? machine[index].machineSn : ""}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}></Text>
                                            </View>
                                        </View>
                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}>工件特徵</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex3]}>
                                                <Text style={styles.tableCell}>{obj.wpCharacteristics}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.flex2]}>
                                                <Text style={styles.tableCell}></Text>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={[styles.marginTop15]}>
                                        <Text>3.成本結構</Text>
                                        <View style={[styles.tableCol, styles.padding5]}>
                                            <View style={[styles.tableRowHeader, styles.textCenter]}>
                                                <View style={[styles.tableCol, styles.flex1]}>
                                                    <Text style={styles.tableCell}>項</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>項目</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>小計</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}>備註</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex1]}>
                                                    <Text style={styles.tableCell}>(1)</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>設備使用費</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? equipmentCostArry[index] : equipmentCostSingleArry[index])}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}></Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex1]}>
                                                    <Text style={styles.tableCell}>(2)</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>人員費用</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? operatorCostArry[index] : operatorCostSingleArry[index])}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}></Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex1]}>
                                                    <Text style={styles.tableCell}>(3)</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>素材費用</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? materialFeeArry[index] : materialFeeSingleArry[index])}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}></Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex1]}>
                                                    <Text style={styles.tableCell}>(4)</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex3]}>
                                                    <Text style={styles.tableCell}>刀具費用</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>{addThousandsSeparator(singleToolCostArry[index])}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}></Text>
                                                </View>
                                            </View>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableCol, styles.flex4, styles.textRight, styles.backgroundColorPurple]}>
                                                    <Text style={styles.tableCell}>總計</Text>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.flex1]}>
                                                            <Text style={styles.tableCell}>$</Text>
                                                        </View>
                                                        <View style={[styles.textRight, styles.flex3]}>
                                                            <Text style={[styles.tableCell]}>
                                                                {
                                                                    addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? equipmentCostArry[index] + operatorCostArry[index] + materialFeeArry[index] + singleToolCostArry[index] : equipmentCostSingleArry[index] + operatorCostSingleArry[index] + materialFeeSingleArry[index])
                                                                }
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableCol, styles.flex2]}>
                                                    <Text style={styles.tableCell}></Text>
                                                </View>
                                            </View>

                                            <View style={[styles.marginTop15]}>
                                                <Text>(1) 設備</Text>
                                                <View style={[styles.tableRowHeader, styles.textCenter]}>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>項目</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>每小時費用</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>時數</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>備註</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableRow, styles.textCenter]}>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}>設備使用費</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1, styles.textLeft]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>{machine[index] ? addThousandsSeparator(machine[index].equipmentCost) : 0}</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2, styles.textRight]}>
                                                        <Text style={styles.tableCell}>{formatTime(totalProductionDuration[index])}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <View style={[styles.tableRow]}>
                                                            <View style={[styles.flex1, styles.textLeft]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>
                                                                    {addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? equipmentCostArry[index] : equipmentCostSingleArry[index])}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}>選用機台「{machine[index] ? machine[index].machineSn : ""}」計價</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableRow]}>
                                                    <View style={[styles.tableCol, styles.flex7, styles.textRight, styles.backgroundColorPurple]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>
                                                                    {addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? equipmentCostArry[index] : equipmentCostSingleArry[index])}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={[styles.marginTop15]}>
                                                <Text>(2) 人員</Text>
                                                <View style={[styles.tableRowHeader, styles.textCenter]}>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>項目</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>每小時費用</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>時數</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>備註</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableRow, styles.textCenter]}>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}>人員費用</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1, styles.textLeft]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>{machine[index] ? addThousandsSeparator(machine[index].operatorCost) : 0}</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2, styles.textRight]}>
                                                        <Text style={styles.tableCell}>{formatTime(totalProductionDuration[index])}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textRight]}>
                                                        <View style={[styles.tableRow]}>
                                                            <View style={[styles.flex1, styles.textLeft]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>
                                                                    {addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? operatorCostArry[index] : operatorCostSingleArry[index])}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}>選用機台「{machine[index] ? machine[index].machineSn : ""}」計價</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableRow]}>
                                                    <View style={[styles.tableCol, styles.flex7, styles.textRight, styles.backgroundColorPurple]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>
                                                                    {addThousandsSeparator(obj.intQuantity > obj.pgmPdcQuantity ? operatorCostArry[index] : operatorCostSingleArry[index])}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={[styles.marginTop15]}>
                                                <Text>(3) 素材</Text>
                                                <View style={[styles.tableRowHeader, styles.textCenter]}>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>項目</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>單價</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>數量</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>備註</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableRow, styles.textCenter]}>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}>素材費用</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1, styles.textLeft]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                {
                                                                    obj.objReference.arrMaterialCost.length > 0 ?
                                                                        <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.objReference.arrMaterialCost[0].price)}</Text> : "-"
                                                                }
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2, styles.textRight]}>
                                                        <Text style={styles.tableCell}>{obj.intQuantity}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1, styles.textLeft]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                {
                                                                    obj.objReference.arrMaterialCost.length > 0 ?
                                                                        <Text style={[styles.tableCell]}>{addThousandsSeparator(obj.objReference.arrMaterialCost[0].price * obj.intQuantity)}</Text> : "-"
                                                                }
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.tableRow]}>
                                                    <View style={[styles.tableCol, styles.flex7, styles.textRight, styles.backgroundColorPurple]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                {
                                                                    obj.objReference.arrMaterialCost.length > 0 ?
                                                                        <Text style={[styles.tableCell]}>
                                                                            {addThousandsSeparator(obj.objReference.arrMaterialCost[0].price * obj.intQuantity)}
                                                                        </Text> : ""
                                                                }
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={[styles.marginTop15]}>
                                                <Text>(4) 刀具</Text>
                                                <View style={[styles.tableRowHeader, styles.textCenter]}>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>項目</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>單價</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                        <Text style={styles.tableCell}>使用量</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <Text style={styles.tableCell}>備註</Text>
                                                    </View>
                                                </View>
                                                {
                                                    toolArry[index] ?
                                                        toolArry[index].list.map((obj, itemIndex) => {
                                                            return (
                                                                <View style={[styles.tableRow]} key={itemIndex}>
                                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                                        <Text style={styles.tableCell}>{obj.item}</Text>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.flex2]}>
                                                                        <View style={styles.tableRow}>
                                                                            <View style={[styles.flex1]}>
                                                                                <Text style={styles.tableCell}>$</Text>
                                                                            </View>
                                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                                <Text style={styles.tableCell}>{addThousandsSeparator(obj.price)}</Text>
                                                                            </View>
                                                                        </View>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.flex2, styles.textRight]}>
                                                                        <Text style={styles.tableCell}>{(obj.quantity).toFixed(1)}</Text>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                                        <View style={styles.tableRow}>
                                                                            <View style={[styles.flex1]}>
                                                                                <Text style={styles.tableCell}>$</Text>
                                                                            </View>
                                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                                <Text style={styles.tableCell}>{addThousandsSeparator(obj.total)}</Text>
                                                                            </View>
                                                                        </View>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                                        <Text style={styles.tableCell}></Text>
                                                                    </View>
                                                                </View>
                                                            )
                                                        }) :
                                                        <></>
                                                }
                                                <View style={[styles.tableRow]}>
                                                    <View style={[styles.tableCol, styles.flex5, styles.textRight, styles.backgroundColorPurple]}>
                                                        <Text style={styles.tableCell}>小計</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex2, styles.textRight]}>
                                                        <Text style={styles.tableCell}>{quantityArry[index]?.toFixed(1)}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3]}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.flex1]}>
                                                                <Text style={styles.tableCell}>$</Text>
                                                            </View>
                                                            <View style={[styles.textRight, styles.flex3]}>
                                                                <Text style={[styles.tableCell]}>
                                                                    {addThousandsSeparator(priceArry[index])}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.flex3, styles.textLeft]}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>


                                    </View>

                                    <Text>----------({obj.wpNo} / END)----------</Text>
                                </Page> : <></>
                        );
                    })
                    :
                    <></>
            }

        </Document>
    );

}
//#endregion 

export default WorkPiece;