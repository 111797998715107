import React, {useState, useEffect, useContext, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { MyUserContext } from 'contexts/MyUserContext';
import { apiMachineListData } from 'utils/Api';

import Tab from 'components/basic/tab';
import IdeaArt from 'page/monitor/ideaArt';
import Cookies from 'universal-cookie';

const ideaUrl = window.ideaUrl;
const cookies = new Cookies();
const domain = window.cookieDomain;

const MachineStatus = () => {
  const { lang, checkLogin, permission } = useContext(MyUserContext);
  const navigate = useNavigate();

  const [tabList, setTabList] = useState([]);
  const [currentPage, setCurrentPage] = useState(<IdeaArt pageURL={`${ideaUrl}/machine_list.php`} />);

  const [filterPage, setFilterPage] = useState("");

  useEffect(() => {
    checkLogin();

    const fetchData = async () => {
      const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
      if(httpStatusMachine == "200"){
        if(reponseDataMachine.statusCode === "20000"){
          let artMachine = reponseDataMachine.data;
          // console.log("artMachine", artMachine)
          cookies.set("artMachine", JSON.stringify(artMachine), { HttpOnly: true, path: "/", domain: domain });
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let newTabList = [];
    const defaultTabs = [
      {
        tabName: "MachineStatus",
        component: <IdeaArt pageURL={`${ideaUrl}/machine_list.php`} />,
        icon: "icon-EquipmentStatus",
        // icon: "airplay",
        active: true
      },
      {
        tabName: "ComponentDiagnosis",
        component:<IdeaArt pageURL={`${ideaUrl}/machine_components_diagnosis.php`} />,
        icon: "icon-ComponentDiagnosis",
        // icon: "list_alt",
        active: false
      },
      {
        tabName: "LifetimeEstimation",
        component:<IdeaArt pageURL={`${ideaUrl}/lifetime_estimation.php`} />,
        icon: "icon-LifetimeEstimation",
        // icon: "hourglass_top",
        active: false
      },
      {
        tabName: "ConsumableSupplies",
        component:<IdeaArt pageURL={`${ideaUrl}/consumable_supplies_monitor.php`} />,
        icon: "icon-LubricantLevel",
        // icon: "colors",
        active: false
      },
      {
        tabName: "PowerConsumption",
        component:<IdeaArt pageURL={`${ideaUrl}/power_consumption_monitor.php`} />,
        icon: "icon-PowerConsumption",
        // icon: "bolt",
        active: false
      },
      {
        tabName: "UtilizationRate",
        component:<IdeaArt pageURL={`${ideaUrl}/utilization.php`} />,
        icon: "icon-RunningTimeline",
        // icon: "view_timeline",
        active: false
      }
    ];

    if(permission !== null){
      let filterMainTab = permission.find(d => d.tabName === "Monitor");
      if(filterMainTab){
        for(let item of filterMainTab.tabList){
          const tab = defaultTabs.find(tab => tab.tabName === item);
          if(tab) newTabList.push(tab);
        }
      }
      else{
        newTabList = defaultTabs;
      }
    }
    else{
      newTabList = defaultTabs;
    }
    setTabList(newTabList);
  }, [permission]);

  useEffect(() => {
    if(tabList != null){
      let filterAcitve = tabList?.find(d => d.active === true);

      if(filterAcitve){
        setCurrentPage(filterAcitve.component);
      }
  
      let filter = tabList?.find(d => d.active);
      if(filter){
        const newPath = `/Monitor/${filter.tabName}`;
        navigate(newPath);
      }
    }
  }, [tabList, lang, filterPage, setFilterPage, navigate]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if(machineListResponse){
        const httpStatus = machineListResponse.request.status;
        const reponseData = machineListResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion


  return (
    <div>
      <Tab tabList={tabList} setTabList={setTabList} />
      {currentPage}
    </div>
  );
};

export default MachineStatus;