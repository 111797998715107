import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import moment from 'moment';
import { MyUserContext } from 'contexts/MyUserContext';
import { formatTime, secondsToHours, decimalWithOneDecimal, judgeUtilizationRate, judgeErrorTime, formatDateTimeRange } from 'utils/commonFun';
import { apiShutDownEventsData, apiWorkpiecePgmDurationData } from 'utils/Api';

import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { DonutChart } from '../utilizationRateShift/DonutChart';

import { encrypt } from 'utils/crypt';

//#region 稼動率圖表
const DataCharts = (props) => {
  const { startDate, endDate, plannedEquipmentLoadingTime, rawDataMachining, rawDataIdle, rawDataAlarm,
    rawDataOffline, rawData, ratingCriteria, choiceMachine, shutDownDaily, calIntervalDays, setCalProgramNum } = props;

  const { t, i18n } = useTranslation("equipmentRuntime");
  const { overtime, misc, showFormatDate } = useContext(MyUserContext);

  const [optionsAvailability, setOptionsAvailability] = useState(null); //稼動率資料
  const [optionsEquipmentOperationTimeline, setOptionsEquipmentOperationTimeline] = useState(null); //設備運轉時序
  const [shutDownEvents, setShutDownEvents] = useState(null); //顯示錯誤停機事件列表
  const [totalTimes, setTotalTimes] = useState(0);
  const [workpiecePgmDuration, setWorkpiecePgmDuration] = useState(null); //加工程序運轉時長

  const [errorShutdownTimes, setErrorShutdownTimes] = useState(0);
  const [errorShutdownEventsTimes, setErrorShutdownEventsTimes] = useState(0);

  useEffect(() => {
    setOptionsAvailability({
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: [t("plannedTime"), t("actualRuntime")]
      },
      grid: {
        left: '16%',
        bottom: '10%',
        right: '5%',
        top: '0%'
      },
      series: [
        {
          name: 'PlannedEquipmentLoadingTime',
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: [{ value: plannedEquipmentLoadingTime, itemStyle: { color: '#3259CA' } }, { value: 0, itemStyle: { opacity: 0 } }]
        },
        {
          name: 'RawDataMachining',
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: [{ value: 0, itemStyle: { opacity: 0 } }, { value: secondsToHours(rawDataMachining), itemStyle: { color: '#7CC629' } }]
        },
        {
          name: 'RawDataIdle',
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: [{ value: 0, itemStyle: { opacity: 0 } }, { value: secondsToHours(rawDataIdle), itemStyle: { color: '#FFAA00' } }]
        },
        {
          name: 'RawDataAlarm',
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: [{ value: 0, itemStyle: { opacity: 0 } }, { value: secondsToHours(rawDataAlarm), itemStyle: { color: '#F66F6F' } }]
        }
      ]
    });

    let running = [];
    let idlePreparing = [];
    let errorShutdown = [];
    let plannedTime = [];
    let weekDay = [];
    if (rawData) {
      for (let item of rawData) {
        let statusList = item.statusList;
        running.push(secondsToHours(statusList[0].seconds));
        idlePreparing.push(secondsToHours(statusList[1].seconds));
        errorShutdown.push(secondsToHours(statusList[2].seconds));
        plannedTime.push(item.plannedHours);

        weekDay.push(t(moment(item.showDate).format('ddd')));
      }
    }

    setOptionsEquipmentOperationTimeline({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      legend: {
        data: [t("running"), t("idlePreparing"), t("errorShutdown"), t("plannedTime")]
      },
      xAxis: [
        {
          type: 'category',
          data: weekDay,
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: t("actualRuntimeHR"),
          min: 0,
          max: 24,
          interval: 4,
          axisLabel: {
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: t("plannedTimeHR"),
          min: 0,
          max: 24,
          interval: 4,
          axisLabel: {
            formatter: '{value}'
          }
        }
      ],
      /*↓ 網友說加上這段，就能把Apache echart上下左右的空白都消掉*/
      grid: {
        top: '40px',
        left: '0px',
        right: '0px',
        bottom: '10px',
        containLabel: true
      },
      /*↑ 網友說加上這段，就能把Apache echart上下左右的空白都消掉*/
      color: ['#7CC629', '#FFAA00', '#F66F6F', '#3259CA'],
      series: [
        {
          name: t("running"),
          type: 'bar',
          stack: 'Real',
          emphasis: {
            focus: 'series'
          },
          data: running
        },
        {
          name: t("idlePreparing"),
          type: 'bar',
          stack: 'Real',
          emphasis: {
            focus: 'series'
          },
          data: idlePreparing
        },
        {
          name: t("errorShutdown"),
          type: 'bar',
          stack: 'Real',
          emphasis: {
            focus: 'series'
          },
          data: errorShutdown
        },
        {
          name: t("plannedTime"),
          type: 'line',
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value;
            }
          },
          data: plannedTime
        }
      ]
    });
  }, [plannedEquipmentLoadingTime, rawDataMachining, rawDataIdle, rawDataAlarm, rawDataOffline, i18n.language, rawData]);

  useEffect(() => {
    if (startDate != "" && endDate != "" && choiceMachine != null) {
      const fetchData = async () => {
        //#region 錯誤停機事件列表API
        const [httpStatusShutDownEvents, reponseDataShutDownEvents] = await handleApiShutDownEventsData();
        if (httpStatusShutDownEvents == "200") {
          if (reponseDataShutDownEvents.statusCode === "20000") {
            setShutDownEvents(reponseDataShutDownEvents.data);

            let newTotalTimes = 0;

            for (let item of reponseDataShutDownEvents.data) {
              newTotalTimes += item.times;
            }
            setTotalTimes(newTotalTimes);
          }
          else if (reponseDataShutDownEvents.statusCode === "40103" || reponseDataShutDownEvents.statusCode === "40104") {
            overtime();
          }
        }
        //#endregion

        //#region 加工程序運轉時長API
        const [httpStatusWorkpiecePgmDuration, reponseDataWorkpiecePgmDuration] = await handleApiWorkpiecePgmDurationData();
        if (httpStatusWorkpiecePgmDuration == "200") {
          if (reponseDataWorkpiecePgmDuration.statusCode === "20000") {
            setWorkpiecePgmDuration(reponseDataWorkpiecePgmDuration.data);
            setCalProgramNum(reponseDataWorkpiecePgmDuration.data?.wpPgmDurationList?.length);
          }
          else if (reponseDataWorkpiecePgmDuration.statusCode === "40103" || reponseDataWorkpiecePgmDuration.statusCode === "40104") {
            overtime();
          }
        }
        //#endregion
      };
      fetchData();
    }
  }, [startDate, endDate, choiceMachine]);

  useEffect(() => {
    if (shutDownDaily != null) {
      let times = 0;
      for (let item of shutDownDaily) {
        times += item.shiftList[0].times;
      }
      setErrorShutdownTimes(times);
    }
  }, [shutDownDaily]);

  useEffect(() => {
    if (shutDownEvents != null) {
      let times = 0;
      for (let item of shutDownEvents) {
        times += item.times;
      }
      setErrorShutdownEventsTimes(times);
    }
  }, [shutDownEvents]);

  //#region 錯誤停機事件列表API
  const handleApiShutDownEventsData = async () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      id: choiceMachine.mid
    }

    let shutDownEventsResponse = await apiShutDownEventsData(postData);
    if (shutDownEventsResponse) {
      const httpStatus = shutDownEventsResponse.request.status;
      const reponseData = shutDownEventsResponse.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 加工程序運轉時長API
  const handleApiWorkpiecePgmDurationData = async () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      mid: choiceMachine.mid
    }

    let response = await apiWorkpiecePgmDurationData(postData);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion


  const jumpPartsPrograms = (wpID, programID) => {
    const baseUrl = window.location.origin;
    const path = '/Power/PartsPrograms';
    localStorage.setItem("power_startDate", localStorage.getItem("analytics_startDate"));
    localStorage.setItem("power_endDate", localStorage.getItem("analytics_endDate"));
    const fullUrl = `${baseUrl}${path}/${wpID != null ? encrypt(wpID) : encrypt("0")}/${programID != null ? encrypt(programID) : encrypt("0")}`;
    window.open(fullUrl, '_blank');
  }

  //#region 跳轉數據頁面的用電和碳排放
  const jumpEquipmentRuntimeChart = (chart) => {
    chart.on('click', function (params) {
      const baseUrl = window.location.origin;
      const path = '/RawData/EquipmentRuntime';
      localStorage.setItem("rawData_startDate", localStorage.getItem("analytics_startDate"));
      localStorage.setItem("rawData_endDate", localStorage.getItem("analytics_endDate"));
      const fullUrl = `${baseUrl}${path}`;
      window.open(fullUrl, '_blank');
    });
  }
  //#endregion

  //#region 跳轉數據頁面的用電和碳排放
  const jumpEquipmentRuntime = () => {
    const baseUrl = window.location.origin;
    const path = '/RawData/EquipmentRuntime';
    localStorage.setItem("rawData_startDate", localStorage.getItem("analytics_startDate"));
    localStorage.setItem("rawData_endDate", localStorage.getItem("analytics_endDate"));
    const fullUrl = `${baseUrl}${path}`;
    window.open(fullUrl, '_blank');
  }
  //#endregion

  return (
    <>
      {/* <!--第3排 圖表--> */}
      <div className="flex spacebetween chartbox outer-white-chartbox">
        {/* <!--第3排 區塊1 計畫工作時間、實際運轉時間--> */}
        <div className="chartbox-scoreandtable width-50">
          <div className={!misc?.detailedExplanation ? "d-none" : ""}>
            {t("chartDescription1")}
          </div>
          <div className="box-style-white-shadow">
            <div>
              <div className="box-upper-score-gp grid-col-2">
                {/* <!--上排分數--> */}
                <div>
                  <div className="subtitle">{t("availability")}</div>
                  <div className="score-XL">{decimalWithOneDecimal(secondsToHours(rawDataMachining), plannedEquipmentLoadingTime)}%</div>
                </div>
                <div>
                  <div className="subtitle">{t("operationalReadinessRate")}</div>
                  <div className="score-XL">{decimalWithOneDecimal(secondsToHours(rawDataMachining + rawDataIdle), plannedEquipmentLoadingTime)}%</div>
                </div>
              </div>
              <section className={`chartbox-inner-nodata ${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "" : "d-none"}`}>
                <div>
                  <div>{t("noData")}</div>
                </div>
              </section>
              <div className={`${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "d-none" : ""}`}>
                {/* <!--下排圖&表--> */}
                <div className="chartblock16 chart-width-wide">
                  {
                    optionsAvailability ?
                      <ReactEcharts option={optionsAvailability}
                        onChartReady={jumpEquipmentRuntimeChart}
                        echarts={echarts} className='w-100 h-100' /> : null
                  }
                </div>
                <div className="chart-table chart-width-narrow">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("timeEntries")}</th>
                        <td>{t("rate")}</td>
                        <td>{t("hours")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th><img src="/images/pieChartBlue.svg" alt={t("plannedLoadingTime")} /></th>
                        <th>{t("plannedLoadingTime")}</th>
                        <td></td>
                        <td>{plannedEquipmentLoadingTime}</td>
                      </tr>
                      <tr>
                        <th><img src="/images/pieChartGreen.svg" alt={t("running")} /></th>
                        <th>{t("running")}</th>
                        <td>{decimalWithOneDecimal(secondsToHours(rawDataMachining), plannedEquipmentLoadingTime)}<span className="unit">%</span></td>
                        <td>{secondsToHours(rawDataMachining)}</td>
                      </tr>
                      <tr>
                        <th><img src="/images/pieChartGreenYellow.svg" alt={t("operationalReadiness")} /></th>
                        <th>{t("operationalReadiness")}</th>
                        <td>{decimalWithOneDecimal(secondsToHours(rawDataMachining + rawDataIdle), plannedEquipmentLoadingTime)}<span className="unit">%</span></td>
                        <td>{secondsToHours(rawDataMachining + rawDataIdle)}</td>
                      </tr>
                      <tr>
                        <th><img src="/images/pieChartSum.svg" alt={t("powerOn")} /></th>
                        <th>{t("powerOn")}</th>
                        <td>{decimalWithOneDecimal(secondsToHours(rawDataMachining + rawDataIdle + rawDataAlarm), plannedEquipmentLoadingTime)}<span className="unit">%</span></td>
                        <td>{secondsToHours(rawDataMachining + rawDataIdle + rawDataAlarm)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--第3排 區塊2 實際運轉時間--> */}
        <div className="chartbox-scoreandtable width-33">
          <div className={!misc?.detailedExplanation ? "d-none" : ""}>
            {t("chartDescription2")}
          </div>
          <div className="box-style-white-shadow">
            <div>
              <div className="box-upper-score-gp grid-col-2">
                {/* <!--上排分數--> */}
                <div>
                  <div className="subtitle">{t("equipmentRunningRate")}</div>
                  <div className="score-XL">{decimalWithOneDecimal(secondsToHours(rawDataMachining), (24 * calIntervalDays))}%</div>
                </div>
                <div>
                  <div className="subtitle">{t("runningHours")}</div>
                  <div className="score-XL">{secondsToHours(rawDataMachining)}</div>
                </div>
              </div>
              <section className={`chartbox-inner-nodata ${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "" : "d-none"}`}>
                <div>
                  <div>{t("noData")}</div>
                </div>
              </section>
              <div className={`${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "d-none" : ""}`}>
                {/* <!--下排圖&表--> */}
                <div className="chartblock16 chart-width-evenly-narrow">
                  {
                    optionsEquipmentOperationTimeline ?
                      <DonutChart Operating={rawDataMachining} Idle={rawDataIdle}
                        Error={rawDataAlarm} NotUse={rawDataOffline}
                        onChartReady={jumpEquipmentRuntimeChart}
                        t={t}
                      /> : null
                  }
                </div>
                <div className="chart-table chart-width-evenly-wide">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("status")}</th>
                        <td>{t("rate")}</td>
                        <td>{t("hours")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th><div className="dot running"></div></th>
                        <th>{t("running")}</th>
                        <td>{decimalWithOneDecimal(secondsToHours(rawDataMachining), (24 * calIntervalDays))}<span className="unit">%</span></td>
                        <td>{secondsToHours(rawDataMachining)}</td>
                      </tr>
                      <tr>
                        <th><div className="dot idle"></div></th>
                        <th>{t("idlePreparing")}</th>
                        <td>{decimalWithOneDecimal(secondsToHours(rawDataIdle), (24 * calIntervalDays))}<span className="unit">%</span></td>
                        <td>{secondsToHours(rawDataIdle)}</td>
                      </tr>
                      <tr>
                        <th><div className="dot error"></div></th>
                        <th>{t("errorShutdown")}</th>
                        <td>{decimalWithOneDecimal(secondsToHours(rawDataAlarm), (24 * calIntervalDays))}<span className="unit">%</span></td>
                        <td>{secondsToHours(rawDataAlarm)}</td>
                      </tr>
                      <tr>
                        <th><div className="dot offline"></div></th>
                        <th>{t("powerOffOffline")}</th>
                        <td>{decimalWithOneDecimal(secondsToHours(rawDataOffline), (24 * calIntervalDays))}%</td>
                        <td>{secondsToHours(rawDataOffline)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--第3排-區塊3 設備每日使用情況--> */}
        <div className="chartbox-notable width-16of2">
          <div className={!misc?.detailedExplanation ? "d-none" : ""}>
            {t("chartDescription3")}
          </div>
          <div className="box-style-white-shadow">
            <div className="stretch">
              {/* <!--上排小標題--> */}
              <div>
                <h3>{t("dailyAvailabilityEquipment")}</h3>
              </div>
              <section className={`chartbox-inner-nodata w-100 ${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "" : "d-none"}`}>
                <div>
                  <div>{t("noData")}</div>
                </div>
              </section>
              {/* <!--下排圖--> */}
              <div className={`chart-week-daily ${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "d-none" : ""}`}>
                <table>
                  <tbody>
                    {
                      rawData?.map((obj, index) => {
                        return (
                          <tr key={index}>
                            <th>
                              {(() => {
                                const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");
                                const removeYear = cleanString(showFormatDate.replace(/YYYY/g, ""));
                                const trans = formatDateTimeRange(removeYear, null, obj.showDate);
                                return trans.startDate;
                              })()}
                              <span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span>
                            </th>
                            <td>
                              {(() => {
                                let resultVal = decimalWithOneDecimal(obj.statusList[0].seconds, obj.plannedHours * 3600);
                                let result = judgeUtilizationRate(resultVal, ratingCriteria?.equipmentOperatingRate);

                                if (obj.plannedHours > 0) {
                                  return <div className={`color-div-lv3-${result} cursor-pointer`} onClick={jumpEquipmentRuntime}>{resultVal}%</div>;
                                }
                                return <div className="color-div-lv3-restday cursor-pointer" onClick={jumpEquipmentRuntime}>{t("restDay")}</div>;
                              })()}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--第4排 圖表--> */}
      <div className="flex spacebetween chartbox outer-white-chartbox">
        {/* <!--第4排-區塊1 設備運轉時序--> */}
        <div className="chartbox-notable width-50">
          <div className={!misc?.detailedExplanation ? "d-none" : ""}>
            {t("chartDescription4")}
          </div>
          <div className="box-style-white-shadow">
            <div className="stretch">
              <div>
                {/* <!--上排小標題--> */}
                <h3>{t("equipmentOperationTimeline")}</h3>
              </div>
              <section className={`chartbox-inner-nodata w-100 ${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "" : "d-none"}`}>
                <div>
                  <div>{t("noData")}</div>
                </div>
              </section>
              {/* <!--下排圖--> */}
              <div className={`chartblock18 ${rawDataMachining === 0 && rawDataIdle === 0 && rawDataAlarm === 0 && rawDataOffline === 0 ? "d-none" : ""}`}>
                {
                  optionsEquipmentOperationTimeline ?
                    <ReactEcharts option={optionsEquipmentOperationTimeline}
                      onChartReady={jumpEquipmentRuntimeChart}
                      echarts={echarts} className='w-100 h-100' /> : null
                }
              </div>
            </div>
          </div>
        </div>

        {/* <!--第4排-區塊2 錯誤停機次數--> */}
        <div className="chartbox-notable width-16of3">
          <div className={!misc?.detailedExplanation ? "d-none" : ""}>
            {t("chartDescription5")}
          </div>
          <div className="box-style-white-shadow">
            <div className="stretch">
              {/* <!--上排小標題--> */}
              <div className="title-and-score">
                <h3 className="title">{t("numberErrorEvents")}</h3>
                <span className="score">{errorShutdownEventsTimes}</span>
              </div>
              <section className={`chartbox-inner-nodata w-100 ${shutDownEvents?.length > 0 ? "d-none" : ""}`}>
                <div>
                  <div>{t("noData")}</div>
                </div>
              </section>
              <div className={`chart-persentbar-text ${!shutDownEvents || shutDownEvents?.length === 0 ? "d-none" : ""}`}>
                {/* <!--下排圖表--> */}
                <div>
                  {/* <!--表頭--> */}
                  <div>
                    <div>{t("eventName")}</div>
                    <div>{t("times")}</div>
                  </div>
                </div>
                <div>
                  {
                    shutDownEvents?.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div>
                            <div>{obj.eventName}</div>
                            <div>{obj.times}</div>
                          </div>
                          <div>
                            <div></div>
                            <div style={{ width: `${decimalWithOneDecimal(obj.times, totalTimes)}%` }} className="color-bg-danger"></div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--第4排-區塊3 設備錯誤停機次數 (每日)--> */}
        <div className="chartbox-notable width-16of3">
          <div className={!misc?.detailedExplanation ? "d-none" : ""}>
            {t("chartDescription6")}
          </div>
          <div className="box-style-white-shadow">
            <div className="stretch">
              {/* <!--上排小標題--> */}
              <div className="title-and-score">
                <h3 className="title">{t("numberErrorDaily")}</h3>
                <span className="score">{errorShutdownTimes}</span>
              </div>
              <section className={`chartbox-inner-nodata w-100 ${shutDownDaily?.length > 0 ? "d-none" : ""}`}>
                <div>
                  <div>{t("noData")}</div>
                </div>
              </section>
              {/* <!--下排圖--> */}
              <div className={`chart-week-daily ${!shutDownDaily || shutDownDaily?.length === 0 ? "d-none" : ""}`}>
                <table>
                  <tbody>
                    {
                      shutDownDaily?.map((obj, index) => {
                        return (
                          <tr key={index}>
                            {/* <th>{moment(obj.showDate).format('MMM D')}<span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span></th> */}
                            <th>
                              {(() => {
                                const cleanString = (str) => str.replace(/^[, /-]+|[, /-]+$/g, "");
                                const removeYear = cleanString(showFormatDate.replace(/YYYY/g, ""));
                                const trans = formatDateTimeRange(removeYear, null, obj.showDate);
                                return trans.startDate;
                              })()}
                              <span className="remark-bluebox">{t(moment(obj.showDate).format('ddd'))}</span>
                            </th>
                            <td>
                              {(() => {
                                let resultVal = obj.shiftList[0].times;
                                let result = judgeErrorTime(resultVal, ratingCriteria?.equipmentErrorDowntime, 1);

                                return <div className={`color-danger-div-${result}`}>{resultVal}</div>;
                              })()}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <!--第4排-區塊4 加工程序運轉時長--> */}
        <div className="chartbox-notable width-16of3">
          <div className={!misc?.detailedExplanation ? "d-none" : ""}>
            {t("chartDescription7")}
          </div>
          <div className="box-style-white-shadow">
            <div className="stretch">
              {/* <!--上排小標題--> */}
              <div>
                <h3>{t("durationMachiningProgram")}</h3>
              </div>
              <section className={`chartbox-inner-nodata w-100 ${workpiecePgmDuration?.wpPgmDurationList?.length > 0 ? "d-none" : ""}`}>
                <div>
                  <div>{t("noData")}</div>
                </div>
              </section>
              {/* <!--下排圖表--> */}
              <div className={`chart-persentbar-img ${!workpiecePgmDuration || workpiecePgmDuration?.wpPgmDurationList?.length === 0 ? "d-none" : ""}`}>
                {/* <!--表頭--> */}
                <div>
                  <div>
                    <div>{t("machiningProgram")}</div>
                    <div>{t("durationProgram")}</div>
                  </div>
                </div>
                <div>
                  {
                    workpiecePgmDuration?.wpPgmDurationList?.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div>
                            <div className='cursor-pointer' onClick={() => jumpPartsPrograms(obj.wpID, obj.programID)}>{obj.wpImage != null ? <img src={obj.wpImage} className='w-100' /> : ""}</div>
                            <div>
                              <div className='cursor-pointer' onClick={() => jumpPartsPrograms(obj.wpID, obj.programID)}>{obj.wpName != null ? <div>{obj.wpName}</div> : <></>}<div>{obj.pgmNo}</div></div>
                              <div>{formatTime(obj.seconds)}</div>
                            </div>
                          </div>
                          <div>
                            <div></div>
                            <div style={{ width: `${decimalWithOneDecimal(obj.seconds, workpiecePgmDuration.totalSeconds)}%` }} className="bar color-bg-black"></div>
                          </div>
                        </div>
                      )
                    })
                  }
                  {/* <!--第1項--> */}
                  {/* <div> 
                      <div>
                          <div></div>
                          <div>
                              <div><div>EM-AL-0003 (v1.2)</div></div>
                              <div>27:49:15</div>
                          </div>
                      </div>
                      <div>
                          <div></div>
                          <div style={{width: "48.6%"}} className="color-bg-danger"></div>
                      </div>
                    </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
//#endregion

export default DataCharts;