import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import './LoginPreMain.css';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { removeCookieLogin, getCookieLogin } from 'utils/loginUtil';

import { apiQueryAuthority } from 'utils/Api';

const LoginPreMain = () => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const [showOverTimeMsg, setShowOverTimeMsg] = useState(false);

    // const [authority, setAuthority] = useState(null); //權限列表

    //#region 初始載入
    useEffect(() => {
        setTimeout(function() {
            handleAuthority();
    
            // if (!localStorage.getItem('permission')) {
            //     console.log("userData", userData)


            //     // fetch('/json/permission.json')
            //     //     .then(response => response.json())
            //     //     .then(data => {
            //     //         setPermission(data);
            //     //         handlePermission(data);
            //     //     })
            //     //     .catch(error => {
            //     //         setPermission(null);
            //     //         navigate("/Monitor/machineStatus");
            //     //     });
            // } else {
            //     let localVal = JSON.parse(localStorage.getItem('permission'));
            //     handlePermission(localVal);
            // }
        }, 2000);
    }, []);

    // useEffect(() => {
    //     setTimeout(function() {
    //         if (!localStorage.getItem('permission')){
    //             fetch('/json/permission.json')
    //             .then(response => response.json())
    //             .then(data => {
    //                 setPermission(data);

    //                 if(data != null){
    //                     if(data.length === 0){
    //                         alert("權限設定檔尚未設定，請通知管理員!")
    //                     }
    //                     else{
    //                         let url = `${data[0].tabName}${data[0].tabList.length > 0 ? `\\${data[0].tabList[0]}` : ""}`;
    //                         navigate(url);
    //                     }
    //                 }
    //                 else{
    //                     navigate("/Monitor/machineStatus");
    //                 }
    //             })
    //             .catch(error => {
    //                 setPermission(null);
    //             });
    //         }
    //         else{
    //             if(permission != null){
    //                 if(permission.length === 0){
    //                     alert("權限設定檔尚未設定，請通知管理員!")
    //                 }
    //                 else{
    //                     let url = `${permission[0].tabName}${permission[0].tabList.length > 0 ? `\\${permission[0].tabList[0]}` : ""}`;
    //                     navigate(url);
    //                 }
    //             }
    //             else{
    //                 navigate("/Monitor/machineStatus");
    //             }
    //         }
            
    //     }, 2000);
    // }, []);
    //#endregion

    //#region 載入權限
    const handleAuthority = async () => {
        const [httpStatusUser, reponseDataUser] = await handleApiAuthority();
        if(httpStatusUser == "200"){
            if(reponseDataUser.statusCode === "20000"){
                // setAuthority(reponseDataUser.data);
                handlePermission(reponseDataUser.data);
                localStorage.setItem('permission', JSON.stringify(reponseDataUser.data.clientPages));
            }
            else if(reponseDataUser.statusCode === "40103"){
                overtime();
            }
            else{
                // setAuthority(null);

                toast.error(reponseDataUser.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            }
        }
        else{
            // setAuthority(null);
            toast.error(reponseDataUser.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    //#endregion

    //#region 權限API
    const handleApiAuthority = async () => {
        let postData = {};

        let settingListResponse = await apiQueryAuthority(postData);
        if(settingListResponse){
            const httpStatus = settingListResponse.request.status;
            const reponseData = settingListResponse.data;

            return [httpStatus, reponseData];
        }
        else{
            return ["500", ""]
        }
    }
    //#endregion

    const handlePermission = (data) => {
        var clientPages = data.clientPages;
        if (clientPages !== null && clientPages.length === 0) {
            alert("權限設定檔尚未設定，請通知管理員!");
            logInAgain();
        } else {
            // console.log("data", data);
            const url = `/${clientPages[0].tabName}${clientPages[0].tabList.length > 0 ? `/${clientPages[0].tabList[0]}` : ""}`;
            navigate(url, { state: data});
        }
    };

    //#region token過期無效登出訊息
    const overtime = async () => {
        if(getCookieLogin()){
            setShowOverTimeMsg(true);
        }
        else{
            removeCookieLogin();
            navigate("/");
        }
    }
    //#endregion

    //#region 登出
    const logInAgain = async () => {
        removeCookieLogin();
        navigate("/");

        localStorage.clear();
    }
    //#endregion

    return (
        <>
            <div className='loginPreMain-container'>
                <form>
                    <div className="w-100 d-flex justify-content-center">
                        <Spinner animation="border" size="md">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    <div className='text-blue mt-4'>
                        {t("loginSuccessful")}
                    </div>
                </form>
            </div>

            <div className={`fade modal-backdrop ${showOverTimeMsg ? "show" : "d-none"}`}></div>
            <div className={`token-circle-div ${!showOverTimeMsg ? "d-none" : ""}`}>
                <div className="pwd-container">
                    <div className='message-board'>
                        <p className='message-board-title mb-0'>
                            <b>{t("connectionExpired")}</b>
                        </p>
                        <p className='message-board-content'>
                        {t("connectionExpiredMsg1")}<br />
                        {t("connectionExpiredMsg2")}<br />
                        </p>
                        <input type="button" className='btn btn-lv1 w-100' value={t("logInAgain")} onClick={logInAgain} />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
        
    )
}

export default LoginPreMain;