import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './modal.css';
import { MyUserContext } from 'contexts/MyUserContext';

import { apiWorkpieceList, apiProgramList, apiMachineListData, apiIdeaLogin, apiRawDataPowerCarbon } from 'utils/Api';

const SelectProgramModal = ({ showModal, handleClose, programBehavior, 
  showConsumptionProgramRef, setShowConsumptionProgramRef, showConsumptionProgramList, setShowConsumptionProgramList }) => {
  const { t } = useTranslation("reports");
  const { overtime, authority } = useContext(MyUserContext);

  const [workpieceList, setWorkpieceList] = useState(null); //下拉選單-工件列表
  const [programList, setProgramList] = useState(null); //下拉選單-工件列表未分類

  const [machineList, setMachineList] = useState(null); //機台列表

  const [mainProgramId, setMainProgramId] = useState(""); //程式比較-選擇加工程式流水號
  const [mainProgramName, setMainProgramName] = useState(""); //程式比較-選擇加工程式名稱
  const [choiceMachine, setChoiceMachine] = useState(""); //程式比較-選擇機器

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息
  const [isExecutionRecords, setIsExecutionRecords] = useState(1);  //是否有執行紀錄

  //#region 初始載入
  useEffect(() => {
    if(showModal){
      loadWorkpieceList();
      loadProgramList();
      setJudgeCheckErr(false);
      setIsExecutionRecords(1);
    }
  }, [showModal]);
  //#endregion

  useEffect(() => {
    if(authority?.userAccount != null){
        const fetchData = async () => {
          const [httpStatusMachine, reponseDataMachine] = await handleApiMachineList();
          if(httpStatusMachine == "200"){
              if(reponseDataMachine.statusCode === "20000"){
                let artMachine = reponseDataMachine.data;
                let newMachineList = [];
                const [httpStatus, reponseData] = await handleApiIdeaLogin();
                if(httpStatus === 200){
                  let ideaMachine = reponseData?.artUserInfo?.owingMachines;
                  for(let item of ideaMachine){
                    let filter = artMachine.find(d => d.machineSn === item.serialNumber);
                    if(filter){
                      newMachineList.push(filter);
                    }
                  }
                }
                else{
                  newMachineList = artMachine;
                }
                
                setMachineList(newMachineList);

                if (localStorage.getItem("choiceMachine") !== null){
                  setChoiceMachine(JSON.parse(localStorage.getItem("choiceMachine")));
                }
                else{
                  if(newMachineList.length > 0){
                    localStorage.setItem("choiceMachine", JSON.stringify(newMachineList[0]));
                    setChoiceMachine(newMachineList[0]);
                  }
                  else{
                    localStorage.removeItem("choiceMachine");
                    setChoiceMachine(null);
                  }
                }
              }
              else if(reponseDataMachine.statusCode === "40103" || reponseDataMachine.statusCode === "40104"){
                overtime();
              }
          }
      };
      fetchData();
    }
  }, [authority]);

  //#region 下拉選單機台列表API
  const handleApiMachineList = async () => {
    let machineListResponse = await apiMachineListData();
    if(machineListResponse){
        const httpStatus = machineListResponse.request.status;
        const reponseData = machineListResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 艾創點登入API
  const handleApiIdeaLogin = async () => {
    const formData = new FormData();
    formData.append('accountId', authority?.userAccount);
    formData.append('password', 'Demo2023');
    
    let ideaLoginResponse = await apiIdeaLogin(formData);
    
    if(ideaLoginResponse){
        const httpStatus = ideaLoginResponse.request.status;
        const reponseData = ideaLoginResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 機台下拉選單
  const handleEquipmentUsed = (e) => {
    const { value } = e.target;
    let filter = machineList.find(d => d.mid === parseInt(value));
    if(filter){
      setChoiceMachine(filter);
      localStorage.setItem("choiceMachine", JSON.stringify(filter));
    }
  };
  //#endregion

  useEffect(() => {
    if(mainProgramId == ""){
      if(workpieceList != null && workpieceList?.totalItems > 0){
        setMainProgramId(workpieceList.detail[0].programList[0].programID);
        setMainProgramName(workpieceList.detail[0].programList[0].programNO);
      }
      if(workpieceList == null && programList != null && programList?.totalItems > 0){
        setMainProgramId(programList.detail[0].programID);
        setMainProgramName(programList.detail[0].programName);
      }
    }
  }, [workpieceList, programList]);

  //#region 載入工件列表
  const loadWorkpieceList = async () => {
    const [httpStatus, reponseData] = await handleApiWorkpieceList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        if(data.totalItems > 0){
          setWorkpieceList(data);
        }
        else{
          setWorkpieceList(null);
          setMainProgramId("");
          setMainProgramName("");
        }
      }
      else if(reponseData.statusCode === "40103" || reponseData.statusCode === "40104"){
        overtime();
      }
    }
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = {
      doIncludeNoProgram: 0
    };
    let workpieceListResponse = await apiWorkpieceList(postJson);
    if(workpieceListResponse){
      const httpStatus = workpieceListResponse.request.status;
      const reponseData = workpieceListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

   //#region 載入加工程序列表
   const loadProgramList = async () => {
    const [httpStatus, reponseData] = await handleApiProgramList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        if(data.totalItems > 0){
          setProgramList(data);
        }
        else{
          setProgramList(null);
        }
      }
      else if(reponseData.statusCode === "40103" || reponseData.statusCode === "40104"){
          overtime();
      }
    }
  }
  //#endregion

  //#region API抓取加工程序列表
  const handleApiProgramList = async () => {
    let postJson = {
      filterWPCondition: 2
    }
    let programListResponse = await apiProgramList(postJson);
    if(programListResponse){
        const httpStatus = programListResponse.request.status;
        const reponseData = programListResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 用電量
  const loadRawDataPowerCarbon = async () => {
    const [httpStatus, reponseData] = await handleApiRawDataPowerCarbon("json");
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        if(data.totalItems > 0){
          setIsExecutionRecords(1);
          finishWork();
        }
        else{
          setIsExecutionRecords(0);
        }
      }
    }
    else{
      setIsExecutionRecords(0);
      toast.error(`HTTP Status Code:${httpStatus}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  //#endregion

  //#region 用電量API
  const handleApiRawDataPowerCarbon = async (format) => {
    let postData = {
      midList: [choiceMachine?.mid],
      programID: parseInt(mainProgramId),
      format: format,
      intLimit: 1,
      intOffset: 0
    };

    let response = await apiRawDataPowerCarbon(postData, false);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 切換加工程式
  const handleMachineProgram = (e) => {
    const { value, options, selectedIndex } = e.target;
    const selectedText = options[selectedIndex].text;

    setMainProgramId(value);
    setMainProgramName(selectedText);
  }
  //#endregion

  //#region 套用
  const handleApply = () => {
    setJudgeCheckErr(true);
    loadRawDataPowerCarbon();
  }
  //#endregion

  //#region 最後需要完成組回去需要的值
  const finishWork = () => {
    if(programBehavior === 1){ //對照
      setShowConsumptionProgramRef({
        programID: parseInt(mainProgramId), 
        programName: mainProgramName, 
        mid: choiceMachine?.mid,
        mName: choiceMachine?.mName,
      });
    }
    else{
      let newShowConsumptionProgramList = [...showConsumptionProgramList];
      newShowConsumptionProgramList.push(
        {
          programID: parseInt(mainProgramId), 
          programName: mainProgramName, 
          mid: choiceMachine?.mid,
          mName: choiceMachine?.mName,
        }
      );
      setShowConsumptionProgramList(newShowConsumptionProgramList);
    }
    handleClose();
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md" backdrop="static" keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("selectProgram")}<br />
              <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="machineProgram">
                    <Form.Label className='form-label-custom'>
                      {t("machiningProgram")}
                      <span className='text-danger ms-1'>*</span>
                    </Form.Label><br />
                    <Form.Select as="select" className='machineProgram' 
                      value={mainProgramId} onChange={handleMachineProgram}>
                    {
                      workpieceList?.detail?.map((obj, index) => {
                        return(
                          <optgroup label={`${obj.wpNO} ${obj.wpName}`} key={index}>
                            {obj.programList?.map((item, idx) => (
                              <option value={item.programID} key={idx}>{item.programNO}</option>
                            ))}
                          </optgroup>
                        )
                      }) 
                    }
                    {
                      programList ?
                      <optgroup label="Unclassified" style={{ borderBottom: '1px solid black' }}>
                        {programList?.detail.map((item, idx) => {
                          return(
                            <option value={item.programID} key={idx}>{item.programName}</option>
                          )})
                        }
                      </optgroup> :
                      null
                    }
                    </Form.Select>
                  </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col xs={12}>
                    <Form.Group controlId="equipmentUsed">
                      <Form.Label className='form-label-custom'>
                        {t("equipmentUsed")}
                        <span className='text-danger ms-1'>*</span>
                      </Form.Label><br />
                      <Form.Select as="select" 
                        onChange={handleEquipmentUsed}
                        value={choiceMachine?.mid || ''} 
                        className={`equipmentUsed ${judgeCheckErr && isExecutionRecords === 0 ? "input-error" : ""}`}
                        >
                          {
                            machineList?.map((obj, index) => {
                              return <option key={index} value={obj.mid}>{obj.mName}</option>;
                            })
                          }
                      </Form.Select>
                      {
                        judgeCheckErr && isExecutionRecords === 0 && showModal ?
                          <Form.Label className='text-danger mb-0'>{t("noExecutionRecords")}</Form.Label> :
                          <></>
                      }
                    </Form.Group>
                  </Col>
                </Row>
            </Form>
          </Modal.Body>
          <div className="modal-footer-custom">
            <div className="btn-gp">
              <div>
                <button className="btn btn-txt-black" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button className="btn btn-lv1 fat"onClick={handleApply}>
                  {t("apply")}
                </button>
              </div>
            </div>
          </div>
          {/* <Modal.Footer className="modal-footer-custom">
            <Row>
              <Col xs={6} >
                <button className="button-not-border" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </Col>
              <Col xs={6} className='d-flex justify-content-end' onClick={handleApply}>
                <button className="button-primary">
                  {t("apply")}
                </button>
              </Col>
            </Row>
          </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
};

export default SelectProgramModal;