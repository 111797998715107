import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  formatDateTimeRange, isoWeekCalc, secondsToHours, decimalWithOneDecimal,
  judgeUtilizationRate, judgeErrorTime
} from 'utils/commonFun';
import { MyUserContext } from 'contexts/MyUserContext';

//#region 機台稼動率概要
const Summary = (props) => {
  const { startDate, endDate, workCalDays, plannedEquipmentLoadingTime, rawDataMachining, rawDataIdle, machineOperatingTime,
    machineOperatingHours, ratingCriteria, shutDownDailyTimes, calProgramNum, calIntervalDays } = props;

  const { t, i18n } = useTranslation("equipmentRuntime");
  const { lang, showFormatDate, showFormatTime } = useContext(MyUserContext);
  const navigate = useNavigate();
  const [transPeriodDate, setTransPeriodDate] = useState("");

  useEffect(() => {
    let trans = formatDateTimeRange(showFormatDate, null, startDate, endDate);
    setTransPeriodDate(`${trans.startDate} ${trans.startTime} ~ ${trans.endDate} ${trans.endTime}`);
  }, [showFormatDate, showFormatTime, lang]);

  const handleEquipmentRuntime = () => {
    localStorage.setItem("rawData_startDate", startDate);
    localStorage.setItem("rawData_endDate", endDate);
    sessionStorage.setItem('startDate', startDate);
    sessionStorage.setItem('endDate', endDate);
    navigate("/RawData/EquipmentRuntime");
  }


  return (
    <>
      {/* <!--第1排 全小藍框--> */}
      <div className="flex-box-111111 mtop10">
        {/* <!--第1排｜1號框--選擇區間--> */}
        <div className="boxstyle-blue littlebox">
          <div>
            <div className="title">{t("selectedPeriod")}</div>
            <div>
              <div className="score">{t("weekNum", { e: isoWeekCalc(startDate) })}</div>
              <div className="note">({transPeriodDate})</div>
            </div>
          </div>
        </div>
        {/* <!--第1排｜2號框--計畫工作天數--> */}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="custom-tooltip">
              <span dangerouslySetInnerHTML={{ __html: t("plannedWorkdayToolTip") }} />
            </Tooltip>
          }
        >
          <div className="boxstyle-blue littlebox">
            <div>
              <div className="title">{t("plannedWorkday")}</div>
              <div>
                <div>
                  <span className="score">{t("dayNum", { e: workCalDays })}</span>
                </div>
                <button className="btn-littlebox" onClick={handleEquipmentRuntime}>
                  {/* <div className="material-symbols-rounded">edit</div> */}
                  <div className="icon icon-Edit"></div>
                  <div className="text">{t("edit")}</div>
                </button>
              </div>
            </div>
          </div>
        </OverlayTrigger>

        {/* <!--第1排｜3號框--設備的計畫工作時間--> */}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className='custom-tooltip'>
              {(() => {
                if (machineOperatingTime) {
                  let result = [];
                  for (let item of machineOperatingTime) {
                    result.push(moment(`2024-01-01 ${item.startTime}`).format("HH:mm A") + " ~ " + (moment(`2024-01-01 ${item.endTime}`).format("HH:mm A")));
                  }

                  let resultString = i18n.language === 'en' ? result.join(', ') : result.join('、');
                  return <span dangerouslySetInnerHTML={{ __html: t("plannedEquipmentLoadingTimeToolTip", { e: plannedEquipmentLoadingTime, period: resultString, oneDayHour: machineOperatingHours.toFixed(1) }) }} />;
                }
                return <span dangerouslySetInnerHTML={{ __html: t("plannedEquipmentLoadingTimeToolTip24Hours", { e: plannedEquipmentLoadingTime }) }} />;
              })()}
            </Tooltip>
          }
        >
          <div className="boxstyle-blue littlebox">
            <div>
              <div className="title">{t("plannedEquipmentLoadingTime")}</div>
              <div>
                <div>
                  <span className="score">{plannedEquipmentLoadingTime}</span>
                </div>
                <button className="btn-littlebox" onClick={handleEquipmentRuntime}>
                  {/* <div className="material-symbols-rounded">edit</div> */}
                  <div className="icon icon-Edit"></div>
                  <div className="text">{t("edit")}</div>
                </button>
              </div>
            </div>
          </div>
        </OverlayTrigger>

        {/* <!--第1排｜4號框--設備實際運轉時數--> */}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="custom-tooltip">
              <span dangerouslySetInnerHTML={{ __html: t("equipmentActualRunningHoursToolTip") }} />
            </Tooltip>
          }
        >
          <div className="boxstyle-blue littlebox">
            <div>
              <div className="title">{t("equipmentActualRunningHours")}</div>
              <div>
                <div>
                  <span className="score">{secondsToHours(rawDataMachining)}</span>
                </div>
              </div>
            </div>
          </div>
        </OverlayTrigger>

        {/* <!--第1排｜5號框--設備使用及可用時數--> */}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="custom-tooltip">
              <span dangerouslySetInnerHTML={{ __html: t("equipmentActualOperationalReadinessHoursToolTip") }} />
            </Tooltip>
          }
        >
          <div className="boxstyle-blue littlebox">
            <div>
              <div className="title">{t("equipmentActualOperationalReadinessHours")}</div>
              <div>
                <div>
                  <span className="score">{secondsToHours(rawDataMachining + rawDataIdle)}</span>
                </div>
              </div>
            </div>
          </div>
        </OverlayTrigger>


        {/* <!--第1排｜6號框--選擇過的加工程序數量--> */}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="custom-tooltip">
              <span dangerouslySetInnerHTML={{ __html: t("numberSelectedMachiningProgramsToolTip") }} />
            </Tooltip>
          }
        >
          <div className="boxstyle-blue littlebox">
            <div>
              <div className="title">{t("numberSelectedMachiningPrograms")}</div>
              <div>
                <div>
                  <span className="score">{calProgramNum}</span>
                </div>
              </div>
            </div>
          </div>
        </OverlayTrigger>

      </div>
      {/* <!--第1排 END--> */}

      {/* <!--第2排 100% width白外框--> */}
      <div className="boxgroup-outer outer-white">
        <div className="box-style-white-shadow">
          <div className="grid grid-col-4to2">
            {/* <!--第2排｜1號框--設備稼動率--> */}
            {(() => {
              let resultVal = decimalWithOneDecimal(secondsToHours(rawDataMachining), plannedEquipmentLoadingTime);
              let result = "";
              if (ratingCriteria) {
                result = judgeUtilizationRate(resultVal, ratingCriteria.equipmentOperatingRate);
              }

              return <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className="custom-tooltip">
                    <span dangerouslySetInnerHTML={{ __html: t("equipmentAvailabilityToolTip") }} />
                  </Tooltip>
                }
              >
                <div className={`boxstyle-white littlebox color-div-lv1-${result}`}>
                  <div>
                    <div>{t("equipmentAvailability")}</div>
                    <div>
                      <span className="score-XL">{resultVal}%</span>
                      <span className="criteria-L">{result.toUpperCase()}</span>
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            })()}
            {/* <!--第2排｜2號框--設備使用及可用率--> */}
            {(() => {
              let resultVal = decimalWithOneDecimal(secondsToHours(rawDataMachining + rawDataIdle), plannedEquipmentLoadingTime);
              return <div className="boxstyle-lightblue littlebox">
                <div>
                  <div>{t("equipmentOperationalReadinessRate")}</div>
                  <div>
                    <span className="score-XL">{resultVal}%</span>
                  </div>
                </div>
              </div>
            })()}
            {/* <!--第2排｜3號框--> */}
            {(() => {
              let resultVal = decimalWithOneDecimal(secondsToHours(rawDataMachining), (24 * calIntervalDays));
              return <div className="boxstyle-lightblue littlebox">
                <div>
                  <div>{t("equipmentFullTimeOperationRate")}</div>
                  <div>
                    <span className="score-XL">{resultVal}%</span>
                  </div>
                </div>
              </div>
            })()}
            {/* <!--第2排｜4號框--> */}
            {(() => {
              let resultVal = shutDownDailyTimes;
              let result = "";
              if (ratingCriteria) {
                result = judgeErrorTime(resultVal, ratingCriteria.equipmentErrorDowntime, calIntervalDays);
              }

              return <div className={`boxstyle-lightblue littlebox color-div-lv2-${result}`}>
                <div>
                  <div>{t("numberErrors")}</div>
                  <div>
                    <span className="score-XL">{resultVal}</span>
                    <span className="criteria-L">{result.toUpperCase()}</span>
                  </div>
                </div>
              </div>
            })()}
          </div>
        </div>
      </div>
      {/* <!--第2排 END--> */}

    </>
  );
}
//#endregion

export default Summary;