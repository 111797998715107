import React, {useState, useEffect} from 'react';
import { DatePicker, Space, ConfigProvider } from 'antd';
import { I18nProvider } from '@react-aria/i18n';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './DateRangePicker.css';
import 'dayjs/locale/zh-tw';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;


export default function DateRangePicker({dateValue, setDateValue, lang}) {

  useEffect(() => {
    console.log("YA", dateValue)
  }, [dateValue]);
  
  const handleChange = (dates) => {
    setDateValue(formatDates(dates));
  };
  
  // 將日期轉換為所需格式的函數
  const formatDates = (dates) => {
    return dates ? dates.map(date => date.format('YYYY-MM-DD')) : [];
  };


  return (
    <ConfigProvider locale={lang === "zh-TW" ? require('antd/lib/locale/zh_TW').default : require('antd/lib/locale/en_US').default}>
      {
        dateValue?.length === 0 || !dateValue ? <RangePicker onChange={handleChange} /> : 
          <RangePicker value={[dayjs(dateValue[0], "YYYY-MM-DD"), dayjs(dateValue[1], "YYYY-MM-DD")]} onChange={handleChange} />
      }
    </ConfigProvider>
  );
}