import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal } from 'react-bootstrap';
import { apiSchedulePreview, apiSingleProcess, apiScheduleConfirm, apiSingleOrder } from 'utils/Api';
import { convertDateFormat, formatTimeDays, isTodayAfter } from 'utils/commonFun';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import MessageModal from 'components/basic/MessageModal';

const ManualScheduling = ({ showModal, handleClose, manualOrderId, isChangeSchedule, setAutoSchedulingModal,
  loadOrderList, loadOrderCountAndStatus }) => {
  const { t } = useTranslation("aps");

  const [oldAssignedStartTime, setOldAssignedStartTime] = useState("");  //舊的指定開始時間
  const [assignedStartTime, setAssignedStartTime] = useState("");  //指定開始時間
  const [machineSN, setMachineSN] = useState("");  //指定設備

  const [equipmentSetting, setEquipmentSetting] = useState([]); //設備設定檔

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息
  const [judgeCheckDisabled, setJudgeCheckDisabled] = useState(false); //是否要檢查開始排程按鈕disbled樣式

  const [scheduleResult, setScheduleResult] = useState([]); //排程結果

  const [showAbandonSchedulingModal, setShowAbandonSchedulingModal] = useState(false); //是否顯示放棄這個排程？Modal

  useEffect(() => {
    if (showModal) {
      handleSingleProcess();
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      if (!judgeCheckDisabled) {
        if (assignedStartTime != "" && assignedStartTime === oldAssignedStartTime) {
          setJudgeCheckDisabled(true);
        }
      }
      else {
        setJudgeCheckDisabled(false);
      }
    }
  }, [assignedStartTime, machineSN]);

  //#region 載入單一一筆工序裡的機台
  const handleSingleProcess = async () => {
    const [httpStatus, reponseData] = await handleApiSingleProcess();
    if (httpStatus == "200") {
      if (reponseData.statusCode === "20000") {
        let data = reponseData.data;

        let newEquipmentSetting = [];
        for (let item of data?.equipmentAndPrograms) {
          for (let detail of item?.equipment) {
            newEquipmentSetting.push({ value: detail?.machineSn, label: detail?.mShortName });
          }
        }
        setEquipmentSetting(newEquipmentSetting);

        if (manualOrderId?.orderStatus != 0) {
          if (manualOrderId?.machineSn) {
            setMachineSN(manualOrderId?.machineSn);
          }

          if (manualOrderId?.datetimeStart) {
            setAssignedStartTime(manualOrderId?.datetimeStart);
          }
        }
        else {
          setMachineSN(newEquipmentSetting[0].value);
        }
      }
    }
  }
  //#endregion

  //#region 載入單一一筆工序API
  const handleApiSingleProcess = async () => {
    let postJson = {
      processID: manualOrderId?.processID
    };
    let response = await apiSingleProcess(postJson);

    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "AssignedStartTime") {
      setAssignedStartTime(value);
    }
    else if (name === "AssignedEquipment") {
      setMachineSN(value);
    }
  };
  //#endregion

  //#region 開始排程按鈕
  const handleStartSchedule = async () => {
    setJudgeCheckErr(true);

    let check = checkCol();
    if (!check) {
      const [httpStatus, reponseData] = await handleApiSchedulePreview();
      if (httpStatus == "200") {
        if (reponseData.statusCode === "20000") {
          setJudgeCheckDisabled(true);
          setOldAssignedStartTime(assignedStartTime);
          setScheduleResult(reponseData.data.orderList);
        }
        else {
          setScheduleResult([]);
          toast.error(reponseData.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    }
  }
  //#endregion

  //#region 確認欄位
  const checkCol = () => {
    if (assignedStartTime === "" || !isTodayAfter(assignedStartTime, true, true)) {
      return true;
    }

    return false;
  }
  //#endregion

  //#region 預覽排程API
  const handleApiSchedulePreview = async () => {
    const formatted = assignedStartTime.includes('T')
      ? assignedStartTime.replace('T', ' ').includes(':')
        ? `${assignedStartTime.replace('T', ' ')}:00`
        : `${assignedStartTime.replace('T', ' ')}:00:00`
      : assignedStartTime;

    let postJson = {
      schedulingMethod: 1,
      orderID: [manualOrderId?.orderID],
      assignedStartTime: formatted,
      machineSn: machineSN
    };

    let response = await apiSchedulePreview(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 前往自動排程
  const jumpAuto = () => {
    handleClose();
    setAutoSchedulingModal(true);
  }
  //#endregion

  //#region 套用
  const handleApply = async () => {
    const [httpStatus, reponseData] = await handleApiScheduleConfirm();
    if (httpStatus == "200") {
      handleClose();
      if (reponseData.statusCode === "20000") {
        loadOrderList();
        loadOrderCountAndStatus();
        toast(t("appliedSuccessfully"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        handleClear();
      }
      else {
        toast.error(t("appliedFailed"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 套用排程API
  const handleApiScheduleConfirm = async () => {
    let postJson = {
      schedulingMethod: 1
    };

    let response = await apiScheduleConfirm(postJson);
    if (response) {
      const httpStatus = response.request.status;
      const reponseData = response.data;

      return [httpStatus, reponseData];
    }
    else {
      return ["500", ""]
    }
  }
  //#endregion

  //#region 取消排程按鈕
  const cancelSchedule = () => {
    setShowAbandonSchedulingModal(true);
    document.querySelector("#manualScheduling").classList.add('d-none');
  }
  //#endregion

  //#region 清空預設值
  const handleClear = () => {
    setOldAssignedStartTime("");
    setAssignedStartTime("");
    setMachineSN("");
    setJudgeCheckErr(false);
    setJudgeCheckDisabled(false);
    setScheduleResult([]);
  };
  //#endregion


  return (
    <>
      <Modal id="manualScheduling" show={showModal} onHide={handleClose} backdrop="static" size="xl" keyboard={false} centered>
        <div className="modal-header">
          <div>
            <div className="modal-title">{t("manualScheduling")}</div>
            <div className="modal-title-note">{t("requiredFieldsAsterisk")}
              <span className="text-red">&nbsp;*</span>
            </div>
          </div>
          <button type="button" className="close"
            data-dismiss="modal" aria-label="Close" onClick={cancelSchedule}>
            <div className="icon-Cross"></div>
          </button>
        </div>
        <Modal.Body>
          <div className="gp-BGgrey">
            <div className="title">{manualOrderId?.orderNo}-{manualOrderId?.processNo}</div>
            <div className="items grid3-1 gap20">
              <div className="item">
                <div className="label">{t("part")} / {t("process")}</div>
                <div className="content">{manualOrderId?.wpNO}  {manualOrderId?.wpName} / {manualOrderId?.processNo}  {manualOrderId?.processName}</div>
              </div>
              <div className="item">
                <div className="label">{t("scheduledDeliveryDate")}</div>
                <div className="content">
                  {convertDateFormat(manualOrderId?.scheduledDeliveryDate, "MMM DD")}
                  {convertDateFormat(manualOrderId?.scheduledDeliveryDate, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(manualOrderId?.scheduledDeliveryDate, "YYYY")}</> : null}
                </div>
              </div>
              <div className="item">
                <div className="label">{t("estDuration")}</div>
                <div className="content">
                  {(() => {
                    let result = [];
                    let resultDuration = [];
                    const minEstimatedDuration = manualOrderId?.minEstimatedDuration;
                    const maxEstimatedDuration = manualOrderId?.maxEstimatedDuration;

                    if (minEstimatedDuration != null) {
                      const transMinEstimatedDuration = formatTimeDays(minEstimatedDuration, t);
                      const transMaxEstimatedDuration = formatTimeDays(maxEstimatedDuration, t);

                      const [dayMin, timeMin] = transMinEstimatedDuration.split(' ');
                      if (timeMin != undefined) {
                        resultDuration.push(<span className="bold">{dayMin}</span>);
                        resultDuration.push(<span>{timeMin}</span>);
                      }
                      else {
                        resultDuration.push(<span>{dayMin}</span>);
                      }

                      if (maxEstimatedDuration != null) {
                        resultDuration.push(" ~ ");

                        const [dayMax, timeMax] = transMaxEstimatedDuration.split(' ');
                        if (timeMax != undefined) {
                          resultDuration.push(<span className="bold">{dayMax}</span>);
                          resultDuration.push(<span>{timeMax}</span>);
                        }
                        else {
                          resultDuration.push(<span>{dayMax}</span>);
                        }
                      }

                      result.push(<div>{resultDuration}</div>);
                    }

                    return result;
                  })()}
                </div>
              </div>
              <div className="item modal-inputText">
                <input type="datetime-local" id="AssignedStartTime"
                  name="AssignedStartTime"
                  className={`${judgeCheckErr && (assignedStartTime === "" || !isTodayAfter(assignedStartTime, true, true)) ? "error" : ""}`}
                  value={assignedStartTime}
                  onChange={handleInputChange}
                  required
                />
                <label className="label">{t("assignedStartTime")}</label>
                {
                  judgeCheckErr && assignedStartTime === "" ?
                    <span className="errortext">{t("requiredFields")}</span> : null
                }

                {
                  judgeCheckErr && assignedStartTime !== "" && !isTodayAfter(assignedStartTime, true, true) ?
                    <span className="errortext">{t("pastTimeAllowed")}</span> : null
                }
              </div>
              <div className="item modal-inputText">
                <select name="AssignedEquipment" required
                  value={machineSN} onChange={handleInputChange}>
                  {
                    equipmentSetting?.map((obj, index) => {
                      return (<option value={obj.value} key={index}>{obj.label}</option>)
                    })
                  }
                </select>
                <label className="label">{t("assignedEquipment")}</label>
              </div>
            </div>
            <div className="button-group">
              <div></div>
              <button className="button btn-lv3"
                onClick={handleStartSchedule}
                disabled={judgeCheckDisabled}>
                {t("startScheduling")}
              </button>
            </div>
          </div>
          {
            scheduleResult?.length > 0 ?
              <>
                <div className="mt-5"></div>
                <div className="stickyTop bgWhite">
                  <div className='schedulingResults mb-3'>{t("schedulingResults")}</div>
                  <div className="APSTable-gp">
                    <div className="gpcolor-left bg-black gpcolor-flatbottom"></div>
                    <div className="APSTable" id="WorkOrder">
                      <div className="thead">
                        <div className="tr tLeft">
                          <div className="th w1"></div>
                          <div className="td f3">{t("workOrderID")}</div>
                          <div className="td f5">{t("scheduledTime")}</div>
                          <div className="td f2">{t("equipment")}</div>
                          <div className="td f2">{t("scheduledDeliveryDate")}</div>
                          <div className="td f5">{t("part")}/{t("process")}</div >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="APSTable-gp">
                  <div className="gpcolor-left bg-black gpcolor-flattop"></div>
                  <div className="APSTable" id="WorkOrder">
                    {/*為了將表頭固定在上方，所以將原本放在表格區塊中的表頭往外移動到上方的sticky區塊*/}
                    {/* <div className="thead">
                      <div className="tr tLeft">
                        <div className="th w1"></div>
                        <div className="td f3">{t("workOrderID")}</div>
                        <div className="td f5">{t("scheduledTime")}</div>
                        <div className="td f2">{t("equipment")}</div>
                        <div className="td f2">{t("scheduledDeliveryDate")}</div>
                        <div className="td f5">{t("part")}/{t("process")}</div >
                      </div>
                    </div> */}
                    <div className="tbody">
                      {
                        scheduleResult?.map((obj, index) => {
                          return (
                            <div className={`tr ${manualOrderId.orderID === obj.orderID ? "clicked" : ""}`} key={index}>
                              {
                                obj.orderStatus === 2 ?
                                  <div className="th w1 dot">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip className="custom-tooltip">
                                          <span>{t("started")}</span>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="green"></div>
                                    </OverlayTrigger>
                                  </div> : <div className="th w1"></div>
                              }
                              <div className="td f3">{obj.orderNo}-{obj.processNo}</div>
                              <div className="td f5">
                                <div className={moment(obj.startTime).isAfter(assignedStartTime) ? "text-red" : ""}>
                                  {
                                    obj.startTime != null ?
                                      <div>
                                        <b>{convertDateFormat(obj.startTime, "MMM DD")}</b>
                                        <span>{convertDateFormat(obj.startTime, "hh:mm:ss A")}</span>
                                        <span> - </span>
                                        {
                                          convertDateFormat(obj.startTime, "MMM DD") != convertDateFormat(obj.endTime, "MMM DD") ?
                                            <b>{convertDateFormat(obj.endTime, "MMM DD")}</b> :
                                            null
                                        }
                                        <span>{convertDateFormat(obj.endTime, "hh:mm:ss A")}</span>
                                        {
                                          moment(obj.startTime).isAfter(assignedStartTime) ?
                                            <span className="schedulingDelayedTag">{t("postponed")}</span> : null
                                        }
                                      </div> : "-"
                                  }
                                </div>
                              </div>
                              <div className="td f2">{obj.mShortName}</div>
                              <div className="td f2">
                                <span className={`bold ${obj.deliveryDelay ? "text-red" : ""}`}>
                                  {convertDateFormat(obj.scheduledDeliveryDate, "MMM DD")}
                                  {convertDateFormat(obj.scheduledDeliveryDate, "YYYY") !== new Date().getFullYear().toString() ? <>, {convertDateFormat(obj.scheduledDeliveryDate, "YYYY")}</> : null}
                                </span>
                                {
                                  obj.deliveryDelay ?
                                    <div className='schedulingDelayedTag'>{t("delayed")}</div> : null
                                }
                              </div>
                              <div className="td f5">
                                <div>
                                  <b>{obj.wpNO}</b>
                                  {obj.wpName}
                                  /
                                  <b>{obj.processNo}</b>
                                  {obj.processName}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
                <div className="spaceHeight1"></div>
              </> : null
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <div>
              <button className="button btn-txt-black" onClick={cancelSchedule}>
                {t("cancel")}
              </button>
              {
                isChangeSchedule ?
                  <button className="button btn-lv2" onClick={jumpAuto}>
                    {t("goAutoScheduling")}
                  </button> : null
              }
            </div>
            {
              scheduleResult?.length > 0 ?
                <button className="button btn-lv1" onClick={handleApply}>
                  {t("apply")}
                </button> : null
            }
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

      {/* 放棄這個排程？ */}
      <MessageModal
        title={t("abandonScheduling")}
        content={`${t("abandonSchedulingContent1")}<br /><br />${t("abandonSchedulingContent2")}`}
        showModal={showAbandonSchedulingModal}
        handleConfirmData={() => { handleClose(); setShowAbandonSchedulingModal(false); handleClear(); loadOrderList(true); }}
        size="xs"
        confirmModalTxt={t("abandon")}
        cancelTxt={t("cancel")}
        cancelModal={() => { document.querySelector("#manualScheduling").classList.remove('d-none'); setShowAbandonSchedulingModal(false);  loadOrderList(false); }}
        handleClose={() => { document.querySelector("#manualScheduling").classList.remove('d-none'); setShowAbandonSchedulingModal(false); }}
      />

    </>
  );
};

export default ManualScheduling;