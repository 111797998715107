import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import "./Modal.css";

const SelectData = ({showModal, handleClose, metricsApiData, selectData, setSelectData, setMetricsNum,
  delSelectData, setDelSelectData
}) => {
  const { t } = useTranslation("chartData");
  const [initSelectData, setInitSelectData] = useState([]); //初始值
  const [currentSelectData, setCurrentSelectData] = useState([]);


  useEffect(() => {
    if(showModal){
      setInitSelectData(selectData);
      setCurrentSelectData(selectData);
      setDelSelectData([]);
    }
  }, [showModal]);


  //代表刪除只能先改變狀態(要記得做)
  const handleCheckboxChange = (event, main, sub, chartIndex) => {
    const isChecked = event.target.checked;  
    let judgeDel = false;
    
    setCurrentSelectData(prevSelectData => {
      const newSelectData = [...prevSelectData];

      // Find index of the main group
      const mainIndex = newSelectData.findIndex(d => d.metricGroupID === main.metricGroupID);
            
      if (mainIndex !== -1) {
        const metricItems = newSelectData[mainIndex].metricItems || [];
        // Find sub item index
        const subIndex = metricItems.findIndex(d => d.metricID === sub.metricID);

        if (!isChecked) {
          if(subIndex !== -1){
            // Remove the existing sub item
            // metricItems.splice(subIndex, 1);
            metricItems[subIndex].behavior = "delete";
            metricItems[subIndex].searchResult = 0;

            judgeDel = true;
            // // If metricItems is empty, remove the whole metricGroup
            // if (metricItems.length === 0) {
            //   newSelectData.splice(mainIndex, 1);
            // } 
            // else {
            //   // Otherwise, update the metricItems in the group
            //   newSelectData[mainIndex].metricItems = metricItems;
            // }
          }
          else{
            metricItems[subIndex].behavior = "";
            // if (metricItems.length === 0) {
            //   newSelectData.splice(mainIndex, 1);
            // } 
          }
        } 
        else {
          // Add the sub item
          if(subIndex === -1){
            metricItems.push({
              metricID: sub.metricID,
              metricCode: sub.metricCode,
              metricCollection: sub.metricCollection,
              metricStatus: "d-none",  //show=>顯示 hide=>隱藏 nodata=>完全沒有數據 loading=>搜尋當中 d-none=>不出現
              behavior: "",  //操作行為 delete=>要刪除
              apiData: [],
              apiStatus: "", //finish資料完成 空代表完全沒做 Stop暫停
              searchResult: 0  //0:尚未執行新搜尋  1:已執行新搜尋
            });
            newSelectData[mainIndex].metricItems = metricItems;
          }
          else{
            metricItems[subIndex].behavior = "";
          }
        }
      } else {
        if (isChecked){
          // If main group does not exist, add a new one with metricItems
          newSelectData.push({
            metricGroupID: main.metricGroupID,
            metricGroupSelectName: main.metricGroupSelectName,
            metricGroupDisplayName: main.metricGroupDisplayName,
            chartStatus: "d-none", //d-none=>不出現 loading=>搜尋當中 finish=>全部完成(有資料) nodata=>全部完成(無資料)
            chartIndex: chartIndex,
            metricItems: [
              {
                metricID: sub.metricID,
                metricCode: sub.metricCode,
                metricCollection: sub.metricCollection,
                metricStatus: "d-none",  //show=>顯示 hide=>隱藏 nodata=>完全沒有數據 loading=>搜尋當中 d-none=>不出現
                behavior: "",  //操作行為 delete=>要刪除
                apiData: [],
                apiStatus: "", //finish資料完成 空代表完全沒做 Stop暫停
                searchResult: 0  //0:尚未執行新搜尋  1:已執行新搜尋
              },
            ],
          });
        }
      }

      setDelSelectData(prevDelSelectData => {
        const newDelSelectData = [...prevDelSelectData];
        const filterExists = newDelSelectData.findIndex(d => d.metricCode === sub.metricCode);
  
        if (judgeDel) {
          if (filterExists === -1) {
            newDelSelectData.push({groupIndex: chartIndex, metricCode: sub.metricCode});
          }
        } else {
          if (filterExists !== -1) {
            newDelSelectData.splice(filterExists, 1);
          }
        }
  
        return newDelSelectData;
      });
      return newSelectData;
    });
  };

  //#region 刪除數據
  const handleDelMetrics = (main, sub) => {
    setCurrentSelectData(prevSelectData => {
      // Clone the previous state
      const newSelectData = [...prevSelectData];
      const mainIndex = newSelectData.findIndex(d => d.metricGroupID === main.metricGroupID);
      if (mainIndex !== -1) {
        const metricItems = newSelectData[mainIndex].metricItems || [];
        const subIndex = metricItems.findIndex(d => d.metricID === sub.metricID);

        if(subIndex !== -1){
          // metricItems.splice(subIndex, 1);
          metricItems[subIndex].behavior = "delete";
          metricItems[subIndex].searchResult = 0;

          setDelSelectData(prevDelSelectData => {
            let newDelSelectData = [...(prevDelSelectData || [])];
            newDelSelectData.push(sub.metricCode);
            return newDelSelectData;
          });

          // if (metricItems.length === 0) {
          //   newSelectData.splice(mainIndex, 1);
          // }
        }
      }

      return newSelectData;
    });
  }
  //#endregion

  //#region 清空
  const handleClear = () => {
    // setCurrentSelectData([]);
    const updatedData = currentSelectData.map(group => ({
      ...group,
      metricItems: group.metricItems.map(item => ({
          ...item,
          behavior: "delete"
      }))
    }));

    const newDelSelectData = currentSelectData.flatMap(group => 
      group.metricItems.map(item => ({
          groupIndex: group.chartIndex,
          metricCode: item.metricCode
      }))
    );
    setDelSelectData(newDelSelectData);

    setCurrentSelectData(updatedData);
  }
  //#endregion

  //#region 套用
  const handleApply = () => {
    setSelectData(currentSelectData);

    let num = 0;
    for(let main of currentSelectData){
      num += main.metricItems.filter(d => d.behavior != "delete").length;
    }
    setMetricsNum(num);
    handleClose();
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("selectData")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="area-checkbox-category">
            <div className="common">
              <div className="dot"></div>
              <div className="text">{t("commonlySelectedData")}</div>
            </div>
            <div>
            {(() => {
              if(metricsApiData){
                let result = [];
                let oldMetricGroupSelectName = "";
                let currentCategory = [];
                let currentItem = [];
                const newSelectData = [...currentSelectData];

                for(let [itemIndex, item] of metricsApiData.entries()){
                  let judgeCheck = false;
                  const mainIndex = newSelectData.findIndex(d => d.metricGroupID === item.metricGroupID);

                  if(item.metricGroupSelectName !== oldMetricGroupSelectName){
                    currentItem = [];
                    for(let [detailIndex, detail] of item.metricItems.entries()){
                      if(mainIndex != -1){
                        const metricItems = newSelectData[mainIndex].metricItems || [];
                        const subIndex = metricItems.findIndex(d => d.metricID === detail.metricID && d.behavior != "delete");

                        if(subIndex !== -1){
                          judgeCheck = true;
                        }
                        else{
                          judgeCheck = false;
                        }
                      }

                      currentItem.push(
                        <div className={`item ${detail.commonlySelected ? "common" : ""}`} key={detail.metricID}>
                            <div>
                                <input type="checkbox" id={`metric_${detail.metricID}`}
                                  checked={judgeCheck} 
                                  onChange={(e) => handleCheckboxChange(e, item, detail, itemIndex)}  />
                                <label htmlFor={`metric_${detail.metricID}`}>
                                    {t(`${detail.metricCode}_modal`)}
                                    <div className="fill"></div>
                                </label>
                            </div>
                            <div className="remark">
                              <div>{t(`${detail.metricCode}_unit`)}</div>
                              <div>{detail.chartType === "L" ? t("lineChart") : t("timeLine")}</div>
                            </div>
                        </div>
                      );
                    }

                    currentCategory.push(
                      <div className="category" key={`group_${item.metricGroupID}`}>
                        <div className="title">{t(`${item.metricGroupSelectName}`)}</div>
                        <div className="item-group">
                          {currentItem}
                        </div>
                      </div>
                    );

                    oldMetricGroupSelectName = item.metricGroupSelectName;


                  }
                  else{
                    //想要push在上一個迴圈的category
                    for(let detail of item.metricItems){
                      if(mainIndex != -1){
                        const metricItems = newSelectData[mainIndex].metricItems || [];
                        const subIndex = metricItems.findIndex(d => d.metricID === detail.metricID && d.behavior != "delete");

                        if(subIndex !== -1){
                          judgeCheck = true;
                        }
                        else{
                          judgeCheck = false;
                        }
                      }

                      // onChange={(e)=>choiceItem(e, item, detail)}
                      currentItem.push(
                        <div className={`item ${detail.commonlySelected ? "common" : ""}`} key={detail.metricID}>
                            <div>
                                <input type="checkbox" id={`metric_${detail.metricID}`}
                                  checked={judgeCheck} 
                                  onChange={(e) => handleCheckboxChange(e, item, detail, itemIndex)}
                                />
                                <label htmlFor={`metric_${detail.metricID}`}>
                                    {t(`${detail.metricCode}_modal`)}
                                    <div className="fill"></div>
                                </label>
                            </div>
                            <div className="remark">
                              <div>{t(`${detail.metricCode}_unit`)}</div>
                              <div>{detail.chartType === "L" ? t("lineChart") : t("timeLine")}</div>
                            </div>
                        </div>
                      );
                    }
                  }
                }
                
                  

                if(currentCategory){
                  result.push(currentCategory);
                }
                
                return result;
              }
              
            })()}
            </div>
          </form>
          
        </Modal.Body>
        <div className="modal-footer-custom">
          <div className="item-selected scroll">
            {currentSelectData.map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {group.metricItems
                  .filter(item => item.behavior !== "delete")
                  .map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <div>
                      {t(`${item.metricCode}_modal`)}
                    </div>
                    <div className="cancel" onClick={()=>handleDelMetrics(group, item)}>
                      <div className="material-symbols-rounded">close</div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
          <div className="btn-gp">
            <div>
                <button className="btn btn-txt-black" onClick={handleClose}>{t("cancel")}</button>
                <button type="reset" className="btn btn-lv3" onClick={handleClear}>{t("clear")}</button>
            </div>
            <button type="submit" className="btn btn-lv1" onClick={handleApply}>{t("apply")}</button>
          </div>
        </div>
        {/* <Modal.Footer>
          <div className="item-selected scroll">
            {currentSelectData.map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {group.metricItems
                  .filter(item => item.behavior !== "delete")
                  .map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <div>
                      {t(`${item.metricCode}_modal`)}
                    </div>
                    <div className="cancel" onClick={()=>handleDelMetrics(group, item)}>
                      <div className="material-symbols-rounded">close</div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
          <div className="button-group">
            <div>
                <button className="button btn-txt-black" onClick={handleClose}>{t("cancel")}</button>
                <button type="reset" className="button btn-lv3" onClick={handleClear}>{t("clear")}</button>
            </div>
            <button type="submit" className="button btn-lv1" onClick={handleApply}>{t("apply")}</button>
          </div>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
}

export default SelectData;