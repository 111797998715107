import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal } from 'react-bootstrap';
import { apiOrderStatus } from 'utils/Api';
import { ToastContainer, toast } from 'react-toastify';

//#region 暫停工單
const PauseWorkOrder = ({showModal, handleClose, orderID, loadOrderList, loadOrderCountAndStatus}) => {
  const { t } = useTranslation("aps");  

  //#region 確定暫停
  const handleConfirm = async () => {
    const [httpStatus, reponseData] = await handleApiOrderStatus();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20100"){
        handleClose();
        loadOrderList();
        loadOrderCountAndStatus();
        toast(t("pausedSuccessfully"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else{
        toast.error(reponseData.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }
  //#endregion

  //#region 工單狀態更新API
  const handleApiOrderStatus = async () => {
    let postJson = {
      orderID: orderID,
      orderStatus: 3
    };
    
    let response = await apiOrderStatus(postJson);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static" size="sm" keyboard={false} centered>
        <div className="scroll">
          <div className="modal-header">
            <div>
                <div className="modal-title">{t("pauseWorkOrder")}</div>
            </div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
              onClick={handleClose}>
                <div className="icon-Cross"></div>
            </button>
          </div>
          <div className="modal-body">
            <div className="text">
              {t("pauseWorkOrderContent1")}
              <br /><br />
              {t("pauseWorkOrderContent2")}
            </div><br />
            <div className='text-blue'>
              {t("pauseWorkOrderNote1")}<br />
              {t("pauseWorkOrderNote2")}
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn-gp">
              <button className="btn btn-txt-blue" onClick={handleClose}>{t("cancel")}</button>
              <button className="btn btn-lv1 fat" onClick={handleConfirm}>{t("confirm")}</button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default PauseWorkOrder;
//#endregion