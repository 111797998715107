import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useParams, useNavigate } from 'react-router-dom';
import { encrypt, decrypt } from 'utils/crypt';

export default function WorkpieceBreadCrumb({ basinInfo, workpieceID, programID, execuutionID = "" }) {
  const { t } = useTranslation("powerCarbon");
  const navigate = useNavigate();

  const jumpMain = () => {
    if (decrypt(programID) != "0") {
      localStorage.setItem("workpieces_choicePage", 2);
    }
    else {
      localStorage.setItem("workpieces_choicePage", 1);
    }
    navigate("/Power/PartsPrograms");
  }

  return (
    <div className='breadcrumb-div'>
      <span className='clickable cursor-pointer' onClick={jumpMain}>
        {t("list")}
      </span>
      {
        basinInfo?.wpNO != "" ?
          <div className="icon icon-BreadCrumbNext"></div>:
          null
      }
      {
        decrypt(programID) != "0" ?
          <div className="text clickable cursor-pointer" onClick={() => { navigate(`/Power/PartsPrograms/${workpieceID}/${encrypt("0")}`) }}>
            {basinInfo?.wpNO}
          </div> :
          <div className="item">
            {basinInfo?.wpNO}
          </div>
      }
      {
        execuutionID !== "" ?
          <>
            <div className="icon icon-BreadCrumbNext"></div>
            <div className="text clickable cursor-pointer" onClick={() => { navigate(`/Power/PartsPrograms/${workpieceID}/${programID}`) }}>{basinInfo?.programName}</div>
          </> :
          decrypt(programID) != "0" ?
            <>
              <div className="icon icon-BreadCrumbNext"></div>
              <div className="item short">{basinInfo?.programName}</div>
            </> :
            null
      }
      {
        execuutionID !== "" ?
          <>
            <div className="icon icon-BreadCrumbNext"></div>
            <div className="item short">{t("details")}</div>
          </> :
          null
      }
    </div>
  );
}