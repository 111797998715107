import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal } from 'react-bootstrap';
import copy from 'copy-to-clipboard';

const UnableSchedule = ({showModal, handleClose, unableScheduleProgram}) => {
  const { t } = useTranslation("aps");  

  //#region 複製加工程式列表
  const handleCopy = () => {
    let txt = "";
    for(let item of unableScheduleProgram){
      txt += item + "\n";
    }
    copy(txt);
  }
  //#endregion


  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
            <Modal.Title>{t("unableSchedule")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='unableScheduleContent'>
              {t("unableScheduleContent")}
            </div>
            <a href="/Folder/Programs" target="_blank" className='unableScheduleLink'>
              {t("unableScheduleLink")}
            </a>
            <div className='unableSchedule-programList'>
              {
                unableScheduleProgram.map((obj, index) => {
                  return(
                    <p key={index} className="mb-1">{obj}</p>
                  )
                }) 
              }
              <div className='copyRow cursor-pointer' onClick={handleCopy}>
                <div className='copyTxt'>{t("copy")}</div>
              </div>
              
            </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <div>
              
            </div>
            <button className="button btn-lv1" onClick={handleClose}>
              {t("confirm")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default UnableSchedule;