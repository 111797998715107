import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { MyUserContext } from 'contexts/MyUserContext';
import Select from 'react-select';
import "./modal.css";

import { isNumber, isPositiveInteger, getCurrentDateTime } from 'utils/commonFun';

import { apiPostPeriodReport, apiProgramList, apiCreateWorkpiece, apiWorkpieceList } from 'utils/Api';

const CapturePeriodPart = ({showModal, handleClose, currentStep, setCurrentStep, handleNextStep, choiceMachine,
  periodName, setPeriodName, currentReportID, periodReportType, setPeriodReportType, wpID, setWpID, setProgramID,
  wpNO, setWpNO, wpName, setWpName, programID, programName, setProgramName, productionQty, setProductionQty,
  remark, setRemark, displayCreatPart, setDisplayCreatPart
}) => {
  const { t } = useTranslation("powerCarbon");
  const { overtime, authority, lang, periodReportTypeList } = useContext(MyUserContext);

  const regex = /^[^\/\\\*\?\$\@]+$/;

  const [judgeCheckErr, setJudgeCheckErr] = useState(false); //是否要檢查錯誤訊息

  const [judgeUniquePeriodName, setJudgeUniquePeriodName] = useState(true); //檢查是否唯一值區間名稱

  const [judgeUniqueWpNO, setJudgeUniqueWpNO] = useState(true); //檢查是否唯一值工件代號
  const [judgeUniqueWpNOError, setJudgeUniqueWpNOError] = useState(""); //若工件不是唯一值，就顯示工件名稱
  const [judgeUniqueProgramName, setJudgeUniqueProgramName] = useState(true); //檢查是否唯一值加工程式
  const [judgeUniqueProgramNameError, setJudgeUniqueProgramNameError] = useState(""); //若加工程式不是唯一值，就顯示工件代號與工件名稱

  const [selectedOptionPart, setSelectedOptionPart] = useState(null);
  const [optionsPart, setOptionsPart] = useState([]);

  //下拉選單工件資訊
  const [selWpID, setSelWpID] = useState(0);
  const [selWpNO, setSelWpNO] = useState("");
  const [selWpName, setSelWpName] = useState("");
  const [selProgramID, setSelProgramID] = useState(0);
  const [selProgramName, setSelProgramName] = useState("");

  //建立工件資訊
  const [createWpID, setCreateWpID] = useState(0);
  const [createWpNO, setCreateWpNO] = useState("");
  const [createWpName, setCreateWpName] = useState("");
  const [createProgramID, setCreateProgramID] = useState(0);
  const [createProgramName, setCreateProgramName] = useState("");

  useEffect(() => {
    if(showModal){
      setJudgeCheckErr(false);
      setCurrentStep(2);
      loadSelWorkpiece();

      if(periodReportType === 1){
        if(wpID === 0 && programID === 0){
          setCreateWpID(0);
          setCreateWpNO("");
          setCreateWpName("");
          setCreateProgramID(0);
          setCreateProgramName("");
        }
        else{
          setCreateWpID(wpID);
          setCreateWpNO(wpNO);
          setCreateWpName(wpName);
          setCreateProgramID(programID);
          setCreateProgramName(programName);
        }

        if (selectedOptionPart && !optionsPart.find(option => option.value === selectedOptionPart.value) || (wpID === 0 && programID === 0)) {
          setSelectedOptionPart(null);
          setSelWpID(0);
          setSelWpNO("");
          setSelWpName("");
          setSelProgramID(0);
          setSelProgramName("");
        }
        else{
          setSelWpID(wpID);
          setSelWpNO(wpNO);
          setSelWpName(wpName);
          setSelProgramID(programID);
          setSelProgramName(programName);
        }

        // if(displayCreatPart){
          
        // }
        // else{
          
        // }
      }

      if(periodName === ""){
        //要帶預設 開始時間的年月日時分秒 + "-" + 設備名稱
        setPeriodName(`${getCurrentDateTime()}-${choiceMachine?.machineSn}`);
      }

      if(productionQty === ""){
        setProductionQty("1");
      }
    }
  }, [showModal]);

  // useEffect(() => {
  //   console.log("finishWpID", wpID);
  //   console.log("finishWpNO", wpNO);
  //   console.log("finishWpName", wpName);
  //   console.log("finishProgramID", programID);
  //   console.log("finishProgramName", programName);
  // }, [wpID, wpNO, wpName, programID, programName]);

  // useEffect(() => {
  //   console.log("建立WpID", createWpID);
  //   console.log("建立WpNO", createWpNO);
  //   console.log("建立WpName", createWpName);
  //   console.log("建立ProgramID", createProgramID);
  //   console.log("建立ProgramName", createProgramName);
  // }, [createWpID, createWpNO, createWpName, createProgramID, createProgramName]);

  // useEffect(() => {
  //   console.log("下拉WpID", selWpID);
  //   console.log("下拉WpNO", selWpNO);
  //   console.log("下拉WpName", selWpName);
  //   console.log("下拉ProgramID", selProgramID);
  //   console.log("下拉ProgramName", selProgramName);
  // }, [selWpID, selWpNO, selWpName, selProgramID, selProgramName]);

  //#region 回上一步
  const handlePrevious = () => {
    handleClose(1);
  }
  //#endregion

  //#region 開始擷取按鈕
  const handleStartCapture = async() => {
    setJudgeCheckErr(true);

    let checkError = await checkCol();
    if(!checkError){
      if(periodReportType === 1){
        if(displayCreatPart){
          setWpID(createWpID);
          setWpNO(createWpNO);
          setWpName(createWpName);
          setProgramID(createProgramID);
          setProgramName(createProgramName);
        }
        else{
          setWpID(selWpID);
          setWpNO(selWpNO);
          setWpName(selWpName);
          setProgramID(selProgramID);
          setProgramName(selProgramName);
        }
      }
      
      handleNextStep();
      // let checkUniquePartErr = await checkUniquePart(true, true);
      // if(!checkUniquePartErr){
      //   handleNextStep();
      // }
    }
  }
  //#endregion

  //#region 檢查欄位是否有問題
  const checkCol = async () => {
    let result = false;
    if(periodName === "" || periodName.length > 100 || !regex.test(periodName?.trim())){
      result = true;
    }

    if(periodReportType != 1){
      if(remark != "" && remark.length > 300){
        result = true;
      }
    }
    else{
      if(displayCreatPart){
        if((createWpNO === "" && createWpName !== "") || (createWpNO !== "" && createWpName === "")){
          result = true;
        }
        else{
          if((createWpNO !== "" && createWpNO.length > 100) || (createWpName !== "" && createWpNO.length > 100)){
            result = true;
          }
        }

        if(createProgramName !== "" && createProgramName.length > 100){
          result = true;
        }
      }

      if(productionQty === ""){
        result = true;
      }
      else{
        if(!isNumber(productionQty) || !isPositiveInteger(productionQty)){
          result = true;
        }
      }
    }

    if(remark !== "" && remark.length > 300){
      result = true;
    }

    //上面錯誤訊息沒有錯再進入API檢查名稱重複問題
    if(!result){
      //檢查區間名稱是否重複
      let checkUniquePeriodNameErr = await checkUniquePeriodName(true);
      if(checkUniquePeriodNameErr){
        return true;
      }

      //檢查工件是否有重複
      if(displayCreatPart && periodReportType === 1){
        let checkUniquePartErr = await checkUniquePart(true);
        if(checkUniquePartErr){
          return true;
        }
      }
    }
    return result;
  }
  //#endregion
  
  //#region Input欄位改變值
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const setters = {
      periodName: setPeriodName,
      periodReportType: (v) => setPeriodReportType(parseInt(v)),
      createWpNO: setCreateWpNO,
      createWpName: setCreateWpName,
      createProgramName: setCreateProgramName,
      producedCount: setProductionQty,
      remark: setRemark
    };
  
    if (setters[name]) {
      setters[name](value);
    }

    if(name === "createWpNO"){
      setWpNO(value);
    }

    if(name === "createWpName"){
      setWpName(value);
    }

    if(name === "createProgramName"){
      setProgramName(value);
    }
  };
  //#endregion

  //#region 檢查區間名稱是否重複
  const checkUniquePeriodName = async (judgeForceCheck = false) => {
    let result = false;
    //格式正確再來檢查是否有重複
    if((judgeCheckErr && periodName !== "" && periodName.length <= 100 && regex.test(periodName?.trim())) || judgeForceCheck){
      const [httpStatus, reponseData] = await handleApiPostPeriodReport();
      if(httpStatus == "200"){
        if(reponseData.statusCode === "DL001"){
          setJudgeUniquePeriodName(false);
          result = true;
        }
        else if(reponseData.statusCode === "20100"){
          setJudgeUniquePeriodName(true);
        }
      }
    }

    return result;
  }
  //#endregion

  //#region 檢查區間名稱是否重複API
  const handleApiPostPeriodReport = async () => {
    let postJson = {
      action: "checkReportName",
      reportID: currentReportID,
      reportName: periodName
    };

    let response = await apiPostPeriodReport(postJson);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region change事件工件/加工程式
  const handleChangePart = (option) => {
    setSelectedOptionPart(option);

    if(option != null){
      let parts = option.value.split('-');
      let firstValue = parts[0];
      let secondValue = parts[1];

      if(firstValue != "0"){
        setSelWpID(parseInt(firstValue));
        setSelWpNO(option.wpNO);
        setSelWpName(option.wpName);
      }
      else{
        setSelWpID(0);
        setSelWpNO("");
        setSelWpName("");
      }

      if(secondValue != "0"){
        setSelProgramID(parseInt(secondValue));
        setSelProgramName(option.programName);
      }
      else{
        setSelProgramID(0);
        setSelProgramName("");
      }
    }
    else{
      setSelWpID(0);
      setSelProgramID(0);
      setSelWpNO("");
      setSelWpName("");
      setSelProgramName("");
    }
  };
  //#endregion

  //#region 下拉選單工件
  const loadSelWorkpiece = async () => {
    var parts = [];

    //抓取工件/加工程式以及只有加工程式
    const [httpStatus, reponseData] = await handleApiProgramList();
    if(httpStatus == "200"){
      if(reponseData.statusCode === "20000"){
        const data = reponseData.data;
        if(data.totalItems > 0){
          var parts = [];
          for(let item of data.detail){
            if(item.wpID != null && item.programID != null){
              parts.push({ 
                value: `${item.wpID}-${item.programID}`, 
                label: `${item.wpNO} ${item.wpName}/${item.programName}`,
                wpNO: item.wpNO,
                wpName: item.wpName,
                programName: item.programName
              });
            }
            else if(item.wpID == null && item.programID != null){
              parts.push({ 
                value: `0-${item.programID}`, 
                label: `${item.programName}`,
                wpNO: item.wpNO,
                wpName: item.wpName,
                programName: item.programName
              });
            }
          }
        }
      }
    }

    const [httpStatusWorkpiece, reponseDataWorkpiece] = await handleApiWorkpieceList();
    if(httpStatusWorkpiece == "200"){
      if(reponseDataWorkpiece.statusCode === "20000"){
        const data = reponseDataWorkpiece.data;
        if(data.totalItems > 0){
          for(let item of data.detail){
            parts.push({ 
              value: `${item.wpID}-0`, 
              label: `${item.wpNO} ${item.wpName}`,
              wpNO: item.wpNO,
              wpName: item.wpName,
              programName: item.programName
            });
          }
        }
      }
    }

    setOptionsPart(parts);
  }
  //#endregion

  //#region API抓取工件列表
  const handleApiWorkpieceList = async () => {
    let postJson = {doIncludeNoProgram: 2};
    let programListResponse = await apiWorkpieceList(postJson);
    if(programListResponse){
      const httpStatus = programListResponse.request.status;
      const reponseData = programListResponse.data;

      return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region API抓取加工程式列表
  const handleApiProgramList = async () => {
    let postJson = {
      filterWPCondition: 0
    }
    let programListResponse = await apiProgramList(postJson);
    if(programListResponse){
        const httpStatus = programListResponse.request.status;
        const reponseData = programListResponse.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 工件資訊是否重複
  const checkUniquePart = async (judgeForceCheck = false, doCreate = false) => {
    var result = false;
    //格式正確再來檢查是否有重複
    if((judgeCheckErr && (wpNO != "" && wpName != "" && wpNO.length <= 100 && wpName.length <= 100) || 
      (programName != "" && programName.length <= 100) || judgeForceCheck)){
      const [httpStatus, reponseData] = await handleApiCreateWorkpiece(doCreate);
      if(httpStatus == "200"){
        let data = reponseData.data;
        if(reponseData.statusCode === "DL001"){
          result = true;
          if(data.notUnique.length === 2){
            setJudgeUniqueWpNO(false);
            setJudgeUniqueProgramName(false);

            setJudgeUniqueWpNOError(data.errorMsgWpNO);
            setJudgeUniqueProgramNameError(data.errorMsgPgmName);
          }
          else if(data.notUnique.length === 1){
            if(data.notUnique[0] === "wpNO"){
              setJudgeUniqueWpNO(false);
              setJudgeUniqueProgramName(true);

              setJudgeUniqueWpNOError(data.errorMsgWpNO);
              setJudgeUniqueProgramNameError("");
            }
            else{
              setJudgeUniqueWpNO(true);
              setJudgeUniqueProgramName(false);

              setJudgeUniqueWpNOError("");
              setJudgeUniqueProgramNameError(data.errorMsgPgmName);
            }
          }
        }
        else if(reponseData.statusCode === "20100"){
          setJudgeUniqueWpNO(true);
          setJudgeUniqueProgramName(true);

          setJudgeUniqueWpNOError("");
          setJudgeUniqueProgramNameError("");

          // if(data.wpID != null){
          //   setWpID(data.wpID)
          // }
          // else{
          //   setWpID(0);
          // }

          // if(data.programID != null){
          //   setProgramID(data.programID)
          // }
          // else{
          //   setProgramID(0);
          // }
        }
      }
    }
    else{
      setJudgeUniqueWpNO(true);
      setJudgeUniqueProgramName(true);

      setJudgeUniqueWpNOError("");
      setJudgeUniqueProgramNameError("");
    }

    return result;
  }
  //#endregion

  //#region 檢查工件資訊是否重複API
  const handleApiCreateWorkpiece = async (doCreate) => {
    let postJson = {
      doCreate: doCreate
    };

    if(createWpNO != "" && createWpName != ""){
      postJson.wpNO = createWpNO;
      postJson.wpName = createWpName;
    }

    if(createProgramName != ""){
      postJson.programName = createProgramName;
    }

    let response = await apiCreateWorkpiece(postJson);
    if(response){
        const httpStatus = response.request.status;
        const reponseData = response.data;

        return [httpStatus, reponseData];
    }
    else{
        return ["500", ""]
    }
  }
  //#endregion

  //#region 建立工件Div
  const handleCreatePart = () => {
    setDisplayCreatPart(true);
  }
  //#endregion

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("capturePeriod")}<br />
            <p className='planColTitle mb-0'>{t("requiredFieldsAsterisk")} <span className='text-danger ms-1'>*</span></p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="periodName">
                    <Form.Label className='form-label-custom'>
                      {t("periodName")}
                      <span className='text-danger ms-1'>*</span>
                      <span className='form-textnum ms-1'>({periodName.length ?? 0}/100)</span>
                    </Form.Label><br />
                    <Form.Control
                      type="text" name="periodName" value={periodName}
                      onChange={handleInputChange} onBlur={checkUniquePeriodName} autoComplete="off" 
                      className={`${judgeCheckErr && (periodName === "" || periodName.length > 100 || !regex.test(periodName?.trim()) || !judgeUniquePeriodName) ? "input-error" : ""}`}
                    />
                    {
                      judgeCheckErr && periodName === "" ?
                        <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                        <></>
                    }
                    {
                      judgeCheckErr && periodName !== "" && periodName.length > 100 ?
                        <Form.Label className='text-danger mb-0'>
                          {t("exceedsCharacterLimit")}
                        </Form.Label> :
                        <></>
                    }
                    {
                      judgeCheckErr && periodName !== "" && periodName.length <= 100 && !regex.test(periodName?.trim()) ?
                        <Form.Label className='text-danger mb-0'>
                          {t("specialCharactersError")}
                        </Form.Label> :
                        <></>
                    }
                    {
                      judgeCheckErr && periodName !== "" && periodName.length <= 100 && regex.test(periodName?.trim()) && !judgeUniquePeriodName ?
                        <Form.Label className='text-danger mb-0'>
                          {t("nameAlreadyTaken")}
                        </Form.Label> :
                        <></>
                    }
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="periodReportType">
                  <Form.Label className='form-label-custom'>
                    {t("periodReportType")}
                    <span className='text-danger ms-1'>*</span>
                  </Form.Label><br />
                  <Form.Select as="select" name='periodReportType' 
                    value={periodReportType} onChange={handleInputChange}>
                      {
                        periodReportTypeList?.map((obj, index) => {
                          return(
                            <option key={index} value={obj.key}>{obj.name}</option>
                          )
                        }) 
                      }
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {
              periodReportType === 1 ?
                <>
                  <Row>
                    <Col xs={12}>
                      <Form.Group className="mb-3" controlId="part">
                        <Form.Label className='form-label-custom'>
                          {t("partProduced")}
                        </Form.Label><br />
                        <Select
                          value={selectedOptionPart}
                          onChange={handleChangePart}
                          options={optionsPart}
                          isSearchable
                          placeholder={t("selectPartProgram")}
                          isClearable
                          isDisabled={displayCreatPart}
                        />
                        <div className='d-flex align-content-end justify-content-end mt-3'>
                          <span className='text-grey4'>{t("cantFindPart")}</span>
                          <div className='cursor-pointer d-flex align-content-center' onClick={handleCreatePart}>
                            <span className='text-blue'>{t("createNewOne")}</span>
                            <span className="material-symbols-rounded addPart">add</span>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {
                    displayCreatPart ? 
                      <>
                        <div className='createPartCard'>
                          <Row>
                            <Col xs={6}>
                              <span className='createPartTitle'>{t("create")}</span>
                            </Col>
                            <Col xs={6} className='d-flex justify-content-end'>
                              <span className="material-symbols-rounded cursor-pointer" onClick={()=>{setDisplayCreatPart(false);}}>close</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <Form.Group className="mb-3" controlId="part">
                                <Form.Label className='form-label-custom'>
                                  {t("partID")}
                                  <span className='form-textnum ms-1'>({createWpNO.length ?? 0}/100)</span>
                                  /{t("name")}
                                  <span className='form-textnum ms-1'>({createWpName.length ?? 0}/100)</span>
                                </Form.Label><br />
                                <Row>
                                  <Col xs={6} className='pe-0'>
                                    <Form.Control
                                      type="text" name="createWpNO" value={createWpNO}
                                      onChange={handleInputChange} onBlur={checkUniquePart} autoComplete="off" 
                                      className={`rounded-0 ${judgeCheckErr && ((createWpNO === ""  && createWpName !== "") || createWpNO.length > 100 || !judgeUniqueWpNO) ? "input-error" : ""}`}
                                    />
                                  </Col>
                                  <Col xs={6} className='ps-0'>
                                    <Form.Control
                                      type="text" name="createWpName" value={createWpName}
                                      onChange={handleInputChange} onBlur={checkUniquePart} autoComplete="off" 
                                      className={`rounded-0 ${periodReportType === 1 && judgeCheckErr && ((createWpName === "" && createWpNO !== "") || createWpName.length > 100) ? "input-error" : ""}`}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} className='pe-0'>
                                    {
                                      periodReportType === 1 && judgeCheckErr && createWpNO === "" && createWpName !== "" ?
                                        <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                                        <></>
                                    }
                                    {
                                      periodReportType === 1 && judgeCheckErr && createWpNO !== "" && createWpNO.length > 100 ?
                                        <Form.Label className='text-danger mb-0'>
                                          {t("exceedsCharacterLimit")}
                                        </Form.Label> :
                                        <></>
                                    }
                                    {
                                      periodReportType === 1 && judgeCheckErr && createWpNO !== "" && createWpNO.length <= 100 && !judgeUniqueWpNO ?
                                        <Form.Label className='text-danger mb-0'>
                                          {t("thisPartIDAlreadyTaken")}&nbsp;{judgeUniqueWpNOError}
                                        </Form.Label> :
                                        <></>
                                    }
                                  </Col>
                                  <Col xs={6} className='ps-0'>
                                    {
                                      periodReportType === 1 && judgeCheckErr && createWpNO !== "" && createWpName === "" ?
                                        <Form.Label className='text-danger mb-0'>{t("requiredFields")}</Form.Label> :
                                        <></>
                                    }
                                    {
                                      periodReportType === 1 && judgeCheckErr && createWpName !== "" && createWpName.length > 100 ?
                                        <Form.Label className='text-danger mb-0'>
                                          {t("exceedsCharacterLimit")}
                                        </Form.Label> :
                                        <></>
                                    }
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <Form.Group className="mb-3" controlId="programName">
                                  <Form.Label className='form-label-custom'>
                                    {t("machiningProgram")}
                                    <span className='text-danger ms-1'>*</span>
                                    <span className='form-textnum ms-1'>({createProgramName.length ?? 0}/100)</span>
                                  </Form.Label><br />
                                  <Form.Control
                                    type="text" name="createProgramName" value={createProgramName}
                                    onChange={handleInputChange} onBlur={checkUniquePart} autoComplete="off" 
                                    className={`${judgeCheckErr && (createProgramName !== "" && (createProgramName.length > 100 || !judgeUniqueProgramName)) ? "input-error" : ""}`}
                                  />
                                  {
                                    periodReportType === 1 && judgeCheckErr && createProgramName !== "" && createProgramName.length > 100 ?
                                      <Form.Label className='text-danger mb-0'>
                                        {t("exceedsCharacterLimit")}
                                      </Form.Label> :
                                      <></>
                                  }
                                  {
                                    periodReportType === 1 && judgeCheckErr && createProgramName !== "" && createProgramName.length <= 100 && !judgeUniqueProgramName ?
                                      <Form.Label className='text-danger mb-0'>
                                        {
                                          judgeUniqueProgramNameError === "" ? 
                                            t("thisProgramAlreadyTaken2") : <>{t("thisProgramAlreadyTaken")}&nbsp;{judgeUniqueProgramNameError}</>
                                        }
                                      </Form.Label> :
                                      <></>
                                  } 
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </>
                    : <></>
                  } 
                </>
                 : null
            }  
            {
              periodReportType === 1 ?
                <Row>
                  <Col xs={12}>
                    <Form.Group className="mb-3" controlId="producedCount">
                        <Form.Label className='form-label-custom'>
                          {t("producedCount")}
                          <span className='text-danger ms-1'>*</span>
                        </Form.Label><br />
                        <Form.Control
                          type="text" name="producedCount" value={productionQty}
                          onChange={handleInputChange} autoComplete="off" 
                          className={`${judgeCheckErr && (productionQty === "" || !isNumber(parseFloat(productionQty)) 
                            || !isPositiveInteger(parseFloat(productionQty))) ? "input-error" : ""}`}
                        />                        
                        {
                          periodReportType === 1 && judgeCheckErr && productionQty === "" ?
                            <Form.Label className='text-danger mb-0'>
                              {t("requiredFields")}
                            </Form.Label> :
                            <></>
                        }
                        {
                          periodReportType === 1 && judgeCheckErr && productionQty !== "" && (!isNumber(parseFloat(productionQty)) || !isPositiveInteger(parseFloat(productionQty))) ?
                            <Form.Label className='text-danger mb-0'>
                              {t("formatErrorGreaterZero")}
                            </Form.Label> :
                            <></>
                        } 
                    </Form.Group>
                  </Col>
                </Row> : null
            }
            <hr />
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="remark">
                    <Form.Label className='form-label-custom'>
                      {t("remark")}
                      <span className='form-textnum ms-1'>({remark.length ?? 0}/300)</span>
                    </Form.Label><br />
                    <Form.Control
                      as="textarea" rows={4} name="remark" value={remark}
                      onChange={handleInputChange} autoComplete="off" 
                      className={`${judgeCheckErr && (remark !== "" && remark.length > 300) ? "input-error" : ""}`}
                    />
                    {
                      judgeCheckErr && remark !== "" && remark.length > 300 ?
                        <Form.Label className='text-danger mb-0'>
                          {t("exceedsCharacterLimit")}
                        </Form.Label> :
                        <></>
                    }
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <div className="modal-footer-custom">
        <div className="btn-gp">
            <div>
              <button type="button" className="btn btn-lv3" onClick={handlePrevious}>
                {t("previous")}
              </button>
            </div>
            <div>
              <button className="btn btn-lv1" onClick={handleStartCapture}>
                {t("startCapture")}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal.Footer className="modal-footer-custom">
          <Row>
            <Col xs={6} className='ps-0'>
              <button type="button" className="button-previous" onClick={handlePrevious}>
                {t("previous")}
              </button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end pe-0'>
              <button className="button-primary" onClick={handleStartCapture}>
                {t("startCapture")}
              </button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer />
    </>
  );
}

export default CapturePeriodPart;