import React, {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

import { apiForgotPwd, apiCSRF } from 'utils/Api';
import { useNavigate } from 'react-router-dom';

import { addOneDay, maskEmail } from 'utils/commonFun';

const ForgetPaw = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    const [userAccount, setUserAccount] = useState("");
    const [errorTxt, setErrorTxt] = useState(""); //錯誤訊息

    const [showContainer, setShowContainer] = useState("setForgotPaw"); //msg=>訊息框 setForgotPaw=>忘記密碼
    const [msgInfo, setMsgInfo] = useState(null); //回傳資訊

    const handleInputChange = async (e) => {
        const { value } = e.target;
        setUserAccount(value);
    };

    const submitForgotPWD = async (e) => {
        e.preventDefault();
        if(userAccount != ""){
            const [httpStatusCSRF, reponseDataCSRF] = await handleApiCSRF();
            if(httpStatusCSRF == "200"){
                if(reponseDataCSRF.statusCode === "20000"){
                    const [httpStatus, reponseData] = await handleApiForgotPWD();
                    if(httpStatus == "200"){
                        if(reponseData.statusCode === "20100"){
                            setMsgInfo(reponseData.data);
                            setShowContainer("msg");
                        }
                        else if(reponseData.statusCode === "40400"){
                            setErrorTxt(t("loginErrorMsg1"));
                        }
                        else if(reponseData.statusCode === "40300"){
                            setErrorTxt(t("forgotErrorMsg1"));
                        }
                        else{
                            toast.error(reponseData.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark"
                            });
                        }
                    }
                    else{
                        toast.error(reponseData.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                }
                else{
                    toast.error(reponseDataCSRF.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark"
                    });
                }
            }
            else{
                toast.error(reponseDataCSRF.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }
        else{
            setErrorTxt(t("requiredFields"));
        }
    }

    //#region CSRF API
    const handleApiCSRF = async () => {
        let csrfResponse = await apiCSRF();
        if(csrfResponse){
            const httpStatus = csrfResponse.request.status;
            const reponseData = csrfResponse.data;

            return [httpStatus, reponseData];
        }
        else{
            return ["500", ""]
        }
    }
    //#endregion


    //#region 忘記密碼API
    const handleApiForgotPWD = async () => {
        let postData = {
            userAccount: userAccount
        }
        let forgotPwdResponse = await apiForgotPwd(postData);
        if(forgotPwdResponse){
            const httpStatus = forgotPwdResponse.request.status;
            const reponseData = forgotPwdResponse.data;

            return [httpStatus, reponseData];
        }
        else{
            return ["500", ""]
        }
    }
    //#endregion

    //#region 跳轉登入頁面
    const navigateLogin = () => {
        navigate("/");
    }
    //#endregion

    return (
        <>
            <div className={`login-container  ${showContainer === "setForgotPaw" ? "" : "d-none"}`}>
                <form className="login-form">
                    <div className='login-board'>
                        <h3 className='tCenter mb-3'>{t("forgotPassword")}</h3>
                        <p className='forgetPawInput mb10'>{t("enterUserAccount")}</p>
                        <div className="input-leftpadding w-100 mb20">
                            <input type="text" placeholder={t("userAccount")} className='input outline w-100'
                                name="userAccount" value={userAccount} onChange={handleInputChange}
                            />
                            <div className="icon icon-User black"></div>
                        </div>
                        {/* <div className="input-with-icon ps-2 mb-4">
                            <span className="material-symbols-rounded">face</span>
                            <input type="text" placeholder={t("userAccount")} className='w-100'
                                name="userAccount" value={userAccount} onChange={handleInputChange}
                            />
                        </div> */}
                        <button className='btn btn-lv1 w-100 h38' onClick={submitForgotPWD}>{t("sendPasswordEmail")}</button>

                        {
                            errorTxt != "" ?
                            <>
                                <hr style={{color: "black"}} />

                                <p className='text-error text14'>{errorTxt}</p>
                            </> : ""
                        }
                    </div>

                    <div className='w-100 d-flex justify-content-center mt-5'>
                        <button className='btn btn-txtBlue-bgWhite btn-icontext' onClick={navigateLogin}>
                            <div className="icon icon-Back"></div>
                            <div className="text">{t("backLogin")}</div>
                            </button>
                    </div>
                </form>
            </div>
            <div className={`login-container  ${showContainer === "msg" ? "" : "d-none"}`}>
                <div className='message-board'>
                    <p className='message-board-title mb-0'>
                        <b>
                        {(() => {
                            if(msgInfo && msgInfo.email != ""){
                                return t("checkmailbox");
                            }
                            else {
                                return t("contactAdmin");
                            }
                        })()}
                        </b>
                    </p>
                    <div className='message-board-content'>
                        {(() => {
                            if(msgInfo && msgInfo.role == "user" && (msgInfo.email == null || msgInfo.email == "")){
                                return t("forgotMsg4");
                            }
                            else {
                                return <div dangerouslySetInnerHTML={{ __html: t("forgotMsg1", { mail: maskEmail(msgInfo?.email ?? ""), time: `<span style="color: #F66F6F;">${addOneDay()}</span>` }) }} />;
                            }
                        })()}

                        <br />

                        {(() => {
                            if(msgInfo && msgInfo.role != "user"){
                                return t("forgotMsg2");
                            }
                            else if(msgInfo && msgInfo.role == "user" && msgInfo.email != ""){
                                return t("forgotMsg3");
                            }
                            else {
                                return t("forgotMsg5");
                            }
                        })()}
                    </div>
                    <input type="button" className='btn btn-lv1 w-100 h38' value={t("backLogin")} onClick={navigateLogin} />
                </div>
            </div>

            <ToastContainer />
        </>
        
    )
}

export default ForgetPaw;