import React, {useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { MyUserContext } from 'contexts/MyUserContext';
import { calculateDaysBetweenDates, formatDateTimeRange } from 'utils/commonFun';
import moment from 'moment';

import { apiGetRatingCriteria, apiGetWorkDays, apiGetMachineOperatingTime, 
  apiRawDataEquipmentRuntime, apiShutDownDailyShiftData } from 'utils/Api';

import Summary from 'components/utilizationRate/Summary';
import DataCharts from 'components/utilizationRate/DataCharts';
import TimeLine from 'components/utilizationRate/TimeLine';

import './UtilizationRate.css';

const UtilizationRate = (props) => {
    const {choiceMachine, startDate, endDate} = props;

    const { t } = useTranslation("common");
    const { overtime, jobLastUpdateDate, lang, showFormatDate, showFormatTime } = useContext(MyUserContext);

    const [transJobLastUpdateDate, setTransJobLastUpdateDate] = useState(jobLastUpdateDate);
    //#region 設定檔
    const [workDays, setWorkDays] = useState(null); //顯示工作日
    const [workCalDays, setWorkCalDays] = useState(null); //計算工作日天數
    const [machineOperatingTime, setMachineOperatingTime] = useState(null); //顯示設備運轉時間
    const [machineOperatingHours, setMachineOperatingHours] = useState(null); //計算一天工作時數
    const [plannedEquipmentLoadingTime, setPlannedEquipmentLoadingTime] = useState(0.0);  //設備計畫工作時間
    const [ratingCriteria, setRatingCriteria] = useState(null); //顯示評級標準
    const [shutDownDaily, setShutDownDaily] = useState(null); //顯示停機(Day)
    const [shutDownDailyTimes, setShutDownDailyTimes] = useState(0); //計算停機總次數
    const [calIntervalDays, setCalIntervalDays] = useState(0); //計算區間天數
    const [calProgramNum, setCalProgramNum] = useState(0); //加工程序數量
    //#endregion

    //#region 真實資料
    const [rawData, setRawData] = useState(null);

    const [rawDataMachining, setRawDataMachining] = useState(0); //運轉中時數
    const [rawDataIdle, setRawDataIdle] = useState(0); //閒置或準備中時數
    const [rawDataAlarm, setRawDataAlarm] = useState(0); //錯誤停機時數
    const [rawDataOffline, setRawDataOffline] = useState(0); //關機或未連線時數
    //#endregion

    useEffect(() => {
      let trans = formatDateTimeRange(showFormatDate, showFormatTime, jobLastUpdateDate);
      setTransJobLastUpdateDate(`${trans.startDate} ${trans.startTime}`);
    }, [jobLastUpdateDate, showFormatDate, showFormatTime, lang]);
    
    //#region 
    useEffect(() => {
      if(choiceMachine != null && startDate != null && endDate != null){
        setCalIntervalDays(calculateDaysBetweenDates(startDate, endDate));
        const fetchData = async () => {
          const [httpStatusEquipmentRuntime, reponseDataEquipmentRuntime] = await handleApiGetEquipmentRuntime();
          if(httpStatusEquipmentRuntime == "200"){
            if(reponseDataEquipmentRuntime.statusCode === "20000"){
              setRawData(reponseDataEquipmentRuntime.data.detail);
            }
            else if(reponseDataEquipmentRuntime.statusCode === "40103" || reponseDataEquipmentRuntime.statusCode === "40104"){
              overtime();
            }
          }

          //#region 顯示停機(Day)API
          const [httpStatusShutDownDailyShift, reponseDataShutDownDailyShift] = await handleApiShutDownDailyShift();
          if(httpStatusShutDownDailyShift == "200"){
              if(reponseDataShutDownDailyShift.statusCode === "20000"){
                setShutDownDaily(reponseDataShutDownDailyShift.data);
                
                if(reponseDataShutDownDailyShift.data){
                  let times = 0;
                  for(let data of reponseDataShutDownDailyShift.data){
                    times += data.shiftList[0].times;
                  }
                  setShutDownDailyTimes(times);
                }
              }
              else if(reponseDataShutDownDailyShift.statusCode === "40103" || reponseDataShutDownDailyShift.statusCode === "40104"){
                  overtime();
              }
          }
          //#endregion

          //#region 評級資料
          const [httpStatusRatingCriteria, reponseDataRatingCriteria] = await handleApiGetRatingCriteria();
          if(httpStatusRatingCriteria == "200"){
            if(reponseDataRatingCriteria.statusCode === "20000"){
              
              setRatingCriteria(reponseDataRatingCriteria.data);
            }
            else if(reponseDataRatingCriteria.statusCode === "40103" || reponseDataRatingCriteria.statusCode === "40104"){
                overtime();
            }
          }
          //#endregion

          //#region 工作日
          const [httpStatusWorkDays, reponseDataWorkDays] = await handleApiGetWorkDays();
          if(httpStatusWorkDays == "200"){
            if(reponseDataWorkDays.statusCode === "20000"){
                setWorkDays(reponseDataWorkDays.data);

              //   setWorkCalDays(Object.values(reponseDataWorkDays?.data).filter(value => value === true).length);
            }
            else if(reponseDataWorkDays.statusCode === "40103" || reponseDataWorkDays.statusCode === "40104"){
                overtime();
            }
          }
          //#endregion

          //#region 顯示機台運轉時間API
          const [httpStatusMachineOperatingTime, reponseDataMachineOperatingTime] = await handleApiMachineOperatingTime();
          if(httpStatusMachineOperatingTime == "200"){
            if(reponseDataMachineOperatingTime.statusCode === "20000"){
              if(reponseDataMachineOperatingTime.data.choice === 1){
                setMachineOperatingTime(reponseDataMachineOperatingTime.data.customizedTimeList);
                // let customizedTime = reponseDataMachineOperatingTime.data.customizedTimeList;

                // let calHours = 0;  //計算總時數
                // for(let time of customizedTime){
                //   calHours += parseFloat(secondsToHours(timeCalc(time.startTime, time.endTime)));
                // }
                setMachineOperatingHours(reponseDataMachineOperatingTime.data.customizedTime);
              }
              else{
                setMachineOperatingTime(null);
                setMachineOperatingHours(24);
              }
            }
            else if(reponseDataMachineOperatingTime.statusCode === "40103" || reponseDataMachineOperatingTime.statusCode === "40104"){
                overtime();
            }
          }
          //#endregion
        }

        fetchData();
      }
    }, [choiceMachine, startDate, endDate]);
    
    useEffect(() => {
      if(workCalDays != null && machineOperatingHours != null && rawData != null){
        let finishPlanHours = 0;
        //#region 跑起訖日期間
        let currentDate = moment(startDate);
        while (currentDate <= moment(endDate)) {
          //判斷是否真實資料裡面有沒有這一天
          let filter = rawData.find(d => d.showDate === currentDate.format("yyyy-MM-DD"));
          if(filter){
            finishPlanHours += filter.plannedHours;
          }
          else{
            //判斷星期幾
            const dayOfWeek = currentDate.day();
            //判斷是否為休息日
            let judgeRest = false;
            if((dayOfWeek === 0 && !workDays?.Sunday) || (dayOfWeek === 1 && !workDays?.Monday) ||
                (dayOfWeek === 2 && !workDays?.Tuesday) || (dayOfWeek === 3 && !workDays?.Wednesday) ||
                (dayOfWeek === 4 && !workDays?.Thursday) || (dayOfWeek === 5 && !workDays?.Friday) ||
                (dayOfWeek === 6 && !workDays?.Saturday)){
              judgeRest = true;
            }
            
            if(!judgeRest){
              finishPlanHours += machineOperatingHours;
            }
          }

          currentDate = currentDate.add(1, 'days');
        }

        setPlannedEquipmentLoadingTime(finishPlanHours.toFixed(1));
        //#endregion
      }
    }, [workCalDays, machineOperatingHours, rawData]);

    useEffect(() => {
      if(rawData){
        let newMachining = 0; //運轉中
        let newIdle = 0;  //閒置或準備中
        let newAlarm = 0;  //錯誤停機
        let newOffline = 0;  //關機或未連線
        let plannedDays = 0; //計畫工作天數 有大於0才計算
        for(let item of rawData){
          let statusList = item.statusList;
          let filterMachining = statusList.find(d => d.status === "Machining");
          if(filterMachining){
            newMachining += filterMachining.seconds;
          }
          
          let filterIdle = statusList.find(d => d.status === "Idle");
          if(filterIdle){
            newIdle += filterIdle.seconds;
          }
  
          let filterAlarm = statusList.find(d => d.status === "Alarm");
          if(filterAlarm){
            newAlarm += filterAlarm.seconds;
          }
  
          let filterOffline = statusList.find(d => d.status === "Offline");
          if(filterOffline){
            newOffline += filterOffline.seconds;
          }
  
          if(item.plannedHours > 0){
              plannedDays = plannedDays + 1;
          }
        }
  
        setWorkCalDays(plannedDays);
  
        setRawDataMachining(newMachining);
        setRawDataIdle(newIdle);
        setRawDataAlarm(newAlarm);
        setRawDataOffline(newOffline);
      }
    }, [rawData]);
    //#endregion

    //#region 抓取休息日
    const handleApiGetWorkDays = async () => {
      let workDaysResponse = await apiGetWorkDays();
      if(workDaysResponse){
          const httpStatus = workDaysResponse.request.status;
          const reponseData = workDaysResponse.data;

          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 顯示機台運轉時間API
    const handleApiMachineOperatingTime = async () => {
      let machineOperatingTimeResponse = await apiGetMachineOperatingTime();
      if(machineOperatingTimeResponse){
          const httpStatus = machineOperatingTimeResponse.request.status;
          const reponseData = machineOperatingTimeResponse.data;

          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 抓取評級標準
    const handleApiGetRatingCriteria = async () => {
      let ratingCriteriaResponse = await apiGetRatingCriteria();
      if(ratingCriteriaResponse){
          const httpStatus = ratingCriteriaResponse.request.status;
          const reponseData = ratingCriteriaResponse.data;

          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 抓取原始數據內容(設備運轉)
    const handleApiGetEquipmentRuntime = async () => {
      let calEndDate = endDate;
      const parsedEndDate = new Date(endDate);
      const today = new Date();
      // Set today's date to midnight for comparison
      today.setHours(0, 0, 0, 0);
    
      // Check if parsed endDate is greater than today
      if (parsedEndDate > today) {
        // If so, set endDate to today's date in yyyy-MM-dd format
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(today.getDate()).padStart(2, '0');
        calEndDate = `${year}-${month}-${day}`;
      }

      const postData = {
        startDate: startDate,
        endDate: calEndDate,
        midList: [choiceMachine.mid]
      }

      let response = await apiRawDataEquipmentRuntime(postData);
      if(response){
          const httpStatus = response.request.status;
          const reponseData = response.data;

          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
    }
    //#endregion

    //#region 顯示停機(Day)API
    const handleApiShutDownDailyShift = async () => {
      const postData = {
          startDate: startDate,
          endDate: endDate,
          id: choiceMachine.mid,
          doGroupbyShift: false
      }

      let shutDownDailyShiftResponse = await apiShutDownDailyShiftData(postData);
      if(shutDownDailyShiftResponse){
          const httpStatus = shutDownDailyShiftResponse.request.status;
          const reponseData = shutDownDailyShiftResponse.data;

          return [httpStatus, reponseData];
      }
      else{
          return ["500", ""]
      }
  }
  //#endregion

    return(
      <div className='pageContent'>
        <div className="block-title-remark">
          <div>
            <h2 className="block-title">{t("summary")}</h2>
          </div>
          <div className="remark">
            <span>{t("lastUpdate")}</span>{localStorage.getItem("runJob") != null ? t("updating") : transJobLastUpdateDate}
          </div>
        </div>
        <Summary 
          startDate={startDate} //開始日期
          endDate={endDate}  //結束日期
          workCalDays={workCalDays} //計畫工作天數
          machineOperatingHours={machineOperatingHours}  //計算一天幾個小時
          machineOperatingTime={machineOperatingTime}   //API計畫工作時間null代表是24小時
          plannedEquipmentLoadingTime={plannedEquipmentLoadingTime} //設備的計畫工作時間 
          rawDataMachining={rawDataMachining} //區間內的實際運轉中時數
          rawDataIdle={rawDataIdle} //區間內的實際閒置或準備中
          ratingCriteria={ratingCriteria} //API評分標準
          shutDownDailyTimes={shutDownDailyTimes}
          calProgramNum={calProgramNum} //加工程序數量
          calIntervalDays={calIntervalDays}
        />

        
        <h2 className="block-title">{t("dataCharts")}</h2>
        <DataCharts 
            startDate={startDate} //開始日期
            endDate={endDate}  //結束日期
            workCalDays={workCalDays} //計畫工作天數
            plannedEquipmentLoadingTime={plannedEquipmentLoadingTime} //設備的計畫工作時間
            rawDataMachining={rawDataMachining} //區間內的實際運轉中時數
            rawDataIdle={rawDataIdle} //區間內的實際閒置或準備中
            rawDataAlarm={rawDataAlarm} //區間內的錯誤停機
            rawDataOffline={rawDataOffline}  //區間內的關機或未連線
            rawData={rawData}
            ratingCriteria={ratingCriteria} //API評分標準
            choiceMachine={choiceMachine}
            shutDownDaily={shutDownDaily} //每日錯誤停機
            calIntervalDays={calIntervalDays}  //區間天數
            setCalProgramNum={setCalProgramNum}  //設定加工程序數量
        />

        <h2 className="block-title">{t("equipmentStatusTimeline")}</h2>
        <TimeLine 
          startDate={startDate} //開始日期
          endDate={endDate}  //結束日期
          choiceMachine={choiceMachine}
        />
      </div>
    )
}

export default UtilizationRate;