import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { Row, Col, Dropdown, Modal, Form } from 'react-bootstrap';
import './header.css';
import { MyUserContext } from 'contexts/MyUserContext';
import { getMisc } from 'utils/commonFun';
import moment from 'moment';

const Header = (props) => {
  const { handleLogOut, lang, handleChangeLang, misc, setMisc, 
    showFormatDate, setShowFormatDate, showFormatTime, setShowFormatTime } = props;
  const { t } = useTranslation("common");
  const { authority } = useContext(MyUserContext);

  const [showLang, setShowLang] = useState("English");
  const [fullscreen, setFullscreen] = useState(false);
  const [modalMisc, setModalMisc] = useState(null); //其他設定
  const [showModalScreenDisplay, setShowModalScreenDisplay] = useState(false); //是否顯示Modal(畫面顯示)

  const [selectedDateFormat, setSelectedDateFormat] = useState(""); //日期格式
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(""); //時間格式
  const [judgeTwoDigitFormat, setJudgeTwoDigitFormat] = useState(true); //是否顯示日期顯示兩位數

  const [previewDateFormat, setPreviewDateFormat] = useState(showFormatDate);
  const [previewTimeFormat, setPreviewTimeFormat] = useState(showFormatTime);

  const dateFormats = [
    { id: "dateFormat1", formatTwoDigit: "DD / MM / YYYY", format: "D / M / YYYY" },
    { id: "dateFormat2", formatTwoDigit: "DD MMM YYYY", format: "D MMM YYYY" },
    { id: "dateFormat3", formatTwoDigit: "MM / DD / YYYY", format: "M / D / YYYY" },
    { id: "dateFormat4", formatTwoDigit: "MMM DD, YYYY", format: "MMM D, YYYY" },
    { id: "dateFormat5", formatTwoDigit: "YYYY / MM / DD", format: "YYYY / M / D" },
    { id: "dateFormat6", formatTwoDigit: "YYYY MMM DD", format: "YYYY MMM D" }
  ];

  const timeFormats = [
    { id: "timeFormat1", format: "HH:mm:ss" },
    { id: "timeFormat2", format: "hh:mm:ss A" }
  ];
  
  //#region 初始載入
  useEffect(() => {    
    let dataMisc = getMisc();    
    handleMiscChange(dataMisc);
  }, []);

  useEffect(() => {    
    let filterDateFormat = dateFormats.find(d => d.format === showFormatDate || d.formatTwoDigit === showFormatDate);
    if(filterDateFormat){
      setSelectedDateFormat(filterDateFormat.id);
      handlePreviewDateFormat(filterDateFormat.id);
    }
    else{
      setSelectedDateFormat("dateFormat4");
      handlePreviewDateFormat("dateFormat4");
    }    

    let filterTimeFormat = timeFormats.find(d => d.format === showFormatTime);
    if(filterTimeFormat){
      setSelectedTimeFormat(filterTimeFormat.id);
      handlePreviewTimeFormat(filterTimeFormat.id);
    }
    else{
      setSelectedTimeFormat("timeFormat2");
      handlePreviewTimeFormat("timeFormat2");
    }
  }, [showFormatDate, showFormatTime]);
  //#endregion
  
  useEffect(() => {
    if (lang === "en") {
      setShowLang("English");
    }
    else {
      setShowLang("繁體中文");
    }
  }, [lang]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setFullscreen(false);
      });
    }
  };  

  const handleMiscChange = (dataMisc) => {    
    setMisc(dataMisc);
    localStorage.setItem("misc", JSON.stringify(dataMisc));
    let theme = dataMisc.darkTheme ? 'Dark' : 'Light';
    document.body.className = theme;
  }

  //#region 是否顯示Modal(畫面顯示)
  const handleModalScreenDisplay_fun = () => {
    setShowModalScreenDisplay(!showModalScreenDisplay);

    if (!showModalScreenDisplay) {
      setModalMisc(misc);
    }
  }
  //#endregion

  //#region 是否顯示Modal(畫面顯示)-取消按鈕
  const handleModalScreenDisplayCancel_fun = () => setShowModalScreenDisplay(false);
  //#endregion

  //#region 是否顯示Modal(畫面顯示)-套用按鈕
  const handleModalScreenDisplayApply_fun = async () => {
    let filterDateFormat = dateFormats.find(d => d.id === selectedDateFormat);
    if(filterDateFormat){
      if(judgeTwoDigitFormat){
        localStorage.setItem("showFormatDate", filterDateFormat.formatTwoDigit);
        setShowFormatDate(filterDateFormat.formatTwoDigit);
      }
      else{
        localStorage.setItem("showFormatDate", filterDateFormat.format);
        setShowFormatDate(filterDateFormat.format);
      }
    }

    let filterTimeFormat = timeFormats.find(d => d.id === selectedTimeFormat);
    if(filterTimeFormat){
      localStorage.setItem("showFormatTime", filterTimeFormat.format);
      setShowFormatTime(filterTimeFormat.format);
    }

    handleMiscChange(modalMisc);
    setShowModalScreenDisplay(false);
  }
  //#endregion

  //#region 改變值
  const handleChange = (e, modalName) => {
    let newModalMisc = { ...modalMisc };
    if (modalName === "detailedExplanation") {      
      newModalMisc.detailedExplanation = !newModalMisc.detailedExplanation;      
    }
    else if (modalName === "darkTheme") {
      newModalMisc.darkTheme = !newModalMisc.darkTheme;
    }
    setModalMisc(newModalMisc);
  }
  //#endregion

  const set = () => {
    setModalMisc(misc);
    setShowModalScreenDisplay(true);
  }

  //#region 跳轉至後台
  const jumpAdmin = () => {
    if (authority.pages.length > 0) {
      window.open(window.backendUrl + authority.pages[0].pagePath, '_blank'); //暫時另開分頁
    }
  }
  //#endregion

  const handlePreviewDateFormat = (format) => {
    let filterDateFormat = dateFormats.find(d => d.id === format);
    if(filterDateFormat){
      if(judgeTwoDigitFormat){
        setPreviewDateFormat(filterDateFormat.formatTwoDigit);
      }
      else{
        setPreviewDateFormat(filterDateFormat.format);
      }
    }
  }

  const handlePreviewTimeFormat = (format) => {
    let filterTimeFormat = timeFormats.find(d => d.id === format);
    if(filterTimeFormat){
      setPreviewTimeFormat(filterTimeFormat.format);
    }
  }

  const handleChangeDate = (event) => {
    const selectedValue = event.target.value; // 獲取使用者選擇的 value
    setSelectedDateFormat(selectedValue);
    handlePreviewDateFormat(selectedValue);
  }

  const handleChangeTime = (event) => {
    const selectedValue = event.target.value; // 獲取使用者選擇的 value
    setSelectedTimeFormat(selectedValue);
    handlePreviewTimeFormat(selectedValue)    
  }

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setJudgeTwoDigitFormat(checked);
  }

  return (
    <>
      <div className='header'>
        {/* <Row className='w-100'> */}
        <div className='filterRow small'>
          <div className="align-center">
            <div className='logoHeaderImg icon-ART'></div>
            {/*<Dropdown className='ms-2'>
                <Dropdown.Toggle className='logOutBtn h-100'>
                  <div className='d-flex align-content-center'>
                    <p className='utilizationCardTitle'>TW-9527　東坡肉機械有限公司</p>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>TW-9527　東坡肉機械有限公司</Dropdown.Item>
                  <Dropdown.Item>TW-123　糖醋排骨機械有限公司</Dropdown.Item>
                  <Dropdown.Item>TW-456　雞排機械有限公司</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>*/}
          </div>
          <div>
            <Dropdown onSelect={(selectedKey) => handleChangeLang(selectedKey)}>
              <Dropdown.Toggle className='btn-txtBlue-bgLightBlue'>
                <div className='d-flex align-content-center' style={{ lineHeight: "normal" }}>
                  <div className="icon icon-Language"></div>
                  <div >{showLang}</div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="en">English</Dropdown.Item>
                <Dropdown.Item eventKey="zh-TW">繁體中文</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <button className='btn-txtBlue-bgLightBlue btn btn-onlyicon ms-2 mobile-noshow' title={fullscreen ? t("exitFullScreen") : t("fullScreen")} onClick={toggleFullscreen}>
              <div className={`icon ${fullscreen ? "icon-FullScreen" : "icon-FullScreenExit"}`}></div>
            </button>
            <Dropdown className='ms-2'>
              <Dropdown.Toggle className='btn btn-lv3 h-100'>
                <div>
                  {authority?.username}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropmenu">
                <Dropdown.Item className='tRight'>
                  <b>{authority?.username}</b>
                  <p className='text-blue mb-0 text12'>
                    {(() => {
                      if (authority?.role === "sysAdmin") {
                        return "System Admin";
                      }
                      else if (authority?.role === "admin") {
                        return "Admin";
                      }
                      else if (authority?.role === "user") {
                        return "User";
                      }
                    })()}
                  </p>
                  <p className='text-greyA mb-0 text12'>{authority?.email}</p>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                {
                  authority?.role !== "user" ?

                    <Dropdown.Item className='item' onClick={jumpAdmin}>
                      <div className="icon icon-AdminDeck">tune</div>
                      <div className="text">{t("adminDeck")}</div>
                    </Dropdown.Item> : <></>
                }
                <Dropdown.Item className='item' onClick={set}>
                  <div className="icon icon-Settings"></div>
                  <div className="text">{t("settings")}</div>
                </Dropdown.Item>
                <Dropdown.Item className='item' onClick={handleLogOut}>
                  <div className="icon icon-LogOut"></div>
                  <div className="text">{t("logout")}</div>
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
            {/* <button className='logOutBtn h-100 ms-2'>
                <div className='d-flex align-content-center'>
                  <span className="material-symbols-rounded text-primary">logout</span>{t("logout")}
                </div>
              </button> */}
          </div>
        </div>
        {/* </Row> */}

      </div >

      {/*畫面顯示 modal - start*/}
      < Modal show={showModalScreenDisplay} onHide={handleModalScreenDisplay_fun} size="m" backdrop="static" keyboard={false} centered={true} >
        <div className="modal-header">
          <div>
            <div className="modal-title">{t("screenDisplay")}</div>
          </div>
          <button type="button" className="close" aria-label="Close" onClick={handleModalScreenDisplay_fun}>
            <div className="icon-Cross"></div>
          </button>
        </div>

        <Modal.Body>
          {/*1/3 啾啾新增下面這個<Row>，請Sheena製作成外觀(主題)切換功能*/}
          <Row className='my-2 align-items-center'>
            <Col xs={6} md={8}>
              {t("appearanceTheme")}
            </Col>
            <Col xs={6} md={4}>
              <Form.Check
                type="switch"
                id="darkTheme"
                name="darkTheme"
                label={modalMisc?.darkTheme  ? t("dark") : t("light")}
                checked={modalMisc?.darkTheme}
                onChange={(e) => handleChange(e, "darkTheme")}
              />
            </Col>
          </Row>
          <Row className='my-2 align-items-center'>
            <Col xs={6} md={8}>
              {t("detailedChartDescriptionText")}
            </Col>
            <Col xs={6} md={4}>
              <Form.Check
                type="switch"
                id="detailedExplanation"
                name="detailedExplanation"
                label={modalMisc?.detailedExplanation ? t("display") : t("hide")}
                checked={modalMisc?.detailedExplanation}
                onChange={(e) => handleChange(e, "detailedExplanation")}
              />
            </Col>
          </Row>
          <Row className='my-2 align-items-center'>
            <Col xs={6}>
              {t("timeFormat")}
            </Col>
            <Col xs={6}>
              <Form.Control as="select"
                name="dateFormat"
                onChange={handleChangeDate}
                value={selectedDateFormat}
              >
                {
                  dateFormats?.map((obj, index) => {
                    return(<option key={`dateFormat${index}`} value={obj.id}>
                      {t(obj.id)}
                    </option>)
                  }) 
                }
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col xs={6}></Col>
            <Col xs={6}>
              <Form.Control as="select"
                name="timeFormat"
                onChange={handleChangeTime}
                value={selectedTimeFormat}
              >
                {
                  timeFormats?.map((obj, index) => {
                    return(<option key={`timeFormat${index}`} value={obj.id}>
                      {t(obj.id)}
                    </option>)
                  }) 
                }
              </Form.Control>
            </Col>
          </Row>
          <Row className='my-2 align-items-center'>
            <Col xs={6}></Col>
            <Col xs={6}>
              <div>
                <input type="checkbox" id="TwoDigitFormat" name="TwoDigitForma" 
                  onChange={handleCheckboxChange} checked={judgeTwoDigitFormat} />
                <label htmlFor="TwoDigitForma">{t("dateDisplayedTwoDigitFormat")}</label>
              </div>
            </Col>
          </Row>
          <Row className='my-2 align-items-center'>
            <Col xs={6}></Col>
            <Col xs={6}>
              <span className="durationDay">{moment().startOf('year').format(`${previewDateFormat} ${previewTimeFormat}`)}</span>
            </Col>
          </Row>
          <Row className='mt-2 pt-3'>
            <Col>
              <button type="button" className="btn btn-txt-black h38" onClick={handleModalScreenDisplayCancel_fun}>
                {t("cancel")}
              </button>
            </Col>
            <Col className='d-flex justify-content-end'>
              <button type="button" className="btn btn-lv1 fat h38" onClick={handleModalScreenDisplayApply_fun}>
                {t("apply")}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal >
      {/*畫面顯示 modal - end*/}
    </>

  );
};

export default Header;