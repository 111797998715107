import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import './Login.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { setCookieLogin, getCookieLogin } from 'utils/loginUtil';

import { apiLogin, apiCSRF } from 'utils/Api';

import { encrypt, decrypt } from 'utils/crypt';

const Login = () => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const [loginData, setLoginData] = useState(
        {
            userAccount: "",
            userPwd: ""
        }
    );
    const [judgeVisibility, setJudgeVisibility] = useState(false); //是否打開眼睛
    const [errorTxt, setErrorTxt] = useState(""); //錯誤訊息

    useEffect(() => {
        if (getCookieLogin()) {
            navigate("/loginPreMain");
        }

        // console.log(decrypt("E09E298EF9A0549DF0E8D3CC7AD442F6"))
    }, []);

    //#region 要不要打開眼睛
    const handleEye = () => {
        setJudgeVisibility(!judgeVisibility);
    }
    //#endregion

    const handleInputChange = async (e) => {
        const { name, value } = e.target;

        setLoginData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    //#region 登入API
    const handleApiLogin = async () => {
        let postJson = {
            userAccount: loginData.userAccount,
            userPwd: encrypt(loginData.userPwd)
        }
        let loginResponse = await apiLogin(postJson);
        if (loginResponse) {
            const httpStatus = loginResponse.request.status;
            const reponseData = loginResponse.data;

            return [httpStatus, reponseData];
        }
        else {
            return ["500", ""]
        }
    }
    //#endregion

    //#region CSRF API
    const handleApiCSRF = async () => {
        let csrfResponse = await apiCSRF();
        if (csrfResponse) {
            const httpStatus = csrfResponse.request.status;
            const reponseData = csrfResponse.data;

            return [httpStatus, reponseData];
        }
        else {
            return ["500", ""]
        }
    }
    //#endregion

    //#region 登入按鈕
    const loginUser = async (e) => {
        e.preventDefault();
        const [httpStatusCSRF, reponseDataCSRF] = await handleApiCSRF();
        if (httpStatusCSRF == "200") {
            if (reponseDataCSRF.statusCode === "20000") {
                const [httpStatusUser, reponseDataUser] = await handleApiLogin();
                if (httpStatusUser == "200") {
                    if (reponseDataUser.statusCode === "20000") {
                        setCookieLogin(true);
                        // navigate("/loginPreMain", { state: reponseDataUser.data});
                        navigate("/loginPreMain");
                    }
                    else if (reponseDataUser.statusCode === "40400") {
                        setErrorTxt(t("loginErrorMsg1"));
                    }
                    else if (reponseDataUser.statusCode === "40102") {
                        setErrorTxt(t("loginErrorMsg2"));
                    }
                    else if (reponseDataUser.statusCode === "40300") {
                        setErrorTxt(t("loginErrorMsg3"));
                    }
                    else {
                        toast.error(reponseDataUser.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark"
                        });
                    }
                }
                else {
                    toast.error(reponseDataUser.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }
            else {
                toast.error(reponseDataCSRF.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            }
        }
        else {
            toast.error(reponseDataCSRF.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    //#endregion

    //#region 跳轉至忘記密碼
    const jumpForgetPaw = () => {
        navigate("/forgetPaw");
    }
    //#endregion

    return (
        <>
            <div className="login-container">
                <form className="login-form">
                    <div className='w-100 d-flex justify-content-center'>
                        <img src="/images/logo.svg" className='logoHeaderLoggin' />
                    </div>

                    <h4 className='tCenter mb-4 mt-5'><b>{t("logIn")}</b></h4>

                    <div className='login-board'>
                        <div className="input-leftpadding w-100 mb20">
                            <input type="text" placeholder={t("userAccount")} className='input outline w-100'
                                name="userAccount" value={loginData.userAccount} onChange={handleInputChange}
                            />
                            <div className="icon icon-User black"></div>
                        </div>
                        <div className="input-sidepadding w-100 mb10">
                            <input type={judgeVisibility ? "text" : "password"} placeholder={t("password")} className='input outline w-100'
                                name="userPwd"
                                value={loginData.userPwd} onChange={handleInputChange}
                                onClick={handleEye}
                            />                            
                            <div className="icon icon-Password black"></div>
                            <div className={`icon blue size-M cursor-pointer ${judgeVisibility ? "icon-ShowPassword" : "icon-HidePassword"}`} onClick={handleEye}>
                                
                            </div>
                        </div>
                        {/* <div className="input-with-icon ps-2 mb-4">
                            <span className="material-symbols-rounded">face</span>
                            <input type="text" placeholder={t("userAccount")} className='w-100'
                                name="userAccount" value={loginData.userAccount} onChange={handleInputChange}
                            />
                        </div> */}
                        {/* <div className="input-with-icon px-2 mb-4">
                            <span className="material-symbols-rounded">lock</span>
                            <input type={judgeVisibility ? "text" : "password"} placeholder={t("password")} className='w-100'
                                name="userPwd"
                                value={loginData.userPwd} onChange={handleInputChange}
                            />
                            <span className="material-symbols-rounded text-blue cursor-pointer" onClick={handleEye}>
                                {judgeVisibility ? "visibility" : "visibility_off"}
                            </span>
                        </div> */}
                        <p className='text-blue tRight text14 cursor-pointer' onClick={jumpForgetPaw}>{t("forgotPassword")}</p>

                        <button className='btn btn-lv1 w-100 h38' onClick={loginUser} 
                            onKeyDown={(event) => {
                                if (event.key === "Enter") { loginUser(); }
                            }}>{t("logInBtn")}
                        </button>

                        {
                            errorTxt != "" ?
                                <>
                                    <hr style={{ color: "black" }} />

                                    <p className='text14 text-error'>{errorTxt}</p>
                                </> : ""
                        }

                    </div>

                    {/* <p className='text-blue tCenter cursor-pointer mt-5'>{t("haveAccount")}</p>
                    <div className='w-100 d-flex justify-content-center'>
                        <button className='create-Account-btn'>{t("createAccount")}</button>
                    </div> */}
                </form>
            </div>
            <ToastContainer />
        </>
    )
}

export default Login;