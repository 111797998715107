import React from 'react';
import { useTranslation } from 'react-i18next'; //語系
import './tab.css';
import { capitalizeFirstLetter } from 'utils/commonFun';

const Tab = ({ tabList, setTabList, currentClickTabName = "", setCurrentClickTabName }) => {
  const { t } = useTranslation("common");

  const handlerTab = (tabName) => {
    setTabList((prevTabList) =>
      prevTabList.map((tab) => ({
        ...tab,
        active: tab.tabName === tabName
      }))
    );

    if (currentClickTabName != "") {
      setCurrentClickTabName(tabName);
    }
  }

  return (
    
    <div className='tabHeader scroll'>
      {
        tabList?.map((obj, index) => {
          return <div key={index} className={`tabItem ${obj.active ? "active" : ""}`}
            onClick={(e) => handlerTab(obj.tabName)}>
            <div className='d-flex align-items-center'>
              {obj.icon ? <><div className={`icon ${obj.icon}`}></div></> : ""}
              <div>{t(capitalizeFirstLetter(obj.tabName))}</div>
            </div>
          </div>;
        })
      }
    </div>
    
  );
};

export default Tab;